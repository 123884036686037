import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { ChangeLoadStatus, companyListByType, editloadvalues, loadAction, saveLoadPostOrder, TenderDetails } from '../../../actions/loadActions';
import { concat } from '../../../helpers/appUtil';
import { STATUS } from '../../../helpers/status';
import CancelledLoadpopup from './cancelledLoadpopup';
import moment from 'moment';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { latsTrackingByAssetIdService } from '../../../services/assetService';
import { formatLocation, getMapboxKey } from '../../../helpers/appUtil';
import { TabsBar, TableDataComponent } from 'UI-Component';

class LoadList extends Component {
	state = {
		postOrder: true,
		showMap: false
	}
	constructor(props) {
		super(props);
		this.currentStatus = STATUS.LOAD.DELIVERY_CONFIRMED;
		this.getIndex = this.getIndex.bind(this)
		this.MyLoads = [];
		this.accepted = [];
		this.tendered = [];
		this.dispatched = [];
		this.delivered = [];
		this.notyetdispatched = [];
		this.totalItemsCount = 1;
		this.activePage = 1;
		this.itemsCountPerPage = 5;
		this.pageRangeDisplayed = 5;
		this.checkedValue = '';
		this.newStatusId = '';
		this.cancelled = [];
	}
	getIndex(index, selectedId) {
		this.setState({ activeId: selectedId })
	}
	componentDidMount() {
		document.title = 'Loads';
		this.tabChange(STATUS.LOAD.DELIVERY_CONFIRMED);
		this.props.dispatch(companyListByType({ companytypeid: 2 }));
		mapboxgl.accessToken = getMapboxKey();
	}
	tabChange = (type) => {
		const params = {
			...type,
			searchdata: this.props.searchData,
			page: this.activePage,
			size: this.itemsCountPerPage
		}
		this.props.dispatch(loadAction(params));
		this.currentStatus = type;
	}
	state = {
		modaldata: []
	}
	getModal = (item, dispatch) => {
		this.setState({ showModal: true, modaldata: item });
	};

	dispacthFun(load) {
		const params = {
			loadId: load
		}
		this.props.dispatch(ChangeLoadStatus(params));
	}

	editloadpopup(loadid) {
		const params = {
			loadId: loadid
		}
		this.props.dispatch(editloadvalues(params));
	}

	openTenderOrder(id) {
		const params = {
			loadId: id
		}
		const params1 = {
			companytypeid: 3
		}
		this.props.dispatch(TenderDetails(params));
		this.props.dispatch(companyListByType(params1));
	}

	postLoad = (e) => {
		if (this.node.selectionContext.selected.length === 0) {
			toastr.error('Error', 'Select load!');
			return;
		}
		this.setState({
			...this.state,
			postOrder: true
		})
	}

	postOrderClose = (e) => {
		this.setState({
			...this.state,
			postOrder: false
		})
	}

	assignLoad = (e, row) => {

		if (this.node.selectionContext.selected.length === 0) {
			toastr.error('Error', 'Select load!');
			return;
		}

		this.props.dispatch(saveLoadPostOrder({
			loads: this.node.selectionContext.selected, brokers: [row.company_Id]
		}));

		this.setState({
			...this.state,
			postOrder: false
		})

	}

	openMap = (row) => {
		if (row.assetId) {
			let param = {
				assetId: row.assetId
			}
			latsTrackingByAssetIdService(param).then((response) => {
				if (response.TrackingData) {
					row = {
						Latitude: response.TrackingData.Latitude,
						Longitude: response.TrackingData.Longitude,
						Asset_Id: 1,
						Asset_Number: row.assetName,
						Location: response.TrackingData.Location,
						Speed: response.TrackingData.Speed,
						UTC_Date_Time: response.TrackingData.UTC_Date_Time
					}
					var Latitude = parseFloat(row.Latitude);
					var Longitude = parseFloat(row.Longitude);


					if (isNaN(Latitude) || isNaN(Longitude)) {
						toastr.info('Info', 'Location not valid!');
						return;
					}
					else if (Latitude > 90 || Longitude < -90) {
						toastr.error('Error', 'Invalid Latitude Longitude');
						return;
					}
					this.setState({
						...this.state,
						showMap: true
					});

					setTimeout(() => this.loadMap(row), 1000);
				}
				else {
					toastr.info('Info', 'Tracking data not found');
					return;
				}
			});
		}
		else {
			toastr.info('Info', 'Vehicle not assigned');
			return;
		}
	}

	loadMap = (row) => {

		var el = document.querySelector('#map-canvas');
		if (el === null) {
			setTimeout(() => this.loadMap(row), 1000);
			return;
		}

		this.showRouteMap(row.Asset_Id.toString(), row.Asset_Number.toString(), row.Location.toString(), row.Latitude.toString(), row.Longitude.toString(), row.Speed.toString(), row.UTC_Date_Time.toString());
	}

	showRouteMap(id, truckNo, location, Latitude, longitude, Speed, utctime) {
		var el = document.querySelector('#map-canvas');

		if (el == null) {
			setTimeout(
				function () {
					this.showRouteMap(id, truckNo, location, Latitude, longitude, Speed, utctime);
				}
					.bind(this),
				1000
			);

			return;
		}

		if (el.clientHeight < 1) {
			var body = document.body,
				html = document.documentElement;
			var height = Math.min(body.scrollHeight, body.offsetHeight,
				html.clientHeight, html.scrollHeight, html.offsetHeight);
			height -= 150;
			el.style.height = height + 'px';
		}
		this.setState({
			...this.state,
			mapData: [
				{
					'id': id,
					'truckNo': truckNo,
					'latitude': Latitude,
					'longitude': longitude,
					'speed': Speed,
					'address': location,
					'location': location,
					'date': utctime
				}
			]
		});

		var features = this.state.mapData.map(data => {
			return (
				{
					'type': 'Feature',
					'properties': {
						'id': data.id,
						'latitude': data.latitude,
						'longitude': data.longitude,
						'speed': data.speed,
						'location': data.location,
						'date': data.date,
						'truckNo': data.truckNo,
					},
					'geometry': {
						'type': 'Point',
						'coordinates': [
							data.longitude,
							data.latitude

						]
					}
				}
			)
		})

		let Lat = 45;
		let Lon = -90;
		if (this.state.mapData.length > 0) {
			Lat = parseFloat(this.state.mapData[0].latitude);
			Lon = parseFloat(this.state.mapData[0].longitude);
		}

		if (isNaN(Lat) || isNaN(Lon)) {
			Lat = 45;
			Lon = -90;
		}

		const map = new mapboxgl.Map({
			container: 'map-canvas', // container id
			style: 'mapbox://styles/mapbox/streets-v11', //stylesheet location
			center: [Lon, Lat], // starting position
			zoom: 13 // st arting zoom
		});
		var geojsonData = {
			'type': 'FeatureCollection',
			'features': features
		}

		map.on('load', function () {
			// Add a new source from our GeoJSON data and set the
			// 'cluster' option to true.
			map.addSource('earthquakes', {
				type: 'geojson',
				// Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
				// from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
				data: geojsonData,
				cluster: true,
				clusterMaxZoom: 14, // Max zoom to cluster points on
				clusterRadius: 40 // Radius of each cluster when clustering points (defaults to 50)

			});
			map.loadImage('https://maps.google.com/mapfiles/ms/icons/red.png', function (error, image) {
				if (error) throw error;
				map.addImage('cat', image);
			});
			map.addLayer({
				'id': 'unclustered-points',
				'type': 'symbol',
				'source': 'earthquakes',
				'layout': {
					'icon-image': 'cat',
					'icon-size': 1
				},
				'properties': {
					iconAllowOverlap: false
				}
			});
			// Display the earthquake data in three layers, each filtered to a range of
			// count values. Each range gets a different fill color.
			var layers = [
				[20, '#f28cb1'],
				[10, '#f1f075'],
				[0, '#51bbd6']
			];
			layers.forEach(function (layer, i) {
				map.addLayer({
					'id': 'cluster-' + i,
					'type': 'circle',
					'source': 'earthquakes',
					'paint': {
						'circle-color': layer[1],
						'circle-radius': 18
					},
					'filter': i === 0 ?
						['>=', 'point_count', layer[0]] :
						['all',
							['>=', 'point_count', layer[0]],
							['<', 'point_count', layers[i - 1][0]]]
				});
			});
			// Add a layer for the clusters' count labels
			map.addLayer({
				'id': 'cluster-count',
				'type': 'symbol',
				'source': 'earthquakes',
				'layout': {
					'text-field': '{point_count}',
					'text-font': [
						'DIN Offc Pro Medium',
						'Arial Unicode MS Bold'
					],
					'text-size': 12
				},
				'paint': {
					'text-color': 'black'
				}
			});
		});

		var popup = new mapboxgl.Popup({
			closeButton: false,
			closeOnClick: false
		});

		map.on('mouseenter', 'unclustered-points', function (e) {
			// Change the cursor style as a UI indicator.
			map.getCanvas().style.cursor = 'pointer';
			var coordinates = e.features[0].geometry.coordinates.slice();
			var description = e.features[0].properties;
			// Ensure that if the map is zoomed out such that multiple
			// copies of the feature are visible, the popup appears
			// over the copy being pointed to.
			while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
				coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
			}
			// Populate the popup and set it's coordinates
			// based on the feature found.
			var popupDate = '';
			if (description.date != null) {
				popupDate = moment(description.date).format('MM-DD-YYYY T: h:mm a');
			}
			else {
				popupDate = '';
			}
			let speed = parseFloat(description.speed);
			if (isNaN(speed)) {
				speed = '';
			}
			else {
				speed = speed.toFixed(2) + ' mph';
			}
			let location = formatLocation(description.location);
			popup
				.setLngLat(coordinates)
				.setHTML('<span class="map-popup-label">Truck No. : </span><span class="map-popup-label">' + description.truckNo + '</span></br><span class="map-popup-label1"> Date : </span><span class="map-popup-label">' + popupDate + '</span></br><span class="map-popup-label1">Speed : </span><span class="map-popup-label">' + speed + '</span></br><span class="map-popup-label2">Location : </span><span class="map-popup-label">' + location + '</span></br>')
				.addTo(map);
		});
		map.on('mouseleave', 'unclustered-points', function () {
			map.getCanvas().style.cursor = '';
			popup.remove();
		});


		map.on('mouseleave', 'unclustered-points1', function () {
			map.getCanvas().style.cursor = '';
			popup.remove();
		});

	}
	closeMap = () => {
		this.setState({
			...this.state,
			showMap: false
		});
	}
	render() {
		const { loading } = this.props;
		if (this.props.data.LoadList !== undefined) {
			if (this.currentStatus === STATUS.LOAD.DELIVERY_CONFIRMED) {
				this.MyLoads = this.props.data.LoadList;
			}
			else if (this.currentStatus === STATUS.LOAD.ACCEPTED) {
				this.accepted = this.props.data.LoadList;
			}
			else if (this.currentStatus === STATUS.LOAD.TENDERED) {
				this.tendered = this.props.data.LoadList;
			}
			else if (this.currentStatus === STATUS.LOAD.DISPATCHEDENROUTE) {
				this.dispatched = this.props.data.LoadList;
			}
			else if (this.currentStatus === STATUS.LOAD.DELIVERY_CONFIRMED) {
				this.notyetdispatched = this.props.data.LoadList;
			}
			else if (this.currentStatus === STATUS.LOAD.DELIVERED) {
				this.delivered = this.props.data.LoadList;
			}
		}

		var loadmyLoads = [];
		var loadaccepted = [];
		var loadtendered = [];
		var notyetdispatched = [];
		var dispatched = [];
		var delivered = [];
		var brokerList = [];

		if (Array.isArray(this.props.companyList)) {
			brokerList = this.props.companyList.map(row => {
				row.id = row.company_Id;
				row.address = concat([row.current_Address1, row.current_Address2, row.current_Address3]);
				row.assignBtn = <button className='btn btn-primary' onClick={(e) => this.assignLoad(e, row)} >Assign</button>
				return row;
			});
		}


		if (loading) {
			var indicationhtml =
				<div className='spinner-border' role='status'>
					<div className='row react-bs-table-no-data'></div>
				</div>
		}
		else {
			if (this.MyLoads.length > 0) {
				loadmyLoads = [];
				loadmyLoads = this.MyLoads.map(
					row => {
						var drivername = '';
						if (row.driverName !== null) {
							drivername = row.driverName.toUpperCase()
						} else {
							drivername = '-'
						}
						var stringLoadDate = '';
						if (row.stringLoadDate !== '') {
							stringLoadDate = moment(row.stringLoadDate).format('MM-DD-YYYY');
						}
						else {
							stringLoadDate = '';
						}
						return ({
							id: row.loadId,
							loadNumber: row.customerLoadNumber,
							stringLoadDate: stringLoadDate,
							shipperName: row.shipperName,
							customerName: row.customerName,
							brokerName: row.brokerName,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + ' ' + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							orginCityName: row.orginCityName,
							destinationCityName: row.destinationCityName,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							loadPointsList: row.loadPointsList,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							originState: row.orginStateName,
							destinationState: row.destinationStateName,
							shipperref: row.shipperReferenceNumber,
							assetName: row.assetName,
							driverName: drivername,
							distance: row.distance,
							carrierName: row.carrierName
						});
					}
				);

			}
			else {
				loadmyLoads = [];
				indicationhtml = 'No Data Found';
			}

			if (this.accepted.length > 0) {
				loadaccepted = []
				loadaccepted = this.accepted.map(
					row => {
						var drivername = '';
						if (row.driverName !== null) {
							drivername = row.driverName.toUpperCase()
						} else {
							drivername = '-'
						}
						var stringLoadDate = '';
						if (row.stringLoadDate !== '') {
							stringLoadDate = moment(row.stringLoadDate).format('MM-DD-YYYY');
						}
						else {
							stringLoadDate = '';
						}
						return ({
							id: row.loadId,
							loadNumber: row.customerLoadNumber,
							stringLoadDate: stringLoadDate,
							shipperName: row.shipperName,
							customerName: row.customerName,
							brokerName: row.brokerName,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + ' ' + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCityName: row.orginCityName,
							carrierName: row.carrierName,
							destinationCityName: row.destinationCityName,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							loadPointsList: row.loadPointsList,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							originState: row.orginStateName,
							destinationState: row.destinationStateName,
							shipperref: row.shipperReferenceNumber,
							distance: row.distance,
							assetName: row.assetName,
							driverName: drivername
						});
					}
				);
			}
			else {
				loadaccepted = [];
				indicationhtml = 'No Data Found';
			}

			if (this.tendered.length > 0) {
				loadtendered = []
				loadtendered = this.tendered.map(
					row => {
						var drivername = '';
						if (row.driverName !== null) {
							drivername = row.driverName.toUpperCase()
						} else {
							drivername = '-'
						}
						var stringLoadDate = '';
						if (row.stringLoadDate !== '') {
							stringLoadDate = moment(row.stringLoadDate).format('MM-DD-YYYY');
						}
						else {
							stringLoadDate = '';
						}
						return ({
							id: row.loadId,
							loadNumber: row.customerLoadNumber,
							stringLoadDate: stringLoadDate,
							shipperName: row.shipperName,
							customerName: row.customerName,
							carrierName: row.carrierName,
							brokerName: row.brokerName,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + ' ' + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCityName: row.orginCityName,
							destinationCityName: row.destinationCityName,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							action: <a href='/#' className='btn_carrier_move btn btn-sm btn-primary'>Status</a>,
							loadPointsList: row.loadPointsList,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							originState: row.orginStateName,
							destinationState: row.destinationStateName,
							shipperref: row.shipperReferenceNumber,
							assetName: row.assetName,
							driverName: drivername,
							distance: row.distance
						});
					}
				);
			}
			else {
				loadtendered = [];
				indicationhtml = 'No Data Found';
			}

			if (this.notyetdispatched.length > 0) {
				var notyetdispatched = []
				notyetdispatched = this.notyetdispatched.map(
					row => {
						var drivername = '';
						if (row.driverName !== null) {
							drivername = row.driverName.toUpperCase()
						} else {
							drivername = '-'
						}
						var stringLoadDate = '';
						if (row.stringLoadDate !== '') {
							stringLoadDate = moment(row.stringLoadDate).format('MM-DD-YYYY');
						}
						else {
							stringLoadDate = '';
						}
						return ({
							id: row.loadId,
							loadNumber: row.customerLoadNumber,
							stringLoadDate: stringLoadDate,
							shipperName: row.shipperName,
							customerName: row.customerName,
							carrierName: row.carrierName,
							brokerName: row.brokerName,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + ' ' + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCityName: row.orginCityName,
							destinationCityName: row.destinationCityName,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							action: <a href='/#' className='btn_carrier_move btn btn-sm btn-primary'>Status</a>,
							loadPointsList: row.loadPointsList,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							originState: row.orginStateName,
							destinationState: row.destinationStateName,
							shipperref: row.shipperReferenceNumber,
							assetName: row.assetName,
							driverName: drivername,
							distance: row.distance
						});
					}
				);
			}
			else {
				notyetdispatched = [];
				indicationhtml = 'No Data Found';
			}

			if (this.dispatched.length > 0) {
				var dispatched = []
				dispatched = this.dispatched.map(
					row => {
						var drivername = '';
						if (row.driverName !== null) {
							drivername = row.driverName.toUpperCase()
						} else {
							drivername = '-'
						}
						var stringLoadDate = '';
						if (row.stringLoadDate !== '') {
							stringLoadDate = moment(row.stringLoadDate).format('MM-DD-YYYY');
						}
						else {
							stringLoadDate = '';
						}


						var pickUpdate = '';
						var pickUpTime = '';
						if (row.loadPointsList.length > 0) {
							if (row.loadPointsList.length === 1) {
								if (row.loadPointsList[0].loadPointTypeId === 2) {
									if (row.loadPointsList[0].earliestTime !== '') {
										 pickUpdate = moment(row.loadPointsList[0].earliestTime).format('MM-DD-YYYY');
										 pickUpTime = moment(row.loadPointsList[0].earliestTime).format('HH:mm');
									}
								}
							}
							else if (row.loadPointsList.length === 2) {
								if (row.loadPointsList[0].loadPointTypeId === 2) {
									if (row.loadPointsList[0].earliestTime !== '') {
										 pickUpdate = moment(row.loadPointsList[0].earliestTime).format('MM-DD-YYYY');
										 pickUpTime = moment(row.loadPointsList[0].earliestTime).format('HH:mm');
									}
								}
								else if (row.loadPointsList[1].loadPointTypeId === 2) {
									if (row.loadPointsList[1].earliestTime !== '') {
										 pickUpdate = moment(row.loadPointsList[1].earliestTime).format('MM-DD-YYYY');
										 pickUpTime = moment(row.loadPointsList[1].earliestTime).format('HH:mm');
									}
								}
							}
						}

						return ({
							id: row.loadId,
							loadNumber: row.customerLoadNumber,
							loadNumberLink: <a href='/#' onClick={(e) => this.openMap(row)} >{row.customerLoadNumber}</a>,
							stringLoadDate: stringLoadDate,
							pickUpdate: pickUpdate,
							pickUpTime: pickUpTime,
							shipperName: row.shipperName,
							customerName: row.customerName,
							carrierName: row.carrierName,
							brokerName: row.brokerName,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + ' ' + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCityName: row.orginCityName,
							destinationCityName: row.destinationCityName,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							action: <a href='/#' className='btn_carrier_move btn btn-sm btn-primary'>Status</a>,
							loadPointsList: row.loadPointsList,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							originState: row.orginStateName,
							destinationState: row.destinationStateName,
							shipperref: row.shipperReferenceNumber,
							assetName: row.assetName,
							driverName: drivername,
							distance: row.distance,
							location: row.assetLocation
						});
					}
				);
			}
			else {
				dispatched = [];
				indicationhtml = 'No Data Found';
			}

			if (this.delivered.length > 0) {
				delivered = []
				delivered = this.delivered.map(
					row => {
						var drivername = '';
						if (row.driverName !== null) {
							drivername = row.driverName.toUpperCase()
						} else {
							drivername = '-'
						}
						var stringLoadDate = '';
						if (row.stringLoadDate !== '') {
							stringLoadDate = moment(row.stringLoadDate).format('MM-DD-YYYY');
						}
						else {
							stringLoadDate = '';
						}
						var deliverydate = '';
						var deliveryTime = '';
						if (row.loadPointsList.length > 0) {
							if (row.loadPointsList.length === 1) {
								if (row.loadPointsList[0].loadPointTypeId === 3) {
									if (row.loadPointsList[0].latestTime !== '') {
										 deliverydate = moment(row.loadPointsList[0].latestTime).format('MM-DD-YYYY');
										 deliveryTime = moment(row.loadPointsList[0].latestTime).format('HH:mm');
									}
								}
							}
							else if (row.loadPointsList.length === 2) {
								if (row.loadPointsList[0].loadPointTypeId === 3) {
									if (row.loadPointsList[0].latestTime !== '') {
										 deliverydate = moment(row.loadPointsList[0].latestTime).format('MM-DD-YYYY');
										 deliveryTime = moment(row.loadPointsList[0].latestTime).format('HH:mm');
									}
								}
								else if (row.loadPointsList[1].loadPointTypeId === 3) {
									if (row.loadPointsList[1].latestTime !== '') {
										 deliverydate = moment(row.loadPointsList[1].latestTime).format('MM-DD-YYYY');
										 deliveryTime = moment(row.loadPointsList[1].latestTime).format('HH:mm');
									}
								}
							}
						}
						return ({
							id: row.loadId,
							loadNumber: row.customerLoadNumber,
							stringLoadDate: stringLoadDate,
							deliverydate: deliverydate,
							deliveryTime: deliveryTime,
							shipperName: row.shipperName,
							carrierName: row.carrierName,
							customerName: row.customerName,
							brokerName: row.brokerName,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + ' ' + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCityName: row.orginCityName,
							destinationCityName: row.destinationCityName,
							originState: row.orginStateName,
							destinationState: row.destinationStateName,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							action: <a href='/#' className='btn_carrier_move btn btn-sm btn-primary'>Status</a>,
							loadPointsList: row.loadPointsList,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							shipperref: row.shipperReferenceNumber,
							assetName: row.assetName,
							driverName: drivername,
							distance: row.distance
						});
					}
				);
			}
			else {
				delivered = [];
				indicationhtml = 'No Data Found';
			}
		}
		const { SearchBar } = Search;
		const columns = [
			{
				text: 'Customer Load #',
				dataField: 'loadNumber',
				sort: true
			},
			{
				text: 'Date',
				dataField: 'stringLoadDate',
				sort: true
			},
			{
				text: 'Carrier',
				sort: true,
				dataField: 'brokerName'
			},
			{
				text: 'Truck No',
				sort: true,
				dataField: 'assetName'
			},
			{
				text: 'Distance',
				dataField: 'distance',
				sort: true
			},
			{
				text: 'Pick up City',
				dataField: 'orginCityName',
				sort: true
			},
			{
				text: 'Pick up State',
				dataField: 'originState',
				sort: true
			},
			{
				text: 'Dest. City',
				dataField: 'destinationCityName',
				sort: true
			},
			{
				text: 'Dest. State',
				dataField: 'destinationState',
				sort: true
			}
		]
		const brokerColumns = [
			{
				text: 'Company',
				dataField: 'company_Name',
				sort: true
			},
			{
				text: 'Speciality',
				dataField: '',
				sort: true
			},
			{
				text: 'Email',
				dataField: 'company_Email',
				sort: true
			},
			{
				text: 'Phone',
				dataField: 'phone_Number',
				sort: true
			},
			{
				text: 'Address',
				dataField: 'address',
				sort: true
			},
			{
				text: 'Pick up City',
				dataField: 'originCityName',
				sort: true
			},
			{
				text: 'Pick up State',
				dataField: 'originState',
				sort: true
			},
			{
				text: 'Dest. City',
				dataField: 'destinationCityName',
				sort: true
			},
			{
				text: 'Dest. State',
				dataField: 'destinationState',
				sort: true
			},
			{
				text: '',
				dataField: 'assignBtn',
				headerStyle: { width: '6%' }
			}
		];

		const accptdcolumns = [
			{
				text: 'Customer Load #',
				dataField: 'loadNumberLink',
				sort: true,
			},
			{
				text: 'Carrier',
				sort: true,
				dataField: 'brokerName'
			},
			{
				text: 'Pick up Date',
				sort: true,
				dataField: 'pickUpdate'
			},
			{
				text: 'Time',
				sort: true,
				dataField: 'pickUpTime'
			},
			{
				text: 'Pick up City',
				dataField: 'originCityName',
				sort: true
			},
			{
				text: 'Pick up State',
				dataField: 'originState',
				sort: true
			},
			{
				text: 'Dest. City',
				dataField: 'destinationCityName',
				sort: true
			},
			{
				text: 'Dest. State',
				dataField: 'destinationState',
				sort: true
			},
			{
				text: 'Current Location',
				dataField: 'location',
				sort: true
			},
			{
				text: 'ETA',
				dataField: 'eta',
				sort: true
			},
			{
				text: 'Status',
				dataField: 'status',
				sort: true
			}
		]

		const DeliveredColumns = [
			{
				text: 'Customer Load #',
				dataField: 'loadNumber',
				sort: true,
			},
			{
				text: 'Carrier',
				sort: true,
				dataField: 'brokerName'
			},
			{
				text: 'Delivery Date',
				sort: true,
				dataField: 'deliverydate'
			},
			{
				text: 'Time',
				sort: true,
				dataField: 'deliveryTime'
			},
			{
				text: 'Truck No',
				sort: true,
				dataField: 'assetName'
			},
			{
				text: 'Pick up City',
				dataField: 'originCityName',
				sort: true
			},
			{
				text: 'Pick up State',
				dataField: 'originState',
				sort: true
			},
			{
				text: 'Dest. City',
				dataField: 'destinationCityName',
				sort: true
			},
			{
				text: 'Dest. State',
				dataField: 'destinationState',
				sort: true
			},
			{
				text: 'Status',
				dataField: 'commodity',
				sort: true
			},
		]

		const expandRow2 = {
			renderer: row => (
				<article className='table-data truck-data shadow bg-white rounded'>
					<div className='row no-gutters'>
						<div className='col-md-12'>
							<ul className='nav nav-tabs' id='myTrucks' role='tablist'>
								<li className='nav-item'>
									<a className='nav-link active' id={`pickup-tab_${row.id}`} data-toggle='tab' href={`#pickup_${row.id}`} role='tab' aria-controls='pickup' aria-selected='true' >
										pickup Details
									</a>
								</li>
								<li className='nav-item'>
									<a className='nav-link' id='delivery-tab' data-toggle='tab' href={`#delivery_${row.id}`} role='tab' aria-controls='delivery' aria-selected='false' >
										Delivery Details
									</a>
								</li>
							</ul>
							<div className='tab-content' id='myTabContent'>
								<div className='tab-pane fade show active' id={`pickup_${row.id}`} role='tabpanel' aria-labelledby='pickup-tab'>
									<table className='table table-sm'>
										<thead>
											<tr>
												<th scope='col'>Address</th>
												<th scope='col'>Contact</th>
												<th scope='col'>Earliest Time</th>
												<th scope='col'>Latest Time</th>
											</tr>
										</thead>
										<tbody>
											{
												row.loadPointsList.length ? (row.loadPointsList.map(function (doc, index) {
													if (doc.loadPointTypeId === 2) {
														var Contact = (doc.contactAddress1 == null ? '' : doc.contactAddress1);
														Contact = (Contact === '' ? '' : (Contact + ', ')) + (doc.contactAddress2 == null ? '' : doc.contactAddress2);
														Contact = (Contact === '' ? '' : (Contact + ', ')) + (doc.contactZip == null ? '' : doc.contactZip);
														return (
															<tr key={index}>
																<td>{(Contact === null) ? <i>&nbsp;</i> : (Contact)}</td>
																<td>{(doc.contactNumber === null) ? <i>&nbsp;</i> : (doc.contactNumber)}</td>
																<td>{(doc.earliestTime === null) ? <i>&nbsp;</i> : (doc.earliestTime)}</td>
																<td>{(doc.latestTime === null) ? <i>&nbsp;</i> : (doc.latestTime)}</td>
															</tr>
														)
													}
												})) : (<tr><td className='text-center' colspan='4'>No Record Found</td></tr>)
											}
										</tbody>
									</table>
								</div>
								<div className='tab-pane fade' id={`delivery_${row.id}`} role='tabpanel' aria-labelledby='delivery-tab'>
									<table className='table table-sm'>
										<thead>
											<tr>
												<th scope='col'>Address</th>
												<th scope='col'>Contact</th>
												<th scope='col'>Earliest Time</th>
												<th scope='col'>Latest Time</th>
											</tr>
										</thead>
										<tbody>
											{
												row.loadPointsList.length ? (row.loadPointsList.map(function (doc, index) {
													if (doc.loadPointTypeId === 3) {
														var Contact = (doc.contactAddress1 == null ? '' : doc.contactAddress1);
														Contact = (Contact === '' ? '' : (Contact + ', ')) + (doc.contactAddress2 == null ? '' : doc.contactAddress2);
														Contact = (Contact === '' ? '' : (Contact + ', ')) + (doc.contactZip == null ? '' : doc.contactZip);
														return (
															<tr key={index}>
																<td>{(Contact === null) ? <i>&nbsp;</i> : (Contact)}</td>
																<td>{(doc.contactNumber === null) ? <i>&nbsp;</i> : (doc.contactNumber)}</td>
																<td>{(doc.earliestTime === null) ? <i>&nbsp;</i> : (doc.earliestTime)}</td>
																<td>{(doc.latestTime === null) ? <i>&nbsp;</i> : (doc.latestTime)}</td>
															</tr>
														)
													}
												})) : (<tr><td className='text-center' colspan='4'>No Record Found</td></tr>)
											}
										</tbody>
									</table>
								</div>
								<div className='tab-pane fade' id={`brokertender_${row.id}`} role='tabpanel' aria-labelledby='brokertender-tab'>
									<table className='table table-sm'>
										<thead>
											<tr>
												<th scope='col'>Base Charge</th>
												<th scope='col'>Fuel Charge</th>
												<th scope='col'>Accessorial Charge</th>
												<th scope='col'>Tendered Price</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>{(row.basecharge === null) ? <i>&nbsp;</i> : (row.basecharge)}</td>
												<td>{(row.fulecharge === null) ? <i>&nbsp;</i> : (row.fulecharge)}</td>
												<td>{(row.accessiorialcharge === null) ? <i>&nbsp;</i> : (row.accessiorialcharge)}</td>
												<td>{(row.tenderprice === null) ? <i>&nbsp;</i> : (row.tenderprice)}</td>
											</tr>
										</tbody>
									</table>
								</div>

							</div>
						</div>
					</div>
				</article>
			),
			showExpandColumn: true,
			expandByColumnOnly: true,
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return (
						<i className='icofont-minus-circle'></i>
					);
				}
				return (
					<i className='icofont-plus-circle'></i>
				);
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<i className='icofont-minus-circle'></i>
					);
				}
				return (
					<i className='icofont-plus-circle'></i>
				);
			}
		};
		const customTotal = (from, to, size) => (
			<span className='react-bootstrap-table-pagination-total'>
				Showing {from} to {to} of {size} Results
			</span>
		);

		const options = {
			paginationSize: 4,
			pageStartIndex: 1,
			firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: customTotal,
			// sizePerPageList: [{  //  ----> this is example
			//   text: '5', value: 5
			// }, {
			//   text: '10', value: 10
			// }, {
			//   text: 'All', value: products.length
			// }] // A numeric array is also available. the purpose of above example is custom the text
		};



		return (
			<article className='table-data truck-data shadow bg-white rounded'>
				<div className='row no-gutters'>
					<div className='col-md-12'>
						<div className='row'>
							<div className='col-md-2'>
							</div>
							<div className='col-md-10'>
								<div className='d-flex justify-content-end'>
									<ul className='ul-horizontal tab-menus'>
									</ul>
								</div>
							</div>
						</div>
						<TabsBar tabsData={
							[
								{ component: 'ASSIGNED', tabID: 'myload-tab', refData: '#myload', methodName: (e, data, index) => { this.tabChange(STATUS.LOAD.DELIVERY_CONFIRMED) } },
								{ component: 'DISPATCHED ', tabID: 'dispatched-tab', refData: '#dispatched', methodName: (e, data, index) => { this.tabChange(STATUS.LOAD.DISPATCHEDENROUTE) } },
								{ component: 'DELIVERED', tabID: 'delivered-tab', refData: '#delivered', methodName: (e, data, index) => { this.tabChange(STATUS.LOAD.DELIVERED) } }
							]}
							getIndex={this.getIndex}
							activeTab={'#requested'}
						/>
						<div className='tab-content' id='myTabContent'>
							<div className='tab-pane fade show active' id='myload' role='tabpanel' aria-labelledby='myload-tab'>
								<ToolkitProvider
									keyField='id'
									data={loadmyLoads}
									columns={columns}
									search
								>
									{
										props => (
											<div>
												<SearchBar {...props.searchProps} />
												<BootstrapTable ref={n => this.node = n} keyField='id' headerWrapperClasses='foo'
													{...props.baseProps} pagination={paginationFactory(options)} noDataIndication={indicationhtml} hover expandRow={expandRow2}
													bordered={false}
												/>
											</div>
										)
									}
								</ToolkitProvider>
							</div>
							<div className='tab-pane fade' id='accepted' role='tabpanel' aria-labelledby='accepted-tab'>
								<TableDataComponent tableData={loadaccepted} columnData={accptdcolumns} noDataIndication={indicationhtml} expandRow={expandRow2} />
							</div>
							<div className='tab-pane fade' id='tendered' role='tabpanel' aria-labelledby='tendered-tab'>
								<TableDataComponent tableData={loadtendered} columnData={accptdcolumns} noDataIndication={indicationhtml} expandRow={expandRow2} />
							</div>

							<div className='tab-pane fade' id='notyetdispatched' role='tabpanel' aria-labelledby='notyetdispatched-tab'>
								<TableDataComponent tableData={notyetdispatched} columnData={accptdcolumns} noDataIndication={indicationhtml} expandRow={expandRow2} />
							</div>

							<div className='tab-pane fade' id='dispatched' role='tabpanel' aria-labelledby='dispatched-tab'>
								<TableDataComponent tableData={dispatched} columnData={accptdcolumns} noDataIndication={indicationhtml} expandRow={expandRow2} />
							</div>

							<div className='tab-pane fade' id='delivered' role='tabpanel' aria-labelledby='delivered-tab'>
								<TableDataComponent tableData={delivered} columnData={DeliveredColumns} noDataIndication={indicationhtml} expandRow={expandRow2} />
							</div>

							<div className='tab-pane fade' id='delivery_uc' role='tabpanel' aria-labelledby='delivery_uc_load-tab'>
							</div>
							<div className='tab-pane fade' id='delivery_c' role='tabpanel' aria-labelledby='delivery_c_load-tab'>
								<table className='table table-sm'>
									<thead>
										<tr>
											<th scope='col'></th>
											<th scope='col'></th>
											<th scope='col'>Customer Load #</th>
											<th scope='col'>Truck #</th>
											<th scope='col'>Bill Miles</th>
											<th scope='col'>Location</th>
											<th scope='col'>Dispatcher</th>
											<th scope='col'>Driver</th>
											<th scope='col'>Driver Pay</th>
											<th scope='col'>HOS</th>
											<th scope='col'>Shipper</th>
											<th scope='col'>Consignee</th>
											<th scope='col'>Action</th>
										</tr>
									</thead>
									<tbody>
									</tbody>
								</table>


							</div>
							<div className='tab-pane fade' id='bamload' role='tabpanel' aria-labelledby='bamload-tab'>
								<table className='table table-sm'>
									<thead>
										<tr>
											<th scope='col'></th>
											<th scope='col'>Customer Load #</th>
											<th scope='col'>Date</th>
											<th scope='col'>Shipper</th>
											<th scope='col'>Carrier</th>
											<th scope='col'>Broker</th>
											<th scope='col'>Rate</th>
											<th scope='col'>Fuel Advance</th>
											<th scope='col'>Fuel Advance Commission</th>
											<th scope='col'>Broker Net</th>
											<th scope='col'>Total Broker Net</th>
											<th scope='col'>Weight</th>
											<th scope='col'>Equipment</th>
											<th scope='col'>Origin</th>
											<th scope='col'>Destination</th>
										</tr>
									</thead>
									<tbody>
									</tbody>
								</table>


							</div>
							<div className='tab-pane fade' id='paid_close' role='tabpanel' aria-labelledby='paid_close_load-tab'>
								<table className='table table-sm'>
									<thead>
										<tr>
											<th scope='col'></th>
											<th scope='col'></th>
											<th scope='col'>Customer Load #</th>
											<th scope='col'>Truck #</th>
											<th scope='col'>Bill Miles</th>
											<th scope='col'>Location</th>
											<th scope='col'>Dispatcher</th>
											<th scope='col'>Driver</th>
											<th scope='col'>Driver Pay</th>
											<th scope='col'>HOS</th>
											<th scope='col'>Shipper</th>
											<th scope='col'>Consignee</th>
											<th scope='col'>Action</th>
										</tr>
									</thead>
									<tbody>
									</tbody>
								</table>
							</div>
						</div>

					</div>
				</div>

				<CancelledLoadpopup loadlistval={this.cancelled} />
				<Modal show={this.state.postOrder} onHide={this.postOrderClose} dialogClassName='modal-90w'>
					<Modal.Header closeButton>
						<Modal.Title>Brokers</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<ToolkitProvider
							keyField='id'
							data={brokerList}
							columns={brokerColumns}
							search
						>
							{
								props => (
									<div>
										<SearchBar {...props.searchProps} />
										<BootstrapTable keyField='id'
											{...props.baseProps} pagination={paginationFactory(options)} noDataIndication={indicationhtml} hover bordered={false} />
									</div>
								)
							}
						</ToolkitProvider>
					</Modal.Body>
				</Modal>
				<Modal show={this.state.showMap} onHide={this.closeMap} dialogClassName='modal-90w modal-map'>
					<Modal.Header closeButton>
						<Modal.Title>Vehicle Location</Modal.Title>
					</Modal.Header>
					<Modal.Body >
						<div id='map-canvas' className='map-container'></div>
					</Modal.Body>
				</Modal>
			</article>
		)
	}
}
const mapStateToProps = state => {
	return {
		data: state.loadReducer.data,
		companyList: state.loadReducer.companyList,
		editvaluesview: state.loadReducer.editvaluesview,
		loading: state.loadReducer.loading,
		error: state.loadReducer.error,
		LoadPostOrder: state.loadReducer.LoadPostOrder
	}
}
export default connect(mapStateToProps)(LoadList);