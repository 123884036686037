import React, { Component } from 'react';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { connect } from 'react-redux';
import { assetlist, companyAuthEld, dropdownEldlist } from '../../../actions/assetAction';
import EditTruckPopup from './EditTruckPopup';
import { withRouter } from 'react-router-dom';
import $ from 'jquery'
import ViewTruckPopup from './ViewTruckPopup';
import { TableDataComponent,GetEld } from 'UI-Component';

class TrucksList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageReady: false,
			newUserPopup: false,
			userData: null,
			title: ''
		}
	}
	componentDidMount() {
		const params = {
			assettype: 'truck',
		}
		this.props.dispatch(assetlist(params));
	}
	state = {
		modaldata: []
	}
	getModal = (tList) => {
		this.props.dispatch(companyAuthEld());
		this.setState({ showModal: true, modaldata: tList });
	};
	truckAdd = () => {
		this.props.dispatch(companyAuthEld());
	}
	eldSelect() {
		let id = $('#findELDId').val();
		let params = {
			assettype: 'truck',
			eldProviderId: id
		}
		this.props.dispatch(dropdownEldlist(params));
	}
	getEldProviderDropdown = () => {
		return <GetEld id='findELDId' state={this.state} selectELD={this.props?.trucks}  onChange={(e) => { this.eldSelect(e) }} />
	}
	viewTruck = (rowdata) => {
		this.setState(
			{
				...this.state,
				newUserPopup: true,
				userData: rowdata,
				title: 'Trucks Info'
			}
		);
	}
	closeNewUser = () => {
		this.setState(
			{
				...this.state,
				newUserPopup: false,
				userData: null,
				title: ''
			}
		);
	}
	render() {
		var products = [];
		var indicationhtml = 'No Data Found';
		if (this.props.loading) {
			indicationhtml =
				<div className='spinner-border' role='status'>
					<div className='row react-bs-table-no-data'></div>
				</div>
		}
		else if (this.props.trucks.assetList) {
			products = this.props.trucks.assetList.map(
				row => {
					return ({
						id: row.assetId,
						Device_ID: row.assetSerialNo,
						Asset_Name: row.assetName,
						Asset_Type: row.assetType,
						Truck: row.assetNumber,
						Year: row.modelYear,
						Make: row.make,
						Model: row.model,
						VIN: row.identificationNo,
						Plate: row.licensePlateNo,
						Color: row.color,
						More: <i onClick={(e) => { this.viewTruck(row) }} className='icofont-eye-alt'></i>
					});
				}
			);
		}
		else {
			products = [];
			indicationhtml = 'No Data Found';
		}
		const columns = [
			{
				dataField: 'id',
				text: 'TC Asset ID',
				sort: true
			},
			{
				dataField: 'Truck',
				text: 'Truck/Asset No',
				sort: true
			},
			{
				dataField: 'Plate',
				text: 'License Plate No',
				sort: true
			},
			{
				dataField: 'VIN',
				text: 'VIN',
				sort: true
			},
			{
				dataField: 'Device_ID',
				text: 'Device ID',
				sort: true
			},
			{
				dataField: 'Asset_Name',
				text: 'Asset Name',
				sort: true
			},
			{
				dataField: 'Asset_Type',
				text: 'Asset Type ',
				sort: true
			},
			{
				dataField: 'More',
				text: 'More'
			}]
		return (
			<article className='table-data truck-data shadow bg-white rounded'>
				<div className='row no-gutters'>
					<div className='col-md-12'>
						<ul className='nav nav-tabs' id='myTrucks' role='tablist'>
							<li className='nav-item'>
								<a className='nav-link active' id='mytruck-tab' data-toggle='tab' href='#mytruck' role='tab' aria-controls='mytruck' aria-selected='true'><i className='icofont-truck-loaded'></i>My Trucks</a>
							</li>
						</ul>
						{this.getEldProviderDropdown()}
						<div className='tab-content' id='myTabContent'>
							<div className='tab-pane fade show active' id='mytruck' role='tabpanel' aria-labelledby='mytruck-tab'>
								<TableDataComponent tableData={products} columnData={columns} noDataIndication={indicationhtml} />
							</div>
							<div className='tab-pane fade' id='newtruck' role='tabpanel' aria-labelledby='newtruck-tab'>
							</div>
							<EditTruckPopup trucktype={this.props.truck} state={this.props.state} fueltypelist={this.props.fueltypelist} eldprovider={this.props.eld} modalvalues={this.state.modaldata} />
							<ViewTruckPopup newUserPopup={this.state.newUserPopup} closeNewUser={(flag) => { this.closeNewUser(flag) }} userData={this.state.userData} title={this.state.title} />
						</div>
					</div>
				</div>
			</article>
		)
	}
}

const ShowTheLocationWithRouter = withRouter(TrucksList);

const mapStateToProps = (state) => {
	return {
		trucks: state.assetReducer.data,
		companyEldlist: state.assetReducer.companyEldlist,
		loading: state.assetReducer.loading,
		pagination: state.assetReducer.pagination,
		error: state.assetReducer.error
	}
}
export default connect(mapStateToProps)(ShowTheLocationWithRouter)