import * as types from './index';

export const taskList = (payload) => {
    return {
        type: types.TASK_LIST,
        payload: payload
    }
};

export const TaskAdd = (payload) => {
    return {
        type: types.TASK_ADD,
        payload: payload
    }
};

export const TaskChangeStatus = (payload) => {
    return {
        type: types.TASK_CHANGE_STATUS,
        payload: payload
    }
};






