import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Field, reduxForm } from 'redux-form';
import { savePod,dispatchflag,loadAction } from '../../../actions/loadActions';
import { renderPodNumber } from '../../../helpers/FormControls';
import { validate } from './validation';
import { STATUS } from '../../../helpers/status';
function submit(values,params) {   
    const frmdata = {};
    frmdata.loadId= (values.loadId) ? (values.loadId) : ("");
    frmdata.POD_NO= (values.loadId) ? (values.POD_NO) : ("");
    this.props.dispatch(savePod(frmdata)); 
  }

class ProofOfDelivery extends Component
{
    constructor(props){
        super(props);
        this.state = {
          files: ''
        };
      }

    handleFile(fieldName, event) {
        event.preventDefault();
        var fd = new FormData(); 
        var files = event.target.files[0]; 
        fd.append('file', files);
        this.setState({
            files: fd
        });
    }

   renderFileInput = ({ input, type,id, meta: { touched, error } }) => {
        const { mimeType } = this.props;
        return (
          <div>
            <input
              name={input.name}
              type={type}
              id={id}
              onChange={this.handleFile.bind(this, 'image')}
            />
             {touched && (error && <span className="error">{error}</span>)}
          </div>
        );
      };

      componentDidUpdate()
	{
		if(this.props.changeloadstatusupdateflag==true)
		{
			if(this.props.data.message=="Load PDO saved Successfully")
			{
				toastr.success('Success', 'Load POD saved Successfully!');
				this.props.dispatch(dispatchflag());
				const params={
					...STATUS.LOAD.DISPATCHEDENROUTE
				}
                this.props.dispatch(loadAction(params));
                document.getElementById("podcarrier").click();
		   }
	   }
	}

    render(){
        const { handleSubmit, pristine, reset, submitting,modalvalues,itemsCountPerPage,activePage } = this.props
        return(            
        <div id="proofofdeliveryPopup" className="modal fade bd-editload-modal-sm"  role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg proofofdelivery">
                <div className="modal-content">   
                <form name="ManageLoadsForm" id="proofOfForm" method="post" onSubmit={handleSubmit(submit.bind(this))} className="mb-0" autoComplete="off" encType="multipart/form-data">
                      
                <div className="modal-header">
                   <h5 className="modal-title doc-color" id="exampleModalLabel">Proof Of Delivery</h5>
                    <button type="button" id="podcarrier" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                   </div>
               <div className="modal-body" id="favourite-loads-container">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-md-4 col-sm-4 col-xs-12 lbl">
                        <label>
                            Load #
                        </label>
                        </div>
                        <div className="col-md-8 col-sm-8 col-xs-12">
                          <label>{this.props.loadDetails.loadNumber}</label>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-md-4 col-sm-4 col-xs-12 lbl">
                        <label>
                            POD #
                        </label>
                        </div>
                        <div className="col-md-8 col-sm-8 col-xs-12">
                           <Field type="text" className="form-control" name="POD_NO" id="POD_NO" component={renderPodNumber}/>  
                           <input type="hidden" name="loadId"/>                       
                        </div>
                    </div>
                </div>
                <br/>
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-md-4 col-sm-4 col-xs-12 lbl">
                        <label>
                            POD Image
                        </label>
                        </div>
                        <div className="col-md-8 col-sm-8 col-xs-12">
                         <Field
                                name="podfilename"
                                type="file"
                                id="podfileid"
                                component={this.renderFileInput}
                          />
                          {/* <input type='file' name="POD_REF" onChange={this.handleFile.bind(this, 'image')} /> */}
                       </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button type="submit" className="btn btn-primary btn-sm float-right">Save</button>
            </div>
           </form>               
                </div>
            </div>
        </div>
        )
    }
}

const PageOneForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    })(ProofOfDelivery)

  const mapStateToProps = (state,ownProps) => { 
    return{
        data: state.loadReducer.data,
        form: 'poddetails',
        initialValues: ownProps.loadDetails,
        changeloadstatusupdateflag:state.loadReducer.changeloadstatusupdateflag,
        validate,      
        enableReinitialize: true 
    }    
  }
  
  const PageOneFormContainer = connect(mapStateToProps)(PageOneForm)
    
  export default PageOneFormContainer