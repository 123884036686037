import * as types from './index';

export const truckAction = (payload) => {
    return {
        type: types.GET_TRUCK_DATA,
        payload: payload
    }
};

export const addNewTruck = (payload) => {
	return {
		type: types.POST_NEW_TRUCK_DATA,
		payload: payload
	}
};

// export const UpdateTruck = (payload) => {
// 	return {
// 		type: types.POST_UPDATE_TRUCK_DATA,
// 		payload: payload
// 	}
// };

export const EditTruck = (payload) => {
	return {
		type: types.EDIT_TRUCK_DATA,
		payload: payload
	}
};

export const Viewtrucks = (payload) => {
	return {
		type: types.VIEW_TRUCK_DATA,
		payload: payload
	}
};

export const searchtrucks = (payload) => {
    return {
        type: types.SEARCH_TRUCK_DATA,
        payload: payload
    }
};

export const truckList = () => {
	return {
	  	type: types.GET_TRUCK_LIST
	}
};

export const bulkuploadaction = (payload) => {
    return {
        type: types.TRUCK_BULK_UPLOAD,
        payload: payload
    }
};

