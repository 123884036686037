import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DriverList from '../drivers/DriverList';

class Drivers extends Component{

	render(){
		return(
			<Fragment>
				<DriverList />
			</Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		data: state.userReducer.data,
		loading: state.userReducer.loading,
		error: state.userReducer.error
	}
}

export default connect(mapStateToProps)(Drivers)