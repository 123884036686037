import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { carrierInvitation } from '../../actions/companyActions';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import wave from '../../wave.svg'
import noPhoto from '../../no-photo-available.png'
import { history } from '../../helpers/history';
import { withRouter } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { appBaseUrl, getBaseUrl, getUrlParams } from '../../helpers/appUtil';
import $ from 'jquery';
import Icofont from 'react-icofont';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import FAQModal from '../../helpers/FAQModal';
import NewEld from '../../helpers/NewEld';
import { getDynamicDocumentURL } from '../../services/companyService';
import logo from '../../truckergig-logo.png';

require('dotenv').config();
var localFilteredData;

class CarrierOnBoardNewcopy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedData: [],
            invitationData: {},
            error: '',
            isDataUpdated: false
        }
        this.checkedArray = []
        this.carrierInviteReponse = {};
        this.preChecked = [];
        this.temp = []

    }
    componentDidMount = () => {
        let theme = process.env.REACT_APP_BASE_URL + 'publicresource/getbycode/css?originUrl=' + getBaseUrl();
        $('<link/>', { rel: 'stylesheet', href: theme }).appendTo('head');
        if (this.props.location.search) {
            let urlparam = getUrlParams(this.props.location.search);
            if (urlparam.invitationkey) {
                document.title = appBaseUrl() + 'System';
                this.setState({ invitationkey: urlparam.invitationkey })
            }
            this.carrierInvitation(urlparam.invitationkey);
        }
        else {
            this.props.history.push('/login');
        }
        if (Object.keys(this.props.carrierInvitationRes).length > 0) {
            this.setState({
                invitationData: this.props.carrierInvitationRes
            });
            this.carrierInviteReponse = this.props.carrierInvitationRes;
        }
    }
    onClickAuthcarrierPage() {
        this.props.history.push('/authcarrier', this.state);
    }
    onClickNextPage() {
        for (let i = 0; i < this.state.invitationData.eldInfo.length; i++) {
            if (this.state.invitationData.invitationInfo.latestAttempt === this.state.invitationData.eldInfo[i].eldVendor) {
                let eldInfoarr = []
                eldInfoarr.push(this.state.invitationData.eldInfo[i])
                this.setState({
                    checkedData: eldInfoarr
                }, () => {
                    this.onClickAuthcarrierPage();
                })
            }
        }
    }
    componentWillReceiveProps(prevProps, nextProps) {
        if (prevProps !== nextProps) {
            if (prevProps.carrierInvitationRes.hasOwnProperty('message')) {
                this.setState({ error: prevProps.carrierInvitationRes.message })
                return
            }
            this.carrierInviteReponse = prevProps.carrierInvitationRes;
            localFilteredData = prevProps.carrierInvitationRes;
            this.setState({
                invitationData: prevProps.carrierInvitationRes,
                invitationToken: this.carrierInviteReponse.invitationInfo.invitationToken,
                isDataUpdated: true
            });
            this.preChecked = this.carrierInviteReponse.eldInfo.filter(val => val.overallStatus === 'VERIFIED')
            if (sessionStorage.getItem('hasLocalData')) {
                this.checkedArray = JSON.parse(sessionStorage.getItem('localData'))
                this.checkedArray.forEach(element => {
                    this.temp.push(element)
                });
                this.preChecked.forEach(element => {
                    this.temp.push(element)
                });
                this.temp = this.removeDuplicate(this.temp)
                this.setState({ checkedData: this.temp })
            }
            else {
                this.checkedArray = this.carrierInviteReponse.eldInfo.filter(val => val.overallStatus === 'VERIFIED')
            }
            this.setState({
                checkedData: this.checkedArray
            })
            if (this.props?.history?.location?.state?.dummyData?.checkedData.length > 0) {
                this.checkedArray = this.props?.history?.location?.state?.dummyData?.checkedData
                this.setState({
                    checkedData: this.props?.history?.location?.state?.dummyData?.checkedData
                })
            }
        }
    }
    setELDName(eld_name, eld_id, eldVendor) {
        let eldDetails = { eld_name: eld_name, eld_id: eld_id, eldVendor: eldVendor };
        this.setState({ eldDetails: eldDetails })
    }
    addDefaultSrc(ev) {
        ev.target.src = noPhoto
    }
    diableCheck(data) {
        for (let i = 0; i < this.state.checkedData.length; i++) {
            if (this.state.checkedData[i].eldProviderId === data) {
                return true
            }
        }
    }
    selectedFilterData(data) {
        for (let i = 0; i < this.state.checkedData.length; i++) {
            if (this.state.checkedData[i].eldProviderId === data) {
                return true
            }
        }
    }
    componentDidUpdate() {
        if (this.state.checkedData.length > 4) {
            toastr.info('Info', 'Only 5 elds are to be selected');
        }
    }
    toLower(str) {
        return str = str.toLowerCase();
    }
    carrierInvitation = (urlparam) => {
        this.props.dispatch(carrierInvitation(urlparam))
    }

    displayCompanies() {
        let eldData = []
        const eldListLength = this.state.invitationData.eldInfo.length;
        // let path = 'https://carrierapi.truckercloud.com/api/v1/elds/logo/'
        let path = process.env.REACT_APP_CARRIER_API_URL + 'elds/logo/'
        for (let index = 0; index < eldListLength; index++) {
            eldData.push({
                id: index,
                website_Name:
                    <span key={index} className='website_name'>
                        {
                            this.state.invitationData.eldInfo[index].eldVendor === 'LbTechnology' ?
                                <a href={'https://lbtechnologyinc.com/'} target='_blank' rel='noopener noreferrer'>lbtechnologyinc.com</a>
                                : this.state.invitationData.eldInfo[index].eldVendor === 'OmnitracsVLV' ?
                                    <a href={'https://www.omnitracs.com/'} target='_blank' rel='noopener noreferrer'>omnitracs.com</a>
                                    : this.state.invitationData.eldInfo[index].eldVendor === 'ThreeMD' ?
                                        <a href={'https://3mdinc.com/'} target='_blank' rel='noopener noreferrer'>3mdinc.com</a>
                                        : this.state.invitationData.eldInfo[index].eldVendor === 'EldMandateHOS' ?
                                            <a href={'https://www.eldmandate.com/'} target='_blank' rel='noopener noreferrer'>eldmandate.com</a>
                                            : this.state.invitationData.eldInfo[index].eldVendor === 'SkyBits' ?
                                                <a href={'https://sky-bits.com/'} target='_blank' rel='noopener noreferrer'>sky-bits.com</a>
                                                : this.state.invitationData.eldInfo[index].eldVendor === 'Geotab-ATT' ?
                                                    <a href={'https://www.geotab.com/'} target='_blank' rel='noopener noreferrer'>geotab.com</a>
                                                    : this.state.invitationData.eldInfo[index].eldVendor === 'RandMcNallyDriverTech' ?
                                                        <a href={'https://www.randmcnally.com/'} target='_blank' rel='noopener noreferrer'>randmcnally.com</a>
                                                        : this.state.invitationData.eldInfo[index].eldVendor === 'Motive' ?
                                                            <a href={'https://gomotive.com/'} target='_blank' rel='noopener noreferrer'>gomotive.com</a>
                                                            : this.state.invitationData.eldInfo[index].eldVendor === 'VerizonConnectReveal' ?
                                                                <a href={'https://www.verizonconnect.com/'} target='_blank' rel='noopener noreferrer'>verizonconnect.com</a>
                                                                : this.state.invitationData.eldInfo[index].eldVendor === 'EldMandatePlus' ?
                                                                    <a href={'https://www.eldmandate.biz/'} target='_blank' rel='noopener noreferrer'>eldmandate.biz</a>
                                                                    : this.state.invitationData.eldInfo[index].eldVendor === 'ISAAC' ?
                                                                    <a href={'https://www.isaacinstruments.com/'} target='_blank' rel='noopener noreferrer'>isaacinstruments.com</a>
                                                                    : this.state.invitationData.eldInfo[index].eldVendor === 'Pedigree' ?
                                                                    <a href={'https://pedigreetechnologies.com/'} target='_blank' rel='noopener noreferrer'>pedigreetechnologies.com</a>
                                                                    : <a href={`https://www.${this.state.invitationData.eldInfo[index].eldVendor}.com`} target='_blank' rel='noopener noreferrer'>{this.toLower(this.state.invitationData.eldInfo[index].eldVendor)}.com</a>
                        }
                    </span>,
                faq_credentials: <i className='fa fa-eye info-icon-cob website_name' aria-hidden='true' data-toggle='modal' data-target='#faqModal' onClick={() => { this.setELDName(this.state.invitationData.eldInfo[index].eldVendorDisplayName, this.state.invitationData.eldInfo[index].eldVendorId, this.state.invitationData.eldInfo[index].eldVendor) }}></i>,
                eld_name: <div className='img_block' style={{ opacity: this.state.invitationData.eldInfo[index]?.overallStatus === 'VERIFIED' || (!this.diableCheck(this.state.invitationData.eldInfo[index]?.eldProviderId) && this.state.checkedData.length > 4) ? '.5' : '1' }} key={this.state.invitationData.eldInfo[index]?.eldProviderId} data-tip data-for={'image' + this.state.invitationData.eldInfo[index]?.eldVendor}>
                    <input type='checkbox' name={this.state.invitationData.eldInfo[index]?.eldProviderId}
                        checked={this.state.invitationData.eldInfo[index].overallStatus === 'VERIFIED' || this.selectedFilterData(this.state.invitationData.eldInfo[index]?.eldProviderId)}
                        readOnly={this.state.invitationData.eldInfo[index]?.overallStatus === 'VERIFIED' || (!this.diableCheck(this.state.invitationData.eldInfo[index]?.eldProviderId) && this.state.checkedData.length > 4)}
                        className='chkbox'
                        onChange={(e) => this.handleChange(e, this.state.invitationData.eldInfo[index], index)} />
                    <img src={path + this.state.invitationData.eldInfo[index]?.eldVendor} className='image' onError={this.addDefaultSrc} alt='Not Found'/>
                    <ReactTooltip id={'image' + this.state.invitationData.eldInfo[index]?.eldVendor}>
                        {this.state.invitationData.eldInfo[index]?.eldVendorDisplayName}
                    </ReactTooltip>
                </div>
            })
        }
        return eldData
    }
    carrierCodes() {
        let carrierCode = [];
        carrierCode.push(
            this.state.invitationData.carrierInfo.carrierCodes.map((val, index) => {
                return (
                    <h5 className='list-cont-data' key={index}><span id='iconCOB'>#</span> {val.codeType}-{val.codeValue}</h5>
                )
            })
        )
        return carrierCode
    }
    preventCheck(e, index) {
        if (this.state.invitationData.eldInfo[index]?.overallStatus === 'VERIFIED') {
            e.target.checked = true
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            return false;
        }
        e.target.checked = false
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        return false;

    }

    previousCheck(data) {
        for (let i = 0; i < this.props?.history?.location?.state?.dummyData?.checkedData.length; i++) {
            if (this.props?.history?.location?.state?.dummyData?.checkedData[i].eldProviderId === data) {
                return true
            }
        }
    }
    searchELDs(e) {
        let code = (e.keyCode ? e.keyCode : e.which);
        const input = e.target.value.toLowerCase();
        if (input.length === 0 || code === 27) {
            this.setState(prevState => ({
                invitationData: {
                    ...prevState.invitationData,
                    eldInfo: localFilteredData.eldInfo
                }
            }))
        }
        else {
            const filteredArray = localFilteredData.eldInfo.filter(obj => {
                return obj.eldVendor.toLowerCase().includes(input);
            });
            this.setState(prevState => ({
                invitationData: {
                    ...prevState.invitationData,
                    eldInfo: filteredArray
                }
            }))
        }
    }
    nextPage() {
        history.push('/verify_providers');
    }
    removeDuplicate(data) {
        const unique = [];
        data.map(x => unique.filter(a => a.eldProviderId === x.eldProviderId).length > 0 ? null : unique.push(x));
        return unique;
    }
    handleChange = (e, data, index) => {
        if (this.state.invitationData.eldInfo[index]?.overallStatus === 'VERIFIED' || (!this.diableCheck(this.state.invitationData.eldInfo[index]?.eldProviderId) && this.state.checkedData.length > 4)) {
            this.preventCheck(e, index);
            return
        }
        if (e.target.checked) {
            this.checkedArray.push(data)
        }
        if (e.target.checked === false) {
            let index = this.checkedArray.map(function (x) { return x.eldProviderId; }).indexOf(data.eldProviderId)
            this.checkedArray.splice(index, 1);
        }
        this.checkedArray = this.removeDuplicate(this.checkedArray)
        this.setState({
            checkedData: this.checkedArray
        })
    }
    async callTCURL(eldName){
        let url=`${process.env.REACT_APP_CARRIER_API_URL}carriers/terms-and-conditions/${this.state.invitationkey}/${eldName}`
        let finalLink;
        await getDynamicDocumentURL(url).then((response)=>{
                 finalLink=response.url;
		});
        return await encodeURI(finalLink)
    }
    async authCarrier() {
        for (let index = 0; index < this.state.checkedData.length; index++) {
            const element = this.state.checkedData[index];
            element.tcURL=await this.callTCURL(element.eldVendor)
            for (let index = 0; index < element.eldMetadataParams.length; index++) {
                const element2 = element.eldMetadataParams[index];
                element2.isShowValidation = false;
            }
        }
        sessionStorage.setItem('hasLocalData', JSON.stringify(true))
        sessionStorage.setItem('localData', JSON.stringify(this.state.checkedData))
        history.push('/terms-and-conditons', this.state);
        // history.push('/authcarrier', this.state);
    }
    render() {
        const eldColumns = [
            {
                text: 'ELD',
                dataField: 'eld_name',
                classes: 'custom-td-eldlist'
            },
            {
                text: 'Website',
                dataField: 'website_Name',
                classes: 'custom-td-websitename'
            },
            {
                text: 'FAQ Credentials',
                dataField: 'faq_credentials',
            }
        ];
        if (Object.keys(this.state.invitationData).length > 0) {
            return (
                <article className='parent-container bg-white rounded'>
                    <div class='row parent-row'>
                        <div class='col-sm-3 col-md-3 col-lg-3 col-xs-3 onboard-left-side'>
                            <div className='left-row-img'>
                                <div className='in-cob-img-css'><img src={logo} className='logo-img-css' onError={this.addDefaultSrc}  alt='Not Found'/> </div>
                                <div className='left-row-after-img'>
                                    <div className='list-cont-data'>
                                        <h5 className='list-cont-data'><Icofont icon='icofont-user-alt-3' id='iconCOB' className='cob-icon-css' /> &nbsp;
                                            {this.state.invitationData?.contactInfo?.firstName}&nbsp;{this.state.invitationData?.contactInfo?.lastName}
                                        </h5>
                                    </div>
                                    <div className='list-cont-data'>
                                        <h5 className='list-cont-data'><Icofont icon='icofont-truck-alt' id='iconCOB' className='cob-icon-css' /> &nbsp;
                                            {this.state.invitationData?.carrierInfo?.carrierName}
                                        </h5>
                                    </div>
                                    <div className='list-cont-data'>
                                        <h5 className='list-cont-data'><Icofont icon='icofont-envelope' id='iconCOB' className='cob-icon-css' /> &nbsp;
                                            {this.state.invitationData?.contactInfo?.email}
                                        </h5>
                                    </div>
                                    <div className='list-cont-data'>
                                        {this.carrierCodes()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class='col-sm-9 col-md-9 col-lg-9 col-xs-9 onboard-right-side'>
                            <h5 className='eld-provider-text-css'>ELD Providers</h5>
                            <article className='table-data truck-data  bg-white rounded'>
                                <ToolkitProvider
                                    keyField='id'
                                    data={this.displayCompanies()}
                                    columns={eldColumns}
                                    search
                                >
                                    {
                                        props => (
                                            <div>
                                                <div className='eld-search-bar'>
                                                    <div className='eld-info-css'>
                                                        <Icofont icon='icofont-info-circle' />
                                                        <i className='eldText'>Select your ELD providers from the list provided.</i>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='form-group col col-lg-6'>
                                                            <div className='input-container'>
                                                                <i className='fas fa-search info-icon-cob'></i>
                                                                <input type='text' placeholder='    Search ELDs' id='searchField' autoComplete='off' onKeyUp={(e) => { this.searchELDs(e) }} />
                                                            </div>
                                                        </div>
                                                        <div className='form-group col col-lg-6'>
                                                            <div className='input-container1'>
                                                                <a href='/#' className='btn btn-primary btn-sm' data-toggle='modal' data-backdrop='false' data-target='#newELDModal' >Request New ELD</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <BootstrapTable
														classes={'custom-row-class-eldlist'}
                                                    {...props.baseProps} noDataIndication={'No Data Found'} hover
                                                    bordered={false}
                                                />
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                                <>
                                <div className='stepper-wrapper'>
                                                <div className='stepper-item completed'>
                                                    <div className='step-counter'><i class='icofont-tick-mark'></i></div>
                                                    <div className='step-name'>Select Providers</div>
                                                </div>
                                                <div className='stepper-item active'>
                                                    <div className='step-counter'><i class='icofont-tick-mark'></i></div>
                                                    <div className='step-name'>Data Permissions</div>
                                                </div>
                                                <div className='stepper-item active'>
                                                    <div className='step-counter'><i class='icofont-tick-mark'></i></div>
                                                    <div className='step-name'>Verify Providers</div>
                                                </div>
                                            </div>
                                    <div className='outer' >
                                        <div className='row' >
                                            <div className='col-md-1 wave-img' >
                                                <img src={wave} alt='Not Found'/>
                                            </div>
                                            <div className='col welcome-header' >
                                                <span className='welcomeCss'>
                                                    Welcome back to TruckerCloud!
                                                </span> <br />
                                                <span>
                                                    Your are invited to connect with <strong className='contentCss'> {this.state.invitationData.invitationInfo.invitingUserFirstName}  {this.state.invitationData.invitationInfo.invitingUserLastName} </strong>
                                                </span>
                                            </div>
                                        </div>
                                    </div></>
                                <FAQModal state={this.state.eldDetails} />
                                <NewEld state={this.state} />
                                <br /><hr />
                            </article>
                            <div class='in-cob-footer'>
                                <div className='footer-buttons'>
                                    <button type='button' className='btn btn-primary3 footer-button'>  <span className='checkmark'>L</span> &nbsp; {this.state.checkedData.length} ELD  selected</button>
                                    <button type='button' className='btn btn-primary2 footer-button' >  <span className='checkmarkDouble'>L</span>  <span className='checkmarkDoubleBelow' style={{ float: 'left', fontSize: '9px', marginRight: '-5px', marginTop: '-1px' }}>L</span> &nbsp;{this.preChecked.length} ELD  authorized</button>
                                    {this.state.checkedData.length > 0
                                        ? <button type='button' onClick={() => { this.authCarrier() }} className='btn btn-primary footer-button eld-veify-btn' >Verify ELD</button>
                                        : <button type='button' className='btn btn-primary footer-button eld-veify-btn'  disabled={true} style={{ cursor: 'not-allowed' }}>Verify ELD</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            );
        } else if (this.state.error.length > 1) {
            return (
                <h4>Invitation Token expired. Please contact admin@truckercloud.com for further assistance</h4>
            )
        }
        else {
            return (
                <div className='text-center'>
                    <div className='spinner-border' role='status'>
                        <span className='sr-only'>Loading...</span>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    let carrierInvitationList = {};
    if (state.companyReducer.carrierInvitation) {
        carrierInvitationList = state.companyReducer.carrierInvitation;
    }
    return {
        carrierInvitationRes: carrierInvitationList
    };
}

export default connect(mapStateToProps)(withRouter(CarrierOnBoardNewcopy))
