import React,{Component} from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import {docList} from '../../../actions/documentActions';
import EditDocumentPopup from './EditDocumentPopup';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
class DocumentsList extends Component{
	state={
		modaldata:[],
		documentlists:[]
	 }	
	getModal = (row) => {
		let modaldata={
          documentId:row.DocumentId,
          loadId:row.LoadId,
          documentTitle:row.DocumentTitle,
          documentTypeId:row.DocumentTypeId,
          expiration:row.Expiration,
          issuedBy:row.Issued_By,
          issuedDate:row.Issued_Date,
          others:row.Others
		}
		this.setState({ showModal: true, modaldata:modaldata });
	 }	
	componentDidMount=()=>
	{ 
		this.props.dispatch(docList());
	} 
	 render(){
	// const { handleSubmit,selectAll, selectOne, allChecked }=this.props;
	var documentlists = [];
	if(this.props.DocumentList && this.props.DocumentList.length>0)
	{
		documentlists = [];
		documentlists = this.props.DocumentList.map(
			row =>{
				return({
					id:row.DocumentId,
					Load:row.LoadNumber,					
					Document:row.DocumentTitle,
					Category:row.DocumentTypeName,
					Owner:row.Owner, 
					UploadedDetails:row.UploadedDetails, 
					Notes:row.Notes,
					Expiration:row.Expiration,
					Issued_By:row.Issued_By,
					Issued_Date:row.Issued_Date,
					Others:row.Others,
					action:<div>
 					<a href='/#'><i className="icofont-eye-alt"></i></a>&nbsp;&nbsp;
 					<a href='/#' data-toggle="modal" data-target=".bd-documentedit-modal-lg" onClick={() => {this.getModal(row)}}
 					><i className="icofont-ui-edit"></i></a>&nbsp;&nbsp;
 					<a href='/#'><i className="icofont-download"></i></a>&nbsp;&nbsp;
 					<a href='/#'><i className="icofont-ui-delete"></i></a>&nbsp;&nbsp;
 					</div>					
				});
			}

		);
	}
	else
	{
		documentlists = [];				
	}	
	const { SearchBar } = Search;
	const columns=[	
	{
          text:'Load',
          dataField:'Load',
          sort:true
	},	  
	   {
		   text:'Document Title',
		   dataField:'Document',
		   sort:true
	   },
	   {
		   text:'Category',
		   dataField:'Category',
		   sort:true
	   },
	   {
		   text:'Owner',
		   dataField:'Owner',
		   sort:true
	   },
	   {
		   text:'Uploaded Details',
		   dataField:'UploadedDetails',
		   sort:true
	   },
	   {
		   text:'Notes',
		   dataField:'Notes',
		   sort:true
	   },	  
	   {
		  text:'Action',
		  dataField:'action'
	   }
	   
	]
	const customTotal = (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
		  Showing { from } to { to } of { size } Results
		</span>
	  );
	  
	  const options = {
		paginationSize: 4,
		pageStartIndex: 1,
			firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
		showTotal: true,
		paginationTotalRenderer: customTotal
	  };

	return(
		<article className="table-data truck-data shadow bg-white rounded">
			<div className="row no-gutters">
				<div className="col-md-12">
					<ul className="nav nav-tabs" id="myDocuments" role="tablist">
					  	<li className="nav-item">
					    	<a className="nav-link active" id="mydocument-tab" data-toggle="tab" href="#mydocument" role="tab" aria-controls="mydocument" aria-selected="true"><i className="icofont-truck-loaded"></i>DOCUMENTS-LOAD</a>
					  	</li>
					  	<div className="flex-left-auto">
					  		<ul>
					  			<li>
								  <Link to="addocument" loadlist={this.props.loadlist} documenttypelist={this.props.documenttypelist}  className="btn btn-primary btn-sm">Add Document</Link>
					  			</li>
					  		</ul>
					  	</div>
					</ul>
					<div className="tab-content" id="myTabContent">
					  	<div className="tab-pane fade show active" id="mydocument" role="tabpanel" aria-labelledby="mydocument-tab">
						  <ToolkitProvider
									keyField="id"
									data={ documentlists }
									columns={ columns }
									search
								   >
									{
									props => (
										<div>
										<div className="text-right">
										<SearchBar { ...props.searchProps } />
										</div>
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication="No Data Found"  hover 
											selectRow={ { mode: 'checkbox' } }  bordered={ false } />
										</div>
									
									)
									}
						  </ToolkitProvider>
						  <EditDocumentPopup modalvalues={this.state.modaldata} loadlist={this.props.loadlist} documenttypelist={this.props.documenttypelist}/>
					  	</div>
					</div>
				</div>
			</div>
		
		</article>
	)
  }
}


const mapStateToProps = (state) => {
	return{
	   loading : state.contactReducer.loading,
      DocumentList:state.documentReducer.data,
      error: state.documentReducer.error
	}    
}
export default connect(mapStateToProps)(DocumentsList)

