import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../helpers/FormControls';
import { validate } from './validation';

function submit(values) {
}

class EditTrailerPopup extends Component{
render(){
	const { handleSubmit } = this.props
return(
   <div className="modal fade bd-trailersedit-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg">
         <div className="modal-content">     
            <div className="modal-header">
               <h5 className="modal-title" id="exampleModalLabel">EDIT TRAILER</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
            </div>
            <div className="modal-body table-data">
               <ul className="nav nav-tabs" id="myTrailers" role="tablist">
                  <li className="nav-item">
                     <a className="nav-link active" id="trailerinfo-tab" data-toggle="tab" href="#trailerinfo" role="tab" aria-controls="trailerinfo" aria-selected="true">EDIT TRAILER</a>
                  </li>                                 
               </ul>
               <div className="tab-content" id="myTabContent">
                  	<div className="tab-pane fade show active" id="trailerinfo" role="tabpanel" aria-labelledby="trailerinfo-tab">                                      
					  	<form onSubmit={handleSubmit(submit.bind(this))}>
							<div className="row">                 
								<div className="col col-md-12">
									<h5>TRAILER INFO</h5>
									<article className="table-data trailer-data shadow bg-white rounded">
										<div className="row">
											<div className="col col-md-4">
												<div className="form-group">
													<Field type="text" name="trailer" component={renderField} label="Trailer #"/> 
												</div>
												<div className="form-group">
													<Field type="text" name="year" component={renderField} label="Year"/>  
												</div>
											</div>
											<div className="col col-md-4">
												<div className="form-group">
													<Field type="text" name="make" component={renderField} label="Make"/> 
												</div>
												<div className="form-group">
													<Field type="text" name="plate" component={renderField} label="Plate #"/>
												</div>
											</div>
											<div className="col col-md-4">
												<div className="form-group">
													<Field type="text" name="model" component={renderField} label="Model"/> 
												</div>
												<div className="form-group">
													<Field type="text" name="serial" component={renderField} label="Serial"/>
												</div>
											</div>                           
										</div>
									</article>
									<h5>NOTES</h5>
									<article className="table-data trailer-data shadow bg-white rounded">
                                        <div className="row">
                                            <div className="form-group col col-md-12"> 
												<Field type="text" name="notes" component={renderField} label="Notes"/>
                                            </div>             
                                        </div> 
									</article>
									<div className="row">
										<div className="col col-md-12 ">
											<div className="modal-footer">
												<button type="submit" className="btn btn-secondary btn-sm">Save</button>&nbsp;
											<a href="/#" className="btn btn-primary btn-sm">Reset</a>
											</div>
										</div>
									</div>
								</div>
							</div>
                    	</form>
                    </div>                    
                </div>
            </div>                              
        </div>
    </div>
</div>  
)
}
}
export default reduxForm({
    form: 'edittrailerValidation',  
    validate             
})(EditTrailerPopup)