import { CommonRoutes } from './CommonRoutes';

const components = {
	brokerprofileview: {
		component: 'brokerprofileview',
		url: '/profileView',
		title: 'profileview',
		icon: 'menu',
		module: 1
	},
	shipperloads: {
		component: 'ShipperLoads',
		url: '/loads',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	shipperaddload: {
        component: 'ShipperAddLoad',
        url: '/addLoad',
        title: 'addLoad',
        icon: 'menu',
        module: 1
    },
	shipperbrokers: {
		component: 'ShipperBrokers',
		url: '/brokers',
		title: 'brokers',
		icon: 'menu',
		module: 1
	},
	shipperdocuments: {
		component: 'BrokerDocuments',
		url: '/documents',
		title: 'documents',
		icon: 'menu',
		module: 1
	},
	shipperadddocument: {
		component: 'AddDocument',
		url: '/addocument',
		title: 'Adddocumentpage',
		icon: 'menu',
		module: 1
	},
	shippercarriers: {
		component: 'ShipperCarriers',
		url: '/carriers',
		title: 'Carriers',
		icon: 'menu',
		module: 1
	},
	shippertrucks: {
		//component: 'ShipperTrucks',
		component: 'CarrierTrucks',
		url: '/trucks',
		title: 'trucks',
		icon: 'menu',
		module: 1
	},
	shippercontacts: {
		component: 'ShipperContacts',
		url: '/contacts',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	shipperviewtrucks: {
        component: 'ShipperViewTrucks',
        url: '/viewtruck',
        title: 'View Truck',
        icon: 'menu',
        module: 1
	},
	shippertrailers: {
        component: 'CarrierTrailers',
        url: '/trailers',
        title: 'Trailers',
        icon: 'menu',
        module: 1
    },
    shipperviewtrailers: {
        component: 'ShipperViewTrailers',
        url: '/viewtrailer/:id',
        title: 'View Trailer',
        icon: 'menu',
        module: 1
	},
	shippercancelledloads: {
		component: 'cancelloads',
        url: '/cancelledLoads',
        title: 'View Trailer',
        icon: 'menu',
        module: 1
	}, 
	Dispatched: {
		component: 'Dispatched',
		url: '/Dispatched',
		title: 'Dispatched',
		icon: 'menu',
		module: 1
	}, 
	Delivered: {
		component: 'Delivered',
		url: '/Delivered',
		title: 'Delivered',
		icon: 'menu',
		module: 1
	}, 
	ShipperCarriers:{
		component: 'ShipperCarriers',
		url: '/geofence',
		title: 'GeoFence',
		icon: 'menu',
		module: 1
	},
	Permissions:{
		component: 'Permissions',
		url: '/permissions',
		title: 'Permissions',
		icon: 'menu',
		module: 1
    },
	Profile: {
		component: 'Profile',
		url: '/Profile',
		title: 'Profile',
		icon: 'menu',
		module: 2
	} 
};

const ShipperRoutes = {
	shipper: {
		routes: [
			...CommonRoutes,
			components.shipperloads,
			components.shipperaddload,
			components.shipperbrokers,
			components.shippercarriers,
			components.shippercontacts,
			components.shipperdocuments,
			components.shipperadddocument,
			components.shippertrucks,
			components.shipperviewtrucks,
			components.shippertrailers,
			components.shipperviewtrailers,
			components.brokerprofileview,
			components.shippercancelledloads,
			components.Dispatched,
			components.Delivered,
			components.ShipperCarriers,
			components.Permissions,
			components.Profile
		]
	}
};

export { ShipperRoutes };