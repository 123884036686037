import React from 'react';
import { TypoGraph, Card, Container, Button, Icon } from '../../muiComponents'
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from "react-router-dom";
import HeaderBar from '../../muiComponents/header';
require('dotenv').config();

const useStyles = makeStyles({
    cardContentContainer: {
        gridTemplateColumns: '1fr 1fr 1fr',
        gridGap: '1.5rem',
        contentContainer: {
            gridTemplateRows: 'auto auto',
            gridGap: '1rem'
        },
        btnContainer: {
            gridGap: '1rem',
            maxWidth: '10rem',
            background: 'red',
            marginTop: '40px'
        }
    },
    outerBtnContainer: {
        gridGap: '1rem',
        maxWidth: '20rem',
        marginTop: '30px'
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '-10px'
    }
});
const Settings = (props) => {
    const classes = useStyles();
    let userMenu = localStorage.getItem('userMenu');
    let userType = localStorage.getItem('userType');
    let SettingsArr = [];
    let mnu;
    if (userMenu) {
        userMenu = userMenu.substring(1, userMenu.indexOf(']'));
        mnu = userMenu.split(",").map(String);
        mnu.map((menu) => {
            if (menu.trim() === 'company_profile' || menu.trim() === 'email_accounts' || menu.trim() === 'email_templates' || menu.trim() === 'users' || menu.trim() === 'branding')
                SettingsArr.push(
                    {
                        title: menu.trim().split("_").join(" "),
                        subTItle: `Add and update ${menu.trim().split("_").join(" ")}`,
                        buttonText: `Manage ${menu.trim().split("_").join(" ")}`,
                        icon: menu.trim(),
                        route: menu.trim()
                    })
        })
    }
    return (
        <Container>
            <HeaderBar title='Settings' />
            <Container className={classes.cardContentContainer}>
                {SettingsArr.map((item, cardindex) => (
                    <Card
                        key={cardindex}
                        sxContent={{
                            // width: '375px',
                            padding: '36px 48px',
                            height: '223px',
                            maxHeight: '300px'
                        }}
                    >
                        <Container className={classes.iconContainer}>
                            <Icon
                                name={item.icon}
                                width={31}
                                height={25}
                            />
                        </Container>
                        <Container className={classes.contentContainer}>
                            <TypoGraph content={item.title} align="center" sx={{ marginTop: '9px', fontWeight: '600', fontSize: '18px' ,textTransform: 'capitalize'}} />
                        </Container>
                        <Container className={classes.contentContainer}>
                            <TypoGraph align="center" sx={{ color: '#666666', marginTop: '12px' }}>{item.subTItle}</TypoGraph>
                        </Container>
                        <Container className={classes.outerBtnContainer}>
                            <Button
                                label={item.buttonText}
                                variant="outlined"
                                colorprop='#44A3DB'
                                bordercolorprop='#44A3DB'
                                component={NavLink}
                                to={'item.route'}
                                onClick={() => props.history.push(`/${userType}/settings/${item.route}`)}
                            />
                        </Container>
                    </Card>
                ))}
            </Container>
        </Container>
    );
}
export default Settings