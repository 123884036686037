import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { renderField, renderSelect } from '../../helpers/FormControls';
import { driveraddaction } from '../../actions/userActions';
import { getcitybystate,getzipbycity } from '../../actions/loadActions';
//import { validate } from './validation';
// import { connect } from 'tls';
import { connect } from 'react-redux';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import moment from 'moment';
import {toastr} from 'react-redux-toastr'
import { validate } from '../carriers/drivers/validation';

function submit(values) {
   const frmdata = {};
   frmdata.userTypeId= 4;
   frmdata.firstName= (values.firstname) ? (values.firstname) : ("");
   frmdata.middleName= (values.middleName) ? (values.middleName) : ("");
   frmdata.lastName=(values.lastname) ? (values.lastname) : ("");
   frmdata.emailId= (values.email) ? (values.email) : ("");
   frmdata.currentAddress1=(values.address) ? (values.address) : ("");
   frmdata.currentAddress2=(values.currentAddress2) ? (values.currentAddress2) : ("");
   frmdata.currentAddress3=(values.currentAddress3) ? (values.currentAddress3) : ("");
   frmdata.currentCity=(values.city) ? (values.city) : ("");
   frmdata.currentStateId=(values.state) ? (values.state) : ("");
   frmdata.CurrentZip=(values.zip) ? (values.zip) : ("");
   frmdata.currentCountryId=(values.currentCountryId) ? (values.currentCountryId) : ("");
   frmdata.permenantAddress1=(values.address) ? (values.address) : ("");
   frmdata.permenantAddress2=(values.permenantAddress2) ? (values.permenantAddress2) : ("");
   frmdata.permenantAddress3=(values.permenantAddress3) ? (values.permenantAddress3) : ("");
   frmdata.permenantCity=(values.city) ? (values.city) : ("");
   frmdata.permanentStateId=(values.state) ? (values.state) : ("");
   frmdata.permanentZip=(values.zip) ? (values.zip) : ("");
   frmdata.permanentCountryId=(values.permanentCountryId) ? (values.permanentCountryId) : ("");
   frmdata.companyName=(values.companyName) ? (values.companyName) : ("");
   frmdata.phoneCountryCode=(values.phoneCountryCode) ? (values.phoneCountryCode) : ("");
   frmdata.phoneNumber=(values.mobilenumber) ? (values.mobilenumber) : ("");
   frmdata.altPhoneCountryCode=(values.altPhoneCountryCode) ? (values.altPhoneCountryCode) : ("");
   frmdata.altPhoneNumber=(values.altPhoneNumber) ? (values.altPhoneNumber) : ("");
   frmdata.faxCountryCode=(values.faxCountryCode) ? (values.faxCountryCode) : ("");
   frmdata.faxNumber=(values.faxNumber) ? (values.faxNumber) : ("");
   frmdata.drivingLicenseNo=(values.licensenumber) ? (values.licensenumber) : ("");    
   frmdata.driverTypeId=(values.drivertype) ? (values.drivertype) : ("");
   frmdata.drivingLicenseExpDate=(values.licenseexp) ? (moment(values.licenseexp).format('YYYY-MM-DD')) : ("");
   frmdata.Dob = (values.dob) ? (moment(values.dob).format('YYYY-MM-DD')) : ("");   
   frmdata.physicalExpDate=(values.physicalexpdate) ? (moment(values.physicalexpdate).format('YYYY-MM-DD')) : ("");
   frmdata.hireDate=(values.hiredate) ? (moment(values.hiredate).format('YYYY-MM-DD')) : ("");   
   frmdata.drivingLicenseStateCode=(values.licensestate) ? (values.licensestate) : ("");
   frmdata.emptyRate=(values.emptyrate) ? (values.emptyrate) : ("");
   frmdata.driverPay=(values.driverpay) ? (values.driverpay) : ("");
   this.props.dispatch(driveraddaction(frmdata));  
}
class AddDriverPopUp extends Component{
    constructor(props){
        super(props);
        this.state={
        };
    }

handleClick = () =>{
    this.props.initialize('adddriverValidation')
}
stateOnchange=(event)=>
{
    this.props.dispatch(getcitybystate(event.target.value));
}

cityOnchange=(event)=>
{
    this.props.dispatch(getzipbycity(event.target.value));
}  

componentDidUpdate()
	{
		if(this.props.data.status==="ALREADY_REPORTED")
        {
		   toastr.info('Info', 'Driver Already Exists!');
		    // const params={	
			// 	usertype: 'Driver'			
		    // }
		    //this.props.dispatch(userlistAction(params));		
        }
        else if(this.props.data.status==="CREATED")
        {
			toastr.success('Success', 'Driver Created Successfully!');
			// const params={	
			// 	usertype: 'Driver'			
			// }
			//this.props.dispatch(userlistAction(params));
            document.getElementById("adddriverfrm").click();
            this.props.refreshParent();
		}
	}

render(){
    const { handleSubmit,pristine, reset, submitting } = this.props;
    console.log(this.props,"COmmonData")

return(
    <div className="modal fade bd-trucks-modal-lg"  data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-lg">
         <div className="modal-content">     
           <div className="modal-header">
           <h5 className="modal-title doc-color" id="exampleModalLabel">ADD DRIVER</h5>
            <button type="button" className="close" id="adddriverfrm" data-dismiss="modal" aria-label="Close" onClick={this.handleClick}>
                <span aria-hidden="true">&times;</span>
            </button>
           </div>
            <div className="modal-body table-data">
                              {/* <ul className="nav nav-tabs" id="mydrivers" role="tablist">
                                 <li className="nav-item">
                                    <a  className="nav-link active" id="adddriver-tab" data-toggle="tab" href="#adddriver" role="tab" aria-controls="adddriver" aria-selected="true" >
                                    ADD Driver
                                    </a>
                                  </li>
                               </ul> */}
                               <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="adddriver" role="tabpanel" aria-labelledby="adddriver-tab">
                                      <form className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}>
                                       <div className="row">                 
                                          <div className="col col-md-12">
                                          {/* <h5 className="doc-color">DRIVER INFO</h5> */}
                                          <article className="table-data driver-data shadow bg-white rounded">
                                                   <div className="row">
                                                      <div className="col col-md-4">
                                                         <div className="form-group">
                                                         <Field type="text" name="firstname" controlClass="requiredfield" component={renderField} label="First Name"/>										    
                                                         </div>
                                                         <div className="form-group">
                                                         <Field controlClass="level1" type="text" name="licenseexp" defaultValue={null} label="License Exp. Date" component={renderDatePicker} />
                                                         {/* <Field type="date" name="licenseexp" component={renderField} label="License Exp. Date"/>		 */}
                                                         </div> 
                                                         <div className="form-group">
                                                           <Field type="date" name="licensestate" component={renderSelect} label="License State" data={this.props.statelist.data}/>
                                                         </div>
                                                      </div>
                                                      <div className="col col-md-4">
                                                      <div className="form-group">
                                                      <Field type="text" name="lastname" component={renderField} label="Last Name"/>	
                                                         </div>
                                                         <div className="form-group">
                                                         <Field controlClass="level1" type="text" name="physicalexpdate" defaultValue={null} label="Physical Exp. Date" component={renderDatePicker} />
                                                         {/* <Field type="date" name="physicalexpdate" component={renderField} label="Physical Exp. Date"/>    */}
                                                         </div>
                                                         <div className="form-group">
                                                         <Field type="text" name="licensenumber" controlClass="requiredfield" component={renderField} label="License Number" />                          
                                                      </div>
                                                      </div>
                                                      <div className="col col-md-4">     
                                                         <div className="form-group">
                                                         {/* <Field type="date" name="dob" component={renderField} label="D.O.B"/> */}
                                                         <Field controlClass="level1" type="text" name="dob" defaultValue={null} label="DOB" component={renderDatePickerforhiredate} />
                                                         </div>
                                                         <div className="form-group">
                                                         <Field type="text" name="drivertype" controlClass="requiredfield" component={renderSelect} label="Driver Type" data={this.props.drivertype.data} />  
                                                         
                                                         </div>
                                                         <div className="form-group">
                                                         {/* <Field type="date" name="hiredate" component={renderField}  label="hire Date"/> */}
                                                         <Field controlClass="level1" type="text" name="hiredate" defaultValue={null} label="Hire Date" component={renderDatePickerforhiredate} />
                                                         </div>
                                                      </div>                           
                                                   </div>

                                                   <div className="row">
                                                      <div className="col col-md-4">
                                                         <div className="form-group">
                                                         <Field type="text" name="email" controlClass="requiredfield" component={renderField} label="Email"/>										    
                                                         </div>
                                                         <div className="form-group">
                                                         <Field type="text" name="mobilenumber" controlClass="requiredfield" component={renderField} label="Mobile Number"/>		
                                                         </div> 
                                                         <div className="form-group">
                                                         <Field type="text" name="occupation" component={renderField} label="Occupation"/>	
                                                           
                                                         </div>
                                                      </div>
                                                      <div className="col col-md-4">
                                                      <div className="form-group">
                                                      <Field type="text" name="driverpay" component={renderField} label="LoadedRate/Driver-pay"/>	
                                                         </div>
                                                         <div className="form-group">
                                                         <Field type="text" name="state"  onChange={this.stateOnchange} controlClass="requiredfield" component={renderSelect} label="State" data={this.props.statelist.data}/>
                                                         </div>
                                                         <div className="form-group">
                                                         <Field type="text" name="address" component={renderField} label="Address" />                          
                                                      </div>
                                                      </div>
                                                      <div className="col col-md-4">     
                                                         <div className="form-group">
                                                         <Field type="text" name="emptyrate" component={renderField} label="Empty Rate"/>                                                         
                                                         </div>
                                                         <div className="form-group">
                                                         <Field type="text" name="city" onChange={this.cityOnchange} component={renderSelect} data={this.props.CityVal} keyColumn='cityId' keyValue='cityName' label="City" />                                                           
                                                         </div>
                                                         <div className="form-group">
                                                         <Field type="text" name="zip" keyColumn='zipId' keyValue='zipValue' data={this.props.ZipVal} component={renderSelect} label="Zip"/>
                                                         </div>
                                                      </div>                           
                                                   </div>
                                                   </article>                                    
                                                   <div className="row">
                                                        <div className="col col-md-12">
                                                        <div className="form-group form-group-btns">
                                                            <button type="submit" className="btn btn-primary btn-sm">Save</button>&nbsp;
                                                            <button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-primary btn-sm">Reset</button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                             </div>
                                         </form>                          
                                      </div>
                                </div>
                      </div>
                 </div>
            </div>
       </div>
)
}
}
const renderDatePicker = ({ input: { onChange, value }, showTime,input, label, controlClass,labelnotavailabe, data,disable,type, meta: { touched, error } }) =>
<div className={`form-group ${(touched && error) ? 'error' : ''}`}>
{(labelnotavailabe) ? '' : <label>{label}</label>}
<div>
<DateTimePicker dropDown={true}
{...input}
    className={`form-group ${(controlClass) ? controlClass : ''}`}
    onChange={onChange}
    min={new Date()}
    type={type}
    text={`${(data) ? data : ''}`}
    format="MM-DD-YYYY"
    placeholder="Date"
    time={false}
    value={!value ? null : new Date(value)}
/> 
{touched && (error && <span className="error">{error}</span>)}
</div>
</div>

const renderDatePickerforhiredate = ({ input: { onChange, value }, showTime,input, label, controlClass,labelnotavailabe, data,disable,type, meta: { touched, error } }) =>
<div className={`form-group ${(touched && error) ? 'error' : ''}`}>
{(labelnotavailabe) ? '' : <label>{label}</label>}
<div>
<DateTimePicker dropDown={true}
{...input}
    className={`form-group ${(controlClass) ? controlClass : ''}`}
    onChange={onChange}
    max={new Date()}
    type={type}
    text={`${(data) ? data : ''}`}
    format="MM-DD-YYYY"
    placeholder="Date"
    time={false}
    value={!value ? null : new Date(value)}
/> 
{touched && (error && <span className="error">{error}</span>)}
</div>
</div>



const mapStateToProps = state => {
    return {
      CityVal:state.loadReducer.CityVal,
      ZipVal:state.loadReducer.ZipVal,
      data: state.userReducer.data,
      loading: state.userReducer.loading,
      error: state.userReducer.error,
        }
    }
  
export default connect(mapStateToProps)(reduxForm({ form: 'adddriverValidation', validate }) (AddDriverPopUp))

