export const validate = values => 
{
    const errors = {}
    if (!values.driverId) {
        errors.driverId = 'Name can not be left blank.!'
    }
    if (!values.title) {
        errors.title = 'Title can not be left blank.!'
    } 
    if(!values.taskDate)
    {
        errors.taskDate='Date can not be left blank.!'
    }

  return errors
}