import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderSelect } from '../../../helpers/FormControls';
import { validate } from './validation';

function submit(values) {
}

class EditCarrier extends Component{
    state = {
        speciality : [
			{id: "1", name: "Dry Van"},
			{id:"2", name: "Flat Bed"},
			{id:"3", name: "Reefer"},
			{id:"4", name: "Intermodal"},
		],
        eldprovider: [
			{id: "1", name: "Azuga"},
			{id:"2", name: "Big Road"},
			{id:"3", name: "Blue Ink"}
        ],
        selstate : [
			{id: "1", name: "Alaska"},
			{id:"2", name: "Alabama"},
			{id:"3", name: "Arkansas"}
        ],
        country: [
			{id: "1", name: "Canada"},
            {id:"2", name: "Mexico"},
            {id:"3", name: "USA"}
        ]
    }
    render(){
        const { handleSubmit } = this.props
        return(
            <div className="modal fade bd-editcarrier-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content"> 
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">EDIT CARRIER</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                        </div>
                        <div className="modal-body">
                            <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">EDIT CARRIER</a>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab"> 
                                        <form onSubmit={handleSubmit(submit.bind(this))}>
                                            <div className="row"> 
                                                <div className="col col-md-12">
                                                    <h5>Carrier Details</h5>
                                                <article className="table-data truck-data shadow bg-white rounded">
                                                <div className="row">
                                                    <div className="col col-md-4">
                                                        <div className="form-group">
                                                            <Field type="text" name="firstname" component={renderField} label="First Name"/> 
                                                        </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="speciality" component={renderSelect} label="Speciality" data={this.state.speciality}/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="carriername" component={renderField} label="Carrier Name"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="currentaddress" component={renderField} label="Current Address"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="permanentaddress" component={renderField} label="Permanent Address"/>
                                                    </div>
                                                </div>
                                                <div className="col col-md-4">
                                                    <div className="form-group">
                                                        <Field type="text" name="lastname" component={renderField} label="Last Name"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="email" component={renderField} label="Email"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="primaryphone" component={renderField} label="Primary Phone"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="fax" component={renderField} label="Fax"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="website" component={renderField} label="Website"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="eldprovider" component={renderSelect} label="ELD Provider" data={this.state.eldprovider}/> 
                                                    </div>
                                                </div>
                                                <div className="col col-md-4">
                                                    <div className="form-group">
                                                        <Field type="text" name="city" component={renderField} label="City"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="state" component={renderSelect} label="State" data={this.state.selstate}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="zipcode" component={renderField} label="Zip Code"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="country" component={renderSelect} label="Country" data={this.state.country}/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="nooftrucks" component={renderField} label="No of Trucks"/>
                                                    </div>
                                                </div> 
                                            </div>
                                            </article>
                                            <h5>Description</h5>
                                            <article className="table-data truck-data shadow bg-white rounded">
                                                <div className="row">
                                                    <div className="col col-md-12">
                                                        <div className="form-group">
                                                            <textarea className="col-md-12 form-control control-custom"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                        </article>
                                        <div className="row">
                                            <div className="col col-md-12">
                                                <div className="form-group form-group-btns">
                                                    <a href="/#" className="btn btn-primary btn-sm">Save</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        )
    }
}
export default reduxForm({
    form: 'editcarrierValidation',  
    validate             
  })(EditCarrier)