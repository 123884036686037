import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { assetlist } from '../../../actions/assetAction';
import AddTruckPopup from '../trucks/AddTruckPopup';
import Trucklists from '../trucks/Trucklist';


class Trucks extends Component{
	componentDidMount() {
		document.title = 'Trucks';
	}
	componentDidUpdate()
    {
        if(this.props.data.status==="ALREADY_REPORTED")
        {
           toastr.info('Info', 'Truck Already Exists!');
            const params={              
                assettype: 'truck',
                //usertype: 'carrier'           
            }       
            this.props.dispatch(assetlist(params)); 
        
        }
        else if(this.props.data.status==="CREATED")
        {
            toastr.success('Success', 'Truck Created Successfully!');
            const params={              
                assettype: 'truck',
                //usertype: 'carrier'           
            }       
            this.props.dispatch(assetlist(params));  
            document.getElementById("addfrm").click(); 
        }
        else if(this.props.data.status==="OK")
        {
            toastr.success('Success', 'Truck Updated Successfully!');
            const params={              
                assettype: 'truck',      
            }       
            this.props.dispatch(assetlist(params));  
            document.getElementById("editfrm").click(); 
        }
        
    }

	
	render(){
        console.log(this.props.data,"data")
        if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}
		return(
			<Fragment>				
				<Trucklists deleteTruck={this.deleteTruck}  eldproviderlist={this.props.eldproviderlist} trucktypelist={this.props.trucktypelist} fueltypelist={this.props.fueltypelist}/>	
				<AddTruckPopup trucktypelist={this.props.trucktypelist} fueltypelist={this.props.fueltypelist} eldproviderlist={this.props.eldproviderlist}/>
				{/*<Route exact path="trucks/summary/:id" component={TruckSummary}/> */}
			</Fragment> 				
		)
	}
}

const mapStateToProps = state => ({
    data: state.assetReducer.data,
    loading: state.assetReducer.loading,
    error: state.assetReducer.error,
});

export default connect(mapStateToProps)(Trucks)
