import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderSelect } from '../../../helpers/FormControls';
import { validate } from './validation';

function submit(values) {
}

class EditBroker extends Component{
    state = {
		selstate : [
			{id: "1", name: "Alaska"},
			{id:"2", name: "Alabama"},
			{id:"3", name: "Arkansas"}
        ],
      speciality : [
			{id: "1", name: "Dry Van"},
			{id:"2", name: "Flat Bed"},
			{id:"3", name: "Reefer"},
			{id:"4", name: "Intermodal"},
		]
	}
render(){
    const { handleSubmit } = this.props
return(
        <div className="modal fade editbroker" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                 <div className="modal-content">     
                   <div className="modal-header">
                    <h5 className="modal-title doc-color" id="exampleModalLabel">EDIT BROKER</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                   </div>
                    <div className="modal-body">
                              <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                                 <li className="nav-item">
                                    <a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">EDIT BROKER</a>
                                 </li>                                                                 
                              </ul>
                                 <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">                                      
                                        <form className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}>
                                             <div className="row">                 
                                                <div className="col col-md-12">
                                                <article className="table-data truck-data shadow bg-white rounded">
                                                         <div className="row">
                                                            <div className="col col-md-4">
                                                               <div className="form-group">
                                                                    <Field type="text" name="company" component={renderField} label="Company"/>
                                                               </div>
                                                               <div className="form-group">
                                                                    <Field type="text" name="phone" component={renderField} label="Phone"/>   
                                                               </div>
                                                               <div className="form-group">
                                                                    <Field type="text" name="city" component={renderField} label="City"/>                                 
                                                               </div>
                                                            </div>
                                                            <div className="col col-md-4">
                                                               <div className="form-group">
                                                                    <Field type="text" name="email" component={renderField} label="Email"/>                                  
                                                               </div>
                                                               <div className="form-group">
                                                                    <Field type="text" name="address" component={renderField} label="Address"/>
                                                               </div>
                                                               <div className="form-group">
                                                                    <Field type="text" name="state" component={renderSelect} className="form-control" label="State" data={this.state.selstate}/>    
                                                               </div>
                                                            </div>
                                                            <div className="col col-md-4">
                                                               <div className="form-group">
                                                                    <Field type="text" name="speciality" component={renderSelect} className="form-control" label="Speciality" data={this.state.speciality}/>                           
                                                               </div>
                                                               <div className="form-group">
                                                                    <Field type="text" name="website" component={renderField} className="form-control" label="Website"/>                                
                                                               </div>
                                                               <div className="form-group">
                                                                    <Field type="text" name="zipcode" component={renderField} className="form-control" label="Zip Code"/>  
                                                               </div>
                                                            </div>                           
                                                         </div>
                                                         </article>
                                                               <div className="row">
                                                               <div className="col col-md-12">
                                                                  <div className="form-group form-group-btns">
                                                                  <button type="submit" className="btn btn-secondary btn-sm">Save</button>&nbsp;
                                                                     <a href="/#" className="btn btn-primary btn-sm">Reset</a>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                      </div>
                                                   </div>
                                          </form>
                                    </div>
                                 </div>
                                 </div>                              
                           </div>
            </div>
       </div>
)
}
}
export default reduxForm({
    form: 'editbrokerValidation',  
    validate                
  })(EditBroker)