export const validate = values => {
  //console.log(values);
    const errors = {}
     if (!values.loadId) {
       errors.loadId = 'This field is required.'
     }
     if (!values.documentTypeId) {
       errors.documentTypeId = 'This field is required.'
     }
    if (!values.documentTitle) {
      errors.documentTitle = 'This field is required.'
    }
   /* if (!values.expiration) {
      errors.expiration = 'Required'
    }
    if (!values.issued_by) {
      errors.issued_by = 'Required'
    }
    if (!values.issued_date) {
      errors.issued_date = 'Required'
    }
    if (!values.others) {
      errors.others = 'Required'
    }*/
    if (!values.select_file) {
      errors.select_file = 'This field is required.'
    }
    if (!values.members || !values.members.length) {
      errors.members = {}
    } else {
      const membersArrayErrors = []
      values.members.forEach((member, memberIndex) => {
        const memberErrors = {}
         if (!member || !member.loadId) {
           memberErrors.loadId = 'This field is required.'
           membersArrayErrors[memberIndex] = memberErrors
         }
         if (!member || !member.documentTypeId) {
           memberErrors.documentTypeId = 'This field is required.'
           membersArrayErrors[memberIndex] = memberErrors
         }
        if (!member || !member.documentTitle) {
          memberErrors.documentTitle = 'This field is required.'
          membersArrayErrors[memberIndex] = memberErrors
        }
        /*if (!member || !member.expiration) {
          memberErrors.expiration = 'Required'
          membersArrayErrors[memberIndex] = memberErrors
        }
        if (!member || !member.issued_by) {
          memberErrors.issued_by = 'Required'
          membersArrayErrors[memberIndex] = memberErrors
        }
        if (!member || !member.issued_date) {
          memberErrors.issued_date = 'Required'
          membersArrayErrors[memberIndex] = memberErrors
        }
        if (!member || !member.others) {
          memberErrors.others = 'Required'
          membersArrayErrors[memberIndex] = memberErrors
        }*/
        if (!member || !member.select_file) {
          memberErrors.select_file = 'This field is required.'
          membersArrayErrors[memberIndex] = memberErrors
        }
      })
      if (membersArrayErrors.length) {
        errors.members = membersArrayErrors
      }
    }
    return errors
  }