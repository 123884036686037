import React, { Component } from 'react';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { assetlist } from '../../../actions/assetAction';
import Edittruckpopup from '../trucks/EditTruckPopup';
import {TableDataComponent } from 'UI-Component';

class TrucksList extends Component {
	constructor(props) {
		super(props);		
		this.totalItemsCount=1;
		this.activePage=1;
		this.itemsCountPerPage=10;
		this.pageRangeDisplayed=5;
	}
	componentDidMount() {	
		const params={				
			assettype: 'truck',
			usertype: 'Broker'			
		}		
		this.props.dispatch(assetlist(params));  		
	}
	state={
	   modaldata:[]
	}	
	getModal = (tList) => {
		this.setState({ modaldata: tList });
	};
	render(){	
		var truckList=[];
		if(this.props.loading){
			var indicationhtml = 
			   <div className="spinner-border" role="status">
			 		<div className="row react-bs-table-no-data"></div>
			   </div>
		} 
		else 
		if(this.props.trucks.assetList)
		{
			truckList = []	;
			truckList = this.props.trucks.assetList.map(
				row =>{
					return({
						   id:row.asset_id,
						   truck_no:row.asset_Number,
						   Year:row.model_Year,
						   Make:row.make,
						   Model:row.model,
						   VIN:row.identification_No,
						   Plate:row.license_Plate_No,
						   Color:row.color,
						   TruckType:row.truck_Type_Name,
						   More:<div className="dropdown">
							 <label data-toggle="dropdown">&hellip;</label>
							 <div className="dropdown-menu dropdown-menu-right animate slideIn">
							 <a className="dropdown-item" data-toggle="modal" data-target=".bd-trucksedit-modal-lg" href="/#" onClick={() => {this.getModal(row)}}><i className="icofont-ui-edit"></i> Edit</a>
							 <Link className="dropdown-item" to={
								{ 
									pathname: "viewtruck/"+row.asset_id,
									aboutProps: {
										id:row.asset_id,
										truckno:row.asset_Number,
										model_Year:row.model_Year,
										make:row.make,
										model:row.model,
										identification_No:row.identification_No,
										license_Plate_No:row.license_Plate_No,
										color:row.color,
										device_id:row.device_id,
										eld_Provider_Id:row.eld_Provider_Id,
										trucktype:row.truck_Type_Id
									}
								}
							}><i className="icofont-eye-alt"></i> View  
							</Link>
								</div>
						 </div>						
					});
				}
			);
		}
		else
		{
			indicationhtml = "No Data Found"
			truckList = [];				
		}	
		const columns=[
			  {
		        text: 'Truck #',
		        dataField: 'truck_no',
		        sort: true
		      },				
		      {
		        text: 'Year',
		        dataField: 'Year',
		        sort: true
		      },
		      {
		        text: 'Make',
		        dataField: 'Make',
		        sort: true
		      },
		      {
		        text: 'Model',
		        dataField: 'Model',
		        sort: true
		      },
		      {
		        text: 'VIN',
		        dataField: 'VIN',
				sort: true
		      },
		      {
		        text: 'Plate #',
		        dataField: 'Plate',
		        sort: true
		      },
		      {
		        text: 'Color',
		        dataField: 'Color',
		        sort: true
		      },
		      {
		        text: 'Truck Type',
		        dataField: 'TruckType',
		        sort: true
		      },
		      {
		        text: 'More',
		        dataField: 'More',
		        sort: true
		      }		   
		]
		return(
			<article className="table-data truck-data shadow bg-white rounded">			
				<div className="row no-gutters">
					<div className="col-md-12">
						<ul className="nav nav-tabs" id="myTrucks" role="tablist">
							  <li className="nav-item">
								<a className="nav-link active" id="mytruck-tab" data-toggle="tab" href="#mytruck" role="tab" aria-controls="mytruck" aria-selected="true"><i className="icofont-truck-loaded"></i>My Trucks</a>
							  </li>					  	
						</ul>
						<div className="tab-content" id="myTabContent">
							  <div className="tab-pane fade show active" id="mytruck" role="tabpanel" aria-labelledby="mytruck-tab">
								<TableDataComponent tableData={truckList} columnData={columns}  noDataIndication={indicationhtml} />
							  </div>
							  <div className="tab-pane fade" id="newtruck" role="tabpanel" aria-labelledby="newtruck-tab">								 
							  </div>
							  <Edittruckpopup modalvalues={this.state.modaldata} fueltypelist={this.props.fueltypelist} eldproviderlist={this.props.eldproviderlist} trucktypelist={this.props.trucktypelist} />
						</div>
					</div>
				</div>	
			</article>
		)
	}	
}

const mapStateToProps = (state) => {	
	return{
		trucks: state.assetReducer.data,
		loading: state.assetReducer.loading,		
		error: state.assetReducer.error
	}    
}	
export default connect(mapStateToProps)(TrucksList)