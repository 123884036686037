import React, { Component } from 'react';
import LoadCountList from './loadCountList';

class LoadReport extends Component{
	state={
	}
	componentDidMount() {
		document.title = 'Load Report';
	}

	render(){		
		return(
		<LoadCountList loadcountlist={ this.state.loadcountlist }/>
		)
	}
}

export default LoadReport