import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import TaskList from './TaskList';

class Contacts extends Component{
	componentDidMount() {
		document.title = 'Task';
	}

	render(){
		return(
			<Fragment>
				<TaskList />
			</Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		data: state.taskReducer.data,
		error: state.taskReducer.error,
	}
}
export default connect(mapStateToProps)(Contacts)