
import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {validate} from './validation';
import { profileedit,profileview } from '../../actions/userActions';
import { renderField,renderSelect } from '../../helpers/FormControls';
import {toastr} from 'react-redux-toastr';
import { getcitybystate,getzipbycity } from '../../actions/loadActions';
import normalizePhone from '../../helpers/normalizePhone';

function submit(values,params) {   
    const frmdata = {};    
     frmdata.companyName= (values.companyName) ? (values.companyName) : ("");
     frmdata.companyEmail= (values.companyEmail) ? (values.companyEmail) : ("");
     frmdata.currentAddress1= (values.currentAddress1) ? (values.currentAddress1) : ("");
     frmdata.currentAddress2= (values.currentAddress1) ? (values.currentAddress1) : ("");
     frmdata.currentAddress3= (values.currentAddress1) ? (values.currentAddress1) : ("");
     frmdata.currentCityId= (values.currentCityId) ? (values.currentCityId) : ("");
     frmdata.currentStateCode= (values.currentStateCode) ? (values.currentStateCode) : ("");
     frmdata.currentZipId= (values.currentZipId) ? (values.currentZipId) : ("");
     frmdata.currentCountryCode= (values.currentCountryCode) ? (values.currentCountryCode) : ("");
     frmdata.websiteAddress= (values.websiteAddress) ? (values.websiteAddress) : ("");
     frmdata.phoneCountryCode= "";
     frmdata.phoneNumber= (values.phoneNumber) ? (values.phoneNumber) : ("");
     frmdata.altPhoneCountryCode= "";
     frmdata.altPhoneNumber= (values.altPhoneNumber) ? (values.altPhoneNumber) : ("");
     frmdata.faxCountryCode= "";
     frmdata.faxNumber= (values.faxNumber) ? (values.faxNumber) : ("");
     frmdata.subscriptionPlanId= (values.subscriptionPlanId) ? (values.subscriptionPlanId) : ("");
     frmdata.specialityId= (values.specialityId) ? (values.specialityId) : ("");
     frmdata.noOfTrucks= (values.noOfTrucks) ? (values.noOfTrucks) : ("");
     frmdata.instructions= (values.instructions) ? (values.instructions) : ("");
     frmdata.firstName= (values.firstName) ? (values.firstName) : ("");
     frmdata.lastName= "";       
     this.props.dispatch(profileedit(frmdata)); 
  }

class brokerProfilEdit extends Component
{
    stateOnchange=(event)=>
    {
        this.props.dispatch(getcitybystate(event.target.value));
    }

    cityOnchange=(event)=>
    {
        this.props.dispatch(getzipbycity(event.target.value));
    } 

    handleClick=()=>
    {
        this.props.initialize(this.props.initialValues);
    }

    componentDidMount()
    {
        this.props.initialize(this.props.initialValues);
    }

    componentDidUpdate()
    {
        if(this.props.data.status==="CREATED")
        {              
            toastr.success('Success', 'Profile has been Successfully Updated!!');
            document.getElementById("prfbrokerlbtnmodL").click();
            this.props.dispatch(profileview());
        }
    }
   
    render(){   
        const { handleSubmit, pristine, reset, submitting } = this.props
        if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}
        return(    
            <div  data-backdrop="static" data-keyboard="false" className="modal fade brokerprofiledetailsview"  role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">  
                <div className="modal-header">
                   <h5 className="modal-title doc-color" id="exampleModalLabel">EDIT PROFILE</h5>
                    <button type="button" className="close" data-dismiss="modal" id="prfbrokerlbtnmodL" onClick={this.handleClick} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                   </div>
               <div className="modal-body" id="favourite-loads-container">
               <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">  
                        <div className="row">                 
                            <div className="col col-md-12">                               
                                <article className="table-data truck-data shadow bg-white rounded">
                                <form className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}>    
                                <div className="row">
                                        <div className="col col-md-6">
                                            <div className="form-group">
                                            <Field type="text" name="firstName" component={renderField} controlClass="requiredfield"  label="First Name"/>                                
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="companyEmail" component={renderField} controlClass="requiredfield"  label="Email"/>
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="specialityId" data={this.props.specialitytypelist.data} component={renderSelect} label="Speciality"/>                                
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="currentAddress1" component={renderField} className="form-control" label="Address" />
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" className="form-control" component={renderField} normalize={normalizePhone} controlClass="requiredfield" name="phoneNumber" label="Primary Phone" />
                                            </div>
                                        </div>     
                                        <div className="col col-md-6">
                                            <div className="form-group">
                                            <Field type="text" name="currentStateCode"  onChange={this.stateOnchange} controlClass="requiredfield" component={renderSelect} label="State" data={this.props.state.data}/>                            
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="currentCityId" onChange={this.cityOnchange} component={renderSelect} data={this.props.CityVal} keyColumn='cityId' keyValue='cityName' label="City" />                                                                                        
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="currentZipId" keyColumn='zipId' keyValue='zipValue' data={this.props.ZipVal} component={renderSelect} label="Zip"/>                        
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="websiteAddress" component={renderField} label="Website"/>
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="companyName" component={renderField} className="form-control" label="Company" />
                                            </div>
                                        </div>                                                           
                                    </div>
                                    <div className="row">
										<div className="col col-md-12">
											<div className="form-group form-group-btns">
												<button type="submit" className="btn btn-primary btn-sm">Save</button>&nbsp;&nbsp;
												<button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-primary btn-sm">Reset</button>
											</div>
										</div>
									</div>
                                    </form>    
                                   </article>  
                                 </div>
                             </div>
                         </div>                   
                    </div>
                </div> 
                </div>
            </div>
        </div>    
         
        )
       
    }
}

const PageOneForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    })(brokerProfilEdit)

  const mapStateToProps = (state,ownProps) => { 
    return{
        CityVal:state.loadReducer.CityVal,
        ZipVal:state.loadReducer.ZipVal,
        data: state.userReducer.data,
        form: 'brokerprofiledetailsview',
        initialValues: ownProps.modalvalues,
        validate,      
        enableReinitialize: true 
    }    
  }
  
  const PageOneFormContainer = connect(mapStateToProps)(PageOneForm)
    
  export default PageOneFormContainer