import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { assetlist } from '../../../actions/assetAction';
import EditTrailerPopup from './EditTrailerPopup';

class TrailerList extends Component {
	constructor(props) {
		super(props);		
		this.totalItemsCount=1;
		this.activePage=1;
		this.itemsCountPerPage=10;
		this.pageRangeDisplayed=5;
	}
	componentDidMount() {	
		const params={	
			assettype: 'trailer',
			usertype: 'carrier'			
		}
		this.props.dispatch(assetlist(params));  	
	}


	state={
	   modaldata:[]
	}	
 

	 getModal = (tList,dispatch) => {
		this.setState({ showModal: true, modaldata: tList });
	 };


	render(){
	var products=[];
	if(this.props.loading){
		var indicationhtml = 
		<div className="spinner-border" role="status">
		 <div className="row react-bs-table-no-data"></div>
		 </div>
	}else
	if(this.props.trucks.assetList){
		 products = this.props.trucks.assetList.map(
			row =>{
				return({
					//icon:<i onClick={(e)=>{this.changeicon(e)}} className="icofont-plus-circle"></i>,
					id:row.asset_id,
					Truck:row.asset_id,
					Year:row.model_Year,
					asset_Number:row.asset_Number,
					Make:row.make,
					Model:row.model,
					VIN:row.identification_No,
					Plate:row.license_Plate_No,
					Color:row.color,
					device_id:row.device_id,
					More:<div className="dropdown">
					<label data-toggle="dropdown">&hellip;</label>
					<div className="dropdown-menu">
					<a className="dropdown-item" data-toggle="modal" data-target=".bd-trailersedit-modal-lg" href="/#" onClick={() => {this.getModal(row)}}><i className="icofont-ui-edit"></i> Edit</a>
					<Link className="dropdown-item" 
						to={
							{ 
								pathname: "viewtrailer/"+row.asset_id,
								aboutProps: {									
									id:row.asset_id,
									truckno:row.asset_Number,
									model_Year:row.model_Year,
									asset_Number:row.asset_Number,
									make:row.make,
									model:row.model,
									identification_No:row.identification_No,
									license_Plate_No:row.license_Plate_No,
									color:row.color,
									device_id:row.device_id,
									eld_Provider_Id:row.eld_Provider_Id,
									trucktype:row.truck_Type_Id
								}
							}
						}
						><i className="icofont-eye-alt"></i> View</Link>
					</div>
					</div>	
				});
			}
		);
	}
	else
	{
		products=[];
		indicationhtml = "No Data Found";
	}
	const { SearchBar } = Search;
	const columns=[				
		     {
		        text: 'Trailer #',
		        dataField: 'asset_Number',
		        sort: true
		      },
		      {
		        text: 'Year',
		        dataField: 'Year',
		        sort: true
		      },
		      {
		        text: 'Make',
		        dataField: 'Make',
		        sort: true
		      },
		      {
		        text: 'Model',
		        dataField: 'Model',
		        sort: true
		      },		     
		      {
		        text: 'Plate #',
		        dataField: 'Plate',
		        sort: true
		      },
		      {
		        text: 'Serial',
		        dataField: 'device_id',
		        sort: true
		      },		     
			  {
				dataField: 'More',
				text: 'More'
			 }]
		   const customTotal = (from, to, size) => (
			 <span className="react-bootstrap-table-pagination-total">
			   Showing { from } to { to } of { size } Results
			 </span>
		   );
		   
		   const options = {
			 paginationSize: 4,
			 pageStartIndex: 1,
			 firstPageText: 'First',
			 prePageText: 'Back',
			 nextPageText: 'Next',
			 lastPageText: 'Last',
			 nextPageTitle: 'First page',
			 prePageTitle: 'Pre page',
			 firstPageTitle: 'Next page',
			 lastPageTitle: 'Last page',
			 showTotal: true,
			 paginationTotalRenderer: customTotal
		   };
		
		return(
			<article className="table-data truck-data shadow bg-white rounded">			
				<div className="row no-gutters">
					<div className="col-md-12">
						<ul className="nav nav-tabs" id="myTrucks" role="tablist">
							  <li className="nav-item">
								<a className="nav-link active" id="mytruck-tab" data-toggle="tab" href="#mytruck" role="tab" aria-controls="mytruck" aria-selected="true"><i className="icofont-truck-loaded"></i>My Trailer</a>
							  </li>
						</ul>
						<div className="tab-content" id="myTabContent">
							  <div className="tab-pane fade show active" id="mytruck" role="tabpanel" aria-labelledby="mytruck-tab">
								  
								 <ToolkitProvider
										keyField="id"
										data={ products }
										columns={ columns }
										search
									>
										{
										props => (
											<div>
											<SearchBar { ...props.searchProps } />
											<BootstrapTable
												{ ...props.baseProps }  noDataIndication={indicationhtml}  pagination={ paginationFactory(options) } hover  bordered={false}/>
											</div>
										)
										}
								 </ToolkitProvider>
							  </div>
							  <div className="tab-pane fade" id="newtruck" role="tabpanel" aria-labelledby="newtruck-tab">								 
							  </div>
							
							  <EditTrailerPopup modalvalues={this.state.modaldata} />
						</div>
					</div>
				</div>
				
				 
 
			</article>
		)
	}	
}

const mapStateToProps = (state,ownprops) => {	
	return{
		trucks: state.assetReducer.data,
		loading: state.assetReducer.loading,
		pagination:state.assetReducer.pagination,		
		error: state.assetReducer.error
	}    
}	
export default connect(mapStateToProps)(TrailerList)

