
const components = {
	pagenotfound: {
		component: 'PageNotFound',
		url: '/pagenotfound',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	loadreportforcustomer: {
		component: 'Loadreportforcustomer',
		url: '/loadreportforcustomer',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	truckreportforcustomer: {
		component: 'TruckReportForcustomer',
		url: '/truckreporforcustomer',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	hosreportforcustomer: {
		component: 'HosReportForcustomer',
		url: '/hosreportforcustomer',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	drivers: {
		component: 'Drivers',
		url: '/drivers',
		title: 'Drivers',
		icon: 'menu',
		module: 1
	},
	brokerdashboard: {
		component: 'BrokerDashboard',
		url: '/dashboard',
		title: 'Dashboard',
		icon: 'menu',
		module: 1
	},
	brokerprofileview: {
		component: 'brokerprofileview',
		url: '/profileView',
		title: 'profileview',
		icon: 'menu',
		module: 1
	},
	brokertrucks: {
		//component: 'BrokerTrucks',
		component: 'CarrierTrucks',
		url: '/trucks',
		title: 'trucks',
		icon: 'menu',
		module: 1
	},
	brokercarriers: {
		component: 'BrokerCarriers',
		url: '/carriers',
		title: 'Carriers',
		icon: 'menu',
		module: 1
	},
	brokerloads: {
		component: 'BrokerLoads',
		url: '/loads',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	brokerdocuments: {
		component: 'BrokerDocuments',
		url: '/documents',
		title: 'documents',
		icon: 'menu',
		module: 1
	},
	brokercontacts: {
		component: 'BrokerContacts',
		url: '/contacts',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	brokeradddocument: {
		component: 'AddDocument',
		url: '/addocument',
		title: 'Adddocumentpage',
		icon: 'menu',
		module: 1
	},
	brokerloadreport: {
		component: 'BrokerLoadReport',
		url: '/report',
		title: 'Report',
		icon: 'menu',
		module: 1
	},
	brokertrailers: {
        component: 'CarrierTrailers',
        url: '/trailers',
        title: 'BrokerTrailers',
        icon: 'menu',
        module: 1
	},
	brokerviewtrailers: {
        component: 'BrokerViewTrailers',
        url: '/viewtrailer',
        title: 'View Trailer',
        icon: 'menu',
        module: 1
    },
    brokerviewtrucks: {
        component: 'BrokerViewTrucks',
        url: '/ViewTruck/:id',
        title: 'View Truck',
        icon: 'menu',
        module: 1
	},
    quickdispatch: {
        component: 'BrokerQuickDispatch',
        url: '/loads/QuickDispatch',
        title: 'Quick Dispatch',
        icon: 'menu',
        module: 1
	},
	brokercancelledloads: {
		component: 'cancelloads',
        url: '/cancelledLoads',
        title: 'View Trailer',
        icon: 'menu',
        module: 1
	},  
	shipper: {
		component: 'shippermodule',
        url: '/shipper',
        title: 'Shipper',
        icon: 'menu',
        module: 1
    },
    geofencecarriers: {
		component: 'GeofenceCarriers',
		url: '/geofence',
		title: 'GeoFence Carriers',
		icon: 'menu',
		module: 1
	}, 
	LoadReport: {
		component: 'LoadReport',
		url: '/loadreport',
		title: 'Load count',
		icon: 'menu',
		module: 1
	},
	Users: {
		component: 'Users',
		url: '/settings/users',
		title: 'Users',
		icon: 'menu',
		module: 2
	},  
	Settings: {
		component: 'Settings',
		url: '/settings',
		title: 'Settings',
		icon: 'menu',
		module: 2
	},  
	Profile: {
		component: 'Profile',
		url: '/Profile',
		title: 'Profile',
		icon: 'menu',
		module: 2
	},  
	CompanyProfile: {
		component: 'CompanyProfile',
		url: '/settings/company_profile',
		title: 'Company Profile',
		icon: 'menu',
		module: 1
	},
	emailaccountlist: {
		component: 'EmailAccountList',
		url: '/settings/email_accounts',
		title: 'Email Accounts',
		icon: 'menu',
		module: 1
	},
	emailtemplatelist: {
		component: 'EmailTemplateList',
		url: '/settings/email_templates',
		title: 'Email Templates',
		icon: 'menu',
		module: 1
	},
	techcarriers: {
		component: 'TechCarriers',
		url: '/carriers',
		title: 'Carriers',
		icon: 'menu',
		module: 2
	},
	Permissions:{
		component: 'Permissions',
		url: '/permissions',
		title: 'Permissions',
		icon: 'menu',
		module: 1
    }, 
};

const BrokerRoutes = {
	broker: {
		routes: [
			components.drivers,
			components.pagenotfound,
			components.loadreportforcustomer,
			components.truckreportforcustomer,
			components.hosreportforcustomer,
			components.brokerdashboard,
			components.brokertrucks,
			components.brokercarriers,
			components.brokerloads,
			components.brokerdocuments,
			components.brokeradddocument,
			components.brokercontacts,
			components.brokerloadreport,
			components.brokertrailers,
			components.brokerviewtrailers,
			components.brokerviewtrucks,
			components.quickdispatch,
			components.brokerprofileview,
			components.brokercancelledloads,
			components.shipper,
			components.geofencecarriers,
			components.LoadReport,
			components.Users,
			components.CompanyProfile,
			components.emailaccountlist,
			components.emailtemplatelist,
			components.techcarriers,
			components.Permissions,
			components.Settings,
			components.Profile
		]
	}
};

export { BrokerRoutes };