import _ from 'lodash';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { assetadd } from '../../../actions/assetAction';
import { renderField, renderSelect } from '../../../helpers/FormControls';
import Bulkupload from './Bulkupload';
import { validate } from './validation';

function submit(values) {
	//window.alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`)
	 const formObject={'licensePlateNo':'','eldProviderId':'',
	 'color':'','make':'','model':'','modelYear':'','identificationNo':'',
	 'plate':'','assetType':'2','companyId':'3','fuelType':'','weight':'','vehicleMileage':'','mileageUnit':''};
	 var formData = _.extend({}, formObject, values);
	 this.props.dispatch(assetadd(formData));
}

class AddTrailerPopup extends Component{
	handleClick = () =>{
		this.props.initialize('addtrailerpopupshippper')
	}	
render(){
	const { handleSubmit, pristine, reset, submitting } = this.props
 // for year
  let minOffset = 0, maxOffset = 20;
  let thisYear = (new Date()).getFullYear();
  let allYears = [];
  for(let x = 0; x <= maxOffset; x++) {
      allYears.push(thisYear - x)
  }
  //const yearList = allYears.map((x) => {return(<option value={x} key={x}>{x}</option>)});
  const yearList = allYears.map(x=>{       
      return{
          "id":x,
          "name":x,
      }
  }); 
 
return(
  <div className="modal fade bd-trailers-modal-lg" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                 <div className="modal-content">     
                   <div className="modal-header">
                   <h5 className="modal-title doc-color" id="exampleModalLabel">ADD TRAILER</h5>
                    <button type="button" className="close" id="traileraddfrm" onClick={this.handleClick} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                   </div>
                    <div className="modal-body table-data">
                                      <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                                         <li className="nav-item">
                                            <a  className="nav-link active" id="addtruck-tab" data-toggle="tab" href="#addtruck" role="tab" aria-controls="addtruck" aria-selected="true" >
                                            ADD TRAILER
                                            </a>
                                          </li>
                                          <li className="nav-item">
                                            <a className="nav-link " id="bulkupload-tab" data-toggle="tab" href="#bulkupload" role="tab" aria-controls="bulkupload" aria-selected="false" >
                                            BULK UPLOAD
                                            </a>
                                          </li>
                                       </ul>
                                       <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="addtruck" role="tabpanel" aria-labelledby="addtruck-tab">
                                              <form className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}>
                                               		<div className="row">                 
														<div className="col col-md-12">
															<h5 className="doc-color">TRAILER INFO</h5>
															<article className="table-data trailer-data shadow bg-white rounded">
																<div className="row">
																	<div className="col col-md-4">
																		<div className="form-group">
																			<Field type="text" className="form-control"  controlClass="requiredfield" name="assetNumber" component={renderField} label="Trailer #"/>                                
																		</div>
																		<div className="form-group">
																			<Field type="text" className="form-control" name="modelYear" id="year" component={renderSelect} data={yearList} label="Year" />                                 
																		</div>
																	</div>
																	<div className="col col-md-4">
																		<div className="form-group">
																			<Field type="text" name="make" component={renderField} label="Make"/>                                  
																		</div>
																		<div className="form-group">
																			<Field type="text" className="form-control"  controlClass="requiredfield" name="licensePlateNo" component={renderField} label="Plate #"/>                                
																		</div>
																	</div>
																	<div className="col col-md-4">
																		<div className="form-group">
																			<Field type="text" name="model" component={renderField} label="Model"/> 
																		</div>
																		<div className="form-group">
																			<Field type="text" className="form-control"  controlClass="requiredfield" name="deviceId" component={renderField} label="Serial"/>                                
																		</div>
																	</div>                           
																</div>
															</article>
															<h5 className="doc-color">NOTES</h5>
															<article className="table-data trailer-data shadow bg-white rounded">
						                                        <div className="row">
						                                            <div className="form-group col col-md-12"> 
																		<Field type="text" name="notes" component={renderField} label="Notes"/>
						                                            </div>             
						                                        </div> 
															</article>
															<div className="row">
																<div className="col col-md-12 ">
																	<div className="modal-footer">
																		<button type="submit" className="btn btn-secondary btn-sm">Save</button>&nbsp;
																		<button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-primary btn-sm">Reset</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
                                              </form>                          
                                              </div>
                                              <div className="tab-pane fade" id="bulkupload" role="tabpanel" aria-labelledby="bulkupload-tab">
											  <Bulkupload />                                             
                                              </div>
                                        </div>
                              </div>
                         </div>
                    </div>
               </div>
)
}
}
export default reduxForm({
    form: 'addtrailerpopupshippper',  
    validate             
})(AddTrailerPopup)