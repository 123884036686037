import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import { taskListService,taskaddService,taskchangeStatusService } from '../services/taskService';
import * as types from '../actions';

async function fetchAsync(func) {
    const response = await func();
    if (response.ok) {
        return await response.json();
    }
    throw new Error("Unexpected error!!!");
}

function* TaskList(payload) { 
    try {   
            const response = yield call( taskListService, payload );
            yield put({type: types.TASK_LIST_SUCCESS, data: response });
    } catch (error) {
            yield put({ type: types.TASK_LIST_ERROR, error })
    }
} 

function* TaskAdd(payload) { 
    try {   
            const response = yield call( taskaddService, payload );   
            yield put({type: types.TASK_ADD_SUCCESS, data: response });
    } catch (error) {
            yield put({ type: types.TASK_ADD_ERROR, error })
    }
} 

function* TaskChangeStatus(payload) { 
        try {   
                const response = yield call( taskchangeStatusService, payload );   
                yield put({type: types.TASK_CHANGE_STATUS_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.TASK_CHANGE_STATUS_ERROR, error })
        }
    } 



export function* taskSaga() {
    yield takeEvery(types.TASK_LIST, TaskList);
    yield takeEvery(types.TASK_ADD, TaskAdd);
    yield takeEvery(types.TASK_CHANGE_STATUS, TaskChangeStatus);
}