import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { loadActionfrcancelled } from '../../../actions/loadActions';
import { STATUS } from '../../../helpers/status';
import { connect } from 'react-redux';
import moment from 'moment';
import { TabsBar } from 'UI-Component';

class cancelLoads extends Component {
    constructor(props) {
        super(props);
        this.getIndex = this.getIndex.bind(this)
    }
    getIndex(index, selectedId) {
        this.setState({ activeId: selectedId })
    }
    state = {
        data: [
            { optName: "Azuga", id: 1 },
            { optName: "Big Road", id: 2 },
            { optName: "Blue Ink", id: 3 }
        ]
    }
    componentDidMount() {
        const params = {
            ...STATUS.LOAD.CANCELLED,
        }
        this.props.dispatch(loadActionfrcancelled(params));
    }
    render() {
        var cancelledloads = [];
        if (this.props.cancelledloads.cancelledloadlist) {
            cancelledloads = [];
            cancelledloads = this.props.cancelledloads.cancelledloadlist.LoadList.map(
                row => {
                    var stringLoadDate = "";
                    var cancelDateTime = "";
                    if (row.stringLoadDate !== "") {
                        stringLoadDate = moment(row.stringLoadDate).format('MM-DD-YYYY');
                    }
                    else {
                        stringLoadDate = "";
                    }
                    if (row.cancelDateTime != null) {
                        cancelDateTime = moment(row.cancelDateTime).format('MM-DD-YYYY hh:mm A');
                    }
                    else {
                        cancelDateTime = "";
                    }
                    var pickUpLocation = "";
                    if (row.orginCityName !== null && row.orginStateCode !== null && row.orginZipValue !== null) {
                         pickUpLocation = row.orginCityName + ', ' + row.orginStateCode + ', ' + row.orginZipValue
                    }
                    else if (row.orginCityName !== null && row.orginStateCode !== null) {
                         pickUpLocation = row.orginCityName + ', ' + row.orginStateCode
                    }
                    else if (row.orginCityName !== null && row.orginZipValue !== null) {
                         pickUpLocation = row.orginCityName + ', ' + row.orginZipValue
                    }
                    else if (row.orginCityName !== null) {
                         pickUpLocation = row.orginCityName
                    }
                    else if (row.orginStateCode !== null) {
                         pickUpLocation = row.orginStateCode
                    }
                    else if (row.orginZipValue !== null) {
                         pickUpLocation = row.orginZipValue
                    }

                    var DeliveryLocation = "";
                    if (row.destinationCityName !== null && row.destinationStateCode !== null && row.destinationZipValue !== null) {
                         DeliveryLocation = row.destinationCityName + ', ' + row.destinationStateCode + ', ' + row.destinationZipValue
                    }
                    else if (row.destinationCityName !== null && row.destinationStateCode !== null) {
                         DeliveryLocation = row.destinationCityName + ', ' + row.destinationStateCode
                    }
                    else if (row.destinationCityName !== null && row.destinationZipValue !== null) {
                         DeliveryLocation = row.destinationCityName + ', ' + row.destinationZipValue
                    }
                    else if (row.destinationCityName !== null) {
                         DeliveryLocation = row.destinationCityName
                    }
                    else if (row.destinationStateCode !== null) {
                         DeliveryLocation = row.destinationStateCode
                    }
                    else if (row.destinationZipValue !== null) {
                         DeliveryLocation = row.destinationZipValue
                    }
                    return ({
                        id: row.loadId,
                        loadNumber: row.loadNumber,
                        stringLoadDate: stringLoadDate,
                        customerName: row.customerName,
                        consigneeName: row.consigneeName,
                        shipperName: row.shipperName,
                        brokerName: row.brokerName,
                        shipperref: row.shipperReferenceNumber,
                        pickUpLocation: pickUpLocation,
                        DeliveryLocation: DeliveryLocation,
                        cancelledDate: cancelDateTime
                    });
                }
            );

        }
        else {
            cancelledloads = [];
        }
        const { SearchBar } = Search;
        const columns = [
            {
                text: 'Load #',
                dataField: 'loadNumber',
                sort: true
            },
            {
                text: 'Date',
                dataField: 'stringLoadDate',
                sort: true
            },
            {
                text: 'Shipper',
                dataField: 'shipperName',
                sort: true
            },
            {
                text: 'Shipper Ref',
                dataField: 'shipperref',
                sort: true
            },
            {
                text: 'Broker',
                dataField: 'brokerName'
            },
            {
                text: 'Equipment',
                dataField: 'equipmentType',
                sort: true
            },
            {
                text: 'Origin',
                dataField: 'pickUpLocation',
                sort: true
            },
            {
                text: 'Destination',
                dataField: 'DeliveryLocation',
                sort: true
            },
            {
                text: 'Cancelled Date',
                dataField: 'cancelledDate',
                sort: true
            }
        ];

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                Showing {from} to {to} of {size} Results
            </span>
        );

        const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
        };
        return (
            <div className="table-data border">
                <TabsBar tabsData={
                    [{ component: 'CANCELLED LOADS', tabID: 'truckinfo-tab', refData: '#truckinfo' }
                    ]}
                    getIndex={this.getIndex}
                    activeTab={'#truckinfo'}
                />
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">

                        <div className="row">
                            <div className="col col-md-12">
                                <article className="table-data truck-data shadow bg-white rounded">
                                    <ToolkitProvider
                                        keyField="id"
                                        data={cancelledloads}
                                        columns={columns}
                                        search
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <SearchBar {...props.searchProps} />
                                                    <BootstrapTable
                                                        {...props.baseProps} pagination={paginationFactory(options)} noDataIndication="No Data Found" hover bordered={false}
                                                    />
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        cancelledloads: state.loadReducer.data,
        loading: state.loadReducer.loading,
        error: state.loadReducer.error
    }
}
export default connect(mapStateToProps)(cancelLoads);