import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'
import { renderField, renderSelect } from '../../../helpers/FormControls';
import { validate } from './validation';

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({ 
    input: { value: omitValue, onChange, onBlur, ...inputProps },data, label, 
    meta:{omitMeta,touched, error},  ...props
  }) => {
    return (
        <div className={` ${(touched && error) ? 'error' : ''}`}>
            <label>{label}</label>
            <input 
                onChange={adaptFileEventToValue(onChange)}
                onBlur={adaptFileEventToValue(onBlur)}
                type="file"
                {...props.input}
                {...props}
                placeholder={label}
            />        
            {touched && error && <span className="error">{error}</span>}
        </div>
    );
};

const renderMembers = (arg) =>({ fields, meta: { error, submitFailed } }) => (    
    <div className='addDocumentData'> 
        <button type="button" id="add_new" onClick={() => fields.length<4 ? fields.push({}):""} hidden>
            Add Member
        </button>
    {
    fields.map((member, index) => (
        <div className="adddocument" key={index}>
            <div className="row">
                <div className="col-md-10">
                    <h4 className="doc-color">Add New Document - {arg}</h4>
                </div>
                <div className="col-md-2">
                    <i className="icofont-ui-delete doc-color add_plus pl-3 float-right" onClick={()=> fields.remove(index)}></i>
                </div>
            </div>
            <article className="table-data truck-data shadow bg-white rounded">
                <div className="row">
                    <div className="form-group col col-md-3">
                        <Field type="text" name={`${member}.order_no`} component={renderSelect}  label={arg}/>
                    </div>
                    <div className="form-group col col-md-3">
                        <Field type="text" name={`${member}.document_type`} component={renderSelect}  label="Document Type"/>
                    </div>
                    <div className="form-group col col-md-3">
                        <Field type="text" name={`${member}.name`} component={renderField} label="Document Name" />
                    </div>
                    <div className="form-group col col-md-3">
                        <Field type="date" name={`${member}.expiration`} component={renderField} label="Expiration" />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col col-md-3">
                        <Field type="text" name={`${member}.issued_by`} component={renderField} label="Issued By" />
                    </div>
                    <div className="form-group col col-md-3">
                        <Field type="date" name={`${member}.issued_date`} component={renderField} label="Issued Date" />
                    </div>
                    <div className="form-group col col-md-3">
                        <Field type="text" name={`${member}.others`} component={renderField} label="Others" />
                    </div>
                    <div className="form-group col col-md-3">
                        <Field name={`${member}.select_file`} id={`select_file${index + 1}`} component={FileInput} type="file" label="Select File"/>
                    </div>
                </div>
            </article>
        </div>               
        ))
    } 
</div>
)
function submit(values){
    const document1={};
    document1.name=values.name;
    document1.expiration=values.expiration;
    document1.issued_by=values.issued_by;
    document1.issued_date=values.issued_date;
    document1.others=values.others;
    document1.select_file=values.select_file;
    // values.members.push(document1);
    const members=values.members;
    // const new_values={...members,document1};
}

function addnew(values){
    document.getElementById("add_new").click();
}

const AdddocumentPopup = props => {
    const { handleSubmit } = props
    return (
        <article className="table-data truck-data shadow bg-white rounded"> 
            <form onSubmit={handleSubmit(submit.bind(this))}>
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="doc-color">Add New Document - {props.match.params.type}</h4>
                    </div>
                </div>
                <article className="table-data truck-data shadow bg-white rounded"> 
                <div className="row">
                    <div className="form-group col col-md-3">
                        <Field type="text" name="order_no" label={props.match.params.type} component={renderSelect} />
                    </div>
                    <div className="form-group col col-md-3">
                        <Field type="text" name="document_type" label="Document Type" component={renderSelect} />
                    </div>
                    <div className="form-group col col-md-3">
                        <Field type="text" name="name" component={renderField} label="Document Name" />
                    </div>
                    <div className="form-group col col-md-3">
                        <Field type="date" name="expiration" component={renderField} label="Expiration" />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col col-md-3">
                        <Field type="text" name="issued_by" component={renderField} label="Issued By" />
                    </div>
                    <div className="form-group col col-md-3">
                        <Field type="date" name="issued_date" component={renderField} label="Issued Date" />
                    </div>
                    <div className="form-group col col-md-3">
                        <Field type="text" name="others" component={renderField} label="Others" />
                    </div>
                    <div className="form-group col col-md-3">
                        <Field name="select_file" id="select_file" component={FileInput} type="file" label="Select File"/>
                    </div>
                </div>
            </article>	
            <FieldArray name="members" component={renderMembers(props.match.params.type)} />
                <div className="mt-2 text-right">
                    <button type="button" className="btn btn-primary btn-sm" onClick={handleSubmit(addnew.bind(this))}><i className="icofont-plus"></i> Add New</button>&nbsp;
                    <button className="btn btn-primary btn-sm">List</button>&nbsp;
                    <button type="submit" className="btn btn-primary btn-sm">Save</button>
                </div>
            <div>
        </div>
    </form>
</article>
)}

export default reduxForm({
  form: 'fieldArrays', 
  validate
})( AdddocumentPopup )