import React,{Component} from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'
import { renderField, renderSelect } from '../../../helpers/FormControls';
import { addDocument } from '../../../actions/documentActions';
import { connect } from 'react-redux';
import { validate } from './validation';
import { formData } from '../../../helpers/formData';

function submit(values,dispatch){ 
     var data = formData(document.querySelector("#documentfrm"));
    if(Array.isArray(data.documentdata))
    {
        data = data.documentdata
    }
    else
    {
        data = [data.documentdata];
    }
    var list=data.map(dList=>{
        return{
            "documentTypeId":dList['members.documentTypeId'],
            "loadId":dList['members.loadId'],
            "documentTitle":dList['members.documentTitle'],
            "expiration":dList['members.expiration'],
            "issuedBy":dList['members.issuedBy'],
            "issuedDate":dList['members.issuedDate'],
            "others":dList['members.others']
        }
    });
  
    //  var files = [];

     var formfileval = document.querySelector('#documentfrm');
     var newval = formfileval.querySelectorAll('.fileclass');
     const documentobjects =
     {
        'document':list,
        'refs':newval
     } 
    this.props.dispatch(addDocument(documentobjects)); 
}

function addnew(values){
    document.getElementById("add_new").click();
      //this.props.dispatch(commonlist()); 
}
class renderMembers extends Component
{
    render()
    {
        const { fields, meta: { } } = this.props;
        return(<div className='addDocumentData'> 
        <button type="button" id="add_new" onClick={() => fields.length<4 ? fields.push({}):""} hidden>
            Add Member
        </button>
    {
    fields.map((member,index) => (
        <div className="adddocument" key={index}>
            <div className="row">
                <div className="col-md-10">
                    <h4 className="doc-color">Add New Document-Load</h4>
                </div>
                <div className="col-md-2">
                    <i className="icofont-ui-delete doc-color add_plus pl-3 float-right" onClick={()=> fields.remove(index)}></i>
                </div>
            </div>
            <article className="table-data truck-data shadow bg-white rounded">
            <div className="json-row" jsoncol="documentdata">
                <div className="row">
                    <div className="form-group col col-md-3">
                        <Field controlClass="json-col" type="text" name={`${member}.loadId`} keyColumn="loadId" keyValue="name" label="Order No" component={renderSelect} data={this.props.loadlist.data}/>
                    </div>
                    <div className="form-group col col-md-3">    
                        <Field controlClass="json-col" type="text" name={`${member}.documentTypeId`} keyColumn="id" keyValue="name" component={renderSelect}  data={this.props.documenttypelist.data} label="Document Type"/>
                    </div>
                    <div className="form-group col col-md-3">
                    
                        <Field controlClass="json-col" type="text" name={`${member}.documentTitle`} component={renderField} label="Document Name" />
                  
                    </div>
                    <div className="form-group col col-md-3">
                    
                        <Field controlClass="json-col" type="date" name={`${member}.expiration`} component={renderField} label="Expiration" />
                  
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col col-md-3">
                    
                        <Field controlClass="json-col" type="text" name={`${member}.issuedBy`} component={renderField} label="Issued By" />
                   
                    </div>
                    <div className="form-group col col-md-3">
                     
                        <Field controlClass="json-col" type="date" name={`${member}.issuedDate`} component={renderField} label="Issued Date" />
                   
                    </div>
                    <div className="form-group col col-md-3">
                    
                        <Field controlClass="json-col" type="text" name={`${member}.others`} component={renderField} label="Others" />
                   
                    </div>
                    <div className="form-group col col-md-3">
                     
                        <Field controlClass="json-col fileclass" name={`${member}.select_file`} id={`select_file${index + 1}`} component={FileInput} type="file" label="Select File" />
                   
                    </div>
                </div>
                </div>
            </article>
        </div>               
        ))
    } 
</div>);
    }
}
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({ 
    input: { value: omitValue, onChange, onBlur, ...inputProps },data, label, controlClass,
    meta:{omitMeta,touched, error},  ...props
  }) => {
    return (
        <div className={` ${(touched && error) ? 'error' : ''}`}>
            <label>{label}</label>
            <input 
                className={`form-control ${(controlClass) ? controlClass : ''}`}
                onChange={adaptFileEventToValue(onChange)}
                onBlur={adaptFileEventToValue(onBlur)}
                type="file"
                name="file"
                {...props.input}
                {...props}
                placeholder={label} 
            />        
            {touched && error && <span className="error">{error}</span>}
        </div>
    );
};



class AdddocumentPopup extends Component {
    componentDidMount()
    {        
        //this.props.dispatch(commonlist());
         document.getElementById("add_new").click();
    }
    render(){
    const { handleSubmit } = this.props;
    // console.log(this.props.loadlist,"loadnumber");
    return (
        <article className="table-data truck-data shadow bg-white rounded"> 
            <form onSubmit={handleSubmit(submit.bind(this))} name="docform" id="documentfrm" encType="multipart/form-data"> 
            <FieldArray name="members" component={renderMembers} props={this.props}/>
                <div className="mt-2 text-right">
                    <button className="btn btn-primary btn-sm">List</button>&nbsp;
                    <button type="submit" className="btn btn-primary btn-sm">Save</button>&nbsp;
                      <button type="button" className="btn btn-primary btn-sm" onClick={handleSubmit(addnew.bind(this))}><i className="icofont-plus"></i> Add New</button>
                </div>
            <div>
        </div>
    </form>
</article>
)}
}
const mapStateToProps = state => {
return {
 
      data:state.documentReducer.data,
      loading: state.documentReducer.loading,
      error: state.documentReducer.error
    }
}
export default connect(mapStateToProps)(reduxForm({ form: 'fieldArrays', validate }) (AdddocumentPopup))