import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { reduxForm } from 'redux-form';
import { validate } from './validation';
import { inviteNewCarrierService } from '../../services/companyService';
import email from '../../splash_email.svg'
import $ from 'jquery';
import InviteNewCarrierWithOutExistingDataComponent from '../uicomponents/InviteNewCarrierWithOutExistingDataComponent';
import InviteNewCarrierWithExistingDataComponent from '../uicomponents/InviteNewCarrierWithExistingDataComponent';
var MC_NO, DOT_NO, UUID_NO;
class InviteCarrier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carrierName: '',
            email: '',
            mcNumber: '',
            dotNumber: '',
            firstName: '',
            lastName: '',
            uuID: '',
            has_carrier: false,
            has_mcnumber: false,
            has_dotnumber: false,
            has_uuID: false,
            has_email: false,
            has_lastname: false,
            has_firstname: false,
            dataBackdrop: 'static',
            emailError: ''
        }
    }
    componentDidUpdate = (prevProps) => {
        MC_NO = ''
        DOT_NO = ''
        UUID_NO = ''
        if (this.props.carrierData.carrierInfo) {
            let carrierCodes = this.props.carrierData.carrierInfo.carrierCodes;
            for (let index = 0; index < carrierCodes.length; index++) {
                if (carrierCodes[index].codeType === 'MC') {
                    MC_NO = carrierCodes[index].codeValue;
                }
                else if (carrierCodes[index].codeType === 'DOT') {
                    DOT_NO = carrierCodes[index].codeValue;
                }
                else if (carrierCodes[index].codeType === 'UUID') {
                    UUID_NO = carrierCodes[index].codeValue;
                }
            }
        }
        if (this.props.carrierData !== prevProps.carrierData) {
            console.log('this.props',this.props)
            if(this.props.carrierData?.uuId){
                this.setState({
                    ...this.state,
                    carrierName: '',
                    uuID: this.props.carrierData.uuId,
                    email: '',
                    mcNumber: MC_NO,
                    dotNumber: DOT_NO,
                    firstName: '',
                    lastName: '',
                    has_no_carrier:true
                });
            }
            else if (this.props.carrierData?.statusInfo?.status === 'NOT_IN_NETWORK') {
                this.setState({
                    ...this.state,
                    carrierName: this.props.carrierData.carrierInfo.carrierName,
                    uuID: this.props.carrierData.carrierInfo.UU_ID,
                    email: '',
                    mcNumber: MC_NO,
                    dotNumber: DOT_NO,
                    uuID: UUID_NO,
                    firstName: '',
                    lastName: '',
                    has_no_carrier:false
                });
            }
            else {
                this.setState({
                    ...this.state,
                    carrierName: this.props.carrierData.carrierInfo.carrierName,
                    uuID: this.props.carrierData.carrierInfo.UU_ID,
                    email: this.props.carrierData.contactInfo.email,
                    mcNumber: MC_NO,
                    dotNumber: DOT_NO,
                    uuID: UUID_NO,
                    firstName: this.props.carrierData.contactInfo.firstName,
                    lastName: this.props.carrierData.contactInfo.lastName,
                    has_no_carrier:false
                });
            }
        }
    }
    set_carrierName = (e) => {
        this.setState({
            ...this.state,
            carrierName: e.target.value.trim()
        });
    }
    set_mcNumber = (e) => {
        this.setState({
            ...this.state,
            mcNumber: e.target.value
        });
    }
    set_dotnumber = (e) => {
        this.setState({
            ...this.state,
            dotNumber: e.target.value
        });
    }
    set_email = (e) => {
        this.setState({
            ...this.state,
            email: e.target.value,
            emailError: ''
        });
    }
    set_firstName = (e) => {
        this.setState({
            ...this.state,
            firstName: e.target.value.trim()
        });
    }
    set_lastName = (e) => {
        this.setState({
            ...this.state,
            lastName: e.target.value.trim()
        });
    }
    set_uuID = (e) => {
        this.setState({
            ...this.state,
            uuID: e.target.value
        });
    }
    closeModal = (e) => {
        document.getElementById('modal2').style.display = 'none'
        document.getElementById('modal1').style.display = 'none'
        if (this.props.carrierData.statusInfo?.status === 'NOT_IN_NETWORK') {
            this.setState({
                email: '',
                firstName: '',
                lastName: '',
                has_carrier: false,
                has_mcnumber: false,
                has_dotnumber: false,
                has_uuID: false,
                emailError: ''
            });
        } else {
            this.setState({
                emailError: ''
            });
        }
    }
    cancelCloseModal = (e) => {
        document.getElementById('modal2').style.display = 'none'
        if (this.props.carrierData.statusInfo?.status === 'NOT_IN_NETWORK') {
            this.setState({
                email: '',
                firstName: '',
                lastName: '',
                has_carrier: false,
                has_mcnumber: false,
                has_dotnumber: false,
                has_uuID: false,
                emailError: ''
            });
        } else {
            this.setState({
                emailError: ''
            });
        }
    }
    sendbtnclk = async (e) => {
        $('#new_carrier').validate({
            rules: {
                carrierName: {
                    required: true
                },
                email: {
                    required: true
                },
                firstName: {
                    required: true,
                },
                lastName: {
                    required: true
                }
            },
            messages: {
                carrierName: {
                    required: 'Please Enter Carrier Name!'
                },
                 email: {
                    required: 'Please Enter Email!'
                },
                firstName: {
                    required: 'Please Enter FirstName.!',
                },
                lastName: {
                    required: 'Please Enter LastName!'
                }
            }, errorPlacement: function (error, element) {
                if (element.attr('name') === 'fromDate' || element.attr('name') === 'toDate') {
                    error.insertAfter($(element).parents('div.date-picker').find($('.error_place')));
                }
                else {
                    error.insertAfter(element);
                }
            }
        });
        if (this.props.carrierData.carrierInfo) {
            let carrierCodes = this.props.carrierData.carrierInfo.carrierCodes;
            for (let index = 0; index < carrierCodes.length; index++) {
                if (carrierCodes[index].codeType === 'UUID') {
                    UUID_NO = carrierCodes[index].codeValue;
                    await this.setState({ has_uuID: true })
                }
            }
        }
        if ($('#new_carrier').valid()) {
            const params = {
                "carrierInfo": {
                    carrierName: this.state.carrierName
                },
                'contactInfo': {
                    email: this.state.email,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName
                }
            }
            let passCodeType;
            if (this.state.has_uuID || this.state.has_no_carrier) {
                passCodeType = { codeType: 'UUID', codeValue: this.state.uuID }
            }
            else {
                passCodeType = { codeType: 'DOT', codeValue: this.state.dotNumber }
            }
            inviteNewCarrierService(params, passCodeType).then((response) => {
                if (response.status) {
                    if (response.status === 'OK') {
                        toastr.success('Success', response.message);
                        this.closeModal();

                    } else {
                        this.setState({ emailError: response.message })
                    }
                }
            });
            await this.setState({ has_uuID: false })
        }
        //removing empty space
        this.state.email = this.state.email.trim();
        this.state.firstName = this.state.firstName !== null ? this.state.firstName.trim() : this.state.firstName;
        this.state.lastName = this.state.lastName !== null ? this.state.lastName.trim() : this.state.lastName;
    }
    render() {
        return (
            <div className='modal fade' id='modal2' data-keyboard='false' tabIndex='-1' role='dialog' aria-labelledby='mySmallModalLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered modal-lz'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id='inviteModalLabel'> Invite New Carrier</h5>
                            <button type='button' className='close' id='inviteaddmodalbox' aria-label='Close' onClick={(e) => { this.closeModal(e); }}>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body table-data'>
                            <div className='tab-content' id='myTabContent'>
                                <div className='tab-pane fade show active' id='carrierinfo' role='tabpanel' aria-labelledby='carrierinfo-tab'>
                                    <div className='img text-center'>
                                        <img src={email} alt='' />
                                    </div>
                                    <span className='icofont-info-circle' style={{ color: '#44a4db', fontSize: 'medium', marginLeft: '6rem' }}></span> Send Carrier Invitation to join TruckerCloud and Connect
                                    {this.props.carrierData.statusInfo?.status === 'NOT_IN_NETWORK' ?
                                        <InviteNewCarrierWithOutExistingDataComponent state={this.state}
                                            set_lastName={(e) => { this.set_lastName(e) }}
                                            set_firstName={(e) => { this.set_firstName(e) }}
                                            set_email={(e) => { this.set_email(e) }}
                                            set_dotnumber={(e) => { this.set_dotnumber(e) }}
                                            set_mcNumber={(e) => { this.set_mcNumber(e) }}
                                            set_carrierName={(e) => { this.set_carrierName(e) }}
                                            set_uuID={(e) => { this.set_uuID(e) }}
                                        />
                                        :this.props.carrierData.statusInfo?.status === 'IN_NETWORK' ?
                                        <InviteNewCarrierWithExistingDataComponent state={this.state}
                                            set_lastName={(e) => { this.set_lastName(e) }}
                                            set_firstName={(e) => { this.set_firstName(e) }}
                                            set_email={(e) => { this.set_email(e) }}
                                            set_dotnumber={(e) => { this.set_dotnumber(e) }}
                                            set_mcNumber={(e) => { this.set_mcNumber(e) }}
                                            set_carrierName={(e) => { this.set_carrierName(e) }}
                                            set_uuID={(e) => { this.set_uuID(e) }}
                                        />
                                        :<InviteNewCarrierWithOutExistingDataComponent state={this.state}
                                        set_lastName={(e) => { this.set_lastName(e) }}
                                        set_firstName={(e) => { this.set_firstName(e) }}
                                        set_email={(e) => { this.set_email(e) }}
                                        set_dotnumber={(e) => { this.set_dotnumber(e) }}
                                        set_mcNumber={(e) => { this.set_mcNumber(e) }}
                                        set_carrierName={(e) => { this.set_carrierName(e) }}
                                        set_uuID={(e) => { this.set_uuID(e) }}
                                    />
                                    }
                                    <div className='row'>
                                        <div className='col col-md-12'>
                                            <div className='form-group form-group-btns'>
                                                <button type='button' className='btn btn-primary1 btn-sm closebtn' id='inviteaddmodalbox' aria-label='Close' onClick={(e) => { this.cancelCloseModal(e); }} >Cancel</button>
                                                <button type='button' onClick={(e) => { this.sendbtnclk(e); }} className='btn btn-primary btn-sm sendbtn '>Send Invitation</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: state.companyReducer.data,
        loading: state.companyReducer.loading,
        error: state.companyReducer.error
    }
}
export default connect(mapStateToProps)(reduxForm({
    form: 'InviteCarrierForm', validate
})(InviteCarrier))