import { authHeader } from '../helpers/authHeader';
import {checkUnauthorized} from '../helpers/appUtil';
require('dotenv').config();

export const statelistservice = (request) => {
   
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    
    const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/statelist';
    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        return {};
    });
};



export const countrylistservice = (request) => {
   
    const BASE_URL= process.env.REACT_APP_BASE_URL;
  
    const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/countrylist';
    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);              
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const assetlistservice = (request) => {
    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
   
    const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/assettypelist';
    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);              
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const loadstatusservice = (request) => {
   
    const BASE_URL= process.env.REACT_APP_BASE_URL;
  
    const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/loadstatuslist';
    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);              
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const loadpointtypeservice = (request) => {
    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
   
    const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/loadpointtypelist';
    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);              
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const eldproviderservice = (request) => {
    
    const BASE_URL= process.env.REACT_APP_BASE_URL;  
   
    const LOGIN_API_ENDPOINT = BASE_URL+'eldprovidersresource/eldproviders/list';
    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);              
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const allEldProviderService = (request) => {
    
    const BASE_URL= process.env.REACT_APP_BASE_URL;  
   
    const LOGIN_API_ENDPOINT = BASE_URL+'publicresource/getalleldproviders';
    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);              
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const trucktypeservice = (request) => {
    
    const BASE_URL= process.env.REACT_APP_BASE_URL;  
    const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/trucktypelist';
    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);              
        return responseData;
    })
    .catch(error => {
        return {};
    });
};
//Fueltype
export const fueltypeservice = (request) => {
    
    const BASE_URL= process.env.REACT_APP_BASE_URL;  
    const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/fueltypelist';
    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const drivertypeservice = (request) => {
    
    const BASE_URL= process.env.REACT_APP_BASE_URL;  
   
    const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/drivertypelist';
    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);              
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const specialitytypeservice = (request) => {
    
    const BASE_URL= process.env.REACT_APP_BASE_URL;  
   
    const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/specialitytypelist';
    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);              
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const carrierNameservice = (request) => {
    
    const BASE_URL= process.env.REACT_APP_BASE_URL;  
   
    const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/usercarriernamelist';
    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);              
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const accessorialsservice = (request) => {
    
    const BASE_URL= process.env.REACT_APP_BASE_URL;  
   
    const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/get-accessorials';
    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);             
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const subscriptionplansservice = (request) => {
    
    const BASE_URL= process.env.REACT_APP_BASE_URL;  
   
    const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/subscriptionplanslist';
    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);              
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const documenttypeservice = (request) => {
    
    const BASE_URL= process.env.REACT_APP_BASE_URL;  
   
    const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/documenttypelist';
    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);              
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const loadservice = (request) => {
    
    const BASE_URL= process.env.REACT_APP_BASE_URL;  
   
    const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/lodnolist';
    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);              
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const Loadstatus = (request) => {
    
    const BASE_URL= process.env.REACT_APP_BASE_URL;     
    const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/reportloadstatuslist';    
    return fetch(LOGIN_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => { 
        checkUnauthorized(responseData.status, responseData.message);              
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const mapboxCredentials = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const MAPBOX_API = BASE_URL + 'commonresource/appSettingsFetchMapBoxkey';    
    return fetch(MAPBOX_API,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => {
        return response.json();
    })
    .then((responseData) => {    
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const getTimeZones = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const MAPBOX_API = BASE_URL + 'commonresource/timezones';    
    return fetch(MAPBOX_API,{
        method: "GET",
        headers: authHeader()
    })
    .then((response) => {
        return response.json();
    })
    .then((responseData) => {    
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

// export const cityListservice = (request) => {
    
//     const BASE_URL= process.env.REACT_APP_BASE_URL;  
   
//     //const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/getcitylist';
//     const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/specialitytypelist';
    
//     return fetch(LOGIN_API_ENDPOINT, {
//         method: 'GET',
//         headers: authHeader()
//     });
// };



// export const ziplistsservice = (request) => {
    
//     const BASE_URL= process.env.REACT_APP_BASE_URL;  
   
//     //const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/getziplist';
//     const LOGIN_API_ENDPOINT = BASE_URL+'commonresource/specialitytypelist';
    
//     return fetch(LOGIN_API_ENDPOINT, {
//         method: 'GET',
//         headers: authHeader()
//     });
// };

