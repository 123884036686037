import Grid from '@mui/material/Unstable_Grid2';
import EldCard from './EldCard';
import React from 'react';

const EldCardList = (props) => {
    const filter = (providerId) => {
        props.checkedData.map((data) => {
            if (data.eldProviderId === providerId) {
                return true
            }
        })
        return false
    }

    const clickEvent = (event, eld, index) => {
        if (eld.overallStatus === 'VERIFIED' || filter(eld?.eldProviderId)) {
            event.preventDefault();
        }
        else {
            props.redirectVerifyELD(eld, index)
        }
    }

    if (props.companies) {
        return (
            <Grid container spacing={2}>
                {
                    props.companies.map((eld, index) => {
                        return (
                            <Grid key={eld.eldVendor} xs={6} md={3} lg={2}>
                                <EldCard 
                                    name={eld.eldVendor}
                                    logo={props.path + eld.eldVendor}
                                    eld={eld}
                                    index={index}
                                    clickEvent={clickEvent}
                                    findMyCredentialsClick={() => props.setELDName(eld.eldVendorDisplayName, eld.eldVendorId, eld.eldVendor)}
                                    available={!(eld?.overallStatus === 'VERIFIED' || (!props.diableCheck(eld?.eldProviderId) && props.onboardedEDLs > 4))}
                                    previouslyOnboardedELDs={props.previouslyOnboardedELDs}
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>
        );
    }
    else {
        return (
            <>No ELDs were found.</>
        )
    }
}

export default EldCardList;