const components = {
	factordashboard: {
		component: 'FactorDashboard',
		url: '/dashboard',
		title: 'Dashboard',
		icon: 'menu',
		module: 1
	},
	techcarriers: {
		component: 'TechCarriers',
		url: '/carriers',
		title: 'Carriers',
		icon: 'menu',
		module: 2
	},
	brokercarriers: {
		component: 'BrokerCarriers',
		url: '/carriers',
		title: 'Carriers',
		icon: 'menu',
		module: 1
	},
	carrierprofileview: {
        component: 'carrierprofileview',
        url: '/profileView',
        title: 'View Trailer',
        icon: 'menu',
        module: 1
	},
	FuelAdvance: {
		component: 'FuelAdvance',
		url: '/factoring/fuelAdvances',
		title: 'FuelAdvance',
		icon: 'menu',
		module: 1
	},
	DeliveryConfirmed: {
		component: 'DeliveryConfirmed',
		url: '/factoring/deliveryconfirmed',
		title: 'DeliveryConfirmed',
		icon: 'menu',
		module: 1
	},
	Factoring: {
		component: 'Factoring',
		url: '/factoring',
		title: 'Factoring',
		icon: 'menu',
		module: 2
	},
	AdminMyCarrier:{
		component: 'AdminMyCarrier',
		url: '/lead_carrier',
		title: 'My Carrier',
		icon: 'menu',
		module: 1
	},
	FactorCarriers: {
		component: 'FactorCarriers',
		url: '/carrier_dashboard',
		title: 'Carriers',
		icon: 'menu',
		module: 2
	},
	TechpartnerTrucks: {
		component: 'TechpartnerTrucks',
		url: '/trucks',
		title: 'Trucks',
		icon: 'menu',
		module: 1
	},
	emailtemplatelist: {
		component: 'EmailTemplateList',
		url: '/email_templates',
		title: 'Email Templates',
		icon: 'menu',
		module: 1
	},
	Users: {
		component: 'Users',
		url: '/users',
		title: 'Users',
		icon: 'menu',
		module: 1
	},
	CompanyProfile: {
		component: 'CompanyProfile',
		url: '/company_profile',
		title: 'Company Profile',
		icon: 'menu',
		module: 1
	},
	RoleAccess: {
		component: 'RoleAccess',
		url: '/role_access',
		title: 'Role Access',
		icon: 'menu',
		module: 1
	},
	emailaccountlist: {
		component: 'EmailAccountList',
		url: '/email_accounts',
		title: 'Email Accounts',
		icon: 'menu',
		module: 1
	},
	Permissions:{
		component: 'Permissions',
		url: '/permissions',
		title: 'Permissions',
		icon: 'menu',
		module: 1
    },
	Settings: {
		component: 'Settings',
		url: '/settings',
		title: 'Settings',
		icon: 'menu',
		module: 2
	},  
	Profile: {
		component: 'Profile',
		url: '/Profile',
		title: 'Profile',
		icon: 'menu',
		module: 2
	}, 
}

const FactorRoutes = {
	factor: {
		routes: [
			components.factordashboard,
			components.techcarriers,
			components.brokercarriers,
			components.carrierprofileview,
			components.AdminMyCarrier,
			components.FactorCarriers,
			components.TechpartnerTrucks,
			components.emailtemplatelist,
			components.Users,
			components.TechpartnerTrucks,
			components.CompanyProfile,
			components.RoleAccess,
			components.emailaccountlist,
			components.Permissions,
			components.Factoring,
			components.DeliveryConfirmed,
			components.FuelAdvance,
			components.Settings,
			components.Profile
        ]
    }
};

export { FactorRoutes };