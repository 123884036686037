import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Field, reduxForm } from 'redux-form';
import { getcitybystate, getzipbycity } from '../../../actions/loadActions';
import { adduserAction } from '../../../actions/userActions';
import { renderField, renderSelect, renderSelectForMultiple } from '../../../helpers/FormControls';
import { validate } from './validation';

function submit(values) {
  const formObject={'userTypeId':'1','firstName':'','middleName':'','lastName':'','emailId':'','currentAddress1':'','currentAddress2':'','currentAddress3':'',
                     'current_City':'','currentStateId':'','CurrentZip':'','currentCountryId':'','permenantAddress1':'','permenantAddress2':'',
                     'permenantAddress3':'','permenantCity':'','permanentStateId':'','permanentZip':'','permanentCountryId':'','companyName':'','phoneCountryCode':''
                     ,'phoneNumber':'','altPhoneCountryCode':'','altPhoneNumber':'','faxCountryCode':'','faxNumber':'' };
  var formData = _.extend({}, formObject, values);
  this.props.dispatch(adduserAction(formData));
}

class CarrierNew extends Component {
    
    stateOnchange=(event)=>
    {
        this.props.dispatch(getcitybystate(event.target.value));
    }

    cityOnchange=(event)=>
    {
        this.props.dispatch(getzipbycity(event.target.value));
    }

    componentDidUpdate()
	{
        if(this.props.data.status==="CREATED")
        {              
            toastr.success('Success', 'User has been Successfully Created!');
            setTimeout(function(){
				window.location.reload();
			 }, 2000);
        }
        else if(this.props.data.status==="ALREADY_REPORTED")
        {
            toastr.error('Error', 'User Already Exists!');
            setTimeout(function(){
				window.location.reload();
			 }, 2000);
        }
    }


	
	render() {
        const { handleSubmit } = this.props
       
		return (
			<div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">ADD CARRIER</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body table-data">
							<ul className="nav nav-tabs" id="myTrucks" role="tablist">
								<li className="nav-item">
									<a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">ADD CARRIER</a>
								</li>
							</ul>
							<div className="tab-content" id="myTabContent">
								<div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">
									<form className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}>
                                            <div className="row"> 
                                                <div className="col col-md-12">
                                                    <h5>Carrier Details</h5>
                                                <article className="table-data truck-data shadow bg-white rounded">
                                                <div className="row">
                                                    <div className="col col-md-4">
                                                        <div className="form-group">
                                                            <Field type="text" name="firstname" component={renderField} label="First Name"/> 
                                                        </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="speciality" component={renderSelect} label="Speciality" data={this.props.specialitytypelist.data}/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="carriername" component={renderField} label="Carrier Name"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        {/* <Field type="text" name="currentAddress1" component={renderField} label="Current Address"/> */}
                                                        <Field name="currentAddress1" label="Current Address" component={renderTextArea}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <Field name="permenantAddress1" label="permenantAddress1" component={renderTextArea}/>
                                                        {/* <Field type="text" name="permenantAddress1" component={renderField} label="Permanent Address"/> */}
                                                    </div>
                                                </div>
                                                <div className="col col-md-4">
                                                    <div className="form-group">
                                                        <Field type="text" name="lastName" component={renderField} label="Last Name"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="emailId" component={renderField} label="Email"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="phoneNumber" component={renderField} label="Primary Phone"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="faxNumber" component={renderField} label="Fax"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="website" component={renderField} label="Website"/> 
                                                    </div>
                                                  <div className="form-group">
                                                        <Field type="text" name="eldprovider" component={renderSelectForMultiple} label="ELD Provider" data={this.props.eldproviderlist.data}/> 
                                                    </div>
                                                </div>
                                                <div className="col col-md-4">
                                                    <div className="form-group">
                                                        <Field type="text" name="currentStateId" component={renderSelect}  onChange={this.stateOnchange} className="form-control" data={this.props.statelist.data} label="State" />                                                        
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="current_City" component={renderSelect} data={this.props.CityVal}  onChange={this.cityOnchange} keyColumn='cityId' keyValue='cityName' label="City"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="CurrentZip" component={renderSelect} label="Zip Code" data={this.props.ZipVal} keyColumn='zipId' keyValue='zipValue' /> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="currentCountryId" component={renderSelect} data={this.props.country.data} label="Country" /> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="text" name="nooftrucks" component={renderField} label="No of Trucks"/>
                                                    </div>
                                                </div> 
                                            </div>
                                            </article>
                                            <h5>Description</h5>
                                            <article className="table-data truck-data shadow bg-white rounded">
                                                <div className="row">
                                                    <div className="col col-md-12">
                                                    <Field name="instructions" label="Instructions" component={renderTextArea}/>
                                                    </div>
                                                </div>
                                        </article>
                                        <div className="row">
                                          <div className="col col-md-12">
                                             <div className="form-group form-group-btns">
                                                <button type="submit" className="btn btn-secondary btn-sm">Save</button>&nbsp;
                                                <a href="/#" className="btn btn-primary btn-sm">Reset</a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                </div>
                            </form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const renderTextArea = ({input,label, meta: { touched, error, warning }}) => (
    <div>
        <label>{label}</label>
            <textarea {...input} className="form-control level2" rows="3" ></textarea>
            {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
);

const mapStateToProps = state => {
  return {
    data: state.userReducer.data,
    loading: state.userReducer.loading,
    error: state.userReducer.error,
    CityVal:state.loadReducer.CityVal,
    ZipVal:state.loadReducer.ZipVal
  }
}

export default connect(mapStateToProps)(reduxForm({
	form:'CarrierNewForm',validate
})(CarrierNew))