import React, { Component, Fragment } from 'react';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Loader from '../../common/loader';
import NewAccount from './NewAccount';
import { emailaccountlistservice } from '../../../services/emailAccountService';
import { TableDataComponent } from 'UI-Component';
require('dotenv').config();

class EmailAccountList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageReady: false,
            newAccountPopup: false,
            accountList: [],
            accountData: null,
            companyId: null,
            title: ''
        }
    }
    componentDidMount = () => {
        this.getEmailAccList();
    }
    getEmailAccList = () => {
        let param = {
            payload: {
                usertype: localStorage.getItem('userType')
            }
        }
        emailaccountlistservice(param).then((response) => {
            let accounts = [];
            if (response) {
                accounts = response.map((data, index) => {
                    return {
                        senderName: data.senderName,
                        senderEmail: data.senderEmail,
                        host: data.host,
                        port: data.port,
                        incomingMailServer: data.incomingMailServer,
                        outgoingMailServer: data.outgoingMailServer,
                        accountName: data.accountName,
                        accountId: data.carrierEmailAccountSettingId,
                        isVerified: data.isVerified
                    }
                });
            }
            this.setState({
                ...this.state,
                accountList: accounts,
                pageReady: true,
                newAccountPopup: false,
                accountData: null,
                title: ''
            });
        });
    }
    getEmailAccountColumns = () => {
        return (
            [
                {
                    text: 'Sender Name',
                    dataField: 'senderName',
                    sort: true
                },
                {
                    text: 'Sender Email',
                    dataField: 'senderEmail',
                    sort: true
                },
                {
                    text: 'Is Verified',
                    dataField: 'isVerified',
                    sort: true
                },
                {
                    text: 'Actions',
                    dataField: 'actions',
                    sort: true
                }
            ]
        );
    }
    editAccount = (e, data) => {
        this.setState(
            {
                ...this.state,
                accountData: data,
                newAccountPopup: true,
                title: 'Update Account'
            });
    }
    getEmailAccountData = () => {
        let templateData = [];
        templateData = this.state.accountList.map((data, index) => {
            return {
                senderName: data.senderName,
                senderEmail: data.senderEmail,
                host: data.host,
                port: data.port,
                incomingMailServer: data.incomingMailServer,
                outgoingMailServer: data.outgoingMailServer,
                accountName: data.accountName,
                password: data.password,
                isVerified: (data.isVerified === 1 ? 'Yes' : 'No'),
                actions: <Fragment>
                    <button className='btn icofont-pencil icofonts' onClick={(e) => { this.editAccount(e, data) }}></button>
                </Fragment>
            }
        });
        return templateData;
    }
    loadPage = () => {
        if (this.state.pageReady) {
            return (
                <>
                    <div className='btn-export float-right d-inline-block pr-2'><button className='btn btn-primary' onClick={(e) => { this.openNewAccount() }} >New Account</button></div>
                    <TableDataComponent tableData={this.getEmailAccountData()} columnData={this.getEmailAccountColumns()} noDataIndication={'No data found'} />
                </>
            );
        } else {
            return (<Loader />);
        }
    }
    closeNewAccount = (flag) => {
        if (flag === 1) {
            this.getEmailAccList();
        } else {
            this.setState(
                {
                    ...this.state,
                    newAccountPopup: false,
                    accountData: null,
                    title: ''
                }
            );
        }
    }
    openNewAccount = () => {
        this.setState(
            {
                ...this.state,
                newAccountPopup: true,
                accountData: null,
                title: 'Add New Account'
            }
        );
    }
    render = () => {
        document.title = 'Email Accounts';
        return (
            <article className='table-data truck-data shadow bg-white rounded'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h2>Email Accounts</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        {this.loadPage()}
                    </div>
                </div>
                <NewAccount newAccountPopup={this.state.newAccountPopup} closeNewAccount={(flag) => { this.closeNewAccount(flag) }} accountData={this.state.accountData} title={this.state.title} />
            </article>
        );
    }
}
export default EmailAccountList;