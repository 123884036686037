import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { loadListActionfrdispatch, QuickDispatch, quickdispatchFlagAction, ChkAuthToken, canceledloadflag, loadListActionDelivery } from '../../actions/loadActions';
import moment from 'moment';
import queryString from 'query-string';
import logo from '../../truckergig-logo.png';
import intellitranslogo from '../../intelitrans.png';
import { loadActionfrcancelled } from '../../actions/loadActions';
import { STATUS } from '../../helpers/status';
import { TabsBar } from 'UI-Component';

import $ from 'jquery';
class QuickLoad extends Component {
    constructor(props) {
        super(props);
        this.getIndex = this.getIndex.bind(this)
        this.state = {
            isReady: false,
            verification: '',
            InvalidKeyStatus: true,
            LoadIDArray: [],
            TruckAssignValue: [],
            LoadChecked: [],
            addCheckbox: '',
            LoadIDArrayAssigned: [],
            LoadCheckedAssigned: []
        }
    }
    getIndex(index, selectedId) {
        this.setState({ activeId: selectedId })
    }

    handleGetSelectedData = () => {
        if (this.node.selectionContext.selected.length === 0) {
            toastr.info('Info', 'Please select a truck!');
            return;
        }
        else {
            const params = {
                assignLoad: this.state.TruckAssignValue
            }
            this.props.dispatch(QuickDispatch(params));
            //this.props.dispatch(QuickDispatchfrintellitrans(params)); 
            //this.props.dispatch(cancelledloadsbyloadid(params))
        }
    }


    cancelleddata = () => {
        const params = {
            ...STATUS.LOAD.CANCELLED,
        }
        this.props.dispatch(loadActionfrcancelled(params));
    }

    componentDidMount = () => {
        if (this.props.location.search) {
            let urlparam = this.getUrlParams();
            if (urlparam.parameter && urlparam.verification) {
                localStorage.clear();
                const params = {
                    verification: urlparam.verification
                }
                this.props.dispatch(ChkAuthToken(params));
            }
            else {
                this.props.history.push("/login");
            }
        }
        else {
            this.props.history.push("/login");
        }
    }

    getUrlParams = () => {
        let urlparam = this.props.location.search.substring(1);
        urlparam = JSON.parse('{"' + decodeURI(urlparam).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
        return urlparam;
    }

    componentDidUpdate = (nextProps) => {
        if (this.props.authtokenval) {
            if (nextProps.data !== this.props.authtokenval) {
                if (this.props.authtokenval.status === 0) {
                    this.setState({
                        InvalidKeyStatus: false
                    })
                }
            }
        }

        if (!this.state.isReady) {
            if (this.props.authtokenval.AuthToken) {
                if (this.props.authtokenval.status === 0) {
                    toastr.error('Invalid', 'Verification key not valid');
                    setTimeout(
                        function () {
                            localStorage.clear();
                            this.props.history.push("/login");
                        }
                            .bind(this),
                        1000
                    );
                }
                else {
                    if (this.props.authtokenval.AuthToken !== "" && this.props.authtokenval.AuthToken != null) {
                        localStorage.setItem('authToken', this.props.authtokenval.AuthToken);
                        this.setState({
                            ...this.state,
                            isReady: true
                        });
                        this.props.dispatch(loadListActionfrdispatch());
                        this.props.dispatch(loadListActionDelivery())
                    }
                }
            }
        }

        if (this.props.canceledloadflag === true) {
            if (this.props.data.message === "Loads Cancelled Sucessfully") {
                toastr.success('Success', 'Loads Cancelled Sucessfully!');
                this.props.dispatch(canceledloadflag());
                this.props.dispatch(loadListActionfrdispatch());
                this.props.dispatch(loadListActionDelivery())
            }
        }

        if (this.props.quickdispatchflagAction === true) {
            if (this.props.quickDispatchStatus.status === "ACCEPTED") {
                toastr.success('Success', 'Quick dispatch saved Successfully!');
                this.props.dispatch(quickdispatchFlagAction());
                let paramsval = queryString.parse(this.props.location.search)
                const params = {
                    verification: paramsval.verification
                }
                this.setState({
                    TruckAssignValue: [],
                    LoadChecked: []
                })
                this.props.dispatch(loadListActionfrdispatch(params));
                this.props.dispatch(loadListActionDelivery(params))
            }
            else if (typeof this.props.quickDispatchStatus.status !== "undefined") {
                toastr.error('error', 'Unable to save Quick dispatch!');
                this.props.dispatch(quickdispatchFlagAction());
                let paramsval = queryString.parse(this.props.location.search)
                const params = {
                    verification: paramsval.verification
                }
                this.setState({
                    TruckAssignValue: [],
                    LoadChecked: []
                })
                this.props.dispatch(loadListActionfrdispatch(params));
                this.props.dispatch(loadListActionDelivery(params))
            }
        }
    }
    getQucickload = () => {
        this.props.dispatch(loadListActionfrdispatch());
    }
    getDelivery = () => {
        this.props.dispatch(loadListActionDelivery())
    }

    truckChange = (row, index, e) => {
        console.log(row)
        let { TruckAssignValue, LoadChecked } = this.state;
        var truckVal = $("#assetvalue_" + row.loadId).val();
        var selectId = $("#assetvalue_" + row.loadId).attr('id');
        if (TruckAssignValue.length > 0) {
            TruckAssignValue.map((element) => {
                if (element.loadId === row.loadId) {
                    const index = TruckAssignValue.indexOf(element);
                    if (index > -1) {
                        TruckAssignValue.splice(index, 1);
                    }
                }
            })
        }

        if (LoadChecked.length > 0) {
            LoadChecked.map((element) => {
                if (element === row.loadId) {
                    const index = LoadChecked.indexOf(element);
                    if (index > -1) {
                        LoadChecked.splice(index, 1);
                    }
                    $('#' + selectId).parent().closest('tr').removeClass('active');
                }
            })
        }

        if (truckVal !== "") {
            TruckAssignValue.push({
                'loadId': row.loadId,
                'truckId': truckVal
            })
            LoadChecked.push(row.loadId);
            this.setState({
                addCheckbox: "Active"
            })
            //$('#'+selectId).parent().closest('tr').addClass('active');
        }

        // pickupdrops[index].rowData.pikupcity=PickUpcityId;
        // var Tval = this.state.TruckValue;
        // Tval[index] = truckVal;
        this.setState({
            ...this.state,
            TruckAssignValue: TruckAssignValue,
            LoadChecked: LoadChecked
        })
        console.log(this.state.TruckAssignValue,this.state.LoadChecked)
    }

    render = () => {
        var indicationhtml = "No Data Found";
        var { LoadIDArray, LoadChecked, LoadIDArrayAssigned } = this.state;
        if (this.state.isReady && this.state.InvalidKeyStatus) {
            var data = [];
            var deliveryData = [];
            if (this.props.loading) {
                indicationhtml =
                    <div className="spinner-border" role="status">
                        <div className="row react-bs-table-no-data"></div>
                    </div>
            }
            else {
                if (this.props.loadlistStatus.LoadInfoList) {
                    data = this.props.loadlistStatus.LoadInfoList.map(
                        (row, index) => {
                            var loadDate = "";
                            if (row.loadDate != null) {
                                loadDate = moment(row.loadDate).format('MM-DD-YYYY');
                            }
                            else {
                                loadDate = "";
                            }
                            var pickUpLocation = "";
                            if (row.orginCityName !== null && row.orginStateCode !== null && row.orginZipValue !== null) {
                                pickUpLocation = row.orginCityName + ', ' + row.orginStateCode + ', ' + row.orginZipValue
                            }
                            else if (row.orginCityName !== null && row.orginStateCode !== null) {
                                pickUpLocation = row.orginCityName + ', ' + row.orginStateCode
                            }
                            else if (row.orginCityName !== null && row.orginZipValue !== null) {
                                pickUpLocation = row.orginCityName + ', ' + row.orginZipValue
                            }
                            else if (row.orginCityName !== null) {
                                pickUpLocation = row.orginCityName
                            }
                            else if (row.orginStateCode !== null) {
                                pickUpLocation = row.orginStateCode
                            }
                            else if (row.orginZipValue !== null) {
                                pickUpLocation = row.orginZipValue
                            }

                            var DeliveryLocation = "";
                            if (row.destinationCityName !== null && row.destinationStateCode !== null && row.destinationZipValue !== null) {
                                DeliveryLocation = row.destinationCityName + ', ' + row.destinationStateCode + ', ' + row.destinationZipValue
                            }
                            else if (row.destinationCityName !== null && row.destinationStateCode !== null) {
                                DeliveryLocation = row.destinationCityName + ', ' + row.destinationStateCode
                            }
                            else if (row.destinationCityName !== null && row.destinationZipValue !== null) {
                                DeliveryLocation = row.destinationCityName + ', ' + row.destinationZipValue
                            }
                            else if (row.destinationCityName !== null) {
                                DeliveryLocation = row.destinationCityName
                            }
                            else if (row.destinationStateCode !== null) {
                                DeliveryLocation = row.destinationStateCode
                            }
                            else if (row.destinationZipValue !== null) {
                                DeliveryLocation = row.destinationZipValue
                            }
                            LoadIDArray.push(row.loadId);
                            var truckDefault = ''
                            if (this.state.TruckAssignValue.length > 0) {
                                this.state.TruckAssignValue.map((element) => {
                                    if (element.loadId === row.loadId) {
                                        truckDefault = element.truckId
                                    }
                                })
                            }
                            return ({
                                id: row.loadId,
                                loadNumber: row.loadNumber,
                                stringLoadDate: loadDate,
                                origincity: row.orginCityName,
                                destcity: row.destinationCityName,
                                orginStateName: row.orginStateName,
                                destinationStateName: row.destinationStateName,
                                customerName: row.brokerName,
                                shipperName: row.shipperName,
                                carrierName: this.props.loadlistStatus.CompanyName,
                                // carrierName: row.carrierName,
                                shipperref: row.shipperReferenceNumber,
                                selindex: '',
                                pickUpLocation: pickUpLocation,
                                DeliveryLocation: DeliveryLocation,
                                deliveryEarliestTime: row.earliestTime,
                                deliveryLatestTime: row.latestTime,
                                pickUpEarliestTime: row.earliestTime,
                                pickUpLatestTime: row.latestTime,
                                contactNumber: row.contactNumber,
                                seltruckInput: <select name="assetId" onChange={(e) => { this.truckChange(row, index, e) }}
                                    defaultValue={truckDefault} id={"assetvalue_" + row.loadId}
                                    className={truckDefault ? "form-control assetidval Active" : "form-control assetidval"}>
                                    <option value="">Select Truck</option>
                                    {
                                        this.props.loadlistStatus.AssetsNumber.AssetNumberList.map(
                                            (assets, index) =>
                                                <option key={index} value={assets.assetId}>{assets.assetNumber}</option>

                                        )
                                    }
                                </select>,
                            });
                        }
                    );
                }
                else {
                    data = [];
                    indicationhtml = "No Data Found";
                }
                if (this.props.loaddeliveryStatus.LoadInfoList) {
                    deliveryData = this.props.loaddeliveryStatus.LoadInfoList.map(
                        (row, index) => {
                            console.log(row)
                            var loadDateAssigned = "";
                            if (row.loadDate != null) {
                                loadDateAssigned = moment(row.loadDate).format('MM-DD-YYYY');
                            }
                            else {
                                loadDateAssigned = "";
                            }
                            var pickUpLocationAssigned = "";
                            if (row.orginCityName !== null && row.orginStateCode !== null && row.orginZipValue !== null) {
                                pickUpLocationAssigned = row.orginCityName + ', ' + row.orginStateCode + ', ' + row.orginZipValue
                            }
                            else if (row.orginCityName !== null && row.orginStateCode !== null) {
                                pickUpLocationAssigned = row.orginCityName + ', ' + row.orginStateCode
                            }
                            else if (row.orginCityName !== null && row.orginZipValue !== null) {
                                pickUpLocationAssigned = row.orginCityName + ', ' + row.orginZipValue
                            }
                            else if (row.orginCityName !== null) {
                                pickUpLocationAssigned = row.orginCityName
                            }
                            else if (row.orginStateCode !== null) {
                                pickUpLocationAssigned = row.orginStateCode
                            }
                            else if (row.orginZipValue !== null) {
                                pickUpLocationAssigned = row.orginZipValue
                            }

                            var DeliveryLocationAssigned = "";
                            if (row.destinationCityName !== null && row.destinationStateCode !== null && row.destinationZipValue !== null) {
                                DeliveryLocationAssigned = row.destinationCityName + ', ' + row.destinationStateCode + ', ' + row.destinationZipValue
                            }
                            else if (row.destinationCityName !== null && row.destinationStateCode !== null) {
                                DeliveryLocationAssigned = row.destinationCityName + ', ' + row.destinationStateCode
                            }
                            else if (row.destinationCityName !== null && row.destinationZipValue !== null) {
                                DeliveryLocationAssigned = row.destinationCityName + ', ' + row.destinationZipValue
                            }
                            else if (row.destinationCityName !== null) {
                                DeliveryLocationAssigned = row.destinationCityName
                            }
                            else if (row.destinationStateCode !== null) {
                                DeliveryLocationAssigned = row.destinationStateCode
                            }
                            else if (row.destinationZipValue !== null) {
                                DeliveryLocationAssigned = row.destinationZipValue
                            }
                            LoadIDArrayAssigned.push(row.loadId);
                            var truckDefaultAssigned = ''
                            if (row.assetId !== null) {
                                this.props.loaddeliveryStatus.AssetsNumber.AssetNumberList.map((element) => {
                                    if (element.assetId === row.assetId) {
                                        truckDefaultAssigned = element.assetId
                                    }
                                })
                            }
                            return ({
                                idAssigned: row.loadId,
                                loadNumberAssigned: row.loadNumber,
                                stringLoadDateAssigned: loadDateAssigned,
                                origincityAssigned: row.orginCityName,
                                destcityAssigned: row.destinationCityName,
                                orginStateNameAssigned: row.orginStateName,
                                destinationStateNameAssigned: row.destinationStateName,
                                customerNameAssigned: row.shipperName,
                                customerName: row.brokerName,
                                shipperName: row.shipperName,
                                // carrierName: row.carrierName,
                                carrierName: this.props.loadlistStatus.CompanyName,
                                shipperrefAssigned: row.shipperReferenceNumber,
                                selindexAssigned: '',
                                pickUpLocation: pickUpLocationAssigned,
                                DeliveryLocation: DeliveryLocationAssigned,
                                deliveryEarliestTime: row.earliestTime,
                                deliveryLatestTime: row.latestTime,
                                pickUpEarliestTime: row.earliestTime,
                                pickUpLatestTime: row.latestTime,
                                contactNumber: row.contactNumber,
                                seltruckInputAssigned: <select name="assetId" onChange={(e) => { this.truckChange(row, index, e) }}
                                    defaultValue={truckDefaultAssigned} id={"assetvalue_" + row.loadId}
                                    className={truckDefaultAssigned ? "form-control assetidval Active" : "form-control assetidval"}>
                                    <option value="">Select Truck</option>
                                    {
                                        this.props.loaddeliveryStatus.AssetsNumber.AssetNumberList.map(
                                            (assets, i) =>
                                                <option key={i + 1} value={assets.assetId}>{assets.assetNumber}</option>
                                        )
                                    }
                                </select>,
                            });
                        }
                    );
                }
                else {
                    deliveryData = [];
                    indicationhtml = "No Data Found";
                }
            }
            const { SearchBar } = Search;
            const columns = [
                {
                    dataField: 'loadSelInput',
                    text: ''
                },
                {
                    text: 'Load #',
                    dataField: 'loadNumber',
                    sort: true
                },
                {
                    text: 'Date',
                    dataField: 'stringLoadDate',
                    sort: true
                },
                {
                    text: 'Customer Name',
                    dataField: 'customerName',
                    sort: true
                },
                {
                    text: 'Shipper Name',
                    dataField: 'shipperName',
                    sort: true
                },
                {
                    text: 'Carrier Name',
                    dataField: 'carrierName',
                    sort: true,
                },
                {
                    text: 'Truck Number',
                    dataField: 'seltruckInput',
                    sort: true,
                    headerStyle: { width: '10%' }
                }
            ]
            const deliveryColoumn = [
                {
                    text: 'Load #',
                    dataField: 'loadNumberAssigned',
                    sort: true
                },
                {
                    text: 'Date',
                    dataField: 'stringLoadDateAssigned',
                    sort: true
                },
                {
                    text: 'Customer Name',
                    dataField: 'customerName',
                    sort: true
                },
                {
                    text: 'Shipper Name',
                    dataField: 'shipperName',
                    sort: true
                },
                {
                    text: 'Carrier Name',
                    dataField: 'carrierName',
                    sort: true,
                },
                {
                    text: 'Truck Number',
                    dataField: 'seltruckInputAssigned',
                    sort: true,
                    headerStyle: { width: '10%' }
                },
            ]
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Showing {from} to {to} of {size} Results
                </span>
            );

            const options = {
                sizePerPage: 10,
                paginationSize: 4,
                pageStartIndex: 1,
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                showTotal: true,
                paginationTotalRenderer: customTotal
            };
            const expandRow = {
                renderer: row => (
                    console.log('row', row),
                    <article className='table-data truck-data shadow bg-white rounded'>
                        <div className='row no-gutters'>
                            <div className='col-md-12'>
                                <ul className='nav nav-tabs' id='myTrucks' role='tablist'>
                                    <li className='nav-item'>
                                        <a className='nav-link active' id='pickup-tab' data-toggle='tab' href={`#pickup_${row.id}`} role='tab' aria-controls='pickup' aria-selected='true' >
                                            pickup Details
                                        </a>
                                    </li>
                                    <li className='nav-item'>
                                        <a className='nav-link' id='delivery-tab' data-toggle='tab' href={`#delivery_${row.id}`} role='tab' aria-controls='delivery' aria-selected='false' >
                                            Delivery Details
                                        </a>
                                    </li>
                                </ul>
                                <div className='tab-content' id='myTabContent'>
                                    <div className='tab-pane fade show active' id={`pickup_${row.id}`} role='tabpanel' aria-labelledby='pickup-tab'>
                                        <table className='table table-sm'>
                                            <thead>
                                                <tr>
                                                    <th scope='col'>Address</th>
                                                    <th scope='col'>Contact</th>
                                                    <th scope='col'>Earliest Time</th>
                                                    <th scope='col'>Latest Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    row ?
                                                        <tr>
                                                            <td>{row.pickUpLocation}</td>
                                                            <td>{row.contactNumber}</td>
                                                            <td>{moment(row.pickUpEarliestTime).format('MM-DD-YYYY')}</td>
                                                            <td>{moment(row.pickUpLatestTime).format('MM-DD-YYYY')}</td>
                                                        </tr>
                                                        : (<tr><td className='text-center' colSpan='4'>No Record Found</td></tr>)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='tab-pane fade' id={`delivery_${row.id}`} role='tabpanel' aria-labelledby='delivery-tab'>
                                        <table className='table table-sm'>
                                            <thead>
                                                <tr>
                                                    <th scope='col'>Address</th>
                                                    <th scope='col'>Contact</th>
                                                    <th scope='col'>Earliest Time</th>
                                                    <th scope='col'>Latest Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    row ?
                                                        <tr>
                                                            <td>{row.DeliveryLocation}</td>
                                                            <td>{row.contactNumber}</td>
                                                            <td>{moment(row.deliveryEarliestTime).format('MM-DD-YYYY')}</td>
                                                            <td>{moment(row.deliveryLatestTime).format('MM-DD-YYYY')}</td>
                                                        </tr>
                                                        : (<tr><td className='text-center' colSpan='4'>No Record Found</td></tr>)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                ),
                showExpandColumn: true,
                expandByColumnOnly: true,
                expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                    if (isAnyExpands) {
                        return (
                            <i className='icofont-minus-circle'></i>
                        );
                    }
                    return (
                        <i className='icofont-plus-circle'></i>
                    );
                },
                expandColumnRenderer: ({ expanded }) => {
                    if (expanded) {
                        return (
                            <i className='icofont-minus-circle'></i>
                        );
                    }
                    return (
                        <i className='icofont-plus-circle'></i>
                    );
                }
            };
            var companyName = '';
            if (this.props.loadlistStatus.CompanyName) {
                if (this.props.loadlistStatus.CompanyName !== null) {
                    companyName = this.props.loadlistStatus.CompanyName.toUpperCase()
                } else {
                    companyName = '';
                }
            }
            return (<article className="table-data truck-data shadow bg-white rounded">
                <Fragment>
                    <nav id="user_nav" className="navbar navbar-expand-lg navbar-light bg-light">
                        <a className="navbar-brand" href="/#">
                            <img src={logo} alt='' />
                        </a>
                        <span className="nav-item homelabel" href="#"><b>WELCOME  {companyName}</b></span>
                        <a className="navbar-brand flex-left-auto" href="/#">
                            <img src={intellitranslogo} alt='' />
                        </a>
                    </nav>
                    <br />
                    <div className="row no-gutters">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-end">
                                <ul className="ul-horizontal tab-menus">
                                    <li>
                                        <a href="#" id="reqcancelbtn" onClick={this.handleGetSelectedData} name="cancelbtn" className="btn_carrier_move btn btn-sm btn-primary">Assign</a>
                                    </li>
                                    <li>
                                    </li>
                                </ul>
                            </div>
                            <TabsBar tabsData={
                                [
                                    { component: 'UNASSIGNED LOADS', tabID: 'mytruck-tab', refData: '#mytruck', methodName: (e, data, index) => { this.getQucickload(e, data, index) } },
                                    { component: 'ASSIGNED LOADS ', tabID: 'assigned-tab', refData: '#assigned', methodName: (e, data, index) => { this.getDelivery(e, data, index) } },
                                ]}
                                getIndex={this.getIndex}
                                activeTab={'#mytruck'}
                            />
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="mytruck" role="tabpanel" aria-labelledby="mytruck-tab">
                                    <ToolkitProvider
                                        keyField="id"
                                        data={data}
                                        columns={columns}
                                        search
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <SearchBar {...props.searchProps} />
                                                    <BootstrapTable ref={n => this.node = n}
                                                        selectRow={{
                                                            mode: 'checkbox', clickToSelect: false,
                                                            selected: LoadChecked,
                                                            nonSelectable: LoadIDArray,
                                                            hideSelectAll: true,
                                                            nonSelectableClasses: "Truck-list",
                                                        }}
                                                        {...props.baseProps} pagination={paginationFactory(options)} noDataIndication={indicationhtml}
                                                        hover 
                                                        // expandRow={expandRow}
                                                        bordered={false}
                                                    />
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>
                                <div className="tab-pane fade" id="assigned" role="tabpanel" aria-labelledby="assigned-tab">
                                    <ToolkitProvider
                                        keyField="idAssigned"
                                        data={deliveryData}
                                        columns={deliveryColoumn}
                                        search
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <SearchBar {...props.searchProps} />
                                                    <BootstrapTable ref={n => this.node = n}
                                                        selectRow={{
                                                            mode: 'checkbox',
                                                            clickToSelect: true,
                                                            selected: LoadChecked,
                                                            nonSelectable: LoadIDArrayAssigned,
                                                            hideSelectAll: true,
                                                            nonSelectableClasses: "Truck-list",
                                                        }}
                                                        {...props.baseProps} pagination={paginationFactory(options)} noDataIndication={indicationhtml} hover
                                                        //  expandRow={expandRow}
                                                        bordered={false}
                                                    />
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
                {/* <CancelledLoadpopup  cancelledloadlist={this.props.cancelledloadlist}/> */}
            </article>);
        }
        else if (!this.state.InvalidKeyStatus) {
            return (<Fragment>
                <section className="masterhead">
                    <div className="container h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-md-4 offset-md-4 text-center">
                                <form name="" className="shadow">
                                    <div className="login-logo">
                                        <img src={logo} alt='' />
                                    </div>
                                    <span className="InvalidKeyhomelabel" ><b>Invalid key</b></span>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>);
        }
        else {
            return (<Fragment></Fragment>)
        }
    }
}

const mapStateToProps = state => {
    return {
        loadlistStatus: state.loadReducer.loadlistStatus,
        loaddeliveryStatus: state.loadReducer.loaddeliveryStatus,
        quickDispatchStatus: state.loadReducer.quickDispatchStatus,
        authtokenval: state.loadReducer.authtokenval,
        authtokenvalidval: state.loadReducer.authtokenvalidval,
        data: state.loadReducer.data,
        quickDispatch: state.loadReducer.QuickDispatch,
        quickdispatchflagAction: state.loadReducer.quickdispatchflag,
        authtokenflag: state.loadReducer.authtokenflag,
        loading: state.loadReducer.loading,
        canceledloadflag: state.loadReducer.canceledloadflag,
        cancelledloadlist: state.loadReducer.data.cancelledloadlist
    }
}
export default connect(mapStateToProps)(QuickLoad)

