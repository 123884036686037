import React, { Component, Fragment } from 'react';
import HosList from './HosList';
class Hos extends Component{

	render(){
		return(
			<Fragment>		
				<HosList />
			</Fragment>
		)		
	}
}

export default Hos