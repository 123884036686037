import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadActionRestore } from '../../../actions/loadActions';
import restoreImg from '../../../restore-confirm.png'

class RestoreLoadPopup extends Component {
 
  restoreLoadAPICall(load){
    let params=load;
    this.props.dispatch(loadActionRestore(params));
  }
  render() {
    return (
        <div class='modal' id='restoreloadModal'>
        <div class='modal-dialog modal-dialog-centered modal-lz'>
          <div class='modal-content'>
          <div className="modal-header">
          <h5 className='opt-heading-css'>Restore Load</h5>              
        </div>
            <div class='modal-body'>
            <div className='img text-center'>
            <img src={restoreImg} /><br/>
            <h6 className='opt-sub-heading-css'>Do you want to restore load  <span className='contentCss' >{this.props.loadTab?.loadData?.loadNo} ?</span></h6>              
            <p>Once it is restored it will be not available in cancelled tab</p>
            </div>
            </div>
            <div class='modal-footer'>
              <button type='button'className='btn btn-primary btn-sm' data-dismiss='modal'>Go back</button>
              <button type='button' data-dismiss='modal' className='btn btn-primary btn-sm sendbtn'  onClick={(e) => { this.restoreLoadAPICall(this.props.loadTab?.loadData) }}>Yes restore </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
	return {
		data: state.loadReducer.data,
		LoadList: state.loadReducer.data,
		companyList: state.loadReducer.companyList,
		editvaluesview: state.loadReducer.editvaluesview,
		loading: state.loadReducer.loading,
		error: state.loadReducer.error,
		changeloadstatusupdateflag: state.loadReducer.changeloadstatusupdateflag,
		quickdispatchloads: state.loadReducer.data,
		vehicleData: state.loadReducer.vehicleData
	}
}
export default connect(mapStateToProps)(RestoreLoadPopup);