import * as types from './index';

export const assetlist = (payload) => {
    return {
        type: types.ASSET_LIST,
        payload: payload
    }
};
export const dropdownEldlist = (payload) => {
    return {
        type: types.ELD_LIST,
        payload: payload
    }
};
export const assetadd = (payload) => {
    return {
        type: types.ASSET_ADD,
        payload: payload
    }
};

export const assetupdate = (payload) => {
    return {
        type: types.ASSET_UPDATE,
        payload: payload
    }
};

export const companyAuthEld = (payload) => {
    return {
        type: types.COMPANY_AUTH_ELD,
    }
};

export const getassetlistfortechpartner = (payload) => {
    return {
        type: types.GET_ASSETS_LIST_TECHPARTNER,
        payload: payload
    }
};
export const addNewELD = (payload) => {
    console.log('payload',payload)
    return {
		type: types.POST_NEW_ELD_DATA,
        payload: payload
    }
};