import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { shipperList } from '../../../actions/userActions';
class ShipperList extends Component{

	state={
		modaldata:[]
	 }	

	getModal = (tList,dispatch) => {
		this.setState({ showModal: true, modaldata: tList });
	 };	
	componentDidMount=()=>
	{ 
		this.props.dispatch(shipperList());
	}

	render(){
	var shipperFavrtLists = [];
	var shipperLists = [];
	if(this.props.loading){
		var indicationhtml = 
		<div className="spinner-border" role="status">
		<div className="row react-bs-table-no-data"></div>
		 </div>
	}else
	if(this.props.data.CompanysData)
	{
		shipperLists = []	;	
		shipperLists = this.props.data.CompanysData.map(
			row =>{
				return({
					id:row.companyId,	
					phoneNumber:row.phoneNumber,				
					companyName:row.companyName,
					companyEmail:row.companyEmail,
					currentStateName:row.state, 
					currentCityName:row.city, 
					currentZipValue:row.zipcode,
					specialityTypeName:row.specialityTypeName,	
					laneList:row.laneList
				});
			}
		);
		
	}
	else
	{
		shipperLists = [];	
		indicationhtml = "No Data Found";			
	}	
	const { SearchBar } = Search;
	const shipperFavListcolumns=[	
		{
			text:'Shipper Name',
			dataField:'companyName',
			sort:true
		},
		{
			text:'Phone',
			dataField:'phoneNumber',
			sort:true
		},
		{
			text:'Email',
			dataField:'companyEmail',
			sort:true
		},
		{
			text:'City',
			dataField:'currentCityName',
			sort:true
		},
		{
			text:'State',
			dataField:'currentStateName',
			sort:true
		},	  
		{
		   text:'Zipcode',
		   dataField:'currentZipValue',
		   sort:true
		},
		{
			text:'Speciality',
			dataField:'specialityTypeName',
			sort:true
		}
	]
	const columns=[		  
		{
			text:'Shipper Name',
			dataField:'companyName',
			sort:true
		},
		{
			text:'Phone',
			dataField:'phoneNumber',
			sort:true
		},
		{
			text:'Email',
			dataField:'companyEmail',
			sort:true
		},
		{
			text:'City',
			dataField:'currentCityName',
			sort:true
		},
		{
			text:'State',
			dataField:'currentStateName',
			sort:true
		},	  
		{
		   text:'Zipcode',
		   dataField:'currentZipValue',
		   sort:true
		},
		{
			text:'Speciality',
			dataField:'specialityTypeName',
			sort:true
		}
	]
	const customTotal = (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
		  Showing { from } to { to } of { size } Results
		</span>
	  );
	  
	  const options = {
		paginationSize: 4,
		pageStartIndex: 1,
			firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
		showTotal: true,
		paginationTotalRenderer: customTotal
	  };

	  const laneDetails = {	
		renderer: row => (	
			<article className="table-data truck-data shadow bg-white rounded">  
			   <div className="row no-gutters">
				<div className="col-md-12">
					<ul className="nav nav-tabs" id="myTrucks" role="tablist">
						<li className="nav-item">
							<a className="nav-link active" id={ `pickup-tab_${row.id}` } data-toggle="tab" href={ `#pickup_${row.id}` } role="tab" aria-controls="pickup" aria-selected="true" >
							 Source
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" id="delivery-tab" data-toggle="tab" href={ `#delivery_${row.id}` } role="tab" aria-controls="delivery" aria-selected="false" >
							 Destination
							</a>
						</li>
					</ul>
					<div className="tab-content" id="myTabContent">
							<div className="tab-pane fade show active" id={ `pickup_${row.id}` } role="tabpanel" aria-labelledby="pickup-tab">								
								  <table className="table table-sm">
											<thead>
												<tr>
													<th scope="col">Street Name</th>
													<th scope="col">State</th>
													<th scope="col">City</th>
													<th scope="col">Zipcode</th>
													<th scope="col">Base Price</th>
													<th scope="col">Miles</th>
												</tr>
											</thead>
											<tbody>
											{
												row.laneList.length?(row.laneList.map(function(doc, index) {
												    return(
															<tr key={index}>
																<td>{(doc.sourceAddress===null)?<i>&nbsp;</i>:(doc.sourceAddress)}</td>
																<td>{(doc.sourceCityName===null)?<i>&nbsp;</i>:(doc.sourceCityName)}</td>
																<td>{(doc.sourceStateName===null)?<i>&nbsp;</i>:(doc.sourceStateName)}</td>
																<td>{(doc.sourceZip===null)?<i>&nbsp;</i>:(doc.sourceZip)}</td>	
																<td>{(doc.basePrice===null)?<i>&nbsp;</i>:(doc.basePrice)}</td>
																<td>{(doc.miles===null)?<i>&nbsp;</i>:(doc.miles)}</td>
														</tr>
													)             
												})):(<tr><td className="text-center" colSpan="4">No Record Found</td></tr>)
											}
									</tbody>	
								</table>						
							</div>
							<div className="tab-pane fade" id={ `delivery_${row.id}` } role="tabpanel" aria-labelledby="delivery-tab">								
							<table className="table table-sm">
											<thead>
												<tr>
												    <th scope="col">Street Name</th>
													<th scope="col">State</th>
													<th scope="col">City</th>
													<th scope="col">Zipcode</th>
													<th scope="col">Base Price</th>
													<th scope="col">Miles</th>
												</tr>
											</thead>
											<tbody>
											{
												row.laneList.length?(row.laneList.map(function(doc, index) { 
															return(
																<tr key={index}>
																		<td>{(doc.destinationAddress===null)?<i>&nbsp;</i>:(doc.destinationAddress)}</td>
																		<td>{(doc.destinationCityName===null)?<i>&nbsp;</i>:(doc.destinationCityName)}</td>
																		<td>{(doc.destinationStateName===null)?<i>&nbsp;</i>:(doc.destinationStateName)}</td>
																		<td>{(doc.destinationZip===null)?<i>&nbsp;</i>:(doc.destinationZip)}</td>	
																		<td>{(doc.basePrice===null)?<i>&nbsp;</i>:(doc.basePrice)}</td>
																		<td>{(doc.miles===null)?<i>&nbsp;</i>:(doc.miles)}</td>
															</tr>
														)            
												 })):(<tr><td className="text-center" colSpan="4">No Record Found</td></tr>)
											}
									</tbody>	
								</table>					
							</div>			
					</div>
				</div>
			</div>
		</article>
		),
		 showExpandColumn: true,
		 expandByColumnOnly: true,
		 clickToSelect: false,
		expandHeaderColumnRenderer: ({ isAnyExpands  }) => {
			if (isAnyExpands ) {
			  return (
				<i className="icofont-minus-circle"></i>
			  );
			}
			return (
				<i  className="icofont-plus-circle"></i>
			);
		  },
		expandColumnRenderer: ({ expanded }) => {
			if (expanded) {
			  return (
				<i className="icofont-minus-circle"></i>
			  );
			}
			return (
				<i  className="icofont-plus-circle"></i>
			);
		  }
   };	

	return(
		<article className="table-data truck-data shadow bg-white rounded">
			<div className="row no-gutters">
				<div className="col-md-12">
					<ul className="nav nav-tabs" id="myContacts" role="tablist">
					  	<li className="nav-item">
					    	<a className="nav-link active" id="shippers-tab" data-toggle="tab" href="#shippers" role="tab" aria-controls="shippers" aria-selected="true">SHIPPERS</a>
					  	</li>
						  {/* <li className="nav-item">
					    	<a className="nav-link" id="myshipper-tab" data-toggle="tab" href="#myshipper" role="tab" aria-controls="myshipper" aria-selected="true">MY SHIPPERS</a>
					  	</li> */}
					  	<div className="flex-left-auto">
					  		<ul>
					  			<li>
								  <a href="/#" className="btn btn-primary btn-sm" data-toggle="modal" data-target=".addshipperloadmodal">Add Shipper</a>
					  			</li>
					  		</ul>
					  	</div>
					</ul>
					{/* expandRow={ laneDetails }  */}
					<div className="tab-content" id="myTabContent">
					  	<div className="tab-pane fade show active" id="shippers" role="tabpanel" aria-labelledby="shippers-tab">
						  <ToolkitProvider
									keyField="id"
									data={ shipperLists }
									columns={ columns }
									search
								   >
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={indicationhtml}  hover  expandRow={ laneDetails } 
											 bordered={ false } />
										</div>
									)
									}
						  </ToolkitProvider>
					  	</div>
						<div className="tab-pane fade" id="myshipper" role="tabpanel" aria-labelledby="myshipper-tab">
						  <ToolkitProvider
									keyField="id"
									data={ shipperFavrtLists }
									columns={ shipperFavListcolumns }
									search
								   >
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } indicationhtml = "No Data Found"  hover 
											 bordered={ false } />
										</div>
									)
									}
						  </ToolkitProvider>
					  	</div>
					</div>
				</div>
			</div>
   </article>
	)
  }
}


const mapStateToProps = (state) => {
	console.log(state)
	return{
	   loading : state.userReducer.loading,
	   data : state.userReducer.data
	}    
}
export default connect(mapStateToProps)(ShipperList)