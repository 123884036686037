import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { shipperList } from '../../../actions/userActions';
import ShipperList from './ShipperList';
import ShipperAdd from './ShipperAdd';

class Shipper extends Component{
	componentDidUpdate()
	{
		if(this.props.data.status==="ALREADY_REPORTED")
        {
		   toastr.info('Info', 'Shipper Already Exists!');
		   this.props.dispatch(shipperList());
		   //this.props.dispatch(ContactListactions());	
        }
		else if(this.props.data.status==="ACCEPTED")
		{              
			toastr.success('Success', 'Shipper has been Successfully Created!');
			document.getElementById("addmodalpopup").click(); 
			this.props.dispatch(shipperList());
		}
		
	}
	
	componentDidMount() {
		document.title = 'Shipper';
	}

	render(){
		return(
			<Fragment>
				<ShipperList  />
				<ShipperAdd specialitytypelist={this.props.specialitytypelist} statelist={this.props.statelist} />
			</Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		data: state.userReducer.data,
		error: state.userReducer.error,
	}
}
export default connect(mapStateToProps)(Shipper)