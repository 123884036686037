import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import { trucksService,addTrucksService, editTruckService,viewTruckService,searchTruckService, trucklistservice,truckBulkUploadService } from '../services/trucksService';
import * as types from '../actions';



function* fetchData(payload) {
    try {
        const response = yield call(trucksService,payload);
        yield put({type: types.GET_TRUCK_DATA_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.GET_TRUCK_DATA_ERROR, error: e.message});
    }
}

function* searchData(payload) {
    try {
        const response = yield call(searchTruckService,payload);
        yield put({type: types.SEARCH_TRUCK_DATA_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.SEARCH_TRUCK_DATA_ERROR, error: e.message});
    }
}

function* newTruckData(payload) {
    try {
        const response = yield call( addTrucksService, payload ); 
        yield put({type: types.POST_NEW_TRUCK_DATA_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.POST_NEW_TRUCK_DATA_ERROR, error: e.message});
    }
}

function* viewTruckData(payload) {
    try {
        const response = yield call( viewTruckService, payload );         
        yield put({type: types.VIEW_TRUCK_DATA_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.VIEW_TRUCK_DATA_ERROR, error: e.message});
    }
}

function* editTruckData(payload) { 
    try {   
            const response = yield call( editTruckService, payload );                   
            yield put({type: types.EDIT_TRUCK_DATA_SUCCESS, data:response });
    } catch (error) {
            yield put({ type: types.EDIT_TRUCK_DATA_ERROR, error })
    }
}   


function* fetchTruckList(payload) {
    try {   
        const response = yield call(trucklistservice, payload);
        yield put({type: types.GET_TRUCK_LIST_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.GET_TRUCK_LIST_ERROR, error: e.message});
    }
}

function* bulkuploadTruck(payload) {
    try {   
        const response = yield call(truckBulkUploadService, payload);
        yield put({type: types.TRUCK_BULK_UPLOAD_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.TRUCK_BULK_UPLOAD_ERROR, error: e.message});
    }
}
export function* truckSaga() {
    yield takeEvery(types.GET_TRUCK_DATA, fetchData);  
    yield takeEvery(types.SEARCH_TRUCK_DATA, searchData);  
    yield takeEvery(types.POST_NEW_TRUCK_DATA, newTruckData); 
    yield takeEvery(types.VIEW_TRUCK_DATA, viewTruckData); 
    yield takeEvery(types.EDIT_TRUCK_DATA, editTruckData);
    yield takeEvery(types.GET_TRUCK_LIST, fetchTruckList);
    yield takeEvery(types.TRUCK_BULK_UPLOAD, bulkuploadTruck);
}
// export function* trucklistSaga() {
//     // Does not Allows concurrent fetches of users
//     yield takeLatest(types.GET_TRUCK_LIST, fetchTruckList);
//     yield takeEvery(types.TRUCK_BULK_UPLOAD, bulkuploadTruck);
// }

