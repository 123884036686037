export const validate = values => 
{
    const errors = {}

    /*if (!values.truck) {
        errors.truck = 'Truck # cannot be left blank.!'
    } else if (values.truck.length > 15) {
        errors.truck = 'Must be 15 characters or less.!'
    } else if (values.truck.length < 3) {
        errors.truck = 'Must be 3 characters or greater.!'
    }*/
    // FOR ADD  
    if (!values.assetNumber) {
            errors.assetNumber = 'Truck # can not be left blank.!'
    } 
    if (!values.identificationNo) {
        errors.identificationNo = 'VIN cannot be left blank.!'
    }
    if (!values.deviceId) {
        errors.deviceId = 'Device cannot be left blank.!'
    }

    // FOR EDIT
    if (!values.asset_Number) {
            errors.asset_Number = 'Truck # can not be left blank.!'
    } 
    if (!values.identification_No) {
        errors.identification_No = 'VIN cannot be left blank.!'
    }
    if (!values.device_id) {
        errors.device_id = 'Device cannot be left blank.!'
    }
    if(values.color)
    {
       if (values.color.length > 10) {
        errors.color = 'Must be 10 characters or less.'
      }
    }
    
  return errors
}