import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Loader from './loader';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ReactTooltip from 'react-tooltip';
import {getMyCarriersService} from '../../services/adminService';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Icofont from 'react-icofont';

import AdminMyCarrierEntry from './myCarrierEntry';
import AdminMyCarrierView from './myCarrierView';

class AdminMyCarrier extends Component{
    constructor(props){
        super(props);

        this.state={
            isReady:false,
            carrierList:[],
            pageFlag:'LIST',
            carrierData:{},
            viewStatus:false,
            statusData:''
        }
    }

    componentDidMount = () => {
        document.title = 'Lead Carrier';
        this.getMyCarriers();
    }

    getMyCarriers = () => {
        this.setState({
            ...this.state,
            isReady:false
        });
        getMyCarriersService().then((response) =>{
            console.log(response);
            if(response.myCarrierList){
                this.setState({
                    ...this.state,
                    isReady:true,
                    carrierList:response.myCarrierList,
                    pageFlag:'LIST',
                    carrierData:{}
                });
            }
        });
    }

    viewCarrier = (e, index) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            pageFlag:'VIEW',
            carrierData:this.state.carrierList[index]
        });
    }

    viewStatus = (e, index) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            viewStatus:true,
            statusData:this.state.carrierList[index].status
        });
    }

    getRowData = () => {
        if(this.state.carrierList.length===0){
            return [];
        } else {
            return this.state.carrierList.map((row, index) =>{
                let addDate="";
                if(row.addDate!=="" && row.addDate != null)
                {
                    addDate=moment(row.addDate).format('MM-DD-YYYY');
                }
                return({
                id:row.myCarrierId,
                legalName:row.legalName,
                dotNumber:row.dotNumber,
                phone:<span>
                        <i data-tip data-for={"phone"+row.myCarrierId} id="lanehistoryclass" className="icofont-phone-circle"></i>
                        <ReactTooltip id={"phone"+row.myCarrierId} >
                            <p>Phone : {row.telphone}</p>
                        </ReactTooltip>
                    </span>,
                email:<span>
                        <i data-tip data-for={"email"+row.myCarrierId} id="lanehistoryclass" className="icofont-email"></i>
                        <ReactTooltip id={"email"+row.myCarrierId} >
                            <p>Email : {row.emailAddress}</p>
                        </ReactTooltip>
                    </span>,
                addDate:addDate,
                nbrPowerUnit:row.nbrPowerUnit,
                driverTotal:row.driverTotal,
                statusIcon:<a href="/#" title="Status" onClick={(e) => {this.viewStatus(e, index)}}><Icofont icon="info-circle"/></a>,                
                viewCarrier:<span>
                    <a href="/#" className="tableIcon" title="View" onClick={(e) => {this.viewCarrier(e, index)}}><Icofont icon="eye"/></a> &nbsp;
                    <a href="/#" className="tableIcon" title="Edit" onClick={(e) => {this.editCarrier(e, index, null)}}><Icofont icon="edit-alt"/></a>
                    </span>
                });
            });
        }
    }

    getTableColumns = () => {
        return [
            {
				text:'Legal Name',
				dataField:'legalName',
                sort:true,
                style:{width: '25%'}
            },
            {
				text:'DOT Number',
                dataField:'dotNumber',
                sort:true
            },
            {
				text:'Phone',
                dataField:'phone'
            },
            {
				text:'Email',
                dataField:'email'
            },
            {
				text:'Add Date',
                dataField:'addDate',
                sort:true
            },
            {
				text:'NBR Power Unit',
                dataField:'nbrPowerUnit',
                sort:true
            },
            {
				text:'Driver Total',
                dataField:'driverTotal',
                sort:true
            },
            {
				text:'Status',
                dataField:'statusIcon'
            },
            {
				text:'',
                dataField:'viewCarrier'
            }
        ]
    }

    exportData = () => {        
        if(this.state.carrierList.length===0){
            toastr.info('Info', 'No Data Available!');
            return;
        }
        let csvData = this.state.carrierList.map(row =>{
            let data = {...row};
            let dateVar="";
            if(row.addDate!=="" && row.addDate != null)
            {
                dateVar=moment(row.addDate).format('MM-DD-YYYY');
            }
            data.addDate=dateVar;

            dateVar="";
            if(row.mcs150Date!=="" && row.mcs150Date != null)
            {
                dateVar=moment(row.mcs150Date).format('MM-DD-YYYY');
            }
            data.mcs150Date=dateVar;

            dateVar="";
            if(row.createdTime!=="" && row.createdTime != null)
            {
                dateVar=moment(row.createdTime).format('MM-DD-YYYY hh:mm A');
            }
            data.createdTime=dateVar;

            dateVar="";
            if(row.updatedTime!=="" && row.updatedTime != null)
            {
                dateVar=moment(row.updatedTime).format('MM-DD-YYYY hh:mm A');
            }
            data.updatedTime=dateVar;

            return(data);
        });
        let fileName = 'Lead Carrier';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    addNewCarrier = (index) => {
        this.setState({
            ...this.state,
            pageFlag:'NEW',
            carrierData:{}
        });
    }

    editCarrier = (e, index, carrierData) => {
        let carrier_Data = carrierData;
        if(index || index===0){
            carrier_Data = this.state.carrierList[index];
        }
        this.setState({
            ...this.state,
            pageFlag:'EDIT',
            carrierData:carrier_Data
        });
    }
    
    closeForm = (rowsAffected) => {        
        console.log(rowsAffected);
        if(rowsAffected>0){
            this.getMyCarriers();
        } else {
            this.setState({
                ...this.state,
                pageFlag:'LIST',
                carrierData:{}
            });
        }
    }

    closeViewStatus = () => {
        this.setState({
            ...this.state,
            viewStatus:false,
            statusData:''
        });
    }

    render = () => {
        if(this.state.isReady){
            if(this.state.pageFlag==='LIST'){
                const { SearchBar } = Search;
                const customTotal = (from, to, size) => (
                    <span className="react-bootstrap-table-pagination-total">
                    Showing { from } to { to } of { size } Results
                    </span>
                );
                
                const options = {
                    paginationSize: 4,
                    pageStartIndex: 1,      
                    firstPageText: 'First',
                    prePageText: 'Back',
                    nextPageText: 'Next',
                    lastPageText: 'Last',
                    nextPageTitle: 'First page',
                    prePageTitle: 'Pre page',
                    firstPageTitle: 'Next page',
                    lastPageTitle: 'Last page',
                    showTotal: true,
                    paginationTotalRenderer: customTotal,
                };

                const defaultSorted = [{
                    dataField: 'legalName',
                    order: 'asc'
                }];
                return(
                    <article className="shadow bg-white rounded" id="onboardpage">
                        <div className="row">
                            <div className="col">
                                <h3>Lead Carrier</h3>
                            </div>
                        </div>
                        <article className="table-data truck-data shadow bg-white rounded">
                            <div className="row no-gutters">
                                <div className="col-md-12">
                                    <ToolkitProvider
                                        keyField="id"
                                        data={ this.getRowData() }
                                        columns={ this.getTableColumns() }
                                        search
                                        >
                                        {
                                        props => (
                                            <div>                                            
                                                <div className="SearchDispatched float-right d-inline-block"><SearchBar { ...props.searchProps } /></div>                                            
                                                <div className="btn-export float-right d-inline-block pr-2"><button onClick={(e)=>{this.exportData()}} className='btn btn-primary' >Export</button></div>
                                                <div className="btn-export float-right d-inline-block pr-2"><button onClick={(e)=>{this.addNewCarrier(0)}} className='btn btn-primary' >Add Carrier</button></div>
                                                <div className="btn-export float-right d-inline-block pr-2"><button onClick={(e)=>{this.getMyCarriers()}} className='btn btn-primary' >Refresh</button></div>
                                            <BootstrapTable
                                                { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={'No data found'}  hover bordered={ false } 
                                                defaultSorted={defaultSorted}
                                            />
                                            </div>
                                        )
                                        }
                                    </ToolkitProvider>
                                </div>
                            </div>
                        </article>
                        <Modal show={this.state.viewStatus} id="verificationData">
                            <ModalHeader>
                                <ModalTitle>Carrier Status</ModalTitle>
                            </ModalHeader>
                            <ModalBody>
                                {this.state.statusData}
                            </ModalBody>
                            <ModalFooter>					  
                                <button type="button" className="btn btn-primary" onClick={() => {this.closeViewStatus()}}>Close</button>
                            </ModalFooter>
                        </Modal>
                    </article>
                );
            } else if(this.state.pageFlag==='NEW' || this.state.pageFlag==='EDIT'){               
                return <AdminMyCarrierEntry carrierData={this.state.carrierData} flag={this.state.pageFlag} closeForm={this.closeForm} />
            } else if(this.state.pageFlag==='VIEW'){               
                return <AdminMyCarrierView carrierData={this.state.carrierData} closeForm={this.closeForm} editCarrier={this.editCarrier} />
            }
        } else {
            return(<Loader />);
        }
    }
}

export default AdminMyCarrier;