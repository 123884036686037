import { authHeader } from '../helpers/authHeader';
import {checkUnauthorized} from '../helpers/appUtil';
require('dotenv').config();

export const editCarrierService = (request) => {    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_TRUCK_API_ENDPOINT = BASE_URL+'assetsdata/update'; 
    return fetch(UPDATE_TRUCK_API_ENDPOINT, {
        method: 'POST',
        body:JSON.stringify(request.payload),
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => {   
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const carrierOnboarKeyCheckService = (request) => {    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_TRUCK_API_ENDPOINT = BASE_URL+'publicresource/invitationverification?verificationKey=' + request.verificationKey; 
    return fetch(UPDATE_TRUCK_API_ENDPOINT, {
        method: 'GET',
        body:JSON.stringify(request.payload),
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => {   
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const saveCarrierOnboardAuthService = (request) => {
    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_TRUCK_API_ENDPOINT = BASE_URL+'publicresource/savecarrieronboardauthorized'; 
    return fetch(UPDATE_TRUCK_API_ENDPOINT, {
        method: 'POST',
        body:JSON.stringify(request),
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => {    
        checkUnauthorized(responseData.status, responseData.message);        
        return responseData;
    })
    .catch(error => {
        return {};
    });
};

export const saveCarrierOnboardAllowService = (request) => {
    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_TRUCK_API_ENDPOINT = BASE_URL+'publicresource/carrieronboard_allow'; 
    return fetch(UPDATE_TRUCK_API_ENDPOINT, {
        method: 'POST',
        body:JSON.stringify(request),
        headers: authHeader()
    }).then((response) => response.json())
    .then((responseData) => {    
        checkUnauthorized(responseData.status, responseData.message);        
        return responseData;
    })
    .catch(error => {
        return {};
    });
};
