import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import wave from '../../wave.svg'
import Icofont from 'react-icofont';
import noPhoto from '../../no-photo-available.png'
import logo from '../../truckergig-logo.png';

let path = process.env.REACT_APP_CARRIER_API_URL + 'elds/logo/'
// let path = 'https://carrierapi.truckercloud.com/api/v1/elds/logo/'
class WidgetTermsAndConditionsScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tcData: this.props.location.state,
            tabData: this.prepareTabsData(this.props.location.state),
            indexcount: JSON.parse(sessionStorage.getItem('indexcount')) || 0,
            dummyData: this.props.location.state,
            priviousPassing: this.props.location.state.checkedData,
            tabIndex: 0,
            invitationkey: this.props.location.state.invitationData.invitationkey,
            dataShareAndLOAArr: [],
            isTermsNConditions:false
        };
        this.handleTCChecked.bind(this);
        this.handleChecked.bind(this);
    }
    componentDidMount = () => {
    }
    prepareTabsData(dataToBePrepared) {
        var preparedTabData = []
        this.setState({ tabIndex: dataToBePrepared.checkedData[0].eldIndex })
        dataToBePrepared.checkedData.forEach((element, index) => {
            if (element.overallStatus === 'VERIFIED') {
                preparedTabData.push({ ...element, verifyingImage: '', eldIndex: index, overallStatus: 'VERIFIED', disableELDFormFields: false, isUtilizeCredentials: false, isTermsNConditions: false, isInvalidCredentials: false, methodName: (index) => { this.setIndex(index) }, isDataShared: false })
            }
            else {
                preparedTabData.push({ ...element, verifyingImage: '', eldIndex: index, overallStatus: 'NOT_VERIFIED', attemptsLeft: 3, disableELDFormFields: false, isUtilizeCredentials: true, isTermsNConditions: false, isInvalidCredentials: false, methodName: (index) => { this.setIndex(index) }, isDataShared: false })
            }
        });
        return preparedTabData;
    }
    handleChecked(e, item, i) {
        item.eldDataSharing[e.target.id] = !item.eldDataSharing[e.target.id]
    }
    newHandleTCChecked(){
        this.setState({isTermsNConditions:!this.state.isTermsNConditions})
    }
    async handleTCChecked(index) {
        await this.setState(state => {
            const list = state.tabData.map((item, j) => {
                if (item.eldVendorId === index) {
                    item.isTermsNConditions = !item.isTermsNConditions;
                    item.isDataShared = !item.isDataShared
                }
                else {
                    return item;
                }
            });
            return {
                list
            };
        });
        this.removeDisabledDataShared()
    }
    removeDisabledDataSharedTC = (eldVendorId) => {
        this.state.dataShareAndLOAArr.splice(this.state.dataShareAndLOAArr.findIndex(function (i) {
            return i.eldVendorId === eldVendorId;
        }), 1);
    }
    async removeDisabledDataShared() {
        await this.setState({ dataShareAndLOAArr: [] })
        let newArray = this.state.tabData.filter(function (el) {
            return el.isDataShared === true
        }
        );
        await this.setState({ dataShareAndLOAArr: newArray })
    }
    async changeDataSharingStatus(index, content) {
        await this.setState(state => {
            const list = state.tabData.map((item, j) => {
                if (item.eldVendorId === index && item.isTermsNConditions === true) {
                    item.isDataShared = !item.isDataShared;
                } else {
                    return item;
                }
            });
            return {
                list
            };
        });
        this.removeDisabledDataShared()
    }
    async verifyElds() {
        this.props.history.push('/tcWidgetAuthCarrier', { tcData: this.state.tcData, checkData: JSON.stringify(this.state, this.state.tcData) });
    }
    setIndex(tabIndex) {
        this.setState({ tabIndex: tabIndex })
    }
    preventCheck(e) {
        if (e.target.checked === true) {
            e.target.checked = false
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            return false;
        }
        e.target.checked = true
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        return false;
    }
    carrierCodes() {
        let carrierCode = [];
        carrierCode.push(
            this.state.dummyData.invitationData.carrierInfo.carrierCodes.map((val, index) => {
                return (
                    <h5 className='list-cont-data'><span id='iconCOB'>#</span>{val.codeType}-{val.codeValue}</h5>
                )
            })
        )
        return carrierCode
    }
    addDefaultSrc(ev) {
        ev.target.src = noPhoto
    }
    render() {
        return (<div>
            <article className='parent-container bg-white rounded'>
                <div class='row parent-row'>
                    <div class='col-sm-3 col-md-3 col-lg-3 col-xs-3 onboard-left-side'>
                        <div className='left-row-img'>
                            <div className='in-cob-img-css'><img src={logo} className='logo-img-css' onError={this.addDefaultSrc} alt='Not Found' /> </div>
                            <div className='left-row-after-img'>
                                <div className='list-cont-data'>
                                    <h5 className='list-cont-data'><Icofont icon='icofont-user-alt-3' id='iconCOB' className='cob-icon-css' /> &nbsp;
                                        {this.state.dummyData.invitationData?.contactInfo?.firstName}&nbsp;{this.state.dummyData.invitationData?.contactInfo?.lastName}
                                    </h5>
                                </div>
                                <div className='list-cont-data'>
                                    <h5 className='list-cont-data'><Icofont icon='icofont-truck-alt' id='iconCOB' className='cob-icon-css' /> &nbsp;
                                        {this.state.dummyData.invitationData.carrierInfo?.carrierName}
                                    </h5>
                                </div>
                                <div className='list-cont-data'>
                                    <h5 className='list-cont-data'><Icofont icon='icofont-envelope' id='iconCOB' className='cob-icon-css' /> &nbsp;
                                        {this.state.dummyData.invitationData?.contactInfo?.email}
                                    </h5>
                                </div>
                                <div className='list-cont-data'>
                                    {this.carrierCodes()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='col-sm-9 col-md-9 col-lg-9 col-xs-9 onboard-right-side'>
                        <article className='table-data truck-data  bg-white rounded'>
                            <div className='tab-content ' id='eldTabsContent'>
                                {this.state.tabData.map((tabcontent, tabIndex) => (
                                    <div >
                                        {/* <div className={tabcontent.eldIndex === this.state.tabIndex ? 'tab-pane fade show active' : 'tab-pane fade'} id={tabcontent.eldProviderId} role='tabpanel' aria-labelledby={tabcontent.eldVendor}> */}
                                        {/* required data Permissions starts */}
                                        <h5 className='eldVendorNameCss'>{tabcontent.eldVendorDisplayName}</h5>
                                        <h5 className='subTitleCss'>Required Data Permissions &nbsp;
                                            <Icofont icon='icofont-info-circle' style={{ color: 'var(--main-color)', fontSize: 'medium' }} />
                                            <img src={path + tabcontent.eldVendor} alt='To be loaded' onError={this.addDefaultSrc} className='eldLogo' />
                                        </h5>
                                        <div>
                                            <div className='form-check-header-data'>
                                                <input className='form-check-input'
                                                    type='checkbox'
                                                    id='authenticationData'
                                                    defaultChecked={tabcontent.eldDataSharing.authenticationData}
                                                    readOnly={!tabcontent.eldDataSharing.authenticationDataEnabled || tabcontent.disableELDFormFields}
                                                    onChange={(e) => { tabcontent.eldDataSharing.authenticationDataEnabled ? this.handleChecked(e, tabcontent, tabIndex) : this.preventCheck(e, tabIndex, tabcontent) }} />
                                                <label className='form-check-label' >
                                                    <Icofont icon='icofont-lock iconName' id='iconCOB' /> &nbsp;Authentication
                                                </label>
                                            </div>
                                            <div className='form-check-header-data' >
                                                <input className='form-check-input'
                                                    type='checkbox'
                                                    id='locationData'
                                                    defaultChecked={tabcontent.eldDataSharing.locationData}
                                                    readOnly={!tabcontent.eldDataSharing.locationDataEnabled || tabcontent.disableELDFormFields}
                                                    onChange={(e) => { tabcontent.eldDataSharing.locationDataEnabled ? this.handleChecked(e, tabcontent, tabIndex) : this.preventCheck(e, tabIndex, tabcontent) }} />
                                                <label className='form-check-label ' >
                                                    <Icofont icon='icofont-location-pin iconName ' id='iconCOB' /> &nbsp;Location Data
                                                </label>
                                            </div>
                                            <div className='form-check-header-data'>
                                                <input className='form-check-input'
                                                    type='checkbox'
                                                    id='vehicleData'
                                                    defaultChecked={tabcontent.eldDataSharing.vehicleData}
                                                    readOnly={!tabcontent.eldDataSharing.vehicleDataEnabled || tabcontent.disableELDFormFields}
                                                    onChange={(e) => { tabcontent.eldDataSharing.vehicleDataEnabled ? this.handleChecked(e, tabcontent, tabIndex) : this.preventCheck(e, tabIndex, tabcontent) }} />
                                                <label className='form-check-label ' >
                                                    <Icofont icon='icofont-truck-alt iconName ' id='iconCOB' /> &nbsp;Vehicle Data
                                                </label>
                                            </div>
                                        </div>
                                        {/* additional data Permissions ends */}
                                        {/* required data Permissions starts */}
                                        <h5 className='subTitleCss'>Additional Data Permissions</h5>
                                        <div>
                                            <div className='form-check-header-data'>
                                                <input className='form-check-input'
                                                    type='checkbox'
                                                    id='driverData'
                                                    defaultChecked={tabcontent.eldDataSharing.driverData}
                                                    readOnly={!tabcontent.eldDataSharing.driverDataEnabled || tabcontent.disableELDFormFields}
                                                    onChange={(e) => { tabcontent.eldDataSharing.driverDataEnabled ? this.handleChecked(e, tabcontent, tabIndex) : this.preventCheck(e, tabIndex, tabcontent) }} />
                                                <label className='form-check-label' >
                                                    <i className='icofont-user-alt-7 iconName ' id='iconCOB' /> &nbsp;Driver Data
                                                </label>
                                            </div>
                                            <div className='form-check-header-data'>
                                                <input className='form-check-input'
                                                    type='checkbox'
                                                    id='hoursOfServiceData'
                                                    defaultChecked={tabcontent.eldDataSharing.hoursOfServiceData}
                                                    readOnly={!tabcontent.eldDataSharing.hoursOfServiceDataEnabled || tabcontent.disableELDFormFields}
                                                    onChange={(e) => { tabcontent.eldDataSharing.hoursOfServiceDataEnabled ? this.handleChecked(e, tabcontent, tabIndex) : this.preventCheck(e, tabIndex, tabcontent) }} />
                                                <label className='form-check-label' >
                                                    <i className='icofont-clock-time iconName' id='iconCOB' /> &nbsp;Hours of Service
                                                </label>
                                            </div>
                                            <div className='form-check-header-data'>
                                                <input className='form-check-input'
                                                    type='checkbox' id='engineData'
                                                    defaultChecked={tabcontent.eldDataSharing.engineData}
                                                    readOnly={!tabcontent.eldDataSharing.engineDataEnabled || tabcontent.disableELDFormFields}
                                                    onChange={(e) => { tabcontent.eldDataSharing.engineDataEnabled ? this.handleChecked(e, tabcontent, tabIndex) : this.preventCheck(e, tabIndex, tabcontent) }} />
                                                <label className='form-check-label' >
                                                    <i className='icofont-spanner iconName' id='iconCOB' /> &nbsp;Engine Data
                                                </label>
                                            </div>
                                            <div className='form-check-header-data'>
                                                <input className='form-check-input'
                                                    type='checkbox'
                                                    id='dvirData'
                                                    defaultChecked={tabcontent.eldDataSharing.dvirData}
                                                    readOnly={!tabcontent.eldDataSharing.dvirDataEnabled || tabcontent.disableELDFormFields}
                                                    onChange={(e) => { tabcontent.eldDataSharing.dvirDataEnabled ? this.handleChecked(e, tabcontent, tabIndex) : this.preventCheck(e, tabIndex, tabcontent) }} />
                                                <label className='form-check-label' >
                                                    <i className='icofont-ebook iconName' id='iconCOB' /> &nbsp;DVIR
                                                </label>
                                            </div>
                                        </div>
                                        {/* additional data Permissions ends */}
                                        {this.state.tabData.length > 1 ?
                                            <div className='form-check'>
                                                <a href={tabcontent.tcURL} class='dataShareBtn' target='_blank' rel='noopener noreferrer'> &nbsp;Click to get terms &amp; conditions</a>
                                            </div> : null
                                        }
                                    </div>
                                ))}
                                <div>
                                </div>
                            </div>
                            <h5 className='subTitleCss' >Terms &amp; Conditions</h5>
                            <div className='form-check-header-data'>
                                <input className='form-check-input'
                                    type='checkbox'
                                    id='tcCheckbox'onChange={(e) => { this.newHandleTCChecked()}}
                                />
                                <label className='form-check-label' >
                                    {this.state.tabData.length > 1 ?
                                        <a target='_blank' rel='noopener noreferrer'>&ensp;{`Accept terms & conditions`}</a> :
                                        <a target='_blank' href={this.state.tabData[0].tcURL} rel='noopener noreferrer'>&ensp;{`Accept terms & conditions`}</a>}
                                    {this.state.isTermsNConditions ? <><i class='icofont-tick-mark dataShareBtn2'></i><span className='dataSharetext'>Agreed to Data sharing and LOA</span></> : null}
                                </label>
                            </div>
                            <br />
                            <div className='stepper-wrapper'>
                                <div className='stepper-item completed'>
                                    <div className='step-counter'><i class='icofont-tick-mark'></i></div>
                                    <div className='step-name'>Select Providers</div>
                                </div>
                                <div className='stepper-item completed'>
                                    <div className='step-counter'><i class='icofont-tick-mark'></i></div>
                                    <div className='step-name'>Data Permissions</div>
                                </div>
                                <div className='stepper-item active'>
                                    <div className='step-counter'><i class='icofont-tick-mark'></i></div>
                                    <div className='step-name'>Verify Providers</div>
                                </div>
                            </div>
                            <div className='outer' >
                                <div className='row' >
                                    <div className='col-md-1 wave-img' >
                                        <img src={wave} alt='Not Found' />
                                    </div>
                                    <div className='col welcome-header' >
                                        <span className='welcomeCss'>
                                            Welcome back to TruckerCloud!
                                        </span> <br />
                                        <span>
                                            Your are invited to connect with <strong className='contentCss'> {this.state.dummyData.invitationData.invitationInfo.invitingUserFirstName}  {this.state.dummyData.invitationData.invitationInfo.invitingUserLastName} </strong>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <div class='in-cob-footer'>
                            <div className='footer-buttons'>
                                <button type='button' className={this.state.verifiedNoOfEldProgress === 100 && !this.state.isSignedUp ? 'btn btn-primary3 footer-button' : 'btn btn-primary3 footer-button-signup'}>  <span className='checkmark'>L</span> &nbsp; {this.state.tabData.length} ELD  selected</button>
                                <button type='button' className={this.state.verifiedNoOfEldProgress === 100 && !this.state.isSignedUp ? 'btn btn-primary2 footer-button' : 'btn btn-primary2 footer-button-signup'} >  <span className='checkmarkDouble'>L</span>  <span className='checkmarkDoubleBelow' style={{ float: 'left', fontSize: '9px', marginRight: '-5px', marginTop: '-1px' }}>L</span> &nbsp;{this.state.dataShareAndLOAArr.length} ELD Data Shared</button>
                                {this.state.isTermsNConditions ?<button type='button' onClick={() => { this.verifyElds() }} className='btn btn-primary footer-button data-share-btn' >Verify Credentials</button>
                                    : <button type='button' className='btn btn-primary footer-button eld-veify-btn' disabled={true} style={{ cursor: 'not-allowed' }}>Verify Credentials</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </article>

        </div>

        )
    }
}
const mapStateToProps = state => {
    return {
        loading: state.companyReducer.loading,
        searchFlag: state.companyReducer.searchFlag,
        error: state.companyReducer.error,
    }
}

export default connect(mapStateToProps)(reduxForm({
    form: 'WidgetTermsAndConditionsScreenForm'
})(WidgetTermsAndConditionsScreen))
