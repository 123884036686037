import React, { Component, Fragment } from 'react';
import Trucklist from '../trucks/Trucklist';
import AddTruckPopup from './AddTruckPopup';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { assetlist } from '../../../actions/assetAction';

class Trucks extends Component{	
	componentDidUpdate()
	{
		if(this.props.data.status==="ALREADY_REPORTED")
        {
		   toastr.info('Info', 'Truck Already Exists!');
			const params={				
				assettype: 'truck',
				//usertype: 'carrier'			
			}		
			this.props.dispatch(assetlist(params)); 		
        }
        else if(this.props.data.status==="CREATED")
        {
			toastr.success('Success', 'Truck Created Successfully!');
			const params={				
				assettype: 'truck',
				//usertype: 'carrier'			
			}		
			this.props.dispatch(assetlist(params));  
			document.getElementById("addfrmshpr").click(); 
		}
		else if(this.props.data.status==="OK")
        {
			toastr.success('Success', 'Truck Updated Successfully!');
			const params={				
				assettype: 'truck',
				usertype: 'carrier'			
			}		
			this.props.dispatch(assetlist(params));  
			document.getElementById("editfrmshpr").click(); 
        }		
	}

	componentDidMount() {
		document.title = 'Trucks';
	}
	render(){	
		return(
			<Fragment>							
				<Trucklist truck={this.props.trucktypelist} fueltypelist={this.props.fueltypelist} eld={this.props.eldproviderlist}/>						
				<AddTruckPopup trucktypelist={this.props.trucktypelist} fueltypelist={this.props.fueltypelist} eldproviderlist={this.props.eldproviderlist}/>
			</Fragment> 				
		)
	}
}

const mapStateToProps = state => {
	return {
		data: state.assetReducer.data,
		error: state.assetReducer.error,
	}
}
export default connect(mapStateToProps)(Trucks)
