import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
//import DashboardSearch from './DashboardSearch';
import GoogleMapBreadcrump from './GoogleMapBreadcrump';
// import DashboardGoogleMap from './DashboardGoogleMap';
import { eldAssetList, dashboardAction } from '../../actions/dashboardActions';
import { Row, Col } from 'react-bootstrap';
import {isOnboarded} from '../../services/companyService';
import DashboardTrucksNotOnBoarded from './DashboardTrucksNotOnBoarded';
import GoogleMapMobileCarrierBreadcrump from './GoogleMapMobileCarrierBreadcrump';
import DashboardGoogleMapMobileCarrier from './DashboardGoogleMapMobileCarrier';
import { DashboardTrucksComponent, GetEld, DashboardGoogleMap } from 'UI-Component'
import loadVehicleicon from '../../images/loadedtruck.png'
import $ from 'jquery'
require('dotenv').config();

class Dashboard extends Component{
	constructor(props) {
		super(props);
		this.state={
			selectedData : [],
			checkedData : [],
			viewFlag : false,
			routeData : [],
			updated : 0,
			TrucksNum :[],
			mapZoom : 7,
			mapCenter : [-96,37],
			truckState : 'active',
			mapViewMode:'static',
			truckInfo:{},
			carriers : [],			
			eldList : [],
			mapFlag:0
		}
		this.selectedMenu=0;
		this.userType= localStorage.getItem('userType');
		
		this.SUBSCRIPTION_PLAN_ID=localStorage.getItem('SUBSCRIPTION_PLAN_ID');

	}
	componentDidMount() {
		document.title = 'Dashboard';
		isOnboarded().then((response) =>{
			if(response.Status){
				this.setState({
					...this.state,
					isReady:true,
					isOnboarded:response.Status
				});
			}
			else if(response.status){
				if(response.status==="UNAUTHORIZED"){
					localStorage.clear();
            		window.location = '/login';
				}
			} else{
				this.setState({
					...this.state,
					isReady:true
				});
			}
		});

		//this.props.dispatch(eldAssetList(1));
		this.props.dispatch(dashboardAction()); 
		//this.props.dispatch(truckList());
		
	}	
	componentDidUpdate (){
		if(this.props.dashboard.dashboard)
		{

			if(this.selectedMenu===0) { 
				if(this.props.dashboard.dashboard.carrierList){
					if(this.props.dashboard.dashboard.carrierList.length>0){
						this.selectedMenu=this.props.dashboard.dashboard.carrierList[0].Invited_Company_Id;
					}
				} else if(this.props.dashboard.dashboard.eldList){
					if(this.props.dashboard.dashboard.eldList.length>0){
						this.selectedMenu=this.props.dashboard.dashboard.eldList[0].ELD_Provider_Id;
					}
				}
			}
		}

		if(this.state.updated === 0 && this.props.dashboard.dashboard){
			if(this.state.checkedData  !== this.props.dashboard.dashboard.active) {
				this.setState({
					selectedData: this.props.dashboard.dashboard.active,
					checkedData : this.props.dashboard.dashboard.active,
					viewFlag : false,
					routeData : [],
					TrucksNum : this.props.dashboard.dashboard.active,
					carriers:this.props.dashboard.dashboard.carrierList,
					eldList : this.props.dashboard.dashboard.eldList
				});
			}
		}
	}
	handleTabClicked = (val,stopped) => {	
		if(this.props.dashboard.dashboard){
			if(val === 1){			// For Active Trucks 			
				this.setState({
					updated : 1,
					selectedData: this.props.dashboard.dashboard.active,
					checkedData : this.props.dashboard.dashboard.active,
					viewFlag : false,
					routeData : [],
					TrucksNum : this.props.dashboard.dashboard.active,
					mapZoom : 3.5,
					mapCenter : [-96,37],
					truckState : 'active',
					mapViewMode:'static',
					mapFlag:this.state.mapFlag+1
				});
			}else if(val === 2){		// For Idle Trucks			
				this.setState({
					updated : 1,
					selectedData: this.props.dashboard.dashboard.idle,
					checkedData : this.props.dashboard.dashboard.idle,
					viewFlag : false,
					routeData : [],
					TrucksNum : this.props.dashboard.dashboard.idle,
					mapZoom : 3.5,
					mapCenter : [-96,37],
					truckState : 'ideal',
					mapViewMode:'static',
					mapFlag:this.state.mapFlag+1
				});
			}else if(val === 3){		// For Stopped Trucks			
				this.setState({
					updated : 1,
					selectedData : this.props.dashboard.dashboard.stopped,
					checkedData : this.props.dashboard.dashboard.stopped,
					viewFlag : false,
					routeData : [],
					TrucksNum : this.props.dashboard.dashboard.stopped,
					mapZoom : 3.5,
					mapCenter : [-96,37],
					truckState : 'stopped',
					mapViewMode:'static',
					mapFlag:this.state.mapFlag+1
				});
			}
		}		
	   }
	handletruckClicked = (selectedTruck) => {			
		if(selectedTruck.length === 1){					// change to route Map			
			this.setState({
				updated : 1,
				viewFlag: true,
				routeData : selectedTruck,
				mapFlag:this.state.mapFlag+1
			});									
		}else{											// restore original map
			this.setState({
				updated : 1,
				viewFlag: false,
				routeData : [],
				mapFlag:this.state.mapFlag+1
			});			
		}			
	}
	handlecheckList = (filteredEle) =>{
		switch(filteredEle.length) {
		case 0:				
			this.setState({
				updated : 1,
				viewFlag: false,
				checkedData : filteredEle,
				routeData: [],
				mapFlag:this.state.mapFlag+1
			});
			break; 
		case 1:				
			this.setState({
				updated : 1,
				viewFlag : true,
				routeData : filteredEle,
				checkedData : filteredEle,
				mapFlag:this.state.mapFlag+1					
			});
			break;
		default:					
			this.setState({
				updated : 1,
				viewFlag : false,
				checkedData : filteredEle,
				routeData : [],
				mapFlag:this.state.mapFlag+1
			});
			break;
		}	
	   }	
	   handleEldprovider = (id) =>{
		this.props.dispatch(eldAssetList(id));
	   }
	   _truckClick = (truckInfo) => {
		   let mapViewMode = 'static';
		   if(this.state.truckState === 'active'){
			mapViewMode = 'moving';
		   }
		   this.setState({
			   ...this.state,
			   checkedData:[truckInfo],
			   mapZoom:12,
			   mapCenter:[truckInfo.longitude, truckInfo.latitude],
			   mapViewMode:mapViewMode,
			   truckInfo:truckInfo,
			   mapFlag:this.state.mapFlag+1
		   });
	   }
	showBreadcrump = () => {		
		let userType = localStorage.getItem('userType').toLowerCase();
		if(userType === "shipper"){
			return <Fragment></Fragment>;
		} else {
			return <GoogleMapBreadcrump trucks={this.state.TrucksNum} timeZones={this.props.timeZones} />
		} 

		//return userType == "shipper"?(<Fragment></Fragment>):<DashboardBreadcrump trucks={this.state.TrucksNum} timeZones={this.props.timeZones} />
	}

	carrierSelect = (key) => {
		this.selectedMenu=key;
		let params ={
			carrierCompanyId:key
		}
		this.props.dispatch(dashboardAction(params)); 		
		this.setState({
			...this.state,
			mapViewMode:'static',
			mapZoom:3.5,
			mapCenter : [-96,37],
			mapFlag:this.state.mapFlag+1
		});
		// if(this.userType =="broker"){

		// }
		// carrierCompanyId
	}

	eldSelect = (key,e, ELD_Provider_Id) => {
		this.selectedMenu=ELD_Provider_Id;
		let id = $('#findCarrierId').val();
		let params ={
			eldProviderId:id
		}
		this.props.dispatch(dashboardAction(params));
		this.setState({
			...this.state,
			mapViewMode:'static',
			mapCenter : [-96,37],
			mapZoom:3.5,
			mapFlag:this.state.mapFlag+1
		});
	}

	getMenu = () => {
		let menu = [];
		if(this.userType ==="broker" && this.state.carriers){			
			menu = this.state.carriers.map( (row, index) =>{
				if(row.Invited_Company_Id === this.selectedMenu){
					return <button key={row.Invited_Company_Id} className="btn btn-primary">{row.Company_Name}</button>
				}
				else{
					return <button onClick={(e)=>{this.carrierSelect(row.Invited_Company_Id)}} key={row.Invited_Company_Id} className="btn btn-light">{row.Company_Name}</button>
				}
			});
		} else if(this.userType ==="carrier" && this.state.eldList) {
			menu = this.state.eldList.map( (row, index) =>{
				if(row.ELD_Provider_Id === this.selectedMenu){
					return <button key={row.ELD_Provider_Id} className="btn btn-primary">{row.ELD_Provider_Disp_Name}</button>
				}
				else{
					return <button onClick={(e)=>{this.eldSelect(row.ELD_Provider_Id)}} key={row.ELD_Provider_Id} className="btn btn-light">{row.ELD_Provider_Disp_Name}</button>
				}
			});
		}
		if(menu.length>0){
			return <div className="dashboard-top-menu" >{menu}</div>;
		} else {
			return <Fragment></Fragment>
		}
	}

	getDashboardMap = (TOKEN) => {
		
			let mapCenter={
				lat:parseFloat(this.state.mapCenter[1]), 
                lng:parseFloat(this.state.mapCenter[0])
			}
			let mapZoom = 5;
			return <DashboardGoogleMap mapZoom={mapZoom} mapCenter={mapCenter} mapData={this.state.checkedData} truckstate={this.state.truckState} mapViewMode = {this.state.mapViewMode} truckInfo={this.state.truckInfo} location={this.props.history.location} 
			mapFlag={this.state.mapFlag} />

		
	}

	showBreadcrumpMobileCarrier = () =>{
		
			return <GoogleMapMobileCarrierBreadcrump trucks={this.state.TrucksNum} timeZones={this.props.timeZones} />;
		
	}

	showDashMapMobileCarrier = () => {
			let mapCenter={
				lat:parseFloat(this.state.mapCenter[1]), 
                lng:parseFloat(this.state.mapCenter[0])
			}
			let mapZoom = 5;
			return <DashboardGoogleMapMobileCarrier mapZoom={mapZoom} mapCenter={mapCenter} mapData={this.state.checkedData} truckstate={this.state.truckState} mapViewMode = {this.state.mapViewMode} truckInfo={this.state.truckInfo} location={this.props.history.location} 
			mapFlag={this.state.mapFlag} />;
		
	}
	getEldProviderDropdown = () => {
		return <div className="carrierDropdown">
			<div className="col col-md-12">
				<label htmlFor="carrierdropdownLabel" className="carrierdropdownLabel" ><b>Select ELD Providers</b></label>
				<select className="form-control" id="findCarrierId" onChange={(e) => { this.eldSelect(e) }}>
					{
						(this.state.eldList && this.state.eldList.length > 0) ? (this.state.eldList.map(function (row, index) {
							return (
								<option key={row.ELD_Provider_Id} value={row.ELD_Provider_Id}>{row.ELD_Provider_Disp_Name}</option>

							)
						})) : null
					}
				</select>
			</div>
		</div>

	}
	render(){
		const TOKEN=process.env.REACT_APP_MAPBOX_TOKEN;
		var dashboard ={};
		var stopped   ={};
		var idle      ={};
		var active    ={};

		if(this.props.dashboard.dashboard) { 
			dashboard = this.props.dashboard.dashboard;
			stopped   = dashboard.stopped;
			idle      = dashboard.idle;
			active    = dashboard.active;
		}

		if(this.state.isReady){
			if(this.state.isOnboarded===1){
				{this.showBreadcrump()}

				return(			
					<article className="dashboard-data shadow bg-white rounded">
						<Row className="no-gutters">
							<Col md={3} className="pos-rel">
							{/* {this.getEldProviderDropdown()} */}
							{/* {<GetCarrier state={this.state} userType={this.userType} onChange={(e) => { this.loadCarrierDashboardData(e) }} />} */}
							{<GetEld state={this.state}  dashboard={this.props?.dashboard?.dashboard} eldList={this.props?.dashboard?.dashboard?.eldList} onChange={(e) => { this.eldSelect(e) }} />}
								{/* <DashboardTrucks  stopped={ stopped } idle={ idle } active={ active }
								tabClicked={(val)=> {this.handleTabClicked(val)}}
								truckClicked={(selectedTruck)=> {this.handletruckClicked(selectedTruck)}}
								selectedData={ this.state.selectedData } truckstate={this.state.truckState}
								checkList={(filteredEle)=> {this.handlecheckList(filteredEle)}}
								_truckClick = {(truckInfo) => { this._truckClick(truckInfo); }}
								/> */}
								<DashboardTrucksComponent
								disabled={this.state.disabled} stopped={stopped} idle={idle} active={active} loading={this.props.loading}
								tabClicked={(val) => { this.handleTabClicked(val) }}
								truckClicked={(selectedTruck) => { this.handletruckClicked(selectedTruck) }}
								selectedData={this.state.selectedData} truckstate={this.state.truckState}
								checkList={(filteredEle) => { this.handlecheckList(filteredEle) }}
								_truckClick={(truckInfo) => { this._truckClick(truckInfo); }}
								loadloadVehicleicon={loadVehicleicon}
							/>
								{this.showBreadcrump()}
							
							</Col>
							<Col md={9}>
								{/* {this.getMenu()} */}
								{this.getDashboardMap(TOKEN)}							
							</Col>
						</Row>
					</article>
				)
			}
			else{
				return(
					<article className="dashboard-data shadow bg-white rounded">						
					<Row className="no-gutters">
						<Col md={3} className="pos-rel">
							<DashboardTrucksNotOnBoarded  stopped={ stopped } idle={ idle } active={ active } loading={this.props.loading}
							tabClicked={(val)=> {this.handleTabClicked(val)}}
							truckClicked={(selectedTruck)=> {this.handletruckClicked(selectedTruck)}}
							selectedData={ this.state.selectedData } truckstate={this.state.truckState}
							checkList={(filteredEle)=> {this.handlecheckList(filteredEle)}}
							_truckClick = {(truckInfo) => { this._truckClick(truckInfo); }}
							/>
							{this.showBreadcrumpMobileCarrier()}
						</Col>
						<Col md={9}>
							{this.showDashMapMobileCarrier()}
						</Col>
					</Row>
				</article>
				);

				
			}
		} else {
			return(
				<div className="text-center">
  					<div className="spinner-border" role="status">
    					<span className="sr-only">Loading...</span>
  					</div>
				</div>
			);

		}
	}
}
const mapStateToProps = (state) => {	
	return{
		dashboard : state.dashboardReducer.data,
		loading: state.dashboardReducer.loading
		//dashboard : state.dashboardReducer.eldAssets,
		//trucks: state.truckReducer.trucks
	}    
}
export default connect(mapStateToProps)(Dashboard)

