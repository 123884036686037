import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {validate} from './changepwdvalidation';
import { profileview } from '../../actions/userActions';
import { renderField } from '../../helpers/FormControls';
import {toastr} from 'react-redux-toastr';
import CarrierProfilEdit from './carrierProfilEdit';
import { getcitybystate,getzipbycity } from '../../actions/loadActions';
import { companyVerifyService } from '../../services/companyService';

  const renderTextArea = ({input,label, meta: { touched, error, warning }}) => (
    <div>
        <label>{label}</label>
            <textarea {...input} className="form-control level2" ></textarea>
            {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
);


class carrierprofiledetailsview extends Component
{
 
     state={
        modaldata:[],
        verifyResponse : ""
     }

     componentDidUpdate()
	{
		if(this.props.data.message==="Password Updated")
		{
            toastr.success('Success', 'Password updated Successfully!');      
            document.getElementById('modalclose').click();      
        }
        else if(this.props.data.message==="Invalid Password")
		{
            toastr.error('Error', 'Please Enter a valid password!');    
        }
    }
    
    carrierprofile=()=>
    {
        if(this.props.data.Company.currentCityId!==null)
        {
            this.props.dispatch(getcitybystate(this.props.data.Company.currentStateCode));
        }

        if(this.props.data.Company.currentZipId!==null)
        {
            this.props.dispatch(getzipbycity(this.props.data.Company.currentCityId));
        }
        this.setState({ modaldata: this.props.data.Company });
    }

    verifyThisProfile =()=>
    {
	  companyVerifyService().then((response) => {            
		  if(response){                
			  this.setState({
				  ...this.state,
				  verifyResponse:response
			  });
		  }
	  });
	}
	
	componentDidUpdate = () =>{  
        if(this.state.verifyResponse){
        if(this.state.verifyResponse.message==="Company Verified Successfully"){  
			toastr.success('Success', 'Company Verified Successfully!');
        }}
    }

    componentDidMount()
    {
        this.props.dispatch(profileview());
    }

    render(){
        document.title="Company Profile";   
        console.log(this.props)
        this.props.initialize(this.props.data.Company);
        if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}
        return( 
            <div className="table-data border">
                <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">Company Profile</a>
                    </li>      
                    <div className="flex-left-auto">
                        <ul className="ul-horizontal tab-menus">      
                            <li>
                              <a href="/#" onClick={() => {this.verifyThisProfile()}} className="btn_carrier_move btn btn-sm btn-primary">Verify</a>
                            </li>                  
                           {/*<li>
                              <a href="#" data-toggle="modal" onClick={() => {this.carrierprofile()}}  data-target=".carrierprofiledetailsview" className="btn_carrier_move btn btn-sm btn-primary">Edit</a>
                            </li> */}
                        </ul>
                    </div>                           
                </ul>
                <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab"> 
                      
                        <div className="row">                 
                            <div className="col col-md-12">
                               <form className="tg-forms"> 
                                <article className="table-data truck-data shadow bg-white rounded">
                                    <div className="row">
                                        <div className="col col-md-3">
                                            <div className="form-group">
                                            <Field type="text" name="companyName" component={renderField} label="Name"/>     
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="specialityTypeName" component={renderField} label="Speciality"/>  
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="currentAddress1" component={renderField} label="Current Address"/>                                
                                            </div>
                                            {/* <div className="form-group">
                                            <Field type="text" name="CarrierLogin" component={renderField} className="form-control" label="Carrier Login" />
                                            </div> */}
                                        </div>
                                        <div className="col col-md-3">
                                            <div className="form-group">
                                            <Field type="text" name="companyEmail" component={renderField} label="Email"/> 
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="phoneNumber" component={renderField} label="Primary Phone"/>
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="altPhoneNumber" component={renderField} label="Secondry Phone"/>                                
                                            </div>
                                        </div>
                                        <div className="col col-md-3">
                                            <div className="form-group">
                                            <Field type="text" name="currentCityName" component={renderField} label="City"/>
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="faxNumber" component={renderField} label="Fax"/>                               
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="websiteAddress" component={renderField} label="Website"/>                                
                                            </div>
                                        </div>  
                                        <div className="col col-md-3">
                                            <div className="form-group">
                                            <Field type="text" name="currentStateName" component={renderField} label="State"/>
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="currentZipValue" component={renderField} label="Zip Code"/>                               
                                            </div>
                                            <div className="form-group">
                                            <Field type="text" name="currentCountryName" component={renderField} label="Country"/>                               
                                            </div>
                                        </div>                             
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                            <Field type="text" name="currentAddress1" component={renderField} label="Current Address"/>                                
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                            <Field type="text" name="permanentaddress" component={renderField} label="Permanent Address"/>                                
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                            <Field type="text" name="Eldproviders" component={renderField} label="Eldprovider(s)"/>                                
                                            </div>
                                        </div>
                                     </div> */}
                                     <div className="row">
                                        <div className="col-md-12">
                                            {/* <label htmlFor="instructions">Description</label> */}
                                            <Field name="instructions" label="Description" component={renderTextArea}/>
                                            {/* <textarea type="text" name="instructions" className="instructions form-control"></textarea> */}
                                        </div>
                                     </div>
                                </article>
                                </form>
                            </div>
                        </div>
                  </div>                   
               </div>
               <CarrierProfilEdit modalvalues={this.state.modaldata} country={this.props.country} CityVal={this.props.CityVal} ZipVal={this.props.ZipVal} state={this.props.statelist} specialitytypelist={this.props.specialitytypelist} 
               SubscriptionPlansList={this.props.subscriptionplanlist}/>
        </div>   
        
        )
    }
}
const PageOneForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    })(carrierprofiledetailsview)

  const mapStateToProps = (state,ownProps) => { 
    return{
        data: state.userReducer.data,
        CityVal:state.loadReducer.CityVal,
        ZipVal:state.loadReducer.ZipVal,
        form: 'profiledetailsview',
        validate,      
        //enableReinitialize: true 
    }    
  }
  
  const PageOneFormContainer = connect(mapStateToProps)(PageOneForm)
    
  export default PageOneFormContainer
