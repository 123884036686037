import * as types from '../actions';

const initialState = {
    loading: false,    
    data: [],
    exportFlg:false,
    goFlg:false,
}
export default function(state = initialState, action) {  
    switch(action.type) {
        case types.GET_LOAD_REPORTS:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.GET_LOAD_REPORTS_SUCCESS:
            return { 
                ...state,
                data: action.data,
                exportFlg: action.responseData.exportFlg,
                goFlg: action.responseData.goFlg,
                loading: false,
            };
        case types.GET_LOAD_REPORTS_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.GET_LOAD_STATUS:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.GET_LOAD_STATUS_SUCCESS:
            return { 
                ...state,
                loadStatusList : action.data, 
                data: action.data,                
                loading: false,
            };
        case types.GET_LOAD_STATUS_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.SET_GOFLAG:
            return { 
                ...state,
                goFlg: false,
                exportFlg: false
            }; 
        case types.TRIP_REG_REPORT_STATUS:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.TRIP_REG_REPORT_STATUS_SUCCESS:
            return { 
                ...state,                
                data: action.data,                
                loading: false,
            };
        case types.TRIP_REG_REPORT_STATUS_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.TRUCK_SUMMARY_REPORT:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.TRUCK_SUMMARY_REPORT_SUCCESS:
            return { 
                ...state,                
                data: action.data,                
                loading: false,
            };
        case types.TRUCK_SUMMARY_REPORT_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.LOAD_SUMMARY_REPORT:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.LOAD_SUMMARY_REPORT_SUCCESS:
            return { 
                ...state,                
                data: action.data,                
                loading: false,
            };
        case types.LOAD_SUMMARY_REPORT_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.FILE_SIZE_DB:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.FILE_SIZE_DB_SUCCESS:
            return { 
                ...state,                
                data: action.data,                
                loading: false,
            };
        case types.FILE_SIZE_DB_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.FILE_RECORDS_SIZE_DB:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.FILE_RECORDS_SIZE_DB_SUCCESS:
            return { 
                ...state,                
                data: action.data,                
                loading: false,
            };
        case types.FILE_RECORDS_SIZE_DB_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.HOS_REPORT:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.HOS_REPORT_SUCCESS:
            return { 
                ...state,                
                data: action.data,                
                loading: false,
            };
        case types.HOS_REPORT_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};