import React,{Component} from 'react';
import {userlistAction} from '../../actions/userActions';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search,ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
class DriverList extends Component
{

	state={
		modaldata:[]
	 }	

	getModal = (carrier_list,dispatch) => {
		this.setState({ showModal: true, modaldata: carrier_list });
	 };
	componentDidMount=()=>
	{ 
		var params={
			usertype:'driver',
		};
		this.props.dispatch(userlistAction(params));
	}
	userlist = [];
	userlistformycarriers=[];
	render()
	{
		var userlist = [];
		var userlistformycarriers=[];
		var userlistforinvited=[];
		var userlistforonboarded=[];
		if(this.props.data.length > 0)
		{
			userlist = []	;
			userlist = this.props.data.map(
				row =>{
					return({
						id:row.userId,
						firstName:row.firstName,
						staricon:<i className="far fa-star staricon"></i>,
						phoneNumber:row.phoneNumber,
						emailId:row.emailId,
						eldproviders:row.eldproviders,
						currentCityName:row.currentCityName,	
						currentStateName:row.currentStateName,
						CurrentZip:row.CurrentZip,
						speciality:row.speciality,
						videoprofile:<i className="icofont-video"></i>,
						action:<div className="dropdown">
						<label data-toggle="dropdown">&hellip;</label>
						<div className="dropdown-menu">
						   <a className="dropdown-item" data-toggle="modal" data-target=".bd-trucksedit-modal-lg" href="/#"><i className="icofont-ui-edit"></i> Edit</a>						
						   <a className="dropdown-item trigger-addedit_document" href="/#"><i className="icofont-ui-delete"></i>Remove</a>
						   <a className="dropdown-item trigger-addedit_document" href="/#"><i className="icofont-heart-eyes"></i>View</a>
						   <a className="dropdown-item trigger-addedit_document" href="/#"><i className="icofont-file-document"></i> Add Document</a>
						   </div>
					</div>								
					});
				}
			);
			
		}
		else
		{
			userlist = [];				
		}	
		const { SearchBar } = Search;
		const { ToggleList } = ColumnToggle;
		const columns=[	
		   {
				text:'',
				dataField:'staricon',
				clickToSelect: false,
				toggle: false
		   },
		   {
				text:'Name',
				dataField:'firstName'
		   },
		   {
				text:'Recruiter',
				dataField:'recruiter',
		   },
		   {
				text:'Title',
				dataField:'title',
		   },
		   {
				text:'Speciality / Unit',
				dataField:'speciality',
		   },
		   {
				text:'Current City',
				dataField:'currentCityName',
		   },
		   {
				text:'State',
				dataField:'currentStateName',
		   },
		   {
				text:'Phone',
				dataField:'phoneNumber'
		   },
		   {
				text:'Last Note Added',
				dataField:'lastnoteadded'
		   },
		   {
				text:'Email',
				dataField:'emailId'
		   },
		   {
				text:'Gpm',
			 	dataField:'gpm'
		   },
		   {
				text:'Video Profile',
				dataField:'videoprofile'
			},
			{
				text:'More',
				dataField:'action'
	  	    }
		   
		]

		const columnsd=[
			{
				 text:'Recruiter',
				 dataField:'recruiter',
			},
			{
				 text:'Title',
				 dataField:'title',
			},
			{
				 text:'Speciality',
				 dataField:'speciality',
			},
			{
				 text:'Current City',
				 dataField:'currentCityName',
			},
			{
				 text:'State',
				 dataField:'currentStateName',
			}
		 ]

		const customTotal = (from, to, size) => (
			<span className="react-bootstrap-table-pagination-total">
			  Showing { from } to { to } of { size } Results
			</span>
		  );

		  const CustomToggleList = ({		
			columns,
			onColumnToggle,
			toggles
		  }) => (
			<div className="btn-group btn-group-toggle" data-toggle="buttons">
			  {
				columnsd
				  .map(column => ({
					...column,
					toggle: toggles[column.dataField]
				  }))
				  .map(column => (
					  <div>&nbsp;
					<button
					  type="button"
					  key={ column.dataField }
					  className={ `btn btn-primary ${column.toggle ? 'active' : ''}` }
					  data-toggle="button"
					  aria-pressed={ column.toggle ? 'true' : 'false' }
					  onClick={ () => onColumnToggle(column.dataField) }
					>
					  { column.text }
					</button>
					</div>
				  ))
			  }
			</div>
		  );
		  
		  const options = {
			paginationSize: 4,
			pageStartIndex: 1,
			firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: customTotal
		  };
		return(
			<article className="table-data truck-data shadow bg-white rounded">
				<div className="row no-gutters">
					<div className="col-md-12">
						<ul className="nav nav-tabs" id="myTrucks" role="tablist">
						  	<li className="nav-item">
						    	<a className="nav-link active" id="carriers-tab" data-toggle="tab" href="#carriers" role="tab" aria-controls="carriers" aria-selected="true"><i className="icofont-user"></i>LIST DRIVERS</a>
						  	</li>
						  	<div className="flex-left-auto">
						  		<ul>
						  			<li>
			
						  				{/* <a href="#" className="btn btn-primary btn-sm" data-toggle="modal" data-target=".bd-example-modal-lg" >Add Carrier</a> */}
						  			</li>
						  		</ul>
						  	</div>
						</ul>
						<div className="tab-content" id="myTabContent">
						  	<div className="tab-pane fade show active" id="carriers" role="tabpanel" aria-labelledby="carriers-tab">
						  	<ToolkitProvider
									keyField="id"
									data={ userlist }
									columns={ columns }
									search
									columnToggle
								   >
									{
									
									props => (										
										<div>
										<span><b>Show/hide: </b></span>
										<CustomToggleList { ...props.columnToggleProps } />
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication="No Data Found"  hover 
											selectRow={ { mode: 'checkbox' } }  bordered={ false } />
										</div>
									)
									}
								</ToolkitProvider>
						  	</div>
						  	<div className="tab-pane fade" id="mycarriers" role="tabpanel" aria-labelledby="mycarriers-tab">				  			
							  <ToolkitProvider
									keyField="id"
									data={ userlistformycarriers }
									columns={ columns }
									search
								   >
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication="No Data Found"  hover 
											selectRow={ { mode: 'checkbox' } }  bordered={ false } />
										</div>
									)
									}
								</ToolkitProvider>
						  	</div>
								
							  <div className="tab-pane fade" id="invited" role="tabpanel" aria-labelledby="invited-tab">
							  <ToolkitProvider
									keyField="id"
									data={ userlistforinvited }
									columns={ columns }
									search
								   >
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication="No Data Found"  hover 
											selectRow={ { mode: 'checkbox' } }  bordered={ false } />
										</div>
									)
									}
								</ToolkitProvider>
								</div>
								<div className="tab-pane fade" id="onboarded" role="tabpanel" aria-labelledby="onboarded-tab">
								<ToolkitProvider
									keyField="id"
									data={ userlistforonboarded }
									columns={ columns }
									search
								   >
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication="No Data Found"  hover 
											selectRow={ { mode: 'checkbox' } }  bordered={ false } />
										</div>
									)
									}
								</ToolkitProvider>
								</div>
						  	<div>
						  
						  	{/* <EditCarrierPopup modaldata={this.state.modaldata}/> */}
						  	</div>
						</div>
					</div>
				</div>
			</article>
		)
}
}
const mapStateToProps = (state) => {
	return{
	   loading : state.userReducer.loading,
       data : state.userReducer.data,
       pagination : state.userReducer.pagination
	}    
}
export default connect(mapStateToProps)(DriverList)