import React, { Component } from 'react';
import FaqData from './../data.json';
class FAQModal extends Component {
  showUniqueFAQs(eldVendor){
    let dataid;
    const result=FaqData.some((value,index) => { 
      index++;
      dataid=index;
      return (value.eldVendor== eldVendor); 
  });
    if(result){
      return (
        <ol>
        <div dangerouslySetInnerHTML={{ __html: FaqData[dataid-1].faqs }} />
        </ol>)
    }
    else{
      return(<ol>
        <li>A user with administrator access to your ELD account should click the link in the TruckerCloud invite email</li>
        <li>Select your ELD Provider</li>
        <li>Enter username/password from your ELD portal.</li>
        <li>Click 'I agree to terms and conditions'</li>
        <li>Wait 15-30 seconds for credentials to verify</li>
        <li><a href='https://truckercloud.zendesk.com/hc/en-us/articles/4415549969435' target='_blank' rel='noopener noreferrer' className='cob-link'>Where do I find my credentials ?</a></li>
      </ol>)
    }
  }
  render() {
    return (
      <div className='modal' id='faqModal'>
        <div className='modal-dialog modal-dialog-centered modal-lz'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='opt-heading-css'>{this.props?.state?.eld_name}</h5>
            </div>
            <div className='modal-body'>
              <div className=''>
                {this.showUniqueFAQs(this.props?.state?.eldVendor)}
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-primary btn-sm' data-dismiss='modal'>Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQModal;