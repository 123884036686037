import React from 'react';

class LoadDropdown  extends React.Component {
    getRow(data, value) {
        switch (value) {
            case 'cid': return (
                data ? (data.map(function (row, index) {
                    return (
                        <option key={index} data-lanedetailsindex={index} value={row.companyId}>{row.companyName}</option>
                    )
                })) : (<option></option>)
            )
            case 'id': return (
                data ? (data.map(function (row, index) {
                    return (
                        <option key={index} data-lanedetailsindex={index} value={row.id}>{row.name}</option>
                    )
                })) : (<option></option>)
            )
            case 'c_id': return (
                data ? (data.map(function (row, index) {
                    return (
                        <option key={index} data-lanedetailsindex={index} value={row.Invited_Company_Id}>{row.Company_Name}</option>
                    )
                })) : (<option></option>)
            )
            case 'lane': return (
                data ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.laneId}>{row.selectLane}</option>
                    )
                })) : (<option></option>)
            )
            case 'cState': return (
                data ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.id+','+row.name}>{row.name}</option>
                    )
                })) : (<option value=''>Loading...</option>)
            )
            case 'cCity': return (
                data.length > 0 ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.cityId+','+row.cityName}>{row.cityName}</option>
                    )
                })) : (<option value=''>Loading...</option>)
            )
            case 'cZip': return (
                data.length > 0 ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.zipId+','+row.zipValue}>{row.zipValue}</option>
                    )
                })) : (<option value=''>Loading...</option>)
            )
            case 'ctrucktype': return (
                data && data.length > 0 ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.id}>{row.name}</option>
                    )
                })) : (<option value=''>Loading...</option>)
            )
            case 'c_eldlist': return (
                data && data.length > 0 ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.eldProviderId}>{row.eldProviderDispName}</option>
                    )
                })) : (<option value=''>Loading...</option>)
            )
            case 'c_truckno': return (
                data && data.length > 0 ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.assetId}>{row.assetName?row.assetName:row.assetNumber}</option>
                    )
                })) : (<option value=''>Loading...</option>)
            )
            case 'c_timezone': return (
                data && data.length > 0 ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.id} selected={row.value==='EST'}>{row.value}</option>
                    )
                })) : (<option value=''>Loading...</option>)
            )
        }
    }
    render() {
        let value = this.props.value
        return (
            <div className='col form-group'>
                {/* <label>{this.props.label}</label> */}
                <select onChange={this.props.onChange} className={this.props.class}id={this.props.id} name={this.props.name}>
                    <option value='' hidden>{this.props.placeHolder}</option>
                    {
                        this.getRow(this.props.data, value)
                    }
                </select>
            </div>
        )
    }
}

export default LoadDropdown;
