
import React from 'react'
import { TypoGraph, Card, Container, Button } from '../../muiComponents'
import { makeStyles } from '@material-ui/core/styles';
import { logoutUserAction } from '../../actions/authActions';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import HeaderBar from '../../muiComponents/header';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
require('dotenv').config();
const useStyles = makeStyles({
    cardContentContainer: {
        gridTemplateColumns: '1fr 1fr 1fr',
        gridGap: '1rem',
        contentContainer: {
            gridTemplateRows: 'auto auto',
            gridGap: '1rem'
        },
        btnContainer: {
            gridGap: '1rem',
            maxWidth: '10rem'
        }
    },
    container: {
        height: '100',
        display: 'flex !important'
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop:'-10px'
    }
});
const Profile = (props) => {
    const classes = useStyles();
    const loggedUser = {
        companyId: localStorage.getItem('companyId'),
        userName: localStorage.getItem('userName'),
        userType: localStorage.getItem('userType'),
        FirstName: localStorage.getItem('FirstName')
    }
    const { userType, FirstName } = loggedUser;
    console.log(userType, FirstName)
    if (props.userAuthenticated === false && localStorage.getItem('FirstName') == null) {
        return (
            <Redirect to={{ pathname: '/' }} />
        )
    }
    let userTypeVal;
    if (userType === 'shipper') {
        userTypeVal = "Customer"
    } else {
        userTypeVal = userType
    }
    const logoutHandler = () => {
        props.dispatch(logoutUserAction());
    }
    return (
        <Container>
            <HeaderBar title='Profile' />
            <Container className={classes.container}>
                <Card
                    sxContainer={{ width: 600, margin: 'auto' }}
                    sxContent={{ textAlign: 'center' }}
                >
                    <Container className={classes.iconContainer}>
                        <Avatar sx={{ bgcolor: '#44A3DB',padding:'1rem' }} >
                            {FirstName.charAt(0)}
                        </Avatar>
                    </Container>
                    <TypoGraph variant="h1" align="center" sx={{padding:'1rem',textTransform: 'capitalize'}}>   
                        {userType}
                    </TypoGraph>
                    <TypoGraph variant="h1" align="center" sx={{paddingBottom:'1rem'}}>
                        {FirstName}
                    </TypoGraph>
                    {/* <TypoGraph align="center">Page Under Construction</TypoGraph> */}
                    <Button
                        variant="contained"
                        label='Logout'
                        colorprop='white'
                        hoverColor='#4497db'
                        backgroundcolorprop='#44A3DB'
                        onClick={() => logoutHandler()}
                    />
                </Card>
            </Container>
        </Container>
    );
}
const mapStateToProps = state => ({
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userAuthenticated: state.authReducer.userAuthenticated
});

export default connect(mapStateToProps)(Profile)
