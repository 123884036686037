import React, { Component } from 'react';

class ViewTrailer extends Component{
    render(){
        return(
            <form className="tg-forms"> 
                <div className="table-data border">
                    <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">View Trailer</a>
                        </li>                                 
                    </ul>
                    <div className="tab-content" id="myTabContent">
                  	    <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">                                      
                    	    <form className="tg-forms">
							    <div className="row">                 
								    <div className="col col-md-12">									    
									    <article className="table-data truck-data shadow bg-white rounded">
                                        <h5 className="modal-title doc-color" id="exampleModalLabel">Trailer Info</h5>
                                        <hr/>
										    <div className="row">
											    <div className="col col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="truck">Trailer #</label>
                                                        <input type="text" readOnly className="form-control" id="truck" />                                
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="make">Year</label>
                                                        <input type="text" readOnly className="form-control" id="year" />                                  
                                                    </div>
                                                </div>
                                                <div className="col col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="year">Make</label>
                                                        <input type="text" readOnly className="form-control" id="make" />                                  
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="text">Plate #</label>
                                                        <input type="text" readOnly className="form-control" id="plate" />                                
                                                    </div>
                                                </div>
                                                <div className="col col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="trucktype">Model</label>
                                                        <input type="text" readOnly className="form-control" id="model" /> 
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="color">Serial</label>
                                                        <input type="text" readOnly className="form-control" id="serial" />                                
                                                    </div>
                                                </div>                           
										    </div>
                                            <h5 className="modal-title doc-color" id="exampleModalLabel">Notes</h5>
                                        <hr/>
                                            <div className="row">
                                                <div className="form-group col col-md-12"> 
                                                    <input type="text" readOnly className="form-control" id="text"/>
                                                </div>             
                                            </div> 
                                            <h5 className="modal-title doc-color" id="exampleModalLabel">View Document</h5>
                                            <hr/>
                                            <div class="table-responsive">
                                                <table class="table table-borderless all-table">
                                                    <thead>
                                                    <tr>
                                                        <th className=""></th>
                                                        <th className="">Trailer #</th>
                                                        <th className="">Category</th>
                                                        <th className="">Document</th>
                                                        <th className="">Expiry Date</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>                         
							                            <tr>
							                                <td colspan="5" className="text-center"> No Documents found. </td>
							                            </tr>	
                                                    </tbody>
                                                </table>
                                                <hr/>
                                            </div>
									</article>
								</div>
							</div>
                    	</form>
                    </div>                   
                </div>
            </div>   
        </form>
        )
    }
}

export default ViewTrailer