import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { shipperandlanedetails } from '../../../actions/loadActions';
import { STATUS } from '../../../helpers/status';
// import TenderPopup from '../../widgets/TenderPopup';
// import TruckAvailable from '../TruckAvailable';
import AddLoadPopup from './AddLoadPopup';
import LoadList from './LoadList';
import { toastr } from 'react-redux-toastr';
import { loadAction } from '../../../actions/loadActions';


class Loads extends Component{

	constructor(props){
		super(props);
		this.myloads=[];
		this.requested = [];
		this.accepted = [];
		this.tendered = [];
		this.delivery_uc = [];
		this.delivery_c = [];
		this.paid_close = [];		
		this.currentStatus = STATUS.LOAD.MYLOADS;
		this.loadLists=[];
	}
		
	componentDidMount() {
		document.title = 'Loads';
		this.props.dispatch(shipperandlanedetails());
	}

	componentDidUpdate()
	{
        if(this.props.data.status==="CREATED")
        {              
            toastr.success('Success', 'Load has been Successfully Created!');
			const params={
				...STATUS.LOAD.REQUESTED
			}
			this.props.dispatch(loadAction(params));
			document.getElementById('addmodalpopup').click()
        }
        else if(this.props.data.status==="ALREADY_REPORTED")
        {
            toastr.error('Error', 'Load Already Exists!');
           
        }
    }

	render(){		
		if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}
		return(
			<div className="wrapper">
				<Fragment>		
				    <LoadList carriernamelist={this.props.carriernamelist} state={this.props.statelist} loadpoint={this.props.loadpoint} trucktypelist={this.props.trucktypelist} shippernameAndLane={this.props.ShipperNameAndLaneDetails}/>					
				
					{/* <TenderPopup /> */}
					<AddLoadPopup state={this.props.statelist} citylist={this.props.citylist} loadpoint={this.props.loadpoint} carriernamelist={this.props.carriernamelist} trucktypelist={this.props.trucktypelist} shippernameAndLane={this.props.ShipperNameAndLaneDetails}/>
					
				</Fragment>		
			</div>
		)
	}
}
const mapStateToProps = state => {
	return {
	data: state.loadReducer.data,
	ShipperNameAndLaneDetails:state.loadReducer.Shipperandlanedetails.companyTypeList,
    loading: state.loadReducer.loading,
	error: state.loadReducer.error,
	}
}

export default connect(mapStateToProps)(Loads)