import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { formData } from '../../helpers/formData';
import $ from 'jquery';
import { NewEmailTemplate } from 'UI-Component';
import 'jquery-validation';
import { getemailtemplatetypelist, getallWeblinks, addNewTemplate, updateTemplate, getallTags } from '../../services/emailTemplateService';
class EditMailTemplate extends Component {
    state = {
        templateName: '',
        templateContent: '',
        templateDefaultContent: '',
        templatetypeName: '',
        templateId: '',
        modaldata: [],
        weblinks: [],
        tags: [],
        accountId: '',
        accountName: ''
    }
    componentDidMount() {
        getallWeblinks().then((response) => {
            let weblinksdata = [];
            if (response) {
                weblinksdata = response.map((data, index) => {
                    return {
                        id: data.carrierEmailTemplateTypeId,
                        title: data.displayTitle,
                        description: data.description
                    }
                });
            }
            this.setState({
                ...this.state,
                weblinks: weblinksdata
            });
        });
        getallTags().then((response) => {
            let templatetags = [];
            if (response) {
                templatetags = response.map((data, index) => {
                    return {
                        id: data.carrierEmailTemplateTypeId,
                        title: data.displayTitle,
                        description: data.description
                    }
                });
            }
            this.setState({
                ...this.state,
                tags: templatetags
            });
        });
    }
    componentDidUpdate = (prevProps) => {
        if (this.props.templateData !== prevProps.templateData) {
            if (this.props.templateData) {
                this.setState({
                    ...this.state,
                    templateName: this.props.templateData.emailTemplateName,
                    templateContent: this.props.templateData.emailTemplateContent,
                    templatetypeName: this.props.templateData.carrierEmailTemplateTypeName,
                    templateId: this.props.templateData.carrierEmailTemplateId,
                    accountId: this.props.templateData.carrierEmailAccountSettingId,
                    accountName: this.props.templateData.userName,
                    templateTypeId: this.props.templateData.carrierEmailTemplateTypeId
                });
            } else {
                this.setState({
                    ...this.state,
                    templateName: '',
                    templateContent: '',
                    templatetypeName: '',
                    templateId: '',
                    templateTypeId: '',
                    accountId: '',
                    accountName: ''
                });
            }
        }
    }
    closeModal = (flag) => {
        $('#new_template').trigger('reset');
        this.setState({
            ...this.state,
            templateName: '',
            templateContent: '',
            templatetypeName: '',
            templateId: '',
            templateTypeId: '',
            accountId: '',
            accountName: ''
        });
        this.props.closePopup(flag);
    }
    closeThis = (flag) => {
        $('#new_template').trigger('reset');
        this.props.closePopup(flag);
    }
    getTemplateList = (typeId) => {
        let param = {
            payload: {
                id: typeId
            }
        }
        getemailtemplatetypelist(param).then((response) => {
            this.setState({
                ...this.state,
                templateDefaultContent: response.data[0].defaultContent,
                templateContent: response.data[0].defaultContent,
                pageReady: true
            });
        });
    }
    saveNewTemplate = () => {

        $('#new_template').validate({
            ignore: [],
            rules: {
                emailTemplateName: {
                    required: true
                },
                carrierEmailTemplateTypeId: {
                    required: true
                },
                carrierEmailAccountSettingId: {
                    required: true
                },
                templateContentX: {
                    required: true
                }
            },
            messages: {
                emailTemplateName: {
                    required: 'Template name can not be left blank.!'
                },
                carrierEmailTemplateTypeId: {
                    required: 'Select template type',
                },
                carrierEmailAccountSettingId: {
                    required: 'Select Email Account'
                },
                templateContentX: {
                    required: 'Template body can not be left blank.!'
                }
            }, errorPlacement: function (error, element) {
                if (element.attr('name') === 'fromDate' || element.attr('name') === 'toDate') {
                    error.insertAfter($(element).parents('div.date-picker').find($('.error_place')));
                }
                else {
                    error.insertAfter(element);
                }
            }
        });

        if ($('#new_template').valid()) {
            let form = document.getElementById('new_template');
            let loadData = formData(form);
            loadData.companyId = localStorage.getItem('companyId')
            loadData.emailTemplateContent = this.state.templateContent
            loadData.templateId = this.state.templateId
            let param = {
                payload: loadData
            }
            if (loadData.templateId) {
                updateTemplate(param).then((response) => {
                    if (response.status) {
                        if (response.status === 'CREATED') {
                            toastr.success('Success', response.message);
                            this.closeModal(1);
                        } else {
                            toastr.error('Oops!', response.message);
                        }
                    }
                });
            }
            else {
                addNewTemplate(param).then((response) => {
                    if (response.status) {
                        if (response.status === 'CREATED') {
                            toastr.success('Success', response.message);
                            this.closeModal(1);
                        } else {
                            toastr.error('Oops!', response.message);
                        }
                    }
                });
            }
        }
    }
    set_templateTypeId = (e) => {
        this.getTemplateList(e.target.value);
        this.setState({
            ...this.state,
            templateTypeId: e.target.value
        });
    }
    set_accountId = (e) => {
        this.setState({
            ...this.state,
            accountId: e.target.value
        });
    }
    set_templateName = (e) => {
        this.setState({
            ...this.state,
            templateName: e.target.value
        });
    }
    handleEditorChange = (e) => {
        this.setState({
            ...this.state,
            templateContent: e
        });
    }
    renderTemplateTypes = () => {
        let types = [];
        if (this.props.templateTypes) {
            types = this.props.templateTypes.map((data, index) => {
                return <option key={index} value={data.templateTypeId}>{data.templateName}</option>
            });
        }
        return <select className='form-control requiredfield json-col' name='carrierEmailTemplateTypeId' onChange={(e) => { this.set_templateTypeId(e) }} value={this.state.templateTypeId} >
            <option value=''>Select template type</option>
            {types}
        </select>
    }
    renderAccountList = () => {
        let accountlist = [];
        if (this.props.accountList && this.props.accountList.length > 0) {
            accountlist = this.props.accountList.map((data, index) => {
                return <option key={index} data-id={data.accountID} value={data.accountID}>{data.senderEmail}</option>
            });
        }
        return <select className='form-control requiredfield json-col' name='carrierEmailAccountSettingId' onChange={(e) => { this.set_accountId(e); }} value={this.state.accountId}>
            <option value=''>Select Account</option>
            {accountlist}
        </select>
    }
    render = () => {
        return (
            <Modal size='lg' aria-labelledby='contained-modal-title-vcenter' centered show={this.props.popup} id='newtemplate'>
                <ModalHeader>
                    <ModalTitle>New Template</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <NewEmailTemplate
                        state={this.state}
                        renderAccountList={this.renderAccountList()}
                        renderTemplateTypes={this.renderTemplateTypes()}
                        handleEditorChange={(e) => { this.handleEditorChange(e); }}
                        set_templateName={(e) => { this.set_templateName(e) }}
                    />
                </ModalBody>
                <ModalFooter>
                    <button type='button' className='btn btn-primary' onClick={() => { this.saveNewTemplate() }}>Save</button>
                    <button type='button' className='btn btn-primary' onClick={() => { this.closeModal(0) }}>Close</button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default EditMailTemplate;