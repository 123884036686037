import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { changePreference,carrierListForFactor } from '../../../actions/companyActions';
import $ from 'jquery';
import {InviteCompanyService, VerifyCompanyService, EldOnboardedService} from '../../../services/companyService';
import {formData} from '../../../helpers/formData';
import Dashboard from '../dashboard/Dashboard';

require('dotenv').config();

class BrokersCarrierList extends Component
{	

	getModal = (carrier_list,dispatch) => {
		this.setState({ showModal: true, modaldata: carrier_list });
	 };

	constructor(props)
	{
		super(props);
		this.app_url = process.env.REACT_APP_URL;
		this.state={
			modaldata:[],
			IsOpenVerify:false,
			Invited_Id:0,
			Eld_Provider_Id:0,
			pageFlag:'',
			carrierCompanyId:''
		}
	}
	componentDidMount=()=>
	{ 
		this.getCompany();
	}

	getCompany = () => {
		const params1={
            companytypeid:3
		 }
		// if(this.props.companyTypeId == 0){
		// 	this.props.dispatch(preferredCompanyList(params1));
		// } else {
		// 	this.props.dispatch(preferredCompanyListByType(params1));
		// }
		//this.props.dispatch(preferredCompanyListByType(params1));
		this.props.dispatch(carrierListForFactor(params1));
	}  


	userlist = [];
	userlistformycarriers=[];


	favourite = (e, companyId) => {
		
		const params1={
            companyId:companyId
         }
		this.props.dispatch(changePreference(params1));
	}

	// componentDidUpdate()
	// {
	// 	const params1={
	// 		companytypeid:3,
	// 		SearchFlag:false,
	// 	} 
	// 	if(this.props.carriersearchFlag==true)
	// 	{
	// 		if(this.props.data.message=="No searchlist Found")
	// 		{
	// 			 document.getElementById('addcarrierprop').style.display = 'block'
	// 			 this.props.dispatch(preferredCompanyListByType(params1));
	// 			 this.props.dispatch(carrierSearchFlagAction());	
	// 		}
	// 		else{
	// 			document.getElementById("carrieraddmodalbox").click();
	// 			this.props.dispatch(carrierSearchFlagAction());	
	// 		}
	// 	}

    //     if(this.props.carrierAddFlag==true)
    //     {
    //         if(this.props.data.status=="ACCEPTED" && this.props.data.message=="Carrier Added Sucessfully")
    //         {              
    //             toastr.success('Success', 'Carrier has been Successfully Created!');
    //             document.getElementById("carrieraddmodalbox").click(); 
	// 			this.props.dispatch(preferredCompanyListByType(params1));
	// 			this.props.dispatch(carrierAddFlagAction());
    //         }
    //         else if(this.props.data.status=="ALREADY_REPORTED")
    //         {
    //             toastr.info('Info', 'Carrier Email Id Already Exists!');                
    //             //document.getElementById("carrieraddmodalbox").click(); 
	// 			this.props.dispatch(preferredCompanyListByType(params1));
	// 			this.props.dispatch(carrierAddFlagAction());
    //         }
	// 	}

		
    //     if(this.props.carrierbulkuploadFlag==true)
    //     {
	// 		const BASE_URL= process.env.REACT_APP_BASE_URL;   
	// 		var resultFile='';
    //         if(this.props.data.status=="OK" && this.props.data.message=="Company imported Successfully")
    //         {    
	// 			resultFile = this.props.data.result_file;   
	// 			document.querySelector('#companyuploadstatusfile').setAttribute("href",BASE_URL+'publicresource/download/company/import-status?file_name='+resultFile);           
    //             toastr.success('Success', 'File Processed Successfully!');
    //             document.getElementById("carrieraddmodalbox").click(); 
	// 			this.props.dispatch(preferredCompanyListByType(params1));
	// 			this.props.dispatch(bulkuploadFlagAction());
	// 			document.getElementById("companyuploadstatusfile").click(); 
	// 			setTimeout(function(){
	// 				window.location.reload();
	// 			 }, 1000); 
    //         }
	// 	}
    // }


	UNSAFE_componentWillUpdate = (nextProps, nextState) => {
		if(nextProps.preference_changes != null){
			if(nextProps.preference_changes.status === "1"){
				toastr.success('Success', nextProps.preference_changes.result);
				this.getCompany();
			}
			else{
				toastr.error('Error', nextProps.preference_changes.result);
			}
		}
	}

	myCrRowSel = (row, isSelect, rowIndex, e, flag) => {
		if( flag === "invite"){
			$(".btnInvite").addClass('disabled');
			$("#btnInvite_" + row.Company_Id).removeClass('disabled');
		}
		else if( flag === "verify"){
			$(".btnVerify").addClass('disabled');
			$("#btnVerify_" + row.id).removeClass('disabled');
		}
		else if( flag === "onboard"){
			$(".btnOnBoard").addClass('disabled');
			$("#btnOnBoard_" + row.id).removeClass('disabled');
		}
	}

	InviteCompany = (e, row) => {
		if($(e.target).hasClass('disabled')) return;
		const params1={
            companyId:row.Company_Id
         }
		InviteCompanyService(params1).then( (response) => {
			let flag = false;
			if(response){
				if(response.result && response.status){
					flag = true;
				}
			}

			if(flag){
				if(response.status === 1){
					toastr.success('Success', response.result);
					this.getCompany();
				}
				else{
					toastr.error('Error', response.result);
				}
			}
			else{
				toastr.error('Error', "Unable to invite");
			}
			
			$(e.target).addClass('disabled');
		});
				
	}

	VerifyCompany = (e, row) => {
		if($(e.target).hasClass('disabled')) return;		

		this.setState({
			...this.state,
			IsOpenVerify:true,
			Invited_Id:row.Invited_Id,
			Eld_Provider_Id:row.ELD_Provider_Id
		});
		
	}
	
	onboardCompany = (e, row) => {
		if($(e.target).hasClass('disabled')) return;
		
		const params1={
			invitedId:row.Invited_Id,
			eldProviderId:row.ELD_Provider_Id
		}
		EldOnboardedService(params1).then( (response) => {
			if(response.status === "OK"){
			   toastr.success('Success', "Eld onboarded");
				   this.getCompany();
			}
			else{
			   toastr.error('Error', "Unable to onboard ELD");
			}
		});
		//console.log(row.Company_Id, row.ELD_Provider_Id);
	}

	saveVerification = () => {
		let form = document.getElementById("verificationData");

		let verifyData = formData(form);
		
		const params1={
			invitedId:verifyData.Invited_Id,
			eldProviderId:verifyData.Eld_Provider_Id,
			driverData:verifyData.driver_data,
			locationData:verifyData.driver_data,
			vehicleData:verifyData.vehicle_data,
			hosData:verifyData.hos_data,
			verifiedRemarks:verifyData.remark,
			verifiedByName:verifyData.verifiedby
		 }
		 
		 VerifyCompanyService(params1).then( (response) => {
			 if(response.status === "OK"){
				toastr.success('Success', "Verification info. saved");
					this.getCompany();
			 }
			 else{
				toastr.error('Error', "Unable to save verification info.");
			 }
		 });

		this.closeVerification();
	}

	refreshCarrier=()=>
	{
		this.getCompany();
	}
	

	closeVerification = () => {		
		$(".btnVerify").addClass('disabled');
		this.setState({
			...this.state,
			IsOpenVerify:false
		});		
	}

	loadTabs = () => {
		if(this.props.companyTypeId === 0){
			return(
				<ul className="nav nav-tabs" id="myTrucks" role="tablist">
					<li className="nav-item">
						<a className="nav-link active" id="carriers-tab" data-toggle="tab" href="#carriers" role="tab" aria-controls="carriers" aria-selected="true">CARRIERS</a>
					</li>
				</ul>
			);
		}
		else{
			return(
				<ul className="nav nav-tabs" id="myTrucks" role="tablist">
					<li className="nav-item">
						<a className="nav-link active" id="carriers-tab" data-toggle="tab" href="#carriers" role="tab" aria-controls="carriers" aria-selected="true">CARRIERS</a>
					</li>
				</ul>
			);
		}
	}

	getCarrierList = (row) => {
        this.setState({
            ...this.state,
			pageFlag:'NEW',
			carrierCompanyId:row.Company_Id,
			carriercompanyName:row.Company_Name
        });
    }

	render()
	{
		if(this.state.pageFlag==='NEW')
		{
			return <Dashboard flag={this.state.pageFlag} carriercompanyName={this.state.carriercompanyName} carrierCompanyId={this.state.carrierCompanyId} />
		}

		console.log(this.props.data.companyList);

		let onboarded=[];
		let carrier_nodata="";
		if(this.props.loading){
			carrier_nodata = 
			<div className="spinner-border" role="status">
			 <div className="row react-bs-table-no-data"></div>
			 </div>			
		} 
		else {
			carrier_nodata = "No Data Found";
			if(this.props.data.companyList){
				onboarded = this.props.data.companyList.map((row, index) =>{
						return({
							id:index,
							Company_Id:row.Company_Id,
							Invited_Id:row.Invited_Id,
							Company_Name:row.Company_Name,
							Country_Code:row.Country_Code,
							Speciality_Type_Name:row.Speciality_Type_Name,
							State_Name:row.State,
							Current_Zip:row.Zipcode,
							Phone_Number:<span>
							<i data-tip data-for={"onboardedphonenumber"+row.Company_Id} id="lanehistoryclass" className="icofont-phone-circle"></i>
							<ReactTooltip id={"onboardedphonenumber"+row.Company_Id} >
								<p>Phone : {row.Phone_Number}</p>
							</ReactTooltip>
							</span>,
							Company_Email:<span>
							<i data-tip data-for={"onboarded"+row.Company_Id} id="lanehistoryclass" className="icofont-email"></i>
								<ReactTooltip id={"onboarded"+row.Company_Id} >
									<p>Email : {row.Company_Email}</p>
								</ReactTooltip>
							</span>,
							City_Name:row.City,
							mc_no:row.Carrier_MC_Number,
							dot_no:row.Carrier_DOT_Number,
							ELD_Provider_Name:row.ELD_Provider_Name,
							Eld_Provider_Id:row.ELD_Provider_Id,
							Actionformap:
						    <button onClick={(e)=>{this.getCarrierList(row)}} className='btn btn-primary' >View Dashboard </button>
						});
				});
			}
		}
		
		
		const { SearchBar } = Search;

		const onboardColumns=[	
			{
				 text:'',
				 dataField:'staricon',
				 clickToSelect: false
			},
			{
				text:'Company Name',
				dataField:'Company_Name',
				sort:true
			},
			{
				text:'Phone',
				dataField:'Phone_Number',
				sort:true
			},
			{
				text:'Email',
				dataField:'Company_Email',
				sort:true
			},			
			{
				text:'City',
				dataField:'City_Name',
				sort:true
			},
			{
				text:'Zip',
				dataField:'Current_Zip',
				sort:true
			},
			{
				text:'State',
				dataField:'State_Name',
				sort:true
			},
			// {
			// 	text:'Speciality',
			// 	dataField:'Speciality_Type_Name',
			// 	sort:true
			// },					
			// {
			// 	text:'MC #',
			// 	dataField:'mc_no',
			// 	sort:true
			// },
			// {
			// 	text:'DOT #',
			// 	dataField:'dot_no',
			// 	sort:true
			// },
			// {
			// 	text:'ELD Provider',
			// 	dataField:'ELD_Provider_Name',
			// 	sort:true
			// },
			{
				text:'Action',
				dataField:'Actionformap',
				sort:true
			},		
		 ];
		const customTotal = (from, to, size) => (
			<span className="react-bootstrap-table-pagination-total">
			  Showing { from } to { to } of { size } Results
			</span>
		  );
		  
		  const options = {
			paginationSize: 4,
			pageStartIndex: 1,
			firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: customTotal
		  };
		return(
			<article className="table-data truck-data shadow bg-white rounded">
				<div className="row no-gutters">
					<div className="col-md-12">
						<div className="tab-content" id="myTabContent">
						  	<div className="tab-pane fade show active" id="carriers" role="tabpanel" aria-labelledby="carriers-tab">
							   <ToolkitProvider
									keyField="id"
									data={ onboarded }
									columns={ onboardColumns }
									search
								   >
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={carrier_nodata}  hover 
											selectRow={ { mode: 'checkbox' } }  bordered={ false } />
										</div>
									)
									}
								</ToolkitProvider>
						  	</div>
						</div>
					</div>
					{}
				</div>
			</article>
		)
   }
}
const mapStateToProps = (state) => {
	let preferredCompanyList = [];
	let eldCompanyList = [];
	let invitedCompany = [];
	if(state.companyReducer.preferredCompanyList.companyList)
	{
		preferredCompanyList = state.companyReducer.preferredCompanyList.companyList;
	}
	if(state.companyReducer.preferredCompanyList.eldCompanyList)
	{
		eldCompanyList = state.companyReducer.preferredCompanyList.eldCompanyList;
	}
	if(state.companyReducer.preferredCompanyList.invitedCompany)
	{
		invitedCompany = state.companyReducer.preferredCompanyList.invitedCompany;
	}
		//console.log(state.companyReducer.preferredCompanyList);
	return{
	   preferredCompanyList : preferredCompanyList,
	   eldCompanyList:	eldCompanyList,
	   invitedCompany: invitedCompany,
	   CarrierList	: state.loadReducer.companyList,
	   loading : state.companyReducer.loading,
	   data: state.companyReducer.data,
	   searchlists:state.companyReducer.data,
	   carriersearchFlag:state.companyReducer.carriersearchFlag,
	   carrierAddFlag:state.companyReducer.carrierAddFlag,
	   preference_changes : state.companyReducer.preference_changes,
	   carrierbulkuploadFlag:state.companyReducer.carrierbulkuploadflag
	}    
}
export default connect(mapStateToProps)(BrokersCarrierList)