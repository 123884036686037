
import React, { Component, Fragment } from 'react';
import AddTrailerPopup from './AddTrailerPopup';
import EditTrailerPopup from './EditTrailerPopup';
import TrailerList from './TrailerList';

class Trailers extends Component{
    state={
		trailerslist : [
            {Trailername:'Gypsy',year:'2019',make:'freightliner',model:'Cascadia 125',plate:'TN-88-ZE-1234',Serial:'2345678',id:1},
     		{Trailername:'Guzzler',year:'2019',make:'freightliner',model:'Cascadia 125',plate:'TN-88-ZE-1234',Serial:'2345678',id:2},
			 {Trailername:'Squeaker',year:'2019',make:'freightliner',model:'Cascadia 125',plate:'TN-88-ZE-1234',Serial:'2345678',id:3},
			 {Trailername:'Squeaker',year:'2019',make:'freightliner',model:'Cascadia 125',plate:'TN-88-ZE-1234',Serial:'2345678',id:4} 
        ]
	}
	componentDidMount() {
        document.title = 'Trailers';
	}
	render(){
		return(
			<div className="wrapper">				
					<section className="box-wrap">
						<Fragment>							
							<TrailerList trailerlist={ this.state.trailerslist }/>
							<AddTrailerPopup/>
							<EditTrailerPopup />
						</Fragment>						
					</section>								
			</div>
		)
	}
}

export default Trailers