import React, { Component } from 'react';
import splash_warning from '../splash_warning.svg'

class ToggleSwitch extends Component {
  setCustomerDetails(methodName){
    methodName(this.props.data)
  }
  render() {
    return (
        <div class='modal' id='toggleSwitchModal'>
        <div class='modal-dialog modal-dialog-centered modal-lz'>
          <div class='modal-content'>
          <div className="modal-header">
          <h5 className='opt-heading-css'>{this.props.data.authorize?'Enable':'Disable'} Customer</h5>              
        </div>
            <div class='modal-body'>
            <div className='img text-center'>
            <img src={splash_warning} alt='' /><br/>
            <h6 className='opt-sub-heading-css'>Would you like to {this.props.data.authorize?'Enable':'Disable'}  data sharing with  <span className='contentCss' >{this.props.data.customerName} ?</span></h6>              
            <p>Data sharing will remain {this.props.data.authorize?'Enabled':'Disabled'}  until you choose to {this.props.data.authorize?'disable':'enable'} sharing.</p>
            </div>
            </div>
            <div class='modal-footer'>
              <button type='button'className='btn btn-primary btn-sm' data-dismiss='modal'>Cancel</button>
              <button type='button' data-dismiss='modal'  onClick={() => { this.setCustomerDetails(this.props.data.methodName) }} className='btn btn-primary btn-sm sendbtn '>{this.props.data.authorize?'Enable':'Disable'} </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ToggleSwitch;