const components = {
	dashboard: {
		component:'FactorDashboard',
		//component: 'Dashboard',
		url: '/dashboard',
		title: 'Dashboard',
		icon: 'menu',
		module: 1
	},
	techloads: {
		component: 'TechLoads',
		url: '/loads',
		title: 'Loads',
		icon: 'menu',
		module: 4
	},
	techcarriers: {
		component: 'TechCarriers',
		url: '/carriers',
		title: 'Carriers',
		icon: 'menu',
		module: 2
	},
	brokercontacts: {
		component: 'BrokerContacts',
		url: '/contacts',
		title: 'Contacts',
		icon: 'menu',
		module: 3
	},
	//Brokers CarrierPage for techpartner route
	brokercarriers: {
		component: 'BrokerCarriers',
		url: '/carriers',
		title: 'Carriers',
		icon: 'menu',
		module: 1
	},
	TechpartnerTrucks: {
		component: 'TechpartnerTrucks',
		url: '/trucks',
		title: 'Trucks',
		icon: 'menu',
		module: 1
	},
	carrierprofileview: {
        component: 'carrierprofileview',
        url: '/profileView',
        title: 'View Trailer',
        icon: 'menu',
        module: 1
	},
	Permissions:{
		component: 'Permissions',
		url: '/permissions',
		title: 'Permissions',
		icon: 'menu',
		module: 1
    },
	Users: {
		component: 'Users',
		url: '/users',
		title: 'Users',
		icon: 'menu',
		module: 1
	},
	Settings: {
		component: 'Settings',
		url: '/settings',
		title: 'Settings',
		icon: 'menu',
		module: 2
	},  
	Profile: {
		component: 'Profile',
		url: '/Profile',
		title: 'Profile',
		icon: 'menu',
		module: 2
	},  
	CompanyProfile: {
		component: 'CompanyProfile',
		url: '/company_profile',
		title: 'Company Profile',
		icon: 'menu',
		module: 1
	},
	emailaccountlist: {
		component: 'EmailAccountList',
		url: '/email_accounts',
		title: 'Email Accounts',
		icon: 'menu',
		module: 1
	},
	emailtemplatelist: {
		component: 'EmailTemplateList',
		url: '/email_templates',
		title: 'Email Templates',
		icon: 'menu',
		module: 1
	},  
}

const TechUserRoutes = {
	techpartner: {
		routes: [
			components.dashboard,
			components.techcarriers,
			components.brokercontacts,
			components.techloads,
			components.brokercarriers,
			components.TechpartnerTrucks,
			components.carrierprofileview,
			components.Permissions,
			components.Users,
			components.CompanyProfile,
			components.emailaccountlist,
			components.emailtemplatelist,
			components.Settings,
			components.Profile
        ]
    }
};

export { TechUserRoutes };