import MuiCard from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import React from 'react';

const Card = (props) => {
  const { children, raised = false, classes, sxContainer, sxContent } = props
  return (
    <MuiCard
      sx={{
        border: '1px solid #E9E9E9',
        boxShadow: '0px 2px 4px rgb(0 0 0 / 10%)',
        // boxShadow: '0px 2px 4px rgba(18, 28, 45, 0.1',
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        // padding: '36px 48px',
        borderRadius: '8px',
        ...sxContainer,
      }}
      classes={classes}
      raised={raised}
    >
      <CardContent sx={sxContent}>{children}</CardContent>
    </MuiCard>
  )
}

export default Card
