import * as types from './index';

export const ContactAction = (payload) => {
    return {
        type: types.GET_CONTACT_DATA,
        payload: payload
    }
};

export const ContactListactions = (payload) => {
    return {
        type: types.LIST_CONTACT_DATA,
        payload: payload
    }
};

export const AddContact = (payload) => {
    return {
        type: types.ADD_CONTACT_DATA,
        payload: payload
    }
};

export const EditContact = (payload) => {
	return {
		type: types.EDIT_CONTACT_DATA,
		payload: payload
	}
};

export const deleteContact = (payload) => {
	return {
		type: types.DELETE_CONTACT,
		payload: payload
	}
};





