import * as types from '../actions';

const initialState = {
    data: [],
    loading: false,
    error: ''
};

export default function(state = initialState, action) {  
    switch(action.type) {
        case types.LIST_CONTACT_DATA:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.LIST_CONTACT_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.LIST_CONTACT_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.ADD_CONTACT_DATA:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.ADD_CONTACT_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.ADD_CONTACT_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.EDIT_CONTACT_DATA:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.EDIT_CONTACT_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.EDIT_CONTACT_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.DELETE_CONTACT:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.DELETE_CONTACT_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.DELETE_CONTACT_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};