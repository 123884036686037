import { CommonRoutes } from './CommonRoutes';

const components = {
	carrierloads: {
		component: 'CarrierLoads',
		url: '/loads',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	carrierdrivers:{
		component: 'CarrierDrivers',
		url: '/carrier-drivers',
		title: 'CarrierDrivers',
		icon: 'menu',
		module: 1
	},
	quickdispatch:{
		component: 'QuickDispatch',
		url: '/loads/quickdispatch',
		title: 'CarrierLoadsQuickDispatch',
		icon: 'menu',
		module: 1
	},
	carriertrucks: {
		component: 'CarrierTrucks',
		url: '/trucks',
		title: 'Trucks',
		icon: 'menu',
		module: 1
	},
	carriertrailers: {
		component: 'CarrierTrailers',
		url: '/trailers',
		title: 'Trailers',
		icon: 'menu',
		module: 1
	},
	hos: {
		component: 'Hos',
		url: '/hours-of-services',
		title: 'Hos',
		icon: 'menu',
		module: 1
	},
	carriercontacts: {
		component: 'CarrierContacts',
		url: '/contacts',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	carrierdocuments: {
		component: 'CarrierDocuments',
		url: '/documents',
		title: 'Documents',
		icon: 'menu',
		module: 1
	},
	carrieradddocuments: {
		component: 'CarrierAddDocuments',
		url: '/documents/add/:type',
		title: 'Add Documents',
		icon: 'menu',
		module: 1
	},
	carrierviewdrivers: {
        component: 'CarrierViewDrivers',
        url: '/viewdriver',
        title: 'View Driver',
        icon: 'menu',
        module: 1
    },  
    carrierviewtrucks: {
        component: 'CarrierViewTrucks',
        url: '/viewtruck',
        title: 'View Truck',
        icon: 'menu',
        module: 1
    },    
    carrierviewtrailers: {
        component: 'CarrierViewTrailers',
        url: '/viewtrailer/:id',
        title: 'View Trailer',
        icon: 'menu',
        module: 1
	}, 
	carrierprofileview: {
        component: 'carrierprofileview',
        url: '/profileView',
        title: 'View Trailer',
        icon: 'menu',
        module: 1
	},    
	carriercancelledloads: {
		component: 'cancelloads',
        url: '/cancelledLoads',
        title: 'View Trailer',
        icon: 'menu',
        module: 1
	},
	optInOptOut: {
		component: 'optInOptOut',
        url: '/opt_in-out',
        title: 'OptInOptOut',
        icon: 'menu',
        module: 1
	}, 
	shipperbrokers: {
		component: 'ShipperBrokers',
		url: '/brokers',
		title: 'brokers',
		icon: 'menu',
		module: 1
	},
	Task: {
		component: 'Task',
		url: '/Task',
		title: 'task',
		icon: 'menu',
		module: 1
	},
	Permissions:{
		component: 'Permissions',
		url: '/permissions',
		title: 'Permissions',
		icon: 'menu',
		module: 1
    },
	Users: {
		component: 'Users',
		url: '/users',
		title: 'Users',
		icon: 'menu',
		module: 1
	},

	CompanyProfile: {
		component: 'CompanyProfile',
		url: '/company_profile',
		title: 'Company Profile',
		icon: 'menu',
		module: 1
	},
	CustomersList: {
		component: 'CustomersList',
		url: '/customers',
		title: 'customers',
		icon: 'menu',
		module: 1
	},
	Profile: {
		component: 'Profile',
		url: '/Profile',
		title: 'Profile',
		icon: 'menu',
		module: 2
	} 
};

const CarrierRoutes = {
	carrier: {
		routes: [
			...CommonRoutes,
			components.carrierloads,
			components.carrierdrivers,
			components.quickdispatch,
			components.hos,
			components.carriertrucks,
			components.carriertrailers,
			components.carriercontacts,
			components.carrierdocuments,
			components.carrieradddocuments,
			components.carrierviewdrivers, 
    		components.carrierviewtrucks,  
			components.carrierviewtrailers, 
			components.carrierprofileview, 
			components.carriercancelledloads, 
			components.optInOptOut,
			components.shipperbrokers,
			components.Task,
			components.Permissions,
			components.CustomersList,
			components.CompanyProfile,
			components.Users,
			components.Profile
		]
	}
};

export { CarrierRoutes };