import React, { Component } from 'react';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import { ChangeLoadStatus, companyListByType, editloadvalues, lanedetails, loadActionfrquickdispatch, loadAction, dispatchflag, TenderDetails, updateTenderStatusAction, cancelledloadsbyloadid } from '../../../actions/loadActions';
import { STATUS } from '../../../helpers/status';
import CancelledLoadpopup from './cancelledLoadpopup';
import Lanehistory from './lanehistory';
import LoadTenderOrderModalPopup from './LoadTenderOrderModalPopup';
import moment from 'moment';
import QuickDispatch from './QuickDispatch';
import { TabsBar, TableDataComponent } from 'UI-Component';
import $ from 'jquery'

class LoadList extends Component {
	constructor(props) {
		super(props);
		this.getIndex = this.getIndex.bind(this)
		this.myloads = [];
		this.requested = [];
		this.notyetdispatched = [];
		this.dispatched = [];
		this.delivered = [];
		this.accepted = [];
		this.tendered = [];
		this.delivery_uc = [];
		this.delivery_c = [];
		this.paid_close = [];
		this.currentStatus = STATUS.LOAD.REQUESTED;
		this.cancelled = [];
		this.totalItemsCount = 1;
		this.activePage = 1;
		this.itemsCountPerPage = 5;
		this.pageRangeDisplayed = 5;
	}
	state = {
		modaldata: [], cancelledloads: [], checkboxval: false
	}
	getIndex(index, selectedId) {
		this.setState({ activeId: selectedId })
	}
	componentDidMount() {
		$("#notyetdispatchedbrkr").hide();
	}
	handleGetSelectedData = () => {
		if (this.node.selectionContext.selected.length === 0) {
			toastr.info('Info', 'Select Load!');
			return;
		}
		else {
			const params = {
				loadsId: this.node.selectionContext.selected
			}
			this.props.dispatch(cancelledloadsbyloadid(params))
		}
	}

	selectLoad = (e, index, row) => {
		row[index].checkboxvalue = e.target.checked;
		this.setState({
			checkboxval: e.target.checked
		});
	}

	tabChangefrquickdispatch = (type) => {
		if (type.loadStatusId === 8) {
			$("#reqcancelbtnbrkr").hide();
			$("#notyetdispatchedbrkr").show();
			$("#addloadbroker").hide();

		}
		else if (type.loadStatusId === 1) {
			$("#reqcancelbtnbrkr").show();
			$("#notyetdispatchedbrkr").hide();
			$("#addloadbroker").show();
		}
		else {
			$("#reqcancelbtnbrkr").hide();
			$("#notyetdispatchedbrkr").hide();
			$("#addloadbroker").hide();
		}
		const params = {
			...STATUS.LOAD.REQUESTED,
		}
		this.props.dispatch(loadActionfrquickdispatch(params));
		this.currentStatus = type;
	}

	tabChange = (type) => {
		if (type.loadStatusId === 8) {
			$("#reqcancelbtnbrkr").hide();
			$("#notyetdispatchedbrkr").show();
			$("#addloadbroker").hide();

		}
		else if (type.loadStatusId === 1) {
			$("#reqcancelbtnbrkr").show();
			$("#notyetdispatchedbrkr").hide();
			$("#addloadbroker").show();
		}
		else {
			$("#reqcancelbtnbrkr").hide();
			$("#notyetdispatchedbrkr").hide();
			$("#addloadbroker").hide();
		}
		const params = {
			...type,
		}
		this.props.dispatch(loadAction(params));
		this.currentStatus = type;
	}



	getModal = (item, dispatch) => {
		this.setState({ showModal: true, modaldata: item });
	};

	dispacthFun(load) {
		const params = {
			loadId: load
		}
		this.props.dispatch(ChangeLoadStatus(params));
	}

	openTenderOrder(id) {
		const params = {
			loadId: id
		}
		const params1 = {
			companytypeid: 7
		}
		this.props.dispatch(TenderDetails(params));
		this.props.dispatch(companyListByType(params1));
	}

	lanehistory(id) {
		const params = {
			loadId: id
		}
		this.props.dispatch(lanedetails(params));
	}

	componentDidUpdate() {
		if (this.props.changeloadstatusupdateflag === true) {
			if (this.props.data.message === "Load status changed Successfully") {
				toastr.success('Success', 'Load status changed Successfully.!');
				this.props.dispatch(dispatchflag());
				if (this.currentStatus.loadStatusId === 13) {
					const params = {
						...(STATUS.LOAD.DISPATCHEDENROUTE)
					}
					this.props.dispatch(loadAction(params));
				} else if (this.currentStatus.loadStatusId === 8) {
					const params = {
						...(STATUS.LOAD.DELIVERY_CONFIRMED)
					}
					this.props.dispatch(loadAction(params));
				}
				else if (this.currentStatus.loadStatusId === 3) {
					const params = {
						...(STATUS.LOAD.TENDERED)
					}
					this.props.dispatch(loadAction(params));
				}
			}
		}


	}

	editloadpopup(loadid) {
		const params = {
			loadId: loadid
		}
		this.props.dispatch(editloadvalues(params));
	}

	loadtenderedstatuschange = (e, loadid) => {
		const tenderVal =
		{
			'statusval': e.target.value,
			'loadid': loadid,
		}
		this.props.dispatch(updateTenderStatusAction(tenderVal))
	}
	handleTabClicked = (e, val) => {
		if (Array.isArray(val)) {
			this.setState({
				cancelledloads: val
			})
		}
		if (this.state.cancelledloads.length > 0 && e.target.name === "cancelbtn") {
			var selLoads = this.state.cancelledloads.filter((row1) => {
				return row1.loadSel;
			});

			if (selLoads.length === 0) {
				toastr.info('Info', 'Select Load!');
				return;
			}

			selLoads = selLoads.map((load) => load.id);

			const params = {
				loadsId: selLoads
			}

			this.props.dispatch(cancelledloadsbyloadid(params))
		} else if (this.state.cancelledloads.length === 0 && e.target.name === "cancelbtn") {
			toastr.info('Info', 'Select Load!');
			return;
		}
		else {
			return;
		}
	}
	render() {
		const { loading } = this.props;
		if (this.props.data.LoadList !== undefined) {
			if (this.currentStatus === STATUS.LOAD.REQUESTED) {
				this.requested = this.props.data.LoadList;
			}
			else if (this.currentStatus === STATUS.LOAD.ACCEPTED) {
				this.accepted = this.props.data.LoadList;
			}
			else if (this.currentStatus === STATUS.LOAD.TENDERED) {
				this.tendered = this.props.data.LoadList;
			}
			else if (this.currentStatus === STATUS.LOAD.DISPATCHEDENROUTE) {
				this.dispatched = this.props.data.LoadList;
			}
			else if (this.currentStatus === STATUS.LOAD.DELIVERY_CONFIRMED) {
				this.notyetdispatched = this.props.data.LoadList;
			}
			else if (this.currentStatus === STATUS.LOAD.DELIVERED) {
				this.delivered = this.props.data.LoadList;
			}
		}
		var loadRequested = [];
		var loadaccepted = [];
		var loadtendered = [];
		var notyetdispatched = [];
		var dispatched = [];
		var delivered = [];
		if (loading) {
			var indicationhtml =
				<div className="spinner-border" role="status">
					<div className="row react-bs-table-no-data"></div>
				</div>
		}
		else {
			var loadreqlist = [];

			if (this.requested.length > 0) {
				loadreqlist = this.requested;
				loadRequested = [];
				loadRequested = this.requested.map(
					row => {
						var drivername = '';
						if (row.driverName !== null) {
							drivername = row.driverName.toUpperCase()
						} else {
							drivername = '-'
						}
						var stringLoadDate = "";
						if (row.stringLoadDate !== "") {
							stringLoadDate = moment(row.stringLoadDate).format('MM-DD-YYYY');
						}
						else {
							stringLoadDate = "";
						}
						return ({
							id: row.loadId,
							loadNumber: row.loadNumber,
							stringLoadDate: stringLoadDate,
							shipperName: row.shipperName,
							customerName: row.customerName,
							carrierName: row.carrierName,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + " " + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCityName: row.originCityName,
							destinationCityName: row.destinationCityName,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							loadPointsList: row.loadPointsList,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							carrierTenderPrice: row.carrierTenderPrice,
							rate: row.rate,
							totalBrokerNet: row.totalBrokerNet,
							originState: row.orginStateName,
							assetName: row.assetName,
							driverName: drivername,
							distance: row.distance,
							destinationState: row.destinationStateName,
							clkicon: <i data-toggle="modal" data-target=".lanehistorymodal" id="lanehistoryclass" onClick={() => { this.lanehistory(row.loadId); }} className="icofont-clock-time"></i>,
							action: <div><a href="/#" onClick={() => { this.dispacthFun(row.loadId); }} className="btn_carrier_move btn btn-sm btn-primary">Accept</a>
								&nbsp;&nbsp;&nbsp;<a href="/#" className="btn_carrier_move btn btn-sm btn-primary" data-toggle="modal" onClick={() => { this.editloadpopup(row.loadId); }} data-target="#editloadpopup">Edit</a></div>
						});
					}
				);

			}
			else {
				loadRequested = [];
				indicationhtml = "No Data Found"
			}

			if (this.accepted.length > 0) {
				loadaccepted = []
				loadaccepted = this.accepted.map(
					row => {
						var drivername = '';
						if (row.driverName !== null) {
							drivername = row.driverName.toUpperCase()
						} else {
							drivername = '-'
						}
						var stringLoadDate = "";
						if (row.stringLoadDate !== "") {
							stringLoadDate = moment(row.stringLoadDate).format('MM-DD-YYYY');
						}
						else {
							stringLoadDate = "";
						}
						return ({
							id: row.loadId,
							loadNumber: row.loadNumber,
							stringLoadDate: stringLoadDate,
							shipperName: row.shipperName,
							customerName: row.customerName,
							carrierName: row.carrierName,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + " " + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCityName: row.originCityName,
							destinationCityName: row.destinationCityName,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							loadPointsList: row.loadPointsList,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							carrierTenderPrice: row.carrierTenderPrice,
							rate: row.rate,
							assetName: row.assetName,
							driverName: drivername,
							distance: row.distance,
							totalBrokerNet: row.totalBrokerNet,
							originState: row.orginStateName,
							destinationState: row.destinationStateName,
							action: <a href="/#" onClick={() => { this.openTenderOrder(row.loadId); }} data-toggle="modal" data-target="#LoadTenderOrderModalPopup" className="btn_carrier_move btn btn-sm btn-primary">Tender</a>
						});
					}
				);
			}
			else {
				loadaccepted = [];
				indicationhtml = "No Data Found"
			}

			if (this.tendered.length > 0) {
				loadtendered = []
				loadtendered = this.tendered.map(
					row => {
						var drivername = '';
						if (row.driverName !== null) {
							drivername = row.driverName.toUpperCase()
						} else {
							drivername = '-'
						}
						var stringLoadDate = "";
						if (row.stringLoadDate !== "") {
							stringLoadDate = moment(row.stringLoadDate).format('MM-DD-YYYY');
						}
						else {
							stringLoadDate = "";
						}
						return ({
							id: row.loadId,
							loadNumber: row.loadNumber,
							stringLoadDate: stringLoadDate,
							shipperName: row.shipperName,
							customerName: row.customerName,
							carrierName: row.carrierName,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + " " + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCityName: row.originCityName,
							destinationCityName: row.destinationCityName,
							commodity: row.commodity,
							carrierTenderPrice: row.carrierTenderPrice,
							rate: row.rate,
							totalBrokerNet: row.totalBrokerNet,
							equipmentType: row.truckTypeName,
							action:
								<div className="dropdown">
									<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										Status
									</button>
									<div className="dropdown-menu" aria-labelledby="dropdownMenu2">
										<button className="dropdown-item" type="button" value="not_yet_dispatched" onClick={(e) => { this.loadtenderedstatuschange(e, row.loadId) }}>Not Yet Dispatched</button>
										<button className="dropdown-item" type="button" value="dispatched_and_enroute" onClick={(e) => { this.loadtenderedstatuschange(e, row.loadId) }}>Dispatched</button>
										<button className="dropdown-item" type="button" value="delivered" onClick={(e) => { this.loadtenderedstatuschange(e, row.loadId) }}>Delivered</button>
									</div>
								</div>,
							loadPointsList: row.loadPointsList,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							carrierTenderPrice: row.carrierTenderPrice,
							rate: row.rate,
							totalBrokerNet: row.totalBrokerNet,
							originState: row.orginStateName,
							destinationState: row.destinationStateName,
							assetName: row.assetName,
							driverName: drivername,
							distance: row.distance
						});
					}
				);
			}
			else {
				loadtendered = [];
				indicationhtml = "No Data Found"
			}

			if (this.notyetdispatched.length > 0) {
				notyetdispatched = []
				notyetdispatched = this.notyetdispatched.map(
					(row, index) => {
						var drivername = '';
						if (row.driverName !== null) {
							drivername = row.driverName.toUpperCase()
						} else {
							drivername = '-'
						}
						var stringLoadDate = "";
						if (row.stringLoadDate !== "") {
							stringLoadDate = moment(row.stringLoadDate).format('MM-DD-YYYY');
						}
						else {
							stringLoadDate = "";
						}
						return ({
							id: row.loadId,
							loadNumber: row.loadNumber,
							stringLoadDate: stringLoadDate,
							shipperName: row.shipperName,
							customerName: row.customerName,
							carrierName: row.carrierName,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + " " + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCityName: row.originCityName,
							destinationCityName: row.destinationCityName,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							rate: row.rate,
							carrierTenderPrice: row.carrierTenderPrice,
							totalBrokerNet: row.totalBrokerNet,
							action: <div className="dropdown">
								<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									Status
								</button>
								<div className="dropdown-menu" aria-labelledby="dropdownMenu2">
									<button className="dropdown-item" type="button" value="dispatched_and_enroute" onClick={(e) => { this.loadtenderedstatuschange(e, row.loadId) }}>Dispatched</button>
									<button className="dropdown-item" type="button" value="delivered" onClick={(e) => { this.loadtenderedstatuschange(e, row.loadId) }}>Delivered</button>
								</div>
							</div>,
							loadPointsList: row.loadPointsList,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							originState: row.orginStateName,
							carrierTenderPrice: row.carrierTenderPrice,
							destinationState: row.destinationStateName,
							assetName: row.assetName,
							driverName: drivername,
							distance: row.distance,
							checkboxvalue: this.state.checkboxval,
							locationchckbox: <input type="checkbox" id={"chkvaluefrshipper" + row.loadId} defaultChecked={true} />
						});
					}
				);
			}
			else {
				notyetdispatched = [];
				indicationhtml = "No Data Found"
			}

			if (this.dispatched.length > 0) {
				dispatched = []
				dispatched = this.dispatched.map(
					row => {
						var drivername = '';
						if (row.driverName !== null) {
							drivername = row.driverName.toUpperCase()
						} else {
							drivername = '-'
						}
						var stringLoadDate = "";
						if (row.stringLoadDate !== "") {
							stringLoadDate = moment(row.stringLoadDate).format('MM-DD-YYYY');
						}
						else {
							stringLoadDate = "";
						}
						return ({
							id: row.loadId,
							loadNumber: row.loadNumber,
							stringLoadDate: stringLoadDate,
							shipperName: row.shipperName,
							customerName: row.customerName,
							carrierName: row.carrierName,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + " " + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCityName: row.originCityName,
							destinationCityName: row.destinationCityName,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							action: <div className="dropdown">
								<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									Status
								</button>
								<div className="dropdown-menu" aria-labelledby="dropdownMenu2">
									<button className="dropdown-item" type="button" value="delivered" onClick={(e) => { this.loadtenderedstatuschange(e, row.loadId) }}>Delivered</button>
								</div>
							</div>,
							loadPointsList: row.loadPointsList,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							carrierTenderPrice: row.carrierTenderPrice,
							rate: row.rate,
							totalBrokerNet: row.totalBrokerNet,
							originState: row.orginStateName,
							destinationState: row.destinationStateName,
							assetName: row.assetName,
							driverName: drivername,
							distance: row.distance
						});
					}
				);
			}
			else {
				dispatched = [];
				indicationhtml = "No Data Found"
			}

			if (this.delivered.length > 0) {
				delivered = []
				delivered = this.delivered.map(
					row => {
						var drivername = '';
						if (row.driverName !== null) {
							drivername = row.driverName.toUpperCase()
						} else {
							drivername = '-'
						}
						var stringLoadDate = "";
						if (row.stringLoadDate !== "") {
							stringLoadDate = moment(row.stringLoadDate).format('MM-DD-YYYY');
						}
						else {
							stringLoadDate = "";
						}
						return ({
							id: row.loadId,
							loadNumber: row.loadNumber,
							stringLoadDate: stringLoadDate,
							shipperName: row.shipperName,
							customerName: row.customerName,
							carrierName: row.carrierName,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + " " + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCityName: row.originCityName,
							destinationCityName: row.destinationCityName,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							loadPointsList: row.loadPointsList,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							carrierTenderPrice: row.carrierTenderPrice,
							originState: row.orginStateName,
							destinationState: row.destinationStateName,
							assetName: row.assetName,
							driverName: drivername,
							distance: row.distance
						});
					}
				);
			}
			else {
				delivered = [];
				indicationhtml = "No Data Found"
			}
		}
		const columns1 = [
			{
				text: 'Load #',
				dataField: 'loadNumber',
				sort: true
			},
			{
				text: 'Date',
				dataField: 'stringLoadDate',
				sort: true
			},
			{
				text: 'Shipper',
				dataField: 'shipperName',
				sort: true
			},
			{
				text: 'Weight',
				dataField: 'weight',
				sort: true
			},
			{
				text: 'Equipment',
				dataField: 'equipmentType',
				sort: true
			},
			{
				text: 'Distance',
				dataField: 'distance',
				sort: true
			},
			{
				text: 'Rate',
				dataField: 'rate',
				sort: true
			},
			{
				text: 'Broker Net',
				dataField: 'av',
				sort: true
			},
			{
				text: 'Action',
				dataField: 'action'
			}
		]
		const accptdcolumns = [
			{
				text: 'Load #',
				sort: true,
				dataField: 'loadNumber'
			},
			{
				text: 'Date',
				sort: true,
				dataField: 'stringLoadDate'
			},
			{
				text: 'Shipper',
				sort: true,
				dataField: 'shipperName'
			},
			{
				text: 'Carrier',
				sort: true,
				dataField: 'carrierName'
			},
			{
				text: 'Vehicle',
				sort: true,
				dataField: 'assetName'
			},
			{
				text: 'Driver',
				sort: true,
				dataField: 'driverName'
			},
			{
				text: 'Weight',
				sort: true,
				dataField: 'weight'
			},
			{
				text: 'Equipment',
				sort: true,
				dataField: 'equipmentType'
			},
			{
				text: 'Distance',
				sort: true,
				dataField: 'distance'
			},
			{
				text: 'Action',
				dataField: 'action'
			}
		]

		const notyetdispatchedcolumns = [
			{
				text: 'Load #',
				sort: true,
				dataField: 'loadNumber'
			},
			{
				text: 'Date',
				sort: true,
				dataField: 'stringLoadDate'
			},
			{
				text: 'Shipper',
				sort: true,
				dataField: 'shipperName'
			},
			{
				text: 'Carrier',
				sort: true,
				dataField: 'carrierName'
			},
			{
				text: 'Vehicle',
				sort: true,
				dataField: 'assetName'
			},
			{
				text: 'Driver',
				sort: true,
				dataField: 'driverName'
			},
			{
				text: 'Weight',
				sort: true,
				dataField: 'weight'
			},
			{
				text: 'Equipment',
				sort: true,
				dataField: 'equipmentType'
			},
			{
				text: 'Distance',
				sort: true,
				dataField: 'distance'
			},
			{
				text: 'Action',
				dataField: 'action'
			}
		]


		const deliverdcolumns = [
			{
				text: 'Load #',
				sort: true,
				dataField: 'loadNumber'
			},
			{
				text: 'Date',
				sort: true,
				dataField: 'stringLoadDate'
			},
			{
				text: 'Shipper',
				sort: true,
				dataField: 'shipperName'
			},
			{
				text: 'Carrier',
				sort: true,
				dataField: 'carrierName'
			},
			{
				text: 'Vehicle',
				sort: true,
				dataField: 'assetName'
			},
			{
				text: 'Driver',
				sort: true,
				dataField: 'driverName'
			},
			{
				text: 'Weight',
				sort: true,
				dataField: 'weight'
			},
			{
				text: 'Equipment',
				sort: true,
				dataField: 'equipmentType'
			},
			{
				text: 'Distance',
				sort: true,
				dataField: 'distance'
			}
		]


		const expandRow2 = {
			renderer: row => (
				<article className="table-data truck-data shadow bg-white rounded">
					<div className="row no-gutters">
						<div className="col-md-12">
							<ul className="nav nav-tabs" id="myTrucks" role="tablist">
								<li className="nav-item">
									<a className="nav-link active" id={`pickup-tab_${row.id}`} data-toggle="tab" href={`#pickup_${row.id}`} role="tab" aria-controls="pickup" aria-selected="true" >
										pickup Details
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" id="delivery-tab" data-toggle="tab" href={`#delivery_${row.id}`} role="tab" aria-controls="delivery" aria-selected="false" >
										Delivery Details
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" id="brokertender-tab" data-toggle="tab" href={`#brokertender_${row.id}`} role="tab" aria-controls="brokertender" aria-selected="false" >
										Broker Tender
									</a>
								</li>
							</ul>
							<div className="tab-content" id="myTabContent">
								<div className="tab-pane fade show active" id={`pickup_${row.id}`} role="tabpanel" aria-labelledby="pickup-tab">
									<table className="table table-sm">
										<thead>
											<tr>
												<th scope="col">Address</th>
												<th scope="col">Contact</th>
												<th scope="col">Earliest Time</th>
												<th scope="col">Latest Time</th>
											</tr>
										</thead>
										<tbody>
											{
												row.loadPointsList.length ? (row.loadPointsList.map(function (doc, index) {
													if (doc.loadPointTypeId === 2) {
														var Contact = (doc.contactAddress1 == null ? "" : doc.contactAddress1);
														Contact = (Contact === "" ? "" : (Contact + ", ")) + (doc.contactAddress2 == null ? "" : doc.contactAddress2);
														Contact = (Contact === "" ? "" : (Contact + ", ")) + (doc.contactZip == null ? "" : doc.contactZip);
														return (
															<tr key={index}>
																<td>{(Contact === null) ? <i>&nbsp;</i> : (Contact)}</td>
																<td>{(doc.contactNumber === null) ? <i>&nbsp;</i> : (doc.contactNumber)}</td>
																<td>{(doc.earliestTime === null) ? <i>&nbsp;</i> : (doc.earliestTime)}</td>
																<td>{(doc.latestTime === null) ? <i>&nbsp;</i> : (doc.latestTime)}</td>
															</tr>
														)
													}
												})) : (<tr><td className="text-center" colSpan="4">No Record Found</td></tr>)
											}
										</tbody>
									</table>
								</div>
								<div className="tab-pane fade" id={`delivery_${row.id}`} role="tabpanel" aria-labelledby="delivery-tab">
									<table className="table table-sm">
										<thead>
											<tr>
												<th scope="col">Address</th>
												<th scope="col">Contact</th>
												<th scope="col">Earliest Time</th>
												<th scope="col">Latest Time</th>
											</tr>
										</thead>
										<tbody>
											{
												row.loadPointsList.length ? (row.loadPointsList.map(function (doc, index) {
													if (doc.loadPointTypeId === 3) {
														var Contact = (doc.contactAddress1 == null ? "" : doc.contactAddress1);
														Contact = (Contact === "" ? "" : (Contact + ", ")) + (doc.contactAddress2 == null ? "" : doc.contactAddress2);
														Contact = (Contact === "" ? "" : (Contact + ", ")) + (doc.contactZip == null ? "" : doc.contactZip);
														return (
															<tr key={index}>
																<td>{(Contact === null) ? <i>&nbsp;</i> : (Contact)}</td>
																<td>{(doc.contactNumber === null) ? <i>&nbsp;</i> : (doc.contactNumber)}</td>
																<td>{(doc.earliestTime === null) ? <i>&nbsp;</i> : (doc.earliestTime)}</td>
																<td>{(doc.latestTime === null) ? <i>&nbsp;</i> : (doc.latestTime)}</td>
															</tr>
														)
													}
												})) : (<tr><td className="text-center" colSpan="4">No Record Found</td></tr>)
											}
										</tbody>
									</table>
								</div>
								<div className="tab-pane fade" id={`brokertender_${row.id}`} role="tabpanel" aria-labelledby="brokertender-tab">
									<table className="table table-sm">
										<thead>
											<tr>
												<th scope="col">Base Charge</th>
												<th scope="col">Fuel Charge</th>
												<th scope="col">Accessorial Charge</th>
												<th scope="col">Tendered Price</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>{(row.basecharge === null) ? <i>&nbsp;</i> : (row.basecharge)}</td>
												<td>{(row.fulecharge === null) ? <i>&nbsp;</i> : (row.fulecharge)}</td>
												<td>{(row.accessiorialcharge === null) ? <i>&nbsp;</i> : (row.accessiorialcharge)}</td>
												<td>{(row.carrierTenderPrice === null) ? <i>&nbsp;</i> : (row.carrierTenderPrice)}</td>
											</tr>
										</tbody>
									</table>
								</div>

							</div>
						</div>
					</div>
				</article>
			),
			showExpandColumn: true,
			expandByColumnOnly: true,
			clickToSelect: false,
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return (
						<i className="icofont-minus-circle"></i>
					);
				}
				return (
					<i className="icofont-plus-circle"></i>
				);
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<i className="icofont-minus-circle"></i>
					);
				}
				return (
					<i className="icofont-plus-circle"></i>
				);
			}
		};
		return (
			<article className="table-data truck-data shadow bg-white rounded" id="htmlclr">
				<div className="row no-gutters">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-12">
								<div className="d-flex justify-content-end">
									<ul className="ul-horizontal tab-menus">
										<li>
											<a href="/#" className="btn btn-primary btn-sm" data-toggle="modal" id="addloadbroker" data-target=".addLoadModal">Add Load</a>
										</li>
										<li>
											<a href="/#" id="reqcancelbtnbrkr" onClick={(e, val) => { this.handleTabClicked(e, val); }} name="cancelbtn" className="btn_carrier_move btn btn-sm btn-primary">Cancel</a>
											<a href="/#" id="notyetdispatchedbrkr" onClick={this.handleGetSelectedData} name="cancelbtn" className="btn_carrier_move btn btn-sm btn-primary">Cancel</a>
										</li>
										<li>
											<Link className="btn_carrier_move btn btn-sm btn-primary" to="cancelledLoads"> Cancelled</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<TabsBar tabsData={
							[{ component: 'REQUESTED', tabID: 'requested-tab', refData: '#requested', methodName: (e, data, index) => { this.tabChangefrquickdispatch(STATUS.LOAD.REQUESTED) } },
							{ component: 'ASSIGNED', tabID: 'notyetdispatched-tab', refData: '#notyetdispatched', methodName: (e, data, index) => { this.tabChange(STATUS.LOAD.DELIVERY_CONFIRMED) } },
							{ component: 'DISPATCHED ', tabID: 'dispatched-tab', refData: '#dispatched', methodName: (e, data, index) => { this.tabChange(STATUS.LOAD.DISPATCHEDENROUTE) } },
							{ component: 'DELIVERED', tabID: 'delivered-tab', refData: '#delivered', methodName: (e, data, index) => { this.tabChange(STATUS.LOAD.DELIVERED) } }
							]}
							getIndex={this.getIndex}
							activeTab={'#requested'}
						/>
						<div className="tab-content" id="myTabContent">
							<div className="tab-pane fade show active" id="requested" role="tabpanel" aria-labelledby="requested-tab">
								<QuickDispatch loadreq={loadreqlist} tabClicked={(e, val) => { this.handleTabClicked(e, val) }} currentStatus={this.currentStatus} />

							</div>
							<div className="tab-pane fade" id="accepted" role="tabpanel" aria-labelledby="accepted-tab">
								<TableDataComponent tableData={loadaccepted} columnData={columns1} noDataIndication={indicationhtml} expandRow={expandRow2} />
							</div>
							<div className="tab-pane fade" id="tendered" role="tabpanel" aria-labelledby="tendered-tab">
								<TableDataComponent tableData={loadtendered} columnData={columns1} noDataIndication={indicationhtml} expandRow={expandRow2} />
							</div>

							<div className="tab-pane fade" id="notyetdispatched" role="tabpanel" aria-labelledby="notyetdispatched-tab">
								<TableDataComponent tableData={notyetdispatched} columnData={notyetdispatchedcolumns} noDataIndication={indicationhtml} expandRow={expandRow2} />
							</div>

							<div className="tab-pane fade" id="dispatched" role="tabpanel" aria-labelledby="dispatched-tab">
								<TableDataComponent tableData={dispatched} columnData={accptdcolumns} noDataIndication={indicationhtml} expandRow={expandRow2} />
							</div>

							<div className="tab-pane fade" id="delivered" role="tabpanel" aria-labelledby="delivered-tab">
								<TableDataComponent tableData={delivered} columnData={deliverdcolumns} noDataIndication={indicationhtml} expandRow={expandRow2} />
							</div>

							<div className="tab-pane fade" id="delivery_uc" role="tabpanel" aria-labelledby="delivery_uc_load-tab">
							</div>
							<div className="tab-pane fade" id="delivery_c" role="tabpanel" aria-labelledby="delivery_c_load-tab">
							</div>
							<div className="tab-pane fade" id="bamload" role="tabpanel" aria-labelledby="bamload-tab">
								<table className="table table-sm">
									<thead>
										<tr>
											<th scope="col"></th>
											<th scope="col">Load #</th>
											<th scope="col">Date</th>
											<th scope="col">Shipper</th>
											<th scope="col">Carrier</th>
											<th scope="col">Broker</th>
											<th scope="col">Rate</th>
											<th scope="col">Fuel Advance</th>
											<th scope="col">Fuel Advance Commission</th>
											<th scope="col">Broker Net</th>
											<th scope="col">Total Broker Net</th>
											<th scope="col">Weight</th>
											<th scope="col">Equipment</th>
											<th scope="col">Origin</th>
											<th scope="col">Destination</th>
										</tr>
									</thead>
									<tbody>
									</tbody>
								</table>
							</div>
							<div className="tab-pane fade" id="paid_close" role="tabpanel" aria-labelledby="paid_close_load-tab">
								<table className="table table-sm">
									<thead>
										<tr>
											<th scope="col"></th>
											<th scope="col"></th>
											<th scope="col">Load #</th>
											<th scope="col">Truck #</th>
											<th scope="col">Bill Miles</th>
											<th scope="col">Location</th>
											<th scope="col">Dispatcher</th>
											<th scope="col">Driver</th>
											<th scope="col">Driver Pay</th>
											<th scope="col">HOS</th>
											<th scope="col">Shipper</th>
											<th scope="col">Consignee</th>
											<th scope="col">Action</th>
										</tr>
									</thead>
									<tbody>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<LoadTenderOrderModalPopup loadDetails={this.state.selLoad} companyList={this.props.companyList} />
				<CancelledLoadpopup loadlistval={this.cancelled} />
				<Lanehistory loadlistval={this.cancelled} />
			</article>
		)
	}
}
const mapStateToProps = state => {
	return {
		data: state.loadReducer.data,
		companyList: state.loadReducer.companyList,
		editvaluesview: state.loadReducer.editvaluesview,
		loading: state.loadReducer.loading,
		error: state.loadReducer.error,
		changeloadstatusupdateflag: state.loadReducer.changeloadstatusupdateflag,
		quickdispatchloads: state.loadReducer.data
	}
}
export default connect(mapStateToProps)(LoadList);