import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { ContactListactions, deleteContact } from '../../../actions/contactActions';
import ContactsNew from './ContactsNew';
import EditContactsPopup from './EditContactsPopup';
class ContactList extends Component{

	state={
		modaldata:[]
	 }	

	getModal = (tList,dispatch) => {
		this.setState({ showModal: true, modaldata: tList });
	 };	
	componentDidMount=()=>
	{ 
		this.props.dispatch(ContactListactions());
	}
	deletecontact = (id) => {
		confirmAlert({
			title: 'Are you sure to delete the contact ?',
			buttons: [
			  {
				label: 'Yes',
				onClick: () => this.props.dispatch(deleteContact(id))
			  },
			  {
				label: 'No'
			  }
			]
		  });
		};
	 render(){
	
	var contactlists = [];
	if(this.props.loading){
		var indicationhtml = 
		<div className="spinner-border" role="status">
		<div className="row react-bs-table-no-data"></div>
		 </div>
	}else
	if(this.props.data)
	{
		contactlists = []	;
		contactlists = this.props.data.map(
			row =>{
				return({
					id:row.contactId,					
					name:row.firstName,
					companyrow:row.company,
					phone:row.phoneNum, 
					email:row.emailId, 
					notes:row.notes,
					more:<div className="dropdown">
		      			<label data-toggle="dropdown">&hellip;</label>
		      			<div className="dropdown-menu">
		      				<a href="/#" className="dropdown-item" data-toggle="modal" data-target=".editContactModal" onClick={() => {this.getModal(row)}}><i className="icofont-ui-edit"></i> Edit</a>
							  <a className="dropdown-item trigger-addedit_document" onClick={() => {this.deletecontact(row.contactId)}} href="/#"><i className="icofont-ui-delete"></i> Delete </a>						
						  </div>
		      			</div> 					
				});
			}
		);
		
	}
	else
	{
		contactlists = [];	
		indicationhtml = "No Data Found";			
	}	
	const { SearchBar } = Search;
	const columns=[		  
	   {
		   text:'Name',
		   dataField:'name',
		   sort:true
	   },
	   {
		   text:'Company',
		   dataField:'companyrow',
		   sort:true
	   },
	   {
		   text:'Phone',
		   dataField:'phone',
		   sort:true
	   },
	   {
		   text:'Email',
		   dataField:'email',
		   sort:true
	   },
	   {
		   text:'Notes',
		   dataField:'notes',
		   sort:true
	   },	  
	   {
		  text:'Action',
		  dataField:'more'
	   }
	   
	]
	const customTotal = (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
		  Showing { from } to { to } of { size } Results
		</span>
	  );
	  
	  const options = {
		paginationSize: 4,
		pageStartIndex: 1,
			firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
		showTotal: true,
		paginationTotalRenderer: customTotal
	  };

	return(
		<article className="table-data truck-data shadow bg-white rounded">
			<div className="row no-gutters">
				<div className="col-md-12">
					<ul className="nav nav-tabs" id="myContacts" role="tablist">
					  	<li className="nav-item">
					    	<a className="nav-link active" id="mycontact-tab" data-toggle="tab" href="#mycontact" role="tab" aria-controls="mycontact" aria-selected="true"><i className="icofont-truck-loaded"></i>Contacts</a>
					  	</li>
					  	<div className="flex-left-auto">
					  		<ul>
					  			<li>
								  <a href="/#" className="btn btn-primary btn-sm" data-toggle="modal" data-target=".addContactModal">Add Contact</a>
					  			</li>
					  		</ul>
					  	</div>
					</ul>
					<div className="tab-content" id="myTabContent">
					  	<div className="tab-pane fade show active" id="mycontact" role="tabpanel" aria-labelledby="mycontact-tab">
						  <ToolkitProvider
									keyField="id"
									data={ contactlists }
									columns={ columns }
									search
								   >
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={indicationhtml}  hover 
											selectRow={ { mode: 'checkbox' } }  bordered={ false } />
										</div>
									)
									}
						  </ToolkitProvider>
					  	</div>
					</div>
				</div>
			</div>
			<ContactsNew />
			<EditContactsPopup modaldata={this.state.modaldata }/>
		</article>
	)
  }
}


const mapStateToProps = (state) => {
	return{
	   loading : state.contactReducer.loading,
       data : state.contactReducer.data.ContactList
	}    
}
export default connect(mapStateToProps)(ContactList)