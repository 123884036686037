import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { taskList,TaskChangeStatus } from '../../../actions/taskActions';
import TaskNew from './TaskNew';
import { TASKSTATUS } from '../../../helpers/status';
import { toastr } from 'react-redux-toastr';
import $ from 'jquery'

class TaskList extends Component{
	constructor(props) {
		super(props);
		this.currentStatus = TASKSTATUS.TASK.ASSIGNED;
		this.assigned = [];
		this.completed = [];
		this.deleted = [];
	}

	componentDidMount=()=>
	{ 
		const params={
			...TASKSTATUS.TASK.ASSIGNED,
		}
		this.props.dispatch(taskList(params));
	}

	 componentDidUpdate()
	{

		if(this.props.data.message==="Task Status Updated Successfully")
        {
		   toastr.success('Success', 'Task Deleted Successfully!');
		   if(this.currentStatus===TASKSTATUS.TASK.ASSIGNED)
		   {
			   const params={
				...TASKSTATUS.TASK.ASSIGNED,
				}
			   this.props.dispatch(taskList(params));	
		   }else if(this.currentStatus===TASKSTATUS.TASK.COMPLETED)
		   {
			   const params={
				...TASKSTATUS.TASK.COMPLETED,
				}
			   this.props.dispatch(taskList(params));	
		   }
		   
		}
		
		else if(this.props.data.message==="Task Created Successfully")
        {
		   toastr.success('Success', 'Task Created Successfully!');
		   if(this.currentStatus===TASKSTATUS.TASK.ASSIGNED)
		   {
			   const params={
				...TASKSTATUS.TASK.ASSIGNED,
				}
			   this.props.dispatch(taskList(params));	
			   document.getElementById('addtaskPopup').click()
		   }
        }
	}

	tabChange = (type,value) => { 
		if(value==="2")
		{
			$('#addtaskmodal').hide()
		}else if(value==="3")
		{
			$('#addtaskmodal').hide()
		}else if(value==="1")
		{
			$('#addtaskmodal').show()
		}
		const params={
			...type,
		}
		this.props.dispatch(taskList(params));
		this.currentStatus = type;
	}

	
	taskStatusChange(row)
	{
		const params={
			status:3,
			taskId:row.taskId
		}
		confirmAlert({
			title: 'Are you sure to complete the task ?',
			buttons: [
			  {
				label: 'Yes',
				onClick: () => this.props.dispatch(TaskChangeStatus(params))
			  },
			  {
				label: 'No'
			  }
			]
		  });
	}

	deleteTask(row)
	{
		const params={
			status:3,
			taskId:row.taskId
		}
		confirmAlert({
			title: 'Are you sure to delete the task ?',
			buttons: [
			  {
				label: 'Yes',
				onClick: () => this.props.dispatch(TaskChangeStatus(params))
			  },
			  {
				label: 'No'
			  }
			]
		  });
	}

	 render(){
		var AssignedData=[];
		var CompletedData=[];
		var DeletedData=[];
		var indicationhtml="No Data Found"
		if(this.props.data.taskList)
		{
			if(this.props.data.taskList !== undefined)
			{
				 if(this.currentStatus === TASKSTATUS.TASK.ASSIGNED)
				{
					this.assigned = this.props.data.taskList;
				}
				else if(this.currentStatus === TASKSTATUS.TASK.COMPLETED)
				{
					this.completed = this.props.data.taskList;
				}
				else if(this.currentStatus === TASKSTATUS.TASK.DELETED)
				{
					this.deleted = this.props.data.taskList;
				}
			}	
		}
		
		if(this.props.loading){
			indicationhtml = 
				<div className="spinner-border" role="status">
				<div className="row react-bs-table-no-data"></div>
				</div>
		}
		else{
			if(this.assigned.length > 0)
			{
				AssignedData = this.assigned.map(
					(row,index) =>{
						var priorityValue = "";
						if(row.priority==="0")
						{
							priorityValue = "Low"
						}
						if(row.priority==="1")
						{
							priorityValue = "Medium"
						}
						if(row.priority==="2")
						{
							priorityValue = "High"
						}
						var desc='-';
						if(row.description!==null)
						{
							var contactdescription = row.description.substring(0, 10)
							desc = contactdescription.concat('...');
						}else
						{
							desc = '-'
						}
						return({
							id:index,
							taskId:row.taskId,
							title:row.title,
							description:row.description,
							taskDate:row.taskDate,
							priority:priorityValue,
							status:row.status,
							driverName:row.driverName,
							assignedIcon:<i  data-toggle="modal" data-target=".lanehistorymodal" id="lanehistoryclass" title="Delete"
							onClick={() => {this.deleteTask(row);}} className="icofont-ui-delete"></i>	
						});
					}
				);
			}
			else
			{
				AssignedData=[];
				indicationhtml = "No Data Found";
			}

			if(this.completed.length > 0)
			{
				CompletedData = this.completed.map(
					(row,index) =>{
						var priorityValue = "";
						if(row.priority==="0")
						{
							priorityValue = "Low"
						}
						else if(row.priority==="1")
						{
							priorityValue = "Medium"
						}
						else if(row.priority==="2")
						{
							priorityValue = "High"
						}
						return({
							id:index,
							taskId:row.taskId,
							title:row.title,
							description:row.description,
							taskDate:row.taskDate,
							priority:priorityValue, 
							driverName:row.driverName,
							deleteIcon:<i  data-toggle="modal" data-target=".lanehistorymodal" id="lanehistoryclass" title="Delete"
							onClick={() => {this.deleteTask(row);}} className="icofont-ui-delete"></i>
						});
					}
				);
			}
			else
			{
				CompletedData=[];
				indicationhtml = "No Data Found";
			}

			if(this.deleted.length > 0)
			{
				DeletedData = this.deleted.map(
					(row,index) =>{
						var priorityValue = "";
						if(row.priority==="0")
						{
							priorityValue = "Low"
						}
						else if(row.priority==="1")
						{
							priorityValue = "Medium"
						}
						else if(row.priority==="2")
						{
							priorityValue = "High"
						}
						return({
							id:index,
							taskId:row.taskId,
							title:row.title,
							description:row.description,
							driverName:row.driverName,
							taskDate:row.taskDate,
							priority:priorityValue,	
						});
					}
				);
			}
			else
			{
				DeletedData=[];
				indicationhtml = "No Data Found";
			}
		}

	

	const { SearchBar } = Search;
	const columns=[	
	   {
		   text:'Title',
		   dataField:'title',
		   sort:true
	   },
	//    {
	// 	   text:'Description',
	// 	   dataField:'description',
	// 	   sort:true
	//    },
	   {
			text:'Driver Name',
			dataField:'driverName',
			sort:true
	   },
	   {
		   text:'Date',
		   dataField:'taskDate',
		   sort:true
	   },
	   {
		   text:'Priority',
		   dataField:'priority',
		   sort:true
	   },
	   {
			text:'Action',
			dataField:'assignedIcon',
			sort:true
	  },
	]
	const completedcolumns=[	
		{
			text:'Title',
			dataField:'title',
			sort:true
		},
		// {
		// 	text:'Description',
		// 	dataField:'description',
		// 	sort:true
		// },
		{
			 text:'Driver Name',
			 dataField:'driverName',
			 sort:true
		},
		{
			text:'Date',
			dataField:'taskDate',
			sort:true
		},
		{
			text:'Priority',
			dataField:'priority',
			sort:true
		},
		{
			 text:'Action',
			 dataField:'deleteIcon',
			 sort:true
	   },
	 ]

	 const deletedcolumns=[	
		{
			text:'Title',
			dataField:'title',
			sort:true
		},
		// {
		// 	text:'Description',
		// 	dataField:'description',
		// 	sort:true
		// },
		{
			 text:'Driver Name',
			 dataField:'driverName',
			 sort:true
		},
		{
			text:'Date',
			dataField:'taskDate',
			sort:true
		},
		{
			text:'Priority',
			dataField:'priority',
			sort:true
		},
	 ]
	const customTotal = (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
		  Showing { from } to { to } of { size } Results
		</span>
	  );
	  
	  const options = {
		paginationSize: 4,
		pageStartIndex: 1,
			firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
		showTotal: true,
		paginationTotalRenderer: customTotal
	  };

	  const expandRowForDesc = {	
		renderer: row => (	
			<article className="table-data truck-data shadow bg-white rounded">  
			   <div className="row no-gutters accordianclasstest">
				<div className="col-md-12">
						<ul className="nav nav-tabs" id="myTrucks" role="tablist">
							<li className="nav-item">
								<a className="nav-link active" id="pickup-tab"  data-toggle="tab" href={ `#pickup_${row.id}` } role="tab" aria-controls="pickup" aria-selected="true" >
								   Description
								</a>
							</li>
						</ul>
						<div className="tab-content accordianclasstest" id="myTabContent">
								<div className="tab-pane fade show active" id={ `pickup_${row.id}` } role="tabpanel" aria-labelledby="pickup-tab">								
										<div><p><b>{row.description}</b></p></div>						
								</div>				
						  </div>
			    	</div>
			</div>
		</article>
		),
		 showExpandColumn: true,
		 expandByColumnOnly: true,
		 expandHeaderColumnRenderer: ({ isAnyExpands  }) => {
			if (isAnyExpands ) {
			  return (
				<i className="icofont-minus-circle"></i>
			  );
			}
			return (
				<i  className="icofont-plus-circle"></i>
			);
		  },
		expandColumnRenderer: ({ expanded }) => {
			if (expanded) {
			  return (
				<i className="icofont-minus-circle"></i>
			  );
			}
			return (
				<i  className="icofont-plus-circle"></i>
			);
		  }
   };

	return(
		<article className="table-data truck-data shadow bg-white rounded">
		<div className="row no-gutters">
			<div className="col-md-12">
				<ul className="nav nav-tabs" id="myTrucks" role="tablist">
					<li className="nav-item">
						<a onClick={()=>{this.tabChange(TASKSTATUS.TASK.ASSIGNED,1)}} className="nav-link active" id="assigned-tab" data-toggle="tab" href="#requested" role="tab" aria-controls="requested" aria-selected="true" >
						  ASSIGNED
						</a>
					</li>
					<li className="nav-item">
						<a onClick={()=>{this.tabChange(TASKSTATUS.TASK.COMPLETED,2);}} className="nav-link" id="notyetdispatchedload-tab" data-toggle="tab" href="#loadtobedisp" role="tab" aria-controls="notyetdispatchedload" aria-selected="false" >
						  COMPLETED
						</a>
					</li>
					<li className="nav-item">
						<a onClick={()=>{this.tabChange(TASKSTATUS.TASK.DELETED,3)}} className="nav-link" id="dispatchedenrouteload-tab" data-toggle="tab" href="#loadDispatched" role="tab" aria-controls="dispatchedenrouteload" aria-selected="false" >
						   DELETED
						</a>
					</li>						
				     <div className="flex-left-auto">
						<ul className="ul-horizontal tab-menus">
								<li>
								<a href="/#" className="btn btn-primary btn-sm" data-toggle="modal" id="addtaskmodal" data-target=".addContactModal">Add Task</a>
									</li>
							</ul>
					</div>							
				</ul>
				<div className="tab-content" id="myTabContent">
					<div className="tab-pane fade show active" id="requested" role="tabpanel" aria-labelledby="assigned-tab">
						<ToolkitProvider
							keyField="id"
							data={ AssignedData }
							columns={ columns }
							search
						   >
							{
							props => (
								<div>
								<SearchBar { ...props.searchProps } />
								<BootstrapTable bootstrap4 
									{ ...props.baseProps } pagination={ paginationFactory(options) } expandRow={expandRowForDesc}  hover noDataIndication={indicationhtml}
									bordered={ false }  />
								</div>
							)
							}
						</ToolkitProvider>
					</div>
					<div className="tab-pane fade" id="loadtobedisp" role="tabpanel" aria-labelledby="requested-tab">
						<ToolkitProvider
							keyField="id"
							data={ CompletedData }
							columns={ completedcolumns }
							search
						   >
							{
							props => (
								<div>
								<SearchBar { ...props.searchProps } />
								<BootstrapTable bootstrap4 
									{ ...props.baseProps } pagination={ paginationFactory(options) } expandRow={expandRowForDesc} noDataIndication={indicationhtml}  hover 
									  bordered={ false }
									 />
								</div>
							)
							}
						</ToolkitProvider>
					</div>
					<div className="tab-pane fade" id="loadDispatched" role="tabpanel" aria-labelledby="requested-tab">
						<ToolkitProvider
							keyField="id"
							data={ DeletedData }
							columns={ deletedcolumns }
							search
						   >
							{
							props => (
								<div>
								<SearchBar { ...props.searchProps } />
								<BootstrapTable bootstrap4 
									{ ...props.baseProps } pagination={ paginationFactory(options) } expandRow={expandRowForDesc} noDataIndication={indicationhtml}  hover 
									bordered={ false } />
								</div>
							)
							}
						</ToolkitProvider>
					</div>
				</div>
			</div>
		</div>
		<TaskNew />
	</article>
	)
  }
}


const mapStateToProps = (state) => {
	return{
	   loading : state.taskReducer.loading,
       data : state.taskReducer.data
	}    
}
export default connect(mapStateToProps)(TaskList)