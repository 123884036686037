import React, { Component } from 'react';
import Loader from './loader';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ReactTooltip from 'react-tooltip';
import {companyNameListService} from '../../services/companyService';
import { DateTimePicker } from 'react-widgets'
class AdminShipperList extends Component{
    constructor(props){
        super(props);

        this.state={
            isReady:false,
            shipperList:[],
            GeofenceRad:[
                {id:"1",radiusVal:"5"},
                {id:"2",radiusVal:"10"},
                {id:"3",radiusVal:"15"},
                {id:"4",radiusVal:"20"}
            ],
        }
    }

    componentDidMount = () => {
        document.title = 'Shipper List';
        //this.getShippers();
    }

    getShippers = () =>{
        companyNameListService({companytypeid:1}).then((response) => {
            console.log(response);
            if(response.companyList){
                this.setState({
                    ...this.state,
                    isReady:true,
                    shipperList:response.companyList
                });
            }
        });
    }

    getRowData = () => {
        if(this.state.shipperList.length===0){
            return [];
        } else {
            return this.state.shipperList.map(row =>{
                let shipperRef ="";
                if(row.companyMisc){
                    if(row.companyMisc.shipperReferenceNumber){
                        shipperRef =row.companyMisc.shipperReferenceNumber;
                    }
                }
                return({
                    companyId:row.companyId,
                    companyEmail:<span>
					<i data-tip data-for={"driveremail"+row.companyId} id="lanehistoryclass" className="icofont-email"></i>
						<ReactTooltip id={"driveremail"+row.companyId} >
							<p>Email : {row.companyEmail}</p>
						</ReactTooltip>
					</span>,
                    companyName:row.companyName,
                    companyShortCode:row.companyShortCode,
                    phoneNumber:<span>
					<i data-tip data-for={"driverphnnumber"+row.companyId} id="lanehistoryclass" className="icofont-phone-circle"></i>
					<ReactTooltip id={"driverphnnumber"+row.companyId} >
						<p>Phone : {row.phoneNumber}</p>
					</ReactTooltip>
					</span>,
                    geoFenceRadius:<select onChange={this.laneDetails} className="form-control json-col" name="shipper">
                    <option value="">Select Radius</option>
                      {
                           this.state.GeofenceRad?(this.state.GeofenceRad.map(function(row, index) {  
                                       return(
                                           <option key={index} value={row.id}>{row.radiusVal}</option>
                                   )             
                           })):(<option></option>)
                      }                                       
                   </select>,
                   geofencetime: <DateTimePicker
                    className="json-col" 
                    name="geofencetime"
                    placeholder="Time"
                    date={false}
                    min={new Date()}
                    dropDown
                    />,
                    Action : <a href="/#" className="btn_carrier_move btn btn-sm btn-primary">Save</a>
                });
            });
        }
    }

    getTableColumns = () => {
        return [
            {
				text:'Company Name',
				dataField:'companyName',
				sort:true,
            },
            {
				text:'Phone Number',
				dataField:'phoneNumber',
				sort:true,
            },
            {
				text:'Email',
				dataField:'companyEmail',
				sort:true,
            },
            {
				text:'Geofence Radius',
				dataField:'geoFenceRadius',
                sort:true,
                headerStyle:{width:'15%'}
            },
            {
				text:'Time',
				dataField:'geofencetime',
                sort:true,
                headerStyle:{width:'15%'}
            },
            {
				text:'Action',
				dataField:'Action',
                sort:true,
            }
        ]
    }

    render = () =>{        
        if(this.state.isReady){
            const { SearchBar } = Search;
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                  Showing { from } to { to } of { size } Results
                </span>
            );
            
            const options = {
                paginationSize: 4,
                pageStartIndex: 1,      
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                showTotal: true,
                paginationTotalRenderer: customTotal,
            };

            return(
                <article className="shadow bg-white rounded" id="onboardpage">
                    <div className="row">
                        <div className="col">
                            <h3>Shippers List</h3>
                        </div>
                    </div>
                    <article className="table-data truck-data shadow bg-white rounded">
				        <div className="row no-gutters">
					        <div className="col-md-12">
                                <ToolkitProvider
                                    keyField="id"
                                    data={ this.getRowData() }
                                    columns={ this.getTableColumns() }
                                    search
                                    >
                                    {
                                    props => (
                                        <div>
                                        <div className="SearchDispatched float-right d-inline-block"><SearchBar { ...props.searchProps } /></div>
                                        <BootstrapTable
                                            { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={'No data found'}  hover bordered={ false } 
                                        />
                                        </div>
                                    )
                                    }
                                </ToolkitProvider>
                            </div>
                        </div>
                    </article>
                </article>
            );
        }
		else{
			return(<Loader />);
		}
    }
}

export default AdminShipperList;