import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../helpers/FormControls';

class ViewTrailer extends Component{
    render(){
        this.props.initialize(this.props.location.aboutProps);
        return(
            <form className="tg-forms"> 
                <div className="table-data border">
                    <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">View Trailer</a>
                        </li>                                 
                    </ul>
                    <div className="tab-content" id="myTabContent">
                  	    <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab"> 
                          <form className="tg-forms">
							<div className="row">                 
								<div className="col col-md-12">
									<h5>TRAILER INFO</h5>
									<article className="table-data trailer-data shadow bg-white rounded">
										<div className="row">
											<div className="col col-md-4">
												<div className="form-group">
													<Field type="text" name="asset_Number" component={renderField} label="Trailer #"/> 
												</div>
												<div className="form-group">
													<Field type="text" name="model_Year" component={renderField} label="Year"/>  
												</div>
											</div>
											<div className="col col-md-4">
												<div className="form-group">
													<Field type="text" name="make" component={renderField} label="Make"/> 
												</div>
												<div className="form-group">
													<Field type="text" name="license_Plate_No" component={renderField} label="Plate #"/>
												</div>
											</div>
											<div className="col col-md-4">
												<div className="form-group">
													<Field type="text" name="model" component={renderField} label="Model"/> 
												</div>
												<div className="form-group">
													<Field type="text" name="device_id" component={renderField} label="Serial"/>
												</div>
											</div>                           
										</div>
									</article>
									<h5>NOTES</h5>
									<article className="table-data trailer-data shadow bg-white rounded">
                                        <div className="row">
                                            <div className="form-group col col-md-12"> 
												<Field type="text" name="identification_No" component={renderField} label="Notes"/>
                                            </div>             
                                        </div> 
									</article>
									{/* <div className="row">
										<div className="col col-md-12 ">
											<div className="modal-footer">
												<button type="submit" className="btn btn-secondary btn-sm">Save</button>&nbsp;
											<a href="#" className="btn btn-primary btn-sm">Reset</a>
											</div>
										</div>
									</div> */}
								</div>
							</div>
                    	</form>
                    </div>                   
                </div>
            </div>   
        </form>
        )
    }
}
const ShowTheLocationWithRouter = withRouter(ViewTrailer);
export default reduxForm({
    form: 'ViewTrailer', 
     enableReinitialize: false,
})(ShowTheLocationWithRouter);
//export default ViewTrailer