import _ from 'lodash';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { assetadd } from '../../../actions/assetAction';
import { renderField, renderSelect,renderSelectStringVal } from '../../../helpers/FormControls';
import Bulkupload from './Bulkupload';
import { validate } from './validation';

function submit(values) {
	const frmdata = {};
    frmdata.assetELDId=(values.deviceId) ? (values.deviceId) : ("");
    frmdata.assetNumber=(values.assetNumber) ? (values.assetNumber) : ("");
    frmdata.assetName=(values.assetName) ? (values.assetName) : ("");
    frmdata.assetType='trailer';
    frmdata.eldProviderId=(values.eldProviderId) ? (values.eldProviderId) : ("");
    frmdata.licensePlateNo=(values.licensePlateNo) ? (values.licensePlateNo) : ("");
    frmdata.identificationNo=(values.identificationNo) ? (values.identificationNo) : ("");
    frmdata.licenseState=(values.licenseState) ? (values.licenseState) : ("");
    frmdata.manufacturer=(values.manufacturer) ? (values.manufacturer) : ("");
    frmdata.make=(values.make) ? (values.make) : ("");
    frmdata.model=(values.model) ? (values.model) : ("");
    frmdata.modelYear=(values.modelYear) ? (values.modelYear) : ("");
    frmdata.color=(values.color) ? (values.color) : ("");
    frmdata.assetSerialNo=(values.deviceId) ? (values.deviceId) : ("");
    frmdata.fuelType=(values.fuelType) ? (values.fuelType) : ("");  
    frmdata.tankCapacity1=(values.tankCapacity1) ? (values.tankCapacity1) : ("");
    frmdata.tankCapacity2=(values.tankCapacity2) ? (values.tankCapacity2) : ("");
    frmdata.size=(values.size) ? (values.size) : ("");
    frmdata.axles=(values.axles) ? (values.axles) : ("");
    frmdata.weight=(values.weight) ? (values.weight) : ("");
    frmdata.description=(values.description) ? (values.description) : ("");
    frmdata.isHeavyduty=(values.isHeavyduty) ? (values.isHeavyduty) : ("");
    frmdata.dataStatus=(values.dataStatus) ? (values.dataStatus) : ("");
    frmdata.isBulkUpload=(values.isBulkUpload) ? (values.isBulkUpload) : ("");
    frmdata.optInOut=(values.optInOut) ? (values.optInOut) : ("");
    this.props.dispatch(assetadd(frmdata));
}

class AddTrailerPopup extends Component{
	handleClick = () =>{
		this.props.initialize('addtrailerpopupshippper')
	}	
render(){
	const { handleSubmit, pristine, reset, submitting } = this.props   
	let minOffset = 0, maxOffset = 20;
	let thisYear = (new Date()).getFullYear();
	let allYears = [];
	for(let x = 0; x <= maxOffset; x++) {
		allYears.push(thisYear - x)
	}
	//const yearList = allYears.map((x) => {return(<option value={x} key={x}>{x}</option>)});
	const yearList = allYears.map(x=>{       
		return{
			"id":x,
			"name":x,
		}
	}); 
 
return(
	<div className="modal fade"  data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
	<div className="modal-dialog modal-dialog-centered modal-lg">
		 <div className="modal-content">     
		   <div className="modal-header">
		   <h5 className="modal-title doc-color" id="exampleModalLabel">ADD TRAILER</h5>
			<button type="button" className="close" id="addfrm" data-dismiss="modal" aria-label="Close" onClick={this.handleClick}>
				<span aria-hidden="true">&times;</span>
			</button>
		   </div>
			<div className="modal-body table-data">
							  <ul className="nav nav-tabs" id="myTrailers" role="tablist">
								 <li className="nav-item">
									<a  className="nav-link active" id="addtruck-tab" data-toggle="tab" href="#addtruck" role="tab" aria-controls="addtruck" aria-selected="true" >
									ADD TRAILER
									</a>
								  </li>
								  <li className="nav-item">
									<a className="nav-link " id="bulkupload-tab" data-toggle="tab" href="#bulkupload" role="tab" aria-controls="bulkupload" aria-selected="false" >
									BULK UPLOAD
									</a>
								  </li>
							   </ul>
							   <div className="tab-content" id="myTabContent">
									<div className="tab-pane fade show active" id="addtruck" role="tabpanel" aria-labelledby="addtruck-tab">
									  <form className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}>
									   <div className="row">                 
										  <div className="col col-md-12">
										  <h5 className="doc-color">TRAILER INFO</h5>
										  <article className="table-data truck-data shadow bg-white rounded">
												   <div className="row">
													  <div className="col col-md-4">
														 <div className="form-group">
															 <Field type="text" className="form-control"  controlClass="requiredfield" name="assetNumber" id="truck" component={renderField} label="Trailer #" />                          
														 </div>
														 <div className="form-group">
															 <Field type="text" className="form-control" name="modelYear" id="year" component={renderSelect} data={yearList} label="Year" /> 
														 </div> 
														 <div className="form-group">
															 <Field type="text" className="form-control" name="assetName" component={renderField} label="Asset Name" />                                                        
														 </div>
													  </div>
													  <div className="col col-md-4">
													  <div className="form-group">
															 <Field type="text" className="form-control" name="make" id="make" component={renderField} label="Make" />                                                        
														 </div>
														 <div className="form-group">
															 <Field type="text" className="form-control" controlClass="requiredfield" name="identificationNo" id="VIN" component={renderField} label="VIN" />                         
														 </div>
														 <div className="form-group">
															  <Field type="text" className="form-control" name="color" id="color" component={renderField} label="Color" />                          
													  </div>
													  </div>
													  <div className="col col-md-4">     
														 <div className="form-group">
															 <Field type="text" className="form-control" name="model" id="model" component={renderField} label="Model" />                                                       
														 </div>
														 <div className="form-group">
															 <Field type="text" className="form-control" name="licensePlateNo" id="plate" component={renderField} label="License Plate #" />                                                    
														 </div>
														 <div className="form-group">
															<Field type="text" className="form-control" name="licenseState" component={renderSelect} data={this.props.state.data} label="License State" />                          
														 </div>
													  </div>                           
												   </div>
												   <div className="row">
													  <div className="col col-md-4">
														 <div className="form-group">
															 <Field type="text" className="form-control" name="manufacturer" component={renderField} label="Manufacturer" />                          
														 </div>
														 <div className="form-group"> 
															 <Field type="text" className="form-control" name="tankCapacity1" component={renderField} label="Tank Capacity1" /> 
														 </div> 
														 <div className="form-group">
															 <Field type="text" className="form-control" name="tankCapacity2"  component={renderField} label="Tank Capacity2" />                                                        
														 </div>
													  </div>
													  <div className="col col-md-4">
													  <div className="form-group">
															 <Field type="text" className="form-control" name="size" component={renderField} label="Size" />                                                        
														 </div>
														 <div className="form-group">
															 <Field type="text" className="form-control" name="axles" component={renderField} label="Axles" />                         
														 </div>
														 <div className="form-group">
															  <Field type="text" className="form-control" name="weight" component={renderField} label="Weight" />                          
													  </div>
													  </div>
													  <div className="col col-md-4">     
														 <div className="form-group">
															 <Field type="text" className="form-control" name="description" component={renderField} label="Description" />                                                       
														 </div>
														 <div className="form-group">
															<Field type="text" className="form-control" name="fuelType" component={renderSelectStringVal} data={this.props.fueltypelist.FuelTypeList} label="Fuel Type" />                          
														 </div>
													  </div>                           
												   </div>
												   </article>
												   <h5>ELD DETAILS</h5>
												   <article className="table-data truck-data shadow bg-white rounded">
													<div className="row">
													  <div className="col col-md-5">
														 <div className="form-group">
															 <Field type="text" className="form-control" name="eldProviderId" id="provider" component={renderSelect} data={this.props.eldproviderlist.data}  label="ELD Provider" />                         
														 </div>
													  </div>
													  <div className="col col-md-6">
														 <div className="form-group">
															 <Field type="text" className="form-control"  controlClass="requiredfield" name="deviceId" id="serialno" component={renderField} label="Device Serial #" />                                                        
														 </div>
													  </div>
													</div>
													 </article> 
														 <div className="row">
														 <div className="col col-md-12">
															<div className="form-group form-group-btns">
															   <button type="submit" className="btn btn-primary btn-sm">Save</button>&nbsp;
															   <button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-primary btn-sm">Reset</button>
															</div>
														 </div>
													  </div>
												</div>
											 </div>
									  </form>                          
									  </div>
									  <div className="tab-pane fade" id="bulkupload" role="tabpanel" aria-labelledby="bulkupload-tab">
										 <Bulkupload />                                             
									  </div>
								</div>
					  </div>
				 </div>
			</div>
	   </div>
)
}
}
export default reduxForm({
    form: 'addtrailerpopupshippper',  
    validate             
})(AddTrailerPopup)