import React, { Component } from 'react';

class Report extends Component{
  state={
      reportList : [
             {bamwire_order:'BAM01',reference:'RF6789',billing_reference:'LD132Q',po_number:'121252',pu_number:'147457',bol_number:'SN8933',date_ordered:'2019-09-12',shipper:'O2COOL',carrier:'Brylant Logistics Inc',pickup_date:'2019-09-23',id:1},
             {bamwire_order:'BAM02',reference:'RF6980',billing_reference:'LD1786Q',po_number:'22325',pu_number:'10628296',bol_number:'SN14976',date_ordered:'2019-10-12',shipper:'O2COOL',carrier:'GR Cargo Inc',pickup_date:'2019-10-26',id:2},
             {bamwire_order:'BAM03',reference:'RF6345',billing_reference:'LD1808Q',po_number:'134869',pu_number:'SN16073',bol_number:'SN15202',date_ordered:'2019-11-12',shipper:'O2COOL',carrier:'United Freight Systems LLC',pickup_date:'2019-11-25',id:3}
                  ]
       }

   render(){
      const {reportList} = this.state;
      const reportListHtml = reportList.map( rList =>{
      return(
             <tr key={ rList.id }>
               <td><i className="circledown icofont-circled-down"></i></td>
               <td>{ rList.bamwire_order }</td>
               <td>{ rList.reference }</td>
               <td>{ rList.billing_reference }</td>
               <td>{ rList.po_number }</td>
               <td>{ rList.pu_number}</td>
               <td>{ rList.bol_number }</td>
               <td>{ rList.date_ordered }</td>
               <td>{ rList.shipper }</td>
               <td>{ rList.carrier }</td>
               <td>{ rList.pickup_date }</td>
            </tr>
           )
   });
      return(
         <article className="table-data truck-data shadow bg-white rounded">
         <div className="row">
         <div className="col-md-11">
            <h5>LOAD REPORT</h5>
         </div>
         <div className="col-md-11">
         <div>         
             <a href="/#" className="btn btn-primary btn-sm">UNASSIGNED</a>&nbsp;
             <a href="/#" className="btn btn-primary btn-sm">NOT TENDERED</a>&nbsp;
             <a href="/#" className="btn btn-primary btn-sm">DRIVER UNASSIGNED</a>&nbsp;
             <a href="/#" className="btn btn-primary btn-sm">NOT YET DISPATCHED</a>&nbsp;
             <a href="/#" className="btn btn-primary btn-sm">UNPICKED</a>&nbsp;
             <a href="/#" className="btn btn-primary btn-sm">STOPPED</a>&nbsp;
             <a href="/#" className="btn btn-primary btn-sm">UNDELIVERED</a>&nbsp;
             <a href="/#" className="btn btn-primary btn-sm"><i className="icofont-upload-alt"></i>EXCEL</a>&nbsp;           
         </div>
         </div> 
         </div>
          <div className="card">
          <div className="card-body">
            <table className="table table-sm">
            <thead>
                     <tr>
                     <th></th>
                     <th scope="col">BAMWire Order #</th>
                     <th scope="col">Reference #</th>
                     <th scope="col">Billing Ref</th>
                     <th scope="col">PO Number</th>
                     <th scope="col">P/U Number</th>
                     <th scope="col">BOL Number</th>
                     <th scope="col">Date Ordered</th>
                     <th scope="col">Shipper</th>
                     <th scope="col">Carrier</th>
                     <th scope="col">Pickup Date</th>
                     </tr>
             </thead>
             <tbody>
               { reportListHtml }
             </tbody>
             </table>
               </div>
            </div> 
                      
         
        </article>
      )
   }
}

export default Report