import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ReactBootstrap from 'react-bootstrap';
import { GoogleMap, Marker, InfoWindow, Polyline } from '@react-google-maps/api';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { toastr } from 'react-redux-toastr';
import { Field, reduxForm } from 'redux-form';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import $ from 'jquery';
import moment from 'moment';
import { trackingListService } from './../../../services/dashboardService';
import { Fragment } from 'react';
import { polyLien1, mapStyle } from './../../../helpers/GoogleUtils';
import { BreadCrumpTrail, BreadCrumpTrailButton } from 'UI-Component';
import "react-widgets/dist/css/react-widgets.css";

class dashboardrenderSelect extends Component {
    render() {
        const { input, data, label, selectlabel, controlClass, meta: { touched, error } } = this.props
        return (
            <BreadCrumpTrail touched={touched}
                error={error}
                label={label}
                selectlabel={selectlabel}
                data={data}
                input={input}
                id={'breadcrumbtruckno'}
                controlClass={controlClass}
                condition={true} />
        )
    }
}

class zoneSelect extends Component {

    render() {
        const { input, data, label, selectlabel, controlClass, id, meta: { touched, error } } = this.props
        let timeZones = [];
        if (data)
            for (const [key, value] of Object.entries(data)) {
                timeZones.push(value);
            }
        return (
            // <div className={`form-group ${(touched && error) ? 'error' : ''}`}>
            //     <select {...input} placeholder={label} id={id} className={`form-control ${(controlClass) ? controlClass : ''}`}>
            //         {
            //             Array.isArray(timeZones) ? timeZones.map(option => {
            //                 return (
            //                     <option
            //                         value={option}
            //                         key={option}>{option}
            //                     </option>)
            //             })
            //                 : ''
            //         }
            //     </select>
            //     {touched && error && <span className='error'>{error}</span>}
            // </div>
            <BreadCrumpTrail touched={touched}
                error={error}
                label={label}
                id={id}
                selectlabel={selectlabel}
                data={timeZones}
                input={input}
                controlClass={controlClass}
                condition={false} />
        )
    }
}


const renderDateTimePicker = ({ input: { onChange, value }, showTime }) =>
    <DateTimePicker dropUp={true} onChange={onChange} format='MM-DD-YYYY'
        time={false} value={!value ? null : new Date(value)} />

const renderTimePicker = ({ input: { onChange, value }, showTime }) =>
    <DateTimePicker dropUp={true} onChange={onChange} //format='HH:mm'
        date={false} value={!value ? null : new Date(value)}
    />

const validate = values => {
    //console.log(values);
    const errors = {}
    if (!values.from_date) {
        errors.truckno = 'From Date can not be left blank.!'
    }
    else if (!values.to_date) {
        errors.truckno = 'To Date can not be left blank.!'
    }
    else if (!values.truckno) {
        errors.truckno = 'Truck/Asset No can not be left blank.!'
    }
    else {
        errors.truckno = ''
    }

    return errors
}

function submit(values) {
    var truckNumvalue = $('#breadcrumbtruckno option:selected').text(); //alert(truckNumvalue);
    this.setState({ ...this.state, truckNo: ' ( ' + truckNumvalue + ' )' });
    const frmdata = {};
    var from_date = (values.from_date) ? (moment(values.from_date).format('YYYY-MM-DD')) : ('');
    var from_time = (values.from_time) ? (moment(values.from_time).format('HH:mm:ss')) : ('00:00:00');
    frmdata.fromDate = (values.from_date) ? (from_date + ' ' + from_time) : ('');
    var to_date = (values.to_date) ? (moment(values.to_date).format('YYYY-MM-DD')) : ('');
    var to_time = (values.to_time) ? (moment(values.to_time).format('HH:mm:ss')) : ('00:00:00');
    frmdata.toDate = (values.to_date) ? (to_date + ' ' + to_time) : ('');
    frmdata.truckNo = (values.truckno) ? (values.truckno) : ('');
    frmdata.exportFlg = this.state.export;
    frmdata.goFlg = this.state.go;

    if (values.fromZone) {
        frmdata.fromDateTimeZone = values.fromZone;
    } else if (this.props.timeZones[1]) {
        frmdata.fromDateTimeZone = this.props.timeZones[1];
    }

    if (values.toZone) {
        frmdata.toDateTimeZone = values.toZone;
    } else if (this.props.timeZones[1]) {
        frmdata.toDateTimeZone = this.props.timeZones[1];
    }
    frmdata.carrierCompanyId = $('#findCarrierId').val();
    console.log(frmdata);
    this.loadMapPoints(frmdata);

}

class GoogleMapBreadcrump extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            export: false,
            go: false,
            showModal: false,
            mapData: [],
            distance: '0.00',
            mapZoom: 12,
            markerPopupShow: false,
            currentMarker: null,
            infwCenter: {
                lat: 41.89101,
                lng: -87.62342
            },
            infwData: {},
            mapCenter: {
                lat: 41.89101,
                lng: -87.62342
            },
            truckRoute: [],
            routeLength: 0,
            routeCount: 0
        }
        this.GOOGLE_MAP_KEY = localStorage.getItem('GOOGLE_MAP_KEY');
        this.map = null;
        this.showHideBreadcrumbInputFlag = true;
        this.Speed = 200;
    }



    breadcrumpSubmit = (type) => {
        if (type === 'Go') {
            this.setState({
                ...this.state,
                export: false,
                go: true,
                markerPopupShow: false,
                goLoader: true,
                exportLoader: false,
                loading: false
            });
        } else {
            this.setState({
                ...this.state,
                export: true,
                go: false,
                markerPopupShow: false,
                exportLoader: true,
                goLoader: false,
                loading: false
            });
        }
    }

    loadMapPoints = (frmData) => {
        this.setState({ loading: true })
        let param = {
            payload: frmData
        }
        trackingListService(param).then((response) => {
            console.log(response);
            if (response && response.Date_List) {
                if (response.Date_List.data) {
                    let Date_List = response.Date_List.data;
                    if (Date_List.length > 0) {
                        if (this.state.export) {
                            this.exportData(Date_List);
                        } else {
                            let trackingData = [...Date_List];
                            let distance = (response.Date_List.distance) ? response.Date_List.distance : 0;
                            distance = parseFloat(distance);
                            distance = isNaN(distance) ? 0 : distance.toFixed(2);
                            distance = distance > 0 ? ('Distance ' + distance + ' Miles') : '';
                            //'Distance ' + response.Date_List.distance + ' Miles' : '';

                            this.setState({
                                ...this.state,
                                showModal: true,
                                mapZoom: 12,
                                mapData: trackingData,
                                distance: distance,
                                routeLength: trackingData.length,
                                routeCount: 1,
                                loading: false,
                                mapCenter: {
                                    lat: parseFloat(trackingData[0].latitude),
                                    lng: parseFloat(trackingData[0].longitude)
                                }
                            });
                            setTimeout(
                                () => this.addStaticRoute(),
                                10
                            );
                        }
                    } else {
                        toastr.info('Info', 'No Data Available!');
                        this.setState({ loading: false, goLoader: false, exportLoader: false })
                        this.close();
                    }
                } else {
                    toastr.info('Info', 'No Data Available!');
                    this.setState({ loading: false, goLoader: false, exportLoader: false })
                    this.close();
                }
            } else {
                toastr.info('Info', 'No Data Available!');
                this.setState({ loading: false, goLoader: false, exportLoader: false })
                this.close();
            }
        });
    }

    addStaticRoute = () => {
        if (this.map) {
            let bounds = this.map.getBounds();
            let mapZoom = this.state.mapZoom;
            let truckRoute = [];
            if (bounds) {
                truckRoute = this.state.mapData.map((data) => {
                    let lat = parseFloat(data.latitude);
                    let lng = parseFloat(data.longitude);
                    bounds = this.map.getBounds().contains({ lat: lat, lng: lng });
                    if (!bounds) {
                        mapZoom--;
                        this.map.setZoom(mapZoom);
                    }
                    return {
                        lat: lat,
                        lng: lng
                    };
                })
                this.setState({
                    ...this.state,
                    truckRoute: truckRoute,
                    mapZoom: mapZoom,
                    routeCount: this.state.routeLength
                });
            } else {
                setTimeout(
                    () => this.addStaticRoute(),
                    500
                );
            }
        } else {
            setTimeout(
                () => this.addStaticRoute(),
                500
            );
        }
    }

    addRoute = () => {

        if (this.map) {
            let bounds = this.map.getBounds();
            if (bounds) {

                if (this.state.routeLength > this.state.routeCount) {
                    let lat = parseFloat(this.state.mapData[this.state.routeCount].latitude);
                    let lng = parseFloat(this.state.mapData[this.state.routeCount].longitude);
                    let mapZoom = this.state.mapZoom;
                    let bounds = this.map.getBounds().contains({ lat: lat, lng: lng });
                    if (!bounds) {
                        mapZoom--;
                    }

                    let truckRoute = [...this.state.truckRoute];
                    truckRoute.push({
                        lat: lat,
                        lng: lng
                    })

                    this.setState({
                        ...this.state,
                        truckRoute: truckRoute,
                        routeCount: this.state.routeCount + 1,
                        mapZoom: mapZoom
                    });

                    setTimeout(
                        () => this.addRoute(),
                        this.Speed
                    );
                }
            } else {
                setTimeout(
                    () => this.addRoute(),
                    this.Speed
                );
            }
        } else {
            setTimeout(
                () => this.addRoute(),
                this.Speed
            );
        }
    }

    exportData = (trackingData) => {
        let csvData = [];
        csvData = trackingData;
        let fileName = 'TrackHistory';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        this.setState({ loading: false, goLoader: false, exportLoader: false })
    }

    close = (e) => {
        this.setState({
            ...this.state,
            showModal: false,
            markerPopupShow: false,
            mapZoom: 12,
            routeLength: 0,
            truckRoute: []
        });
    }

    onClickMarker = (e, data, index) => {
        console.log(index, this.state.currentMarker);
        if (index === this.state.currentMarker) return;
        let speed = parseFloat(data.speed);
        if (isNaN(speed)) {
            speed = '';
        }
        else {
            speed = speed.toFixed(2) + ' mph';
        }
        let infwCenter = {
            lat: parseFloat(data.latitude),
            lng: parseFloat(data.longitude)
        }
        let infwData = {
            truckNo: data.license_Plate_No,
            date: data.convertedDate,
            speed: speed,
            location: data.location
        }
        this.setState({
            ...this.state,
            markerPopupShow: true,
            currentMarker: index,
            infwCenter: infwCenter,
            infwData: infwData
        })
    }

    loadMarker = () => {
        let mapData = [...this.state.mapData];
        if (mapData.length > 1) {
            let pos1 = {
                lat: parseFloat(mapData[0].latitude),
                lng: parseFloat(mapData[0].longitude)
            };
            let pos2 = {
                lat: parseFloat(this.state.mapData[mapData.length - 1].latitude),
                lng: parseFloat(this.state.mapData[mapData.length - 1].longitude)
            };
            return <Fragment>
                <Marker key={1}
                    icon='http://maps.google.com/mapfiles/ms/icons/red.png'
                    position={pos1}
                    onMouseOver={(e) => { this.onClickMarker(e, mapData[0], 0) }}
                />
                <Marker key={2}
                    icon='http://maps.google.com/mapfiles/ms/icons/green.png'
                    position={pos2}
                    onMouseOver={(e) => { this.onClickMarker(e, mapData[mapData.length - 1], 1) }}
                />
            </Fragment>
        } else if (mapData.length > 0) {
            let pos1 = {
                lat: parseFloat(mapData[0].latitude),
                lng: parseFloat(mapData[0].longitude)
            };
            return <Marker key={1}
                icon='http://maps.google.com/mapfiles/ms/icons/red.png'
                position={pos1}
                onMouseOver={(e) => { this.onClickMarker(e, mapData[0], 0) }}
            />
        }
    }

    loadTrackPoints = () => {
        if (this.state.routeLength === this.state.routeCount && this.state.routeLength > 0) {
            let truckPoints = this.state.mapData.map((row, index) => {
                let lat = parseFloat(row.latitude);
                let lng = parseFloat(row.longitude);
                if (index === 0 || (index + 1) === this.state.routeLength) {
                    return <Marker key={index}
                        icon='https://truckercloud.s3.us-east-2.amazonaws.com/images/blue.png'
                        position={{ lat: lat, lng: lng }}
                    />
                } else {
                    return <Marker key={index}
                        icon='https://truckercloud.s3.us-east-2.amazonaws.com/images/blue.png'
                        position={{ lat: lat, lng: lng }}
                        onMouseOver={(e) => { this.onClickMarker(e, row, index) }}
                    />
                }
            });

            return <Fragment>{truckPoints}</Fragment>
        }
    }

    infClose = () => {
        this.setState({
            ...this.state,
            markerPopupShow: false,
            currentMarker: null
        });
    }

    showHideBreadcrumbInput = (e) => {
        e.preventDefault();
        if (this.showHideBreadcrumbInputFlag) {
            $('#breadcrumbpopup a.arrow').removeClass('icofont-rounded-up').addClass('icofont-rounded-down');
            $('#breadcrumbpopup div.show-hide').show();
        } else {
            $('#breadcrumbpopup a.arrow').removeClass('icofont-rounded-down').addClass('icofont-rounded-up');
            $('#breadcrumbpopup div.show-hide').hide();
        }
        this.showHideBreadcrumbInputFlag = !this.showHideBreadcrumbInputFlag;

    }

    render = () => {
        const Modal = ReactBootstrap.Modal;
        const { handleSubmit } = this.props;
        const userType = localStorage.getItem('userType').toLowerCase();
        const label = userType === 'shipper' ? 'Load' : 'Truck/Asset';
        return (
            <div>
                <form className='row' onSubmit={handleSubmit(submit.bind(this))}>
                    <div className='trcuk-breadcrump'>
                        <h4 className='page-title'>Breadcrumb Trail </h4>
                        <div className='row trim-margin'>
                            <div className='col'>
                                <label>From:</label>
                            </div>
                        </div>
                        <div className='row trim-margin'>
                            <div className='col-md-5'>
                                <div className='form-group form-icon input-date'>
                                    <Field
                                        name='from_date'
                                        defaultValue={null}
                                        component={renderDateTimePicker}
                                    />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group form-icon'>
                                    <Field
                                        name='from_time'
                                        defaultValue={null}
                                        component={renderTimePicker}
                                    />
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='form-group form-icon'>
                                    <Field type='text' className='form-control'
                                        name='fromZone' id='fromZone' component={zoneSelect}
                                        data={this.props.timeZones} label={'Zone'} />
                                </div>
                            </div>
                        </div>
                        <div className='row trim-margin'>
                            <div className='col'>
                                <label>To:</label>
                            </div>
                        </div>
                        <div className='row trim-margin'>
                            <div className='col-md-5'>
                                <div className='form-group form-icon input-date'>
                                    <Field
                                        name='to_date'
                                        defaultValue={null}
                                        time={false}
                                        component={renderDateTimePicker}
                                    />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='form-group form-icon'>
                                    <Field
                                        name='to_time'
                                        defaultValue={null}
                                        component={renderTimePicker}
                                    />
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='form-group form-icon'>
                                    <Field type='text' className='form-control'
                                        name='toZone' id='toZone' component={zoneSelect}
                                        data={this.props.timeZones} label={'Zone'} />
                                </div>
                            </div>
                        </div>
                        <div className='row trim-margin'>
                            <div className='col'>
                                <label>{label} No:</label>
                            </div>
                        </div>
                        <div className='row trim-margin'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <Field type='text' className='form-control' name='truckno' id='truckno' component={dashboardrenderSelect} data={this.props.trucks} label={label + ' No'} />
                                </div>
                            </div>
                            <div className='col-md-5'>
                                <div className='form-group'>
                                    {/* <button type='submit' disabled={this.state.loading} onClick={(e) => { this.breadcrumpSubmit('Export') }} className='btn btn-primary btn-sm'>
                                        {this.state.loading && this.state.exportLoader && (
                                            <i
                                                className='fa fa-refresh fa-spin'
                                                style={{ marginRight: '5px' }}
                                            />
                                        )}
                                        {(!this.state.loading || this.state.goLoader) && <span>Export</span>}
                                        {this.state.loading && this.state.exportLoader && <span>Fetching...</span>}
                                    </button>
                                    <button type='submit' disabled={this.state.loading} onClick={(e) => { this.breadcrumpSubmit('Go') }} className={this.state.loading ? 'btn btn-primary btn-sm float-right loaderButton' : 'btn btn-primary btn-sm float-right'}>
                                        {this.state.loading && this.state.goLoader && (
                                            <i
                                                className='fa fa-refresh fa-spin'
                                                style={{ marginRight: '5px' }}
                                            />
                                        )}
                                        {(!this.state.loading || this.state.exportLoader) && <span>Go</span>}
                                        {this.state.loading && this.state.goLoader && <span>Fetching...</span>}
                                    </button> */}
                                    <BreadCrumpTrailButton state={this.state} condition={true} onClick={(e) => { this.breadcrumpSubmit('Export') }} />
                                    <BreadCrumpTrailButton state={this.state} condition={false} onClick={(e) => { this.breadcrumpSubmit('Go') }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal show={this.state.showModal} onHide={this.close} dialogClassName='modal-90w' id='breadcrumbpopup'>
                    <Modal.Header closeButton>
                        <Modal.Title className='w-100' >Truck Details <span className='h6'>{this.state.truckNo}</span> <span className='h6 float-right'>{this.state.distance}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className='row breadcrumbpopup' onSubmit={handleSubmit(submit.bind(this))} id='frmBreadCrumb1'>
                            <div className='trcuk-breadcrump bottom-left bg-white'>
                                <div className='row trim-margin'>
                                    <h4 className='page-title'>Breadcrumb Trail</h4>
                                    <a href='/#' class='icofont-rounded-up icofont-2x arrow' onClick={(e) => { this.showHideBreadcrumbInput(e) }}/>
                                </div>
                                <div className='row trim-margin show-hide'>
                                    <div className='col-md-1'>
                                        <label>From</label>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='form-icon input-date'>
                                            <Field
                                                name='from_date'
                                                defaultValue={null}
                                                component={renderDateTimePicker}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='form-group form-icon'>
                                            <Field
                                                name='from_time'
                                                defaultValue={null}
                                                component={renderTimePicker}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form-group form-icon'>
                                            <Field type='text' className='form-control'
                                                name='fromZone' id='fromZone' component={zoneSelect}
                                                data={this.props.timeZones} label={'Zone'} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row trim-margin show-hide'>
                                    <div className='col-md-1'>
                                        <label>To</label>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='form-group form-icon input-date'>
                                            <Field
                                                name='to_date'
                                                defaultValue={null}
                                                time={false}
                                                component={renderDateTimePicker}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='form-group form-icon'>
                                            <Field
                                                name='to_time'
                                                defaultValue={null}
                                                component={renderTimePicker}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form-group form-icon'>
                                            <Field type='text' className='form-control'
                                                name='toZone' id='toZone' component={zoneSelect}
                                                data={this.props.timeZones} label={'Zone'} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row trim-margin show-hide'>
                                    <div className='col-md-3'>
                                        <label>{label} No</label>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <Field type='text' className='form-control' name='truckno' id='truckno' component={dashboardrenderSelect} data={this.props.trucks} label={label + ' No'} />
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <div className='form-group'>
                                            <button type='submit' disabled={this.state.loading} value={this.state.export} onClick={(e) => { this.breadcrumpSubmit('Export') }} data-name='export' className='btn btn-primary btn-sm'>
                                                {this.state.loading && this.state.exportLoader && (
                                                    <i
                                                        className='fa fa-refresh fa-spin'
                                                        style={{ marginRight: '5px' }}
                                                    />
                                                )}
                                                {(!this.state.loading || this.state.goLoader) && <span>Export</span>}
                                                {this.state.loading && this.state.exportLoader && <span>Fetching...</span>}
                                            </button>
                                            <button type='submit' disabled={this.state.loading} data-name='go' value={this.state.go} id='resizeButton' onClick={(e) => { this.breadcrumpSubmit('Go') }} data-toggle='modal' data-target='.gopopup' className={this.state.loading ? 'btn btn-primary btn-sm float-right loaderButton' : 'btn btn-primary btn-sm float-right'}>
                                                {this.state.loading && this.state.goLoader && (
                                                    <i
                                                        className='fa fa-refresh fa-spin'
                                                        style={{ marginRight: '5px' }}
                                                    />
                                                )}
                                                {(!this.state.loading || this.state.exportLoader) && <span>Go</span>}
                                                {this.state.loading && this.state.goLoader && <span>Fetching...</span>}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {/* <LoadScript
                                    googleMapsApiKey={this.GOOGLE_MAP_KEY}> */}
                        <GoogleMap key={'A1'}
                            mapContainerStyle={mapStyle}
                            zoom={this.state.mapZoom}
                            center={this.state.mapCenter}
                            onLoad={(map) => { this.map = map; $('#frmBreadCrumb1>div.bottom-left').show(); $('#breadcrumbpopup div.show-hide').hide(); }}
                        >
                            {this.loadMarker()}
                            {this.state.markerPopupShow &&
                                <InfoWindow
                                    position={this.state.infwCenter}
                                    onCloseClick={() => { this.infClose() }}
                                ><span>
                                        <p>Truck/Asset No: {this.state.infwData.truckNo}</p>
                                        <p>Date: {this.state.infwData.date}</p>
                                        <p>Speed: {this.state.infwData.speed}</p>
                                        <p>Location: {this.state.infwData.location}</p>
                                    </span>
                                </InfoWindow>
                            }
                            <Polyline
                                path={this.state.truckRoute}
                                options={polyLien1}
                            />
                            {this.loadTrackPoints()}

                        </GoogleMap>
                        {/* </LoadScript> */}
                    </Modal.Body>
                </Modal>
            </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.dashboardReducer.loading,
        trackingData: state.dashboardReducer.export,
        exportFlg: state.dashboardReducer.exportFlg,
        goFlg: state.dashboardReducer.goFlg
    }
}
GoogleMapBreadcrump = connect(
    mapStateToProps
)(GoogleMapBreadcrump);

export default reduxForm({
    form: 'dashboardSearchValidation', // a unique name for this form
    validate
})(GoogleMapBreadcrump);
