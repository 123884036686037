export const validate = values => {
  const errors = {}    
  if (!values.loadNumber) {
    errors.loadNumber = 'Load Number can not be left blank.!'
    } 
    if (!values.weight) {
        errors.weight = 'Weight can not be left blank.!'
    }
    else if(isNaN(Number(values.weight)))
    {
      errors.weight='weight must be a number'
    }
    // if (!values.commodity) {
    //     errors.commodity = 'Commodity can not be left blank.!'
    // }
    // if (!values.quantity) {
    //     errors.quantity = 'Quantity can not be left blank.!'
    // }
      if(!values.rate)
      {
        errors.rate='Rate can not be left blank.!'
      }
      else if(isNaN(Number(values.rate)))
      {
        errors.rate='Rate must be a number'
      }

      if(values.fuelAdvance)
      {
        if(isNaN(Number(values.fuelAdvance)))
        {
          errors.fuelAdvance='fuelAdvance must be a number'
        }
      }
      if(values.fuelAdvanceCommission)
      {
        if(isNaN(Number(values.fuelAdvanceCommission)))
        {
          errors.fuelAdvanceCommission='fuelAdvanceCommission must be a number'
        }
     }

     if(values.estimatedBrokerNet)
     {
        if(isNaN(Number(values.estimatedBrokerNet)))
        {
          errors.estimatedBrokerNet='estimatedBrokerNet must be a number'
        }
     }

     if(values.totalBrokerNet)
     {
        if(isNaN(Number(values.totalBrokerNet)))
        {
          errors.totalBrokerNet='TotalBrokerNet must be a number'
        }
     }

     if(values.quantity)
     {
      if(isNaN(Number(values.quantity)))
      {
        errors.quantity='quantity must be a number'
      }
    }


    // else if (!/^(0|[1-9][0-9]{9})$/i.test(values.rate)) {
    //   errors.rate = 'Make sure that you entered a valid number.!'
    // }
  if (!values.loadDate) {
    errors.loadDate = 'Load Date can not be left blank.!'
  }
  if (!values.members || !values.members.length) {
    errors.members = {}
  } else {
    const membersArrayErrors = []
    values.members.forEach((member, memberIndex) => {
      const memberErrors = {}        
      if (!member || !member.load_point_type_id) {
        memberErrors.load_point_type_id = 'Required'
        membersArrayErrors[memberIndex] = memberErrors
      }
      if (!member || !member.states) {
        memberErrors.states = 'Required'
        membersArrayErrors[memberIndex] = memberErrors
      }
      // if (!member || !member.expiration) {
      //   memberErrors.expiration = 'Required'
      //   membersArrayErrors[memberIndex] = memberErrors
      // }
      // if (!member || !member.issued_by) {
      //   memberErrors.issued_by = 'Required'
      //   membersArrayErrors[memberIndex] = memberErrors
      // }
      // if (!member || !member.issued_date) {
      //   memberErrors.issued_date = 'Required'
      //   membersArrayErrors[memberIndex] = memberErrors
      // }
      // if (!member || !member.others) {
      //   memberErrors.others = 'Required'
      //   membersArrayErrors[memberIndex] = memberErrors
      // }
     /* if (!member || !member.select_file) {
        memberErrors.select_file = 'Required'
        membersArrayErrors[memberIndex] = memberErrors
      }*/
    })
    if (membersArrayErrors.length) {
      errors.members = membersArrayErrors
    }
  }
  return errors
}