import React, {Component} from 'react';
import { Field , reduxForm } from 'redux-form';
import { renderField, renderSelect } from '../../../helpers/FormControls';

class ViewTruck extends Component{
    state = {
        providers : [
            {optName:"Azuga",id:1},
			{optName:"Big Road",id:2},
			{optName:"Blue Ink",id:3}
        ],
        trucktype : [
            {optName:"Semi Truck", id:1}
        ]
    }
    render(){
        this.props.initialize(this.props.location.aboutProps);
        return(
            <form className="tg-forms"> 
                <div className="table-data border">
                    <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">View Truck</a>
                        </li>                                 
                    </ul>
                    <div className="tab-content" id="myTabContent">
                  	    <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">                                      
                          <form className="tg-forms">
							    <div className="row">                 
								    <div className="col col-md-12">									    
									    <article className="table-data truck-data shadow bg-white rounded">
                                        <h5 className="modal-title doc-color" id="exampleModalLabel">Truck Information</h5>
                                        <hr/>
										    <div className="row">
											    <div className="col col-md-4">
                                                    <div className="form-group">             
                                                        <Field component={renderField} type="text"  className="form-control" name="truckno" label="Truck #" />
                                                    </div>
                                                    <div className="form-group">
                                                        <Field component={renderField} type="text"  className="form-control" name="year" label="Year" /> 
                                                    </div>
                                                    <div className="form-group">
                                                    <Field component={renderSelect} type="text" className="form-control" name="assetType" data={this.state.trucktype} label="Truck Type"/> 
                                                    </div>
                                                </div>
                                                <div className="col col-md-4">
                                                    <div className="form-group">
                                                        <Field component={renderField} type="text"  className="form-control" name="make" label="Make" /> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field component={renderField} type="text"  className="form-control" name="vin" label="VIN" />
                                                    </div>
                                                    <div className="form-group">
                                                        <Field component={renderField} type="text"  className="form-control" name="color" label="Color" />                                  
                                                    </div>
                                                </div>
                                                <div className="col col-md-4">
                                                    <div className="form-group">
                                                        <Field component={renderField} type="text"  className="form-control" name="model" label="Model"/> 
                                                    </div>
                                                    <div className="form-group">
                                                        <Field component={renderField} type="text"  className="form-control" name="plateno" label="Plate #" />                                
                                                    </div>
                                                    <div className="form-group">
                                                        <Field component={renderField} type="text"  className="form-control" name="notes" label="Notes" />                                  
                                                    </div>
                                                </div>                           
										    </div>
                                            <h5 className="modal-title doc-color" id="exampleModalLabel">ELD Details</h5>
                                        <hr/>
                                            <div className="row">
											<div className="col col-md-4">
												<div className="form-group">
                                                    <Field component={renderSelect} type="text"  className="form-control" data={this.state.providers} name="provider" label="ELD Provider" />
												</div>
											</div>
											<div className="col col-md-4">
												<div className="form-group">
													<Field component={renderField} type="text" className="form-control" name="serialno" placeholder="Device Serial" label="Device Serial #" />                                 
												</div>
											</div>
										</div> 
                                        <h5 className="modal-title doc-color" id="exampleModalLabel">View Document</h5>
                                        <hr/>
                                            <div class="table-responsive">
                                                <table class="table table-borderless all-table">
                                                    <thead>
                                                    <tr>
                                                        <th className=""></th>
                                                        <th className="">Truck #</th>
                                                        <th className="">Document Title</th>  
                                                        <th className="">Category</th>
                                                        <th className="">Owner</th>
                                                        <th className="">Uploaded Details</th>
                                                        <th className="">Notes</th>
                                                        <th className="">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>                         
							                            <tr>
							                                <td colspan="7" className="text-center"> No Documents found. </td>
							                            </tr>	
                                                    </tbody>
                                                </table>
                                                <hr/>
                                            </div>
									</article>
								</div>
							</div>
                    	</form>
                    </div>                   
                </div>
            </div>   
        </form>
        )
    }
}

export default reduxForm({
    form: 'viewTrucks', 
    enableReinitialize: false,
})(ViewTruck);