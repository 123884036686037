import React, { Component, Fragment } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { history } from './helpers/history';
import PrivateRoutes from './routes/PrivateRoutesOld';
import AppLogin from './components/pages/AppLogin';
import QuickLoad from './components/pages/QuickLoad';
// import CarrierOnboard from './components/pages/CarrierOnboard';
// import EldProviders from './components/pages/EldProviders';
// import ThankYouPage from './components/pages/ThankYouPage';
import { authHeader } from '../src/helpers/authHeader';
import Forgetpwd from './components/pages/Forgetpwd';
import Resetpwd from './components/pages/Resetpwd';
// import CarrierOnBoardNew from './components/pages/CarrierOnBoardNew'
// import AuthorizeCarrier from './components/common/AuthorizeCarrier'
import '../src/public/css/map_popup.css';
import TCWidgetComponent from './components/common/TCWidgetComponent';
import TCWidgetAuthorizeCarrier from './components/common/TCWidgetAuthorizeCarrier';
import Sweepstakes from './components/common/Sweepstakes';
import FAQCarriers from './components/common/FAQCarriers';
import CarrierOnBoardNewcopy from './components/pages/CarrierOnBoardNewcopy';
import AuthorizeCarrierCopy from './components/common/AuthorizeCarrierCopy';
import TermsAndConditionsScreen from './components/common/TermsAndConditionsScreenNew';
import WidgetTermsAndConditionsScreen from './components/common/WidgetTermsAndConditionsScreen';
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import {lightTheme} from './theme/themeOverride'
import carrierOnboardMaterialUI from './components/pages/carrierOnboardMaterialUI';
require('dotenv').config();

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isReady: false
        }
    }
    componentDidMount = () => {
        let app_title = process.env.REACT_APP_TITLE;
        document.title = app_title;
        if (!this.state.isReady) {
            if (history.location.pathname === '/quickload') {
                this.setState({
                    isReady: true
                });
            } else if (history.location.pathname === '/cob') {
                this.setState({
                    isReady: true
                });
            }
            else if (history.location.pathname === '/cobnew') {
                this.setState({
                    isReady: true
                });
            }
            else if (history.location.pathname === '/faq-tc-carriers') {
                this.setState({
                    isReady: true
                });
            }
            else if (history.location.pathname === '/tcwidget') {
                this.setState({
                    isReady: true
                });
            } else if (history.location.pathname === '/sweepstakes') {
                this.setState({
                    isReady: true
                });
            } else if (history.location.pathname === '/forgotpwd') {
                this.setState({
                    isReady: true
                });
            } else if (history.location.pathname === '/resetpassword') {
                this.setState({
                    isReady: true
                });
            }
            else {
                let authToken = localStorage.getItem('authToken');
                if (authToken) {
                    this.checkAuthToken();
                }
                else {
                    if (!(history.location.pathname === '/login' || history.location.pathname === '/' || history.location.pathname === '/quickload' || history.location.pathname === '/cob' || history.location.pathname === '/forgotpwd' || history.location.pathname === '/resetpassword')) {
                        history.push('/login');
                    }
                    this.setState({
                        isReady: true
                    });
                }
            }
        }
    }
    checkAuthToken = () => {
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        const AUTHCHECK_ENDPOINT = BASE_URL + 'commonresource/checkauthtoken';
        fetch(AUTHCHECK_ENDPOINT, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(response => {
                if (response.status) {
                    if (response.status === 'valid') {
                        if (history.location.pathname === '/login' || history.location.pathname === '/') {
                            let userType = localStorage.getItem('userType');
                            history.push('/' + userType + '/dashboard');
                        }
                    }
                    else if (!(history.location.pathname === '/login' || history.location.pathname === '/')) {
                        history.push('/login');
                        localStorage.clear();
                    }
                }
                else if (!(history.location.pathname === '/login' || history.location.pathname === '/')) {
                    history.push('/login');
                    localStorage.clear();
                }
                this.setState({
                    isReady: true
                });
            })
            .catch(error => {
                if (!(history.location.pathname === '/login' || history.location.pathname === '/')) {
                    history.push('/login');
                }
                this.setState({
                    isReady: true
                });
            });
    }
    render() {
        if (this.state.isReady) {
            return (
                <ThemeProvider theme={lightTheme}>
                    <CssBaseline />
                    <Router history={history}>
                        <Switch>
                            <Route exact path='/login' component={AppLogin} />
                            <Route exact path='/quickload' component={QuickLoad} />
                            <Route exact path='/forgotpwd' component={Forgetpwd} />
                            <Route exact path='/resetpassword' component={Resetpwd} />
                            <Route exact path='/cob' component={carrierOnboardMaterialUI} />
                            {/* <Route exact path='/cob' component={CarrierOnBoardNewcopy} /> */}
                            {/* <Route exact path='/cobnew' component={CarrierOnBoardNewcopy} /> */}
                            <Route exact path='/tcwidget' component={TCWidgetComponent} />
                            <Route path={'/terms-and-conditons'} component={TermsAndConditionsScreen} />
                            <Route path={'/terms-and-conditons-widget'} component={WidgetTermsAndConditionsScreen} />
                            <Route path={'/authCarrier'} component={AuthorizeCarrierCopy} />
                            {/* <Route path = {'/authCarriercopy'} component = {AuthorizeCarrierCopy}/> */}
                            <Route path={'/tcWidgetAuthCarrier'} component={TCWidgetAuthorizeCarrier} />
                            <Route path={'/sweepstakes'} component={Sweepstakes} />
                            <Route exact path={'/faq-tc-carriers'} component={FAQCarriers} />
                            {/* <Route exact path='/eld' component={EldProviders} />                                
                                <Route exact path='/thankyou' component={ThankYouPage} /> */}
                            <Route path={'/' + localStorage.getItem('userType')} component={PrivateRoutes} />
                            <Route exact path='' component={AppLogin} />
                        </Switch>
                    </Router>
                </ThemeProvider>
            );
        }
        else {
            return <Fragment></Fragment>
        }
    }
}
export default App;