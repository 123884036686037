import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import { loadService, shipperLoadService, addNewLoad, editLoad, updateLoadService, changeLoadStatusService, loadaccessorialsService, loadServicefrcancelledloads, savepodStatusService, tenderLoadStatusService, updateTenderPopupService, companylistbytypeService, getAssetOrderService, truckNoAndlocationService, driverbycompanyidService, saveAssetOrder, saveQuickDispatch, saveBrokerQuickDispatch, shippertypecompanylistforlanetable, getzipbycityService, getcitybystateService, saveLoadPostOrder, loadBulkUploadService, tenderChangeLoadStatusService, editloadvaluesservice, updateloadvaluesservice, quickdispatchloadlistservice, lanedetailsservice, quickdispatchservice, checkauthtokenservice, checkauthtokenvalidservice, cancelledbyloadservice, notyetdispatchededitdrivertruckservice, onchangetruckService, onchangedriverService, loadServicefrquickloads, quickdispatchintellitransService, brokerTenderService, quickDispatchLoadDeliveryConfirmedService, loadServiceRestoreloads, createDeliveryService, fuelAdvanceService } from '../services/loadService';
import * as types from '../actions';

// export function* dashboardSaga() {
//      try {
//      const response = yield call(dashboardService);
//      yield put({type: types.GET_DASHBOARD_DATA_SUCCESS, response});
//      } 
//      catch(error) {
//      yield put({ type: types.GET_DASHBOARD_DATA_ERROR, error })
//      }
// }

async function fetchAsync(func) {
        const response = await func();

        if (response.ok) {
                return await response.json();
        }

        throw new Error("Unexpected error!!!");
}

function* fetchData(payload) {
        try {
                const response = yield call(loadService, payload);
                yield put({ type: types.GET_LOAD_DATA_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.GET_LOAD_DATA_ERROR, error: e.message });
        }
}

function* fetchShipperLoadData(payload) {
        try {
                const response = yield call(shipperLoadService, payload);
                yield put({ type: types.GET_SHIPPER_LOAD_DATA_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.GET_SHIPPER_LOAD_DATA_ERROR, error: e.message });
        }
}

function* loadlistcancelled(payload) {
        try {
                const response = yield call(loadServicefrcancelledloads, payload);
                yield put({ type: types.GET_LOAD_DATA_CANCEL_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.GET_LOAD_DATA_CANCEL_ERROR, error: e.message });
        }
}

function* loadlistrestored(payload) {
        try {
                const response = yield call(loadServiceRestoreloads, payload);
                yield put({ type: types.GET_LOAD_DATA_RESTORE_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.GET_LOAD_DATA_RESTORE_ERROR, error: e.message });
        }
}


function* loadlistquickdispatch(payload) {
        try {
                const response = yield call(loadServicefrquickloads, payload);
                yield put({ type: types.GET_LOAD_DATA_QUICKDISPATCH_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.GET_LOAD_DATA_QUICKDISPATCH_ERROR, error: e.message });
        }
}



function* newLoadData(payload) {
        try {
                const response = yield call(addNewLoad, payload);
                yield put({ type: types.POST_EDIT_LOAD_DATA_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.POST_EDIT_LOAD_DATA_ERROR, error: e.message });
        }
}
function* editLoadData(payload) {
        try {
                const response = yield call(editLoad, payload);
                yield put({ type: types.POST_NEW_LOAD_DATA_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.POST_NEW_LOAD_DATA_ERROR, error: e.message });
        }
}

export function* loadSaga() {
        // Allows concurrent fetches of users
        yield takeEvery(types.GET_LOAD_DATA, fetchData);
        yield takeEvery(types.GET_SHIPPER_LOAD_DATA, fetchShipperLoadData);
        yield takeEvery(types.GET_LOAD_DATA_CANCEL, loadlistcancelled);
        yield takeEvery(types.GET_LOAD_DATA_RESTORE, loadlistrestored);
        yield takeEvery(types.GET_LOAD_DATA_QUICKDISPATCH, loadlistquickdispatch);
        yield takeLatest(types.POST_NEW_LOAD_DATA, newLoadData);
        yield takeLatest(types.POST_EDIT_LOAD_DATA, editLoadData);

        // Does not allow concurrent fetches of users
        // yield takeLatest(LOAD_USERS_LOADING, fetchUser);
}
//Update load status
function* updateload(payload) {
        try {
                const response = yield call(updateLoadService, payload);
                yield put({ type: types.UPDATE_LOAD_STATUS_SUCCESS, response });
        } catch (error) {
                yield put({ type: types.UPDATE_LOAD_STATUS_ERROR, error })
        }
}
export function* updateLoadStatus() {
        yield takeEvery(types.UPDATE_LOAD_STATUS, updateload);
}

//Change Load Status

function* ChangeloadStatus(payload) {
        try {
                const response = yield call(changeLoadStatusService, payload);
                yield put({ type: types.CHANGE_LOAD_STATUS_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.CHANGE_LOAD_STATUS_ERROR, error })
        }
}


function* savepodloadStatus(payload) {
        try {
                const response = yield call(savepodStatusService, payload);
                yield put({ type: types.SAVE_POD_LOAD_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.SAVE_POD_LOAD_ERROR, error })
        }
}

function* tenderloadStatus(payload) {
        try {
                const response = yield call(tenderLoadStatusService, payload);
                yield put({ type: types.TENDER_LOAD_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.TENDER_LOAD_ERROR, error })
        }
}

function* TenderPopuploadStatus(payload) {
        try {
                const response = yield call(updateTenderPopupService, payload);
                yield put({ type: types.UPDATE_TENDER_LOAD_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.UPDATE_TENDER_LOAD_ERROR, error })
        }
}


function* companylistbyTypeStatus(payload) {
        try {
                const response = yield call(companylistbytypeService, payload);
                yield put({ type: types.COMPANY_LIST_BY_TYPE_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.COMPANY_LIST_BY_TYPE_ERROR, error })
        }
}

function* getAssetOrderStatus(payload) {
        try {
                const response = yield call(getAssetOrderService, payload);
                yield put({ type: types.GET_ASSET_ORDER_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.GET_ASSET_ORDER_ERROR, error })
        }
}

function* gettruckNoandloaction(payload) {
        try {
                const response = yield call(truckNoAndlocationService, payload);
                yield put({ type: types.TRUCK_NO_AND_LOCATION_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.TRUCK_NO_AND_LOCATION_ERROR, error })
        }
}

function* getdriverbycompanyid(payload) {
        try {
                const response = yield call(driverbycompanyidService, payload);
                yield put({ type: types.DRIVER_BY_COMPANY_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.DRIVER_BY_COMPANY_ERROR, error })
        }
}

function* getloadaccessiorals(payload) {
        try {
                const response = yield call(loadaccessorialsService, payload);
                yield put({ type: types.LOAD_ACCESSIORAL_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.LOAD_ACCESSIORAL_ERROR, error })
        }
}


function* saveAssetOrderSaga(payload) {
        try {
                const response = yield call(saveAssetOrder, payload);
                yield put({ type: types.SAVE_ASSET_ORDER_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.SAVE_ASSET_ORDER_ERROR, error })
        }
}


function* quickDispatchAction(payload) {
        try {
                const response = yield call(saveQuickDispatch, payload);
                yield put({ type: types.SAVE_QUICK_DISPATCH_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.SAVE_QUICK_DISPATCH_ERROR, error })
        }
}

function* brokerQuickDispatchAction(payload) {
        try {
                const response = yield call(saveBrokerQuickDispatch, payload);
                yield put({ type: types.SAVE_BROKER_QUICK_DISPATCH_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.SAVE_BROKER_QUICK_DISPATCH_ERROR, error })
        }
}
function* getshipperandlanedtails(payload) {
        try {
                const response = yield call(shippertypecompanylistforlanetable, payload);
                yield put({ type: types.SHIPPER_AND_LANE_DETAILS_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.SHIPPER_AND_LANE_DETAILS_ERROR, error })
        }
}

function* getcityByStateSage(payload) {
        try {
                const response = yield call(getcitybystateService, payload);
                yield put({ type: types.GET_CITY_BY_STATE_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.GET_CITY_BY_STATE_ERROR, error })
        }
}

function* getZipByCitySaga(payload) {
        try {
                const response = yield call(getzipbycityService, payload);
                yield put({ type: types.GET_ZIP_BY_CITY_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.GET_ZIP_BY_CITY_ERROR, error })
        }
}

function* loadPostOrderAction(payload) {
        try {
                const response = yield call(saveLoadPostOrder, payload);
                yield put({ type: types.SAVE_LOAD_POST_ORDER_DISPATCH_SUCCESS, data: response });
        } catch (error) {
                yield put({ type: types.SAVE_LOAD_POST_ORDER_DISPATCH_ERROR, error })
        }
}

function* bulkuploadTruck(payload) {
        try {
                const response = yield call(loadBulkUploadService, payload);
                yield put({ type: types.LOAD_BULK_UPLOAD_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.LOAD_BULK_UPLOAD_ERROR, error: e.message });
        }
}

function* tenderChangeLoadStatus(payload) {
        try {
                const response = yield call(tenderChangeLoadStatusService, payload);
                yield put({ type: types.TENDER_CHANGE_LOAD_STATUS_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.TENDER_CHANGE_LOAD_STATUS_ERROR, error: e.message });
        }
}

function* editloadviewstatus(payload) {
        try {
                const response = yield call(editloadvaluesservice, payload);
                yield put({ type: types.EDIT_LOAD_VALUES_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.EDIT_LOAD_VALUES_ERROR, error: e.message });
        }
}

function* updateloadstatus(payload) {
        try {
                const response = yield call(updateloadvaluesservice, payload);
                yield put({ type: types.UPDATE_LOAD_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.UPDATE_LOAD_ERROR, error: e.message });
        }
}


function* lanedetailsstatus(payload) {
        try {
                const response = yield call(lanedetailsservice, payload);
                yield put({ type: types.LANE_DETAILS_LIST_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.LANE_DETAILS_LIST_ERROR, error: e.message });
        }
}

function* dispatchloaddetails(payload) {
        try {
                const response = yield call(quickdispatchloadlistservice, payload);
                yield put({ type: types.GET_LOAD_DATA_DISPATCH_SUCCESS, data: response, loadlistStatus: response });
        } catch (e) {
                yield put({ type: types.GET_LOAD_DATA_DISPATCH_ERROR, error: e.message });
        }
}

function* dispatchloaddeliverydetails(payload) {
        try {
                const response = yield call(quickDispatchLoadDeliveryConfirmedService, payload);
                yield put({ type: types.GET_LOAD_DATA_DELIVERY_SUCCESS, data: response, loaddeliveryStatus: response });
        } catch (e) {
                yield put({ type: types.GET_LOAD_DATA_DELIVERY_ERROR, error: e.message });
        }
}

function* quickdispatch(payload) {
        try {
                const response = yield call(quickdispatchservice, payload);
                yield put({ type: types.QUICK_DISPATCH_SUCCESS, data: response, quickDispatchStatus: response });
        } catch (e) {
                yield put({ type: types.QUICK_DISPATCH_ERROR, error: e.message });
        }
}

function* checkauthtoken(payload) {
        try {
                const response = yield call(checkauthtokenservice, payload);
                yield put({ type: types.CHK_AUTH_TOKEN_SUCCESS, data: response, authtokenval: response });
        } catch (e) {
                yield put({ type: types.CHK_AUTH_TOKEN_ERROR, error: e.message });
        }
}

function* checkauthtokenvalid(payload) {
        try {
                const response = yield call(checkauthtokenvalidservice, payload);
                yield put({ type: types.CHK_AUTH_TOKEN_VALID_SUCCESS, data: response, authtokenvalidval: response });
        } catch (e) {
                yield put({ type: types.CHK_AUTH_TOKEN_VALID_ERROR, error: e.message });
        }
}

function* cancelledloadsbyloadid(payload) {
        try {
                const response = yield call(cancelledbyloadservice, payload);
                yield put({ type: types.CANCELLED_LOADS_BYLOADID_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.CANCELLED_LOADS_BYLOADID_ERROR, error: e.message });
        }
}

function* notyetdispatchededitdrivertruck(payload) {
        try {
                const response = yield call(notyetdispatchededitdrivertruckservice, payload);
                yield put({ type: types.NOTYETDISPATCHED_EDITDRIVERTRUCK_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.NOTYETDISPATCHED_EDITDRIVERTRUCK_ERROR, error: e.message });
        }
}

function* onchangetruck(payload) {
        try {
                const response = yield call(onchangetruckService, payload);
                yield put({ type: types.ONCHANGE_TRUCK_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.ONCHANGE_TRUCK_ERROR, error: e.message });
        }
}

function* onchangedriver(payload) {
        try {
                const response = yield call(onchangedriverService, payload);
                yield put({ type: types.ONCHANGE_DRIVER_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.ONCHANGE_DRIVER_ERROR, error: e.message });
        }
}

function* quickdispatchintellitrans(payload) {
        try {
                const response = yield call(quickdispatchintellitransService, payload);
                yield put({ type: types.QUICK_DISPATCH_INTELLITRANS_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.QUICK_DISPATCH_INTELLITRANS_ERROR, error: e.message });
        }
}

function* brokerTenderSaga(payload) {
        try {
                const response = yield call(brokerTenderService, payload);
                yield put({ type: types.BROKER_TENDER_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.BROKER_TENDER_ERROR, error: e.message });
        }
}
function* createDelivery(payload) {
        try {
                const response = yield call(createDeliveryService, payload);
                yield put({ type: types.POST_NEW_DELIVERY_DATA_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.POST_NEW_DELIVERY_DATA_ERROR, error: e.message });
        }
}
function* createFuelAdvance(payload) {
        try {
                const response = yield call(fuelAdvanceService, payload);
                yield put({ type: types.POST_NEW_FUEL_ADVANCE_DATA_SUCCESS, data: response });
        } catch (e) {
                yield put({ type: types.POST_NEW_FUEL_ADVANCE_DATA_ERROR, error: e.message });
        }
}
export function* ChangeLoadStatus() {
        yield takeEvery(types.CHANGE_LOAD_STATUS, ChangeloadStatus);
        yield takeEvery(types.SAVE_POD_LOAD, savepodloadStatus);
        yield takeEvery(types.TENDER_LOAD, tenderloadStatus);
        yield takeEvery(types.UPDATE_TENDER_LOAD, TenderPopuploadStatus);
        yield takeEvery(types.COMPANY_LIST_BY_TYPE, companylistbyTypeStatus);
        yield takeEvery(types.GET_ASSET_ORDER, getAssetOrderStatus);
        yield takeEvery(types.TRUCK_NO_AND_LOCATION, gettruckNoandloaction);
        yield takeEvery(types.DRIVER_BY_COMPANY, getdriverbycompanyid);
        yield takeEvery(types.LOAD_ACCESSIORAL, getloadaccessiorals);
        yield takeEvery(types.SAVE_ASSET_ORDER, saveAssetOrderSaga);
        yield takeEvery(types.SAVE_QUICK_DISPATCH, quickDispatchAction);
        yield takeEvery(types.SAVE_BROKER_QUICK_DISPATCH, brokerQuickDispatchAction);
        yield takeEvery(types.SHIPPER_AND_LANE_DETAILS, getshipperandlanedtails);
        yield takeEvery(types.GET_CITY_BY_STATE, getcityByStateSage);
        yield takeEvery(types.GET_ZIP_BY_CITY, getZipByCitySaga);
        yield takeEvery(types.SAVE_LOAD_POST_ORDER_DISPATCH, loadPostOrderAction);
        yield takeEvery(types.LOAD_BULK_UPLOAD, bulkuploadTruck);
        yield takeEvery(types.TENDER_CHANGE_LOAD_STATUS, tenderChangeLoadStatus);
        yield takeEvery(types.EDIT_LOAD_VALUES, editloadviewstatus);
        yield takeEvery(types.UPDATE_LOAD, updateloadstatus);
        yield takeEvery(types.LANE_DETAILS_LIST, lanedetailsstatus);
        yield takeEvery(types.GET_LOAD_DATA_DISPATCH, dispatchloaddetails);
        yield takeEvery(types.GET_LOAD_DATA_DELIVERY, dispatchloaddeliverydetails);
        yield takeEvery(types.QUICK_DISPATCH, quickdispatch);
        yield takeEvery(types.CHK_AUTH_TOKEN, checkauthtoken);
        yield takeEvery(types.CHK_AUTH_TOKEN_VALID, checkauthtokenvalid);
        yield takeEvery(types.CANCELLED_LOADS_BYLOADID, cancelledloadsbyloadid);
        yield takeEvery(types.NOTYETDISPATCHED_EDITDRIVERTRUCK, notyetdispatchededitdrivertruck);
        yield takeEvery(types.ONCHANGE_TRUCK, onchangetruck);
        yield takeEvery(types.ONCHANGE_DRIVER, onchangedriver);
        yield takeEvery(types.QUICK_DISPATCH_INTELLITRANS, quickdispatchintellitrans);
        yield takeEvery(types.BROKER_TENDER, brokerTenderSaga);
        yield takeLatest(types.POST_NEW_DELIVERY_DATA, createDelivery);
        yield takeLatest(types.POST_NEW_FUEL_ADVANCE_DATA, createFuelAdvance);
}