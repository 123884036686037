import React, { Component } from 'react';
import Icofont from 'react-icofont';
import { connect } from 'react-redux';
import { driverdeleteaction, driverlistAction, driverELDlistAction } from '../../../actions/userActions';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from 'moment';
import { getcitybystate, getzipbycity } from '../../../actions/loadActions';
import ViewDriverPopup from './ViewDriverPopup';
import $ from 'jquery'
import { TableDataComponent,GetEld } from 'UI-Component';

class DriverList extends Component {
	constructor(props) {
		super(props);
		this.totalItemsCount = 1;
		this.activePage = 1;
		this.itemsCountPerPage = 10;
		this.pageRangeDisplayed = 5;
		this.editData = {};
	}
	state = {
		modaldata: [],
		CityValue: [],
		newDriverPopup: false,
		userData: null,
		title: ''
	}
	componentDidMount = () => {
		let params = {
			eldProviderId: 0
		}
		this.props.dispatch(driverlistAction(params));
		this.props.dispatch(driverELDlistAction());
	}
	handleEdit = (arg) => {
		if (arg.currentStateId !== null) {
			this.props.dispatch(getcitybystate(arg.currentStateId));
		}

		if (arg.currentCity !== null) {
			this.props.dispatch(getzipbycity(arg.currentCity));
		}
		var licenseexp = (arg.drivingLicenseExpDate) ? (moment(arg.drivingLicenseExpDate).format('YYYY-MM-DD')) : ('');
		var physicalExpDate = (arg.physicalExpDate) ? (moment(arg.physicalExpDate).format('YYYY-MM-DD')) : ('');
		var hiredate = (arg.hireDate) ? (moment(arg.hireDate).format('YYYY-MM-DD')) : ('');
		let modaldata = {
			firstname: arg.firstName,
			driverid: arg.driverId,
			dob: arg.dob,
			licenseexp: licenseexp,
			physicalexpdate: physicalExpDate,
			email: arg.emailId,
			mobilenumber: arg.phoneNumber,
			lastname: arg.lastName,
			rowType: arg.rowType,
			state: arg.currentStateId,
			hiredate: hiredate,
			licensenumber: arg.drivingLicenseNo,
			drivertype: arg.driverTypeId,
			userTypeName: arg.userTypeName,
			zip: arg.permanentZipValue,
			city: arg.permanentCityName,
			emptyrate: arg.emptyRate,
			driverpay: arg.driverPay,
			licensestate: arg.drivingLicenseStateCode,
			address: arg.permenantAddress1,
			currentZipId: arg.currentZipId,
			currentCityid: arg.currentCity,
			userId: arg.userId
		}
		this.setState({ showModal: true, modaldata: modaldata });
	}

	deletedriver = (driverid) => {
		confirmAlert({
			title: 'Are you sure to delete the driver ?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => this.props.dispatch(driverdeleteaction(driverid))
				},
				{
					label: 'No'
				}
			]
		});
	};
	eldSelect(ELD_Provider_Id) {
		let id = $('#findELDId').val();
		let params = {
			eldProviderId: id
		}
		this.props.dispatch(driverlistAction(params));

	}
	getEldProviderDropdown = () => {
		return <GetEld id='findELDId' state={this.state} selectELD={this.props?.eldlist}  onChange={(e) => { this.eldSelect(e) }} />
	}
	viewDriver = (rowdata) => {
		this.setState(
			{
				...this.state,
				newDriverPopup: true,
				userData: rowdata,
				title: 'Driver Info'
			}
		);
	}
	closeNewUser = (flag) => {
		this.setState(
			{
				...this.state,
				newDriverPopup: false,
				userData: null,
				title: '',
			}
		);
	}
	render() {
		let drivers = this.props.data.Drivers;
		var products = [];
		const columns = [
			{
				dataField: 'driverid',
				text: 'TC Driver ID',
				sort: true
			},
			{
				dataField: 'firstName',
				text: 'Name ',
				sort: true
			},
			{
				dataField: 'drivingLicenseNo',
				text: 'License',
				sort: true
			},
			{
				dataField: 'drivingLicenseExpDate',
				text: 'License Exp. Date',
				sort: true
			},
			{
				dataField: 'currentStateId',
				text: 'State',
				sort: true
			},
			{
				dataField: 'emailid',
				text: 'Email',
				sort: true
			},
			{
				dataField: 'phoneno',
				text: 'Phone',
				sort: true
			},
			{
				dataField: 'address',
				text: 'Address',
				sort: true
			},
			{
				dataField: 'company',
				text: 'Company',
				sort: true
			},
			{
				dataField: 'eld',
				text: 'Eld',
				sort: true
			},
			{
				dataField: 'More',
				text: 'More'
			}]
		const { loading } = this.props;
		if (loading) {
			var indicationhtml =
				<div className='spinner-border' role='status'>
					<div className='row react-bs-table-no-data'></div>
				</div>
		} else
			if (drivers && drivers.length > 0) {
				products = [];
				products = drivers.map(
					(row) => {
						var dob = '';
						var drivingLicenseExpDate = '';
						var physicalExpDate = '';
						var hireDate = '';
						if (row.dob != null) {
							dob = moment(row.dob).format('MM-DD-YYYY');
						}
						else {
							dob = '';
						}
						if (row.drivingLicenseExpDate != null) {
							drivingLicenseExpDate = moment(row.drivingLicenseExpDate).format('MM-DD-YYYY');
						}
						else {
							drivingLicenseExpDate = '';
						}
						if (row.physicalExpDate != null) {
							physicalExpDate = moment(row.physicalExpDate).format('MM-DD-YYYY');
						}
						else {
							physicalExpDate = '';
						}
						if (row.hireDate != null) {
							hireDate = moment(row.hireDate).format('MM-DD-YYYY');
						}
						else {
							hireDate = '';
						}
						return ({
							id: row.userId,
							firstName: row.First_Name,
							emailid: row.Email_Id,
							phoneno: row.Phone_No,
							address: row.Address1,
							driverid: row.DriverId,
							currentStateId: row.State,
							drivingLicenseExpDate: row.License_Expiry,
							drivingLicenseNo: row.License_No,
							physicalExpDate: row.physicalExpDate,
							eld: row.EldProviderDisplayName,
							More: <i onClick={(e) => { this.viewDriver(row) }} className='icofont-eye-alt'></i>
						});
					}
				);
			}
			else {
				products = [];
				indicationhtml = 'No Data Found';
			}
		return (
			<article className='table-data shipperlist-data shadow bg-white rounded'>
				<div className='row no-gutters'>
					<div className='col-md-12'>
						<ul className='nav nav-tabs' id='myTrucks' role='tablist'>
							<li className='nav-item'>
								<a className='nav-link active' id='mydrivers-tab' data-toggle='tab' href='#mydrivers' role='tab' aria-controls='mydrivers' aria-selected='false'><Icofont icon='user-male' />My Drivers</a>
							</li>
						</ul>
						{this.getEldProviderDropdown()}
						<div className='tab-content' id='myTabContent'>
							<div className='tab-pane fade show active' id='mydrivers' role='tabpanel' aria-labelledby='mydrivers-tab'>
								<TableDataComponent tableData={products} columnData={columns} noDataIndication={indicationhtml} />
							</div>
							<ViewDriverPopup newDriverPopup={this.state.newDriverPopup} closeNewUser={(flag) => { this.closeNewUser(flag) }} userData={this.state.userData} title={this.state.title} />
						</div>
					</div>
				</div>
			</article>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		loading: state.userReducer.loading,
		user: state.userReducer.user,
		data: state.userReducer.data,
		eldlist: state.userReducer.eldlist,
		CityVal: state.loadReducer.CityVal,
		ZipVal: state.loadReducer.ZipVal
	}
}
export default connect(mapStateToProps)(DriverList)