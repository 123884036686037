import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-bootstrap/Modal';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { shipperLoadAction } from '../../../actions/loadActions';
import { STATUS } from '../../../helpers/status';
import { connect } from 'react-redux';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {latsTrackingByAssetIdService, getDispatchTrackingByLoadIdService} from '../../../services/assetService';
import {formatLocation, getMapboxKey} from '../../../helpers/appUtil';
import  BreadCrumpLive  from './BreadCrumpLive';

import DispatchLiveMap from './dispatchLiveMap';

class Dispatched extends Component{
    constructor(props)  {
        super(props);
        this.state={
            showMap:false,
            customerLoadNumber:'',
            showLiveMap:false,
            pickupPoint:null,
            dropPoint:null,
            trackingData:null,
            loadInfo:null,
            trackingList:[]
        }
        this.SUBSCRIPTION_PLAN_ID=localStorage.getItem('SUBSCRIPTION_PLAN_ID');
    }
    componentDidMount() {
        document.title="Dispatched";
        const params={
            ...STATUS.LOAD.CONFIRMED,
        }
        this.props.dispatch(shipperLoadAction(params));
        mapboxgl.accessToken = getMapboxKey();
        //mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    }

    openLiveMap = (row) => {
        //console.log(row);
        let customerLoadNumber = row.customerLoadNumber;
            if(row.assetId){
                let param = {
                    loadId:row.loadId
                }
                getDispatchTrackingByLoadIdService(param).then((response) => {
                    if(response.sourcePoint === null) {
                        toastr.info('Info', 'No Data Available!');
                    }
                    else{
                        this.setState({
                            ...this.state,
                            showMap:true,
                            trackingList:response.loadVehicleTrackingList,
                            customerLoadNumber:customerLoadNumber
                        });
                        //this.showLiveMap(response.loadVehicleTrackingList, response.loadInfo, response.sourcePoint, response.destinationPoint)
                    }
                });
                //this.showLiveMap(null,null,null,null);
            }
            else{
                toastr.info('Info', 'Vehicle not assigned');
                return;
            }
        
    }

    showLiveMap (loadVehicleTrackingList, loadInfo, sourcePoint, destinationPoint) {        
        this.setState(
            {
                ...this.state,
                showLiveMap:true,
                pickupPoint:sourcePoint,
                dropPoint:destinationPoint,
                trackingData:loadVehicleTrackingList,
                loadInfo:loadInfo
            }
        );        
    }

    openMap = (row) => {
		//latsTrackingByAssetIdService assetId
		console.log(row);
        let customerLoadNumber = row.customerLoadNumber;
		if(row.assetId){
			let param = {
				assetId:row.assetId
			}
			latsTrackingByAssetIdService(param).then((response) => {				
				if(response.TrackingData){
					row={
						Latitude:response.TrackingData.Latitude,
						Longitude:response.TrackingData.Longitude,
						Asset_Id:1,
						Asset_Number:(row.assetName?row.assetName:''),
						Location:(response.TrackingData.Location?response.TrackingData.Location:''),
						Speed:(response.TrackingData.Speed?response.TrackingData.Speed:''),
                        UTC_Date_Time:(response.TrackingData.UTC_Date_Time?response.TrackingData.UTC_Date_Time:''),
                        truckstatus:response.TrackingData.truckstatus
					}
					var Latitude = parseFloat(row.Latitude);
					var Longitude = parseFloat(row.Longitude);
			
			
					if(isNaN(Latitude) || isNaN(Longitude))
					{
						toastr.info('Info', 'Location not valid!');
						return;
					}
					// else if(Latitude>90||Longitude<-90)
					// {
					// 	toastr.error('Error', 'Invalid Latitude Longitude');
					// 	return;
                    // }
                    console.log(customerLoadNumber);
					this.setState({
						...this.state,
                        showMap:true,
                        customerLoadNumber:customerLoadNumber
					});
			
					setTimeout(() => this.loadMap(row), 1000);
				}
				else{
					toastr.info('Info', 'Tracking data not found');
					return;
				}
			});
		}
		else{
			toastr.info('Info', 'Vehicle not assigned');
			return;
		}
	}

    loadMap = (row) => {

        var el = document.querySelector("#map-canvas");
        if(el === null)
        {
            setTimeout(() => this.loadMap(row), 1000);
            return;
        }
        
        this.showRouteMap(row.Asset_Id.toString(),row.Asset_Number.toString(),row.Location.toString(),row.Latitude.toString(),row.Longitude.toString(),row.Speed.toString(), row.UTC_Date_Time.toString(), row.truckstatus);
	}

	showRouteMap(id,truckNo,location,Latitude,longitude,Speed,utctime, truckstatus){
		var el = document.querySelector("#map-canvas");

		if(el == null)
		{
			setTimeout(
				function() {
					this.showRouteMap(id,truckNo,location,Latitude,longitude,Speed,utctime, truckstatus);
				}
				.bind(this),
				1000
			);

			return;
		}

		if(el.clientHeight < 1)
		{
            var body = document.body,
            html = document.documentElement;
            var height = Math.min( body.scrollHeight, body.offsetHeight, 
            html.clientHeight, html.scrollHeight, html.offsetHeight );
            height -= 150;
            el.style.height = height+"px";
        }
        this.setState({
            ...this.state,
            mapData:[
                { 
                    "id":id,
                    "truckNo":truckNo,
                    "latitude":Latitude,
                    "longitude":longitude,
                    "speed":Speed,
                    "address":location,
                    "location":location,
                    "date":utctime,
                    "truckstatus":truckstatus,
                }
            ]
        });
       
       // if(this.props.data.length>0){
            var features = this.state.mapData.map(data => {
                return(
                {
                    "type":"Feature",
                    "properties":{
                        "id":data.id,                        
                        "latitude":data.latitude,
                        "longitude":data.longitude,
                        "speed":data.speed,                        
                        "location":data.location,
                        "date":data.date,
                        "truckNo":data.truckNo,
                    },
                    "geometry":{
                        "type":"Point",
                        "coordinates":[
                            data.longitude,                            
                            data.latitude
                            
                        ]
                    }
                 }
                )
			}) 
		
		let Lat = 45;
        let Lon = -90;        
		if(this.state.mapData.length>0){
			Lat = parseFloat(this.state.mapData[0].latitude);
            Lon = parseFloat(this.state.mapData[0].longitude);
		}			
			
		if(isNaN(Lat) || isNaN(Lon)){
			Lat = 45;
			Lon = -90;
		}

		const map = new mapboxgl.Map({
			container : 'map-canvas', // container id
			style : 'mapbox://styles/mapbox/streets-v11', //stylesheet location
            center: [Lon, Lat], // starting position
            zoom: 13 // st arting zoom
        });
		
		console.log(this.state.mapData);
		console.log(Lon,Lat);

        var geojsonData = {
            "type":"FeatureCollection",
            "features": features
        }
		
        map.on('load', function() {
            // Add a new source from our GeoJSON data and set the
            // 'cluster' option to true.
            map.addSource("earthquakes", {
                type: "geojson",
                // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
                // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
                data: geojsonData,
                cluster: true,
                clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterRadius: 40 // Radius of each cluster when clustering points (defaults to 50)
               
            });    
            map.loadImage('https://maps.google.com/mapfiles/ms/icons/red.png', function(error, image) {
                if (error) throw error;
                map.addImage('redIcon', image);
            });
            
            map.loadImage('https://maps.google.com/mapfiles/ms/icons/green.png', function(error, image) {
                if (error) throw error;
                map.addImage('greenIcon', image);
            });

            map.loadImage('https://maps.google.com/mapfiles/ms/icons/yellow.png', function(error, image) {
                if (error) throw error;
                map.addImage('yellowIcon', image);
            });

            let icon = (truckstatus===3 ? 'greenIcon' : (truckstatus===2 ? 'yellowIcon' : 'redIcon'));

            map.addLayer({
                "id": "unclustered-points",
                "type": "symbol",
               "source": "earthquakes",
                "layout": {
                        "icon-image": icon,
                        "icon-size": 1
                },
                "properties":{
                    iconAllowOverlap :false
                }
            });
            // Display the earthquake data in three layers, each filtered to a range of
            // count values. Each range gets a different fill color.
            var layers = [
                [20, '#f28cb1'],
                [10, '#f1f075'],
                [0, '#51bbd6']
            ];    
            layers.forEach(function (layer, i) {
                map.addLayer({
                    "id": "cluster-" + i,
                    "type": "circle",
                    "source": "earthquakes",
                    "paint": {
                        "circle-color": layer[1],
                        "circle-radius": 18
                    },
                    "filter": i === 0 ?
                        [">=", "point_count", layer[0]] :
                        ["all",
                            [">=", "point_count", layer[0]],
                            ["<", "point_count", layers[i - 1][0]]]
                });
            });   
    // Add a layer for the clusters' count labels
            map.addLayer({
                "id": "cluster-count",
                "type": "symbol",
                "source": "earthquakes",
                "layout": {
                    "text-field": "{point_count}",
                    "text-font": [
                        "DIN Offc Pro Medium",
                        "Arial Unicode MS Bold"
                    ],
                    "text-size": 12
                },
                'paint': {
                    'text-color': 'black'
                }
            });
        });
		
		var popup = new mapboxgl.Popup({
			closeButton: false,
			closeOnClick: false
			});
			 
			map.on('mouseenter', 'unclustered-points', function(e) {
				// Change the cursor style as a UI indicator.
				map.getCanvas().style.cursor = 'pointer';
				var coordinates = e.features[0].geometry.coordinates.slice();
                var description = e.features[0].properties;  
				// Ensure that if the map is zoomed out such that multiple
				// copies of the feature are visible, the popup appears
				// over the copy being pointed to.
				while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
					coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
				}         
				// Populate the popup and set it's coordinates
                // based on the feature found.
                var popupDate="";
                if(description.date!=null)
                {
                    popupDate=moment(description.date).format('MM-DD-YYYY T: h:mm a');
                }
                else
                {
                    popupDate= "";
                }
                let speed = parseFloat(description.speed);
				if(isNaN(speed))
				{
					speed ='';
				}
				else{
					speed = speed.toFixed(2) + " mph";
				}
				console.log(speed);

				let location = formatLocation(description.location);
                popup
				.setLngLat(coordinates) 
				.setHTML('<span class="map-popup-label">Truck No. : </span><span class="map-popup-label">'+description.truckNo+'</span></br><span class="map-popup-label1"> Date : </span><span class="map-popup-label">'+popupDate+'</span></br><span class="map-popup-label1">Speed : </span><span class="map-popup-label">'+speed+'</span></br><span class="map-popup-label2">Location : </span><span class="map-popup-label">'+location+'</span></br>')
				.addTo(map);
			});         
			map.on('mouseleave', 'unclustered-points', function() {
				map.getCanvas().style.cursor = '';
				popup.remove();
			});

       
			map.on('mouseleave', 'unclustered-points1', function() {
				map.getCanvas().style.cursor = '';
				popup.remove();
			});

	}
	closeMap = () => {                
        this.setState({
            ...this.state,
            showMap:false
        });
    }

    close = (flag) => {
        if(flag) {
            this.setState({
                ...this.state,
                showMap:false
            }); 
        }
    }

    closeLiveMap = () => {
        this.setState({
            ...this.state,
            showLiveMap:false,
            pickupPoint:null
        });
    }
    showBreadcrump = () => {
		
			return <BreadCrumpLive trackingList={this.state.trackingList} closeMap={(flag) => this.close(flag)} />
        

		//return userType == "shipper"?(<Fragment></Fragment>):<DashboardBreadcrump trucks={this.state.TrucksNum} timeZones={this.props.timeZones} />
	}
    render(){        
        var dispatched = [];
        var indicationhtml="No Data Found";
        if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}
        if(this.props.loading){
			indicationhtml = 
			<div className="spinner-border" role="status">
			 <div className="row react-bs-table-no-data"></div>
			 </div>
		}else
        {
        if(this.props.data.LoadList !== undefined)
        {
            this.dispatched  = this.props.data.LoadList;
            if(this.dispatched.length > 0)
            {
                dispatched = []
                dispatched = this.dispatched.map(
                    row =>{
                        var drivername='';
                        if(row.driverName!==null)
                        {
                            drivername = row.driverName.toUpperCase()
                        }else
                        {
                            drivername = '-'
                        }
                        var stringLoadDate="";
                        if(row.stringLoadDate!=="")
                        {
                            stringLoadDate=moment(row.stringLoadDate).format('MM-DD-YYYY');
                        }
                        else
                        {
                            stringLoadDate= "";
                        }

                        
                        // var pickUpdate = "";
                        // var pickUpTime = "";
                        // if(row.loadPointsList.length>0)
                        // {
                        //     if(row.loadPointsList.length==1)
                        //     {
                        //         if(row.loadPointsList[0].loadPointTypeId==2)
                        //         {
                        //             if(row.loadPointsList[0].earliestTime!="")
                        //             {
                        //                 var pickUpdate = moment(row.loadPointsList[0].earliestTime).format('MM-DD-YYYY HH:mm');
                        //                 var pickUpTime = moment(row.loadPointsList[0].earliestTime).format('HH:mm');
                        //             }
                        //         }
                        //     }
                        //     else if(row.loadPointsList.length==2)
                        //     {
                        //         if(row.loadPointsList[0].loadPointTypeId==2)
                        //         {
                        //             if(row.loadPointsList[0].earliestTime!="")
                        //             {
                        //                 var pickUpdate = moment(row.loadPointsList[0].earliestTime).format('MM-DD-YYYY HH:mm');
                        //                 var pickUpTime = moment(row.loadPointsList[0].earliestTime).format('HH:mm');
                        //             }
                        //         }
                        //         else if(row.loadPointsList[1].loadPointTypeId==2)
                        //         {
                        //             if(row.loadPointsList[1].earliestTime!="")
                        //             {
                        //                 var pickUpdate = moment(row.loadPointsList[1].earliestTime).format('MM-DD-YYYY HH:mm');
                        //                 var pickUpTime = moment(row.loadPointsList[1].earliestTime).format('HH:mm');
                        //             }
                        //         }
                        //     }
                        // }

                        // var deliverydate = "";
                        // var deliveryTime = "";
                        // if(row.loadPointsList.length>0)
                        // {
                        //     if(row.loadPointsList.length==1)
                        //     {
                        //         if(row.loadPointsList[0].loadPointTypeId==3)
                        //         {
                        //             if(row.loadPointsList[0].latestTime!="")
                        //             {
                        //                 var deliverydate = moment(row.loadPointsList[0].latestTime).format('MM-DD-YYYY HH:mm');
                        //                 var deliveryTime = moment(row.loadPointsList[0].latestTime).format('HH:mm');
                        //             }
                        //         }
                        //     }
                        //     else if(row.loadPointsList.length==2)
                        //     {
                        //         if(row.loadPointsList[0].loadPointTypeId==3)
                        //         {
                        //             if(row.loadPointsList[0].latestTime!="")
                        //             {
                        //                 var deliverydate = moment(row.loadPointsList[0].latestTime).format('MM-DD-YYYY HH:mm');
                        //                 var deliveryTime = moment(row.loadPointsList[0].latestTime).format('HH:mm');
                        //             }
                        //         }
                        //         else if(row.loadPointsList[1].loadPointTypeId==3)
                        //         {
                        //             if(row.loadPointsList[1].latestTime!="")
                        //             {
                        //                 var deliverydate = moment(row.loadPointsList[1].latestTime).format('MM-DD-YYYY HH:mm');
                        //                 var deliveryTime = moment(row.loadPointsList[1].latestTime).format('HH:mm');
                        //             }
                        //         }
                        //     }
                        // }

                        var pickUpdate = "";
                        if(row.loadPointsList.length>0)
                        {                        
                            if(row.loadPointsList[0].loadPointTypeId===2)
                            {
                                if(row.loadPointsList[0].earliestTime!=="")
                                {
                                     pickUpdate = moment(row.loadPointsList[0].earliestTime).format('MM-DD-YYYY HH:mm');
                                }
                            }
                        }

                        var deliverydate = "";
                        if(row.loadPointsList.length>0)
                        {
                            if(row.loadPointsList[row.loadPointsList.length-1].loadPointTypeId===3)
                            {
                                if(row.loadPointsList[row.loadPointsList.length-1].latestTime!=="")
                                {
                                     deliverydate = moment(row.loadPointsList[row.loadPointsList.length-1].latestTime).format('MM-DD-YYYY HH:mm');
                                }
                            }
                        }

                        var pickUpLocation = "";
                        if(row.orginCityName!==null && row.orginStateCode!==null)
                        {
                             pickUpLocation = row.orginCityName+', '+row.orginStateCode
                        }
                        else if(row.orginCityName!==null)
                        {
                             pickUpLocation = row.orginCityName
                        }else if(row.orginStateCode!==null)
                        {
                             pickUpLocation =  row.orginStateCode
                        }


                        var DeliveryLocation = "";
                        if(row.destinationCityName!==null && row.destinationStateCode!==null)
                        {
                             DeliveryLocation = row.destinationCityName+', '+row.destinationStateCode
                        }
                        else if(row.destinationCityName!==null)
                        {
                             DeliveryLocation = row.destinationCityName
                        }else if(row.destinationStateCode!==null)
                        {
                             DeliveryLocation =  row.destinationStateCode
                        }

                        if(row.brokerName==="FreightCo Brokerage")
                        {
                            var brokerName = "Freightco"
                        }else{
                             brokerName = row.brokerName
                        }
                        return({
                            id:row.loadId,
                            loadNumber:row.customerLoadNumber,
                            loadNumberLink:<a href="/#" onClick={ (e) => this.openMap(row)} >{row.customerLoadNumber}</a>,
                            stringLoadDate:stringLoadDate,
                            pickUpdate:pickUpdate,
                            shipperName:row.shipperName,
                            customerName:row.customerName,
                            carrierName:row.carrierName,
                            brokerName:"Freightco",
                            consigneeName:row.consigneeName,
                            consigneeAddress:((row.consigneeAddress1===null?'':row.consigneeAddress1) + " " + (row.consigneeAddress2===null?'':row.consigneeAddress2)),
                            weight:row.weight,
                            originCityName:row.orginCityName,
                            destinationCityName:row.destinationCityName,
                            commodity:row.commodity,
                            equipmentType:row.truckTypeName,
                            action:<a href="/#" className="btn_carrier_move btn btn-sm btn-primary">Status</a>,
                            loadPointsList:row.loadPointsList,
                            basecharge:row.baseCharges,
                            fulecharge:row.fuelCharges,
                            accessiorialcharge:row.accessorialCharges,
                            totalrevenue:row.totalRevenue,
                            loadedmiles:row.loadedMiles,
                            emptymiles:row.emptyMiles,
                            totalmiles:row.totalMiles,
                            driverpay:row.driverPay,
                            tenderprice:row.shipperLoadPrice,
                            originState:row.orginStateName,
                            destinationState:row.destinationStateName,
                            shipperref:row.shipperReferenceNumber,
                            assetName:row.assetName,
                            driverName:drivername,
                            distance:row.distance,
                            location:row.assetLocation,
                            pickUpLocation:pickUpLocation,
                            DeliveryLocation:DeliveryLocation,
                            deliverydate:deliverydate,
                            status:row.truckStatus,
                            icon:<a href="/#" onClick={(e) => {this.openLiveMap(row)}}><i id="mapiconClass"  className="icofont-map-pins"></i></a>
                        });
                    }
                );
            }
            else{
                dispatched = [];
                indicationhtml="No Data Found";
            }
        }
       }  
        const { SearchBar } = Search;
        const columns=[
            {
				text:'Customer Load #',
				dataField:'loadNumberLink',
				sort:true,
			},	
			{
				text:'Carrier',
				sort:true,
				dataField:'brokerName'
			},  		
			{
				text:'Pickup Date / Time',
				sort:true,
				dataField:'pickUpdate'
			},
			// {
			// 	text:'Time',
			// 	sort:true,
			// 	dataField:'pickUpTime'
			// },
			{
				text:'Pickup Location',
				dataField:'pickUpLocation',
				sort:true
			},	
			{
				text:'Delivery Date / Time',
				sort:true,
				dataField:'deliverydate'
			},
			// {
			// 	text:'Time',
			// 	sort:true,
			// 	dataField:'deliveryTime'
			// },		
			{
				text:'Delivery Location',
				dataField:'DeliveryLocation',
				sort:true
			},
			{
				text:'Current Location',
				dataField:'location',
				sort:true
			}, 
			{
				text:'ETA',
				dataField:'eta',
				sort:true
			} ,
			{
				text:'Status',
				dataField:'status',
				sort:true
			},
            {
				text:'Live',
                dataField:'icon'
            }
         ];
	
      const customTotal = (from, to, size) => (
       <span className="react-bootstrap-table-pagination-total">
         Showing { from } to { to } of { size } Results
       </span>
     );
     
     const options = {
       paginationSize: 4,
       pageStartIndex: 1,      
       firstPageText: 'First',
       prePageText: 'Back',
       nextPageText: 'Next',
       lastPageText: 'Last',
       nextPageTitle: 'First page',
       prePageTitle: 'Pre page',
       firstPageTitle: 'Next page',
       lastPageTitle: 'Last page',
       showTotal: true,
       paginationTotalRenderer: customTotal,
     };
        return(
                <div className="table-data border">
                   {/* <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">DISPATCHED LOADS</a>
                        </li>                                 
                    </ul> */}
                    <div className="tab-content" id="myTabContent">
                  	    <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">                                      
                       
							<div className="row">                 
								<div className="col col-md-12">
									<article className="table-data truck-data shadow bg-white rounded">
									 <ToolkitProvider
										keyField="id"
										data={ dispatched }
										columns={ columns }
										search
									 >
										{
										props => (
											<div>
                                            <div className="SearchDispatched"><SearchBar { ...props.searchProps } /></div>
											<BootstrapTable
												{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={indicationhtml}  hover bordered={ false }
											/>
											</div>
										)
										}
							    	</ToolkitProvider>
									</article>
                                    <Modal show={this.state.showMap} onHide={this.closeMap} dialogClassName="modal-90w modal-map">
                                        <Modal.Header closeButton>
                                            <Modal.Title className="w-100">Vehicle Location <span className="h6 float-right pt-1">Customer Load # : {this.state.customerLoadNumber}</span></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body >
                                            {this.showBreadcrump()}
                                        </Modal.Body>								
                                    </Modal>
                                    <DispatchLiveMap 
                                        pickupPoint={this.state.pickupPoint} 
                                        dropPoint={this.state.dropPoint} 
                                        trackingData={this.state.trackingData} 
                                        loadInfo={this.state.loadInfo} 
                                        showLiveMap={this.state.showLiveMap}
                                        closeLiveMap={this.closeLiveMap} />
								</div>
							</div>
                    </div>                   
                </div>
            </div>  
        )
    }
}  

const mapStateToProps = state => {
	return {
		data: state.loadReducer.data,
	    loading: state.loadReducer.loading,
		error: state.loadReducer.error,
	}
}
export default connect(mapStateToProps)(Dispatched);