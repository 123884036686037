import React, { Component } from 'react';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { getOnboardedCarrierService } from '../../../services/companyService';
import { assetListServiceForTechService } from '../../../services/assetService';
import {  Container ,TableComponent} from '../../../muiComponents'
import HeaderBar from '../../../muiComponents/header';
// import styles from '../../common/IndvidualCss/paymentList.css'
const columns2 = [
	{ field: 'Company_Name', headerName: 'Company Name', width: 150 },
	{ field: 'Truck', headerName: 'Asset #', width: 150 },
	{ field: 'Year', headerName: 'Year', width: 150 },
	{ field: 'Make', headerName: 'Make', width: 150 },
	{ field: 'Model', headerName: 'Model', width: 150 },
	{ field: 'VIN', headerName: 'VIN', width: 150 },
	{ field: 'Plate', headerName: 'Plate', width: 150 },
	{ field: 'Color', headerName: 'Color', width: 150 }
];

class TrucksList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modaldata: [],
			carrierNames: [],
			assetList: []
		};
	}
	componentDidMount() {
		const params = {
			assettype: 'truck',
			carrierid: 0
		}
		assetListServiceForTechService(params).then((response) => {
			let assetList = [];
			if (response && response.assetList) {
				assetList = response.assetList;
			}
			this.setState({
				...this.state,
				assetList: assetList
			});

		});
		getOnboardedCarrierService().then((response) => {
			if (response.companyList) {
				this.setState({
					...this.state,
					carrierNames: response.companyList
				});
			}
		});
	}
	loadAssets = (e) => {
		let carrierid = e.target.value;
		const params = {
			assettype: 'truck',
			carrierid: carrierid
		}
		assetListServiceForTechService(params).then((response) => {
			let assetList = [];
			if (response && response.assetList) {
				assetList = response.assetList;
			}
			this.setState({
				...this.state,
				assetList: assetList
			});

		});
	}
	 handleActions = (_id, actionName) => {
		// toast({
		//   type: toastType[actionName],
		//   title: actionName,
		//   subTitle: 'Updated Successfully',
		// })
	  }
	
	   handleCheckboxSelection = (selections) => {
		console.log('checkbox', selections)
	  }
	
	   handleSelectionActions = (actionName, selectionModel) => {
		// toast({
		//   type: toastType[actionName],
		//   title: actionName,
		//   subTitle: `${selectionModel?.length} rows updated`,
		// })
	  }
	
	   loadPayments = (pageNumber, pageSize) => {
		console.log(`pagenumber: ${pageNumber}, pageSize: ${pageSize}`)
	  }
	
	getCarrierDropdown = () => {
		return (<div className="row">
			<div className="col-3">
				<label>Select Carrier
				<select className="form-control" id="findCarrierId" onChange={(e) => { this.loadAssets(e) }}>
					<option value="0">All</option>
					{
						this.state.carrierNames.length > 0 ? (this.state.carrierNames.map(function (company, index) {
							return (
								<option key={index} value={company.Company_Id}>{company.Company_Name}</option>
							)
						})) : (<option>Loading.....</option>)
					}
				</select></label>
			</div>
		</div>
		)
	}
	render() {
		var products = [];
		var indicationhtml = "No Data Found";
		if (this.props.loading) {
			indicationhtml =
				<div className="spinner-border" role="status">
					<div className="row react-bs-table-no-data"></div>
				</div>
		}
		else if (this.state.assetList) {
			products = this.state.assetList.map(
				row => {
					return ({
						id: row.Asset_Id,
						Truck: row.Asset_Number,
						Year: row.Model_Year,
						Make: row.Make,
						Model: row.Model,
						VIN: row.Identification_No,
						Plate: row.License_Plate_No,
						Color: row.Color,
						Company_Name: row.Company_Name
					});
				}
			);
		}
		else {
			products = [];
			indicationhtml = "No Data Found";
		}
		console.log('products', products)
		return (
			// <article className="table-data truck-data shadow bg-white rounded">

			// 	<div className="row no-gutters">
			// 		<div className="col-md-12">
			// 			<ul className="nav nav-tabs" id="myTrucks" role="tablist">
			// 				<li className="nav-item">
			// 					<a className="nav-link active" id="mytruck-tab" data-toggle="tab" href="#mytruck" role="tab" aria-controls="mytruck" aria-selected="true"><i className="icofont-truck-loaded"></i>My Trucks</a>
			// 				</li>
			// 			</ul>
			// 			{this.getCarrierDropdown()}
			// 			<div className="tab-content" id="myTabContent">
			// 				<div className="tab-pane fade show active" id="mytruck" role="tabpanel" aria-labelledby="mytruck-tab">								 
			// 					<TableDataComponent tableData={products} columnData={columns}  noDataIndication={indicationhtml} />
			// 				  </div>
			// 				  <div className="tab-pane fade" id="newtruck" role="tabpanel" aria-labelledby="newtruck-tab">								 
			// 				  </div>	
			// 				<div style={{ height: 550, width: '100%' }}>
			// 					<DataGrid rows={products} columns={columns2} sx={dataGridSx} />
			// 				</div>
			// 			</div>
			// 		</div>
			// 	</div>
			// </article>
			// <Container>
			// 	<HeaderBar title='Trucks' margin='1rem'/>
			// 	{this.getCarrierDropdown()}<br/>
			// 	<Container>
			// 		<Card sxContent={{ }}>
			// 			<HeaderBar title='My Trucks' />
			// 			<Box sx={{ height: 550, width: '100%' }}>
			// 				<DataGrid rows={products} columns={columns2} sx={dataGridSx} disableSelectionOnClick={true} />
			// 			</Box>
			// 		</Card>
			// 	</Container>
			// </Container>
			
			<Container >
			<HeaderBar
			  title={'Trucks'}
			//   primaryBtnProps={{ label: 'First Action'}}
			//   secondaryBtnProps={{ label: 'Second Action' }}
			/>
			<Container>
			{this.getCarrierDropdown()}
			<HeaderBar title='My Trucks' />	
			</Container>
			<TableComponent
			  title={'My Trucks'}
			  data={products}
			  columns={columns2}
			  totalCount={products.length}
			//   actionList={actionList}
			//   handleActions={handleActions}
			  handleCheckboxSelection={this.handleCheckboxSelection}
			  handleSelectionActions={this.handleSelectionActions}
			  handlePagination={(pageNumber, pageSize) =>
				this.loadPayments(pageNumber, pageSize)
			  }
			/>
		  </Container>
		)
	}
}
export default TrucksList;
