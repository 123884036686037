import React, { Component } from 'react';
import CarrierList from './geofenceCarrierList';

class GeofenceCarriers extends Component{
	state={
	}
	componentDidMount() {
		document.title = 'Geofence Configuration';
	}

	render(){		
		return(
		<CarrierList carrierslist={ this.state.carrierslist }/>
		)
	}
}

export default GeofenceCarriers