export const validate = values => {    
    const errors = {}
    if (!values.firstname) {
        errors.firstname = 'First name can not be left blank.!'
    } else if (values.firstname.length > 15) {
        errors.firstname = 'Must be 15 characters or less.!'
    } else if (values.firstname.length < 3) {
        errors.firstname = 'Must be 3 characters or greater.!'
    }
    if (!values.license) {
        errors.license = 'License can not be left blank.!'
    } else if (values.license.length > 15) {
        errors.license = 'Must be 15 characters or less.!'
    } else if (values.license.length < 3) {
        errors.license = 'Must be 3 characters or greater.!'
    }
    if (!values.drivertype) {
        errors.drivertype = 'Driver type can not be left blank.!'
    }
    if (!values.state) {
        errors.state = 'State can not be left blank.!'
    } 
    if (!values.email) {
        errors.email = 'Email can not be left blank.!'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Make sure that you entered valid email address.!'
    }
    if (!values.driver) {
        errors.driver = 'Driver can not be left blank.!'
    } 
    if (!values.mobilenumber) {
        errors.mobilenumber = 'Mobile No. can not be left blank.!'
    }
    //Regex statement for allow 10 digits and phone number format like 123-456-9780 also (123)-456-9780 
    // else if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(values.mobilenumber)) {
    //     errors.mobilenumber = 'Make sure that you entered valid mobile number.!'
    // }
    //Regex statement for allow 10 digits number only
    else if (!/^\d{10}$/i.test(values.mobilenumber)) {
        errors.mobilenumber = 'Make sure that you entered valid mobile number.!'
    }
    return errors
}
