export const validate = values => {
    const errors = {}
    if (!values.companyName) {
        errors.companyName = 'Company can not be left blank.!'
    }
    // if (!values.category) {
    //     errors.category = 'Category can not be left blank.!'
    // }    
    // if (!values.speciality) {
    //     errors.speciality = 'Speciality can not be left blank.!'
    //} 
    // if (!values.lane) {
    //     errors.lane = 'Lane can not be left blank.!'
    // }
    if (!values.phoneNumber) {
        errors.phoneNumber = 'phoneNumber can not be left blank.!'
    }
    // else if (values.phoneNumber.length > 10) {
    //     errors.phoneNumber = 'Must not be greater than 10 numbers.!'
    // }
    else if (values.phoneNumber.length < 10) {
        errors.phoneNumber = 'Must be 10 numbers.!'
    }
    if(!values.rate)
    {
      errors.rate='Rate can not be left blank.!'
    }
    else if (!/^(0|[1-9][0-9]{9})$/i.test(values.rate)) {
      errors.rate = 'Make sure that you entered a valid number.!'
    }
    // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.phone)) {
    //     errors.phone = 'Make sure that you entered valid phone number.!'
    // }
    // if (!values.city) {
    //     errors.city = 'City can not be left blank.!'
    // }
    if (!values.emailId) {
        errors.emailId = 'Email can not be left blank.!'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailId)) {
        errors.emailId = 'Make sure that you entered valid email address.!'
    }
    // if (!values.currentAddress1) {
    //     errors.currentAddress1 = 'Address can not be left blank.!'
    // }
    if (!values.state) {
        errors.state = 'State can not be left blank.!'
    }
    // if (!values.website) {
    //     errors.website = 'Website can not be left blank.!'
    // }
    // else if(!isUrlValid(values.website) ) {
    //     errors.website="Insert a valid URL";
    // }
    // if (!values.zipcode) {
    //     errors.zipcode = 'zipcode can not be left blank.!'
    // }
    // else if (values.zipcode.length > 5) {
    //     errors.zipcode = 'Must not be greater than 5 numbers.!'
    // } else if (values.zipcode.length < 5) {
    //     errors.zipcode = 'Must be 5 numbers.!'
    // }
    return errors
}

const isUrlValid = (userInput) => {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null)
        return (false );
    else
        return (true);
};