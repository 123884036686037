export const validate = values => 
{

  
    const errors = {}
    if (!values.firstName) {
        errors.firstName = 'First name can not be left blank.!'
    } else if (values.firstName.length > 15) {
        errors.firstName = 'Must be 15 characters or less.!'
    } else if (values.firstName.length < 3) {
        errors.firstName = 'Must be 3 characters or greater.!'
    }

  /*  if (!values.last_name) {
        errors.last_name = 'Last name can not be left blank.!'
    } else if (values.last_name.length > 15) {
        errors.last_name = 'Must be 15 characters or less.!'
    } else if (values.last_name.length < 3) {
        errors.last_name = 'Must be 3 characters or greater.!'
    }*/
    if(!values.primary_phone)
    {
        errors.primary_phone='Primary Phone can not be left blank.!'
    }
    else if (!/^(0|[1-9][0-9]{9})$/i.test(values.primary_phone)) {
        errors.primary_phone = 'Make sure that you entered phone number.!'
    }

    if(!values.emailId)	
    {
      errors.emailId='emailId can not be left blank.!'
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Make sure that you entered valid email address.!'
    }

  return errors
}