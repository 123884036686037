import React, { Component } from 'react';
import moment from 'moment';
class AdminMyCarrierView extends Component{
    render = () => {
        console.log(this.props.carrierData);
        let addDate="";
        if(this.props.carrierData.addDate!=="" && this.props.carrierData.addDate != null)
        {
            addDate=moment(this.props.carrierData.addDate).format('MM-DD-YYYY');
        }

        let MCS150Date="";
        if(this.props.carrierData.mcs150Date!=="" && this.props.carrierData.mcs150Date != null)
        {
            MCS150Date=moment(this.props.carrierData.mcs150Date).format('MM-DD-YYYY');
        }
        return (
            <article className="shadow bg-white rounded" id="onboardpage">
                <div className="row">
                    <div className="col">
                        <h3>Carrier Details</h3>
                    </div>
                </div>
                <p>&nbsp;</p>
                <table className="table">
                    <tbody>
                        <tr>
                            <td className="tableCellBold">Legal Name: </td>
                            <td>{this.props.carrierData.legalName}</td>
                            <td className="tableCellBold">DOT Number: </td>
                            <td>{this.props.carrierData.dotNumber}</td>
                            <td className="tableCellBold">Phone: </td>
                            <td>{this.props.carrierData.telphone}</td>
                            <td className="tableCellBold">Email: </td>
                            <td>{this.props.carrierData.emailAddress}</td>
                        </tr>
                        <tr>
                            <td className="tableCellBold">Website: </td>
                            <td>{this.props.carrierData.website}</td>
                            <td className="tableCellBold">Facebook: </td>
                            <td>{this.props.carrierData.facebook}</td>
                            <td className="tableCellBold">Twitter: </td>
                            <td>{this.props.carrierData.twitter}</td>
                            <td className="tableCellBold">Linkedin: </td>
                            <td>{this.props.carrierData.linkedin}</td>
                        </tr>
                        <tr>
                            <td className="tableCellBold">Instagram: </td>
                            <td>{this.props.carrierData.instagram}</td>
                            <td className="tableCellBold">ELD: </td>
                            <td>{this.props.carrierData.eld}</td>
                            <td className="tableCellBold">Street: </td>
                            <td>{this.props.carrierData.phyStreet}</td>
                            <td className="tableCellBold">City: </td>
                            <td>{this.props.carrierData.phyCity}</td>
                            
                        </tr>
                        <tr>
                            <td className="tableCellBold">State: </td>
                            <td>{this.props.carrierData.phyState}</td>
                            <td className="tableCellBold">Zip: </td>
                            <td>{this.props.carrierData.phyZip}</td>
                            <td className="tableCellBold">Country: </td>
                            <td>{this.props.carrierData.phyCountry}</td>                            
                            <td className="tableCellBold">Operation Status: </td>
                            <td>{this.props.carrierData.operationStatus}</td>                            
                        </tr>
                        <tr>
                            <td className="tableCellBold">Carrier Operation: </td>
                            <td>{this.props.carrierData.carrierOperation}</td>
                            <td className="tableCellBold">MCS 150 Mileage: </td>
                            <td>{this.props.carrierData.mcs150Mileage}</td>
                            <td className="tableCellBold">MCS 150 Mileage Year: </td>
                            <td>{this.props.carrierData.mcs150MileageYear}</td>
                            <td className="tableCellBold">OIC State: </td>
                            <td>{this.props.carrierData.oicState}</td>                            
                        </tr>
                        <tr>
                            <td className="tableCellBold">DBA Name: </td>
                            <td>{this.props.carrierData.dbaName}</td>
                            <td className="tableCellBold">Mailing Street: </td>
                            <td>{this.props.carrierData.mailingStreet}</td>
                            <td className="tableCellBold">Mailing City: </td>
                            <td>{this.props.carrierData.mailingCity}</td>
                            <td className="tableCellBold">Mailing State: </td>
                            <td>{this.props.carrierData.mailingState}</td>                            
                        </tr>
                        <tr>
                            <td className="tableCellBold">Mailing Zip: </td>
                            <td>{this.props.carrierData.mailingZip}</td>
                            <td className="tableCellBold">Mailing Country: </td>
                            <td>{this.props.carrierData.mailingCountry}</td>
                            <td className="tableCellBold">Fax: </td>
                            <td>{this.props.carrierData.fax}</td>
                            <td className="tableCellBold">HM Flag: </td>
                            <td>{this.props.carrierData.hmFlag}</td>                            
                        </tr>
                        <tr>
                            <td className="tableCellBold">PC Flag: </td>
                            <td>{this.props.carrierData.pcFlag}</td>
                            <td className="tableCellBold">NBR Power Unit: </td>
                            <td>{this.props.carrierData.nbrPowerUnit}</td>
                            <td className="tableCellBold">Driver Total: </td>
                            <td>{this.props.carrierData.driverTotal}</td>
                            <td className="tableCellBold">Add Date: </td>
                            <td>{addDate}</td>
                        </tr>
                        <tr>
                            <td className="tableCellBold">MCS 150 Date: </td>
                            <td>{MCS150Date}</td>
                            <td className="tableCellBold">Status: </td>
                            <td>{this.props.carrierData.status}</td>
                            <td className="tableCellBold"></td>
                            <td></td>
                            <td className="tableCellBold"></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colSpan="8" className="text-center">
                                <button type="button" className="btn btn-secondary" onClick={(e) => {this.props.closeForm(0)}}>Close</button>
                                &nbsp;
                                <button type="button" className="btn btn-secondary" onClick={(e) => {this.props.editCarrier(null,null,this.props.carrierData)}}>Edit</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </article>
        );
    }
}
export default AdminMyCarrierView;