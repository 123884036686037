import React, { Component } from 'react';

class DocumentsSearch extends Component{

	render(){
		return(
			<article className="table-data truck-data shadow bg-white rounded">
				<form className="row">
					<div className="col-md-11">
						<div className="row">
							<div className="form-group col col-md-4">
						    	<label htmlFor="title">Title</label>
						    	<input type="text" className="form-control" id="title" aria-describedby="emailHelp" placeholder="Title"/>				    	
						  	</div>
						  	<div className="form-group col col-md-4">
						    	<label htmlFor="category">Category</label>
						    	<select  className="form-control">
						    		<option>All Category</option>
						    		<option>Address proof</option>
						    		<option>CDL</option>
						    	</select>
						  	</div>
						  	<div className="form-group col col-md-4">
						    	<label htmlFor="uploaded_date">Uploaded Date</label>
						    	<input type="date" className="form-control" id="company" aria-describedby="emailHelp" placeholder="Uploaded Date"/>				    	
						  	</div>
						</div>
					</div>
					<div className="col-md-1">
						<div className="form-group">
					  		<a href="/#" className="btn btn-primary btn-sm">Find</a>&nbsp;
					  		<a href="/#" className="btn btn-primary btn-sm">Reset</a>
					  	</div>
					</div>				  	
				</form>
			</article>
		)
	}
}

export default DocumentsSearch