import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { Field, initialize, reduxForm } from 'redux-form';
import { TaskAdd } from '../../../actions/taskActions';
import { renderField,renderSelect } from '../../../helpers/FormControls';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { validate } from './TaskValidation';
import moment from 'moment';
import {driverbycompanyidService} from '../../../services/loadService';

function submit(values) {
   const frmdata = {};
   frmdata.driverId=(values.driverId) ? (values.driverId) : ("");
   frmdata.title=(values.title) ? (values.title) : ("");
   frmdata.description=(values.description) ? (values.description) : ("");
   frmdata.taskDate=(values.taskDate) ? (moment(values.taskDate).format('YYYY-MM-DD HH:mm:ss')) : ("")   
   frmdata.priority=(values.priority) ? (values.priority) : ("");
   this.props.dispatch(TaskAdd(frmdata));
}
   const renderDatePicker = ({ input: { onChange, value }, showTime,input, label, controlClass,labelnotavailabe, data,disable,type, meta: { touched, error } }) =>
   <div className={`form-group ${(touched && error) ? 'error' : ''}`}>
   {(labelnotavailabe) ? '' : <label>{label}</label>}
   <div>
   <DateTimePicker dropDown={true}
   {...input}
      className={`form-group ${(controlClass) ? controlClass : ''}`}
      onChange={onChange}
      min={new Date()}
      type={type}
      text={`${(data) ? data : ''}`}
      format="MM-DD-YYYY HH:mm "
      placeholder="Date"
      time={true}
      value={!value ? null : new Date(value)}
   /> 
   {touched && (error && <span className="error">{error}</span>)}
   </div>
   </div>

const renderTextArea = ({input, meta: { touched, error, warning }}) => (
   <div>
       <label>Description</label>
           <textarea {...input} className="form-control" ></textarea>
           {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
   </div>
);

const renderSelectfrdrivername = ({ input, data,controlClass,labelnotavailabe, label, meta: { touched, error } }) => (    
   <div className={`form-group ${(touched && error) ? 'error' : ''}`}>
     {(labelnotavailabe) ? <label></label> : <label>{label}</label>}     
    <select className={`requiredfield form-control ${(controlClass) ? controlClass : ''}`} {...input} placeholder={label}>         
       <option value="">{ 'Select ' + label}</option>
       { 
          Array.isArray(data) ? data.map(option =>
          <option
             value={option.User_Id}
             key={option.User_Id}>{option.First_Name} - {option.Phone_Number}
          </option>) : ''
       }
    </select>
    {touched && error && <span className="error">{error}</span>}
 </div>
);

class TaskNew extends Component{

   state = {
      priority:[
         {id:"0",name:"Low"},
         {id:"1",name:"Medium"},
         {id:"2",name:"High"},
     ],
     drivers:[]
   }
   handleClick = () =>{
      this.props.dispatch(initialize('TaskForm'));
  }

  componentDidMount() {		
     this.loadDriver();
   }

   loadDriver = () => {
		driverbycompanyidService().then((response) => {	
			if(response.DriverList){				
				this.setState({
					...this.state,
					drivers:response.DriverList
				});
			}			
		});
	}

   render(){
      console.log(this.props,this.state)
      const { handleSubmit, pristine, reset, submitting,first_name } = this.props
      return(
         <div className="modal fade addContactModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" style={{width:'638px'}}>
              <form  className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}>
               <div className="modal-content addcontactcontent">
                  <div className="modal-header">
                  <h5 className="modal-title doc-color" id="exampleModalLabel">ADD TASK</h5>
                     <button type="button" className="close" id="addtaskPopup" data-dismiss="modal" aria-label="Close" onClick={this.handleClick}>
                        <span aria-hidden="true">&times;</span>
                     </button>
                  </div>
                  <div className="modal-body">
                     <div>
                        <div className="row">
                           <div className="col-md-6">
                             <Field type="date" name="driverId" keyColumn='User_Id' keyValue='First_Name' component={renderSelectfrdrivername} label="Driver Name" data={this.state.drivers}/>
                           </div>
                           <div className="col-md-6">
                              <Field type="text" name="title" className="form-control"  controlClass="requiredfield" component={renderField}  label="Title" />
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-md-6">
                             <Field type="date" name="priority" component={renderSelect} label="Priority"
                             data={this.state.priority} />
                           </div>
                           <div className="col-md-6">
                           <Field controlClass="level1 requiredfield" type="text"  name="taskDate" defaultValue={null} label="Date" component={renderDatePicker} />
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-md-12">
                              <Field name="description" component={renderTextArea}/>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="modal-footer">
                     <button type="submit" className="btn btn-primary btn-sm">Save</button>
                     <button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-primary btn-sm">Reset</button>
                  </div>
               </div>
               </form>
            </div>
         </div>
         )
   }
}

const mapStateToProps=(state)=>
{
   return{
      loading : state.contactReducer.loading,
      data : state.contactReducer.data,
      companyNameListValue : state.companyReducer.companyNamelist,
    }
}

export default connect(mapStateToProps)(reduxForm({
   form:'TaskForm',validate
})(TaskNew))