import * as types from '../actions';

const initialState = {
    data: [],
    loading: false,
    type:'',
    error: ''
};

export default function(state = initialState, action) {  
    switch(action.type) {
        case types.GET_TRUCK_DATA:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.GET_TRUCK_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.GET_TRUCK_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error:''
            };
       case types.SEARCH_TRUCK_DATA:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.SEARCH_TRUCK_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.SEARCH_TRUCK_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error:''
            };
        case types.POST_NEW_TRUCK_DATA:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.POST_NEW_TRUCK_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.POST_NEW_TRUCK_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.EDIT_TRUCK_DATA:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.EDIT_TRUCK_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.EDIT_TRUCK_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.VIEW_TRUCK_DATA:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.VIEW_TRUCK_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.VIEW_TRUCK_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.GET_TRUCK_LIST:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.GET_TRUCK_LIST_SUCCESS:
            return { 
                ...state,
                trucks: action.data.assetList,
                loading: false,
            };
        case types.GET_TRUCK_LIST_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.TRUCK_BULK_UPLOAD:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.TRUCK_BULK_UPLOAD_SUCCESS:
            return { 
                ...state,
                trucks: action.data,
                type:action.type,
                loading: false,
            };
        case types.TRUCK_BULK_UPLOAD_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};