
import React from 'react';

class EditLoadInput  extends React.Component {
    render() {
        return (
            <div className='col form-group'>
                {/* <label>{this.props.label}</label> */}
                <input name={this.props.name} type={this.props.type} className={this.props.class} placeholder={this.props.placeHolder} disabled={this.props.disabled} defaultValue={this.props.value?this.props.value:''} onChange={this.props.onChangeValue}/>
            </div>
        )
    }
}

export default EditLoadInput;
