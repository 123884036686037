import React from 'react';

class LoadInput  extends React.Component {
    render() {
        return (
            <div className='col form-group'>
                {/* <label>{this.props.label}</label> */}
                <input name={this.props.name}  maxlength={this.props.maxlength?this.props.maxlength:null}  type={this.props.type} className={this.props.class} placeholder={this.props.placeHolder} disabled={this.props.disabled} value={this.props.value}/>
            </div>
        )
    }
}

export default LoadInput;
