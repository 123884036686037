import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Field, reduxForm } from 'redux-form';
import { bulkuploadaction } from '../../../actions/trucksActions';
import { validate } from './Bulkuploadvalidation';

function bulkuploadsubmit(dispatch)
{
  const values = 
    {
      'trucktype':'TRAILER',
      'urlval':'trailer'
    }
   this.props.dispatch(bulkuploadaction(values)); 
}
class Bulkupload extends Component{

  state={
      resultfile:'',
      url:'#'
  }  
  componentDidUpdate() 
 {  
   const BASE_URL= process.env.REACT_APP_BASE_URL;
   var resultFile='';
   if(this.props.bulkuploadval)   {             
       if(this.props.type==="TRUCK_BULK_UPLOAD_SUCCESS")
       {
         resultFile = this.props.bulkuploadval.result_file; 
         if(this.props.bulkuploadval.status==='OK')
         {                                               
            document.querySelector('#bulkuploadclass').setAttribute("href",BASE_URL+'publicresource/download/trailer/import-status?file_name='+resultFile);
            toastr.success('Success', 'Truck has been Successfully Imported!');
            setTimeout(function(){
            window.location.reload();
            }, 2000);
            document.getElementById("bulkuploadclass").click();  
         }  
         else if(this.props.bulkuploadval.status==='INTERNAL_SERVER_ERROR')
         {
           toastr.error('Error', 'Please upload a valid excel file!');
           setTimeout(function(){
            window.location.reload();
            }, 2000);
         } 
         else
         {
           toastr.error('Error', this.props.bulkuploadval.message);
         }        
       } 
   }
   else
   {
      resultFile = '';
   }  

 }
 handleFile(fieldName, event) {
   event.preventDefault();
   var fd = new FormData(); 
   var files = event.target.files[0]; 
   fd.append('file', files);
   this.setState({
       files: fd
   });
}
adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

renderFileInput = ({
   input: { value: omitValue, onChange, onBlur, ...inputProps },data, label,
   meta:{omitMeta,touched, error},  ...props
 }) => {
   return (
       <div className={` ${(touched && error) ? 'error' : ''}`}>
           <label>{label}</label>
           <input
               onChange={this.adaptFileEventToValue(onChange)}
               //onBlur={this.adaptFileEventToValue(onBlur)}
               type="file"
               {...props.input}
               {...props}
               placeholder={label}
           />  
            <br></br><br></br>      
           {touched && error && <span className="error" style={{padding:'15px'}}>{error}</span>}
       </div>
   );
};
render(){
   const BASE_URL= process.env.REACT_APP_BASE_URL;
   const { handleSubmit } = this.props
return(   
    <form onSubmit={handleSubmit(bulkuploadsubmit.bind(this))}>    
      <div className="row d-flex justify-content-center m-4">                                                
      <div className="col-md-6 text-center">
          <h3 className="mt-5 mb-5">Import bulk data</h3>
          <div className="file-drop-area">                                                           
                <span className="file-msg"></span>                                                            
                  <Field
                  name="bulkuploadfilename"
                  type="file"
                  id="truckbulkuploadfile"
                  component={this.renderFileInput}
                />   
            </div>
          <div className="text-right">
              <br></br>
              <a href={BASE_URL+"publicresource/download/trailer/import-sample"}>Download sample file</a><br></br>              
              <a id="bulkuploadclass" style={{display:'none'}} target="_blank" href="/#" >Download Status file</a>
          </div>
          <br></br>
          <div className="row" style={{float:'right'}}>
                    <div className="col col-md-12">
                      <div className="form-group form-group-btns">
                          <button type="submit" className="btn btn-primary btn-sm">Save</button>&nbsp;                                                                     
                      </div>
                    </div>
                </div>
          </div>                                                    
        </div>    
    </form> 
          )
     }
}  
const mapStateToProps = state => {
return {
      bulkuploadval: state.truckReducer.trucks,
      type:state.truckReducer.type,
      data: state.truckReducer.data,
      loading: state.truckReducer.loading,
      error: state.truckReducer.error
    }
}
export default connect(mapStateToProps)(reduxForm({ form: 'bulkuploadcomponent',validate }) (Bulkupload))