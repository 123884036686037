import { authHeader } from '../helpers/authHeader';
import { checkUnauthorized } from '../helpers/appUtil';
require('dotenv').config();

export const loadService = (request) => {
    let endURL;
    switch (request.payload.loadStatusId) {
        case 8:
            endURL = 'status=ASSIGNED'
            break;
        case 13:
            endURL = 'status=DISPATCHED'
            break;
        case 10:
            endURL = 'status=DELIVERED'
            break;
        case 14:
            endURL = 'status=CANCELLED'
            break;
        default:
            break;
    }
    const BASE_URL = process.env.REACT_APP_LOAD_API_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'loads?' + endURL;
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: 'GET',
        // body:JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const shipperLoadService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'load/shipperlist'
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const loadServicefrcancelledloads = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'load/list'
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return { cancelledloadlist: responseData };
        })
        .catch(error => {
            return {};
        });
}
export const loadServiceRestoreloads = (request) => {
    const BASE_URL = process.env.REACT_APP_LOAD_API_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'loads/' + request.payload.loadId + '/restore';
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: 'PUT',
        // body:JSON.stringify({'status': 'CANCELLED'}),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return { cancelledloadlist: responseData };
        })
        .catch(error => {
            return {};
        });
}
export const loadServicefrquickloads = (request) => {
    const BASE_URL = process.env.REACT_APP_LOAD_API_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'loads?status=CREATED'
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return { quickloadlists: responseData };
        })
        .catch(error => {
            return {};
        });
}
export const addNewLoad = (request) => {
    // const BASE_URL= process.env.REACT_APP_BASE_URL;
    // const LOAD_DATA_API_ENDPOINT = BASE_URL + 'loaddata/add';
    const BASE_URL = process.env.REACT_APP_LOAD_API_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'loads';
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const editLoad = (request) => {
    const BASE_URL = process.env.REACT_APP_LOAD_API_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'loads/' + request.payload.loadId;
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: 'PATCH',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const updateLoadService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL + 'loaddata/updatestatus';
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
};
export const changeLoadStatusService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL + 'loadstatus/change?loadId=' + request.payload.loadId;
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
};
export const savepodStatusService = (request) => {
    const formData = new FormData();
    const fileField = document.querySelector("input[id='podfileid']");
    let authToken = localStorage.getItem('authToken');
    formData.append('loadId', request.payload.loadId);
    formData.append('POD_NO', request.payload.POD_NO);
    formData.append('POD_REF', fileField.files[0]);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL + 'loaddocuments/savepod'
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT, {
        method: 'POST',
        body: formData,
        'mimeType': 'multipart/form-data',
        headers: {
            'Authorization': authToken
        }
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
};
export const tenderLoadStatusService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL + 'tender/brokertender?loadId=' + request.payload.loadId;
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const updateTenderPopupService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const UPDATE_TENDER_POPUP_API_ENDPOINT = BASE_URL + 'tender/brokertenderorder'
    return fetch(UPDATE_TENDER_POPUP_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
};
export const companylistbytypeService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL + 'companyresource/companylistbytype?companytypeid=' + request.payload.companytypeid;
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return { companyList: responseData };
        })
        .catch(error => {
            return {};
        });
}
export const getAssetOrderService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const roles = localStorage.getItem('Company_Name');
    const ASSET_ORDER_API_ENDPOINT = BASE_URL + 'assetorder/get/?loadId=' + request.payload.loadId;
    return fetch(ASSET_ORDER_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            responseData['Make'] = '';
            responseData['Total_Pay'] = '';
            responseData['Driving_License_ExpDate'] = '';
            responseData['co-Driving_License_ExpDate'] = '';
            responseData['Dispatcher'] = roles;
            return { assetOrderList: responseData };
        })
        .catch(error => {
            return {};
        });
}
export const truckNoAndlocationService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const TRUCK_NO_API_ENDPOINT = BASE_URL + 'assignload/gettruckandlocation';
    return fetch(TRUCK_NO_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return { truckNoListAndLocation: responseData };
        })
        .catch(error => {
            return {};
        });
}
export const driverbycompanyidService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const DRIVER_API_ENDPOINT = BASE_URL + 'driver/getbycompanyid';
    return fetch(DRIVER_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return { DriverList: responseData };
        })
        .catch(error => {
            return {};
        });
}
export const loadaccessorialsService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const LOAD_ACCESSIORALS_ENDPOINT = BASE_URL + 'commonresource/get-load-accessorials/?LoadId=' + request.payload.loadId;
    return fetch(LOAD_ACCESSIORALS_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const saveAssetOrder = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'assetorder/save';
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const saveQuickDispatch = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    //This is for multiple loads
    //const LOAD_DATA_API_ENDPOINT = BASE_URL + 'quickdispatch/set';
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'dispatch/movetonotyetdispatched?loadId=' + request.payload.loadId;
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const saveBrokerQuickDispatch = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'quickdispatch/broker';

    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const shippertypecompanylistforlanetable = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const COMPANY_LIST_API_ENDPOINT = BASE_URL + 'loadresource/load/shippertypecompanylistforlanetable'
    return fetch(COMPANY_LIST_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getEldByCariierService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_ELD_API_ENDPOINT = BASE_URL + 'eldprovidersresource/eldproviders/onboardedlist?companyId=' + request.payload;
    return fetch(GET_ELD_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getTrucksByELDSService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_TRUCK_NO_API_ENDPOINT = BASE_URL + 'assetsresource/carrierassetlist?eldProviderId=' + request.eldProviderId + '&carrierCompanyId=' + request.companyId;
    return fetch(GET_TRUCK_NO_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getcitybystateService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_CITY_API_ENDPOINT = BASE_URL + 'commonresource/getcitybystate?stateId=' + request.payload;
    return fetch(GET_CITY_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getzipbycityService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_ZIP_API_ENDPOINT = BASE_URL + 'commonresource/getzipbycity?cityId=' + request.payload;
    return fetch(GET_ZIP_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const saveLoadPostOrder = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'loaddocuments/saveloadpostorder';
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const loadBulkUploadService = (request) => {
    const formData = new FormData();
    const fileField = document.querySelector("input[id='loadbulkuploadfilebroker']");
    let authToken = localStorage.getItem('authToken');
    formData.append('file', request.payload.bulkuploadfilename);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const LOADS_BULK_UPLOAD_STATUS_API_ENDPOINT = BASE_URL + 'loadupload/bulk';
    return fetch(LOADS_BULK_UPLOAD_STATUS_API_ENDPOINT, {
        method: 'POST',
        body: formData,
        'mimeType': 'multipart/form-data',
        headers: {
            'Authorization': authToken
        }
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
};
export const tenderChangeLoadStatusService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_API_ENDPOINT = BASE_URL + 'loadstatus/set/' + request.payload.statusval + '?loadId=' + request.payload.loadid
    return fetch(LOAD_DATA_API_ENDPOINT, {
        method: 'POST',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const editloadvaluesservice = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_EDIT_VIEW_API_ENDPOINT = BASE_URL + 'loadview/get?loadid=' + request.payload.loadId;
    return fetch(GET_EDIT_VIEW_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            responseData['loadId'] = responseData.LoadList.loadId;
            responseData['numberload'] = responseData.LoadList.loadNumber;
            responseData['shippers'] = responseData.LoadList.shipperCompanyId;
            responseData['bolNumber'] = responseData.LoadList.bolNumber;
            responseData['stringLoadDate'] = responseData.LoadList.stringLoadDate;
            responseData['weight'] = responseData.LoadList.weight;
            responseData['commodity'] = responseData.LoadList.commodity;
            responseData['poNumber'] = responseData.LoadList.poNumber;
            responseData['rate'] = responseData.LoadList.rate;
            responseData['truckTypeId'] = responseData.LoadList.truckTypeId;
            responseData['consigneeName'] = responseData.LoadList.consigneeStateName;
            responseData['quantity'] = responseData.LoadList.quantity;
            responseData['consigneeStateId'] = responseData.LoadList.consigneeStateId;
            responseData['consigneeCity'] = responseData.LoadList.consigneeCity;
            responseData['consigneeZip'] = responseData.LoadList.consigneeZip;
            responseData['consigneeAddress1'] = responseData.LoadList.consigneeAddress1;
            responseData['fuelAdvance'] = responseData.LoadList.fuelAdvance;
            responseData['fuelAdvanceCommission'] = responseData.LoadList.fuelAdvanceCommission;
            responseData['estimatedBrokerNet'] = responseData.LoadList.estimatedBrokerNet;
            responseData['totalBrokerNet'] = responseData.LoadList.totalBrokerNet;
            responseData['instructions'] = responseData.LoadList.instructions;
            responseData['originAddress1'] = responseData.LoadList.originAddress1;
            responseData['originCity'] = responseData.LoadList.originCity;
            responseData['originStateId'] = responseData.LoadList.originStateId;
            responseData['originZip'] = responseData.LoadList.originZip;
            responseData['destinationAddress1'] = responseData.LoadList.destinationAddress1;
            responseData['destinationCity'] = responseData.LoadList.destinationCity;
            responseData['destinationStateId'] = responseData.LoadList.destinationStateId;
            responseData['destinationZip'] = responseData.LoadList.destinationZip;
            var result = responseData.LoadList.loadPointsList.map(singleData => {
                return {
                    'loadPointId': singleData.loadPointId,
                    'load_point_type_id': singleData.loadPointTypeId,
                    'contactName': singleData.contactAddress1,
                    'states': singleData.contactStateId,
                    'city': singleData.contactCity,
                    'zip': singleData.contactZip,
                    'contactNumber': singleData.contactNumber,
                    'earliestTime': singleData.earliestTime,
                    'latestTime': singleData.latestTime,
                    'instructions': singleData.instruction
                }
            });
            responseData['members'] = result;
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const updateloadvaluesservice = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const LOAD_DATA_UPDATE_API_ENDPOINT = BASE_URL + 'loaddata/update';
    return fetch(LOAD_DATA_UPDATE_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const lanedetailsservice = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_LANE_API_ENDPOINT = BASE_URL + 'lanehistory/getforload?loadid=' + request.payload.loadId;
    return fetch(GET_LANE_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const quickdispatchloadlistservice = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_API_ENDPOINT = BASE_URL + 'load/quick/get'
    return fetch(GET_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const quickDispatchLoadDeliveryConfirmedService = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_API_ENDPOINT = BASE_URL + 'load/quick/get/delivery-confirmed'
    return fetch(GET_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const quickdispatchservice = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    //const GET_API_ENDPOINT = BASE_URL+'dispatch/movetodispatchandenroute?loadId='+request.payload.loadId
    const GET_API_ENDPOINT = BASE_URL + 'dispatch/movetodispatchandenroute'
    return fetch(GET_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const checkauthtokenservice = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_API_ENDPOINT = BASE_URL + 'publicresource/quickloadverification?verificationKey=' + request.payload.verification
    return fetch(GET_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}

export const checkauthtokenvalidservice = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_API_ENDPOINT = BASE_URL + 'loadresource/load/quickloadverificationchecking?verificationKey=' + request.payload.verification
    return fetch(GET_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const cancelledbyloadservice = (request) => {
    const BASE_URL = process.env.REACT_APP_LOAD_API_URL;
    const GET_API_ENDPOINT = BASE_URL + 'loads/' + request.payload;
    return fetch(GET_API_ENDPOINT, {
        method: 'PATCH',
        body: JSON.stringify({
            status: 'CANCELLED'
        }),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const notyetdispatchededitdrivertruckservice = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_API_ENDPOINT = BASE_URL + 'dispatch/movetodispatchandenroute?loadId=' + request.payload.loadId
    return fetch(GET_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const onchangetruckService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_API_ENDPOINT = BASE_URL + 'loadresource/load/assetupdatedbyloadid?loadid=' + request.payload.loadid + '&assetid=' + request.payload.assetid
    return fetch(GET_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const onchangedriverService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_API_ENDPOINT = BASE_URL + 'driver/updatedbyloadid?loadid=' + request.payload.loadid + '&driverid=' + request.payload.driverid
    return fetch(GET_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const quickdispatchintellitransService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_API_ENDPOINT = 'https://test2-atl.carrierpoint.com/CPWebService/resteasy/tranzlogixEventInbound'
    return fetch(GET_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
//Individual vehicle tracking for broker
export const getVehicleTrackingService = (request) => {
    const BASE_URL = process.env.REACT_APP_LOAD_API_URL;
    // const VEHICLE_TRACKING_API_ENDPOINT = BASE_URL + `loads/369/tracking`;
    const VEHICLE_TRACKING_API_ENDPOINT = BASE_URL+'trackingresource/loadvehicletracking?loadId='+request;
    return fetch(VEHICLE_TRACKING_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const getDeliveredLoadVehicleTracking = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const VEHICLE_TRACKING_API_ENDPOINT = BASE_URL + 'trackingresource/deliveredloadvehicletracking?loadId=' + request;
    return fetch(VEHICLE_TRACKING_API_ENDPOINT, {
        method: 'GET',
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const brokerTenderService = (request) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const GET_API_ENDPOINT = BASE_URL + 'tender/savebrokertender'
    return fetch(GET_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const createDeliveryService = (request) => {
    const CRREATE_DELIVERY_API_ENDPOINT = process.env.REACT_APP_LOAD_API_URL + 'factor';
    return fetch(CRREATE_DELIVERY_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
export const fuelAdvanceService = (request) => {
    const CRREATE_DELIVERY_API_ENDPOINT = process.env.REACT_APP_LOAD_API_URL + 'factor';
    return fetch(CRREATE_DELIVERY_API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(request.payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            checkUnauthorized(responseData.status, responseData.message);
            return responseData;
        })
        .catch(error => {
            return {};
        });
}
