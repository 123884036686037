import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class DocumentList extends Component{
	state = {
		selectOpt : null,
		docname : null
	}
	componentDidMount() {
		let selectOpt = driver; 		
		this.setState({
			selectOpt : selectOpt,
			docname: 'Driver'
		});		
	}
	onChange = (e) => {
		e.preventDefault();	
		if(e.target.value === 'driver'){
			let selectOpt = driver 
			this.setState({
				selectOpt : selectOpt,
				docname: 'Driver'
			});	
		}
		if(e.target.value === 'truck'){
			let selectOpt = truck 
			this.setState({
				selectOpt : selectOpt,
				docname: 'Truck',
			});	
		}
		if(e.target.value === 'trailer'){
			let selectOpt = trailer 
			this.setState({
				selectOpt : selectOpt,
				docname: 'Trailer',
			});	
		}
		
	}
	// handleClick = (e) =>{
	// 	if(e.target.value == 'driver'){	
	// 		return(
	// 			<Link to="/addcarrierdocuments" className="btn btn-primary btn-sm">Add Document</Link>
	// 		)
				
	// 		// this.history.push('/addcarrierdocuments');
	// 		//this.props.history.push('/addcarrierdocuments');
	// 	}
	// 	// if(e.target.value == 'truck'){
		
	// 	// }
	// 	// if(e.target.value == 'trailer'){
				
	// 	// }
	// }
 	render(){
	  	var documentList =this.props.documentList;
	  	const documentListHtml = documentList.length ? (
			documentList.map(documentEntry => {
				return (
						<tr key={ documentEntry.id }>
						<td><input type="checkbox"/></td>
						<td>{ documentEntry.load }</td>
						<td>{ documentEntry.document_title }</td>
						<td>{ documentEntry.category }</td>
						<td>{ documentEntry.owner }</td>
						<td>{ documentEntry.uploaded_details}</td>
						<td>{ documentEntry.notes }</td>
						<td>
						<a href='/#'><i className="icofont-eye-alt"></i></a>
						<a href='/#'><i className="icofont-ui-edit"></i></a>
						<a href='/#'><i className="icofont-download"></i></a>
						<a href='/#'><i className="icofont-ui-delete"></i></a>
						</td>
						</tr>			
				)
		 	 })
		) : (
				<div className="stay-vertical">
					<div>
						<p>No Results Found :(</p>
					</div>
				</div>
		  );
		  return (
			<article className="table-data truck-data shadow bg-white rounded">
			<div className="row no-gutters">
				<div className="col-md-12">
					<ul className="nav nav-tabs" id="myDocuments" role="tablist">
					  	<li className="nav-item">
					    	<a className="nav-link active" id="mydocument-tab" data-toggle="tab" href="#mydocument" role="tab" aria-controls="mydocument" aria-selected="true"><i className="icofont-truck-loaded"></i>Documents</a>
					  	</li>		
						  <div className="flex-left-auto">
					  		<ul className="ul-horizontal tab-menus">
							  <li>
								  	<select id="lang" className="col-md-12" onChange={this.onChange}>
										<option value="select" >Select</option>
										<option value="driver">DRIVER</option>
										<option value="truck">TRUCK</option>
										<option value="trailer">TRAILER</option>
									</select>
					  			</li>
					  			<li>
								  <Link to={"documents/add/" +  this.state.docname} className="btn btn-primary btn-sm">Add Document</Link>
					  			</li>&nbsp;
					  		</ul>
					  	</div>		
					</ul>
					{this.state.selectOpt}					
				</div>
			</div>
		</article>
	  )
  }
}
const driver = <div className="row no-gutters">
<div className="col-md-12">	
	<div className="tab-content" id="myTabContent">
		  <div className="tab-pane fade show active" id="mydocument" role="tabpanel" aria-labelledby="mydocument-tab">
			  <h6 className="doc-color">DOCUMENTS - DRIVER</h6>
			  <table className="table table-sm">
				  <thead>
					<tr>
						<th scope="col"><input type="checkbox"/></th>
						<th scope="col">Driver</th>
						<th scope="col">Document Title</th>	    
						<th scope="col">Category</th>
						<th scope="col">Owner</th>
						<th scope="col">Uploaded Details</th>
						<th scope="col">Notes</th>
						<th scope="col">Action</th>
					</tr>
				  </thead>
				  <tbody>
				
				  </tbody>
			 </table>
		  </div>					  	
	</div>
</div>
</div>;
const truck = <div className="row no-gutters">
<div className="col-md-12">	
	<div className="tab-content" id="myTabContent">
		  <div className="tab-pane fade show active" id="mydocument" role="tabpanel" aria-labelledby="mydocument-tab">
		  <h6 className="doc-color">DOCUMENTS - TRUCK</h6>
			  <table className="table table-sm">
				  <thead>
					<tr>
						<th scope="col"><input type="checkbox"/></th>
						<th scope="col">Truck</th>
						<th scope="col">Document Title</th>	    
						<th scope="col">Category</th>
						<th scope="col">Owner</th>
						<th scope="col">Uploaded Details</th>
						<th scope="col">Notes</th>
						<th scope="col">Action</th>
					</tr>
				  </thead>
				  <tbody>
				
				  </tbody>
			 </table>
		  </div>					  	
	</div>
</div>
</div>;
const trailer = <div className="row no-gutters">
<div className="col-md-12">	
	<div className="tab-content" id="myTabContent">
		  <div className="tab-pane fade show active" id="mydocument" role="tabpanel" aria-labelledby="mydocument-tab">
		  <h6 className="doc-color">DOCUMENTS - TRAILER</h6>
			  <table className="table table-sm">
				  <thead>
					<tr>
						<th scope="col"><input type="checkbox"/></th>
						<th scope="col">Trailer</th>
						<th scope="col">Document Title</th>	    
						<th scope="col">Category</th>
						<th scope="col">Owner</th>
						<th scope="col">Uploaded Details</th>
						<th scope="col">Notes</th>
						<th scope="col">Action</th>
					</tr>
				  </thead>
				  <tbody>
				
				  </tbody>
			 </table>
		  </div>					  	
	</div>
</div>
</div>;
	
export default DocumentList;