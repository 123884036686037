import React, { Component } from 'react';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { TableDataComponent } from 'UI-Component';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

class cancelledLoadpopup extends Component {
    render() {
        var cancelledloads = [];
        if (this.props.cancelledloads.LoadList) {
            cancelledloads = [];
            cancelledloads = this.props.cancelledloads.LoadList.map(
                row => {
                    return ({
                        id: row.loadId,
                        loadNumber: row.loadNumber,
                        stringLoadDate: row.stringLoadDate,
                        shipperName: row.shipperName,
                        customerName: row.customerName,
                        brokerName: row.brokerName,
                        consigneeName: row.consigneeName,
                        consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + ' ' + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
                        weight: row.weight,
                        originCity: row.originCity,
                        destinationCity: row.destinationCity,
                        commodity: row.commodity,
                        equipmentType: row.truckTypeName,
                        loadPointsList: row.loadPointsList,
                        basecharge: row.baseCharges,
                        fulecharge: row.fuelCharges,
                        accessiorialcharge: row.accessorialCharges,
                        totalrevenue: row.totalRevenue,
                        loadedmiles: row.loadedMiles,
                        emptymiles: row.emptyMiles,
                        totalmiles: row.totalMiles,
                        driverpay: row.driverPay,
                        tenderprice: row.shipperLoadPrice,
                        cancelledDate: row.cancelledDate
                    });
                }
            );

        }
        else {
            cancelledloads = [];
        }
        const { SearchBar } = Search;
        const columns = [
            {
                text: 'Load #',
                dataField: 'loadNumber',
                sort: true
            },
            {
                text: 'Date',
                dataField: 'stringLoadDate',
                sort: true
            },
            {
                text: 'Dispatcher',
                dataField: 'shipperName',
                sort: true
            },
            {
                text: 'Customer',
                dataField: 'customerName',
                sort: true
            },
            {
                text: 'Broker Agent',
                dataField: 'brokerName',
                sort: true
            },
            {
                text: 'Consignee',
                dataField: 'consigneeName',
                sort: true
            },
            {
                text: 'Consignee Address',
                dataField: 'consigneeAddress',
                sort: true
            },
            {
                text: 'Weight',
                dataField: 'weight',
                sort: true
            },
            {
                text: 'Origin',
                dataField: 'originCity',
                sort: true
            },
            {
                text: 'Destination',
                dataField: 'destinationCity',
                sort: true
            },
            {
                text: 'Commodity',
                dataField: 'commodity',
                sort: true
            },
            {
                text: 'Equipment Type',
                dataField: 'equipmentType',
                sort: true
            },
            {
                text: 'Canceled Date',
                dataField: 'cancelledDate',
                sort: true
            }
        ]

        const customTotal = (from, to, size) => (
            <span className='react-bootstrap-table-pagination-total'>
                Showing {from} to {to} of {size} Results
            </span>
        );

        const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
        };
        return (
            <div className='modal fade cancelledloadmodal' tabIndex='-1' role='dialog' aria-labelledby='myLargeModalLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title doc-color' id='exampleModalLabel'>Cancelled Loads</h5>
                            <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body table-data'>
                            <TableDataComponent tableData={cancelledloads} columnData={columns} noDataIndication={'No Data Found'} />
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        cancelledloads: state.loadReducer.data,
        loading: state.loadReducer.loading,
        error: state.loadReducer.error
    }
}
export default connect(mapStateToProps)(cancelledLoadpopup);