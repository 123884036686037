import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom'
import Icofont from 'react-icofont';
import './widgets.css';
class RoleMenu extends Component{

	state={
		userType:localStorage.getItem('userType')
	}

	render(){
		const userType=this.state.userType;
		switch(userType) {
			case 'driver':
				return(
					<ul className="navbar-nav mr-auto">
						<li className="nav-item active">
							<a className="nav-link active" href="/#">
								<span>Driver</span>		        		
							</a>
						</li>
					</ul>
				)
			case 'carrier':
				return(
					<ul className="navbar-nav mr-auto">
					
					</ul>
				)
			case 'broker':
				return(
					<ul className="navbar-nav mr-auto">
						
					</ul>
				)
			case 'shipper':
				return(
					<ul className="navbar-nav mr-auto">
					
					</ul>
				)
			case 'admin':
				return(
					<ul className="navbar-nav mr-auto">
					</ul>
				)
			case 'techpartner':
				return(
					<ul className="navbar-nav mr-auto">
					</ul>
				)
			case 'factor':
				return(
					<ul className="navbar-nav mr-auto">
					
					</ul>
				)
			default:
				return(
					<ul className="navbar-nav mr-auto">
						<li className="nav-item dropdown active" id="admin_menu">
							<a className="nav-link dropdown-toggle active" data-toggle="dropdown" href="/#" role="button" aria-haspopup="true" aria-expanded="false">
								<span>Admin</span>
							</a>
							<div className="dropdown-menu">
								<NavLink to="/adminuser/onboarding" className="dropdown-item" href="#">CARRIER ONBOARDING</NavLink>
								<NavLink to="/adminuser/driveronboarding" className="dropdown-item" href="#">DRIVER ONBOARDING</NavLink>
							</div>
						</li>
						<li className="nav-item dropdown disabled-link active" id="admin_menu">
							<a className="nav-link dropdown-toggle" data-toggle="dropdown" href="/#" role="button" aria-haspopup="true" aria-expanded="false">
								<span>Reports</span>
							</a>
							<div className="dropdown-menu">
							<NavLink to="/adminuser/reports" className="dropdown-item" href="#">LOAD SUMMARY</NavLink>
								{/* <NavLink to="/adminuser/tripReg" className="dropdown-item" href="#">TRIP REGISTRATION SUMMARY</NavLink>
								<NavLink to="/adminuser/truckSummary" className="dropdown-item" href="#">TRUCK SUMMARY</NavLink> */}
								{/* <NavLink to="/adminuser/loadSummary" className="dropdown-item" href="#">LOAD SUMMARY</NavLink> */}
								{/* <NavLink to="/adminuser/filesize" className="dropdown-item" href="#">FILE SIZE SUMMARY</NavLink>
								<NavLink to="/adminuser/dbrecords" className="dropdown-item" href="#">DB RECORDS SUMMARY</NavLink>
								<NavLink to="/adminuser/hosreport" className="dropdown-item" href="#">HOS SUMMARY</NavLink> */}
							</div>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/#">
								<span>Driver</span>		        		
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/#">
								<span>Carrier</span>	
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/#">
								<span>Broker</span>	
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/#">
								<span>Shipper</span>	
							</a>
						</li>
					</ul>
				)
		}
	}
}

export default RoleMenu