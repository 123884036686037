import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getcitybystateService, getEldByCariierService, getTrucksByELDSService, getzipbycityService } from '../../../services/loadService';
import { addNewLoad } from '../../../actions/loadActions';
import LoadBulkupload from './Loadbulkupload';
import $ from 'jquery';
import 'jquery-validation';
import { formData } from '../../../helpers/formData';
import { DateTimePicker } from 'react-widgets'
import moment from 'moment';
import { getTimeZones } from '../../../services/commonService';
import LoadInput from './../../uicomponents/LoadInput';
import LoadDropdown from './../../uicomponents/LoadDropdown';
class AddLoadPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shipperName: [],
            Lanedetails: [],
            stateData: [],
            consCity: [],
            consZip: [],
            OriginCity: [],
            OriginZip: [],
            DestinationCity: [],
            DestinationZip: [],
            pickupdrops: [{
                stateData: [],
                cityData: [],
                zipData: [],
                rowData: {
                    pikupcity: '',
                    pikupstate: '',
                    pikuptype: '',
                    pikupzip: '',
                    street: '',
                    contactnumber: '',
                    instruction: '',
                    earliesttime: this.props.initialValue,
                    latesttime: this.props.initialValue
                }
            }],
            loggedUser: {
                FirstName: localStorage.getItem('FirstName')
            }
        }
    }
    componentDidMount() {
        this.loadTimeZone()
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.state !== this.props.state) {
            let stateData = this.props.state.data.map((row) => row);
            let pickupdrops = this.state.pickupdrops;
            pickupdrops[0].stateData = [...stateData];
            this.setState({
                ...this.state,
                stateData: [...stateData],
                pickupdrops: pickupdrops
            });
        }
    }

    submitForm = (e) => {
        $('#myform').validate({
            rules: {
                loadno: {
                    required: true
                },
                noofpickup: {
                    required: true
                },
                noofdropoff: {
                    required: true
                },
                customername: {
                    required: true
                },
                pickaddress1: {
                    required: true
                },
                pickaddress2: {
                    required: true
                },
                originstate: {
                    required: true
                },
                origincity: {
                    required: true
                },
                originzip: {
                    required: true
                },
                pickearliesttime: {
                    required: true
                },
                picklatesttime: {
                    required: true
                },
                picktimezone: {
                    required: true
                },
                picksequence: {
                    required: true
                },
                dropaddress1: {
                    required: true
                },
                dropaddress2: {
                    required: true
                },
                destinationcity: {
                    required: true
                },
                destinationstate: {
                    required: true
                },
                destinationzip: {
                    required: true
                },
                dropearliesttime: {
                    required: true
                },
                droplatesttime: {
                    required: true
                },
                droptimezone: {
                    required: true
                },
                dropsequence: {
                    required: true
                }
            },
            messages: {
                loadno: {
                    required: 'Load Number can not be left blank.!'
                },
                noofpickup: {
                    required: 'No of Pickup can not be left blank.!'
                },
                noofdropoff: {
                    required: 'No of Dropoff can not be left blank.!'
                },
                customername: {
                    required: 'Customer Name can not be left blank.!'
                },
                pickaddress1: {
                    required: 'Address1 can not be left blank.!'
                },
                pickaddress2: {
                    required: 'Address2 can not be left blank.!'
                },
                originstate: {
                    required: 'State can not be left blank.!'
                },
                origincity: {
                    required: 'City Number can not be left blank.!'
                },
                originzip: {
                    required: 'Zip code can not be left blank.!'
                },
                pickearliesttime: {
                    required: 'Earliest time can not be left blank.!'
                },
                picklatesttime: {
                    required: 'Latest time can not be left blank.!'
                },
                picktimezone: {
                    required: 'Time zone can not be left blank.!'
                },
                picksequence: {
                    required: 'Sequence can not be left blank.!'
                },
                dropaddress1: {
                    required: 'Address1 can not be left blank.!'
                },
                dropaddress2: {
                    required: 'Address2 can not be left blank.!'
                },
                destinationcity: {
                    required: 'City can not be left blank.!'
                },
                destinationstate: {
                    required: 'State can not be left blank.!'
                },
                destinationzip: {
                    required: 'Zip code can not be left blank.!'
                },
                dropearliesttime: {
                    required: 'Earliest time can not be left blank.!'
                },
                droplatesttime: {
                    required: 'Latest time can not be left blank.!'
                },
                droptimezone: {
                    required: 'Time zone can not be left blank.!'
                },
                dropsequence: {
                    required: 'Sequence can not be left blank.!'
                }
            }, errorPlacement: function (error, element) {
                if (element.attr('name') === 'pickearliesttime') {
                    error.insertAfter($(element).parents('div.master').find($('.error_place')));
                }
                else if (element.attr('name') === 'picklatesttime') {
                    error.insertAfter($(element).parents('div.master').find($('.error_place')));
                }
                else if (element.attr('name') === 'dropearliesttime') {
                    error.insertAfter($(element).parents('div.master').find($('.error_place')));
                }
                else if (element.attr('name') === 'droplatesttime') {
                    error.insertAfter($(element).parents('div.master').find($('.error_place')));
                }
                else {
                    error.insertAfter(element);
                }
            }
        });
        $('#myform .pikuptype').each(function () {
            $(this).rules('add', {
                required: true,
                messages: {
                    required: 'Required'
                }
            });
        })
        $('#myform .pikupstate').each(function () {
            $(this).rules('add', {
                required: true,
                messages: {
                    required: 'Required'
                }
            });
        })
        let form = document.getElementById('myform');
        let loadData = formData(form);
        if ($('#myform').valid()) {
            //Origin
            var originstate = loadData.originstate;
            var origincity = loadData.origincity;
            var originzip = loadData.originzip;
            //Destination
            var destinationstate = loadData.destinationstate;
            var destinationcity = loadData.destinationcity;
            var destinationzip = loadData.destinationzip;
            const level1 = {};
            //Load Info
            level1.loadNumber = (loadData.loadno) ? (loadData.loadno) : ('');
            level1.bolNumber = (loadData.bolno) ? (loadData.bolno) : ('');
            level1.orederno = (loadData.orederno) ? (loadData.orederno) : ('');
            let loadDate = (loadData.loadDate) ? moment(loadData.loadDate).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]') : ('');
            level1.loadDate = (loadDate) ? (loadDate) : ('');
            level1.rate = (loadData.rate) ? (loadData.rate) : ('');
            level1.weight = (loadData.weight) ? (loadData.weight) : ('');
            level1.distance = (loadData.distance) ? (loadData.distance) : ('');
            level1.noofpickup = (loadData.noofpickup) ? (loadData.noofpickup) : ('');
            let shipperInfo = {};
            shipperInfo.id = (loadData.shipper) ? (loadData.shipper) : ('');
            level1.shipperInfo = shipperInfo;
            level1.noofdropoff = (loadData.noofdropoff) ? (loadData.noofdropoff) : ('');
            level1.customername = (loadData.customername) ? (loadData.customername) : ('');
            let carrierInfo = {};
            carrierInfo.id = (loadData.carrierCompanyId) ? (loadData.carrierCompanyId) : '';
            level1.carrierInfo = carrierInfo;
            level1.eldlist = (loadData.eldlist) ? (loadData.eldlist) : ('');
            let vehicleInfo = {};
            vehicleInfo.id = (loadData.truckno) ? (loadData.truckno) : '';
            vehicleInfo.truckTypeId = (loadData.trucktype) ? (loadData.trucktype) : '';
            vehicleInfo.eldVendor = (loadData.eldlist) ? (loadData.eldlist) : '';
            level1.vehicleInfo = vehicleInfo;
            level1.instruction = (loadData.instruction) ? (loadData.instruction) : ('');
            //pickup info 
            level1.pickaddress1 = (loadData.pickaddress1) ? (loadData.pickaddress1) : ('');
            level1.pickaddress2 = (loadData.pickaddress2) ? (loadData.pickaddress2) : ('');
            level1.orginStateName = (originstate !== 'State') ? (originstate) : ('');
            level1.orginCityName = (origincity !== 'City') ? (origincity) : ('');
            level1.orginZipValue = (originzip !== 'Zip Code') ? (originzip) : ('');
            let pickearliesttime = (loadData.pickearliesttime) ? (moment(loadData.pickearliesttime).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]')) : ('');
            level1.pickearliesttime = (pickearliesttime) ? (pickearliesttime) : ('');
            let picklatesttime = (loadData.picklatesttime) ? (moment(loadData.picklatesttime).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]')) : ('');
            level1.picklatesttime = (picklatesttime) ? (picklatesttime) : ('');
            level1.picktimezone = (loadData.picktimezone !== 'Time Zone') ? (loadData.picktimezone) : ('');
            level1.picksequence = (loadData.picksequence) ? (loadData.picksequence) : ('');
            level1.pickMobileNo = (loadData.pickMobileNo) ? (loadData.pickMobileNo) : ('');
            level1.picklattitude = (loadData.picklattitude) ? (loadData.picklattitude) : ('');
            level1.picklongitude = (loadData.picklongitude) ? (loadData.picklongitude) : ('');
            //dropoff info 
            level1.dropaddress1 = (loadData.dropaddress1) ? (loadData.dropaddress1) : ('');
            level1.dropaddress2 = (loadData.dropaddress2) ? (loadData.dropaddress2) : ('');
            level1.destinationStateName = (destinationstate !== 'State') ? (destinationstate) : ('');
            level1.destinationCityName = (destinationcity !== 'City') ? (destinationcity) : ('');
            level1.destinationZipValue = (destinationzip !== 'Zip Code') ? (destinationzip) : ('');
            let dropearliesttime = (loadData.dropearliesttime) ? (moment(loadData.dropearliesttime).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]')) : ('');
            level1.dropearliesttime = (dropearliesttime) ? (dropearliesttime) : ('');
            let droplatesttime = (loadData.droplatesttime) ? (moment(loadData.droplatesttime).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]')) : ('');
            level1.droplatesttime = (droplatesttime) ? (droplatesttime) : ('');
            level1.droptimezone = (loadData.droptimezone !== 'Time Zone') ? (loadData.droptimezone) : ('');
            level1.dropsequence = (loadData.dropsequence) ? (loadData.dropsequence) : ('');
            level1.dropMobileNo = (loadData.dropMobileNo) ? (loadData.dropMobileNo) : ('');
            level1.droplattitude = (loadData.droplattitude) ? (loadData.droplattitude) : ('');
            level1.droplongitude = (loadData.droplongitude) ? (loadData.droplongitude) : ('');
            //Tender Info
            level1.tamount = (loadData.tamount) ? (loadData.tamount) : ('');
            level1.loadtenderamount = (loadData.loadtenderamount) ? (loadData.tamount) : ('');
            let tenderInfo = {};
            tenderInfo.amount = (loadData.tamount) ? (loadData.tamount) : '';
            tenderInfo.totalBrokerNetAmount = (loadData.loadtenderamount) ? (loadData.loadtenderamount) : '';
            tenderInfo.tenderTime = (loadData.tenderTime) ? (moment(loadData.tenderTime).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]')) : '';
            level1.tenderInfo = tenderInfo;
            //loadPoints  array
            level1.loadPoints = []
            let originCItyPrep = loadData.origincity.split(',');
            let destinationCItyPrep = loadData.destinationcity.split(',');
            let originStatePrep = loadData.originstate.split(',');
            let destinationStatePrep = loadData.destinationstate.split(',');
            let originZipPrep = loadData.originzip.split(',');
            let destinationZipPrep = loadData.destinationzip.split(',');
            //prepare pick up  object
            let pickupObj = {
                typeId: 2,
                address1: level1.pickaddress1,
                address2: level1.pickaddress2,
                city: originCItyPrep[1],
                cityId: originCItyPrep[0],
                state: originStatePrep[1],
                stateId: originStatePrep[0],
                zipId: originZipPrep[0],
                zipCode: originZipPrep[1],
                contactNumber: level1.pickMobileNo,
                earliestTime: level1.pickearliesttime,
                latestTime: level1.picklatesttime,
                sequence: level1.picksequence,
                latitude: level1.picklattitude,
                longitude: level1.picklongitude
            }
            //prepare drop off  object
            let dropoffObj = {
                typeId: 3,
                address1: level1.dropaddress1,
                address2: level1.dropaddress2,
                city: destinationCItyPrep[1],
                cityId: destinationCItyPrep[0],
                state: destinationStatePrep[1],
                stateId: destinationStatePrep[0],
                zipId: destinationZipPrep[0],
                zipCode: destinationZipPrep[1],
                contactNumber: level1.dropMobileNo,
                earliestTime: level1.dropearliesttime,
                latestTime: level1.droplatesttime,
                sequence: level1.dropsequence,
                latitude: level1.droplattitude,
                longitude: level1.droplongitude
            }
            level1.loadPoints.push(pickupObj)
            level1.loadPoints.push(dropoffObj)
            //remove unnecessary keys and preparing final post Body
            let finalPostBody = {
                loadNumber: level1.loadNumber,
                orderNumber: level1.orederno,
                loadDate: level1.loadDate,
                bolNumber: level1.bolNumber,
                rate: level1.rate,
                weight: level1.weight,
                distance: level1.distance,
                numberOfPickup: level1.noofpickup,
                numberOfDropoff: level1.noofdropoff,
                instruction: level1.instruction,
                shipperInfo: level1.shipperInfo,
                carrierInfo: level1.carrierInfo,
                vehicleInfo: level1.vehicleInfo,
                loadPoints: level1.loadPoints,
                tenderInfo: level1.tenderInfo
            }
            console.warn('finalPostBody',finalPostBody)
            this.props.dispatch(addNewLoad(finalPostBody));
        }
    }

    addRow = () => {
        let stateData = this.props.state.data.map((row) => row);
        let pickupdrops = this.state.pickupdrops;
        pickupdrops.push({
            stateData: [...stateData],
            cityData: [],
            zipData: [],
            rowData: {
                pikupcity: '',
                pikupstate: '',
                pikuptype: '',
                pikupzip: '',
                street: '',
                contactnumber: '',
                instruction: '',
                earliesttime: this.props.initialValue,
                latesttime: this.props.initialValue,
            }
        });
        this.setState({
            ...this.state,
            pickupdrops: pickupdrops
        });
    }

    deleteRow = (e, index) => {
        let pickupdrops = this.state.pickupdrops
        pickupdrops.splice(index, 1);
        this.setState({
            ...this.state,
            pickupdrops: pickupdrops
        });
    }

    getStates = (stateData) => {
        return stateData.map((row, index) => {
            return (
                <option key={index} value={row.id}>{row.name}</option>
            );
        });
        return stateData
    }

    getCity = (cityData) => {
        let row = cityData.length > 0 ? (cityData.map(function (row, index) {
            return (
                <option key={index} value={row.cityId}>{row.cityName}</option>
            )
        })) : (<option value=''>Loading...</option>)
        return row;
    }

    getCityloadPoints = (cityData) => {
        let row = cityData.length > 0 ? (cityData.map(function (row, index) {
            return (
                <option key={index} data-value={row.cityId} value={row.cityName}>{row.cityName}</option>
            )
        })) : (<option value=''>Loading...</option>)
        return row;
    }

    loadCity = (e, index) => {
        var concatValfrtruckname = '#pikupstate' + [index];
        let stateId = $(concatValfrtruckname + ' option:selected').attr('data-value');
        let PickUpstateId = $(e.target).val();
        let param = {
            payload: stateId
        }
        getcitybystateService(param).then((response) => {
            if (response) {
                let pickupdrops = this.state.pickupdrops;
                pickupdrops[index].cityData = response;
                pickupdrops[index].rowData.pikupstate = PickUpstateId;
                this.setState({
                    ...this.state,
                    pickupdrops: pickupdrops
                });
            }
        });
    }

    getZiploadPoints = (zipData) => {
        let row = zipData.length > 0 ? (zipData.map(function (row, index) {
            return (
                <option key={index} data-value={row.zipId} value={row.zipValue}>{row.zipValue}</option>
            )
        })) : (<option value=''>Loading...</option>)
        return row;
    }

    getZip = (zipData) => {
        let row = zipData.length > 0 ? (zipData.map(function (row, index) {
            return (
                <option key={index} value={row.zipId}>{row.zipValue}</option>
            )
        })) : (<option value=''>Loading...</option>)
        return row;
    }

    loadZip = (e, index) => {
        var concatValfr = '#pikupcity' + [index];
        let cityId = $(concatValfr + ' option:selected').attr('data-value');
        let PickUpcityId = $(e.target).val();
        let param = {
            payload: cityId
        }
        getzipbycityService(param).then((response) => {
            if (response) {
                let pickupdrops = this.state.pickupdrops;
                pickupdrops[index].zipData = response;
                pickupdrops[index].rowData.pikupcity = PickUpcityId;
                this.setState({
                    ...this.state,
                    pickupdrops: pickupdrops
                });
            }
        });
    }

    pickupTypeChange = (e, index) => {
        let pickupdrops = this.state.pickupdrops;
        pickupdrops[index].rowData.pikuptype = $(e.target).val();
        this.setState({
            ...this.state,
            pickupdrops: pickupdrops
        });
    }

    streetChange = (e, index) => {
        let pickupdrops = this.state.pickupdrops;
        pickupdrops[index].rowData.street = $(e.target).val();
        this.setState({
            ...this.state,
            pickupdrops: pickupdrops
        });
    }

    contactnumberChange = (e, index) => {
        let pickupdrops = this.state.pickupdrops;
        pickupdrops[index].rowData.contactnumber = $(e.target).val();
        this.setState({
            ...this.state,
            pickupdrops: pickupdrops
        });
    }


    earliestTimeChange = (e, index) => {
        let pickupdrops = this.state.pickupdrops;
        var earliesttime = e
        pickupdrops[index].rowData.earliesttime = earliesttime;
        this.setState({
            ...this.state,
            pickupdrops: pickupdrops
        });
    }

    latestTimeChange = (e, index) => {
        let pickupdrops = this.state.pickupdrops;
        var latesttime = e
        pickupdrops[index].rowData.latesttime = latesttime;
        this.setState({
            ...this.state,
            pickupdrops: pickupdrops
        });
    }

    pikupzipChange = (e, index) => {
        //pikupzip
        let pickupdrops = this.state.pickupdrops;
        pickupdrops[index].rowData.pikupzip = $(e.target).val();
        this.setState({
            ...this.state,
            pickupdrops: pickupdrops
        });
    }
    loadOriginCity = (e) => {
        let stateArr = $(e.target).val().split(',')
        let stateId = stateArr[0];
        let param = {
            payload: stateId
        }
        getcitybystateService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    OriginCity: [...response]
                });
            }
        });
    }
    loadEldList = (e) => {
        this.setState({ eldlist: [], trucksNoList: [] })
        let param = {
            payload: e.target.value
        }
        getEldByCariierService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    eldlist: [...response.data],
                    selectedCompany: param.payload
                });
            }
        });
    }
    loadTimeZone() {
        getTimeZones().then((response) => {
            let timeZones = []
            for (let index = 0; index < Object.values(response).length; index++) {
                const element = Object.values(response)[index];
                timeZones.push({ id: index + 1, value: element })
            }
            if (response) {
                this.setState({
                    ...this.state,
                    timeZones: timeZones,
                });
            }
        });
    }

    truckNos = (e) => {
        this.setState({ trucksNoList: [] });
        let param = {
            eldProviderId: e.target.value,
            companyId: this.state.selectedCompany
        }
        getTrucksByELDSService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    trucksNoList: [...response]
                });
            }
        });
    }

    loadOriginZip = (e) => {
        let cityArr = $(e.target).val().split(',')
        let cityId = cityArr[0];
        let param = {
            payload: cityId
        }
        getzipbycityService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    OriginZip: response
                });
            }
        });
    }

    loadDestinationCity = (e) => {
        let stateArr = $(e.target).val().split(',')
        let stateId = stateArr[0];
        let param = {
            payload: stateId
        }
        getcitybystateService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    DestinationCity: [...response]
                });
            }
        });
    }

    loadDestinationZip = (e) => {
        let cityArr = $(e.target).val().split(',')
        let cityId = cityArr[0];
        let param = {
            payload: cityId
        }
        getzipbycityService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    DestinationZip: response
                });
            }
        });
    }

    laneDetails = (event) => {
        var currentTarget = event.currentTarget;
        var indexFinalval = currentTarget.options[currentTarget.selectedIndex].dataset.lanedetailsindex;
        if (typeof indexFinalval === 'undefined') {
            this.setState({
                Lanedetails: []
            })
        }
        else {
            this.setState({
                Lanedetails: this.props.shippernameAndLane[indexFinalval]
            })
        }
    }

    handleClick = () => {
        $('label.error').hide();
        $('.error').removeClass('error');
        $('input[name="loadDate"]').html();
        document.getElementById('myform').reset();
        var popupVal = this.state.pickupdrops;
        var popupVal1 = this.state.pickupdrops;
        popupVal1[0].rowData.street = ''
        popupVal1[0].rowData.contactnumber = ''
        popupVal1[0].rowData.earliesttime = null
        popupVal1[0].rowData.instruction = ''
        popupVal1[0].rowData.latesttime = null
        popupVal1[0].rowData.pikupcity = ''
        popupVal1[0].rowData.pikupstate = ''
        popupVal1[0].rowData.pikuptype = ''
        popupVal1[0].rowData.pikupzip = ''
        popupVal.splice(1, this.state.pickupdrops.length);
        this.setState({
            ...this.state,
            pickupdrops: popupVal1
        });
        this.setState({
            ...this.state,
            pickupdrops: popupVal
        });
    }

    render = () => {
        return (
            <div className='modal fade addLoadModal' data-backdrop='static' data-keyboard='false' tabIndex='-1' role='dialog' aria-labelledby='myLargeModalLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title doc-color' id='exampleModalLabel'>Add Load</h5>
                            <button style={{ display: 'block' }} type='button' className='close' id='addmodalpopup' data-dismiss='modal' onClick={this.handleClick} aria-label='Close'>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                            <button style={{ display: 'none' }} type='button' className='close' id='addmodalpopupbulkuplod' data-dismiss='modal' onClick={this.handleClickbulkupload} aria-label='Close'>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body table-data'>
                            <div className='tab-content' id='addloadshippers'>
                                <div className='tab-pane fade show active' id='addloadshipper' role='tabpanel' aria-labelledby='addloadshipper-tab'>
                                    <form id='myform' >
                                        <h5 className='highlight doc-color'>Load Information</h5>
                                        <div className='row'>
                                            <LoadInput
                                                name={'loadno'}
                                                id='loadno'
                                                placeHolder={'Load Number'}
                                                type={'text'}
                                                class={'form-control json-col requiredfield'}
                                            />
                                            <LoadInput
                                                name={'orederno'}
                                                id='orederno'
                                                placeHolder={'Order Number'}
                                                type={'text'}
                                                class={'form-control json-col'}
                                            />
                                            <div className='col'>
                                                <DateTimePicker
                                                    className='json-col'
                                                    id='LoadDateVal'
                                                    name='loadDate'
                                                    placeholder='Load Date'
                                                    // time={false}
                                                    min={new Date()}
                                                    dropDown
                                                />
                                            </div>
                                            <LoadInput
                                                name={'bolno'}
                                                id='bolno'
                                                placeHolder={'BOL #'}
                                                type={'text'}
                                                class={'form-control json-col'}
                                            />
                                        </div>
                                        <div className='row'>
                                            <LoadInput
                                                name={'rate'}
                                                id='rate'
                                                placeHolder={'Rate($)'}
                                                type={'number'}
                                                class={'form-control json-col'}
                                            />
                                            <LoadInput
                                                name={'weight'}
                                                id='weight'
                                                placeHolder={'Weight(lbs)'}
                                                type={'number'}
                                                class={'form-control json-col'}
                                            />
                                            <LoadInput
                                                name={'distance'}
                                                id={'distance'}
                                                placeHolder={'Distance'}
                                                type={'text'}
                                                class={'form-control json-col'}
                                            />
                                            <LoadInput
                                                name={'noofpickup'}
                                                id={'noofpickup'}
                                                placeHolder={'No Of Pickup'}
                                                type={'number'}
                                                class={'form-control json-col requiredfield'}
                                                value={1}
                                                disabled
                                            />
                                        </div>
                                        <div className='row'>
                                            <LoadInput
                                                name={'noofdropoff'}
                                                id={'noofdropoff'}
                                                placeHolder={'No Of Drop Off'}
                                                type={'number'}
                                                class={'form-control json-col requiredfield'}
                                                value={1}
                                                disabled
                                            />
                                            <LoadDropdown
                                                data={this.props.shippernameAndLane}
                                                placeHolder={'Shipper Name'}
                                                name={'shipper'}
                                                value={'cid'}
                                                id={'shipper'}
                                                onChange={this.laneDetails}
                                                class={'form-control json-col'}
                                            />
                                            <LoadInput
                                                name={'customername'}
                                                id={'customername'}
                                                placeHolder={'Customer Name'}
                                                type={'text'}
                                                class={'form-control json-col requiredfield'}
                                                value={this.state.loggedUser.FirstName}
                                                disabled={true}
                                            />
                                            <LoadDropdown
                                                data={this.props.companyNameListValue !== undefined ? this.props.companyNameListValue.companyList : this.props.companyNameListValue?.companyList}
                                                placeHolder={'Carrier Name'}
                                                name={'carrierCompanyId'}
                                                id={'carrierCompanyId'}
                                                value={'c_id'}
                                                onChange={(e) => { this.loadEldList(e) }}
                                                class={'form-control json-col'}
                                            />
                                        </div>
                                        <div className='row'>
                                            <LoadDropdown
                                                data={this.state.eldlist}
                                                placeHolder={'ELD List'}
                                                name={'eldlist'}
                                                id={'eldlist'}
                                                value={'c_eldlist'}
                                                onChange={(e) => { this.truckNos(e) }}
                                                class={'form-control json-col'}
                                            />
                                            <LoadDropdown
                                                data={this.state.trucksNoList}
                                                placeHolder={'Truck No'}
                                                name={'truckno'}
                                                id={'truckno'}
                                                value={'c_truckno'}
                                                class={'form-control json-col'}
                                            />
                                            <LoadDropdown
                                                data={this.props.trucktypelist.data}
                                                placeHolder={'Truck Type'}
                                                name={'trucktype'}
                                                id={'trucktype'}
                                                value={'ctrucktype'}
                                                class={'form-control json-col'}
                                            />
                                            <div className='col'>
                                                <textarea name='instruction' placeholder='instruction' className='form-control json-col' ></textarea>
                                            </div>
                                        </div>
                                        <h5 className='highlight doc-color'>Pickup Information</h5>
                                        <div className='row'>
                                            <LoadInput
                                                name={'pickaddress1'}
                                                placeHolder={'Address 1'}
                                                type={'text'}
                                                class={'form-control json-col requiredfield'}
                                            />
                                            <LoadInput
                                                name={'pickaddress2'}
                                                placeHolder={'Address 2'}
                                                type={'text'}
                                                class={'form-control json-col requiredfield'}
                                            />
                                            <LoadDropdown
                                                data={this.props.state.data}
                                                placeHolder={'State'}
                                                name={'originstate'}
                                                id={'originstate'}
                                                value={'cState'} //here we pass cstate coz cState has same return type as above
                                                onChange={(e) => { this.loadOriginCity(e) }}
                                                class={'form-control json-col requiredfield'}
                                            />
                                            <LoadDropdown
                                                data={this.state.OriginCity}
                                                placeHolder={'City'}
                                                name={'origincity'}
                                                id={'origincity'}
                                                value={'cCity'}
                                                onChange={(e) => { this.loadOriginZip(e) }}
                                                class={'form-control json-col requiredfield'}
                                            />
                                        </div>
                                        <div className='row'>
                                            <LoadDropdown
                                                data={this.state.OriginZip}
                                                placeHolder={'Zip Code'}
                                                name={'originzip'}
                                                id={'originzip'}
                                                value={'cZip'}
                                                class={'form-control json-col requiredfield'}
                                            />
                                            <div className='col master'>
                                                <DateTimePicker
                                                    className='json-col requiredfield'
                                                    id='pickearliesttime'
                                                    name='pickearliesttime'
                                                    placeholder='Earliest Time'
                                                    min={new Date()}
                                                    dropDown
                                                />
                                                <input hidden class='error_place' value='' />
                                            </div>
                                            <div className='col master'>
                                                <DateTimePicker
                                                    className='json-col requiredfield'
                                                    id='picklatesttime'
                                                    name='picklatesttime'
                                                    placeholder='Latest Time'
                                                    min={new Date()}
                                                    dropDown
                                                />
                                                <input hidden class='error_place' value='' />
                                            </div>
                                            <LoadDropdown
                                                data={this.state.timeZones}
                                                placeHolder={'Time Zone'}
                                                name={'picktimezone'}
                                                id={'picktimezone'}
                                                value={'c_timezone'}
                                                class={'form-control json-col requiredfield'}
                                            />
                                        </div>
                                        <div className='row'>
                                            <LoadInput
                                                name={'picksequence'}
                                                placeHolder={'Sequence'}
                                                type={'number'}
                                                class={'form-control json-col requiredfield'}
                                                value={0}
                                                disabled
                                            />
                                            <LoadInput
                                                name={'pickMobileNo'}
                                                placeHolder={'Contact Number'}
                                                type={'text'}
                                                class={'form-control json-col'}
                                            />
                                            <LoadInput
                                                name={'picklattitude'}
                                                placeHolder={'Lattitude'}
                                                type={'text'}
                                                class={'form-control json-col'}
                                            />
                                            <LoadInput
                                                name={'picklongitude'}
                                                placeHolder={'Longitude'}
                                                type={'text'}
                                                class={'form-control json-col'}
                                            />
                                        </div>
                                        <h5 className='highlight doc-color'>Drop Off Information</h5>
                                        <div className='row'>
                                            <LoadInput
                                                name={'dropaddress1'}
                                                placeHolder={'Address 1'}
                                                type={'text'}
                                                class={'form-control json-col requiredfield'}
                                            />
                                            <LoadInput
                                                name={'dropaddress2'}
                                                placeHolder={'Address 2'}
                                                type={'text'}
                                                class={'form-control json-col requiredfield'}
                                            />
                                            <LoadDropdown
                                                data={this.props.state.data}
                                                placeHolder={'State'}
                                                name={'destinationstate'}
                                                id={'destinationstate'}
                                                value={'cState'} //here we pass cstate coz cState has same return type as above
                                                onChange={(e) => { this.loadDestinationCity(e) }}
                                                class={'form-control json-col requiredfield'}
                                            />
                                            <LoadDropdown
                                                data={this.state.DestinationCity}
                                                placeHolder={'City'}
                                                name={'destinationcity'}
                                                id={'destinationcity'}
                                                value={'cCity'}
                                                onChange={(e) => { this.loadDestinationZip(e) }}
                                                class={'form-control json-col requiredfield'}
                                            />
                                        </div>
                                        <div className='row'>
                                            <LoadDropdown
                                                data={this.state.DestinationZip}
                                                placeHolder={'Zip code'}
                                                name={'destinationzip'}
                                                id={'destinationzip'}
                                                value={'cZip'}
                                                class={'form-control json-col requiredfield'}
                                            />
                                            <div className='col master'>
                                                <DateTimePicker
                                                    className='json-col requiredfield'
                                                    id='dropearliesttime'
                                                    name='dropearliesttime'
                                                    placeholder='Earliest Time'
                                                    min={new Date()}
                                                    dropDown
                                                />
                                                <input hidden class='error_place' value='' />
                                            </div>
                                            <div className='col master'>
                                                <DateTimePicker
                                                    className='json-col requiredfield'
                                                    id='droplatesttime'
                                                    name='droplatesttime'
                                                    placeholder='Latest Time'
                                                    min={new Date()}
                                                    dropDown
                                                />
                                                <input hidden class='error_place' value='' />
                                            </div>
                                            <LoadDropdown
                                                data={this.state.timeZones}
                                                placeHolder={'Time Zone'}
                                                name={'droptimezone'}
                                                id={'droptimezone'}
                                                value={'c_timezone'}
                                                class={'form-control json-col requiredfield'}
                                            />
                                        </div>
                                        <div className='row'>
                                            <LoadInput
                                                name={'dropsequence'}
                                                placeHolder={'Sequence'}
                                                type={'number'}
                                                class={'form-control json-col requiredfield'}
                                                value={0}
                                                disabled
                                            />
                                            <LoadInput
                                                name={'dropMobileNo'}
                                                placeHolder={'Contact Number'}
                                                type={'text'}
                                                class={'form-control json-col'}
                                            />
                                            <LoadInput
                                                name={'droplattitude'}
                                                placeHolder={'Lattitude'}
                                                type={'text'}
                                                class={'form-control json-col'}
                                            />
                                            <LoadInput
                                                name={'droplongitude'}
                                                placeHolder={'Longitude'}
                                                type={'text'}
                                                class={'form-control json-col'}
                                            />
                                        </div>
                                        <h5 className='highlight doc-color'>Tender Information</h5>
                                        <div className='row'>
                                            <LoadInput
                                                name={'tamount'}
                                                placeHolder={'Total Amount'}
                                                type={'number'}
                                                class={'form-control json-col'}
                                            />
                                            <LoadInput
                                                name={'loadtenderamount'}
                                                placeHolder={'Load Tender Amount'}
                                                type={'number'}
                                                class={'form-control json-col'}
                                            />
                                            <div className='col'>
                                                <DateTimePicker
                                                    className='json-col'
                                                    id='tenderTime'
                                                    name='tenderTime'
                                                    placeholder='Tender Date'
                                                    min={new Date()}
                                                    dropDown
                                                />
                                            </div>
                                            <div className='col master'></div>
                                        </div>
                                        <div className='modal-footer'>
                                            <button type='button' className='btn btn-primary' onClick={(e) => { this.submitForm(e) }}>Add Load</button>
                                            <button type='button' className='btn btn-primary' data-dismiss='modal'>Cancel</button>
                                        </div>
                                    </form>
                                </div>
                                <div className='tab-pane fade' id='bulkuploadshipper' role='tabpanel' aria-labelledby='bulkuploadshipper-tab'>
                                    <LoadBulkupload />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        CityVal: state.loadReducer.CityVal,
        ZipVal: state.loadReducer.ZipVal,
        data: state.loadReducer.data,
        loading: state.loadReducer.loading,
        error: state.loadReducer.error,
        companyNameListValue: state.companyReducer.companyNamelist
    }
}

export default connect(mapStateToProps)(AddLoadPopup);