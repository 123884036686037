import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
// import { truckAction } from '../../../actions/trucksActions';
import { assetlist } from '../../../actions/assetAction';
import AddTruckPopup from './AddTruckPopup';
import TrucksList from './TrucksList';
class Trucks extends Component{	
	
	componentDidUpdate()
	{
		if(this.props.data.status==="ALREADY_REPORTED")
        {
		   toastr.info('Info', 'Truck Already Exists!');
			const params={				
				assettype: 'truck',			
			}		
			this.props.dispatch(assetlist(params)); 		
        }
        else if(this.props.data.status==="CREATED")
        {
			toastr.success('Success', 'Truck Created Successfully!');
			const params={				
				assettype: 'truck',			
			}		
			this.props.dispatch(assetlist(params));  
			document.getElementById("addfrm").click(); 
		}
		else if(this.props.data.status==="OK")
        {
			toastr.success('Success', 'Truck Updated Successfully!');
			const params={				
				assettype: 'truck',		
			}		
			this.props.dispatch(assetlist(params));  
			document.getElementById("editfrm").click(); 
		}
		else if(this.props.data.message==="ELD Providers Not Found")
		{
			toastr.info('Info', 'Company Eld Authentication Not Found!!');
			const params={				
				assettype: 'truck',		
			}		
			this.props.dispatch(assetlist(params));  
		}
		else if(this.props.data.message==="Company Invitation Not Found")
		{
			toastr.info('Info', 'Company Invitation Not Found!!');
			const params={				
				assettype: 'truck',		
			}		
			this.props.dispatch(assetlist(params));  
		}
		// else{
			// var element = document.getElementById("truckpopup"); 			
			// element.classList.add("bd-trucks-modal-lg");
		// }
	}

	componentDidMount() {
		document.title = 'Trucks';
	}

	render(){
	    if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}	
		return(	
                  <Fragment>
					<TrucksList truck={this.props.trucktypelist} state={this.props.statelist} fueltypelist={this.props.fueltypelist} eld={this.props.eldproviderlist}/>	
                    <AddTruckPopup trucktypelist={this.props.trucktypelist} state={this.props.statelist} fueltypelist={this.props.fueltypelist} eldproviderlist={this.props.eldproviderlist}/>
					{/* <ViewTruck trucktype={this.props.trucktypelist} eldprovider={this.props.eldproviderlist}  /> */}
                  </Fragment>
		)	
	}
}

const mapStateToProps = state => {
	return {
		data: state.assetReducer.data,
		companyEldlist:state.assetReducer.companyEldlist,
		error: state.assetReducer.error,
	}
}
export default connect(mapStateToProps)(Trucks)
