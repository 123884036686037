import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { Field, reduxForm } from 'redux-form';
import { assetadd } from '../../../actions/assetAction';
import { renderField, renderSelect } from '../../../helpers/FormControls';
import Bulkupload from './Bulkupload';
import { validate } from './validation';



function submit(values,dispatch)
{
  const formObject={'licensePlateNo':'','eldProviderId':'','truckTypeId':'','fuelTypeId':'','color':'','make':'','model':'','modelYear':'','identificationNo':'','plate':'','assetType':'1','companyId':'3','fuelType':'','weight':'','vehicleMileage':'','mileageUnit':''};
  var formData = _.extend({}, formObject, values); 
   this.props.dispatch(assetadd(formData));
}

class AddTruckPopup extends Component{
  componentDidMount()
    {        
        //this.props.dispatch(commonlist());
    }

    handleClick = () =>{
      //this.props.dispatch(reset('addtruckValidation'));
      this.props.initialize('addtruckValidation')
  }

    // componentDidUpdate()
    // {
    //     if(this.props.data.status=="ALREADY_REPORTED")
    //     {
    //       toastr.info('Info', 'Truck Already Exists!');
    //       //window.location.reload();
    //     }
    //     else if(this.props.data.status=="CREATED")
    //     {
    //         toastr.success('Success', 'Truck Created Successfully!');
    //         //window.location.reload();
    //     }
    // }
	
render(){	
	const { handleSubmit, pristine, reset, submitting } = this.props
  // for year
  let minOffset = 0, maxOffset = 20;
  let thisYear = (new Date()).getFullYear();
  let allYears = [];
  for(let x = 0; x <= maxOffset; x++) {
      allYears.push(thisYear - x)
  } 
  //const yearList = allYears.map((x) => {return(<option value={x} key={x}>{x}</option>)});
  const yearList = allYears.map(x=>{       
      return{
          "id":x,
          "name":x,
      }
  }); 
 
	return(
          <div className="modal fade bd-trucks-modal-lg"  data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                 <div className="modal-content">     
                   <div className="modal-header">
                   <h5 className="modal-title doc-color" id="exampleModalLabel">ADD TRUCK</h5>
                    <button type="button" className="close" id="addfrm" data-dismiss="modal" aria-label="Close" onClick={this.handleClick}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                   </div>
                    <div className="modal-body table-data">
                                      <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                                         <li className="nav-item">
                                            <a  className="nav-link active" id="addtruck-tab" data-toggle="tab" href="#addtruck" role="tab" aria-controls="addtruck" aria-selected="true" >
                                            ADD TRUCK
                                            </a>
                                          </li>
                                          <li className="nav-item">
                                            <a className="nav-link " id="bulkupload-tab" data-toggle="tab" href="#bulkupload" role="tab" aria-controls="bulkupload" aria-selected="false" >
                                            BULK UPLOAD
                                            </a>
                                          </li>
                                       </ul>
                                       <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="addtruck" role="tabpanel" aria-labelledby="addtruck-tab">
                                              <form className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}>
                                               <div className="row">                 
                                                  <div className="col col-md-12">
                                                  <h5 className="doc-color">TRUCK INFO</h5>
                                                  <article className="table-data truck-data shadow bg-white rounded">
                                                           <div className="row">
                                                              <div className="col col-md-4">
                                                                 <div className="form-group">
                                                                     <Field type="text" className="form-control"  controlClass="requiredfield" name="assetNumber" id="truck" component={renderField} label="Truck #" />                          
                                                                 </div>
                                                                 <div className="form-group">                                                                     
                                                                     <Field type="text" className="form-control" name="modelYear" id="year" component={renderSelect} data={yearList} label="Year" /> 
                                                                 </div> 
                                                                 <div className="form-group">
                                                                      <Field type="text" className="form-control" name="truckTypeId" id="truck_type" component={renderSelect} data={this.props.trucktypelist.data} label="Truck Type" />                          
                                                                 </div>
                                                              </div>
                                                              <div className="col col-md-4">
                                                              <div className="form-group">
                                                                     <Field type="text" className="form-control" name="make" id="make" component={renderField} label="Make" />                                                        
                                                                 </div>
                                                                 <div className="form-group">
                                                                     <Field type="text" className="form-control" controlClass="requiredfield" name="identificationNo" id="VIN" component={renderField} label="VIN" />                         
                                                                 </div>
                                                                 <div className="form-group">
                                                                      <Field type="text" className="form-control" name="color" id="color" component={renderField} label="Color" />                          
                                                              </div>
                                                              </div>
                                                              <div className="col col-md-4">     
                                                                 <div className="form-group">
                                                                     <Field type="text" className="form-control" name="model" id="model" component={renderField} label="Model" />                                                       
                                                                 </div>
                                                                 <div className="form-group">
                                                                     <Field type="text" className="form-control" name="licensePlateNo" id="plate" component={renderField} label="Plate #" />                                                    
                                                                 </div>
                                                                 <div className="form-group">
                                                                    <Field type="text" className="form-control" name="fuelTypeId" id="fuel_type" component={renderSelect} data={this.props.fueltypelist.FuelTypeList} label="Fuel Type" />                          
                                                                 </div>
                                                              </div>                           
                                                           </div>
                                                           </article>
                                                           <h5>ELD DETAILS</h5>
                                                           <article className="table-data truck-data shadow bg-white rounded">
                                                            <div className="row">
                                                              <div className="col col-md-5">
                                                                 <div className="form-group">
                                                                     <Field type="text" className="form-control" name="eldProviderId" id="provider" component={renderSelect} data={this.props.eldproviderlist.data}  label="ELD Provider" />                         
                                                                 </div>
                                                              </div>
                                                              <div className="col col-md-6">
                                                                 <div className="form-group">
                                                                     <Field type="text" className="form-control"  controlClass="requiredfield" name="deviceId" id="serialno" component={renderField} label="Device Serial #" />                                                        
                                                                 </div>
                                                              </div>
                                                            </div>
                                                             </article>
                                                                 <div className="row">
                                                                 <div className="col col-md-12">
                                                                    <div className="form-group form-group-btns">
                                                                       <button type="submit" className="btn btn-primary btn-sm">Save</button>&nbsp;
                                                                       <button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-primary btn-sm">Reset</button>
                                                                    </div>
                                                                 </div>
                                                              </div>
                                                        </div>
                                                     </div>
                                              </form>                          
                                              </div>
                                              <div className="tab-pane fade" id="bulkupload" role="tabpanel" aria-labelledby="bulkupload-tab">
                                                 <Bulkupload />                                             
                                              </div>
                                        </div>
                              </div>
                         </div>
                    </div>
               </div>
)
}
}
const mapStateToProps = state => {
return {
      data: state.assetReducer.data,
      loading: state.assetReducer.loading,
      error: state.assetReducer.error,
    }
}
export default connect(mapStateToProps) (reduxForm({
    form: 'addtruckValidation',  
    validate                
})(AddTruckPopup))