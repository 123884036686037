import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderSelect } from '../../../helpers/FormControls';
import { validate } from './validation';

function submit(values) {
	window.alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`)
}

class EditLoadPopup extends Component{
    render(){
        const { handleSubmit, pristine, reset, submitting } = this.props
        return(
            <div className="modal fade editLoadModal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title doc-color" id="exampleModalLabel">Edit Load</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body table-data">
                    <ul className="nav nav-tabs" id="myTrucks" role="tablist">
					  	<li className="nav-item">
					    	<a className="nav-link active" id="editload-tab" data-toggle="tab" href="#editload" role="tab" aria-controls="requested" aria-selected="true" >
							Edit Load
					    	</a>
					  	</li>
					  	<li className="nav-item">
					    	<a className="nav-link" id="editbulkupload-tab" data-toggle="tab" href="#editbulkupload" role="tab" aria-controls="editbulkupload" aria-selected="false" >
							Bulk Upload
					    	</a>
					  	</li>
					</ul>
                    <div className="tab-content" id="editloads">
                        <div className="tab-pane fade show active" id="editload" role="tabpanel" aria-labelledby="editload-tab">
                            <form onSubmit={handleSubmit(submit.bind(this))}>
                            <h5 className="highlight doc-color">Load Information</h5>
                            <div className='m-3'>
                                <div className='row'>
                                    <div className="col-md-3">
                                        <Field type="text" name="shippers" component={renderField} label="Shippers"/>
                                    </div>
                                    <div className="col-md-3">
                                        <Field type="text" name="lane" component={renderField} label="Select Lane"/>
                                    </div>
                                    <div className="col-md-3">
                                        <Field type="text" name="loadno" component={renderField} label="Load Number"/>
                                    </div>
                                    <div className="col-md-3">
                                    <Field type="text" name="bol" component={renderField} label="BOL #"/>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-3">
                                        <label htmlFor="Date">Date</label>
                                        <input type="text" className="Date form-control" />
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="weight">Weight(lbs)</label>
                                        <input type="text" className="weight form-control" />
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="loadNumber">Commodity</label>
                                        <input type="text" className="commodity form-control" />
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="PO">PO#</label>
                                        <input type="text" className="PO form-control" />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-3">
                                        <label htmlFor="rate">Rate($)</label>
                                        <input type="text" className="rate form-control" />
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="equipment">Equipment</label>
                                        <input type="text" className="Equipment form-control" />
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="consignee">Consignee</label>
                                        <input type="text" className="consignee form-control" />
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="quantity">Quantity</label>
                                        <input type="text" className="quantity form-control" />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-3">
                                        <label htmlFor="rate">State</label>
                                        <select className="state form-control">
                                            <option value=''>Select State</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="equipment">City</label>
                                        <input type="text" className="city form-control" />
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="consignee">Zip</label>
                                        <input type="text" className="zip form-control" />
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="quantity">Street</label>
                                        <input type="text" className="street form-control" />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-12">
                                        <label htmlFor="instructions">Instructions</label>
                                        <textarea type="text" className="instructions form-control"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="highlight doc-color">Origin</h5>
                                    <div className='m-3'>
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <Field type="text" name="street" component={renderField} label="Street"/>
                                            </div>
                                            <div className="col-md-6">
                                                <Field type="text" name="city" component={renderField} label="City"/>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <Field type="text" name="state" component={renderSelect} label="State"/>
                                            </div>
                                            <div className="col-md-6">
                                                <Field type="text" name="zip" component={renderField} label="Zip"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h5 className="highlight doc-color">Destination</h5>
                                    <div className='m-3'>
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <Field type="text" name="street" component={renderField} label="Street"/>
                                            </div>
                                            <div className="col-md-6">
                                                <Field type="text" name="city" component={renderField} label="City"/>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <Field type="text" name="state" component={renderSelect} label="State"/>
                                            </div>
                                            <div className="col-md-6">
                                                <Field type="text" name="zip" component={renderField} label="Zip"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <h5 className="highlight doc-color">Pickup & Drop Information</h5>
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th width="10%">Pickup Type</th>
                                        <th width="15%">Street Name</th>
                                        <th width="10%">City</th>
                                        <th width="10%">State</th>
                                        <th width="15%">Zip</th>
                                        <th width="15%">Contact No.</th>
                                        <th width="10%">Earliest Time</th>
                                        <th width="10%">Latest Time</th>
                                        <th width="5%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <select className="form-control">
                                                <option value=''>Select</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input type='text' className="form-control"/>
                                        </td>
                                        <td>
                                            <input type='text' className="form-control"/>
                                        </td>
                                        <td>
                                            <select className="form-control">
                                                <option value=''>Select</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input type='text' className="form-control"/>
                                        </td>
                                        <td>
                                            <input type='text' className="form-control"/>
                                        </td>
                                        <td>
                                            <input type='text' className="form-control"/>
                                        </td>
                                        <td>
                                            <input type='text' className="form-control"/>
                                        </td>
                                        <td>
                                            <span className="add_plus">+</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col col-md-12 ">
                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-secondary btn-sm">Save</button>&nbsp;
                                        <a href="#" className="btn btn-primary btn-sm">Reset</a>
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                        <div className="tab-pane fade" id="editbulkupload" role="tabpanel" aria-labelledby="editbulkupload-tab">
                        <form onSubmit={handleSubmit(submit.bind(this))}>
                            <div className="row d-flex justify-content-center m-4">
                                <div className="col-md-6 text-center">
                                    <h3 className="mt-5 mb-5">Import bulk data</h3>
                                    <div className="file-drop-area">
                                        {/* <span className="btn btn-sm btn-primary fake-btn">Select File</span> */}
                                        <span className="file-msg"></span>
                                        <Field type="file" name="attachment" component={renderField} id="importloadfile" className="attachment file-input"/>
                                        {/* <input type="file" name="attachment" value="" id="importloadfile" className="attachment file-input"/> */}
                                    </div>
                                    <div  className="text-right">
                                        <a href="#">Download sample file</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-md-12 ">
                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-secondary btn-sm">Save</button>&nbsp;
                                    <a href="#" className="btn btn-primary btn-sm">Reset</a>
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
	</div>
    )
}
}  
export default reduxForm({
    form: 'editLoadPopupValidation',  
    validate                
  })(EditLoadPopup)