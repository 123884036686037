import * as types from './index';

export const preferredCompanyListByType = (payload) => {
	return {
		type: types.PREFERRED_COMPANY_LIST_BY_TYPE,
		payload: payload
	}
};

export const preferredCompanyList = (payload) => {
	return {
		type: types.PREFERRED_COMPANY_LIST,
		payload: payload
	}
};

export const changePreference = (payload) => {
	return {
		type: types.CHANGE_PREFERRED_REQUEST,
		payload: payload
	}
};

export const carrierSearch= (payload) => {
	return {
		type: types.CARRIER_SEARCH,
		payload: payload
	}
};

export const carrieradd = (payload) => {
	return {
		type: types.CARRIER_ADD,
		payload: payload
	}
};
export const carrierAddFlagAction = () => {
	return {
		type: types.CARRIER_ADD_FLAG_ACTION
	}
};
export const carrierSearchFlagAction = () => {
	return {
		type: types.CARRIER_SEARCH_FLAG_ACTION
	}
};
export const carrierSearchNew= (payload) => {
	return {
		type: types.CARRIER_SEARCH_NEW,
		payload: payload
	}
};
export const makePropsEmpty= (payload) => {
	return {
		type: types.MAKE_PROPS_EMPTY,
		payload: payload
	}
};
export const companyNameListAction = (payload) => {
	return {
		type: types.COMPANY_NAME_LIST,
		payload: payload
	}
};

export const geofenceCompanyNameListAction = (payload) => {
	return {
		type: types.GEOFENCE_COMPANY_NAME_LIST,
		payload: payload
	}
};

export const geofenceCarrierCompanyNameListAction = (payload) => {
	return {
		type: types.GEOFENCE_CARRIER_COMPANY_NAME_LIST,
		payload: payload
	}
};

export const geofenceMailTimeListAction = (payload) => {
	return {
		type: types.GEOFENCE_MAIL_TIME_LIST,
		payload: payload
	}
};

export const geofenceRadiusListAction = (payload) => {
	return {
		type: types.GEOFENCE_RADIUS_LIST,
		payload: payload
	}
};

export const geoFenceRadiusUpdate = (payload) => {
	return {
		type: types.GEOFENCE_RADIUS_STATUS,
		payload: payload
	}
};

export const bulkuploadaction = (payload) => {
    return {
        type: types.CARRIER_BULK_UPLOAD,
        payload: payload
    }
};

export const bulkuploadFlagAction = () => {
    return {
		type: types.CARRIER_BULK_UPLOAD_FlAG,
	}
};

export const carrierListForFactor = (payload) => {
	return {
		type: types.GET_CARRIER_LIST_FACTOR,
		payload: payload
	}
};
export const invitedCompanyList = () => {
	return {
		type: types.GET_INVITED_COMPANY_LIST,
	}
};
export const myCarrierList = () => {
	return {
		type: types.GET_MY_CARRIER_COMPANY_LIST,
	}
};
export const carrierInvitation = (payload) => {
	return {
		type: types.GET_CARRIER_INVITATION,
		payload:payload
	}
};
export const sweepstakesCarrierInvitation = (payload) => {
	return {
		type: types.GET_SWEEPSTAKES_CARRIER_INVITATION,
		payload:payload
	}
};
export const tcWidgetCarrierInvitation = (payload) => {
	return {
		type: types.GET_TC_WIDGET_CARRIER_INVITATION,
		payload:payload
	}
};
export const verifyCredentialsAndSignLOA= (payload) => {
	console.log('payload2',payload)
	return {
		type: types.VERIFY_CREDENTIALS_AND_SIGN_LOA,
		payload: payload
	}
};
export const tcWidgetVerifyCredentialsAndSignLOA= (payload) => {
	console.log('payload',payload)
	return {
		type: types.TC_WIDGET_VERIFY_CREDENTIALS_AND_SIGN_LOA,
		payload: payload
	}
};
export const completeSignUPCarriers= (payload) => {
	return {
		type: types.COMPLETE_SIGNUP_CARRIERS,
		payload: payload
	}
};
//complete widget signup process
export const completeTCWidgetSignUPCarriers= (payload) => {
	return {
		type: types.COMPLETE_TC_WIDGET_SIGNUP_CARRIERS,
		payload: payload
	}
};
//My customer and Invitations
export const invitedCustomerList = () => {
	return {
		type: types.GET_INVITED_CUSTOMER_LIST,
	}
};

export const myCustomerList = () => {
	return {
		type: types.GET_MY_CUSTOMER_LIST,
	}
};
//Enroll customer Opt in /out status
export const myCustomerOptInOutList = (payload) => {
	return {
		type: types.CHANGE_MY_CUSTOMER_OPT_IN_OUT_STATUS_LIST,
		payload: payload
	}
};

export const activeDeliveries = (payload) => {
	return {
		type: types.GET_ACTIVE_DELEVERIES_LIST,
		payload: payload
	}
};
export const pastDeliveries = (payload) => {
	return {
		type: types.GET_PAST_DELEVERIES_LIST,
		payload: payload
	}
};
export const activeFuelAdvances = (payload) => {
	return {
		type: types.GET_ACTIVE_FUEL_ADVANCE_LIST,
		payload: payload
	}
};
export const pastFuelAdvances = (payload) => {
	return {
		type: types.GET_PAST_FUEL_ADVANCE_LIST,
		payload: payload
	}
};
export const UpdateFactors = (payload) => {
	return {
		type: types.UPDATE_FACTOR_BASED_ON_INVOICE,
		payload: payload
	}
};










