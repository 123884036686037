import React, { Component } from 'react';
import Icofont from 'react-icofont';

class HosList extends Component{
    render(){
        return(   
            <article className="table-data truck-data shadow bg-white rounded">
			<div className="row no-gutters">
				<div className="col-md-12">
					<ul className="nav nav-tabs" id="myContacts" role="tablist">
					  	<li className="nav-item">
					    	<a className="nav-link active" id="mycontact-tab" data-toggle="tab" href="#mycontact" role="tab" aria-controls="mycontact" aria-selected="true"><i className="icofont-truck-loaded"></i> Hours Of Service</a>
					  	</li>	
					</ul>
					<div className="tab-content" id="myTabContent">
					  	<div className="tab-pane fade show active" id="mycontact" role="tabpanel" aria-labelledby="mycontact-tab">
                          <table className="table table-border all-table-hos">
                            <thead>
                            <tr>
                                <th rowSpan="2"></th>
                                <th rowSpan="2">Name of the Driver</th>
                                <th colSpan="3" className="status">Status</th>
                                <th colSpan="4" className="time">Time  Remaining</th>
                                <th rowSpan="2" className="violations">Violations</th>
                            </tr>
                            <tr className="hos-duty">
                                <th>Off Duty</th>
                                <th>Driving</th>
                                <th>On Duty</th>

                                <th>8 hrs</th>
                                <th>11 hrs</th>
                                <th>14 hrs</th>
                                <th>70 hrs</th>
                                
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                            <td scope="row"><Icofont className="circledown" icon="circled-down"/></td>
                                  
                                  <td>ROBERTO  LEIVA</td>
                                  <td>0:0</td>                               
                                  <td>0:0</td>
                                  <td>0:0</td>

                                  <td>08:00</td>
                                  <td>11:00</td>
                                  <td>14:0</td>
                                  <td>70:00 </td>

                                  <td>
                                  </td>
                                </tr>
                            </tbody>
                        </table>
					  	</div>
					  	<div className="tab-pane fade" id="newcontact" role="tabpanel" aria-labelledby="newcontact-tab">
					  		
					  	</div>
					</div>
				</div>
			</div>
		</article>
        )
    }
} 
export default HosList


