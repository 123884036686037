import { BrokerRoutes } from './BrokerRoutes';
import { CarrierRoutes } from './CarrierRoutes';
import { ShipperRoutes } from './ShipperRoutes';
import { DemoUserRoutes } from './DemoUserRoutes';
import { TechUserRoutes } from './TechUserRoutes';
import { FactorRoutes } from './FactorRoutes';
const UserRoutes= { ...BrokerRoutes, ...CarrierRoutes, ...ShipperRoutes, ...DemoUserRoutes, ...TechUserRoutes, ...FactorRoutes }

export { 
    UserRoutes 
};