//import useState hook to create menu collapse state
import React from "react";
import { NavLink } from 'react-router-dom';
import { history } from '../../helpers/history'

//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
//import icons from react icons
import { FiHome, FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
//import sidebar css from react-pro-sidebar module and our custom css 
import "react-pro-sidebar/dist/css/styles.css";
import "./Header.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.dataParentToChild,
      menuCollapse: false
    }
  }
  menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    this.props.parentCallback(this.state.menuCollapse);
    this.setState({ menuCollapse: this.state.menuCollapse ? false : true })
    localStorage.setItem('isMenuCollapsed', this.state.menuCollapse)
  };
  redirectToCompanyProfile=()=>{
    history.push("/pages/company_profile");
  }
  render() {
    var userType = this.props.userType;
    var userMenu = this.props.userMenu;
    var menuIcon = localStorage.getItem('menuIcon');
    var mnu;
    if (userMenu) {
      //userMenu =userMenu.substring(1, userMenu.indexOf(']')-1);
      userMenu = userMenu.substring(1, userMenu.indexOf(']'));
      mnu = userMenu.split(",").map(String);
      //if(mnu.length<=1) mnu=null;
    }
   
    const menuList = (mnu) ? (
      mnu.map((menu) => {
        menu = (menu.trim() === 'hos') ? 'hours-of-services' : menu.trim();
        menu = (menu === 'contact') ? 'contacts' : menu;
        menu = (menu === 'document') ? 'documents' : menu;
        menu = (menu === 'assets') ? 'trucks' : menu;
        menu = (menu === 'carrier_onboarding') ? 'carriers' : menu;
        if (userType === 'carrier')
          menu = (menu === 'drivers') ? 'drivers' : menu;
        let menu1 = menu.replaceAll("_", " ");
        return (
          <div key={menu.trim()}>
            {menu.trim() === 'company_profile' ?
              <SubMenu title='My Company' className="subMenucss" defaultOpen={false}>
                {mnu.map((mcheck) => {
                   return <>
                    {mcheck.trim() === 'company_profile' ?
                    <MenuItem className="subMenuItemCss">
                    <NavLink className="nav-link" activeClassName="active" exact={true} to={'/'+userType+'/company_profile'}>Company Config</NavLink>
                    </MenuItem>
                     :mcheck.trim() === 'email_accounts' ?
                      <MenuItem className="subMenuItemCss">
                      <NavLink className="nav-link " activeClassName="active" exact={true} to={'/'+userType+'/email_accounts'}>Email Accounts</NavLink>
                      </MenuItem>  
                     :mcheck.trim() === 'email_templates' ?
                        <MenuItem className="subMenuItemCss">
                        <NavLink className="nav-link " activeClassName="active" exact={true} to={'/'+userType+'/email_templates'}>Email Templates</NavLink>
                       </MenuItem>
                       :mcheck.trim() === 'users' ?
                       <MenuItem className="subMenuItemCss">
                       <NavLink className="nav-link" activeClassName="active" exact={true} to={'/'+userType+'/users'}>Users</NavLink>
                       </MenuItem>:null
                }
                    </>
                })}
              </SubMenu>
              :
              menu.trim() ==='email_accounts' || menu.trim() ==='email_templates'|| menu.trim() === 'users' ?
               null :  <MenuItem className="nav-item" key={menu}>
                 <NavLink className="nav-link sidemenuTextCss" activeClassName="active"  to={'/'+userType+'/'+menu.trim()}> {menu1.toUpperCase()}</NavLink>
             </MenuItem>
            }
          </div>
        )
      })
    ) : (
      <span></span>
    )
    return (
      <div id="header1">
        {/* collapsed props to change menu size using menucollapse state */}
        <ProSidebar collapsed={this.state.menuCollapse}>
          <SidebarHeader>
            <div className="closemenu" onClick={this.menuIconClick}>
              {/* changing menu collapse icon on click */}
              {this.state.menuCollapse ? (
                <FiArrowRightCircle />

              ) : (
                <FiArrowLeftCircle />
              )}
            </div>
          </SidebarHeader>
          {!this.state.menuCollapse ?
            <SidebarContent>
              <Menu iconShape="square">
                {menuList}
              </Menu>
            </SidebarContent> : null}
        </ProSidebar>
      </div>
    );
  };
}

export default Header;