import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { Field, FieldArray, initialize, reduxForm } from 'redux-form';
import { addNewLoad, getcitybystate, getzipbycity } from '../../../actions/loadActions';
import { renderField, renderSelect } from '../../../helpers/FormControls';
import { formData } from '../../../helpers/formData';
import normalizePhone from '../../../helpers/normalizePhone';
import LoadBulkupload from './Loadbulkupload';
import { validate } from './validation';

function addnew(values) {
    document.getElementById('add_new').click();
}

const renderDateTimePicker = ({ input: { onChange, value }, showTime, input, label, controlClass, labelnotavailabe, data, disable, type, meta: { touched, error } }) =>
    <div className={`form-group ${(touched && error) ? 'error' : ''}`}>
        {(labelnotavailabe) ? '' : <label>{label}</label>}
        <div>
            <DateTimePicker dropDown={true}
                {...input}
                className={`form-group ${(controlClass) ? controlClass : ''}`}
                onChange={onChange}
                min={new Date()}
                type={type}
                text={`${(data) ? data : ''}`}
                format='MM-DD-YYYY h:mm:ss A'
                time={true}
                value={!value ? null : new Date(value)}
            />
            {touched && (error && <span className='error'>{error}</span>)}
        </div>
    </div>

const renderDatePicker = ({ input: { onChange, value }, showTime, input, label, controlClass, labelnotavailabe, data, disable, type, meta: { touched, error } }) =>
    <div className={`form-group ${(touched && error) ? 'error' : ''}`}>
        {(labelnotavailabe) ? '' : <label>{label}</label>}
        <div>
            <DateTimePicker dropDown={true}
                {...input}
                className={`form-group ${(controlClass) ? controlClass : ''}`}
                onChange={onChange}
                min={new Date()}
                type={type}
                text={`${(data) ? data : ''}`}
                format='MM-DD-YYYY'
                placeholder='Date'
                time={false}
                value={!value ? null : new Date(value)}
            />
            {touched && (error && <span className='error'>{error}</span>)}
        </div>
    </div>


function submit(values) {
    const level1 = {};
    level1['loadPointsData'] = [];
    level1.shipperCompanyId = (values.shippers) ? (values.shippers) : ('');
    level1.lane = (values.lane) ? (values.lane) : ('');
    level1.loadNumber = (values.loadNumber) ? (values.loadNumber) : ('');
    level1.bolNumber = (values.bolNumber) ? (values.bolNumber) : ('');
    var loadDate = (values.loadDate) ? (moment(values.loadDate).format('YYYY-MM-DD')) : ('');
    level1.loadDate = (loadDate) ? (loadDate) : ('');
    level1.weight = (values.weight) ? (values.weight) : ('');
    level1.commodity = (values.commodity) ? (values.commodity) : ('');
    level1.poNumber = (values.poNumber) ? (values.poNumber) : ('');
    level1.rate = (values.rate) ? (values.rate) : ('');
    level1.truckTypeId = (values.equipment) ? (values.equipment) : ('');
    level1.consigneeName = (values.consigneeName) ? (values.consigneeName) : ('');
    level1.quantity = (values.quantity) ? (values.quantity) : ('');
    level1.consigneeStateId = (values.consigneeStateId) ? (values.consigneeStateId) : ('');
    level1.consigneeCityId = (values.consigneeCity) ? (values.consigneeCity) : ('');
    level1.consigneeZipId = (values.consigneeZip) ? (values.consigneeZip) : ('');
    level1.consigneeAddress1 = (values.consigneeAddress1) ? (values.consigneeAddress1) : ('');
    level1.loadComments = (values.loadComments) ? (values.loadComments) : ('');
    level1.originAddress1 = (values.originAddress1) ? (values.originAddress1) : ('');
    level1.originCityId = (values.originCity) ? (values.originCity) : ('');
    level1.originZipId = (values.originZip) ? (values.originZip) : ('');
    level1.originStateId = (values.originStateId) ? (values.originStateId) : ('');
    level1.destinationAddress1 = (values.destinationAddress1) ? (values.destinationAddress1) : ('');
    level1.destinationCityId = (values.destinationCity) ? (values.destinationCity) : ('');
    level1.destinationZipId = (values.destinationZip) ? (values.destinationZip) : ('');
    level1.destinationStateId = (values.destinationStateId) ? (values.destinationStateId) : ('');
    level1.fuelAdvance = (values.fuelAdvance) ? (values.fuelAdvance) : ('');
    level1.fuelAdvanceCommission = (values.fuelAdvanceCommission) ? (values.fuelAdvanceCommission) : ('');
    level1.estimatedBrokerNet = (values.estimatedBrokerNet) ? (values.estimatedBrokerNet) : ('');
    level1.totalBrokerNet = (values.totalBrokerNet) ? (values.totalBrokerNet) : ('');
    level1.instructions = (values.instructions) ? (values.instructions) : ('');
    var data = formData(document.querySelector('#loadPointsDatapickupdropforaddload'));
    if (Array.isArray(data.loadPointsData)) {
        data = data.loadPointsData
    }
    else {
        data = [data.loadPointsData];
    }
    var result = data.map(singleData => {
        var earliestTime = (singleData['members.earliestTime']) ? (moment(singleData['members.earliestTime']).format('YYYY-MM-DD hh:mm:ss')) : ('');
        var latestTime = (singleData['members.latestTime']) ? (moment(singleData['members.latestTime']).format('YYYY-MM-DD hh:mm:ss')) : ('');
        return {
            'loadPointTypeId': singleData['members.load_point_type_id'],
            'loadPointSequence': '',
            'contactNumber': singleData['members.contactNumber'],
            'contactAddress1': singleData['members.contactName'],
            'contactCityId': singleData['members.city'],
            'contactStateId': singleData['members.states'],
            'contactZipId': singleData['members.zip'],
            'earliestTime': earliestTime,
            'latestTime': latestTime,
            'instructions': singleData['members.instructions']
        }
    });
    level1['loadPointsData'] = result;
    this.props.dispatch(addNewLoad(level1));
}
class renderMembers extends Component {
    iconChange = (index, fields) => {
        const { handleSubmit } = this.props;
        if (index === fields.length - 1) {
            return (
                <i className='icofont-plus-circle add_plus pl-3' onClick={handleSubmit(addnew.bind(this))}></i>
            )
        }
        else {
            return (
                <i className='icofont-ui-delete add_plus pl-3' id='iclassdeletehiddenfield'
                    onClick={(e) => { fields.remove(index); }} ></i>
            )
        }
    }

    stateOnchange = (event) => {
        this.props.dispatch(getcitybystate(event.target.value));
    }

    cityOnchange = (event) => {
        this.props.dispatch(getzipbycity(event.target.value));
    }

    render() {
        const { fields, meta: { } } = this.props;
        // const { handleSubmit, pristine, reset, submitting } = this.props;
        return (
            <div className='addDocumentData'>
                <button type='button' id='add_new' onClick={() => fields.length < 4 ? fields.push({}) : ''} hidden>
                    Add Member
                </button>
                {
                    fields.map((member, index) => (
                        <tr className='filled3 filled3-last loadPointsData json-row' id='loadPointsValuesTableRow' jsoncol='loadPointsData'
                            key={index}>
                            <td>
                                <Field controlClass='json-col level2' type='text' className='form-control' name={`${member}.load_point_type_id`} component={renderSelectload} data={this.props.loadpoint.data} label='pickup type' />
                            </td>
                            <td>
                                <Field controlClass='json-col level2' type='text' name={`${member}.contactName`} component={renderField} />
                            </td>
                            <td>
                                <Field controlClass='json-col level2' type='text' className='form-control' component={renderSelect}  labelnotavailabe='labelnotavailabe' name={`${member}.states`} label='state' data={this.props.states.data} onChange={this.stateOnchange} />
                            </td>
                            <td>
                                <Field controlClass='json-col level2' type='text' className='form-control' name={`${member}.city`} labelnotavailabe='labelnotavailabe' label='city' keyColumn='cityId' keyValue='cityName' onChange={this.cityOnchange} component={renderSelect} data={this.props.CityVal} />
                            </td>
                            <td>
                                <Field controlClass='json-col level2' type='text' className='form-control' component={renderSelect} data={this.props.ZipVal} label='Zip' keyColumn='zipId' keyValue='zipValue' labelnotavailabe='labelnotavailabe' name={`${member}.zip`} />
                            </td>
                            <td>
                                <Field controlClass='json-col level2' type='text' normalize={normalizePhone} name={`${member}.contactNumber`} component={renderField} />
                            </td>
                            <td>
                                <Field controlClass='json-col level2' type='text' name={`${member}.earliestTime`} defaultValue={null} component={renderDateTimePicker} />
                            </td>
                            <td>
                                <Field controlClass='json-col level2' type='text' name={`${member}.latestTime`} defaultValue={null} component={renderDateTimePicker} />
                            </td>
                            <td>
                                <Field controlClass='json-col level2' type='text' name={`${member}.instructions`} component={renderField} />
                            </td>
                            <td>
                                <br></br>
                                {
                                    this.iconChange(index, fields)
                                }
                            </td>
                        </tr>
                    ))
                }
            </div>
        );
    }
}

class AddLoadPopup extends Component {
    laneDetails = (event) => {
        var currentTarget = event.currentTarget;
        var indexFinalval = currentTarget.options[currentTarget.selectedIndex].dataset.lanedetailsindex;

        if (typeof indexFinalval === 'undefined') {
            this.setState({
                Lanedetails: []
            })
        }
        else {
            this.setState({
                Lanedetails: this.props.shippernameAndLane[indexFinalval]
            })
        }

    }
    lanedetailsChange = (event) => {
        var currentTarget = event.currentTarget;
        var sourcecity = currentTarget.options[currentTarget.selectedIndex].dataset.sourcecity;
        var destinationcity = currentTarget.options[currentTarget.selectedIndex].dataset.destinationcity;
        if (typeof currentTarget.options[currentTarget.selectedIndex].dataset.sourcecity === 'undefined') {
            this.props.change('originCity', null);
        } else {
            this.props.change('originCity', currentTarget.options[currentTarget.selectedIndex].dataset.sourcecity);
        }

        if (typeof currentTarget.options[currentTarget.selectedIndex].dataset.destinationcity === 'undefined') {
            this.props.change('destinationCity', null);
        } else {
            this.props.change('destinationCity', currentTarget.options[currentTarget.selectedIndex].dataset.destinationcity);
        }

        if (typeof currentTarget.options[currentTarget.selectedIndex].dataset.sourceaddress === 'undefined') {
            this.props.change('originAddress1', null);
        } else {
            this.props.change('originAddress1', currentTarget.options[currentTarget.selectedIndex].dataset.sourceaddress);
        }

        if (typeof currentTarget.options[currentTarget.selectedIndex].dataset.destinationaddress === 'undefined') {
            this.props.change('destinationAddress1', null);
        } else {
            this.props.change('destinationAddress1', currentTarget.options[currentTarget.selectedIndex].dataset.destinationaddress);
        }

        if (typeof currentTarget.options[currentTarget.selectedIndex].dataset.originstate === 'undefined') {
            this.props.change('originStateId', null);
        } else {
            this.props.change('originStateId', currentTarget.options[currentTarget.selectedIndex].dataset.originstate);
        }

        if (typeof currentTarget.options[currentTarget.selectedIndex].dataset.destinationstate === 'undefined') {
            this.props.change('destinationStateId', null);
        } else {
            this.props.change('destinationStateId', currentTarget.options[currentTarget.selectedIndex].dataset.destinationstate);
        }

        if (typeof currentTarget.options[currentTarget.selectedIndex].dataset.originzip === 'undefined') {
            this.props.change('originZip', null);
        } else {
            this.props.change('originZip', currentTarget.options[currentTarget.selectedIndex].dataset.originzip);
        }

        if (typeof currentTarget.options[currentTarget.selectedIndex].dataset.destinationzip === 'undefined') {
            this.props.change('destinationZip', null);
        } else {
            this.props.change('destinationZip', currentTarget.options[currentTarget.selectedIndex].dataset.destinationzip);
        }
    }

    resetloadpopup = () => {
        this.props.dispatch(initialize('addLoadPopupValidation'));
        document.getElementById('add_new').click();

    }

    stateOnchange = (event) => {
        this.props.dispatch(getcitybystate(event.target.value));
    }

    handleClick = () => {
        this.props.dispatch(initialize('addLoadPopupValidation'));
        document.getElementById('add_new').click();
    }

    handleClickbulkupload = () => {
        this.props.dispatch(initialize('loadbulkupload'));
    }

    cityOnchange = (event) => {
        this.props.dispatch(getzipbycity(event.target.value));
    }

    componentDidMount() {
        document.getElementById('add_new').click();
    }

    state = {
        Lanedetails: [],
    }

    clickeventaddload = (e) => {
        var element = document.getElementById('addmodalpopup');
        element.style.display = 'block'
         element = document.getElementById('addmodalpopupbulkuplod');
        element.style.display = 'none'
    }

    clickevent = (e) => {
        var element = document.getElementById('addmodalpopup');
        element.style.display = 'none'
         element = document.getElementById('addmodalpopupbulkuplod');
        element.style.display = 'block'
    }
    render() {
        const { handleSubmit } = this.props;
        const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
        return (
            <div className='modal fade addLoadModal' data-backdrop='static' data-keyboard='false' tabIndex='-1' role='dialog' aria-labelledby='myLargeModalLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title doc-color' id='exampleModalLabel'>Add Load</h5>
                            <button style={{ display: 'block' }} type='button' className='close' id='addmodalpopup' data-dismiss='modal' onClick={this.handleClick} aria-label='Close'>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                            <button style={{ display: 'none' }} type='button' className='close' id='addmodalpopupbulkuplod' data-dismiss='modal' onClick={this.handleClickbulkupload} aria-label='Close'>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body table-data'>
                            <ul className='nav nav-tabs' id='myTrucks' role='tablist'>
                                <li className='nav-item'>
                                    <a className='nav-link active' onClick={this.clickeventaddload} id='addloadtabclick' data-toggle='tab' href='#addloadshipper' role='tab' aria-controls='requested' aria-selected='true' >
                                        Add Load
                                    </a>
                                </li>
                            </ul>
                            <div className='tab-content' id='addloadshippers'>
                                <div className='tab-pane fade show active' id='addloadshipper' role='tabpanel' aria-labelledby='addloadshipper-tab'>
                                    <form onSubmit={handleSubmit(submit.bind(this))} id='addloadpopupfrm'>
                                        <input type='hidden' className='form-control level1' name='Asset_Type_Id' id='Asset_Type_Id' value='' />
                                        <input type='hidden' className='form-control level1' name='consigneeContactNumber' id='consigneeContactNumber' />
                                        <input type='hidden' className='form-control level1' name='consigneeAddress2' id='consigneeAddress2' />
                                        <input type='hidden' className='form-control level1' name='loadPointSequence' id='loadPointSequence' />
                                        <h5 className='highlight doc-color'>Load Information</h5>
                                        <div className='m-3'>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='shippers' component={renderSelect} label='Shippers' data={this.props.shippernameAndLane} onChange={this.laneDetails} keyColumn='companyId' keyValue='companyName' dataattr8='laneTable' controlClass='level1' />
                                                </div>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='lane' component={renderSelect} data={this.state.Lanedetails.laneTable} label='Select Lane'
                                                        keyColumn='laneId' keyValue='selectLane' dataattr9='sourceCityId' dataattr10='destinationCityId' dataattr11='sourceAddress' dataattr12='destinationAddress'
                                                        dataattr13='sourceStateId' dataattr14='destinationStateId' dataattr15='sourceZipId' dataattr16='destinationZipId'
                                                        onChange={this.lanedetailsChange} controlClass='level1' />
                                                </div>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='loadNumber' component={renderField} label='Load Number' controlClass='level1 requiredfield' />
                                                </div>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='bolNumber' component={renderField} label='BOL #' controlClass='level1' />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <Field controlClass='level1 requiredfield' type='text' name='loadDate' defaultValue={null} label='Date' component={renderDatePicker} />
                                                </div>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='weight' component={renderField} label='Weight(lbs)' controlClass='level1 requiredfield' />
                                                </div>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='commodity' component={renderField} label='Commodity' controlClass='level1' />
                                                </div>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='poNumber' component={renderField} label='PO#' controlClass='level1' />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='rate' component={renderField}
                                                        label='Rate($)' controlClass='level1 requiredfield' />
                                                </div>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='equipment' data={this.props.trucktypelist.data} component={renderSelect} label='Equipment' controlClass='level1' />
                                                </div>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='consigneeName' component={renderField} label='Consignee' controlClass='level1' />
                                                </div>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='quantity' component={renderField} label='Quantity' controlClass='level1' />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='consigneeStateId' component={renderSelect} label='State' onChange={this.stateOnchange} data={this.props.state.data} controlClass='level1' />
                                                </div>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='consigneeCity' component={renderSelect}
                                                        data={this.props.CityVal} keyColumn='cityId' keyValue='cityName' label='City' onChange={this.cityOnchange} controlClass='level1' />
                                                </div>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='consigneeZip' component={renderSelect} data={this.props.ZipVal} label='Zip' keyColumn='zipId' keyValue='zipValue' controlClass='level1' />
                                                </div>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='consigneeAddress1' component={renderField} label='Street' controlClass='level1' />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='fuelAdvance' component={renderField} label='Fuel Advance' controlClass='level1' />
                                                </div>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='fuelAdvanceCommission' component={renderField} label='Fuel Advance Commission' controlClass='level1' />
                                                </div>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='estimatedBrokerNet' component={renderField} label='Broker Net' controlClass='level1' />
                                                </div>
                                                <div className='col-md-3'>
                                                    <Field type='text' name='totalBrokerNet' component={renderField} label='Total Broker Net' controlClass='level1' />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <Field name='carriers' label='Carriers' data={this.props.carriernamelist} component={renderSelect} />
                                                </div>
                                                <div className='col-md-9'>
                                                    <Field name='instructions' component={renderTextArea} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <h5 className='highlight doc-color'>Origin</h5>
                                                <div className='m-3'>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <Field type='text' name='originAddress1' component={renderField} label='Street' controlClass='level1' />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <Field type='text' name='originCity' component={renderSelect} keyColumn='cityId' onChange={this.cityOnchange} keyValue='cityName' data={this.props.CityVal} label='City' controlClass='level1' />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <Field type='text' name='originStateId' component={renderSelect} label='State' onChange={this.stateOnchange} controlClass='level1' data={this.props.state.data} />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <Field type='text' name='originZip' component={renderSelect} data={this.props.ZipVal} keyColumn='zipId' keyValue='zipValue' label='Zip' controlClass='level1' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <h5 className='highlight doc-color'>Destination</h5>
                                                <div className='m-3'>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <Field type='text' name='destinationAddress1' component={renderField} label='Street' controlClass='level1' />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <Field type='text' name='destinationCity' keyColumn='cityId' keyValue='cityName' onChange={this.cityOnchange} component={renderSelect} data={this.props.CityVal} label='City' controlClass='level1' />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <Field type='text' name='destinationStateId' component={renderSelect} label='State' controlClass='level1' onChange={this.stateOnchange} data={this.props.state.data} />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <Field type='text' name='destinationZip' data={this.props.ZipVal} keyColumn='zipId' keyValue='zipValue' component={renderSelect} label='Zip' controlClass='level1' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <h5 className='highlight doc-color'>Pickup & Drop Information</h5>
                                        <table className='table table-sm'>
                                            <thead>
                                                <tr>
                                                    <th width='10%'>Pickup Type</th>
                                                    <th width='10%' id='streetnameloadpoints'>Street Name</th>
                                                    <th width='10%' id='statenameloadpoints'>State</th>
                                                    <th width='10%' id='citynameloadpoints'>City</th>
                                                    <th width='10%' id='zipcodeloadpoints'>Zip</th>
                                                    <th width='13%'>  Contact Num  </th>
                                                    <th width='10%' > Earliest Time  </th>
                                                    <th width='10%' id='latestimeloadpoints'>  Latest Time  </th>
                                                    <th width='10%' id='instructionloadpoints'>  Instruction </th>
                                                    <th width='10%' id='actionloadpoints'>  Action </th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <table>
                                            <tbody id='loadPointsDatapickupdropforaddload'>
                                                <FieldArray name='members' component={renderMembers} loadpoint={this.props.loadpoint} states={this.props.state} props={this.props} />
                                            </tbody>
                                        </table>
                                        <div className='row'>
                                            <div className='col col-md-12 '>
                                                <div className='modal-footer'>
                                                    <button type='submit' className='btn btn-primary btn-sm'>Save</button>&nbsp;
                                                    <button type='button' className='btn btn-primary btn-sm float-right' data-dismiss='modal'>Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className='tab-pane fade' id='bulkuploadshipper' role='tabpanel' aria-labelledby='bulkuploadshipper-tab'>
                                    <LoadBulkupload />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const renderSelectload = ({ input, data, controlClass, keyColumn, keyValue, label, meta: { touched, error } }) => (
    <div className={`form-group ${(touched && error) ? 'error' : ''}`}>
        <label></label>
        <select className={`form-control ${(controlClass) ? controlClass : ''}`} {...input} placeholder={label}>
            <option value=''>{'Select ' + label}</option>
            {
                Array.isArray(data) ? data.map(option =>
                    <option
                        value={option.id}
                        key={option.id}>{option.name}
                    </option>) : ''
            }
        </select>
        {touched && error && <span className='error'>{error}</span>}
    </div>
);

const renderTextArea = ({ input, meta: { touched, error, warning } }) => (
    <div>
        <label>Instructions</label>
        <textarea {...input} className='form-control level2' ></textarea>
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
);

const mapStateToProps = state => {
    return {
        CityVal: state.loadReducer.CityVal,
        ZipVal: state.loadReducer.ZipVal,
        data: state.loadReducer.data,
        loading: state.loadReducer.loading,
        error: state.loadReducer.error
    }
}

export default connect(mapStateToProps)(reduxForm({ form: 'addLoadPopupValidation', validate })(AddLoadPopup))