import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { Field, reduxForm } from 'redux-form';
import { AddContact } from '../../../actions/contactActions';
import { renderField } from '../../../helpers/FormControls';
import { validate } from './ContactsValidation';
function submit(values) {
  const formObject={'firstName':'','lastName':'','company':'','phoneNum':'','emailId':'','notes':''}
  var formData = _.extend({}, formObject, values);
  this.props.dispatch(AddContact(formData));
}
class ContactsNew extends Component{
   componentDidUpdate()
   {
      if(this.props.data.status==="CREATED")
      {              
          toastr.success('Success', 'Contact has been Successfully Created!');
          setTimeout(function(){
          window.location.reload();
        }, 2000);
      }
      else if(this.props.data.status==="ALREADY_REPORTED")
      {
          toastr.error('Error', 'Contact Already Exists!');
          setTimeout(function(){
          window.location.reload();
        }, 2000);
      }
   }
   render(){
      const { handleSubmit } = this.props
      return(
         <div className="modal fade addContactModal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md">
              <form  className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}>
               <div className="modal-content">
                  <div className="modal-header">
                  <h5 className="modal-title doc-color" id="exampleModalLabel">ADD CONTACT</h5>
                     <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                     </button>
                  </div>
                  <div className="modal-body">
                     <div>
                        <div className="row">
                           <div className="col-md-6">
                              <Field type="text" name="firstName" className="form-control" id="first_name" component={renderField}  label="First Name" />
                           </div>
                           <div className="col-md-6">
                              <Field type="text" name="lastName" className="form-control" id="last_name" component={renderField}  label="Last Name" />
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-md-6">
                             <Field type="text" name="emailId" className="form-control" id="email" component={renderField}  label="Email" />
                           </div>
                           <div className="col-md-6">
                             {/* <label>Phone</label>*/}
                             {/*<div className="row">
                                    <div className="col col-md-2">
                                        <div className="mob-prefix">
                                            <input type="text" name="primaryCountryPhoneCode" maxlength="1" size="1" value="+1" className="text-required"/>
                                        </div>
                                    </div>
                                    <div className="col col-md-10">
                                        <input type="text" className="form-control" name="primaryPhone" id="primaryPhone" />
                                    </div>
                                </div>*/}
                                <Field type="text" className="form-control" component={renderField} name="phoneNum" id="primary_phone" label="Primary Phone" />
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-md-6">
                              <Field type="text" name="company" className="form-control" id="company" component={renderField}  label="Company" />
                           </div>
                           <div className="col-md-6">
                              <Field type="text" name="notes" className="form-control" id="notes" component={renderField}  label="Notes" />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="modal-footer">
                     <button type="submit" className="btn btn-primary btn-sm">Save</button>
                     <button data-dismiss="modal" className="btn btn-primary btn-sm">Reset</button>
                  </div>
                     
               </div>
               </form>
            </div>
         </div>
         )
   }
}

const mapStateToProps=(state)=>
{
   return{
      loading : state.contactReducer.loading,
      data : state.contactReducer.data
    }
}

export default connect(mapStateToProps)(reduxForm({
   form:'ContactsNewForm',validate
})(ContactsNew))