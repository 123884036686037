import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
class lanehistory extends Component {   
    render(){   
        var cancelledloads = [];
        if(this.props.data.LaneHistory)
        {
            cancelledloads = []	;
            cancelledloads = this.props.data.LaneHistory.map(
                (row,index) =>{
                    return({
                        id:row.index,
                        loadNumber:row.loadNo,
                        laneHistoryLoadDate:row.laneHistoryLoadDate,
                        laneHistoryLoadweight:row.laneHistoryLoadweight,
                        laneHistoryCompanyName:row.laneHistoryCompanyName,
                        laneHistoryPhoneNumber:row.laneHistoryPhoneNumber,
                        laneHistoryCompanyEmail:row.laneHistoryCompanyEmail,
                        laneHistoryLoadPointName:row.laneHistoryLoadPointName,
                        laneHistoryAverageTender:row.laneHistoryAverageTender,
                        laneHistoryAverageMargin:row.laneHistoryAverageMargin
                    });
                }
            );
            
        }
        else
        {
            cancelledloads = [];				
        }
        const { SearchBar } = Search;
        const columns=[	
           {
               text:'Load #',
               dataField:'loadNumber',
               sort:true
           },
           {
               text:'Date',
               dataField:'laneHistoryLoadDate',
               sort:true
           },
           {
               text:'Weight',
               dataField:'laneHistoryLoadweight',
               sort:true
           },
           {
               text:'Carrier Name',
               dataField:'laneHistoryCompanyName',
               sort:true
           },
           {
               text:'Carrier Contact Number	',
               dataField:'laneHistoryPhoneNumber',
               sort:true
           },
           {
               text:'Carrier Email	',
               dataField:'laneHistoryCompanyEmail',
               sort:true
           },
           {
               text:'Status',
               dataField:'laneHistoryLoadPointName',
               sort:true
           },
           {
               text:'Average Tender	',
               dataField:'laneHistoryAverageTender',
               sort:true
           },
           {
               text:'Average Margin',
               dataField:'laneHistoryAverageMargin',
               sort:true
           }
        ]
	
      const customTotal = (from, to, size) => (
       <span className="react-bootstrap-table-pagination-total">
         Showing { from } to { to } of { size } Results
       </span>
     );
     
     const options = {
       paginationSize: 4,
       pageStartIndex: 1,      
       firstPageText: 'First',
       prePageText: 'Back',
       nextPageText: 'Next',
       lastPageText: 'Last',
       nextPageTitle: 'First page',
       prePageTitle: 'Pre page',
       firstPageTitle: 'Next page',
       lastPageTitle: 'Last page',
       showTotal: true,
       paginationTotalRenderer: customTotal,
     };
    return (
        <div className="modal fade lanehistorymodal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title doc-color" id="exampleModalLabel">Lane History</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body table-data">
                <ToolkitProvider
									keyField="id"
									data={ cancelledloads }
									columns={ columns }
									search
								   >
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication="No Data Found"  hover bordered={ false }
										/>
										</div>
									)
									}
								</ToolkitProvider>
                 
                </div>
               
            </div>
        </div>
       </div>
      )
    }
}
  const mapStateToProps = state => {
	return {
		data: state.loadReducer.data,
	    loading: state.loadReducer.loading,
		error: state.loadReducer.error,
	}
}
export default connect(mapStateToProps)(lanehistory);