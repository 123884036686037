import { TypoGraph, Container ,Button} from '../../muiComponents'
import styles from './header.css'
import React  from 'react';
// import { makeStyles } from '@material-ui/core/styles';


const HeaderBar = (props) => {
  const { title, primaryBtnProps, secondaryBtnProps } = props
  console.log('props',props)
  return (
    <Container className='headerContainer'>
      <Container>
        <TypoGraph variant="h1" mb={0} sx={{fontSize:'24px'}}>
          {title ? title : '  '}
        </TypoGraph>
      </Container>
      <Container className='headerAction'>
        {secondaryBtnProps && (
          <Button variant="outlined" {...secondaryBtnProps} />
        )}
        {primaryBtnProps && <Button variant="contained" {...primaryBtnProps} />}
      </Container>
    </Container>
  )
}

export default HeaderBar
