import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getcitybystateService, getEldByCariierService, getTrucksByELDSService, getzipbycityService } from '../../services/loadService';
import $ from 'jquery';
import 'jquery-validation';
import { DateTimePicker } from 'react-widgets'
import moment from 'moment';
import LoadDropdown from '../uicomponents/LoadDropdown';
import LoadInput from '../uicomponents/LoadInput';
import { addNewDelivery } from '../../actions/loadActions';
import { formData } from '../../helpers/formData';
class CreateDeliveryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateData: [],
            pickupcity: [],
            pickupzip: [],
            deliverycity: [],
            deliveryzip: [],
            loggedUser: {
                FirstName: localStorage.getItem('userName')
            }
        }
    }
    submitForm = (e) => {
        $('#myform').validate({
            rules: {
                invoiceno: {
                    required: true
                },
                carrierCompanyId: {
                    required: true
                },
                eldlist: {
                    required: true
                },
                assetno: {
                    required: true
                },
                pickupstate: {
                    required: true
                },
                pickupcity: {
                    required: true
                },
                pickupzip: {
                    required: true
                },
                schedpudatetime: {
                    required: true
                },
                deliverycity: {
                    required: true
                },
                deliverystate: {
                    required: true
                },
                deliveryzip: {
                    required: true
                },
                scheddudatetime: {
                    required: true
                },
            },
            messages: {
                invoiceno: {
                    required: 'Invoice No can not be left blank.!'
                },
                carrierCompanyId: {
                    required: 'Carrier Name can not be left blank.!'
                },
                eldlist: {
                    required: 'ELD List can not be left blank.!'
                },
                assetno: {
                    required: 'Asset No can not be left blank.!'
                },
                pickupstate: {
                    required: 'Pickup State can not be left blank.!'
                },
                pickupcity: {
                    required: 'Pickup City can not be left blank.!'
                },
                pickupzip: {
                    required: 'Pickup Zip code can not be left blank.!'
                },
                schedpudatetime: {
                    required: 'Scheduled Pickup Date & time can not be left blank.!'
                },
                deliverycity: {
                    required: 'Delivery City can not be left blank.!'
                },
                deliverystate: {
                    required: 'Delivery State can not be left blank.!'
                },
                deliveryzip: {
                    required: 'Delivery Zip code can not be left blank.!'
                },
                scheddudatetime: {
                    required: 'Scheduled Delivery Date & time can not be left blank.!'
                }
            }, errorPlacement: function (error, element) {
                if (element.attr('name') === 'schedpudatetime') {
                    error.insertAfter($(element).parents('div.master').find($('.error_place')));
                }
                else if (element.attr('name') === 'scheddudatetime') {
                    error.insertAfter($(element).parents('div.master').find($('.error_place')));
                }
                else {
                    error.insertAfter(element);
                }
            }
        });
        let form = document.getElementById('myform');
        let deliveryData = formData(form);
        if ($('#myform').valid()) {
            // prepare this.state,city and zip by separating name and IDs
            let pickupstatePrep = deliveryData.pickupstate.split(',');
            let pickupcityPrep = deliveryData.pickupcity.split(',');
            let pickupzipPrep = deliveryData.pickupzip.split(',');
            let deliverystatePrep = deliveryData.deliverystate.split(',');
            let deliverycityPrep = deliveryData.deliverycity.split(',');
            let deliveryzipPrep = deliveryData.deliveryzip.split(',');
            //prepare pickup  object
            let pickupObj = {
                address1: deliveryData.pickupaddress,
                address2: deliveryData.pickupaddress,
                city: pickupcityPrep[1],
                cityId: pickupcityPrep[0],
                state: pickupstatePrep[1],
                stateId: pickupstatePrep[0],
                zipId: pickupzipPrep[0],
                zipcode: pickupzipPrep[1],
                pickupTime: (moment(deliveryData.schedpudatetime).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'))
            }
            //prepare delivery  object
            let deliveryObj = {
                address1: deliveryData.deliveryaddress,
                address2: deliveryData.deliveryaddress,
                city: deliverycityPrep[1],
                cityId: deliverycityPrep[0],
                state: deliverystatePrep[1],
                stateId: deliverystatePrep[0],
                zipId: deliveryzipPrep[0],
                zipcode: deliveryzipPrep[1],
                dropTime: (moment(deliveryData.scheddudatetime).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]')),
            }
            //remove unnecessary keys and preparing final post Body
            let finalPostBody = {
                carrierInfo: {
                    carrierCode: '',
                    codeType: '',
                    carrierId: deliveryData.carrierCompanyId
                },
                vehicleInfo: {
                    eldVendor: deliveryData.eldlist,
                    vehicleIds: [
                        {
                            vehicleIdType: 'assetId',
                            vehicleId: deliveryData.assetno
                        }
                    ]
                },
                factoringInfo: {
                    confirmationType: 'deliveryConfirmation',
                    invoiceNumber: deliveryData.invoiceno.trim(),
                    bolNumber: deliveryData.bolno.trim()
                },
                pickupInfo: pickupObj,
                dropInfo: deliveryObj
            }
            this.props.dispatch(addNewDelivery(finalPostBody));
        }
    }
    loadpickupcity = (e) => {
        let stateArr = $(e.target).val().split(',')
        let stateId = stateArr[0];
        let param = {
            payload: stateId
        }
        getcitybystateService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    pickupcity: [...response]
                });
            }
        });
    }
    loadEldList = (e) => {
        this.setState({ eldlist: [], trucksNoList: [] })
        let param = {
            payload: e.target.value
        }
        getEldByCariierService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    eldlist: [...response.data],
                    selectedCompany: param.payload
                });
            }
        });
    }
    assetnos = (e) => {
        this.setState({ trucksNoList: [] });
        let param = {
            eldProviderId: e.target.value,
            companyId: this.state.selectedCompany
        }
        getTrucksByELDSService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    trucksNoList: [...response]
                });
            }
        });
    }
    loadpickupzip = (e) => {
        let cityArr = $(e.target).val().split(',')
        let cityId = cityArr[0];
        let param = {
            payload: cityId
        }
        getzipbycityService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    pickupzip: response
                });
            }
        });
    }
    loaddeliverycity = (e) => {
        let stateArr = $(e.target).val().split(',')
        let stateId = stateArr[0];
        let param = {
            payload: stateId
        }
        getcitybystateService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    deliverycity: [...response]
                });
            }
        });
    }
    loaddeliveryzip = (e) => {
        let cityArr = $(e.target).val().split(',')
        let cityId = cityArr[0];
        let param = {
            payload: cityId
        }
        getzipbycityService(param).then((response) => {
            if (response) {
                this.setState({
                    ...this.state,
                    deliveryzip: response
                });
            }
        });
    }
    handleClick = () => {
        $('label.error').hide();
        $('.error').removeClass('error');
        $("input[name='loadDate']").html();
        document.getElementById('myform').reset();
    }

    render = () => {
        return (
            <div className='modal fade createDeliveryModal' data-backdrop='static' data-keyboard='false' tabIndex='-1' role='dialog' aria-labelledby='myLargeModalLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title doc-color' id='exampleModalLabel'>Create Delivery</h5>
                            <button style={{ display: 'block' }} type='button' className='close' id='addDeliveryModalpopup' data-dismiss='modal' onClick={this.handleClick} aria-label='Close'>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body table-data'>
                            <div className='tab-content' id='addDelivery'>
                                <div className='tab-pane fade show active' id='addDelivery' role='tabpanel' aria-labelledby='addDelivery-tab'>
                                    <form id='myform' >
                                        <h5 className='highlight doc-color'>Delivery Information</h5>
                                        <div className='row'>
                                            <LoadInput
                                                name={'invoiceno'}
                                                id='invoiceno'
                                                maxlength='20'
                                                placeHolder={'Invoice No#'}
                                                type={'text'}
                                                class={'form-control json-col requiredfield'}
                                            />
                                            <LoadDropdown
                                                data={this.props.companyNameListValue !== undefined ? this.props.companyNameListValue.companyList : this.props.companyNameListValue?.companyList}
                                                placeHolder={'Carrier Name'}
                                                name={'carrierCompanyId'}
                                                id={'carrierCompanyId'}
                                                value={'c_id'}
                                                onChange={(e) => { this.loadEldList(e) }}
                                                class={'form-control json-col requiredfield'}
                                            />
                                            <LoadDropdown
                                                data={this.state.eldlist}
                                                placeHolder={'ELD List'}
                                                name={'eldlist'}
                                                id={'eldlist'}
                                                value={'c_eldlist'}
                                                onChange={(e) => { this.assetnos(e) }}
                                                class={'form-control json-col requiredfield'}
                                            />
                                            <LoadDropdown
                                                data={this.state.trucksNoList}
                                                placeHolder={'Asset No'}
                                                name={'assetno'}
                                                id={'assetno'}
                                                value={'c_truckno'}
                                                class={'form-control json-col requiredfield'}
                                            />
                                        </div>
                                        <div className='row'>
                                            <LoadInput
                                                name={'bolno'}
                                                maxlength='20'
                                                id='bolno'
                                                placeHolder={'BOL #'}
                                                type={'text'}
                                                class={'form-control json-col'}
                                            />
                                            <div className='col master'></div>
                                            <div className='col master'></div>
                                            <div className='col master'></div>
                                        </div>
                                        <h5 className='highlight doc-color'>Pickup Location</h5>
                                        <div className='row'>
                                            <LoadInput
                                                maxlength='30'
                                                name={'pickupaddress'}
                                                placeHolder={'Address'}
                                                type={'text'}
                                                class={'form-control json-col'}
                                            />
                                            <LoadDropdown
                                                data={this.props.state.data}
                                                placeHolder={'State'}
                                                name={'pickupstate'}
                                                id={'pickupstate'}
                                                value={'cState'} //here we pass cstate coz cState has same return type as above
                                                onChange={(e) => { this.loadpickupcity(e) }}
                                                class={'form-control json-col requiredfield'}
                                            />
                                            <LoadDropdown
                                                data={this.state.pickupcity}
                                                placeHolder={'City'}
                                                name={'pickupcity'}
                                                id={'pickupcity'}
                                                value={'cCity'}
                                                onChange={(e) => { this.loadpickupzip(e) }}
                                                class={'form-control json-col requiredfield'}
                                            />
                                            <LoadDropdown
                                                data={this.state.pickupzip}
                                                placeHolder={'Zip Code'}
                                                name={'pickupzip'}
                                                id={'pickupzip'}
                                                value={'cZip'}
                                                class={'form-control json-col requiredfield'}
                                            />
                                        </div>
                                        <div className='row'>
                                            <div className='col master col-md-3'>
                                                <DateTimePicker
                                                    className='json-col requiredfield'
                                                    id='schedpudatetime'
                                                    name='schedpudatetime'
                                                    placeholder='Scheduled Pickup Date & time'
                                                    // min={new Date()}
                                                    dropDown
                                                />
                                                <input hidden class='error_place' value='' />
                                            </div>
                                        </div><br />
                                        <h5 className='highlight doc-color'>Delivery Location</h5>
                                        <div className='row'>
                                            <LoadInput
                                                name={'deliveryaddress'}
                                                maxlength='30'
                                                placeHolder={'Address'}
                                                type={'text'}
                                                class={'form-control json-col'}
                                            />
                                            <LoadDropdown
                                                data={this.props.state.data}
                                                placeHolder={'State'}
                                                name={'deliverystate'}
                                                id={'deliverystate'}
                                                value={'cState'} //here we pass cstate coz cState has same return type as above
                                                onChange={(e) => { this.loaddeliverycity(e) }}
                                                class={'form-control json-col requiredfield'}
                                            />
                                            <LoadDropdown
                                                data={this.state.deliverycity}
                                                placeHolder={'City'}
                                                name={'deliverycity'}
                                                id={'deliverycity'}
                                                value={'cCity'}
                                                onChange={(e) => { this.loaddeliveryzip(e) }}
                                                class={'form-control json-col requiredfield'}
                                            />
                                            <LoadDropdown
                                                data={this.state.deliveryzip}
                                                placeHolder={'Zip code'}
                                                name={'deliveryzip'}
                                                id={'deliveryzip'}
                                                value={'cZip'}
                                                class={'form-control json-col requiredfield'}
                                            />
                                        </div>
                                        <div className='row'>
                                            <div className='col master col-md-3'>
                                                <DateTimePicker
                                                    className='json-col requiredfield'
                                                    id='scheddudatetime'
                                                    name='scheddudatetime'
                                                    placeholder='Scheduled Delivery Date & time'
                                                    // min={new Date()}
                                                    dropDown
                                                />
                                                <input hidden class='error_place' value='' />
                                            </div>
                                        </div>
                                        <br />
                                        <div className='modal-footer'>
                                            <button type='button' className='btn btn-primary' onClick={(e) => { this.submitForm(e) }}>Create Delivery</button>
                                            <button type='button' className='btn btn-primary' data-dismiss='modal'>Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        CityVal: state.loadReducer.CityVal,
        ZipVal: state.loadReducer.ZipVal,
        data: state.loadReducer.data,
        loading: state.loadReducer.loading,
        error: state.loadReducer.error,
        companyNameListValue: state.companyReducer.companyNamelist
    }
}

export default connect(mapStateToProps)(CreateDeliveryModal);