import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { assetlist } from '../../../actions/assetAction';
import AddTrailerPopup from './AddTrailerPopup';
// import EditTrailerPopup from './EditTrailerPopup';
import TrailerList from './TrailerList';

class Trailers extends Component{ 
	componentDidUpdate()
	{
		if(this.props.data.status==="ALREADY_REPORTED")
        {
		   toastr.info('Info', 'Trailer Already Exists!');
			const params={				
				assettype: 'trailer',
				//usertype: 'carrier'			
			}		
			this.props.dispatch(assetlist(params)); 		
        }
        else if(this.props.data.status==="CREATED")
        {
			toastr.success('Success', 'Trailer Created Successfully!');
			const params={				
				assettype: 'trailer',		
			}		
			this.props.dispatch(assetlist(params));  
			document.getElementById("traileraddfrm").click(); 
		}
		else if(this.props.data.status==="OK")
        {
			toastr.success('Success', 'Trailer Updated Successfully!');
			const params={				
				assettype: 'trailer',	
			}		
			this.props.dispatch(assetlist(params));  
			document.getElementById("trailereditfrm").click(); 
		}
		else if(this.props.data.message==="ELD Providers Not Found")
		{
			toastr.info('Info', 'Company Eld Authentication Not Found!!');
			const params={				
				assettype: 'trailer',		
			}		
			this.props.dispatch(assetlist(params));  
		}
		else if(this.props.data.message==="Company Invitation Not Found")
		{
			toastr.info('Info', 'Company Invitation Not Found!!');
			const params={				
				assettype: 'trailer',		
			}		
			this.props.dispatch(assetlist(params));  
		}
		
	}

	componentDidMount() {
		document.title = 'Trailers';
	}
	render(){
		if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}
		return(			
			<Fragment>							
				<TrailerList truck={this.props.trucktypelist} state={this.props.statelist} fueltypelist={this.props.fueltypelist} eld={this.props.eldproviderlist}/>
				<AddTrailerPopup trucktypelist={this.props.trucktypelist} state={this.props.statelist} fueltypelist={this.props.fueltypelist} eldproviderlist={this.props.eldproviderlist}/>				
			</Fragment>	
		)
	}
}

const mapStateToProps = state => {
	return {
	data: state.assetReducer.data,
	error: state.assetReducer.error,
	}
}
export default connect(mapStateToProps)(Trailers)