import { authHeader } from '../helpers/authHeader';
import {checkUnauthorized} from '../helpers/appUtil';
require('dotenv').config();

export const contactListService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_LOAD_STATUS_API_ENDPOINT = BASE_URL+'contactresource/contacts/contactlist';
    return fetch(UPDATE_LOAD_STATUS_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {   
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const addContactService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const ADD_CONTACT_API_ENDPOINT = BASE_URL+'contactresource/contacts/addcontact'
    return fetch(ADD_CONTACT_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {    
        checkUnauthorized(responseData.status, responseData.message);        
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}


export const editContactService = (request) => {    
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_CONTACT_API_ENDPOINT = BASE_URL+'contactresource/contacts/updatecontact'; 
    return fetch(UPDATE_CONTACT_API_ENDPOINT, {
        method: 'POST',
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);          
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};

export const deleteContactService = (request) => {    
    const BASE_URL= process.env.REACT_APP_BASE_URL; 
    const UPDATE_CONTACT_API_ENDPOINT = BASE_URL+'contactresource/contacts/delete?contactId='+request.payload
    return fetch(UPDATE_CONTACT_API_ENDPOINT, {
        method: 'POST',
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {       
        checkUnauthorized(responseData.status, responseData.message);     
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};

