import * as types from './index';

export const loadAction = (payload) => {
	return {
		  type: types.GET_LOAD_DATA,
		  payload: payload
	}
};

export const shipperLoadAction = (payload) => {
	return {
		  type: types.GET_SHIPPER_LOAD_DATA,
		  payload: payload
	}
};

export const loadActionfrcancelled = (payload) => {
	return {
		  type: types.GET_LOAD_DATA_CANCEL,
		  payload: payload
	}
};
export const loadActionRestore = (payload) => {
	return {
		  type: types.GET_LOAD_DATA_RESTORE,
		  payload: payload
	}
};

export const loadActionfrquickdispatch = (payload) => {
	return {
		  type: types.GET_LOAD_DATA_QUICKDISPATCH,
		  payload: payload
	}
};

export const addNewLoad = (payload) => {
	return {
		type: types.POST_NEW_LOAD_DATA,
		payload: payload
	}
};
export const updateLoadAct = (payload) => {
	return {
		type: types.POST_EDIT_LOAD_DATA,
		payload: payload
	}
};
export const updateLoadStatus = (payload) => {
	return {
		type: types.UPDATE_LOAD_STATUS,
		payload: payload
	}
};
export const ChangeLoadStatus = (payload) => {
	return {
		type: types.CHANGE_LOAD_STATUS,
		payload: payload
	}
};

export const dispatchflag = (payload) => {
	return {
		type: types.CHANGE_LOAD_STATUS_FlAG
	}
};

export const savePod = (payload) => {
	return {
		type: types.SAVE_POD_LOAD,
		payload: payload
	}
};
export const TenderDetails = (payload) => {
	return {
		type: types.TENDER_LOAD,
		payload: payload
	}
};
export const updateTenderPopup = (payload) => {
	return {
		type: types.UPDATE_TENDER_LOAD,
		payload: payload
	}
};

export const companyListByType = (payload) => {
	return {
		type: types.COMPANY_LIST_BY_TYPE,
		payload: payload
	}
};

export const getAssetOrder = (payload) => {
	return {
		type: types.GET_ASSET_ORDER,
		payload: payload
	}
};

export const truckNoAndLocation = () => {
	return {
		type: types.TRUCK_NO_AND_LOCATION
	}
};

export const getDriverListByCompany = () => {
	return {
		type: types.DRIVER_BY_COMPANY
	}
};

export const getLoadAccessioral = (payload) => {
	return {
		type: types.LOAD_ACCESSIORAL,
		payload: payload
	}
};

export const saveassetorder = (payload) => {
	return {
		type: types.SAVE_ASSET_ORDER,
		payload: payload
	}
};

export const quickDispatchAction = (payload) => {
	return {
		type: types.SAVE_QUICK_DISPATCH,
		payload: payload
	}
};

export const borkerQuickDispatchAction = (payload) => {
	return {
		type: types.SAVE_BROKER_QUICK_DISPATCH,
		payload: payload
	}
};

export const shipperandlanedetails = (payload) => {
	return {
		type: types.SHIPPER_AND_LANE_DETAILS,
		payload: payload
	}
};

export const getcitybystate = (payload) => {
	return {
		type: types.GET_CITY_BY_STATE,
		payload: payload
	}
};

export const getzipbycity = (payload) => {
	return {
		type: types.GET_ZIP_BY_CITY,
		payload: payload
	}
};

export const saveLoadPostOrder = (payload) => {
	return {
		type: types.SAVE_LOAD_POST_ORDER_DISPATCH,
		payload: payload
	}
};

export const bulkuploadaction = (payload) => {
    return {
        type: types.LOAD_BULK_UPLOAD,
        payload: payload
    }
};

export const updateTenderStatusAction = (payload) => {
    return {
        type: types.TENDER_CHANGE_LOAD_STATUS,
        payload: payload
    }
};

export const editloadvalues = (payload) => {
    return {
        type: types.EDIT_LOAD_VALUES,
        payload: payload
    }
};

export const updateload = (payload) => {
    return {
        type: types.UPDATE_LOAD,
        payload: payload
    }
};
export const lanedetails = (payload) => {
    return {
        type: types.LANE_DETAILS_LIST,
        payload: payload
    }
};
export const quickdispatchFlagAction = () => {
    return {
		type: types.QUICK_DISPATCH_LOAD_ACTION,
	}
};

export const quickdispatchbrokerFlagAction = () => {
    return {
		type: types.QUICK_DISPATCH_BROKER_LOAD_ACTION,
	}
};

export const loadpostflagaction = () => {
    return {
		type: types.LOAD_POST_ORDER_FlAG,
	}
};

export const loadstatusupdateflagaction = () => {
    return {
		type: types.UPDATE_STATUS_LOAD_FlAG,
	}
};
export const bulkuploadFlagAction = () => {
    return {
		type: types.BULK_UPLOAD_LOAD_FlAG,
	}
};

export const loadListActionfrdispatch = (payload) => {
	return {
		  type: types.GET_LOAD_DATA_DISPATCH,
		  payload: payload
	}
};
export const deletePropsData = () => {
	return({
	   type: types.DELETE_PROPS_DATA,
	   payload: {}
	})
 }
export const loadListActionDelivery = (payload) => {
	return {
		  type: types.GET_LOAD_DATA_DELIVERY,
		  payload: payload
	}
};

export const QuickDispatch = (payload) => {
	return {
		  type: types.QUICK_DISPATCH,
		  payload: payload
	}
};

export const ChkAuthToken = (payload) => {
	return {
		  type: types.CHK_AUTH_TOKEN,
		  payload: payload
	}
};

export const authtokenflag = (payload) => {
	return {
		  type: types.CHK_AUTH_TOKEN_fLAG,
		  payload: payload
	}
};

export const ChkvalidAuthToken = (payload) => {
	return {
		  type: types.CHK_AUTH_TOKEN_VALID,
		  payload: payload
	}
};

export const cancelledloadsbyloadid = (payload) => {
	console.log('payload',payload)
	return {
		  type: types.CANCELLED_LOADS_BYLOADID,
		  payload: payload
	}
};

export const notyetdispatchededitdrivertruck = (payload) => {
	return {
		  type: types.NOTYETDISPATCHED_EDITDRIVERTRUCK,
		  payload: payload
	}
};

export const canceledloadflag = () => {
	return {
		type: types.CANCELLED_LOAD_fLAG
	}
};

export const LoadAddFlag = () => {
	return {
		type: types.LOAD_ADD_FLAG
	}
};

export const onchangetruck = (payload) => {
	return {
		type: types.ONCHANGE_TRUCK,
		payload: payload
	}
};


export const onchangedriver = (payload) => {
	return {
		type: types.ONCHANGE_DRIVER,
		payload: payload
	}
};

export const QuickDispatchfrintellitrans = (payload) => {
	return {
		type: types.QUICK_DISPATCH_INTELLITRANS,
		payload: payload
	}
};

export const brokerTender = (payload) => {
	return {
		type: types.BROKER_TENDER,
		payload: payload
	}
};
export const brokerTenderFlag = (payload) => {
	return {
		type: types.BROKER_TENDER_FLAG,
	}
};
//Factor Action for creating new delivery
export const addNewDelivery = (payload) => {
	return {
		type: types.POST_NEW_DELIVERY_DATA,
		payload: payload
	}
}
//Factor Action for fuel Advance
export const addFuelAdvance = (payload) => {
	return {
		type: types.POST_NEW_FUEL_ADVANCE_DATA,
		payload: payload
	}
}






















