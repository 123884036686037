import React from 'react';
import { styled } from '@mui/material/styles';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { TypoGraph } from '../../muiComponents'
import Icon from '../../muiComponents/icon'
import SidePanel from '../../components/onboarding/SidePanel';
const style = {
  listText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    fontSize: '15px !important',
    lineHeight: '22px',
    color: '#666666',
    marginLeft: '14px'
  },
  listData: {
    marginLeft: '1rem',
    width: '320px',
    whiteSpace: 'normal',
    height: '57px'
  }
}
const DrawerHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

const MenuListItem = (props) => {
  const { data } = props
  return (
    <List className={'menuList'}>
      <ListItem key={data.invitationData.contactInfo.firstName+" "+data.invitationData.contactInfo.lastName} sx={style.listData}>
        <ListItemIcon
          sx={{
            minWidth: 0,
            justifyContent: 'center',
          }}
        >
          <Icon name='contact' width={25} height={25} />&ensp;
        </ListItemIcon>
        <TypoGraph
          variant="body1"
          mb={0}
        >
          {data.invitationData.contactInfo.firstName}&ensp; {data.invitationData.contactInfo.lastName}
        </TypoGraph>
      </ListItem>
      <ListItem key={data.invitationData.carrierInfo.carrierName} sx={style.listData} >
        <ListItemIcon
          sx={{
            minWidth: 0,
            justifyContent: 'center',
          }}
        >
          <Icon name='carriers' width={25} height={25} />&ensp;&ensp;
        </ListItemIcon>
        <TypoGraph
          variant="body1"
          mb={0}
          sx={{
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '500 !important',
            fontSize: '15px !important',
            lineHeight: '22px',
            color: '#666666'
          }}
        >
          {data.invitationData.carrierInfo.carrierName}
        </TypoGraph>
      </ListItem>
      <ListItem key={data.invitationData.contactInfo.email} sx={style.listData} >
        <ListItemIcon
          sx={{
            minWidth: 0,
            justifyContent: 'center',
          }}
        >
          <Icon name='mail' width={25} height={25} />&ensp;&ensp;
        </ListItemIcon>
        <TypoGraph
          variant="body1"
          mb={0}
          sx={{
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '500 !important',
            fontSize: '15px !important',
            lineHeight: '22px',
            color: '#666666'
          }}
        >
          {data.invitationData.contactInfo.email}
        </TypoGraph>
      </ListItem>
      {data.invitationData.carrierInfo.carrierCodes.map((item) => (
        <ListItem key={item.codeType+"-"+item.codeValue} sx={style.listData}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              justifyContent: 'center',
            }}
          >
            <Icon name={'hash'} width={25} height={25} />&ensp;&ensp;
          </ListItemIcon>
          <TypoGraph
            variant="body1"
            mb={0}
            sx={{
              fontFamily: 'Poppins',
              fontStyle: 'normal',
              fontWeight: '500 !important',
              fontSize: '15px !important',
              lineHeight: '22px',
              color: '#666666'
            }}
          >
            {item.codeType}-{item.codeValue}
          </TypoGraph>
        </ListItem>
      ))}
    </List>
  )
}
//main header component
function EldDideDrawer(props) {
  return (
    <SidePanel
      open={props.open}
      toggleDrawer={props.toggleDrawer}
    >
      <DrawerHeader sx={{ display:'flex', justifyContent:'flex-end', marginTop: '1rem' }}>
        <div className='eldboxClassName'>
          <img id='trade-logo' src={props.passingData} />
        </div>
      </DrawerHeader>
      <MenuListItem sx={{ marginLeft: '1rem' }}
        data={props.state}
      />
      <List sx={{ position: 'fixed', bottom: '2rem', zIndex: 'auto'}}>
        <ListItem key={'Help'} sx={{ marginLeft: '1rem' ,height:'57px'}}>
          <ListItemIcon>
            <Icon
              name={'help'}
              width={25}
              height={25}
            />
          </ListItemIcon>
          <ListItemText>
            <TypoGraph
              mb={0}
              sx={style.listText}
            >
              {'Help'}
            </TypoGraph>
          </ListItemText>
        </ListItem>
      </List>
    </SidePanel>

  );
}
export default EldDideDrawer
