export const formData = form => {  
    const regex = /\[(.*?)\]/gi;
    var form_data={};

    var ctlName="";

    var els = form.querySelectorAll(".json-col");
    
    for(let el of els)
    {
         var pel = el.closest('.json-row'); 

        if(pel !== null && pel != form )
            continue;
                      
        
        ctlName=el.getAttribute('name');
                
        if(ctlName == null)
        {
            el = el.querySelector('input');
            ctlName=el.getAttribute('name');
            if(ctlName == null)
            continue;   
        }                

        ctlName=ctlName.replace(regex,"");

        var elcrl = (el || {});
        if (elcrl.type === 'checkbox') {

            form_data[ctlName]= el.checked;
        }
        else if(elcrl.type === 'radio'){
            var isChecked = el.checked;
            if(isChecked)                
            {
                form_data[ctlName]=el.value;
            }
        }
        else if(elcrl.type === 'text' || elcrl.type === 'textarea' || elcrl.type === 'hidden'|| elcrl.type === 'date'
        )
        {
            form_data[ctlName]=el.value;
        }
        else if(elcrl.type === 'select-one')
        {
            form_data[ctlName]=el.options[el.selectedIndex].value;
        } 
        else if(elcrl.type === 'select-multiple')
        {
            var selOpt=[];
            for(let op of el.options)
            {
                if(op.selected)
                    selOpt.push(op.value);
            }
            form_data[ctlName]=selOpt;
        }
        else{
            form_data[ctlName]=el.value;
        }
        
    }

    els = form.querySelectorAll(".json-row");

    for(let el of els)
    {
        ctlName=el.getAttribute('jsoncol');

        if( ctlName == null )
            continue;

        ctlName=ctlName.replace(regex,"");

        if(typeof form_data[ctlName] === "undefined")
        {
            form_data[ctlName]=formData(el);
        }
        else if(Array.isArray(form_data[ctlName]))
        {
            form_data[ctlName].push(formData(el));
        }
        else
        {
            form_data[ctlName] = [form_data[ctlName]];
            form_data[ctlName].push(formData(el));
        }
    }

    return form_data;
}