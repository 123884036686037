import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { assetupdate } from '../../../actions/assetAction';
import { renderField, renderSelect } from '../../../helpers/FormControls';
import { validate } from './validation';

function submit(values,params) {
	
	const frmdata = {};
	frmdata.assetId= (values.asset_id) ? (values.asset_id) : ("");
	frmdata.assetNumber= (values.asset_Number) ? (values.asset_Number) : ("");
	frmdata.assetType= 2;
	frmdata.companyId= 3;
	frmdata.eldProviderId= (values.eldprovider) ? (values.eldprovider) : ("");
	frmdata.licensePlateNo=(values.license_Plate_No) ? (values.license_Plate_No) : ("");
	frmdata.identificationNo= (values.identification_No) ? (values.identification_No) : ("");
	frmdata.deviceId=(values.device_id) ? (values.device_id) : ("");
	frmdata.fuelType=(values.fuelType) ? (values.fuelType) : ("");
    frmdata.make=(values.make) ? (values.make) : ("");
	frmdata.model=(values.model) ? (values.model) : ("");	
	frmdata.modelYear=(values.model_Year) ? (values.model_Year) : ("");
	frmdata.color= (values.color) ? (values.color) : ("");
	frmdata.weight= (values.weight) ? (values.weight) : ("");
	frmdata.vehicleMileage=(values.vehicle_Mileage) ? (values.vehicle_Mileage) : ("");
	frmdata.mileageUnit=(values.mileageUnit) ? (values.mileageUnit) : ("");		
	this.props.dispatch(assetupdate(frmdata));
}

class EditTrailerPopup extends Component{
render(){
	let minOffset = 0, maxOffset = 20;
	let thisYear = (new Date()).getFullYear();
	let allYears = [];
	for(let x = 0; x <= maxOffset; x++) {
		allYears.push(thisYear - x)
	}
	//const yearList = allYears.map((x) => {return(<option value={x} key={x}>{x}</option>)});
	const yearList = allYears.map(x=>{      
		return{
			"id":x,
			"name":x,
		}
	}); 
	const { handleSubmit, pristine, reset, submitting } = this.props
return(
   <div className="modal fade bd-trailersedit-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg">
         <div className="modal-content">     
            <div className="modal-header">
               <h5 className="modal-title" id="exampleModalLabel">EDIT TRAILER</h5>
                  <button type="button" className="close" data-dismiss="modal" id="trailereditfrm" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
            </div>
            <div className="modal-body table-data">
               <ul className="nav nav-tabs" id="myTrailers" role="tablist">
                  <li className="nav-item">
                     <a className="nav-link active" id="trailerinfo-tab" data-toggle="tab" href="#trailerinfo" role="tab" aria-controls="trailerinfo" aria-selected="true">EDIT TRAILER</a>
                  </li>                                 
               </ul>
               <div className="tab-content" id="myTabContent">
                  	<div className="tab-pane fade show active" id="trailerinfo" role="tabpanel" aria-labelledby="trailerinfo-tab">                                      
					  	<form className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}>
							<div className="row">                 
								<div className="col col-md-12">
									<h5>TRAILER INFO</h5>
									<article className="table-data trailer-data shadow bg-white rounded">
										<div className="row">
											<div className="col col-md-4">
												<div className="form-group">
													<Field type="text" name="asset_Number" component={renderField} label="Trailer #"/> 
												</div>
												<div className="form-group">
												    <Field type="text" className="form-control" name="model_Year" component={renderSelect} data={yearList} label="Year" />   
													{/* <Field type="text" name="model_Year" component={renderField} label="Year"/>   */}
												</div>
											</div>
											<div className="col col-md-4">
												<div className="form-group">
													<Field type="text" name="make" component={renderField} label="Make"/> 
												</div>
												<div className="form-group">
													<Field type="text" name="license_Plate_No" component={renderField} label="Plate #"/>
												</div>
											</div>
											<div className="col col-md-4">
												<div className="form-group">
													<Field type="text" name="model" component={renderField} label="Model"/> 
												</div>
												<div className="form-group">
													<Field type="text" name="device_id" component={renderField} label="Serial"/>
												</div>
											</div>                           
										</div>
									</article>
									<h5>NOTES</h5>
									<article className="table-data trailer-data shadow bg-white rounded">
                                        <div className="row">
                                            <div className="form-group col col-md-12"> 
												<Field type="text" name="identification_No" component={renderField} label="Notes"/>
                                            </div>             
                                        </div> 
									</article>
									<div className="row">
										<div className="col col-md-12 ">
											<div className="modal-footer">
												<button type="submit" className="btn btn-secondary btn-sm">Save</button>&nbsp;
												<button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-primary btn-sm">Reset</button>
											</div>
										</div>
									</div>
								</div>
							</div>
                    	</form>
                    </div>                    
                </div>
            </div>                              
        </div>
    </div>
</div>  
)
}
}
const PageOneForm = reduxForm({
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
  })(EditTrailerPopup)


  const mapStateToProps = (state,ownProps) => {	
	return{
		  form: 'edittrailerValidation',
		  message: state.assetReducer.data,
		  initialValues: ownProps.modalvalues,
		  validate,
		  enableReinitialize: true 
	}    
}
  
const PageOneFormContainer = connect(mapStateToProps)(PageOneForm)
  
export default PageOneFormContainer