import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { loadActionfrquickdispatch, loadAction, truckNoAndLocation, updateLoadStatus, dispatchflag, cancelledloadsbyloadid, onchangetruck, onchangedriver, getDriverListByCompany } from '../../../actions/loadActions';
import { STATUS } from '../../../helpers/status';
import ProofOfDelivery from './ProofOfDelivery';
import QuickDispatchnew from './QuickDispatchnew';
import moment from 'moment';
import cancel from '../../../cancel.png';
import mapboxgl from 'mapbox-gl';
import { formatLocation, getMapboxKey } from '../../../helpers/appUtil';
import { convertDistance, getDistance } from 'geolib';
import liveTrack from '../../../live-track.png';
import restore from '../../../restore.png';
import $ from 'jquery';
import { getVehicleTrackingService } from '../../../services/loadService';
import momentLocalizer from 'react-widgets-moment';
import CancelLoadConfirmModal from '../../brokers/loads/CancelLoadConfirmModal';
import RestoreLoadPopup from '../../brokers/loads/RestoreLoadPopup';
import 'mapbox-gl/dist/mapbox-gl.css';
import MaterialUITabs from '../../uicomponents/DataTableGridMUIX';
import { Box } from '@mui/material';
require('dotenv').config();
momentLocalizer(moment)
class LoadListNew extends Component {
	constructor(props) {
		super(props);
		this.currentStatus = STATUS.LOAD.REQUESTED;
		this.requested = [];
		this.notyetdispatched = [];
		this.dispatchedenroute = [];
		this.delivered = [];
		this.cancelledLoadsData = [];
		this.bamloads = [];
		this.totalItemsCount = 1;
		this.activePage = 1;
		this.itemsCountPerPage = 5;
		this.pageRangeDisplayed = 5;
		this.checkedValue = '';
		this.cancelled = [];
	}
	state = {
		selLoad: {},
		cancelledloads: [],
		mapobject: null,
		showModal: false,
		distance: '',
		tabIndex: 0
	};
	componentDidMount() {
		mapboxgl.accessToken = getMapboxKey();
		document.title = 'Loads';
		//this.tabChange(STATUS.LOAD.REQUESTED);
		$('#notyetdispatched').hide();
		localStorage.setItem('cancelLoadIdscarrier', 0);
		this.props.dispatch(getDriverListByCompany());
		this.props.dispatch(truckNoAndLocation())
	}
	tabChangefrquickdispatch = (type,index) => {
		this.setState({tabIndex:index });
		if (type.loadStatusId === 8) {
			$('#reqcancelbtn').hide();
			$('#notyetdispatched').show();
		}
		else if (type.loadStatusId === 1) {
			$('#reqcancelbtn').show();
			$('#notyetdispatched').hide();
		}
		else {
			$('#reqcancelbtn').hide();
			$('#notyetdispatched').hide();
		}
		const params = {
			...STATUS.LOAD.REQUESTED,
		}
		this.props.dispatch(loadActionfrquickdispatch(params));
		this.props.dispatch(truckNoAndLocation())
		this.currentStatus = type;
	}
	tabChange = (type,index) => {
		this.setState({tabIndex:index });
		if (type.loadStatusId === 8) {
			$('#reqcancelbtn').hide();
			$('#notyetdispatched').show();
		}
		else if (type.loadStatusId === 1) {
			$('#reqcancelbtn').show();
			$('#notyetdispatched').hide();
		}
		else {
			$('#reqcancelbtn').hide();
			$('#notyetdispatched').hide();
		}
		const params = {
			...type,
		}
		this.props.dispatch(loadAction(params));
		this.currentStatus = type;
	}
	updateLoadStatus = () => {
		if (this.checkedValue) {
			if (this.currentStatus.loadStatusId === 4) {
				this.newStatusId = 5;
			} if (this.currentStatus.loadStatusId === 5) {
				this.newStatusId = 3;
			}
			var payload = {
				'loadStatusId': this.newStatusId,
				'loadId': this.checkedValue,
				'podNumber': ''
			}
			this.props.dispatch(updateLoadStatus(payload));
			toastr.success('Success', 'Load has been Successfully posted!');
		}
	}
	handleTabClicked = (e, val) => {
		if (Array.isArray(val)) {
			this.setState({
				cancelledloads: val
			})
			localStorage.setItem('cancelLoadIdscarrier', val.length)
			//localStorage.setItem('cancelLoadIdscarrier',this.state.cancelledloads.length)
		}
		if (localStorage.getItem('cancelLoadIdscarrier') > 0 && e.target.name === 'cancelbtn') {
			var selLoads = this.state.cancelledloads.filter((row1) => {
				return row1.loadSel;
			});
			if (selLoads.length === 0) {
				toastr.info('Info', 'Select Load!');
				return;
			}
			selLoads = selLoads.map((load) => load.id);
			const params = {
				loadsId: selLoads
			}
			this.props.dispatch(cancelledloadsbyloadid(params))
		} else if (localStorage.getItem('cancelLoadIdscarrier') === 0 && e.target.name === 'cancelbtn') {
			toastr.info('Info', 'Select Load!');
			return;
		}
		else {
			return;
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.data != this.props.data) {
			if (this.props.restoredloadflag === true) {
				if (this.props.data.cancelledloadlist?.status === 'OK') {
					toastr.success('Success', this.props.data.cancelledloadlist.message);
					const params = {
						...(STATUS.LOAD.CANCELED)
					}
					this.props.dispatch(loadAction(params));
				}
				else if (this.props.data.cancelledloadlist?.status === 'BAD_REQUEST') {
					toastr.error('Error', this.props.data.cancelledloadlist.validationErrors[0]);
					const params = {
						...(STATUS.LOAD.CANCELED)
					}
					this.props.dispatch(loadAction(params));
				}
			}
			if (this.props.canceledloadflag === true) {
				if (this.props.data.status === 'OK') {
					toastr.success('Success', this.props.data.message);
					if (this.currentStatus === STATUS.LOAD.DELIVERY_CONFIRMED) {
						const params = {
							...(STATUS.LOAD.DELIVERY_CONFIRMED)
						}
						this.props.dispatch(loadAction(params));
					}
					else if (this.currentStatus === STATUS.LOAD.REQUESTED) {
						this.props.dispatch(loadActionfrquickdispatch({ loadStatusId: 1 }));
					}
				}
				else if (this.props.data.status === 'BAD_REQUEST') {
					toastr.error('Error', this.props.data.validationErrors[0]);
				}
			}
		}
		if (this.props.data.message === 'Asset Updated Successfully') {
			const params = {
				...STATUS.LOAD.DELIVERY_CONFIRMED
			}
			this.props.dispatch(loadAction(params));
			this.props.dispatch(truckNoAndLocation())
		}
		else if (this.props.data.message === 'Driver Updated Successfully') {
			const params = {
				...STATUS.LOAD.DELIVERY_CONFIRMED
			}
			this.props.dispatch(loadAction(params));
		}
		if (this.props.changeloadstatusupdateflag === true) {
			if (this.props.data.message === 'Load status changed Successfully') {
				toastr.success('Success', 'Load Dispatched Successfully!');
				this.props.dispatch(dispatchflag());
				const params = {
					...STATUS.LOAD.DELIVERY_CONFIRMED
				}
				this.props.dispatch(loadAction(params));
			}
		}
	}
	close = (e) => {
		this.setState({
			showModal: false
		});
	}
	createMarkerElement(id, imageurl, height, width) {
		let el = document.createElement('div');
		el.className = 'marker';
		el.id = 'map-markers-' + id;
		el.style.backgroundImage =
			'url(' + imageurl + ')';
		el.style.width = width;
		el.style.height = height;
		return el;
	}
	initHistoryMap() {
		try {
			//return;
			this.setState({
				...this.state,
				showModal: true
			});
			let mapelement = document.querySelector('#map');
			if (mapelement === null) {
				return false;
			}
			let body = document.body,
				html = document.documentElement;
			let height = Math.min(body.scrollHeight, body.offsetHeight,
				html.clientHeight, html.scrollHeight, html.offsetHeight);
			height -= 150;
			mapelement.style.height = height + 'px';
			let mapobject = new mapboxgl.Map({
				container: 'map', // container id
				style: 'mapbox://styles/mapbox/streets-v11', //stylesheet location
				center: [-96, 37], // starting position
				zoom: 3.5 // st arting zoom
			});
			console.log('mapobject', mapobject)
			mapobject.on('load', function () {

			});
			this.setState({
				mapobject: mapobject
			});
			return true;
		}
		catch (e) {
			return false;
		}
	}
	plotInitialMarkers(mapobject, trackingdata) {
		let firstMarkerImage = 'https://maps.google.com/mapfiles/ms/icons/red.png';
		let finalMarkerImage = 'https://maps.google.com/mapfiles/ms/icons/green.png';
		let initialMarkerEl = this.createMarkerElement(0, firstMarkerImage, '32px', '32px');
		let finalMarkerEl = this.createMarkerElement(trackingdata.length - 1, finalMarkerImage, '32px', '32px');
		this.addMarkerToMap(initialMarkerEl, mapobject, trackingdata[0]);
		this.addMarkerToMap(finalMarkerEl, mapobject, trackingdata[trackingdata.length - 1]);
	}
	plotMovementMarkers(mapobject, trackingdata) {
		let pointsImage = 'https://truckercloud.s3.us-east-2.amazonaws.com/images/blue.png';
		trackingdata.forEach(function (data, i) {
			let pointsElement = this.createMarkerElement(i, pointsImage, '8px', '8px');
			this.addMarkerToMap(pointsElement, mapobject, data);
		}.bind(this));
	}
	addMarkerToMap(markerelement, mapobject, locationdata) {
		let speed = parseFloat(locationdata.speed);
		if (isNaN(speed)) {
			speed = '';
		}
		else {
			speed = speed.toFixed(2) + ' mph';
		}
		let location = formatLocation(locationdata.address);
		let popup = new mapboxgl.Popup({
			offset: 25,
			closeButton: false,
			closeOnClick: false
		}).setHTML('<span class="map-popup-label">Truck No. : </span><span class="map-popup-label">' + locationdata.vehicleno + '</span></br><span class="map-popup-label1"> Date : </span><span class="map-popup-label">' + locationdata.utctime + '</span></br><span class="map-popup-label1">Speed : </span><span class="map-popup-label">' + speed + '</span></br><span class="map-popup-label2">Location : </span><span class="map-popup-label">' + location + '</span></br>');
		let newmarker = new mapboxgl.Marker(markerelement)
			.setLngLat([locationdata.longitude, locationdata.latitude])
			.setPopup(popup)
			.addTo(mapobject);
		let markerDiv = newmarker.getElement();
		markerDiv.addEventListener('mouseenter', () => newmarker.togglePopup());
		markerDiv.addEventListener('mouseleave', () => newmarker.togglePopup());
	}
	checkIfDataDuplicate(previouslat, previouslon, currentlat, currentlon) {
		let response = false;
		if (previouslat === currentlat && previouslon === currentlon) {
			response = true;
		}
		previouslat = previouslat.toFixed(5);
		previouslon = previouslon.toFixed(5);
		currentlat = currentlat.toFixed(5);
		currentlon = currentlon.toFixed(5);
		if (previouslat === currentlat && previouslon === currentlon) {
			response = true;
		}
		return response;
	}
	removeDuplicateData(coords) {
		let cleandata = [];
		let previouslat = 0.0;
		let previouslon = 0.0;
		coords.forEach(function (data, i) {
			if (!this.checkIfDataDuplicate(previouslat, previouslon, parseFloat(data.latitude), parseFloat(data.longitude))) {
				cleandata.push(data);
			}
			previouslat = parseFloat(data.latitude);
			previouslon = parseFloat(data.longitude);
		}.bind(this));
		return cleandata;
	}
	calculateDistance(trackingData, coords) {
		var distance = getDistance(
			{ latitude: trackingData[0].latitude, longitude: trackingData[0].longitude },
			{ latitude: trackingData[coords.length - 1].latitude, longitude: trackingData[coords.length - 1].longitude }
		);

		distance = convertDistance(distance, 'km');
		this.setState({
			...this.state,
			distance: distance.toFixed(2)
		});
	}
	async handleVehicleData(req) {
		const response = await getVehicleTrackingService(req);
		if (response.loadVehicleTrackingList === null) {
			toastr.info('Info', 'No Data Available!');
		}
		else if (response.length === 0) {
			toastr.info('Info', 'No Data Available!');
		}
		else if (response.length > 0) {
			this.showMap(response)
		}
		else {
			toastr.info('Info', 'No Data Available!');
		}
	}
	showMap(response) {
		this.initHistoryMap();
		//force initializing map
		let mapobject = this.state.mapobject;
		let geojson = {
			'type': 'FeatureCollection',
			'features': [
				{
					'type': 'Feature',
					'geometry': {
						'type': 'LineString',
						'coordinates': []
					}
				}
			]
		};
		//force initializing map
		let plottimer = null;
		let i = 0;
		let pathdata = [];
		let currentzoom = 15;
		let coords = response.map(data => {
			return {
				latitude: parseFloat(data.latitude),
				longitude: parseFloat(data.longitude),
				vehicleno: data.license_Plate_No,
				speed: data.speed,
				utctime: data.convertedDate,
				address: data.location
			}
		});
		this.calculateDistance(response, coords);
		pathdata = coords;
		console.log('coords', coords)
		console.log('coords', [coords[0].longitude, coords[0].latitude])
		console.log('mapobject', mapobject)
		mapobject.setCenter([-86.454928699, 39.625035629]);
		mapobject.setZoom(currentzoom);
		let datalength = pathdata.length;
		this.plotInitialMarkers(mapobject, pathdata);
		mapobject.on('load', function () {
			mapobject.addSource('movementroute', {
				'type': 'geojson',
				'data': geojson
			});
			mapobject.addLayer({
				'id': 'historypath',
				'type': 'line',
				'source': 'movementroute',
				'layout': {
					'line-join': 'round',
					'line-cap': 'round'
				},
				'paint': {
					'line-color': 'red',
					'line-opacity': 0.75,
					'line-width': 3
				}
			});
			plottimer = setInterval(function () {
				if (pathdata[i].latitude && pathdata[i].longitude) {
					let currentposition = [pathdata[i].longitude, pathdata[i].latitude];
					geojson.features[0].geometry.coordinates.push(currentposition);
					mapobject.getSource('movementroute').setData(geojson);
					i++;
					if (!mapobject.getBounds().contains(currentposition)) {
						currentzoom = currentzoom - 1;
						mapobject.setZoom(currentzoom);
					}
					if (i >= datalength) {
						clearInterval(plottimer);
						this.plotMovementMarkers(mapobject, pathdata)
					}
				}
			}.bind(this, mapobject), 100);
		}.bind(this));
	}

	assetnamedetails = (event) => {
		const params =
		{
			loadid: event.target.getAttribute('data-loadid'),
			assetid: event.target.value
		}
		this.props.dispatch(onchangetruck(params));
	}
	drivernamedetails = (event) => {

		const params =
		{
			loadid: event.target.getAttribute('data-loadid'),
			driverid: event.target.value
		}

		this.props.dispatch(onchangedriver(params));
	}
	restoreloadpopup(loadid, loadNo) {
		const params = {
			loadId: loadid,
			loadNo: loadNo
		}
		this.setState({ loadData: params })
	}
	cancelloadpopup(loadid, loadNo) {
		this.setState({ toBeCancelledLoadID: loadid, toBeCancelledLoadNo: loadNo })
	}
	render() {
		const { loading } = this.props;
		if (this.props.data) {
			if (this.currentStatus === STATUS.LOAD.DELIVERY_CONFIRMED) {
				this.notyetdispatched = this.props.data;
			}
			else if (this.currentStatus === STATUS.LOAD.DISPATCHEDENROUTE) {
				this.dispatchedenroute = this.props.data;
			}
			else if (this.currentStatus === STATUS.LOAD.DELIVERED) {
				this.delivered = this.props.data;
			}
			else if (this.currentStatus === STATUS.LOAD.CANCELLED) {
				this.cancelledLoadsData = this.props.data;
			}
			else if (this.currentStatus === STATUS.LOAD.BAMLOADS) {
				this.bamloads = this.props.data;
			}
		}
		loadRequested = [];
		loadNotYetDispatched = [];
		loaddispatchedenroute = [];
		loaddelivered = [];
		cancelledLoadsData = [];
		if (loading) {
			var indicationhtml =
				<div className='spinner-border' role='status'>
					<div className='row react-bs-table-no-data'></div>
				</div>
		}
		else {
			indicationhtml = 'No Data Found';
			var loadreqlist = [];
			if (this.requested.length > 0) {
				var loadreqlist = this.requested;
				var loadRequested = [];
				loadRequested = this.requested.map(
					row => {
						const drivername = '';
						if (row.driverName !== null) {
							drivername = row.driverName.toUpperCase()
						} else {
							drivername = '-'
						}
						var stringLoadDate = '';
						if (row.stringLoadDate !== '') {
							stringLoadDate = moment(row.stringLoadDate).format('MM-DD-YYYY');
						}
						else {
							stringLoadDate = '';
						}
						return ({
							id: row.loadId,
							loadNumber: row.loadNumber,
							stringLoadDate: stringLoadDate,
							originaddress: row.originAddress1,
							destaddress: row.destinationAddress1,
							customerName: row.customerName,
							shipperName: row.shipperName,
							brokerName: row.brokerName,
							consigneeName: row.consigneeName,
							consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + ' ' + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
							weight: row.weight,
							originCity: row.originCity,
							destinationCity: row.destinationCity,
							commodity: row.commodity,
							equipmentType: row.truckTypeName,
							action: <a href='/#' onClick={() => { this.openAssetOrder(row.loadId); }} data-toggle='modal' data-target='#loadAssetOrderModalPopup' className='btn_carrier_move btn btn-sm btn-primary'>Asset Order</a>,
							loadPointsList: row.loadPointsList,
							basecharge: row.baseCharges,
							fulecharge: row.fuelCharges,
							accessiorialcharge: row.accessorialCharges,
							totalrevenue: row.totalRevenue,
							loadedmiles: row.loadedMiles,
							emptymiles: row.emptyMiles,
							totalmiles: row.totalMiles,
							driverpay: row.driverPay,
							tenderprice: row.shipperLoadPrice,
							assetNumber: row.assetNumber,
							driverName: drivername,
							distance: row.distance,
							originCityName: row.orginCityName,
							destinationCityName: row.destinationCityName,
							originState: row.orginStateName,
							destinationState: row.destinationStateName,
						});
					}
				);
			}
			else {
				loadRequested = [];
				indicationhtml = 'No Data Found';
			}
			if (this.notyetdispatched.length > 0) {
				var loadNotYetDispatched = [];
				loadNotYetDispatched = this.notyetdispatched.map(
					(row, index) => {
						return ({
							id: row.loadId,
							loadNumber: row.loadNumber,
							loadDate: moment(row.loadDate).format('MM-DD-YYYY'),
							shipperName: row.shipperInfo?.name,
							carrierName: row.carrierInfo?.name,
							assetNumber: row.vehicleInfo?.assetNumber,
							brokerName: row.companyInfo?.name,
							distance: row.distance,
							weight: row.weight,
							orderNumber: row.orderNumber,
							bolNumber: row.bolNumber,
							instruction: row.instruction,
							numberOfDropoff: row.numberOfDropoff,
							numberOfPickup: row.numberOfPickup,
							rate: row.rate,
							status: row.status,
							loadPoints: row.loadPoints,
							trackingInfo: row.trackingInfo,
							vehicleInfo: row.vehicleInfo,
							tenderInfo: row.tenderInfo,
							carrierInfo: row.carrierInfo,
							action: <span>
								<img src={cancel} alt='' data-toggle='modal' id='cancelConfirmload' data-target='#cancelConfirmloadModal' onClick={() => { this.cancelloadpopup(row.loadId, row.loadNumber); }} />
							</span>
						});
					}
				);
			}
			else {
				loadNotYetDispatched = [];
				indicationhtml = 'No Data Found';
			}
			if (this.dispatchedenroute.length > 0) {
				var loaddispatchedenroute = [];
				loaddispatchedenroute = this.dispatchedenroute.map(row => {
					return ({
						id: row.loadId,
						loadNumber: row.loadNumber,
						loadDate: moment(row.loadDate).format('MM-DD-YYYY'),
						shipperName: row.shipperInfo?.name,
						carrierName: row.carrierInfo?.name,
						brokerName: row.companyInfo?.name,
						assetNumber: row.vehicleInfo?.assetNumber,
						distance: row.distance,
						weight: row.weight,
						orderNumber: row.orderNumber,
						bolNumber: row.bolNumber,
						instruction: row.instruction,
						numberOfDropoff: row.numberOfDropoff,
						numberOfPickup: row.numberOfPickup,
						rate: row.rate,
						status: row.status,
						loadPoints: row.loadPoints,
						trackingInfo: row.trackingInfo,
						vehicleInfo: row.vehicleInfo,
						tenderInfo: row.tenderInfo,
						carrierInfo: row.carrierInfo,
						action: <span> <img src={liveTrack} onClick={(e) => this.handleVehicleData(row.loadId)} alt='' /></span>
					});
				}
				);
			}
			else {
				loaddispatchedenroute = [];
				indicationhtml = 'No Data Found';
			}
			if (this.delivered.length > 0) {
				var loaddelivered = [];
				loaddelivered = this.delivered.map(row => {
					return ({
						id: row.loadId,
						loadNumber: row.loadNumber,
						loadDate: moment(row.loadDate).format('MM-DD-YYYY'),
						shipperName: row.shipperInfo?.name,
						carrierName: row.carrierInfo?.name,
						brokerName: row.companyInfo?.name,
						assetNumber: row.vehicleInfo?.assetNumber,
						distance: row.distance,
						weight: row.weight,
						orderNumber: row.orderNumber,
						bolNumber: row.bolNumber,
						instruction: row.instruction,
						numberOfDropoff: row.numberOfDropoff,
						numberOfPickup: row.numberOfPickup,
						rate: row.rate,
						status: row.status,
						loadPoints: row.loadPoints,
						trackingInfo: row.trackingInfo,
						vehicleInfo: row.vehicleInfo,
						tenderInfo: row.tenderInfo,
						carrierInfo: row.carrierInfo,
						action: <span> <img src={liveTrack} onClick={(e) => this.handleVehicleData(row.loadId)} alt='' /></span>
					});
				}
				);
			}
			else {
				loaddelivered = [];
				indicationhtml = 'No Data Found';
			}
			//cancelled tab
			if (this.cancelledLoadsData.length > 0) {
				var cancelledLoadsData = this.cancelledLoadsData;
				cancelledLoadsData = [];
				cancelledLoadsData = this.cancelledLoadsData.map(row => {
					return ({
						id: row.loadId,
						loadNumber: row.loadNumber,
						loadDate: moment(row.loadDate).format('MM-DD-YYYY'),
						shipperName: row.shipperInfo?.name,
						carrierName: row.carrierInfo?.name,
						assetNumber: row.vehicleInfo?.assetNumber,
						distance: row.distance,
						weight: row.weight,
						brokerName: row.companyInfo?.name,
						orderNumber: row.orderNumber,
						bolNumber: row.bolNumber,
						instruction: row.instruction,
						numberOfDropoff: row.numberOfDropoff,
						numberOfPickup: row.numberOfPickup,
						rate: row.rate,
						status: row.status,
						loadPoints: row.loadPoints,
						trackingInfo: row.trackingInfo,
						vehicleInfo: row.vehicleInfo,
						tenderInfo: row.tenderInfo,
						carrierInfo: row.carrierInfo,
						cancelledDate: row.cancelDate,
						action: <span> <img src={restore} data-toggle='modal' id='restoreload' data-target='#restoreloadModal' onClick={() => { this.restoreloadpopup(row.loadId, row.loadNumber); }} alt='' /></span>,
					});
				}
				);
			}
			else {
				cancelledLoadsData = [];
				indicationhtml = 'No Data Found'
			}
		}
		const expandRow1 = {
			renderer: row => (
				<article className='table-data truck-data shadow bg-white rounded'>
					<div className='row no-gutters'>
						<div className='col-md-12'>
							<ul className='nav nav-tabs' id='myTrucks' role='tablist'>
								<li className='nav-item'>
									<a className='nav-link active' id='pickup-tab' data-toggle='tab' href={`#pickup_${row.id}`} role='tab' aria-controls='pickup' aria-selected='true' >
										pickup Details
									</a>
								</li>
								<li className='nav-item'>
									<a className='nav-link' id='delivery-tab' data-toggle='tab' href={`#delivery_${row.id}`} role='tab' aria-controls='delivery' aria-selected='false' >
										Delivery Details
									</a>
								</li>
							</ul>
							<div className='tab-content' id='myTabContent'>
								<div className='tab-pane fade show active' id={`pickup_${row.id}`} role='tabpanel' aria-labelledby='pickup-tab'>
									<table className='table table-sm'>
										<thead>
											<tr>
												<th scope='col'>Address</th>
												<th scope='col'>Contact</th>
												<th scope='col'>Earliest Time</th>
												<th scope='col'>Latest Time</th>
											</tr>
										</thead>
										<tbody>
											{
												row.loadPoints.length ? (row.loadPoints.map(function (doc, index) {
													if (doc.typeId === 2) {
														var contactDetails = '';
														let contactNumber = '';
														if (doc.contactNumber !== null) {
															contactNumber = doc.contactNumber
														}
														if (doc.city !== null && doc.state !== null && doc.zipCode !== null) {
															contactDetails = doc.city + ', ' + doc.state + ', ' + doc.zipCode
														}
														else if (doc.city !== null && doc.state !== null) {
															contactDetails = doc.city + ', ' + doc.state
														}
														else if (doc.city !== null && doc.zipCode !== null) {
															contactDetails = doc.city + ', ' + doc.zipCode
														}
														else if (doc.city !== null) {
															contactDetails = doc.city
														}
														else if (doc.state !== null) {
															contactDetails = doc.state
														}
														else if (doc.zipCode !== null) {
															contactDetails = doc.zipCode
														}
														return (
															<tr key={index}>
																<td>{contactDetails}</td>
																<td>{contactNumber}</td>
																<td>{moment(doc.earliestTime).format('MM-DD-YYYY')}</td>
																<td>{moment(doc.latestTime).format('MM-DD-YYYY')}</td>
															</tr>
														)
													}
												})) : (<tr><td className='text-center' colSpan='4'>No Record Found</td></tr>)
											}
										</tbody>
									</table>
								</div>
								<div className='tab-pane fade' id={`delivery_${row.id}`} role='tabpanel' aria-labelledby='delivery-tab'>
									<table className='table table-sm'>
										<thead>
											<tr>
												<th scope='col'>Address</th>
												<th scope='col'>Contact</th>
												<th scope='col'>Earliest Time</th>
												<th scope='col'>Latest Time</th>
											</tr>
										</thead>
										<tbody>
											{
												row.loadPoints.length ? (row.loadPoints.map(function (doc, index) {
													if (doc.typeId === 3) {
														var contactDetails = '';
														let contactNumber = '';
														if (doc.contactNumber !== null) {
															contactNumber = doc.contactNumber
														}
														if (doc.city !== null && doc.state !== null && doc.zipCode !== null) {
															contactDetails = doc.city + ', ' + doc.state + ', ' + doc.zipCode
														}
														else if (doc.city !== null && doc.state !== null) {
															contactDetails = doc.city + ', ' + doc.state
														}
														else if (doc.city !== null && doc.zipCode !== null) {
															contactDetails = doc.city + ', ' + doc.zipCode
														}
														else if (doc.city !== null) {
															contactDetails = doc.city
														}
														else if (doc.state !== null) {
															contactDetails = doc.state
														}
														else if (doc.zipCode !== null) {
															contactDetails = doc.zipCode
														}
														return (
															<tr key={index}>
																<td>{contactDetails}</td>
																<td>{contactNumber}</td>
																<td>{moment(doc.earliestTime).format('MM-DD-YYYY')}</td>
																<td>{moment(doc.latestTime).format('MM-DD-YYYY')}</td>
															</tr>
														)
													}
												})) : (<tr><td className='text-center' colSpan='4'>No Record Found</td></tr>)
											}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</article>
			),
			showExpandColumn: true,
			expandByColumnOnly: true,
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return (
						<i className='icofont-minus-circle'></i>
					);
				}
				return (
					<i className='icofont-plus-circle'></i>
				);
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<i className='icofont-minus-circle'></i>
					);
				}
				return (
					<i className='icofont-plus-circle'></i>
				);
			}
		};
		const { SearchBar } = Search;
		//For assets assigned tab
		const assetsAssignedcolumns = [
			{
				text: 'Load #',
				sort: true,
				dataField: 'loadNumber'
			},
			{
				text: 'Date',
				sort: true,
				dataField: 'loadDate'
			},
			{
				text: 'Customer',
				dataField: 'brokerName',
				sort: true
			},
			{
				text: 'Shipper Name',
				sort: true,
				dataField: 'shipperName',
				clickToSelect: false,
			},
			{
				text: 'Carrier',
				sort: true,
				dataField: 'carrierName'
			},
			{
				text: 'Truck No',
				sort: true,
				dataField: 'assetNumber',
			},
			{
				text: 'Action',
				sort: true,
				dataField: 'action'
			}
		]
		const disaptchedEnrouteColumns = [
			{
				text: 'Load #',
				sort: true,
				dataField: 'loadNumber'
			},
			{
				text: 'Date',
				sort: true,
				dataField: 'loadDate'
			},
			{
				text: 'Customer',
				dataField: 'brokerName',
				sort: true
			},
			{
				text: 'Shipper Name',
				sort: true,
				dataField: 'shipperName',
				clickToSelect: false,
			},
			{
				text: 'Carrier',
				sort: true,
				dataField: 'carrierName'
			},
			{
				text: 'Truck No',
				sort: true,
				dataField: 'assetNumber',
			},
			{
				text: 'Action',
				sort: true,
				dataField: 'action'
			}
		]
		const deliverdcolumns = [
			{
				text: 'Load #',
				sort: true,
				dataField: 'loadNumber'
			},
			{
				text: 'Date',
				sort: true,
				dataField: 'loadDate'
			},
			{
				text: 'Customer',
				dataField: 'brokerName',
				sort: true
			},
			{
				text: 'Shipper',
				sort: true,
				dataField: 'shipperName'
			},
			{
				text: 'Carrier',
				sort: true,
				dataField: 'carrierName'
			},
			{
				text: 'Truck No',
				sort: true,
				dataField: 'assetNumber'
			},
			{
				text: 'Action',
				dataField: 'action'
			}
		]
		const cancelledcolumns = [
			{
				text: 'Load #',
				dataField: 'loadNumber',
				sort: true
			},
			{
				text: 'Date',
				dataField: 'loadDate',
				sort: true
			},
			{
				text: 'Customer',
				dataField: 'brokerName',
				sort: true
			},
			{
				text: 'Shipper',
				dataField: 'shipperName',
				sort: true
			},
			{
				text: 'Carrier',
				sort: true,
				dataField: 'carrierName'
			},
			{
				text: 'Cancelled Date',
				dataField: 'cancelledDate',
				sort: true
			},
			{
				text: 'Action',
				dataField: 'action'
			}
		];
		const customTotal = (from, to, size) => (
			<span className='react-bootstrap-table-pagination-total'>
				Showing {from} to {to} of {size} Results
			</span>
		);
		const options = {
			paginationSize: 4,
			pageStartIndex: 1,
			// alwaysShowAllBtns: true, // Always show next and previous button
			// withFirstAndLast: false, // Hide the going to First and Last page button
			// hideSizePerPage: true, // Hide the sizePerPage dropdown always
			// hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
			firstPageText: '<<<',
			prePageText: '<<',
			nextPageText: '>>',
			lastPageText: '>>>',
			nextPageTitle: 'First page',
			prePageTitle: 'Pre page',
			firstPageTitle: 'Next page',
			lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: customTotal
		};
		return (
			<article className='table-data truck-data shadow bg-white rounded'>
				<div className='row no-gutters'>
					<div className='col-md-12'>
						{/* <ul className='nav nav-tabs' id='myTrucks' role='tablist'>
							<li className='nav-item'>
								<a onClick={() => { this.tabChangefrquickdispatch(STATUS.LOAD.REQUESTED); this.tabStatus = 4; }} className='nav-link active' id='requested-tab' data-toggle='tab' href='#requested' role='tab' aria-controls='requested' aria-selected='true' >
									Unassigned
								</a>
							</li>
							<li className='nav-item'>
								<a onClick={() => { this.tabChange(STATUS.LOAD.DELIVERY_CONFIRMED); this.tabStatus = 5; }} className='nav-link' id='notyetdispatchedload-tab' data-toggle='tab' href='#notyetdispatchedload' role='tab' aria-controls='notyetdispatchedload' aria-selected='false' >
									Assets Assigned
								</a>
							</li>
							<li className='nav-item'>
								<a onClick={() => { this.tabChange(STATUS.LOAD.DISPATCHEDENROUTE); this.tabStatus = 3; }} className='nav-link' id='dispatchedenrouteload-tab' data-toggle='tab' href='#dispatchedenrouteload' role='tab' aria-controls='dispatchedenrouteload' aria-selected='false' >
									Dispatched & Enroute
								</a>
							</li>
							<li className='nav-item'>
								<a onClick={() => { this.tabChange(STATUS.LOAD.DELIVERED); this.tabStatus = 3; }} className='nav-link' id='deliveredload-tab' data-toggle='tab' href='#deliveredload' role='tab' aria-controls='deliveredload' aria-selected='false'>
									Delivered
								</a>
							</li>
							<li className='nav-item'>
								<a onClick={() => { this.tabChange(STATUS.LOAD.CANCELLED); this.tabStatus = 3; }} className='nav-link' id='cancelledload-tab' data-toggle='tab' href='#cancelledload' role='tab' aria-controls='cancelledload' aria-selected='false'>
									Cancelled
								</a>
							</li>
						</ul> */}
						<MaterialUITabs tabsData={
							[{ component: 'Unassigned', tabID: 'requested-tab', refData: '#requested', methodName: (e, data, index) => { this.tabChangefrquickdispatch(STATUS.LOAD.REQUESTED, 0) } },
							{ component: 'Assets Assigned', tabID: 'notyetdispatched-tab', refData: '#notyetdispatched', methodName: (e, data, index) => { this.tabChange(STATUS.LOAD.DELIVERY_CONFIRMED, 1) } },
							{ component: 'Dispatched & Enroute', tabID: 'dispatched-tab', refData: '#dispatched', methodName: (e, data, index) => { this.tabChange(STATUS.LOAD.DISPATCHEDENROUTE, 2) } },
							{ component: 'Delivered', tabID: 'delivered-tab', refData: '#delivered', methodName: (e, data, index) => { this.tabChange(STATUS.LOAD.DELIVERED, 3) } },
							{ component: 'Cancelled', tabID: 'cancelled-tab', refData: '#cancelled', methodName: (e, data, index) => { this.tabChange(STATUS.LOAD.CANCELLED, 4) } }
							]}
							getIndex={this.getIndex}
							activeTab={'#requested'}
							state={this.state}
						/>
						<div className='tab-content' id='myTabContent'>
							{this.state.tabIndex === 0 && (
								<Box>
									<QuickDispatchnew indicationhtml={indicationhtml} tabClicked={(e, val) => { this.handleTabClicked(e, val) }} cancelloadpopupData={(loadId, loadNo) => { this.cancelloadpopup(loadId, loadNo) }} loadreq={loadreqlist} currentStatus={this.currentStatus} expandRow={expandRow1} />
								</Box>
							)}
							{this.state.tabIndex === 1 && (
								<Box>
									<ToolkitProvider
										keyField='id'
										data={loadNotYetDispatched}
										columns={assetsAssignedcolumns}
										search
									>
										{
											props => (
												<div>
													<SearchBar {...props.searchProps} />
													<BootstrapTable ref={n => this.node = n}
														{...props.baseProps} pagination={paginationFactory(options)} noDataIndication={indicationhtml} hover expandRow={expandRow1}
														bordered={false}
													/>
												</div>
											)
										}
									</ToolkitProvider>
								</Box>
							)}
							{this.state.tabIndex === 2 && (
								<Box>
									<ToolkitProvider
										keyField='id'
										data={loaddispatchedenroute}
										columns={disaptchedEnrouteColumns}
										search
									>
										{
											props => (
												<div>
													<SearchBar {...props.searchProps} />
													<BootstrapTable
														{...props.baseProps} pagination={paginationFactory(options)} noDataIndication={indicationhtml} hover expandRow={expandRow1}
														bordered={false}
													/>
												</div>
											)
										}
									</ToolkitProvider>
								</Box>
							)}
							{this.state.tabIndex === 3 && (
								<Box>
									<ToolkitProvider
										keyField='id'
										data={loaddelivered}
										columns={deliverdcolumns}
										search
									>
										{
											props => (
												<div>
													<SearchBar {...props.searchProps} />
													<BootstrapTable
														{...props.baseProps} pagination={paginationFactory(options)} noDataIndication={indicationhtml} hover expandRow={expandRow1}
														bordered={false}
													/>
												</div>
											)
										}
									</ToolkitProvider>
								</Box>
							)}
							{this.state.tabIndex === 4 && (
								<Box>
									<ToolkitProvider
										keyField='id'
										data={cancelledLoadsData}
										columns={cancelledcolumns}
										search
									>
										{
											props => (
												<div>
													<SearchBar {...props.searchProps} />
													<BootstrapTable
														{...props.baseProps} pagination={paginationFactory(options)} noDataIndication={indicationhtml} hover expandRow={expandRow1}
														bordered={false}
													/>
												</div>
											)
										}
									</ToolkitProvider>
								</Box>
							)}
						</div>
					</div>
				</div>
				<RestoreLoadPopup loadTab={this.state} />
				<ProofOfDelivery loadDetails={this.state.selLoad} />
				<CancelLoadConfirmModal loadTab={this.state} />
			</article>
		)
	}
}
const mapStateToProps = state => {
	return {
		data: state.loadReducer.data,
		DriverList: state.loadReducer.DriverList,
		truckNoListAndLocation: state.loadReducer.truckNoListAndLocation,
		LoadAccessorials: state.loadReducer.LoadAccessorials,
		changeloadstatusupdateflag: state.loadReducer.changeloadstatusupdateflag,
		canceledloadflag: state.loadReducer.canceledloadflag,
		loading: state.loadReducer.loading,
		error: state.loadReducer.error,
		quickdispatchloads: state.loadReducer.data,
		restoredloadflag: state.loadReducer.restoredloadflag
	}
}
export default connect(mapStateToProps)(LoadListNew);