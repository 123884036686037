import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import { editDocumentService,documentLoadListService,addBrokerDocumentService,documentBrokerEditService } from '../services/documentService';
import * as types from '../actions';

// async function fetchAsync(func) {
//     const response = await func();

//     if (response.ok) {
//         return await response.json();
//     }

//     throw new Error("Unexpected error!!!");
// }


function* editdocumentData(payload) { 
    try {   
            const response = yield call( editDocumentService, payload );   
            yield put({type: types.EDIT_DOCUMENT_DATA_SUCCESS, response });
    } catch (error) {
            yield put({ type: types.EDIT_DOCUMENT_DATA_ERROR, error })
    }
}

function* fetchDocumentLoadList(payload) { 
    try {
            const response = yield call(documentLoadListService, payload);
            yield put({type: types.LIST_DOCUMENT_DATA_SUCCESS, data: response});
    } catch (error) {
            yield put({ type: types.LIST_DOCUMENT_DATA_ERROR, error })
    }
}

function* newBrokerDocumentData(payload) {
   try {
        const response = yield call( addBrokerDocumentService, payload ); 
        yield put({type: types.POST_DOCUMENT_DATA_SUCCESS, data: response});
    } catch (e) {
        yield put({type: types.POST_DOCUMENT_DATA_ERROR, error: e.message});
    }
}

function * editBrokerDocumentData(payload){
    try{
         const response = yield call( documentBrokerEditService, payload ); 
         yield put({type: types.EDIT_DOCUMENTS_DATA_SUCCESS, data: response});
    }
    catch(e)
    {
        yield put({type: types.EDIT_DOCUMENTS_DATA_ERROR, error: e.message});
    }
}
export function* documentSaga() {
    yield takeLatest(types.EDIT_DOCUMENT_DATA, editdocumentData);
    yield takeEvery(types.LIST_DOCUMENT_DATA,fetchDocumentLoadList);
    yield takeLatest(types.POST_DOCUMENT_DATA,newBrokerDocumentData);
    yield takeLatest(types.EDIT_DOCUMENTS_DATA,editBrokerDocumentData);
}