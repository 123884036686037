import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import { appBaseUrl, getBaseUrl, getUrlParams } from '../../helpers/appUtil';
import $ from 'jquery';
import {sweepstakesCarrierInvitation } from '../../actions/companyActions';
import { connect } from 'react-redux';
  
class Sweepstakes extends Component {
    constructor(props) {
      super(props);
      this.state = {
          invitationData: {},
          error: ''
      }
    }
    componentDidMount = () => {
        let theme = process.env.REACT_APP_BASE_URL + 'publicresource/getbycode/css?originUrl=' + getBaseUrl();
        $('<link/>', { rel: 'stylesheet', href: theme }).appendTo('head');
        if (this.props.location.search) {
            let urlparam = getUrlParams(this.props.location.search);
            console.log('urlparam',urlparam)
            if (urlparam.invitationkey) {
                document.title = appBaseUrl() + 'System';
                this.setState({ invitationkey: urlparam.invitationkey ,template:urlparam.template})
            }
            this.carrierBulkInvitation(urlparam.invitationkey);
        }
        else {
            this.props.history.push('/login');
        }
    }
    carrierBulkInvitation = (urlparam) => {
      this.props.dispatch(sweepstakesCarrierInvitation(urlparam))
  }
  componentWillReceiveProps(prevProps, nextProps) {
    if (prevProps !== nextProps) {
        if (prevProps.carrierInvitationRes.hasOwnProperty('message')) {
            this.setState({ error: prevProps.carrierInvitationRes.message })
            return
        }
        this.carrierInviteReponse = prevProps.carrierInvitationRes;
        this.setState({
            invitationData: prevProps.carrierInvitationRes
        })
    }
}
    render() {
      console.log(this.state)
        return (
          <div>
             <div className='sweepstakes-headerbar'>
               <img className='trade-logo-sweepstakes' alt='' src='https://truckercloud.com/wp-content/uploads/2020/06/trucker-cloud-logo-e1591004403752.png' />
             </div>
            <Carousel interval={null}>
            <Carousel.Item >
              <img
                className='d-block img-crsl-css w-100'
                src={`${process.env.PUBLIC_URL}/images/brian-stalter-arotxe540N4-unsplash.jpg`}
                alt='First slide'
              />
              <Carousel.Caption className='caption2Css'>
              <div className='join-heading-css animate-charcter'>Join the TruckerCloud Network for Free</div>
              <div className='win-chance-heading-css animate-charcter'>& Enter for a Chance to Win $500 </div>
              <p className='para-css'>Learn more about the prizes and how winners will be selected on the next slide</p><br/><br/>
              <h2 className='shadow-input sweepstakes-error'>{this.state?.error}</h2><br/>
              <Link to={{pathname: '/cob',
                            search: `?invitationkey=${this.state?.invitationkey}`,
                            isSweepstakes:true
                          }}
                    className={this.state?.error?'btn sweepstakes-button-css disabled-link':'btn sweepstakes-button-css'}>Enter the sweepstakes now !
                </Link>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item >
              <img
                className='d-block img-crsl-css w-100'
                src={`${process.env.PUBLIC_URL}/images/brian-stalter-arotxe540N4-unsplash.jpg`}
                alt='First slide'
              />
              <Carousel.Caption className='caption3Css'>
               <div className='sweep-heading3-css'>Frequently Asked Questions (FAQ) – Carrier Sweepstakes</div>
               <h1 className='head1-css'>22 winners each month, including a $500 grand prize!</h1>
               <p className='para2-css'>Each month, TruckerCloud through Truckinfun will run a sweepstakes for carriers for onboarding their ELD into the TruckerCloud Network. Onboarding is free, and for each carrier who onboards their ELD, they will be entered into a sweepstakes with a variety of prizes, including a grand prize.</p>
               <h1 className='head1-css'>What other prizes are there? </h1>
               <p className='para2-css'>A grand prize winner will be selected each month, along with winners for other prizes. Other prizes include one winner of a $250 gift card to Pilot, 10 winners of $20 gift card to Pilot, and 10 winners of $10 gift card to Pilot. There will be a total of 22 prize winners each month.</p>
               <h1 className='head1-css'>How will the winners be selected? </h1>
               <p className='para2-css'>Winners for the monthly prizes will be selected at random and will be notified via email. This is an ongoing sweepstakes, and you can enter for the prizes indefinitely. </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          <div className='sweep-heading-css'>Why TruckerCloud?</div>
            <section>
             <div class='container'>
                <div class='row'>
                  <div class='col-lg-4 d-flex align-items-stretch'>
                    <div class='content'>
                      <h1 className='sweep-sub-heading-css'>
                      <svg id='SvgjsSvg3254' width='318' height='152' xmlns='http://www.w3.org/2000/svg' version='1.1' xmlnsXlink='http://www.w3.org/1999/xlink' xmlnsSvgjs='http://svgjs.dev/svgjs' x='0px' y='0px' viewBox='0 0 24 24' class='apexcharts-svg' xmlnsData='ApexChartsNS' transform='translate(0, 0)' className='svgImgCss'>
                      <g transform='translate(0, 0)' class='nc-icon-wrapper' fill='none'>
                            <polyline data-cap='butt' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-miterlimit='10' points='8.894,17.027 1,19 1,4 9,2 15,4 23,2 23,17 16,18.75 ' stroke-linejoin='miter' stroke-linecap='butt'></polyline>
                            <polygon data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' points=' 10,21 13,16 18,13 7,10 ' stroke-linejoin='miter'></polygon>
                        </g>
                      </svg><br/>
                      What is TruckerCloud</h1><br/>
                      {this.state.template==='A'?
                        <p className='para-css'><br/>
                        TruckerCloud is a load visibility platform for brokers, carriers, and shippers. Through the platform, you, the carrier, can share your locations, but only with specific brokers and shippers that you enable, and only on the loads that you want them to have visibility on. TruckerCloud helps you reduce the number of check calls and improve relationships with your customers.
                        </p>
                        :<p className='para-css'><br/>
                        TruckerCloud is a load visibility platform for brokers, carriers, and shippers. Through the platform, you can track location, but only with specific brokers and shippers that you enable, and only on the loads that you want them to have visibility on. TruckerCloud helps you reduce the number of check calls and improve relationships with your customers.
                        </p>
                      }
                    </div>
                  </div>
                  <div class='col-lg-4 d-flex align-items-stretch'>
                    <div class='content'>
                    <h1 className='sweep-sub-heading-css'>
                      <svg id='SvgjsSvg3254' width='318' height='152' xmlns='http://www.w3.org/2000/svg' version='1.1' xmlnsXlink='http://www.w3.org/1999/xlink' xmlnsSvgjs='http://svgjs.dev/svgjs' x='0px' y='0px' viewBox='0 0 24 24' class='apexcharts-svg' xmlnsData='ApexChartsNS' transform='translate(0, 0)' className='svgImgCss'>
                      <g transform='translate(0, 0)' class='nc-icon-wrapper' fill='none'>
                          <polyline data-cap='butt' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-miterlimit='10' points='15.293,6.247 10,1 1,1 1,10 13,22 16.913,18 ' stroke-linejoin='miter' stroke-linecap='butt'></polyline>
                          <circle data-stroke='none' fill='none' cx='7' cy='7' r='2' stroke-linejoin='miter' stroke-linecap='square'></circle>
                          <circle data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' cx='17' cy='12' r='6' stroke-linejoin='miter'></circle>
                          <polyline data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' points=' 17,10 17,12 19,12 ' stroke-linejoin='miter'></polyline>
                      </g>
                      </svg><br/>
                      Free for Carriers Forever</h1><br/>
                      <p className='para-css'><br/>
                      We know you don’t want to pay for a service to share your location, and you should never have to. Joining the TruckerCloud network is free for carriers and enables you to share key tracking data with your customers.
                      </p>
                    </div>
                  </div>
                  <div class='col-lg-4 d-flex align-items-stretch'>
                    <div class='content'>
                      <h1 className='sweep-sub-heading-css'>
                      <svg id='SvgjsSvg3254' width='318' height='152' xmlns='http://www.w3.org/2000/svg' version='1.1' xmlnsXlink='http://www.w3.org/1999/xlink' xmlnsSvgjs='http://svgjs.dev/svgjs'  x='0px' y='0px' viewBox='0 0 24 24'class='apexcharts-svg' xmlnsData='ApexChartsNS' transform='translate(0, 0)' className='svgImgCss'>
                      <g transform='translate(0, 0)' class='nc-icon-wrapper' fill='none'>
                          <path data-cap='butt' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-miterlimit='10' d='M11,13 c-2.824,0-5.329,0.638-6.975,1.193C2.81,14.604,2,15.749,2,17.032V21h9' stroke-linejoin='miter' stroke-linecap='butt'></path>
                          <path fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' d='M11,13L11,13 c-2.761,0-5-3.239-5-6V6c0-2.761,2.239-5,5-5h0c2.761,0,5,2.239,5,5v1C16,9.761,13.761,13,11,13z' stroke-linejoin='miter'></path>
                          <line data-cap='butt' data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-miterlimit='10' x1='16.858' y1='19.515' x2='20.142' y2='21.485' stroke-linejoin='miter' stroke-linecap='butt'></line>
                          <line data-cap='butt' data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-miterlimit='10' x1='16.858' y1='18.485' x2='20.142' y2='16.515' stroke-linejoin='miter' stroke-linecap='butt'></line>
                          <circle data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' cx='16' cy='19' r='1' stroke-linejoin='miter'></circle>
                          <circle data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' cx='21' cy='16' r='1' stroke-linejoin='miter'></circle>
                          <circle data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' cx='21' cy='22' r='1' stroke-linejoin='miter'></circle>
                          <circle data-color='color-2' data-stroke='none' fill='none' cx='16' cy='19' r='1' stroke-linejoin='miter' stroke-linecap='square'></circle>
                          <circle data-color='color-2' data-stroke='none' fill='none' cx='21' cy='16' r='1' stroke-linejoin='miter' stroke-linecap='square'></circle>
                          <circle data-color='color-2' data-stroke='none' fill='none' cx='21' cy='22' r='1' stroke-linejoin='miter' stroke-linecap='square'></circle>
                      </g>
                      </svg><br/>
                      Carrier Controlled Location Sharing</h1><br/>
                      <p className='para-css'>
                      We agree. TruckerCloud is a trusted third party, and not a broker, shipper or 3PL. We only share data when you enable a broker or shipper to view the visibility of a single load. You are not sharing any data by signing up with TruckerCloud. We just make it easier for the next time you want to share data. We value your privacy as much as you do.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section><br/><br/>
            <div class='flex-parent jc-center'>
                <Link to={{pathname: '/cob',
                                search: `?invitationkey=${this.state?.invitationkey}`,
                                isSweepstakes:true
                              }}
                        className='btn sweepstakes-button-css'>Enter the sweepstakes now !
                 </Link>
                <Link to={{pathname: '/faq-tc-carriers' }}
                    className='btn sweepstakes-button-css faq-button'>FAQ FOR CARRIERS
                </Link>
            </div>
          </div>
        );
    }
}
const mapStateToProps = (state) => {
  let carrierInvitationList = {};
  if (state.companyReducer.carrierBulkInvitation) {
      carrierInvitationList = state.companyReducer.carrierBulkInvitation;
  }
  return {
      carrierInvitationRes: carrierInvitationList
  };
}

export default connect(mapStateToProps)(Sweepstakes)
