import React, { Component } from 'react'
import { GoogleMap, LoadScript, Marker, InfoWindow, Polyline } from '@react-google-maps/api';
import {polyLien1, mapStyle} from './../../../helpers/GoogleUtils';
import { Fragment } from 'react';
import { convertDistance, getDistance } from 'geolib';
import { toastr } from 'react-redux-toastr';

export default class BreadCrumpLive extends Component {
    constructor(props){
        super(props);
        this.state={
            export : false,
		    go :  false,
            showModal: false,
            mapData:[],
            distance:'0.00',
            mapZoom:12,
            markerPopupShow:false,
            currentMarker:null,
            infwCenter:{
                lat:41.89101, 
                lng:-87.62342            
            },
            infwData:{},
            mapCenter:{
                lat:41.89101, 
                lng:-87.62342            
            },
            truckRoute:[],
            routeLength:0,
            routeCount:0
        }
        this.GOOGLE_MAP_KEY=localStorage.getItem('GOOGLE_MAP_KEY');
        this.map=null;
        
    }
    componentDidMount(){
        // new function code
        if(this.props.trackingList && this.props.trackingList.length>0){
            let trackingData = [...this.props.trackingList];
                let distance = getDistance(
                    { latitude: trackingData[0].latitude, longitude: trackingData[0].longitude },
                    { latitude: trackingData[trackingData.length-1].latitude, longitude: trackingData[trackingData.length-1].longitude } 
                );
        
                distance = convertDistance(distance, 'mi');  
                if(distance) {
                    distance=parseFloat(distance).toFixed(2);
                } else {
                    distance='';
                }
                this.setState({
                    ...this.state,
                    mapData:trackingData,
                    distance:distance,
                    routeLength:trackingData.length,
                    routeCount:1,
                    truckRoute:[{
                        lat:parseFloat(trackingData[0].latitude), 
                        lng:parseFloat(trackingData[0].longitude)
                    }],
                    mapCenter:{
                        lat:parseFloat(trackingData[0].latitude), 
                        lng:parseFloat(trackingData[0].longitude)
                    }
                });
                setTimeout(
                    () => this.addRoute(), 
                    500
                );
        } else {
            toastr.info('Info', 'No Data Available!');
            this.props.closeMap(1)
        }

        // function end 
    }
    loadMarker = () => {
        let mapData = [...this.props.trackingList];
        if(mapData.length>1){
            let pos1 = {
                lat:parseFloat(mapData[0].latitude),
                lng:parseFloat(mapData[0].longitude)
            };
            // let pos2 = {
            //     lat:parseFloat(this.props.trackingList[mapData.length-1].latitude),
            //     lng:parseFloat(this.props.trackingList[mapData.length-1].longitude)
            // };
            return <Fragment>
                <Marker key={1}
                    icon='http://maps.google.com/mapfiles/ms/icons/red.png'
                    position={pos1}        
                    onMouseOver={(e) =>{this.onClickMarker(e, mapData[0], 0)}}             
                />
                {/* <Marker key={2}
                    icon='http://maps.google.com/mapfiles/ms/icons/green.png'
                    position={pos2}     
                    onClick={(e) =>{this.onClickMarker(e, mapData[mapData.length-1])}}                
                /> */}
            </Fragment>
        } else if(mapData.length>0){
            let pos1 = {
                lat:parseFloat(mapData[0].latitude),
                lng:parseFloat(mapData[0].longitude)
            };
            return <Marker key={1}
                    icon='http://maps.google.com/mapfiles/ms/icons/red.png'
                    position={pos1}  
                    onMouseOver={(e) =>{this.onClickMarker(e, mapData[0], 0)}}                   
                />
        }
    }
    loadTrackPoints = () => {
        if(this.state.routeLength === this.state.routeCount && this.state.routeLength>0){
            let truckPoints = this.props.trackingList.map((row, index) => {
                let lat = parseFloat(row.latitude);
                let lng = parseFloat(row.longitude);
                if(index === 0 || (index+1) === this.state.routeLength){
                    return <Marker key={index}
                        icon='https://truckercloud.s3.us-east-2.amazonaws.com/images/blue.png'
                        position={{lat: lat, lng: lng}}                   
                    />
                } else {
                    return <Marker key={index}
                        icon='https://truckercloud.s3.us-east-2.amazonaws.com/images/blue.png'
                        position={{lat: lat, lng: lng}}  
                        onMouseOver={(e) =>{this.onClickMarker(e, row, index)}}                   
                    />
                }
            });

            return <Fragment>{truckPoints}</Fragment>
        }
    }
    addRoute = () => {
        if(this.map){
            let bounds = this.map.getBounds();
            if(bounds) {           
                if(this.state.routeLength > this.state.routeCount){
                    let lat = parseFloat(this.props.trackingList[this.state.routeCount].latitude);
                    let lng = parseFloat(this.props.trackingList[this.state.routeCount].longitude);
                    let mapZoom = this.state.mapZoom;
                    let bounds = this.map.getBounds().contains({lat: lat, lng: lng});
                    if(!bounds){
                        mapZoom--;
                    }

                    let truckRoute = [...this.state.truckRoute];
                    truckRoute.push({
                        lat:lat, 
                        lng:lng
                    })
                    
                    this.setState({
                        ...this.state,
                        truckRoute:truckRoute,
                        routeCount:this.state.routeCount+1,
                        mapZoom:mapZoom
                    });

                    setTimeout(
                        () => this.addRoute(), 
                        500
                    );
                } 
            } else {
                setTimeout(
                    () => this.addRoute(), 
                    500
                );
            }
        } else {
            setTimeout(
                () => this.addRoute(), 
                500
            );
        }
    }
    onClickMarker = (e, data, index) =>{
        if(index === this.state.currentMarker)   return;
        let speed = parseFloat(data.speed);
        if(isNaN(speed))
        {
            speed ='';
        }
        else{
            speed = speed.toFixed(2) + " mph";
        }     
        let infwCenter={
            lat:parseFloat(data.latitude), 
            lng:parseFloat(data.longitude)
        }
        let infwData={
            truckNo:data.license_Plate_No,
            date:data.convertedDate,
            speed:speed,
            location:data.location
        }
        this.setState({
            ...this.state,
            markerPopupShow:true,
            currentMarker:index,
            infwCenter:infwCenter,
            infwData:infwData
        })
    }
    // onClickMarker = (e, data) =>{   
    //     let speed = parseFloat(data.speed);
    //     if(isNaN(speed))
    //     {
    //         speed ='';
    //     }
    //     else{
    //         speed = speed.toFixed(2) + " mph";
    //     }     
    //     let infwCenter={
    //         lat:parseFloat(data.latitude), 
    //         lng:parseFloat(data.longitude)
    //     }
    //     let infwData={
    //         truckNo:data.license_Plate_No,
    //         date:data.convertedDate,
    //         speed:speed,
    //         location:data.location
    //     }
    //     this.setState({
    //         ...this.state,
    //         markerPopupShow:true,
    //         infwCenter:infwCenter,
    //         infwData:infwData
    //     })
    // }
    infClose = () => {
        this.setState({
            ...this.state,
            markerPopupShow:false,
            currentMarker:null
		});
    }
    render() {
        return (
            <div>
                <LoadScript
                googleMapsApiKey={this.GOOGLE_MAP_KEY}>
                <GoogleMap key={'A1'} 
                    mapContainerStyle={mapStyle}
                    zoom={this.state.mapZoom}
                    center={this.state.mapCenter}                                        
                    onLoad={(map) => {this.map=map}}
                >
                        {this.loadMarker()}                        
                    { this.state.markerPopupShow && 
                    <InfoWindow                            
                        position={this.state.infwCenter}
                        onCloseClick={()=>{this.infClose()}}
                    ><span>                                            
                        <p>Truck/Asset No: {this.state.infwData.truckNo}</p>
                        <p>Date: {this.state.infwData.date}</p>
                        <p>Speed: {this.state.infwData.speed}</p>
                        <p>Location: {this.state.infwData.location}</p>                                        
                        </span>
                    </InfoWindow>
                    }
                    <Polyline                        
                        path={this.state.truckRoute}
                        options={polyLien1}
                    />                                        
                    {/* {this.loadTrackPoints()} */}
                    
                </GoogleMap>
                </LoadScript>
            </div>
        )
    }
}
