import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import {formatLocation, getMapboxKey} from '../../helpers/appUtil';
require('dotenv').config();

class DashboardMap extends Component {
    state = {
        map : null,
        activemarkers:[],
        loader: false,
        randomgen:Math.random(),
        activeUrl: 'https://maps.google.com/mapfiles/ms/icons/green.png',
        idealUrl: 'https://maps.google.com/mapfiles/ms/icons/yellow.png',
        stoppedUrl: 'https://maps.google.com/mapfiles/ms/icons/red.png'
    }

    componentDidUpdate(){
      this.getData();
    }
    
    componentDidMount() {
        mapboxgl.accessToken = getMapboxKey();
    //mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
        let mapobject=new mapboxgl.Map({
            container: 'map', // container id
            style: 'mapbox://styles/mapbox/streets-v11', //stylesheet location
            center: this.props.mapCenter, // starting position
            zoom: this.props.mapZoom // st arting zoom
        });

        let popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

    mapobject.on('load',() => {

        mapobject.loadImage(this.state.activeUrl, function(error, image) {
            if (error) throw error;
            mapobject.addImage('activemarkerimage', image);
        })

        mapobject.loadImage(this.state.idealUrl, function(error, image) {
            if (error) throw error;
            mapobject.addImage('idealmarkerimage', image);
        })

        mapobject.loadImage(this.state.stoppedUrl, function(error, image) {
            if (error) throw error;
            mapobject.addImage('stoppedmarkerimage', image);
        })

        mapobject.addSource("trucklocations",
        {
            type: "geojson",
            data: {
                type: 'FeatureCollection',
                features: []
            },
            cluster: true, 
            clusterMaxZoom: 14, 
            clusterRadius: 40 
        });
        var layers = [
            [20, '#f28cb1'],
            [10, '#f1f075'],
            [0, '#51bbd6']
        ];    
        layers.forEach(function (layer, i) {
            mapobject.addLayer({
                "id": "cluster-" + i,
                "type": "circle",
                "source": "trucklocations",
                "paint": {
                    "circle-color": layer[1],
                    "circle-radius": 18
                },
                "filter": i === 0 ?
                    [">=", "point_count", layer[0]] :
                    ["all",
                        [">=", "point_count", layer[0]],
                        ["<", "point_count", layers[i - 1][0]]]
            });
        });
        mapobject.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'trucklocations',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12,
                'background-color':'#AAA'
            }
        });
        mapobject.addLayer({
            id: 'truckpoint',
            type: 'symbol',
            source: 'trucklocations',
            filter: ['!has', 'point_count'],
            "layout": {
                "icon-image": "",
                "icon-size": 1
            },
        });
        mapobject.on('mouseenter', 'truckpoint', function(e) {
        
            mapobject.getCanvas().style.cursor = 'pointer';
             
            var coordinates = e.features[0].geometry.coordinates.slice();
            var description = e.features[0].properties;
            
            let speed = parseFloat(description.speed);
            if(isNaN(speed))
            {
                speed ='';
            }
            else{
                speed = speed.toFixed(2) + " mph";
            }
            console.log(speed);
            
            let location = formatLocation(description.location);

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }         
            popup
            .setLngLat(coordinates)
            .setHTML('<span class="map-popup-label">Truck/Asset No. : </span><span class="map-popup-label">'+description.truckNo+'</span></br><span class="map-popup-label1"> Date : </span><span class="map-popup-label">'+description.date+'</span></br><span class="map-popup-label1">Speed : </span><span class="map-popup-label">'+speed+'</span></br><span class="map-popup-label2">Location : </span><span class="map-popup-label">'+location+'</span></br>')
            .addTo(mapobject);
        });

        mapobject.on('mouseleave', 'truckpoint', function() {
            mapobject.getCanvas().style.cursor = '';
            popup.remove();
        });
        this.getData();
    });

    this.setState({
        map : mapobject 
    });
   }
   
   getData() { 
    let mapobject = this.state.map;
    mapobject.triggerRepaint();
    if(this.props.data.length===0){ 
        try{
            console.log('length==0  : ' + mapobject.getSource('trucklocations'));
            mapobject.getSource('trucklocations').setData({
                type: 'FeatureCollection',
                features: []
            });
        }
        catch(e){
            this.state.randomgen = Math.random();
        }
    }
    if(this.props.data.length>0){
            for (var i = this.state.activemarkers.length - 1; i >= 0; i--) {
                this.state.activemarkers[i].remove();
            }
            this.state.activemarkers.length=0;
            let features = this.props.data.map(data => {
                return(
                    {
                        "type":"Feature",
                        "properties":{
                            "id":data.id,
                            "truckNo":data.truckNo,
                            "latitude":data.latitude,
                            "longitude":data.longitude,
                            "speed":data.speed,
                            "address":data.address,
                            "location":data.location,
                            "date":data.convertedDate,
                        },
                        "geometry":{
                            "type":"Point",
                            "coordinates":[                            
                                data.longitude,
                                data.latitude
                            ]
                        }
                    }
                )
            });
            try{
                console.log('Map Object  : ' + mapobject.getSource('trucklocations'));
                mapobject.setCenter(this.props.mapCenter);
                mapobject.setZoom(this.props.mapZoom);
                mapobject.getSource('trucklocations').setData({
                    type: 'FeatureCollection',
                    features: features
                });
                if(this.props.truckstate === 'active'){
                    
                    mapobject.setLayoutProperty('truckpoint', 'icon-image', 'activemarkerimage');
                }
                else if(this.props.truckstate === 'ideal'){
                    mapobject.setLayoutProperty('truckpoint', 'icon-image', 'idealmarkerimage');
                    
                }
                else {
                    mapobject.setLayoutProperty('truckpoint', 'icon-image', 'stoppedmarkerimage');
                }
                $("div.spinner-border").hide();
            }
            catch(e){
                this.state.randomgen = Math.random();
            }
        }
   }
   
   handleEldprovider = (id) =>{
    //alert(JSON.stringify(id));
    this.props.handleEldprovider(id);
   }
    render(){
        let spinner=this.props.spinner;

        const LoaderSpinner =()=>(
            <div className="spinner-border spinner-grow-sm maploader" role="status"></div>
        );

        return (
            <div className="ff"> 
                { spinner ? <LoaderSpinner /> : <Fragment></Fragment> }
                <div id="map"></div>
            </div>
        )
    }
};
const mapStateToProps = (state,ownProps) => {
	return{		
        eldprovider : state.commonReducer.eldproviderlist
	}    
}
export default connect(mapStateToProps)(DashboardMap)