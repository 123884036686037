import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { userlistAction } from '../../../actions/userActions';
import EditBroker from './EditBroker';
class BrokersList extends Component
  {
   	componentDidMount()
   	{
   		var params={
            usertype:'broker',
        }
        this.props.dispatch(userlistAction(params));
   	}

   	render()
   	{
		var userlist = [];
		var userlistforbroker=[];
		var userlistforMybroker=[];
		if(this.props.data.length > 0)
		{
			userlistforbroker = []	;
			userlistforbroker = this.props.data.map(
				row =>{
					return({
						id:row.userId,
						companyname:row.companyName,
						//staricon:<i className="far fa-star staricon"></i>,
						phoneNumber:row.phoneNumber,
						emailId:row.emailId,
						eldproviders:row.eldproviders,
						currentStateName:row.currentStateName,	
						currentStateId:row.currentStateId,
						CurrentZip:row.CurrentZip,
						speciality:row.speciality						
					});
				}
			);
			
		}
		else
		{
			userlistforbroker = [];				
		}
		if(this.props.data.length > 0)
		{
			userlistforMybroker = []	;
			userlistforMybroker = this.props.data.map(
				row =>{
					return({
						id:row.userId,
						companyname:row.companyName,
						//staricon:<i className="far fa-star"></i>,
						phoneNumber:row.phoneNumber,
						emailId:row.emailId,
						eldproviders:row.eldproviders,
						currentStateName:row.currentStateName,	
						currentStateId:row.currentStateId,
						CurrentZip:row.CurrentZip,
						speciality:row.speciality						
					});
				}
			);
			
		}
		else
		{
			userlistforMybroker = [];				
		}	
		const { SearchBar } = Search;
		const columns=[	
		   {
			   text:'Company',
			   dataField:'companyname',
			   sort:true
		   },
		//    {
		// 	   text:'Speciality',
		// 	   dataField:'speciality',
		// 	   sort:true
		//    },
		   {
			   text:'Email',
			   dataField:'emailId',
			   sort:true
		   },
		   {
			   text:'Phone',
			   dataField:'phoneNumber',
			   sort:true
		   },		   
		   {
			   text:'State',
			   dataField:'currentStateName',
			   sort:true
		   }		   
		]
		const customTotal = (from, to, size) => (
			<span className="react-bootstrap-table-pagination-total">
			  Showing { from } to { to } of { size } Results
			</span>
		  );		  
		  const options = {
			paginationSize: 4,
			pageStartIndex: 1,
			firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: customTotal
		  };
	return(
		<article className="table-data truck-data shadow bg-white rounded">
			<div className="row no-gutters">
				<div className="col-md-12">
					<ul className="nav nav-tabs" id="myDocuments" role="tablist">
					  	{/* <li className="nav-item">
					    	<a className="nav-link active" id="brokers-tab" data-toggle="tab" href="#brokers" role="tab" aria-controls="brokers" aria-selected="true"><i className="icofont-user"></i>BROKERS</a>
					  	</li>	 */}				  
					  	<li className="nav-item">
					    	<a className="nav-link active" id="mybrokers-tab" data-toggle="tab" href="#mybrokers" role="tab" aria-controls="mybrokers" aria-selected="false"><i className="icofont-user"></i> MY BROKERS</a>
					  	</li>	
					  	  <div className="flex-left-auto">
					  		<ul>
					  			<li>					  				
					  				<a href="/#" className="btn btn-primary btn-sm" data-toggle="modal" data-target=".addbroker">Add Broker</a>
					  			</li>
					  		</ul>
					  	</div>				  	
					</ul>
					<div className="tab-content" id="myTabContent">
					  	<div className="tab-pane fade show active" id="brokers" role="tabpanel" aria-labelledby="brokers-tab">
						   <ToolkitProvider
									keyField="id"
									data={ userlistforbroker }
									columns={ columns }
									search
								   >
								{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication="No Data Found"  
											hover selectRow={ { mode: 'checkbox' } } bordered={ false }/>
										</div>
									)
								}
							</ToolkitProvider>
					  	</div>
					  	 <div className="tab-pane fade" id="mybrokers" role="tabpanel" aria-labelledby="mybrokers-tab">				  			
						   <ToolkitProvider
									keyField="id"
									data={ userlistforMybroker }
									columns={ columns }
									search
								   >
								{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication="No Data Found"  
											hover  bordered={ false }/>
										</div>
									)
								}
							</ToolkitProvider>
					  	</div>
						  <EditBroker />
					</div>
				</div>
			</div>			
	 </article>
	)
  }
}
const mapStateToProps = (state) => {
    return{
        loading : state.userReducer.loading,
        data : state.userReducer.data,
        pagination : state.userReducer.pagination
    }    
}
	
export default connect(mapStateToProps)(BrokersList);