import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Field, initialize, reduxForm } from 'redux-form';
import { bulkuploadaction, bulkuploadFlagAction, loadAction } from '../../../actions/loadActions';
import { STATUS } from '../../../helpers/status';
import { validate } from './Bulkuploadvalidation';

function bulkuploadsubmit(values)
{ 
    this.props.dispatch(bulkuploadaction(values)); 
}
class LoadBulkupload extends Component{

  state={
      resultfile:'',
      url:'#'
  }  
  componentDidUpdate() 
 {
   const BASE_URL= process.env.REACT_APP_BASE_URL;   
   var resultFile='';
   if(this.props.bulkuploadval)   {    
    if(this.props.bulkuploadflag===true)
    {   
    
       if(this.props.type==="LOAD_BULK_UPLOAD_SUCCESS")
       {
         resultFile = this.props.bulkuploadval.result_file; 
         if(this.props.bulkuploadval.status==='OK')
         {                                                          
            document.querySelector('#bulkuploadclass').setAttribute("href",BASE_URL+'publicresource/download/load/import-status?file_name='+resultFile);        
            toastr.success('Success', 'Load has been Successfully Imported!');
            this.props.dispatch(initialize('loadbulkupload'));
            this.props.dispatch(bulkuploadFlagAction());
            const params={
                ...STATUS.LOAD.REQUESTED
            }               
            this.props.dispatch(loadAction(params)); 
            
            document.getElementById("addloadtabclick").click();
            document.getElementById("addmodalpopupbulkuplod").click();
            document.getElementById("loadbulkuploadfilebroker").value="";
            //this.props.dispatch(reset('loadbulkupload'));
            // setTimeout(function(){
            // window.location.reload();
            // }, 1000);
            document.getElementById("bulkuploadclass").click();  
         }  
         else if(this.props.bulkuploadval.status==='INTERNAL_SERVER_ERROR')
         {
           toastr.error('Error', 'Bad Request!!!!');
           this.props.dispatch(bulkuploadFlagAction());
           document.getElementById("addloadtabclick").click();
           document.getElementById("addmodalpopupbulkuplod").click();
        //    setTimeout(function(){
        //     window.location.reload();
        //     }, 1000);
         } 
         else
         {
           toastr.error('Error', this.props.bulkuploadval.message);
           this.props.dispatch(bulkuploadFlagAction());
           document.getElementById("addloadtabclick").click();
           document.getElementById("addmodalpopupbulkuplod").click();
        //    setTimeout(function(){
        //     window.location.reload();
        //     }, 1000);
         }        
       } 
      }
    }
   else
   {
      resultFile = '';
   }  

 }
 handleFile(fieldName, event) {
   event.preventDefault();
   var fd = new FormData(); 
   var files = event.target.files[0]; 
   fd.append('file', files);
   this.setState({
       files: fd
   });
}
//  renderFileInput = ({ input, type,id, meta: { touched, error } }) => {
//    const { mimeType } = this.props;
//    return (
//       <div className={` ${(touched && error) ? 'error' : ''}`}>
//        <input
//          name={input.name}
//          type={type}
//          id={id}
//          onChange={this.handleFile.bind(this, 'image')}
//        />
//        <br></br><br></br>
//         {touched && (error && <span className="error" style={{padding:'15px'}}>{error}</span>)}
//      </div>
//    );
//  };

adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

renderFileInput = ({
   input: { value: omitValue, onChange, onBlur, ...inputProps },data, label,
   meta:{omitMeta,touched, error},  ...props
 }) => {
   return (
       <div className={` ${(touched && error) ? 'error' : ''}`}>
           <label>{label}</label>
           <input
               onChange={this.adaptFileEventToValue(onChange)}
               //onBlur={this.adaptFileEventToValue(onBlur)}
               type="file"
               {...props.input}
               {...props}
               placeholder={label}
           />  
            <br></br><br></br>      
           {touched && error && <span className="error" style={{padding:'15px'}}>{error}</span>}
       </div>
   );
};


render(){
    const { handleSubmit } = this.props
    const BASE_URL= process.env.REACT_APP_BASE_URL;
return(   
        <form onSubmit={handleSubmit(bulkuploadsubmit.bind(this))}>    
        <div className="row d-flex justify-content-center m-4">                                                
        <div className="col-md-6 text-center">
            <h3 className="mt-5 mb-5">Import bulk data</h3>
            <div className="file-drop-area">                                                           
                <span className="file-msg"></span>                                                            
                    <Field
                    name="bulkuploadfilename"
                    type="file"
                    id="loadbulkuploadfilebroker"
                    component={this.renderFileInput}
                />   
            </div>
            <div className="text-right">
                <br></br>
                <a href={BASE_URL+"publicresource/download/load/import-sample"}>Download sample file</a><br></br>
                <a id="bulkuploadclass" style={{display:'none'}} target="_blank" href="/#" >Download Status file</a>
            </div>
            <br></br>
            <div className="row" style={{float:'right'}}>
                    <div className="col col-md-12">
                        <div className="form-group form-group-btns">
                            <button type="submit" className="btn btn-primary btn-sm">Save</button>&nbsp;                                                                     
                        </div>
                    </div>
                </div>
            </div>                                                    
        </div>    
    </form> 
          )
     }
}
  
const mapStateToProps = state => {
return {
      bulkuploadval: state.loadReducer.loads,
      type:state.loadReducer.type,
      data: state.loadReducer.data,
      loading: state.loadReducer.loading,
      error: state.loadReducer.error,
      bulkuploadflag:state.loadReducer.bulkuploadflag
    }
}

export default connect(mapStateToProps)(reduxForm({ form: 'loadbulkupload',validate })(LoadBulkupload))