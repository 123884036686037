import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
//import DashboardSearch from './DashboardSearch';
import GoogleMapBreadcrump from './GoogleMapBreadcrump';
// import DashboardGoogleMap from './DashboardGoogleMap';
import { eldAssetList, factordashboardAction, factordashboarMultipleElddAction } from '../../../actions/dashboardActions';
import { Row, Col } from 'react-bootstrap';
import { carrierListForFactorService } from './../../../services/companyService';
import { DashboardTrucksComponent, GetCarrier, GetEld, DashboardGoogleMap } from 'UI-Component'
import loadVehicleicon from '../../../images/loadedtruck.png'
import $ from 'jquery'
require('dotenv').config();

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedData: [],
			checkedData: [],
			viewFlag: false,
			routeData: [],
			updated: 0,
			TrucksNum: [],
			mapZoom: 3.5,
			mapCenter: [-96, 37],
			truckState: 'active',
			mapViewMode: 'static',
			truckInfo: {},
			carriers: [],
			eldList: [],
			pageView: '',
			carrierNames: [],
			mapFlag: 0
		}
		this.selectedMenu = 0;
		this.userType = localStorage.getItem('userType');
		this.SUBSCRIPTION_PLAN_ID = localStorage.getItem('SUBSCRIPTION_PLAN_ID');
	}
	componentDidMount() {
		document.title = 'Dashboard';
		let param = {
			companytypeid: 3
		}
		carrierListForFactorService(param).then((response) => {
			if (response.companyList) {
				this.setState({
					...this.state,
					isReady: true,
					carrierNames: response.companyList
				});
			}
			else if (response.status) {
				if (response.status === "UNAUTHORIZED") {
					localStorage.clear();
					window.location = '/login';
				}
			} else {
				this.setState({
					...this.state,
					isReady: true
				});
			}
		});

		let params = {
			carrierCompanyId: 0,
			carriercompanyIdFlag: true
		}
		this.props.dispatch(factordashboardAction(params));
	}
	componentDidUpdate = (prevProps) => {
		if (this.props.dashboard.dashboard && this.props.dashboard.dashboard.eldList) {
			if (prevProps.dashboard.dashboard && prevProps.dashboard.dashboard.eldList) {
				if (this.props.dashboard.dashboard.eldList !== prevProps.dashboard.dashboard.eldList) {

					this.setState({
						eldList: []
					});
					if (this.props.dashboard.dashboard.eldList.length > 0) {
						if (this.selectedMenu === 0) {
							this.selectedMenu = this.props.dashboard.dashboard.eldList[0].ELD_Provider_Id;
						}
					}
				}
			} else {
				if (this.props.dashboard.dashboard.eldList.length > 0) {
					this.selectedMenu = this.props.dashboard.dashboard.eldList[0].ELD_Provider_Id;
				}
			}
		}

		// if(this.state.updated == 0 && this.props.dashboard.dashboard){
		// 	if(this.state.checkedData  !== this.props.dashboard.dashboard.active) {
		// 		this.setState({
		// 			selectedData: this.props.dashboard.dashboard.active,
		// 			checkedData : this.props.dashboard.dashboard.active,
		// 			viewFlag : false,
		// 			routeData : [],
		// 			TrucksNum : this.props.dashboard.dashboard.active,
		// 			carriers:this.props.dashboard.dashboard.carrierList,
		// 			eldList : this.props.dashboard.dashboard.eldList
		// 		});
		// 	}
		// }		
		if (this.state.updated === 0 && this.props.dashboard.dashboard) {
			if (this.state.truckState === "active") {
				if (this.state.selectedData !== this.props.dashboard.dashboard.active) {
					this.setState({
						updated: 1,
						selectedData: this.props.dashboard.dashboard.active,
						checkedData: this.props.dashboard.dashboard.active,
						viewFlag: false,
						routeData: [],
						TrucksNum: this.props.dashboard.dashboard.active,
						carriers: this.props.dashboard.dashboard.carrierList,
						eldList: this.props.dashboard.dashboard.eldList,
						mapZoom: 3.5
					});
				}
			} else if (this.state.truckState === "ideal") {
				if (this.state.selectedData !== this.props.dashboard.dashboard.idle) {
					this.setState({
						updated: 1,
						selectedData: this.props.dashboard.dashboard.idle,
						checkedData: this.props.dashboard.dashboard.idle,
						viewFlag: false,
						routeData: [],
						TrucksNum: this.props.dashboard.dashboard.idle,
						carriers: this.props.dashboard.dashboard.carrierList,
						eldList: this.props.dashboard.dashboard.eldList,
						mapZoom: 3.5
					});
				}
			} else if (this.state.truckState === "stopped") {
				if (this.state.selectedData !== this.props.dashboard.dashboard.stopped) {
					this.setState({
						updated: 1,
						selectedData: this.props.dashboard.dashboard.stopped,
						checkedData: this.props.dashboard.dashboard.stopped,
						viewFlag: false,
						routeData: [],
						TrucksNum: this.props.dashboard.dashboard.stopped,
						carriers: this.props.dashboard.dashboard.carrierList,
						eldList: this.props.dashboard.dashboard.eldList,
						mapZoom: 3.5
					});
				}
			}
		}
	}



	handleTabClicked = (val, stopped) => {
		if (this.props.dashboard.dashboard) {
			if (val === 1) {			// For Active Trucks 			
				this.setState({
					updated: 1,
					selectedData: this.props.dashboard.dashboard.active,
					checkedData: this.props.dashboard.dashboard.active,
					viewFlag: false,
					routeData: [],
					TrucksNum: this.props.dashboard.dashboard.active,
					mapZoom: 3.5,
					mapCenter: [-96, 37],
					truckState: 'active',
					eldList: this.props.dashboard.dashboard.eldList,
					mapViewMode: 'static',
					mapFlag: this.state.mapFlag + 1
				});
			} else if (val === 2) {		// For Idle Trucks			
				this.setState({
					updated: 1,
					selectedData: this.props.dashboard.dashboard.idle,
					checkedData: this.props.dashboard.dashboard.idle,
					viewFlag: false,
					routeData: [],
					TrucksNum: this.props.dashboard.dashboard.idle,
					mapZoom: 3.5,
					mapCenter: [-96, 37],
					truckState: 'ideal',
					eldList: this.props.dashboard.dashboard.eldList,
					mapViewMode: 'static',
					mapFlag: this.state.mapFlag + 1
				});
			} else if (val === 3) {		// For Stopped Trucks			
				this.setState({
					updated: 1,
					selectedData: this.props.dashboard.dashboard.stopped,
					checkedData: this.props.dashboard.dashboard.stopped,
					viewFlag: false,
					routeData: [],
					TrucksNum: this.props.dashboard.dashboard.stopped,
					mapZoom: 3.5,
					mapCenter: [-96, 37],
					truckState: 'stopped',
					eldList: this.props.dashboard.dashboard.eldList,
					mapViewMode: 'static',
					mapFlag: this.state.mapFlag + 1
				});
			}
		}
	}
	handletruckClicked = (selectedTruck) => {
		if (selectedTruck.length === 1) {					// change to route Map			
			this.setState({
				updated: 1,
				viewFlag: true,
				routeData: selectedTruck,
				mapZoom: 12,
				mapFlag: this.state.mapFlag + 1
			});
		} else {											// restore original map
			this.setState({
				updated: 1,
				viewFlag: false,
				routeData: [],
				mapZoom: 12,
				mapFlag: this.state.mapFlag + 1
			});
		}
	}
	handlecheckList = (filteredEle) => {
		switch (filteredEle.length) {
			case 0:
				this.setState({
					updated: 1,
					viewFlag: false,
					checkedData: filteredEle,
					routeData: [],
					mapZoom: 12,
					mapFlag: this.state.mapFlag + 1
				});
				break;
			case 1:
				this.setState({
					updated: 1,
					viewFlag: true,
					routeData: filteredEle,
					checkedData: filteredEle,
					mapZoom: 12,
					mapFlag: this.state.mapFlag + 1
				});
				break;
			default:
				this.setState({
					updated: 1,
					viewFlag: false,
					checkedData: filteredEle,
					routeData: [],
					mapZoom: 12,
					mapFlag: this.state.mapFlag + 1
				});
				break;
		}
	}
	handleEldprovider = (id) => {
		this.props.dispatch(eldAssetList(id));
	}
	_truckClick = (truckInfo) => {
		//console.log('Ideal Trucks Info : --',this.state.selectedData);
		let mapViewMode = 'static';
		if (this.state.truckState === 'active') {
			mapViewMode = 'moving';
		}
		this.setState({
			...this.state,
			checkedData: [truckInfo],
			mapZoom: 12,
			mapCenter: [truckInfo.longitude, truckInfo.latitude],
			mapViewMode: mapViewMode,
			truckInfo: truckInfo,
			mapFlag: this.state.mapFlag + 1
		});
	}
	// showBreadcrump = () => {
	// 	let userType = localStorage.getItem('userType').toLowerCase();
	// 	return userType == "shipper"?(<Fragment></Fragment>):<DashboardBreadcrump trucks={this.state.TrucksNum} timeZones={this.props.timeZones} />
	// }

	showBreadcrump = () => {
		let userType = localStorage.getItem('userType').toLowerCase();
		if (userType === "shipper") {
			return <Fragment></Fragment>;
		} else {
			return <GoogleMapBreadcrump trucks={this.state.TrucksNum} timeZones={this.props.timeZones} />
		}
		//return userType == "shipper"?(<Fragment></Fragment>):<DashboardBreadcrump trucks={this.state.TrucksNum} timeZones={this.props.timeZones} />
	}

	carrierSelect = (key) => {
		this.selectedMenu = key;
		let params = {
			carrierCompanyId: key
		}
		//this.props.dispatch(dashboardAction(params)); 		
		this.props.dispatch(factordashboardAction(params));
		this.setState({
			...this.state,
			mapViewMode: 'static',
			mapZoom: 3.5,
			mapCenter: [-96, 37],
			updated: 0,
			mapFlag: this.state.mapFlag + 1
		});
		// if(this.userType =="broker"){

		// }
		// carrierCompanyId
	}

	eldSelect = (key) => {
		var findcarrierId = $('#findCarrierId option:selected').val(); //alert(findcarrierId)
		this.selectedMenu = key;
		let params = {
			eldProviderId: key,
			carrierCompanyId: findcarrierId
		}
		//this.props.dispatch(dashboardAction(params));
		this.props.dispatch(factordashboardAction(params));
		this.setState({
			...this.state,
			mapViewMode: 'static',
			mapCenter: [-96, 37],
			mapZoom: 3.5,
			updated: 0,
			mapFlag: this.state.mapFlag + 1
		});
	}


	// carrierListpage = (e) => {
	// 	//alert("hii")
	// 	this.selectedMenu=0;
	// 	this.setState({
	//         ...this.state,
	// 		pageView:'CARRIERLISTPAGE',
	//     });
	// }

	loadCarrierDashboardData = (e) => { //alert("hi")
		$('#fromDateTime_input').val('');
		$('#toDateTimevalue_input').val('');
		$('#toTime_input').val('');
		$('#toTimevalue_input').val('');
		$('#breadcrumbtruckno').val('');
		let carrierCompanyId = $(e.target).val();
		let params = {
			carrierCompanyId: carrierCompanyId,
			carriercompanyIdFlag: true
		}
		this.setState({
			...this.state,
			updated: 0
		});
		this.props.dispatch(factordashboardAction(params));
	}
	selectELD = (e, ELD_Provider_Id) => { //alert("hi")
		console.log("onclick")
		// if(this.selectedMenu==ELD_Provider_Id) return;
		// $(".eldliststyle > button.btn-primary").removeClass('btn-primary').addClass('btn-light');
		// $(e.target).removeClass('btn-light').addClass('btn-primary');
		// this.selectedMenu=ELD_Provider_Id;
		$('#fromDateTime_input').val('');
		$('#toDateTimevalue_input').val('');
		$('#toTime_input').val('');
		$('#toTimevalue_input').val('');
		$('#breadcrumbtruckno').val('');
		let carrierCompanyId = $('#findCarrierId').val();
		// let eldProviderId = $('#findSelectEldId').val();
		let params = {
			carrierCompanyId: carrierCompanyId,
			eldProviderId: $(e.target).val()
		}
		this.setState({
			...this.state,
			updated: 0
		});
		this.props.dispatch(factordashboarMultipleElddAction(params));
	}

	getMenu = () => {
		let menu = [];
		if ((this.userType === "factor" || this.userType === "techpartner") && this.state.eldList) {
			menu = (this.props.dashboard.dashboard && this.props.dashboard.dashboard.eldList) ? (this.props.dashboard.dashboard.eldList.map((row, index) => {
				return <button onClick={(e) => { this.selectELD(e, row.ELD_Provider_Id) }} key={index} className={`btn ${(row.ELD_Provider_Id == this.selectedMenu) ? 'btn-primary' : 'btn-light'}`}>{row.ELD_Provider_Disp_Name}</button>
			})) : ('')
		}
		if (menu.length > 0) {
			return <div className="dashboard-top-menu eldliststyle" >{menu}</div>;
		} else {
			return <Fragment></Fragment>
		}
	}
	// getCarrierName = () => {
	// 	return <div>
	// 	<button type="button" className="btn btn-link ml-6 " onClick={(e) =>{this.carrierListpage(e)}} >BACK</button>
	// 	<div className="factorheader">
	// 	<h5><b>You are viewing {this.props.carriercompanyName}</b></h5></div></div>
	// }

	getCarrierDropdown = () => {
		return <div className="carrierDropdown">
			<div className="col col-md-12">
				<label htmlFor="carrierdropdownLabel" className="carrierdropdownLabel" ><b>Select Carrier</b></label>
				<select className="form-control" id="findCarrierId" onChange={(e) => { this.loadCarrierDashboardData(e) }}>
					<option value="0">Select Carrier</option>
					{
						this.state.carrierNames.length > 0 ? (this.state.carrierNames.map(function (company, index) {
							return (
								<option key={index} value={company.Company_Id}>{company.Company_Name}</option>
							)
						})) : (<option>Loading.....</option>)
					}
				</select>
			</div>
		</div>
	}

	getEldProviderDropdown = () => {
		if (this.props?.dashboard?.dashboard?.eldList.length > 0) {
			return <div className="carrierDropdown">
				<div className="col col-md-12">
					<label htmlFor="carrierdropdownLabel" className="carrierdropdownLabel" ><b>Select  ELD Providers</b></label>
					<select className="form-control" id="findSelectEldId" onChange={(e) => { this.selectELD(e) }}>
						{
							(this.props?.dashboard?.dashboard && this.props?.dashboard?.dashboard?.eldList) ? (this.props?.dashboard?.dashboard?.eldList.map(function (row, index) {
								return (
									<option key={index} value={row.ELD_Provider_Id}>{row.ELD_Provider_Disp_Name}</option>
								)
							})) : null
						}
					</select>
				</div>
			</div>
		}

	}
	getDashboardMap = (TOKEN) => {
		let mapCenter = {
			lat: parseFloat(this.state.mapCenter[1]),
			lng: parseFloat(this.state.mapCenter[0])
		}
		let mapZoom = 5;
		return <DashboardGoogleMap mapZoom={mapZoom} mapCenter={mapCenter} mapData={this.state.checkedData} truckstate={this.state.truckState} mapViewMode={this.state.mapViewMode} truckInfo={this.state.truckInfo} location={this.props.history.location}
			mapFlag={this.state.mapFlag} />
	}

	render() {
		// if(this.state.pageView=='CARRIERLISTPAGE')
		// {

		// 	return <CarrierList flag={this.state.pageFlag} carriercompanyName={this.state.carriercompanyName} carrierCompanyId={this.state.carrierCompanyId} />
		// }
		const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
		var dashboard = {};
		var stopped = {};
		var idle = {};
		var active = {};

		if (this.props.dashboard.dashboard) {
			dashboard = this.props.dashboard.dashboard;
			stopped = dashboard.stopped;
			idle = dashboard.idle;
			active = dashboard.active;
		}

		if (this.state.isReady) {
			{ this.showBreadcrump() }
			return (
				<article className="dashboard-data shadow bg-white rounded">
					<Row className="no-gutters">
						<Col md={3} className="pos-rel">
							{/* {this.getCarrierDropdown()}
								{this.getEldProviderDropdown()}

								<DashboardTrucks  stopped={ stopped } idle={ idle } active={ active } loading={this.props.loading}
								tabClicked={(val)=> {this.handleTabClicked(val)}}
								truckClicked={(selectedTruck)=> {this.handletruckClicked(selectedTruck)}}
								selectedData={ this.state.selectedData } truckstate={this.state.truckState}
								checkList={(filteredEle)=> {this.handlecheckList(filteredEle)}}
								_truckClick = {(truckInfo) => { this._truckClick(truckInfo); }}
								/> */}
							{<GetCarrier state={this.state} userType={this.userType} onChange={(e) => { this.loadCarrierDashboardData(e) }} />}
							{<GetEld state={this.state}  dashboard={this.props?.dashboard?.dashboard} eldList={this.props?.dashboard?.dashboard?.eldList} onChange={(e) => { this.selectELD(e) }} />}
							<DashboardTrucksComponent
								disabled={this.state.disabled} stopped={stopped} idle={idle} active={active} loading={this.props.loading}
								tabClicked={(val) => { this.handleTabClicked(val) }}
								truckClicked={(selectedTruck) => { this.handletruckClicked(selectedTruck) }}
								selectedData={this.state.selectedData} truckstate={this.state.truckState}
								checkList={(filteredEle) => { this.handlecheckList(filteredEle) }}
								_truckClick={(truckInfo) => { this._truckClick(truckInfo); }}
								loadloadVehicleicon={loadVehicleicon}
							/>

							{this.showBreadcrump()}
						</Col>
						<Col md={9}>
							{/* {this.getMenu()} */}
							{this.getDashboardMap(TOKEN)}
						</Col>
					</Row>
				</article>
			)
		} else {
			return (
				<div className="text-center">
					<div className="spinner-border" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			);

		}
	}
}


const mapStateToProps = (state, ownProps) => {
	return {
		dashboard: state.dashboardReducer.data,
		loading: state.dashboardReducer.loading
		//dashboard : state.dashboardReducer.eldAssets,
		//trucks: state.truckReducer.trucks
	}
}
export default connect(mapStateToProps)(Dashboard)


