import * as types from '../actions';

const initialState = {
    data: [],
    loading: false,
    error: ''
};

export default function(state = initialState, action) {  
    switch(action.type) {
        case types.ASSET_LIST:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.ASSET_LIST_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.ASSET_LIST_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
            case types.ELD_LIST:
                return {
                    ...state,
                    loading: true,
                    error:''
                };
            case types.ELD_LIST_SUCCESS:
                return { 
                    ...state,
                    data: action.data,
                    loading: false,
                };
            case types.ELD_LIST_ERROR:
                return { 
                    ...state,
                    loading: false,
                    error: action.error
                };
        case types.ASSET_ADD:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.ASSET_ADD_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.ASSET_ADD_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.ASSET_UPDATE:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.ASSET_UPDATE_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.ASSET_UPDATE_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.COMPANY_AUTH_ELD:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.COMPANY_AUTH_ELD_SUCCESS:
            return { 
                ...state,
                data: action.data,
                //companyEldlist:action.data,
                loading: false,
            };
        case types.COMPANY_AUTH_ELD_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.GET_ASSETS_LIST_TECHPARTNER:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.GET_ASSETS_LIST_TECHPARTNER_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.GET_ASSETS_LIST_TECHPARTNER_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
            
        case types.POST_NEW_ELD_DATA:
            return { 
                ...state,
                loading: true,
                error:''
            };      
        case types.POST_NEW_ELD_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.POST_NEW_ELD_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};