import React, { Component } from 'react';
//import CarrierList from './CarrierList';
import CarrierList from '../../common/CarrierList';

class CarriersBroker extends Component{
	state={
	}
	componentDidMount() {
		document.title = 'Carriers';
	}

	render(){		
		return(
				<CarrierList history={this.props.history} companyTypeId={3} statelist={this.props.statelist} country={this.props.country} specialitytypelist={this.props.specialitytypelist} eldproviderlist={this.props.eldproviderlist}/>
		)
	}
}

export default CarriersBroker