import React, { Component } from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { loadService, cancelledbyloadservice, saveQuickDispatch, savepodStatusService, changeLoadStatusService, driverbycompanyidService } from '../../../services/loadService';
import { documentList } from '../../../services/documentService';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { TabsBar, TableDataComponent } from 'UI-Component';
import $ from 'jquery';
import { formData } from '../../../helpers/formData';

class LoadListOnboarded extends Component {
	constructor(props) {
		super(props);
		this.getIndex = this.getIndex.bind(this)
		this.state = {
			loadReq: [],
			loadToBeDis: [],
			loadDispatch: [],
			loadDelivered: [],
			drivers: [],
			DocumentNameLists: [],
			load_doc_nodata: '',
			load_req_nodata: '',
			load_tobedis_nodata: '',
			load_dispatch_nodata: '',
			load_delivered_nodata: '',
			currentTab: 0,
			is_pod_open: false,
			podLoadId: '',
			podLoadNo: '',
			is_doc_open: false
		}
	}
	getIndex(index, selectedId) {
		this.setState({ activeId: selectedId })
	}

	componentDidMount = () => {
		this.loadDriver();
		this.loadReq();
	}

	loadDriver = () => {
		driverbycompanyidService().then((response) => {
			if (response.DriverList) {
				this.setState({
					...this.state,
					drivers: response.DriverList
				});
			}
		});
	}

	loadReq = () => {
		this.setState({
			...this.state,
			load_req_nodata: <div className='spinner-border' role='status'><div className='row react-bs-table-no-data'></div></div>
		});

		let param = { payload: { loadStatusId: 1 } };
		loadService(param).then((response) => {
			if (response.LoadList) {
				let load = response.LoadList.map((row) => {
					row['selDriverPhone'] = '';
					row['selDriverId'] = '';
					return row;
				});
				this.setState({
					...this.state,
					loadReq: load,
					load_req_nodata: 'No data found'
				});
			}
		});
	}

	loadToBeDispatched = () => {
		this.setState({
			...this.state,
			load_tobedis_nodata: <div className='spinner-border' role='status'><div className='row react-bs-table-no-data'></div></div>
		});

		let param = { payload: { loadStatusId: 8 } };
		loadService(param).then((response) => {
			if (response.LoadList) {
				let load = response.LoadList;
				this.setState({
					...this.state,
					loadToBeDis: load,
					load_tobedis_nodata: 'No data found'
				});
			}
		});
	}

	loadDispatched = () => {
		this.setState({
			...this.state,
			load_dispatch_nodata: <div className='spinner-border' role='status'><div className='row react-bs-table-no-data'></div></div>
		});

		let param = { payload: { loadStatusId: 13 } };
		loadService(param).then((response) => {
			if (response.LoadList) {
				let load = response.LoadList;
				this.setState({
					...this.state,
					loadDispatch: load,
					load_dispatch_nodata: 'No data found'
				});
			}
		});
	}

	selDriver = (e, index) => {
		let selVal = $(e.target).val();

		let load = this.state.loadReq[index];
		if (selVal) {
			load.selDriverPhone = this.state.drivers[selVal].Phone_Number;
			load.selDriverId = this.state.drivers[selVal].User_Id;
		}
		else {
			load.selDriverPhone = '';
			load.selDriverId = '';
		}
		let loadReq = this.state.loadReq;
		loadReq[index] = load;
		this.setState({
			...this.state,
			loadReq: loadReq
		});
	}

	assignLoad = (row, index) => {
		if (row.selDriverId) {
			let param = {
				payload: {
					loadId: row.loadId,
					driverId: row.selDriverId
				}
			};

			saveQuickDispatch(param).then((response) => {
				toastr.success('Success', 'Load assigned');
				this.loadReq();
			});
		}
		else {
			toastr.info('Info', 'Please select driver');
		}
	}

	setLoadReq = () => {
		let loadReq = this.state.loadReq.map(
			(row, index) => {
				var pickUpLocation = '';
				if (row.orginCityName !== null && row.orginStateCode !== null && row.orginZipValue !== null) {
					 pickUpLocation = row.orginCityName + ', ' + row.orginStateCode + ', ' + row.orginZipValue
				}
				else if (row.orginCityName !== null && row.orginStateCode !== null) {
					 pickUpLocation = row.orginCityName + ', ' + row.orginStateCode
				}
				else if (row.orginCityName !== null && row.orginZipValue !== null) {
					 pickUpLocation = row.orginCityName + ', ' + row.orginZipValue
				}
				else if (row.orginCityName !== null) {
					 pickUpLocation = row.orginCityName
				}
				else if (row.orginStateCode !== null) {
					 pickUpLocation = row.orginStateCode
				}
				else if (row.orginZipValue !== null) {
					 pickUpLocation = row.orginZipValue
				}


				var DeliveryLocation = '';
				if (row.destinationCityName !== null && row.destinationStateCode !== null && row.destinationZipValue !== null) {
					 DeliveryLocation = row.destinationCityName + ', ' + row.destinationStateCode + ', ' + row.destinationZipValue
				}
				else if (row.destinationCityName !== null && row.destinationStateCode !== null) {
					 DeliveryLocation = row.destinationCityName + ', ' + row.destinationStateCode
				}
				else if (row.destinationCityName !== null && row.destinationZipValue !== null) {
					 DeliveryLocation = row.destinationCityName + ', ' + row.destinationZipValue
				}
				else if (row.destinationCityName !== null) {
					 DeliveryLocation = row.destinationCityName
				}
				else if (row.destinationStateCode !== null) {
					 DeliveryLocation = row.destinationStateCode
				}
				else if (row.destinationZipValue !== null) {
					 DeliveryLocation = row.destinationZipValue
				}
				return ({
					id: row.loadId,
					loadNumber: row.loadNumber,
					stringLoadDate: row.stringLoadDate,
					shipperName: row.shipperName,
					shipperref: row.shipperReferenceNumber,
					brokerName: row.brokerName,
					assetId: row.assetId,
					loadPointsList: row.loadPointsList,
					selBtn: <button className='btn btn-primary assignbtn' onClick={(e) => this.assignLoad(row, index)} >Assign</button>,
					selDriverPhone: row.selDriverPhone,
					selDriverId: row.selDriverId,
					orginCityName: row.orginCityName,
					orginStateName: row.orginStateName,
					pickUpLocation: pickUpLocation,
					DeliveryLocation: DeliveryLocation,
					selDriver: <select className='form-control' onChange={(e) => { this.selDriver(e, index) }}>
						<option value=''>Select</option>
						{this.state.drivers.map((row, index) => {
							return (
								<option key={index} value={index}>{row.First_Name + ' ' + row.Last_Name}</option>
							);
						})}
					</select>
				});
			}
		);
		return loadReq;
	}

	dispatchLoad = (row, index) => {
		let params = {
			payload: { loadId: row.loadId }
		};
		changeLoadStatusService(params).then((response) => {
			toastr.success('Success', 'Load dispatched');
			this.loadToBeDispatched();
		});
	}

	setLoadToBeDis = () => {
		let loadReq = this.state.loadToBeDis.map(
			(row, index) => {
				var pickUpLocation = '';
				if (row.orginCityName !== null && row.orginStateCode !== null && row.orginZipValue !== null) {
					 pickUpLocation = row.orginCityName + ', ' + row.orginStateCode + ', ' + row.orginZipValue
				}
				else if (row.orginCityName !== null && row.orginStateCode !== null) {
					 pickUpLocation = row.orginCityName + ', ' + row.orginStateCode
				}
				else if (row.orginCityName !== null && row.orginZipValue !== null) {
					 pickUpLocation = row.orginCityName + ', ' + row.orginZipValue
				}
				else if (row.orginCityName !== null) {
					 pickUpLocation = row.orginCityName
				}
				else if (row.orginStateCode !== null) {
					 pickUpLocation = row.orginStateCode
				}
				else if (row.orginZipValue !== null) {
					 pickUpLocation = row.orginZipValue
				}


				var DeliveryLocation = '';
				if (row.destinationCityName !== null && row.destinationStateCode !== null && row.destinationZipValue !== null) {
					 DeliveryLocation = row.destinationCityName + ', ' + row.destinationStateCode + ', ' + row.destinationZipValue
				}
				else if (row.destinationCityName !== null && row.destinationStateCode !== null) {
					 DeliveryLocation = row.destinationCityName + ', ' + row.destinationStateCode
				}
				else if (row.destinationCityName !== null && row.destinationZipValue !== null) {
					 DeliveryLocation = row.destinationCityName + ', ' + row.destinationZipValue
				}
				else if (row.destinationCityName !== null) {
					 DeliveryLocation = row.destinationCityName
				}
				else if (row.destinationStateCode !== null) {
					 DeliveryLocation = row.destinationStateCode
				}
				else if (row.destinationZipValue !== null) {
					 DeliveryLocation = row.destinationZipValue
				}
				return ({
					id: row.loadId,
					loadNumber: row.loadNumber,
					stringLoadDate: row.stringLoadDate,
					shipperName: row.shipperName,
					shipperref: row.shipperReferenceNumber,
					brokerName: row.brokerName,
					equipmentType: row.truckTypeName,
					distance: row.distance,
					assetId: row.assetId,
					loadPointsList: row.loadPointsList,
					basecharge: row.baseCharges,
					fulecharge: row.fuelCharges,
					accessiorialcharge: row.accessorialCharges,
					tenderprice: row.shipperLoadPrice,
					weight: row.weight,
					selBtn: <button className='btn btn-primary assignbtn' onClick={(e) => this.dispatchLoad(row, index)} >Dispatch</button>,
					selDriverPhone: row.driverPhone,
					selDriverId: row.driverId,
					driverName: row.driverName,
					orginCityName: row.orginCityName,
					orginStateName: row.orginStateName,
					orginStateName: row.destinationCityName,
					orginCityName: row.destinationStateName,
					pickUpLocation: pickUpLocation,
					DeliveryLocation: DeliveryLocation,
				});
			}
		);
		return loadReq;
	}

	setLoadDispatch = () => {
		let loadReq = this.state.loadDispatch.map(
			(row, index) => {

				var pickUpLocation = '';
				if (row.orginCityName !== null && row.orginStateCode !== null && row.orginZipValue !== null) {
					 pickUpLocation = row.orginCityName + ', ' + row.orginStateCode + ', ' + row.orginZipValue
				}
				else if (row.orginCityName !== null && row.orginStateCode !== null) {
					 pickUpLocation = row.orginCityName + ', ' + row.orginStateCode
				}
				else if (row.orginCityName !== null && row.orginZipValue !== null) {
					 pickUpLocation = row.orginCityName + ', ' + row.orginZipValue
				}
				else if (row.orginCityName !== null) {
					 pickUpLocation = row.orginCityName
				}
				else if (row.orginStateCode !== null) {
					 pickUpLocation = row.orginStateCode
				}
				else if (row.orginZipValue !== null) {
					 pickUpLocation = row.orginZipValue
				}


				var DeliveryLocation = '';
				if (row.destinationCityName !== null && row.destinationStateCode !== null && row.destinationZipValue !== null) {
					 DeliveryLocation = row.destinationCityName + ', ' + row.destinationStateCode + ', ' + row.destinationZipValue
				}
				else if (row.destinationCityName !== null && row.destinationStateCode !== null) {
					 DeliveryLocation = row.destinationCityName + ', ' + row.destinationStateCode
				}
				else if (row.destinationCityName !== null && row.destinationZipValue !== null) {
					 DeliveryLocation = row.destinationCityName + ', ' + row.destinationZipValue
				}
				else if (row.destinationCityName !== null) {
					 DeliveryLocation = row.destinationCityName
				}
				else if (row.destinationStateCode !== null) {
					 DeliveryLocation = row.destinationStateCode
				}
				else if (row.destinationZipValue !== null) {
					 DeliveryLocation = row.destinationZipValue
				}
				return ({
					id: row.loadId,
					loadNumber: row.loadNumber,
					stringLoadDate: row.stringLoadDate,
					shipperName: row.shipperName,
					shipperref: row.shipperReferenceNumber,
					brokerName: row.brokerName,
					equipmentType: row.truckTypeName,
					distance: row.distance,
					assetId: row.assetId,
					loadPointsList: row.loadPointsList,
					basecharge: row.baseCharges,
					fulecharge: row.fuelCharges,
					accessiorialcharge: row.accessorialCharges,
					tenderprice: row.shipperLoadPrice,
					weight: row.weight,
					selBtn: <button className='btn btn-primary assignbtn' onClick={(e) => this.openPod(row, index)} >POD</button>,
					selDriverPhone: row.driverPhone,
					selDriverId: row.driverId,
					driverName: row.driverName,
					orginCityName: row.orginCityName,
					orginStateName: row.orginStateName,
					pickUpLocation: pickUpLocation,
					DeliveryLocation: DeliveryLocation,
				});
			}
		);
		return loadReq;
	}

	loadCancel = () => {
		let loads = [];
		if (this.state.currentTab === 0) {
			if (this.tblLoadReq.selectionContext) {
				if (this.tblLoadReq.selectionContext.selected) {
					if (this.tblLoadReq.selectionContext.selected.length > 0) {
						loads = this.tblLoadReq.selectionContext.selected;
					}
				}
			}
		}
		else if (this.state.currentTab === 2) {
			if (this.tblLoadReq.selectionContext) {
				if (this.tblLoadReq.selectionContext.selected) {
					if (this.tblLoadReq.selectionContext.selected.length > 0) {
						loads = this.tblLoadReq.selectionContext.selected;
					}
				}
			}
		}

		if (loads.length === 0) {
			toastr.info('Info', 'Please select load(s)');
		}
		else {
			let params = { payload: { loadsId: loads } };
			cancelledbyloadservice(params).then((response) => {
				toastr.success('Success', 'Load(s) canceled');
				if (this.state.currentTab === 0) {
					this.loadReq();
				}
			});
		}


	}

	setLoadDelivered = () => {
		let loadReq = this.state.loadDelivered.map(
			(row, index) => {
				var pickUpLocation = '';
				if (row.orginCityName !== null && row.orginStateCode !== null && row.orginZipValue !== null) {
					 pickUpLocation = row.orginCityName + ', ' + row.orginStateCode + ', ' + row.orginZipValue
				}
				else if (row.orginCityName !== null && row.orginStateCode !== null) {
					 pickUpLocation = row.orginCityName + ', ' + row.orginStateCode
				}
				else if (row.orginCityName !== null && row.orginZipValue !== null) {
					 pickUpLocation = row.orginCityName + ', ' + row.orginZipValue
				}
				else if (row.orginCityName !== null) {
					 pickUpLocation = row.orginCityName
				}
				else if (row.orginStateCode !== null) {
					 pickUpLocation = row.orginStateCode
				}
				else if (row.orginZipValue !== null) {
					 pickUpLocation = row.orginZipValue
				}
				var DeliveryLocation = '';
				if (row.destinationCityName !== null && row.destinationStateCode !== null && row.destinationZipValue !== null) {
					 DeliveryLocation = row.destinationCityName + ', ' + row.destinationStateCode + ', ' + row.destinationZipValue
				}
				else if (row.destinationCityName !== null && row.destinationStateCode !== null) {
					 DeliveryLocation = row.destinationCityName + ', ' + row.destinationStateCode
				}
				else if (row.destinationCityName !== null && row.destinationZipValue !== null) {
					 DeliveryLocation = row.destinationCityName + ', ' + row.destinationZipValue
				}
				else if (row.destinationCityName !== null) {
					 DeliveryLocation = row.destinationCityName
				}
				else if (row.destinationStateCode !== null) {
					 DeliveryLocation = row.destinationStateCode
				}
				else if (row.destinationZipValue !== null) {
					 DeliveryLocation = row.destinationZipValue
				}
				return ({
					id: row.loadId,
					loadNumber: row.loadNumber,
					stringLoadDate: row.stringLoadDate,
					shipperName: row.shipperName,
					shipperref: row.shipperReferenceNumber,
					brokerName: row.brokerName,
					equipmentType: row.truckTypeName,
					distance: row.distance,
					assetId: row.assetId,
					loadPointsList: row.loadPointsList,
					basecharge: row.baseCharges,
					fulecharge: row.fuelCharges,
					accessiorialcharge: row.accessorialCharges,
					tenderprice: row.shipperLoadPrice,
					weight: row.weight,
					selDriverPhone: row.driverPhone,
					selDriverId: row.driverId,
					driverName: row.driverName,
					orginCityName: row.orginCityName,
					orginStateName: row.orginStateName,
					pickUpLocation: pickUpLocation,
					DeliveryLocation: DeliveryLocation,
					docicon: <i data-toggle='modal' data-target='.lanehistorymodal' id='lanehistoryclass' onClick={() => { this.dochistory(row); }} className='icofont-ui-folder'></i>
				});
			}
		);
		return loadReq;
	}

	loadDelivered = () => {
		this.setState({
			...this.state,
			load_delivered_nodata: <div className='spinner-border' role='status'><div className='row react-bs-table-no-data'></div></div>
		});

		let param = { payload: { loadStatusId: 10 } };
		loadService(param).then((response) => {
			if (response.LoadList) {
				let load = response.LoadList;
				this.setState({
					...this.state,
					loadDelivered: load,
					load_delivered_nodata: 'No data found'
				});
			}
		});
	}

	tabChange = (index) => {

		if (index === this.state.currentTab) return;

		if (index === 0) {
			this.loadReq();
		}
		else if (index === 1) {
			this.loadToBeDispatched();
		}
		else if (index === 2) {
			this.loadDispatched();
		}
		else if (index === 3) {
			this.loadDelivered();
		}
		this.setState({
			...this.state,
			currentTab: index
		});
	}

	openPod = (data, index) => {
		this.setState({
			...this.state,
			is_pod_open: true,
			podLoadId: data.loadId,
			podLoadNo: data.loadNumber
		});
	}

	closePOD = () => {
		this.setState({
			...this.state,
			is_pod_open: false,
			podLoadId: '',
			podLoadNo: ''
		});
	}

	savePOD = () => {
		let form = document.getElementById('frmpod');
		let podData = formData(form);
		this.closePOD();
		let param = { payload: podData }

		savepodStatusService(param).then((response) => {
			toastr.success('Success', 'Load POD saved Successfully');
			this.loadDispatched();
		});
	}

	actionButtons = () => {
		if (this.state.currentTab === 0 || this.state.currentTab === 1) {
			return (
				<ul className='ul-horizontal tab-menus'>
					<li>
						<a href='/#' onClick={(e) => { this.loadCancel() }} className='btn_carrier_move btn btn-sm btn-primary'>Cancel</a>

					</li>
					<li>
						<Link className='btn_carrier_move btn btn-sm btn-primary' to='cancelledLoads'> Cancelled</Link>
					</li>
				</ul>
			);
		}
		else {
			return (
				<ul className='ul-horizontal tab-menus'>
					<li>
						<Link className='btn_carrier_move btn btn-sm btn-primary' to='cancelledLoads'> Cancelled</Link>
					</li>
				</ul>
			);
		}
	}



	dochistory(row) {
		this.setState({
			...this.state,
			load_doc_nodata: <div className='spinner-border' role='status'><div className='row react-bs-table-no-data'></div></div>
		});
		this.setState({
			is_doc_open: true
		})
		const param = {
			loadId: row.loadId
		}
		documentList(param).then((response) => {
			if (response.DocumentList) {
				let docName = response.DocumentList;
				this.setState({
					...this.state,
					DocumentNameLists: docName,
					load_doc_nodata: 'No data found'
				});
			} else if (response.status === 'BAD_REQUEST') {
				this.setState({
					...this.state,
					DocumentNameLists: [],
					load_doc_nodata: 'No data found'
				});
			}
		});
	}

	closeDoc = () => {
		this.setState({
			...this.state,
			is_doc_open: false,
		});
	}

	downloadImg(row) {
		const BASE_URL = process.env.REACT_APP_BASE_URL;
		document.querySelector('#bulkuploadclass').setAttribute('href', row.DocumentUrl);
		document.getElementById('bulkuploadclass').click();
	}

	DocumentData = () => {
		var icon;
		let docDetails = this.state.DocumentNameLists.map(
			(row, index) => {
				var convertDocTypeToLowerCase = null;
				if (row.DocumentType !== null) {
					convertDocTypeToLowerCase = row.DocumentType.toLowerCase()
				}
				if (convertDocTypeToLowerCase === 'pdf') {
					 icon = <i data-toggle='modal' data-target='.lanehistorymodal' id='documentDetails' title='PDF'
						onClick={() => { this.downloadImg(row); }} className='icofont-file-pdf'></i>
				}
				else if (convertDocTypeToLowerCase === 'doc' || convertDocTypeToLowerCase === 'docx') {
					 icon = <i data-toggle='modal' data-target='.lanehistorymodal' id='lanehistoryclass' title='Document'
						onClick={() => { this.downloadImg(row); }} className='icofont-document'></i>
				}
				else if (convertDocTypeToLowerCase === 'xls' || convertDocTypeToLowerCase === 'xlsx') {
					 icon = <i data-toggle='modal' data-target='.lanehistorymodal' id='lanehistoryclass' title='Excel'
						onClick={() => { this.downloadImg(row); }} className='icofont-file-excel'></i>
				}
				else if (convertDocTypeToLowerCase === 'jpg' || convertDocTypeToLowerCase === 'jpeg' || convertDocTypeToLowerCase === 'png' || convertDocTypeToLowerCase === 'gif') {
					 icon = <i data-toggle='modal' data-target='.lanehistorymodal' id='lanehistoryclass' title='Image'
						onClick={() => { this.downloadImg(row); }} className='icofont-image'></i>
				}
				else {
					 icon = <i data-toggle='modal' data-target='.lanehistorymodal' id='lanehistoryclass' title='Download'
						onClick={() => { this.downloadImg(row); }} className='icofont-download'></i>
				}

				return ({
					id: row.DocumentId,
					docName: row.DocumentName,
					uploaddate: row.UploadedDate,
					downloadicon: icon
				});
			}
		);
		return docDetails;
	}

	render = () => {
		let loadReq = this.setLoadReq();
		let loadReqCols = [
			{
				text: 'Load #',
				dataField: 'loadNumber',
				sort: true
			},
			{
				text: 'Date',
				dataField: 'stringLoadDate',
				sort: true
			},
			{
				text: 'Shipper',
				dataField: 'shipperName',
				sort: true
			},
			{
				text: 'Shipper Ref',
				dataField: 'shipperref',
				sort: true
			},
			{
				text: 'Broker',
				dataField: 'brokerName',
				sort: true
			},
			{
				text: 'Origin',
				dataField: 'pickUpLocation',
				sort: true
			},
			{
				text: 'Destination',
				dataField: 'DeliveryLocation',
				sort: true
			},
			{
				text: 'Driver Name',
				dataField: 'selDriver',
				headerStyle: { width: '10%' }
			},
			{
				text: 'Driver Phone',
				dataField: 'selDriverPhone',
				headerStyle: { width: '10%' }
			},
			{
				dataField: 'selBtn',
				text: ' ',
				headerStyle: { width: '6%' }
			}
		];

		let loadToBeDis = this.setLoadToBeDis();

		let loadToBeDisCols = [
			{
				text: 'Load #',
				dataField: 'loadNumber',
				sort: true
			},
			{
				text: 'Date',
				dataField: 'stringLoadDate',
				sort: true
			},
			{
				text: 'Shipper',
				dataField: 'shipperName',
				sort: true
			},
			{
				text: 'Shipper Ref',
				dataField: 'shipperref',
				sort: true
			},
			{
				text: 'Broker',
				dataField: 'brokerName',
				sort: true
			},
			{
				text: 'Origin',
				dataField: 'pickUpLocation',
				sort: true
			},
			{
				text: 'Destination',
				dataField: 'DeliveryLocation',
				sort: true
			},
			{
				text: 'Driver Name',
				dataField: 'driverName',
				headerStyle: { width: '10%' }
			},
			{
				text: 'Driver Phone',
				dataField: 'selDriverPhone',
				headerStyle: { width: '10%' }
			},
			{
				dataField: 'selBtn',
				text: ' ',
				headerStyle: { width: '6%' }
			}
		];

		let loadDispatch = this.setLoadDispatch();

		let loadDispatchCols = [
			{
				text: 'Load #',
				dataField: 'loadNumber',
				sort: true
			},
			{
				text: 'Date',
				dataField: 'stringLoadDate',
				sort: true
			},
			{
				text: 'Shipper',
				dataField: 'shipperName',
				sort: true
			},
			{
				text: 'Shipper Ref',
				dataField: 'shipperref',
				sort: true
			},
			{
				text: 'Broker',
				dataField: 'brokerName',
				sort: true
			},
			{
				text: 'Origin',
				dataField: 'pickUpLocation',
				sort: true
			},
			{
				text: 'Destination',
				dataField: 'DeliveryLocation',
				sort: true
			},
			{
				text: 'Driver Name',
				dataField: 'driverName',
				headerStyle: { width: '10%' }
			},
			{
				text: 'Driver Phone',
				dataField: 'selDriverPhone',
				headerStyle: { width: '10%' }
			},
			{
				dataField: 'selBtn',
				text: ' ',
				headerStyle: { width: '6%' }
			}
		];

		let loadDelivered = this.setLoadDelivered();

		let loadDeliveredCols = [
			{
				text: 'Load #',
				dataField: 'loadNumber',
				sort: true
			},
			{
				text: 'Date',
				dataField: 'stringLoadDate',
				sort: true
			},
			{
				text: 'Shipper',
				dataField: 'shipperName',
				sort: true
			},
			{
				text: 'Shipper Ref',
				dataField: 'shipperref',
				sort: true
			},
			{
				text: 'Broker',
				dataField: 'brokerName',
				sort: true
			},
			{
				text: 'Origin',
				dataField: 'pickUpLocation',
				sort: true
			},
			{
				text: 'Destination',
				dataField: 'DeliveryLocation',
				sort: true
			},
			{
				text: 'Driver Name',
				dataField: 'driverName',
				headerStyle: { width: '10%' }
			},
			{
				text: 'Driver Phone',
				dataField: 'selDriverPhone',
				headerStyle: { width: '10%' }
			},
			{
				text: 'Document',
				dataField: 'docicon',
				sort: true
			},
		];
		const { SearchBar } = Search;

		const customTotal = (from, to, size) => (
			<span className='react-bootstrap-table-pagination-total'>
				Showing {from} to {to} of {size} Results
			</span>
		);

		const options = {
			paginationSize: 4,
			pageStartIndex: 1,
			firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: customTotal
		};

		const expandRow = {
			renderer: (row, index) => {
				return (
					<article className='table-data truck-data shadow bg-white rounded'>
						<div className='row no-gutters'>
							<div className='col-md-12'>

								<ul className='nav nav-tabs' id='myTrucks' role='tablist'>
									<li className='nav-item'>
										<a className='nav-link active' id='pickup-tab' data-toggle='tab' href={`#pickup_${row.id}`} role='tab' aria-controls='pickup' aria-selected='true' >
											pickup Details
										</a>
									</li>
									<li className='nav-item'>
										<a className='nav-link' id='delivery-tab' data-toggle='tab' href={`#delivery_${row.id}`} role='tab' aria-controls='delivery' aria-selected='false' >
											Delivery Details
										</a>
									</li>
								</ul>
								<div className='tab-content' id='myTabContent'>
									<div className='tab-pane fade show active' id={`pickup_${row.id}`} role='tabpanel' aria-labelledby='pickup-tab'>
										<table className='table table-sm'>
											<thead>
												<tr>
													<th scope='col'>Address</th>
													<th scope='col'>Contact</th>
													<th scope='col'>Earliest Time</th>
													<th scope='col'>Latest Time</th>
												</tr>
											</thead>
											<tbody>
												{
													row.loadPointsList.length ? (row.loadPointsList.map(function (doc, index) {
														if (doc.loadPointTypeId === 2) {
															var Contact = (doc.contactAddress1 == null ? '' : doc.contactAddress1);
															Contact = (Contact === '' ? '' : (Contact + ', ')) + (doc.contactAddress2 == null ? '' : doc.contactAddress2);
															Contact = (Contact === '' ? '' : (Contact + ', ')) + (doc.contactZip == null ? '' : doc.contactZip);
															return (
																<tr key={index}>
																	<td>{Contact}</td>
																	<td>{doc.contactNumber}</td>
																	<td>{doc.earliestTime}</td>
																	<td>{doc.latestTime}</td>
																</tr>
															)
														}
													})) : (<tr><td className='text-center' colspan='4'>No Record Found</td></tr>)
												}
											</tbody>
										</table>
									</div>
									<div className='tab-pane fade' id={`delivery_${row.id}`} role='tabpanel' aria-labelledby='delivery-tab'>
										<table className='table table-sm'>
											<thead>
												<tr>
													<th scope='col'>Address</th>
													<th scope='col'>Contact</th>
													<th scope='col'>Earliest Time</th>
													<th scope='col'>Latest Time</th>
												</tr>
											</thead>
											<tbody>
												{
													row.loadPointsList.length ? (row.loadPointsList.map(function (doc, index) {
														if (doc.loadPointTypeId === 3) {
															var Contact = (doc.contactAddress1 == null ? '' : doc.contactAddress1);
															Contact = (Contact === '' ? '' : (Contact + ', ')) + (doc.contactAddress2 == null ? '' : doc.contactAddress2);
															Contact = (Contact === '' ? '' : (Contact + ', ')) + (doc.contactZip == null ? '' : doc.contactZip);
															return (
																<tr key={index}>
																	<td>{Contact}</td>
																	<td>{doc.contactNumber}</td>
																	<td>{doc.earliestTime}</td>
																	<td>{doc.latestTime}</td>
																</tr>
															)
														}
													})) : (<tr><td className='text-center' colspan='4'>No Record Found</td></tr>)
												}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</article>
				)
			},
			showExpandColumn: true,
			expandByColumnOnly: true,
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				if (isAnyExpands) {
					return (
						<i className='icofont-minus-circle'></i>
					);
				}
				return (
					<i className='icofont-plus-circle'></i>
				);
			},
			expandColumnRenderer: ({ expanded }) => {
				if (expanded) {
					return (
						<i className='icofont-minus-circle'></i>
					);
				}
				return (
					<i className='icofont-plus-circle'></i>
				);
			}
		};

		let docData = this.DocumentData();

		let DocCols = [
			{
				text: 'Document Title',
				dataField: 'docName',
				sort: true
			},
			{
				text: 'Uploaded Date',
				dataField: 'uploaddate',
				sort: true
			},
			{
				text: 'Document',
				dataField: 'downloadicon',
				sort: true
			},
		];

		return (
			<article className='table-data truck-data shadow bg-white rounded'>
				<div className='row no-gutters'>
					<div className='col-md-12'>
						<div className='row'>
							<div className='col-md-12'>
								<div className='d-flex justify-content-end'>
									{this.actionButtons()}
								</div>
							</div>
						</div>
						<TabsBar tabsData={
							[{ component: 'REQUESTED', tabID: 'requested-tab', refData: '#requested', methodName: (e, data, index) => { this.tabChange(0) } },
							{ component: 'TO BE DISPATCHED', tabID: 'notyetdispatchedload-tab', refData: '#loadtobedisp', methodName: (e, data, index) => { this.tabChange(1) } },
							{ component: 'PICKED UP', tabID: 'dispatchedenrouteload-tab', refData: '#loadDispatched', methodName: (e, data, index) => { this.tabChange(2) } },
							{ component: 'DELIVERED', tabID: 'deliveredload-tab', refData: '#loadDelivered', methodName: (e, data, index) => { this.tabChange(3) } }
							]}
							getIndex={this.getIndex}
							activeTab={'#requested'}
						/>
						<div className='tab-content' id='myTabContent'>
							<div className={this.state.currentTab === 0 ? 'tab-pane fade show active' : 'tab-pane'} id='requested' role='tabpanel' aria-labelledby='requested-tab'>
								<TableDataComponent tableData={loadReq} columnData={loadReqCols} noDataIndication={this.state.load_req_nodata} expandRow={expandRow} />
							</div>
							<div className={this.state.currentTab === 1 ? 'tab-pane fade show active' : 'tab-pane'} id='loadtobedisp' role='tabpanel' aria-labelledby='requested-tab'>
								<ToolkitProvider
									keyField='id'
									data={loadToBeDis}
									columns={loadToBeDisCols}
									search
								>
									{
										props => (
											<div>
												<SearchBar {...props.searchProps} />
												<BootstrapTable bootstrap4 ref={n => this.tblLoadTobeDis = n}
													{...props.baseProps} pagination={paginationFactory(options)} noDataIndication={this.state.load_tobedis_nodata} hover
													bordered={false}
													expandRow={expandRow} />
											</div>
										)
									}
								</ToolkitProvider>
							</div>
							<div className={this.state.currentTab === 2 ? 'tab-pane fade show active' : 'tab-pane'} id='loadDispatched' role='tabpanel' aria-labelledby='requested-tab'>
								<ToolkitProvider
									keyField='id'
									data={loadDispatch}
									columns={loadDispatchCols}
									search
								>
									{
										props => (
											<div>
												<SearchBar {...props.searchProps} />
												<BootstrapTable bootstrap4 ref={n => this.tblLoadDispatch = n}
													{...props.baseProps} pagination={paginationFactory(options)} noDataIndication={this.state.load_dispatch_nodata} hover
													bordered={false}
													expandRow={expandRow} />
											</div>
										)
									}
								</ToolkitProvider>
							</div>
							<div className={this.state.currentTab === 3 ? 'tab-pane fade show active' : 'tab-pane'} id='loadDelivered' role='tabpanel' aria-labelledby='requested-tab'>
								<ToolkitProvider
									keyField='id'
									data={loadDelivered}
									columns={loadDeliveredCols}
									search
								>
									{
										props => (
											<div>
												<SearchBar {...props.searchProps} />
												<BootstrapTable bootstrap4 ref={n => this.tblLoadDelivered = n}
													{...props.baseProps} pagination={paginationFactory(options)} noDataIndication={this.state.load_dispatch_nodata} hover
													bordered={false}
													expandRow={expandRow} />
											</div>
										)
									}
								</ToolkitProvider>
							</div>
						</div>
					</div>
				</div>
				<Modal show={this.state.is_pod_open} >
					<ModalHeader>
						<ModalTitle>Proof Of Delivery</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<form id='frmpod'>
							<div className='row'>
								<div className='col-md-4 col-sm-4 col-xs-12 lbl'>
									<label>Load #</label>
								</div>
								<div className='col-md-8 col-sm-8 col-xs-12'>
									<label>{this.state.podLoadNo}</label>
									<input type='hidden' className='json-col' name='loadId' value={this.state.podLoadId} />
								</div>
							</div><br />
							<div className='row'>
								<div className='col-md-4 col-sm-4 col-xs-12 lbl' >
									<label>POD #</label>
								</div>
								<div className='col-md-8 col-sm-8 col-xs-12'>
									<input type='text' className='form-control json-col' name='POD_NO' />
								</div>
							</div><br />
							<div className='row'>
								<div className='col-md-4 col-sm-4 col-xs-12 lbl'>
									<label>POD Image</label>
								</div>
								<div className='col-md-8 col-sm-8 col-xs-12'>
									<input type='file' id='podfileid' />
								</div>
							</div>
						</form>
					</ModalBody>
					<ModalFooter>
						<button type='button' className='btn btn-primary' onClick={() => { this.savePOD() }}>Submit</button>
						<button type='button' className='btn btn-primary' onClick={() => { this.closePOD() }}>Close</button>
					</ModalFooter>
				</Modal>
				<Modal show={this.state.is_doc_open} >
					<ModalHeader>
						<ModalTitle>Document Details</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<a id='bulkuploadclass' style={{ display: 'none' }} target='_blank' href='/#' />
						<TableDataComponent tableData={docData} columnData={DocCols} noDataIndication={this.state.load_doc_nodata} />
					</ModalBody>
					<ModalFooter>
						<button type='button' className='btn btn-primary' onClick={() => { this.closeDoc() }}>Close</button>
					</ModalFooter>
				</Modal>
			</article>
		);
	}
}
const mapStateToProps = state => {
	return {
		data: state.loadReducer.data
	}
}
export default connect(mapStateToProps)(LoadListOnboarded);