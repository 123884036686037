export const validate = values => {
      const errors = {}     
      if (!values.name) {
        errors.name = 'Required'
      }
      if (!values.expiration) {
        errors.expiration = 'Required'
      }
      if (!values.issued_by) {
        errors.issued_by = 'Required'
      }
      if (!values.issued_date) {
        errors.issued_date = 'Required'
      }
      if (!values.others) {
        errors.others = 'Required'
      }
      if (!values.select_file) {
        errors.select_file = 'Required'
      }
      if (!values.members || !values.members.length) {
        errors.members = {}
      } else {
        const membersArrayErrors = []
        values.members.forEach((member, memberIndex) => {
          const memberErrors = {}        
          if (!member || !member.name) {
            memberErrors.name = 'Required'
            membersArrayErrors[memberIndex] = memberErrors
          }
          if (!member || !member.expiration) {
            memberErrors.expiration = 'Required'
            membersArrayErrors[memberIndex] = memberErrors
          }
          if (!member || !member.issued_by) {
            memberErrors.issued_by = 'Required'
            membersArrayErrors[memberIndex] = memberErrors
          }
          if (!member || !member.issued_date) {
            memberErrors.issued_date = 'Required'
            membersArrayErrors[memberIndex] = memberErrors
          }
          if (!member || !member.others) {
            memberErrors.others = 'Required'
            membersArrayErrors[memberIndex] = memberErrors
          }
          if (!member || !member.select_file) {
            memberErrors.select_file = 'Required'
            membersArrayErrors[memberIndex] = memberErrors
          }
        })
        if (membersArrayErrors.length) {
          errors.members = membersArrayErrors
        }
      }
      return errors
    }