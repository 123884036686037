import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { ContactListactions } from '../../../actions/contactActions';
import ContactsList from './ContactsList';

class Contacts extends Component{
	componentDidUpdate()
	{
		if(this.props.data.status==="ALREADY_REPORTED")
        {
		   toastr.info('Info', 'Contact Already Exists!');
		   this.props.dispatch(ContactListactions());	
        }
		else if(this.props.data.status==="CREATED")
		{              
			toastr.success('Success', 'Contact has been Successfully Created!');
			document.getElementById("addcontact").click(); 
			this.props.dispatch(ContactListactions());
		}
		else if(this.props.data.status==="ACCEPTED")
		{              
			toastr.success('Success', 'Contact has been Successfully Updated!');
			document.getElementById("editcontact").click(); 
			this.props.dispatch(ContactListactions());
		}
		else if(this.props.data.status==="OK")
        {
			toastr.success('Success', 'Contact Deleted Successfully!');
			this.props.dispatch(ContactListactions());
        }
	}
	
	componentDidMount() {
		document.title = 'Contacts';
	}

	render(){
		return(
			<Fragment>
				<ContactsList />
			</Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		data: state.contactReducer.data,
		error: state.contactReducer.error,
	}
}
export default connect(mapStateToProps)(Contacts)