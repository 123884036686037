import React, { Component } from 'react';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { assetlist } from '../../../actions/assetAction';
import EditTruckPopup from './EditTruckPopup';
import {TableDataComponent } from 'UI-Component';

class Trucklist extends Component {
	constructor(props) {
		super(props);		
		this.totalItemsCount=1;
		this.activePage=1;
		this.itemsCountPerPage=10;
		this.pageRangeDisplayed=5;
	}
	componentDidMount() {	
		const params={				
			assettype: 'truck',		
		}		
		this.props.dispatch(assetlist(params));  	
	}
	state={
	   modaldata:[]
	}	
	getModal = (tList,dispatch) => {	
		this.setState({ showModal: true, modaldata: tList });
	};
	render(){
		var products=[];		
		if(this.props.loading){
			var indicationhtml = 
			<div className="spinner-border" role="status">
			 <div className="row react-bs-table-no-data"></div>
			 </div>
		}
		else if(this.props.trucks.assetList){
			 products = this.props.trucks.assetList.map(
				row =>{
					return({
						id:row.asset_id,
						Truck:row.asset_Number,
						Year:row.model_Year,
						Make:row.make,
						Model:row.model,
						VIN:row.identification_No,
						Plate:row.license_Plate_No,
						Color:row.color,
						More:<div className="dropdown">
						<label data-toggle="dropdown">&hellip;</label>
						<div className="dropdown-menu">
						<a className="dropdown-item" data-toggle="modal" data-target=".bd-trucksedit-modal-lg"
						 href="/#" onClick={() => {this.getModal(row)}}><i className="icofont-ui-edit"></i> Edit</a>
						<Link className="dropdown-item" 
						to={
							{ 
								pathname: "viewtruck/"+row.asset_id,
								aboutProps: {
									id:row.asset_id,
									truckno:row.asset_Number,
									model_Year:row.model_Year,
									make:row.make,
									model:row.model,
									identification_No:row.identification_No,
									license_Plate_No:row.license_Plate_No,
									color:row.color,
									device_id:row.device_id,
									eld_Provider_Id:row.eld_Provider_Id,
									trucktype:row.truck_Type_Id
								}
							}
						}
						><i className="icofont-eye-alt"></i> View</Link>					
						</div>
						</div>		
					});
				}
			);
		}
		else
		{
			products=[];
			indicationhtml = "No Data Found";
		}
       const columns=[				
		      {
				dataField: 'Truck',
				text: 'Truck #',
				sort: true
		      },
		      {
				dataField: 'Year',
				text: 'Year',
				sort: true
		      },
		      {
				dataField: 'Make',
				text: 'Make',
				sort: true
		      },
		      {
				dataField: 'Model',
				text: 'Model',
				sort: true
		      },
		      {
				dataField: 'VIN',
				text: 'VIN',
				sort: true
		      },
		      {
				dataField: 'Plate',
				text: 'Plate #',
				sort: true
		      },
		      {
				dataField: 'Color',
				text: 'Color',
				sort: true
		      },		     
		      {
				dataField: 'More',
				text: 'More'
			  }]
		return(
			<article className="table-data truck-data shadow bg-white rounded">			
				<div className="row no-gutters">
					<div className="col-md-12">
						<ul className="nav nav-tabs" id="myTrucks" role="tablist">
							  <li className="nav-item">
								<a className="nav-link active" id="mytruck-tab" data-toggle="tab" href="#mytruck" role="tab" aria-controls="mytruck" aria-selected="true"><i className="icofont-truck-loaded"></i>My Trucks</a>
							  </li>					  	
						</ul>
						<div className="tab-content" id="myTabContent">
							  <div className="tab-pane fade show active" id="mytruck" role="tabpanel" aria-labelledby="mytruck-tab">
								<TableDataComponent tableData={products} columnData={columns}  noDataIndication={indicationhtml} />
							  </div>
							  <div className="tab-pane fade" id="newtruck" role="tabpanel" aria-labelledby="newtruck-tab">								 
							  </div>							
							 <EditTruckPopup trucktype={this.props.truck} fueltypelist={this.props.fueltypelist} eldprovider={this.props.eld} modalvalues={this.state.modaldata} activePage={this.activePage} itemsCountPerPage={this.itemsCountPerPage}/>
						</div>
					</div>
				</div>	
			</article>
		)
	}	
}
const mapStateToProps = (state) => {	
	return{
		trucks: state.assetReducer.data,
		loading: state.assetReducer.loading,
		pagination:state.assetReducer.pagination,		
		error: state.assetReducer.error
	}    
}	
export default connect(mapStateToProps)(Trucklist)