import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { formData } from '../../../helpers/formData';
import $ from 'jquery';
import 'jquery-validation';
import { NewEmailAccountComponent } from 'UI-Component';
import { addNewAccount, updateAccount } from '../../../services/emailAccountService';
class NewAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            senderName: '',
            senderEmail: '',
            port: '',
            host: '',
            userName: '',
            password: '',
            outgoingMailServer: '',
            incomingMailServer: '',
            accountId: null,
            accountName: ''
        }
    }
    componentDidUpdate = (prevProps) => {
        if (this.props.accountData !== prevProps.accountData) {
            if (this.props.accountData) {
                this.setState({
                    ...this.state,
                    senderName: this.props.accountData.senderName,
                    senderEmail: this.props.accountData.senderEmail,
                    port: this.props.accountData.port,
                    host: this.props.accountData.host,
                    userName: this.props.accountData.userName,
                    password: this.props.accountData.password,
                    incomingMailServer: this.props.accountData.incomingMailServer,
                    outgoingMailServer: this.props.accountData.outgoingMailServer,
                    accountId: this.props.accountData.accountId,
                    accountName: this.props.accountData.accountName
                });
            } else {
                this.setState({
                    ...this.state,
                    senderName: '',
                    senderEmail: '',
                    port: '',
                    host: '',
                    userName: '',
                    password: '',
                    incomingMailServer: '',
                    outgoingMailServer: '',
                    accountName: ''
                });
            }
        }
    }
    set_accountName = (e) => {
        this.setState({
            ...this.state,
            accountName: e.target.value
        });
    }
    set_senderName = (e) => {
        this.setState({
            ...this.state,
            senderName: e.target.value
        });
    }
    set_senderEmail = (e) => {
        this.setState({
            ...this.state,
            senderEmail: e.target.value
        });
    }
    set_port = (e) => {
        this.setState({
            ...this.state,
            port: e.target.value
        });
    }
    set_host = (e) => {
        this.setState({
            ...this.state,
            host: e.target.value
        });
    }
    set_userName = (e) => {
        this.setState({
            ...this.state,
            userName: e.target.value
        });
    }
    set_password = (e) => {
        this.setState({
            ...this.state,
            password: e.target.value
        });
    }
    set_incomingMailServer = (e) => {
        this.setState({
            ...this.state,
            incomingMailServer: e.target.value
        });
    }
    set_outgoingMailServer = (e) => {
        this.setState({
            ...this.state,
            outgoingMailServer: e.target.value
        });
    }
    closeThis = (flag) => {
        $('#new_account').trigger('reset');
        this.props.closeNewAccount(flag);
    }
    saveNewAccount = () => {
        $('#new_account').validate({
            rules: {
                senderName: {
                    required: true
                },
                senderEmail: {
                    required: true,
                    email: true
                }
            },
            messages: {
                senderName: {
                    required: 'Sender name can not be left blank.!'
                },
                senderEmail: {
                    required: 'Sender email can not be left blank.!',
                    email: 'Email not valid'
                }
            }, errorPlacement: function (error, element) {
                if (element.attr('name') === 'fromDate' || element.attr('name') === 'toDate') {
                    error.insertAfter($(element).parents('div.date-picker').find($('.error_place')));
                }
                else {
                    error.insertAfter(element);
                }
            }
        });
        if ($('#new_account').valid()) {
            let form = document.getElementById('new_account');
            let loadData = formData(form);
            loadData.companyId = localStorage.getItem('companyId')
            if (loadData.id === '') {
                loadData.id = null;
            }
            let param = {
                payload: loadData,
                companyId: localStorage.getItem('companyId')
            }
            if (loadData.id === null) {
                addNewAccount(param).then((response) => {
                    if (response.status) {
                        if (response.status === 'CREATED') {
                            toastr.success('Success', response.message);
                            this.closeThis(1);
                        } else {
                            toastr.error('Oops!', response.message);
                        }
                    }
                });
            }
            else {
                updateAccount(param).then((response) => {
                    if (response.status) {
                        if (response.status === 'CREATED') {
                            toastr.success('Success', response.message);
                            this.closeThis(1);
                        } else {
                            toastr.error('Oops!', response.message);
                        }
                    }
                });
            }
        }
    }
    render = () => {
        return (
            <Modal aria-labelledby='contained-modal-title-vcenter' centered show={this.props.newAccountPopup} id='newuser'>
                <ModalHeader>
                    <ModalTitle>{this.props.title}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <NewEmailAccountComponent
                        state={this.state}
                        set_senderName={(e) => { this.set_senderName(e) }}
                        set_senderEmail={(e) => { this.set_senderEmail(e) }}
                    />
                </ModalBody>
                <ModalFooter>
                    <button type='button' className='btn btn-primary' onClick={() => { this.saveNewAccount() }}>Save</button>
                    <button type='button' className='btn btn-primary' onClick={() => { this.closeThis(0) }}>Close</button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default NewAccount;