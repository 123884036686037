import React, { Component } from 'react';
import logo from '../../truckergig-logo.png'

class FAQCarriers extends Component {
    render() {
        return (
            <div className='wrapper faq-sweepstakes'>
                <div className='sweepstakes-headerbar'>
                    <img className='trade-logo-sweepstakes' src='https://truckercloud.com/wp-content/uploads/2020/06/trucker-cloud-logo-e1591004403752.png' />
                </div>
                {/* <img src='https://truckercloud.com/wp-content/uploads/2021/03/jahongir-ismoilov-RcjvFd1k18o-unsplash-scaled.jpg' alt='Notebook' className=' bg-transparent'/> */}
                <section className='box-wrap remove-white-space-css'>
                    <div className='sweep-heading2-css  FAQ-headerbar'>Frequently Asked Questions – General FAQ for Carriers</div>
                        <div class='container'>
                            <div class='row'>
                                <div class='col-md-6 '>
                                <div class='card card-grid'>
                                <h1 className='sweep-sub-heading-css'>
                                <svg id='SvgjsSvg3254' width='318' height='152' xmlns='http://www.w3.org/2000/svg' version='1.1' xmlnsXlink='http://www.w3.org/1999/xlink' xmlnsSvgjs='http://svgjs.dev/svgjs' x='0px' y='0px' viewBox='0 0 24 24' class='apexcharts-svg' xmlnsData='ApexChartsNS' transform='translate(0, 0)' className='svgImgCss'>
                                <g transform='translate(0, 0)' class='nc-icon-wrapper' fill='none'>
                                        <polyline data-cap='butt' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-miterlimit='10' points='8.894,17.027 1,19 1,4 9,2 15,4 23,2 23,17 16,18.75 ' stroke-linejoin='miter' stroke-linecap='butt'></polyline>
                                        <polygon data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' points=' 10,21 13,16 18,13 7,10 ' stroke-linejoin='miter'></polygon>
                                    </g>
                                </svg><br/>
                                What is TruckerCloud</h1>
                                <p className='para-css'>
                                TruckerCloud is a load visibility platform for brokers, carriers, and shippers. Through the platform, you, the carrier, can share your locations, but only with specific brokers and shippers that you enable, and only on the loads that you want them to have visibility on. TruckerCloud helps you reduce the number of check calls and improve relationships with your customers.
                                </p>
                                </div>
                                </div>
                                <div class='col-md-6'>
                                <div class='card card-grid'>
                                <h1 className='sweep-sub-heading-css'>
                                <svg id='SvgjsSvg3254' width='318' height='152' xmlns='http://www.w3.org/2000/svg' version='1.1' xmlnsXlink='http://www.w3.org/1999/xlink' xmlnsSvgjs='http://svgjs.dev/svgjs' x='0px' y='0px' viewBox='0 0 24 24' class='apexcharts-svg' xmlnsData='ApexChartsNS' transform='translate(0, 0)' className='svgImgCss'>
                                <g transform='translate(0, 0)' class='nc-icon-wrapper' fill='none'>
                                    <polyline data-cap='butt' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-miterlimit='10' points='15.293,6.247 10,1 1,1 1,10 13,22 16.913,18 ' stroke-linejoin='miter' stroke-linecap='butt'></polyline>
                                    <circle data-stroke='none' fill='none' cx='7' cy='7' r='2' stroke-linejoin='miter' stroke-linecap='square'></circle>
                                    <circle data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' cx='17' cy='12' r='6' stroke-linejoin='miter'></circle>
                                    <polyline data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' points=' 17,10 17,12 19,12 ' stroke-linejoin='miter'></polyline>
                                </g>
                                </svg><br/>
                                Free for Carriers Forever</h1>
                                <p className='para-css'>
                                We know you don’t want to pay for a service to share your location, and you should never have to. Joining the TruckerCloud network is free for carriers and enables you to share key tracking data with your customers.
                                </p>
                                </div>
                            </div>
                            <div class='col-md-6 card-css'>
                                <div class='card card-grid'>
                                <h1 className='sweep-sub-heading-css'>
                                <svg id='SvgjsSvg3254' width='318' height='152' xmlns='http://www.w3.org/2000/svg' version='1.1' xmlnsXlink='http://www.w3.org/1999/xlink' xmlnsSvgjs='http://svgjs.dev/svgjs' x='0px' y='0px' viewBox='0 0 24 24' class='apexcharts-svg' xmlnsData='ApexChartsNS' transform='translate(0, 0)' className='svgImgCss'>
                                <g transform='translate(0, 0)' class='nc-icon-wrapper'>
                                    <circle fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' cx='12' cy='12' r='9' stroke-linejoin='miter'></circle>
                                    <line fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' x1='12' y1='1' x2='12' y2='5' stroke-linejoin='miter'></line>
                                    <line fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' x1='23' y1='12' x2='19' y2='12' stroke-linejoin='miter'></line>
                                    <line fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' x1='12' y1='23' x2='12' y2='19' stroke-linejoin='miter'></line>
                                    <line fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' x1='1' y1='12' x2='5' y2='12' stroke-linejoin='miter'></line>
                                    <circle data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' cx='12' cy='12' r='1' stroke-linejoin='miter'></circle>
                                    <circle data-color='color-2' data-stroke='none' fill='none' cx='12' cy='12' r='1' stroke-linejoin='miter' stroke-linecap='square'></circle>
                                </g>
                                </svg><br/>
                                Carrier Controlled Location Sharing</h1>
                                <p className='para-css'>
                                We agree. TruckerCloud is a trusted third party, and not a broker, shipper or 3PL. We only share data when you enable a broker or shipper to view the visibility of a single load. You’re not sharing any data by signing up with TruckerCloud. We just make it easier for the next time you want to share data. We only share tracking information 60 minutes before the scheduled pick up, and we end tracking when the truck leaves the final delivery location. We don’t track in between shipments. We value your privacy as much as you do.
                                </p>
                                </div>
                                </div>
                                <div class='col-md-6 card-css'>
                                <div class='card card-grid'>
                                <h1 className='sweep-sub-heading-css'>
                                <svg id='SvgjsSvg3254' width='318' height='152' xmlns='http://www.w3.org/2000/svg' version='1.1' xmlnsXlink='http://www.w3.org/1999/xlink' xmlnsSvgjs='http://svgjs.dev/svgjs' x='0px' y='0px' viewBox='0 0 24 24' class='apexcharts-svg' xmlnsData='ApexChartsNS' transform='translate(0, 0)' className='svgImgCss'>
                                <g transform='translate(0, 0)' class='nc-icon-wrapper' fill='none'>
                                    <polygon fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' points='23,23 1,23 1,1 9,1 12,5 23,5 ' stroke-linejoin='miter'></polygon>
                                    <path data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' d='M14,11h2 c1.7,0,3,1.3,3,3v0c0,1.7-1.3,3-3,3h-2' stroke-linejoin='miter'></path>
                                    <path data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' d='M10,17H8 c-1.7,0-3-1.3-3-3v0c0-1.7,1.3-3,3-3h2' stroke-linejoin='miter'></path>
                                    <line data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' x1='10' y1='14' x2='14' y2='14' stroke-linejoin='miter'></line>
                                </g>
                                </svg><br/>
                                We Don’t Share Data with</h1>
                                <p className='para-css'>
                                No, our mutual customers have subscribed to project44 to access location updates on their shipments through our platform, meaning you must connect through us. After you join project44, you can easily turn on and off access to multiple customers. 
                                </p>
                                </div>
                                </div>
                                <div class='col-md-6 card-css'>
                                <div class='card card-grid'>
                                <h1 className='sweep-sub-heading-css'>
                                <svg id='SvgjsSvg3254' width='318' height='152' xmlns='http://www.w3.org/2000/svg' version='1.1' xmlnsXlink='http://www.w3.org/1999/xlink' xmlnsSvgjs='http://svgjs.dev/svgjs' x='0px' y='0px' viewBox='0 0 24 24' class='apexcharts-svg' xmlnsData='ApexChartsNS' transform='translate(0, 0)' className='svgImgCss'>
                                <g transform='translate(0, 0)' class='nc-icon-wrapper' fill='none'>
                                    <polyline fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' points='21,15 21,23 1,23 1,1 19,1 ' stroke-linejoin='miter'></polyline>
                                    <polygon data-cap='butt' data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-miterlimit='10' points='13,17 10,17 10,14 19,5 22,8 ' stroke-linejoin='miter' stroke-linecap='butt'></polygon>
                                </g>
                                </svg><br/>
                                Why Carriers Should Sign Up</h1>
                                <p className='para-css'>
                                <ol>
                                <li >Increase Efficiency: Reduce tracking calls for your fleet</li>
                                <li >Reduce Costs: Use visibility to prove case on detention and late fees</li>
                                <li >Improve Driver Satisfaction: Keep your drivers driving without check calls or multiple apps</li>
                                </ol>
                                </p>
                                </div>
                                </div>
                                <div class='col-md-6 card-css'>
                                <div class='card card-grid'>
                                <h1 className='sweep-sub-heading-css'>
                                <svg id='SvgjsSvg3254' width='318' height='152' xmlns='http://www.w3.org/2000/svg' version='1.1' xmlnsXlink='http://www.w3.org/1999/xlink' xmlnsSvgjs='http://svgjs.dev/svgjs' x='0px' y='0px' viewBox='0 0 24 24' class='apexcharts-svg' xmlnsData='ApexChartsNS' transform='translate(0, 0)' className='svgImgCss'>
                                <g transform='translate(0, 0)' class='nc-icon-wrapper' fill='none'>
                                <line data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' x1='10' y1='4' x2='22' y2='4' stroke-linejoin='miter'></line>
                                <line data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' x1='10' y1='12' x2='22' y2='12' stroke-linejoin='miter'></line>
                                <line data-color='color-2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' x1='10' y1='20' x2='22' y2='20' stroke-linejoin='miter'></line>
                                <rect x='2' y='2' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' width='4' height='4' stroke-linejoin='miter'></rect>
                                <rect x='2' y='10' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' width='4' height='4' stroke-linejoin='miter'></rect>
                                <rect x='2' y='18' fill='none' stroke='currentColor' vector-effect='non-scaling-stroke' stroke-linecap='square' stroke-miterlimit='10' width='4' height='4' stroke-linejoin='miter'></rect>
                                </g>
                                </svg><br/>
                                What Carriers Get with TruckerCloud ‘Cooperative’</h1>
                                <p className='para-css'>
                                <ol>
                                <li >Haul fewer deadhead miles</li>
                                <li >Help drivers and dispatchers find loads more easily</li>
                                <li >Get access to more profitable loads like Cargo Chief</li>
                                <li >Pre-onboard yourself for customers, who you’ll work with in the future</li>
                                </ol>
                                </p>
                                </div>
                                </div>
                        </div>
                    </div>
                </section>
        </div>
        );
    }
}

export default FAQCarriers;