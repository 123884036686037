import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Loader from './loader';
import { toastr } from 'react-redux-toastr';
import {getRolesAndAccessService, roleAccessChangeService} from '../../services/userRoleService';
import AddRolePopUp from './AddRolePopUp';


class Permissions extends Component{
	constructor(props){
        super(props);
        this.state={
            isReady:false,
            rowSel:-1,
            roleId:'',
            roles:[],
            roleAccess:[],
            saveFlag:false,            
            newRolePopup:false,
            selectedIndex:null,
            readMenus:[]
        }
    }

    componentDidMount = () => {
        document.title = "Permissions";
        let companyId = localStorage.getItem('companyId');
        getRolesAndAccessService({companyId:companyId}).then((response) =>{           
            let roleId='';            
            let roles=[];
            let roleAccess=[];            
            let readMenus=[];
            if(response && response.roleList && response.roleList.length>0){
                roleId = response.roleList[0].roleId;
                roles = response.roleList.map((row, index) =>{
                    return {
                        roleId:row.roleId,
                        roleName:row.roleName
                    }
                });
            }
            
            if(response && response.roleAccessList && response.roleAccessList.length>0){                                
                roleAccess = response.roleAccessList.map((row, index) =>{                    
                    return {
                        roleAccessId:row.roleAccessId,
                        roleId:row.roleId,
                        menuId:row.menuId,
                        companyId:row.companyId,
                        addAccess:row.addAccess,
                        editAccess:row.editAccess,
                        deleteAccess:row.deleteAccess,
                        viewAccess:row.viewAccess,
                        menuName:((row.menu && row.menu.menuName) ? row.menu.menuName : ''),
                        selOption:false,
                        deleted:row.deleted
                    }
                });
            }
            
            if(response && response.readMenus){
                readMenus=response.readMenus.map((row,index)=>parseInt(row));
            }

            this.setState({
                isReady:true,
                roleId:roleId,
                roles:roles,
                roleAccess:roleAccess,
                readMenus:readMenus
            });
        });
        
    }

    setMenu = (row, index) =>{
        this.setState({
            ...this.state,
            selectedIndex:index
        });
        let param={
            roleAccessId:row.roleAccessId,
            isDeleted:(this.state.roleAccess[index].deleted?0:1)
        }
        roleAccessChangeService(param).then((response) =>{
            console.log(response);
            if(response && response.status){
                toastr.success('Success!', 'Status updated');
                let roleAcces = [...this.state.roleAccess];

                roleAcces[this.state.selectedIndex].deleted = !roleAcces[this.state.selectedIndex].deleted;
                this.setState({
                    ...this.state,
                    roleAccess:[...roleAcces]
                });
            } else{
                toastr.error('Oops!', 'Unable to update status');
            }
            
        });

        
    }
    
    loadTableData = () =>{
        return this.state.roleAccess.map((row, index) =>{
            console.log(this.state.readMenus.includes(row.menuId));            
                return {
                    id:row.roleAccessId,
                    menuName:row.menuName,
                    mode:(this.state.readMenus.includes(row.menuId))?'':<input type="checkbox" class="form-check-input" defaultChecked={!row.deleted} onClick={(e) =>{this.setMenu(row, index)}} />
                }
        });
        
    }

    loadTableColumns = () => {
        return [	
            {
                text:'Menu',
                dataField:'menuName',
                sort:true
            },            
            {
                text:'Action',
                dataField:'mode',
                sort:false
            },
        ];
    }

    

    set_role = (e) => {
        this.setState({
            ...this.state,
            isReady:false,
            roleId:e.target.value
        });
        let companyId = localStorage.getItem('companyId');
        getRolesAndAccessService({companyId:companyId,roleId:e.target.value}).then((response) =>{
            
            let roleAccess=[];           
            let readMenus=[];
            if(response && response.roleAccessList && response.roleAccessList.length>0){                
                roleAccess = response.roleAccessList.map((row, index) =>{
                    return {
                        roleAccessId:row.roleAccessId,
                        roleId:row.roleId,
                        menuId:row.menuId,
                        companyId:row.companyId,
                        addAccess:row.addAccess,
                        editAccess:row.editAccess,
                        deleteAccess:row.deleteAccess,
                        viewAccess:row.viewAccess,
                        menuName:((row.menu && row.menu.menuName) ? row.menu.menuName : ''),
                        deleted:row.deleted
                    }
                });
            }

            if(response && response.readMenus){
                readMenus=response.readMenus.map((row,index)=> parseInt(row));
            }

            this.setState({
                isReady:true,  
                roleAccess:roleAccess,
                readMenus:readMenus
            });
        });

        
    }

    renderRoles = () =>{
        let roles = this.state.roles.map((row, index) =>{
            return <option key={index} value={row.roleId}>{row.roleName}</option>
        });
        return <Fragment>
            <div className="col-1">Roles</div>
            <div className="col-2">
                <select className="form-control" value={this.state.roleId} onChange={(e) =>{this.set_role(e)}} disabled={this.state.saveFlag}>
                    {roles}
                </select>
            </div>
        </Fragment>
    }

    openNewRole = (e) =>{
        e.preventDefault();
        this.setState({
            ...this.state,
            newRolePopup:true
        })
    }

    closeNewRole = () =>{
        this.setState({
            ...this.state,
            newRolePopup:false
        })
    }
    render = () =>{
        console.log(this.state.readMenus);
        const { SearchBar } = Search;

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                Showing { from } to { to } of { size } Results
            </span>
        );
		const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal
        };
        if(this.state.isReady){
            return(
                <article className="table-data truck-data shadow bg-white rounded">
                    <div className="row">
                        <div className="col">
                            <h3>Permissions</h3>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                    <div className="row">
                        <div className="col">
                            <ToolkitProvider
                                keyField="id"
                                data={ this.loadTableData() }
                                columns={ this.loadTableColumns() }
                                search
                            >
                                {
                                props => (
                                    <div>
                                        <div className="row">
                                            {this.renderRoles()}
                                            
                                            <div className="col">
                                                <SearchBar { ...props.searchProps } />
                                            </div>
                                            {/* <div className="btn-export float-right d-inline-block pr-2">
                                                    <a href="#" className="btn btn-primary" onClick={(e) =>{this.openNewRole(e)}}>Add Role</a>
                                                </div> */}
                                        </div>
                                        <BootstrapTable bootstrap4 
                                            { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication='No data found'  hover 
                                            bordered={ false } />
                                    </div>
                                )
                                }
                            </ToolkitProvider>
                        </div>
                        <div>
                            <AddRolePopUp companyTypes={this.state.companyType} companyTypeId={this.state.companyTypeId} companies={this.state.company} newRolePopup={this.state.newRolePopup} closeNewRole={() =>{this.closeNewRole()}} />		
						</div>
                    </div>
                </article>
            );
        }
        return(<Loader />);
    }    
    
}
export default Permissions;