export const validate = values => {    
    const errors = {}
    if (!values.fromDate) {
        errors.fromDate = 'Date can not be left blank.!'
    } 
    if (!values.toDate) {
        errors.toDate = 'To Date can not be left blank.!'
    } 
    if (!values.loadStatus) {
        errors.loadStatus = 'Status can not be left blank.!'
    }
    if(!values.customerName){
        errors.customerName = 'Customer Name can not be left blank.!'
    }
    return errors
}
