import React, { Component } from 'react';

class TrailerNew extends Component{

	render(){
		return(
			<article className="table-data truck-data">
				<form className="tg-forms">
					<div className="row">
						<div className="col col-md-3">
							<div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
							  	<a className="nav-link active" id="truckinfoTab" data-toggle="pill" href="#truckinfoHome" role="tab" aria-controls="truckinfoHome" aria-selected="true">Truck Info</a>
							  	<a className="nav-link" id="eldTab" data-toggle="pill" href="#eldinfoHome" role="tab" aria-controls="eldinfoHome" aria-selected="false">Notes</a>							  	
							</div>
						</div>
						<div className="col col-md-9">
							<div className="tab-content" id="v-pills-tabContent">
							  	<div className="tab-pane fade show active" id="truckinfoHome" role="tabpanel" aria-labelledby="truckinfoTab">
							  		<div className="row">
							  			<div className="col col-md-4">
							  				<div className="form-group">
											    <label htmlFor="truck">Trailer #</label>
											    <input type="text" className="form-control" id="truck" placeholder="Trailer #"/>										    
											</div>
											<div className="form-group">
											    <label htmlFor="Year">Year</label>
											    <input type="text" className="form-control" id="make" placeholder="Year"/>										    
											</div>										
							  			</div>
							  			<div className="col col-md-4">
							  				<div className="form-group">
											    <label htmlFor="year">Make</label>
											    <input type="text" className="form-control" id="year" placeholder="Make"/>										    
											</div>
											<div className="form-group">
											    <label htmlFor="Make">VIN</label>
											    <input type="text" className="form-control" id="text" placeholder="VIN"/>										    
											</div>											
							  			</div>
							  			<div className="col col-md-4">
							  				<div className="form-group">
											    <label htmlFor="Model">Model</label>
											    <input type="text" className="form-control" id="trucktype" placeholder="Model"/>										    
											</div>
											<div className="form-group">
											    <label htmlFor="Serial">Serial</label>
											    <input type="text" className="form-control" id="color" placeholder="Serial"/>										    
											</div>										
							  			</div>						  			
							  		</div>
							  		<div className="row">
							  			<div className="col col-md-12">
							  				<div className="form-group form-group-btns">
							  					<a href="/#" className="btn btn-primary btn-sm">Next</a>
							  				</div>
							  			</div>
							  		</div>
							  	</div>
							  	<div className="tab-pane fade" id="eldinfoHome" role="tabpanel" aria-labelledby="eldTab">
							  		<div className="row">						
							  			<div className="col col-md-6">
							  				<div className="form-group">
											    <label htmlFor="Notes">Notes</label>
											    <textarea className="form-controll" rows="5" cols="150"></textarea>											   								    
											</div>
							  			</div>
							  		</div>
							  		<div className="row">
							  			<div className="col col-md-12">
							  				<div className="form-group form-group-btns">
							  				    <a href="/#" className="btn btn-primary btn-sm">Save</a>
							  					<a href="/#" className="btn btn-primary btn-sm">Reset</a>
							  					<a href="/#" className="btn btn-primary btn-sm">Prev</a>
							  					<a href="/#" className="btn btn-primary btn-sm">Submit</a>
							  				</div>
							  			</div>
							  		</div>
							  	</div>							   
							</div>
						</div>
					</div>
				</form>
			</article>
			         
		)
	}
}

export default TrailerNew