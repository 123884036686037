import { authHeader } from '../helpers/authHeader';
import {checkUnauthorized} from '../helpers/appUtil';

require('dotenv').config();

export const trucksService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
   const TRUCK_API_ENDPOINT = BASE_URL+'assetsresource/assets/list?assettype='+request.payload.assettype;   
    return fetch(TRUCK_API_ENDPOINT,{
        method: "POST",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {    
        checkUnauthorized(responseData.status, responseData.message);       
        return responseData;
    })
    .catch(error => {
        return {};
    });
}
// export const addTrucksService = (request) => {   s
//     const BASE_URL= process.env.REACT_APP_BASE_URL;
//     const ADD_TRUCK_API_ENDPOINT = BASE_URL+'assetsresource/asset/add';
//     return fetch(ADD_TRUCK_API_ENDPOINT, {
//         method: 'POST',
//         body:JSON.stringify(request. ),
//         headers: authHeader()
//     });
// };

export const addTrucksService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const ADD_TRUCK_API_ENDPOINT = BASE_URL+'assetsresource/asset/add';
    return fetch(ADD_TRUCK_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const editTruckService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const UPDATE_TRUCK_API_ENDPOINT = BASE_URL+'assetsresource/asset/update';
    return fetch(UPDATE_TRUCK_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
}

export const viewTruckService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const VIEW_API_ENDPOINT = BASE_URL+'assetsresource/getasset?assetid='+request.payload;    
    return fetch(VIEW_API_ENDPOINT,{
        method: "GET",  
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const searchTruckService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const SEARCH_API_ENDPOINT = BASE_URL+'assetsresource/assets/searchlist';
    return fetch(SEARCH_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()

    })
    .then((response) => response.json())
    .then((responseData) => {  
        checkUnauthorized(responseData.status, responseData.message);         
        return responseData;
    })
    .catch(error => {
        return {};
    });
}
export const trucklistservice = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;   
    const TRUCK_LIST_API_ENDPOINT = BASE_URL+'assetsresource/assets/list?assettype=truck';    
    return fetch(TRUCK_LIST_API_ENDPOINT,{
        method: "POST",
        body:JSON.stringify(request.payload),
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {
        checkUnauthorized(responseData.status, responseData.message);          
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};

export const truckBulkUploadService = (request) => {
    const formData = new FormData();
    const fileField = document.querySelector('input[id="truckbulkuploadfile"]');
    let authToken = localStorage.getItem('authToken');
    formData.append(request.payload.trucktype, fileField.files[0]);
    const BASE_URL= process.env.REACT_APP_BASE_URL;   
    const TRUCKS_BULK_UPLOAD_STATUS_API_ENDPOINT = BASE_URL+'assetsresource/assets/'+request.payload.urlval+'/import'   
    return fetch(TRUCKS_BULK_UPLOAD_STATUS_API_ENDPOINT,{
        method: 'POST',
        body: formData,
        "mimeType": "multipart/form-data",
        headers: {
            'Authorization': authToken 
        }       
    })
    .then((response) => response.json())
    .then((responseData) => {   
        checkUnauthorized(responseData.status, responseData.message);        
        return responseData;
    })
    .catch(error => {
        console.warn(error);
        return {};
    });
};
