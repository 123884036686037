import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { renderSelect } from '../../../helpers/FormControls';
import { validate } from './loadReportsValidation';
import { DateTimePicker } from 'react-widgets'
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { HosReportAction } from '../../../actions/reportAction';
import { companyNameListAction } from '../../../actions/companyActions';
const renderDateTimePicker = ({ input: { onChange, value }, label, meta: { touched, error }, showTime }) => (
    <div className={` ${touched && error ? 'error' : ''}`}>
        <DateTimePicker
            dropDown={true}
            onChange={onChange}
            className="requiredfield"
            format="MM-DD-YYYY HH:mm"
            id="dateValue"
            time={true}
            value={!value ? null : new Date(value)}
            max={new Date()}
        />
        {touched && error && <span className="error">{error}</span>}
    </div>
);


function submit(values) {
    const frmdata = {};
    var date = (values.fromDate) ? (moment(values.fromDate).format('YYYY-MM-DD HH:mm:ss')) : ("");
    frmdata.date= date
    frmdata.companyId=(values.customerName) ? (values.customerName) : ("");
    this.props.dispatch(HosReportAction(frmdata));    
 }


class tripRegistration extends Component {
    state={
		payload:"",
        response:"",
        customertype:[
            {id:"1",name:"Shipper"},
            {id:"2",name:"Broker"},
            {id:"3",name:"Carrier"},
            {id:"7",name:"Tech Partner"}
        ]
    }
    componentDidMount() {		
        document.title = 'Hos-Reports';
        // const params1={
        //     companytypeid:3,
        //  }    
        // this.props.dispatch(companyNameListAction(params1));
    }

    
	getPayloadInfo(row)
	{
        console.log(row);
        this.setState({
            payload:row.payload,
            response:row.response
        })
	}
    
    customertypechange = (e) => {
        if(e.target.value>0)
        {
            const params1={
                companytypeid:e.target.value,
             }    
            this.props.dispatch(companyNameListAction(params1));
        }
    }

    render() {
        //console.log(this.props.companyNameListValue)
        var indicationhtml = "No Data Found"
        if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}        
        const { handleSubmit } = this.props;	
        var LoadReports=[];
		
            if(this.props.loading){
                indicationhtml = 
                <div className="spinner-border" role="status">
                    <div className="row react-bs-table-no-data"></div>
                    </div>
            }else
            {
            if(this.props.data.auditlog !== undefined)
            {
                if(this.props.data.auditlog.length > 0)
                {
                    LoadReports = this.props.data.auditlog.map(
                        (row,index) =>{   
                            return({
                                id:index,
                                timetaken:row.timeTaken,
                                method:row.method,
                                responseStatus:row.responseStatus,
                                payload:row.payload,
                                response:row.payload,
                                popupIcon : <i data-toggle="modal" onClick={() => {this.getPayloadInfo(row);}} data-target=".PayloadAndResponse" id="lanehistoryclass"  className="icofont-info-circle"></i>
                            });
                        }
                    );
                }
                else{
                    LoadReports = [];
                    indicationhtml="No Data Found";
                }
            }
           } 
        const { SearchBar } = Search;        
       
		const columns=[	
        // {
        //     text:'Load Id',
        //     dataField:'id',
        //     sort:true
        // },
		{
			text:'Time Taken',
			dataField:'timetaken',
			sort:true
		},
		{
			text:'Method',
			dataField:'method',
			sort:true
        },
        {
			text:'Response Status',
			dataField:'responseStatus',
			sort:true
        },
        {
			text:'Payload & Response',
			dataField:'popupIcon',
			sort:true
		},
	   ]
       
        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Showing { from } to { to } of { size } Results
            </span>
          );
          
          const options = {
            sizePerPage:10,
            paginationSize: 4,
            pageStartIndex: 1,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
          };
          var companyList = [];
          if(this.props.companyNameListValue)
          {
             companyList = this.props.companyNameListValue.companyList
          }else{
             companyList = []
          }

          
        return (
             <article className="table-data truck-data shadow bg-white rounded" style={{display:'block'}}>
                <h3>HOS Summary</h3> <br></br>
                <form onSubmit={handleSubmit(submit.bind(this))}>
                    <div className="row">
                    <div className="form-group col col-lg-4">
                    <label>Date</label>
                    <Field
                        name="fromDate"
                        defaultValue={null}
                        component={renderDateTimePicker}
                        />
                    </div>
                    <div className="form-group col col-lg-3">
                    <Field type="text" name="customertype"  onChange={(e) => {this.customertypechange(e)}} component={renderSelect} label="Customer Type" data={this.state.customertype}/> 
                    </div>

                    <div className="form-group col col-lg-3">
                    <Field type="text" name="customerName" controlClass="requiredfield" keyColumn='Company_Id' keyValue='Company_Name' component={renderSelect} label="Customer" data={companyList}/> 
                    </div>
                   
                    <div className="form-group col col-lg-2">
                        <button type="submit" value="searchbtn" onClick={this.handleGoClick} className="btn btn-secondary searchbtn">Get</button>&nbsp;                        
                    </div>
                    </div>
                </form>
                <ToolkitProvider
                        keyField="id"
                        data={ LoadReports }
                        columns={ columns }
                        search
                        >
                        {
                        props => (
                            <div>
                             <SearchBar { ...props.searchProps } /> 
                            <BootstrapTable
                                { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={indicationhtml} hover 
                                bordered={ false }
                            />
                            </div>
                        )
                        }
                    </ToolkitProvider>
                    <div id="PayloadAndResponse" className="modal fade PayloadAndResponse"  role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg proofofdelivery">
                  <div className="modal-content"> 
                        
                  <div className="modal-header">
                     <h5 className="modal-title doc-color" id="exampleModalLabel">Payload And Reponse</h5>
                      <button type="button" id="podcarrier" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                     </div>
                 <div className="modal-body modalbodyclass" id="favourite-loads-container">
                  <div className="col-sm-12">
                      <div className="row">
                          <div className="col-md-4 col-sm-4 col-xs-12 lbl">
                          <label>
                              <b>Payload :</b>
                          </label>
                          </div>
                          <div className="col-md-8 col-sm-8 col-xs-12">
                          <label>{this.state.payload}</label>
                          </div>
                      </div>
                  </div>
                  <br/>
                  <div className="col-sm-12">
                  <div className="row">
                          <div className="col-md-4 col-sm-4 col-xs-12 lbl">
                          <label>
                             <b>Response :</b> 
                          </label>
                          </div>
                          <div className="displayvalue col-md-8 col-sm-8 col-xs-12">
                          <span>{this.state.response}</span>
                          </div>
                      </div>
                  </div>
              </div>            
                  </div>
              </div>
          </div>
        </article>
             
        )
    }
}

const mapStateToProps = state => {
    return {
        data : state.reportReducer.data,
        loading : state.reportReducer.loading,
        loadReportData : state.reportReducer.data,
        companyNameListValue : state.companyReducer.companyNamelist,
    }
  }
  
  export default connect(mapStateToProps)(reduxForm({
      form:'tripReg',validate
  })(tripRegistration))