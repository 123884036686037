import * as types from '../actions';

const initialState = {
    data: [],
    loading: false,
    //pagination:[],
    error: ''
};

export default function(state = initialState, action) {  
    switch(action.type) {
        case types.EDIT_DOCUMENT_DATA:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.EDIT_DOCUMENT_DATA_SUCCESS:
            return { 
                ...state,
                data: 'ok',
                loading: false,
            };
        case types.EDIT_DOCUMENT_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
             case types.LIST_DOCUMENT_DATA:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.LIST_DOCUMENT_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data.DocumentList,
                //pagination: action.page,
                loading: false,
            };
        case types.LIST_DOCUMENT_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error:''
            };
            case types.POST_DOCUMENT_DATA:
            return { 
                ...state,
                loading: true,
                error:''
            };
        case types.POST_DOCUMENT_DATA_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.POST_DOCUMENT_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
            case types.EDIT_DOCUMENTS_DATA:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.EDIT_DOCUMENTS_DATA_SUCCESS:
            return { 
                ...state,
                data:action.data,
                loading: false,
            };
        case types.EDIT_DOCUMENTS_DATA_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};