import React from 'react';

class EditLoadDropdown  extends React.Component {
    getRow(data, value ,defaultVal) {
        switch (value) {
            case 'cid': return (
               data ? (data.map(function (row, index) {
                    return (
                        <option key={index} data-lanedetailsindex={index} value={row.companyId} selected={row.companyId===defaultVal}>{row.companyName}</option>
                    )
                })):
                 (<option></option>)
            )
            case 'id': return (
                data ? (data.map(function (row, index) {
                    return (
                        <option key={index} data-lanedetailsindex={index} value={row.id}>{row.name}</option>
                    )
                })) : (<option></option>)
            )
            case 'c_id': return (
                data ? (data.map(function (row, index) {
                    return (
                        <option key={index} data-lanedetailsindex={index} value={row.Invited_Company_Id} selected={row.Invited_Company_Id===defaultVal}>{row.Company_Name}</option>
                    )
                })) : (<option></option>)
            )
            case 'lane': return (
                data ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.laneId}>{row.selectLane}</option>
                    )
                })) : (<option></option>)
            )
            case 'pickstate': return (
                data ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.id+','+row.name} selected={defaultVal===row.name}>{row.name}</option>
                    )
                })) : (<option value=''>Loading...</option>)
            )
            case 'dropState': return (
                data ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.id+','+row.name} selected={defaultVal===row.name}>{row.name}</option>
                    )
                })) : (<option value=''>Loading...</option>)
            )
            case 'cCity': return (
                data.length > 0 ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.cityId+','+row.cityName} selected={row.cityName===defaultVal}>{row.cityName}</option>
                    )
                })) : (<option value=''>Loading...</option>)
            )
            case 'cZip': return (
                data.length > 0 ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.zipId+','+row.zipValue} selected={row.zipValue===defaultVal}>{row.zipValue}</option>
                    )
                })) : (<option value=''>Loading...</option>)
            )
            case 'ctrucktype': return (
                data && data.length > 0 ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.id} selected={row.id ===defaultVal}>{row.name}</option>
                    )
                })) : (<option value=''>Loading...</option>)
            )
            case 'c_eldlist': return (
                data && data.length > 0 ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.eldProviderId} selected={defaultVal===row.eldProviderDispName}>{row.eldProviderDispName}</option>
                    )
                })) : (<option value=''>Loading...</option>)
            )
            case 'c_truckno': return (
                data && data.length > 0 ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.assetId} selected={(row.assetNumber || row.assetName)===defaultVal} >{row.assetName?row.assetName:row.assetNumber}</option>
                    )
                })) : (<option value=''>Loading...</option>)
            )
            case 'c_timezone': return (
                data && data.length > 0 ? (data.map(function (row, index) {
                    return (
                        <option key={index} value={row.id} selected={row.value==='EST'}>{row.value}</option>
                    )
                })) : (<option value=''>Loading...</option>)
            )
        }
    }
    render() {
        let value = this.props.value
        return (
            <div className='col form-group'>
                {/* <label>{this.props.label}</label> */}
                <select onChange={this.props.onChange} disabled={this.props.disabled} className={this.props.class}id={this.props.id} name={this.props.name}>
                    <option value='' hidden>{this.props.placeHolder}</option>
                    {
                        this.getRow(this.props.data, value,this.props.defaultValueSelected)
                    }
                </select>
            </div>
        )
    }
}

export default EditLoadDropdown;
