import * as React from 'react'
import MaterialButton from '@mui/material/Button'
import MuiIconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import Icon from '../icon'
// import colors from 'theme/colors'

const Button = (props) => {
  const {
    type,
    variant = 'contained',
    disabled = false,
    size = 'medium',
    startIcon,
    endIcon,
    onClick = () => { },
    label,
    fullWidth = false,
    className,
    loading = false,
    pill = true,
    startIconColor,
    endIconColor,
    color,
    bordercolorprop,
    colorprop,
    backgroundcolorprop,
    hoverColor
  } = props

  const MuiButton = styled(MaterialButton)(({ theme }) => ({
    borderRadius: pill ? 25 : theme.shape.borderRadius,
    textTransform: 'Capitalize',
    fontFamily: 'Poppins',
    fontWeight: 500,
    color: colorprop,
    background:  '#44a4db',
    fontSize: '14px',
    border: `solid 1px ${bordercolorprop}`,
    fontWeight: 700,
     '&:hover': {
      background: hoverColor,
    }
  }))

  if (!label) {
    return <></>
  }

  return (
    <MuiButton
      type={type}
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      onClick={() => !loading && onClick()}
      fullWidth={fullWidth}
      className={className}
      startIcon={startIcon && <Icon name={startIcon} color={startIconColor} />}
      endIcon={endIcon && <Icon name={endIcon} color={endIconColor} />}
    >
      {loading && (
        <CircularProgress
          size={20}
          sx={{
            color: 'white',
            marginRight: '15px',
          }}
        />
      )}
      {label}
    </MuiButton>
  )
}

export default Button
const IconButton = (props) => {
  const { icon, size = 'large' } = props
  return (
    <MuiIconButton size={size}>
      <Icon name={icon} />
    </MuiIconButton>
  )
}

export { IconButton }
