import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Field, reduxForm } from 'redux-form';
import { companyListByType, updateTenderPopup,dispatchflag,loadAction } from '../../../actions/loadActions';
import { renderDateTimePicker, renderField, renderFieldforTableTextBox, renderSelect } from '../../../helpers/FormControls';
import logo from '../../../pc-mailer.png';
import { validate } from './validation';
import { STATUS } from '../../../helpers/status';
function submit(values,params) {
   const frmdata = {};
   frmdata.loadRateId= (values.loadRateId) ? (values.loadRateId) : ("");
   frmdata.loadId= (values.loadId) ? (values.loadId) : ("");
   var load_date = (values.loadDate) ? (moment(values.loadDate).format('YYYY-MM-DD')) : ("");
   frmdata.loadDate= (values.load_date) ? (values.load_date) : ("");

   frmdata.baseCharge= (values.baseCharge) ? (values.baseCharge) : ("");
   frmdata.fuelCharges= (values.fuelCharges) ? (values.fuelCharges) : ("");
   frmdata.targetTenderPrice= (values.targetTenderPrice) ? (values.targetTenderPrice) : ("");
   frmdata.loadMiles= (values.loadMiles) ? (values.loadMiles) : ("");
   frmdata.shipperLoadPrice= (values.shipperLoadPrice) ? (values.shipperLoadPrice) : ("");
   frmdata.carrierTenderPrice= (values.carrierTenderPrice) ? (values.carrierTenderPrice) : ("");
   frmdata.brokerGrossMargin= (values.brokerGrossMargin) ? (values.brokerGrossMargin) : ("");
   frmdata.estimatedBAMwireFee= (values.estimatedBAMwireFee) ? (values.estimatedBAMwireFee) : ("");
   frmdata.estimatedBrokerNet= (values.estimatedBrokerNet) ? (values.estimatedBrokerNet) : ("");
   frmdata.fuelAdvanceCommission= (values.fuelAdvanceCommission) ? (values.fuelAdvanceCommission) : ("");
   frmdata.milePerCents= (values.milePerCents) ? (values.milePerCents) : ("");
   frmdata.milePerPrice= (values.milePerPrice) ? (values.milePerPrice) : ("");
   frmdata.shipperTerm= (values.shipperTerm) ? (values.shipperTerm) : ("");
   frmdata.carrierTermBroker= (values.carrierTermBroker) ? (values.carrierTermBroker) : ("");
   frmdata.carrierTermBAM= (values.carrierTermBAM) ? (values.carrierTermBAM) : ("");
   frmdata.targetBaseCharge= (values.targetBaseCharge) ? (values.targetBaseCharge) : ("");
   frmdata.targetFuelCharge= (values.targetFuelCharge) ? (values.targetFuelCharge) : ("");
   frmdata.targetAccessorial= (values.targetAccessorial) ? (values.targetAccessorial) : ("");
   frmdata.targetTenderedPrice= (values.targetTenderedPrice) ? (values.targetTenderedPrice) : ("");
   frmdata.carrierAdvance= (values.carrierAdvance) ? (values.carrierAdvance) : ("");
   frmdata.carrierAmount= (values.carrierAmount) ? (values.carrierAmount) : ("");
   frmdata.fuelAdvance= (values.fuelAdvance) ? (values.fuelAdvance) : ("");
   frmdata.fuelAdvanceAmount= (values.fuelAdvanceAmount) ? (values.fuelAdvanceAmount) : ("");
   frmdata.carrierCompanyId= (values.carrierCompanyId) ? (values.carrierCompanyId) : ("");
   this.props.dispatch(updateTenderPopup(frmdata)); 
 }

class TenderPopup extends Component{
    componentDidMount()
    {
      const params1={
            companytypeid:7
         }
      this.props.dispatch(companyListByType(params1));	
    }

    state = {
      brokerGrossMargin: '',
      estimatedBAMwireFee:''
    };
  
   onshareManually=(event)=>{ 
      if(event.target.value==="shareManually")
      {
         var element = document.getElementById("choosecarriertabid"); 
         element.classList.add("choosecarriertabshow");
         element.classList.remove("choosecarriertabnone");
         //this.props.dispatch(commonlist());
      }
      else if(event.target.value==="shareAll")
      {
         var element = document.getElementById("choosecarriertabid"); 
         element.classList.remove("choosecarriertabshow");
         element.classList.add("choosecarriertabnone");
      }
   }  

   TenderPLSummarycalculation =(newVal)=>
   {
      var shipperLoadPrice = parseFloat(this.props.initialValues.shipperLoadPrice);
      var carrierTenderPrice = parseFloat(newVal);
      if(isNaN(shipperLoadPrice))
      {
         shipperLoadPrice=0;
         this.props.change('shipperLoadPrice', shipperLoadPrice);
      }
      if(isNaN(carrierTenderPrice))
      {
         carrierTenderPrice=0;
         this.props.change('carrierTenderPrice', carrierTenderPrice);
      }
      var brokergrossmargincal=shipperLoadPrice+carrierTenderPrice; 
      this.props.change('brokerGrossMargin', brokergrossmargincal);
   }

   onchangeForbrokerGrossOrigincalculation=(event)=>
   {    
      this.props.change('carrierTenderPrice', event.target.value);
      var newVal = event.target.value;
      this.TenderPLSummarycalculation(newVal);
   }

   onchangeForEstimatedNetcalculation=(event)=>
   {
      if(event.target.name==="brokerGrossMargin")
      {
        var val1 = event.target.value;
        this.props.initialValues.brokerGrossMargin = val1;
      }else{
         val1 = (this.props.initialValues.brokerGrossMargin) ? (this.props.initialValues.brokerGrossMargin) : 0; 
      }

      if(event.target.name==="estimatedBAMwireFee")
      {
         var val2 = event.target.value;
         this.props.initialValues.estimatedBAMwireFee = val2;
      }else{
          val2 = (this.props.initialValues.estimatedBAMwireFee) ? (this.props.initialValues.estimatedBAMwireFee) : 0;
      }     
      var result = val1-val2;
      this.props.change('estimatedBrokerNet', result);
   }

   onchangeForPricing=(event)=>
   {
      if(event.target.name==="targetBaseCharge")
      {
        var val1 = parseFloat(event.target.value);
        this.props.initialValues.targetBaseCharge = val1;
      }else{
        var val1 = (this.props.initialValues.targetBaseCharge) ? (this.props.initialValues.targetBaseCharge) : 0; 
      }

      if(event.target.name==="targetFuelCharge")
      {
         var val2 = parseFloat(event.target.value);
         this.props.initialValues.targetFuelCharge = val2;
      }else{
         var val2 = (this.props.initialValues.targetFuelCharge) ? (this.props.initialValues.targetFuelCharge) : 0;
      }  

      if(event.target.name==="targetAccessorial")
      {
         var val3 = parseFloat(event.target.value);
         this.props.initialValues.targetAccessorial = val3;
      }else{
         var val3 = (this.props.initialValues.targetAccessorial) ? (this.props.initialValues.targetAccessorial) : 0;
      }  
         
      var result = val1+val2+val3;
      this.props.change('targetTenderedPrice', result);
   }

   componentDidUpdate()
	{
      console.log(this.props)
      if(this.props.changeloadstatusupdateflag===true)
		{
        if(this.props.data.message==="Load Not Found")
        {
            toastr.error('Error', 'Load Not Found!');
            document.getElementById("brokertender").click();
            this.props.dispatch(dispatchflag());	
            const params={
               ...(STATUS.LOAD.ACCEPTED)
            }  
           this.props.dispatch(loadAction(params));	
        }
        else if(this.props.data.message==="Broker Tender Order Updated Successfully")
		  {
            toastr.success('Success', 'Broker Tender Order Updated Successfully!');
            document.getElementById("brokertender").click();
            this.props.dispatch(dispatchflag());	
            const params={
               ...(STATUS.LOAD.ACCEPTED)
            }  
            this.props.dispatch(loadAction(params));		
					
        }
        else if(this.props.data.status==="BAD_REQUEST")
        {
            toastr.error('error', 'Error!');
            document.getElementById("brokertender").click();
            this.props.dispatch(dispatchflag());	
            const params={
               ...(STATUS.LOAD.ACCEPTED)
            }  
           this.props.dispatch(loadAction(params));	
        }
      }
	}

render(){
   const { handleSubmit } = this.props  
   if(this.props.data.LoadInfo){
      var fuelAdvanceCommission = this.props.data.LoadInfo.fuel_Advance_Commission;
   }
   var TotalNet =0;
   if(this.props.initialValues)
   {
       TotalNet = this.props.initialValues.brokerGrossMargin;
    }  
  
return(
<form className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}>
   <div className="modal fade bd-trucks-modal-lg" id="LoadTenderOrderModalPopup"  tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg">
         <div className="modal-content">
            <div className="modal-header">
               <h5 className="modal-title" id="exampleModalLabel">BAM</h5>
               <button type="button" className="close" id="brokertender" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div className="popup-tender">
               <div className="row">
                  <div className="form-group col col-md-2">
                     <Field type="text" className="form-control" name="fromAddress" id="fromAddress" component={renderField} disable="true" label="From"/>                                           
                  </div>
                  <div className="form-group col col-md-2">
                     <Field type="text" className="form-control" name="toAddress" id="toAddress" component={renderField} disable="true" label="To"/>   
                  </div>
                  <div className="form-group col col-md-2"> 
                     <Field type="text" className="form-control" name="loadMiles" id="loadMiles" component={renderField} label="Miles"/>  
                  </div>
                  <div className="form-group col col-md-0 mt-4 justify-content-center">
                     <img src={logo} alt=""/>
                  </div>
                  <div className="form-group col col-md-3">                     
                     <Field type="text" className="form-control" name="loadDate" id="loadDate" component={renderDateTimePicker} label="Date"/> 
                  </div>
                  <div className="form-group col col-md-2">                  
                     <Field type="text" className="form-control" name="fuelCharges" id="fuelCharges" disable="true" component={renderField} label="Fuel Charge"/> 
                  </div>
               </div>
               <div id="accordionOne">
                  <table className="table table-borderless all-table tableTendered mb-0">
                     <thead>
                        <tr>
                           <th scope="col">P&L Summary</th>
                           <th scope="col">Dollars </th>
                           <th scope="col">Percentage</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>Shipper Load Price</td>
                           <td><Field type="text" name="shipperLoadPrice" id="shipperLoadPrice" disable="true" component={renderFieldforTableTextBox} onBlur={this.onchangeForbrokerGrossOrigincalculation}/></td> 
                           <td></td>
                        </tr>
                        <tr>
                           <td>Carrier Tender Price</td>
                           <td><Field type="text" name="carrierTenderPrice" id="carrierTenderPrice" component={renderFieldforTableTextBox} onChange={this.onchangeForbrokerGrossOrigincalculation}/></td>
                           <td>82.0%</td>
                        </tr>
                        <tr>
                           <td>Broker Gross Margin</td>
                           <td><Field type="text" name="brokerGrossMargin" id="brokerGrossMargin" component={renderFieldforTableTextBox} onChange={this.onchangeForEstimatedNetcalculation}/></td>
                           <td>18.0%</td>
                        </tr>
                        <tr>
                           <td>Estimated BAM wire Fee</td>
                           <td><Field type="text" name="estimatedBAMwireFee" id="estimatedBAMwireFee" component={renderFieldforTableTextBox}  onChange={this.onchangeForEstimatedNetcalculation}/></td>
                           <td>1.2%</td>
                        </tr>
                        <tr>
                           <td>Estimated Broker Net</td>
                           <td><Field type="text" name="estimatedBrokerNet" id="estimatedBrokerNet" component={renderFieldforTableTextBox} /></td>
                           <td>16.9%</td>
                        </tr>
                        <tr>
                           <td>Fuel Advance Commission</td>
                           <td>$  {fuelAdvanceCommission}</td>
                           <td></td>
                        </tr>
                     </tbody>
                     <tfoot>
                        <tr>
                           <td>Total Broker Net</td>
                           <td>$ <input type="text" name="totalnet" id="demo"/></td>
                           <td>16.9%</td>
                        </tr>
                     </tfoot>
                  </table>
                  <div className="row">
                     <div className="col-sm-12">
                        <div className="accordion accordion-bordered carrier-accordion" id="accordion-1" role="tablist">
                           <div className="accordion accordion-solid-header" id="Pricing-4" role="tablist">
                              <div className="card">
                                 <div className="card-header carrier-header" data-toggle="collapse" data-target="#Pricing-10" role="tab" id="heading-10">
                                    
                                    <h5 className="mb-0" style={{display: 'inline'}}>Pricing</h5>                                   
                                          
                                       <a className="btn-icon collapsed float-right" href='/#' data-toggle="collapse" data-target="#Pricing-10" aria-expanded="false" aria-controls="pickup">
                                            <i className="icofont-plus-circle icofont-2x"></i>
                                            <i className="icofont-minus-circle icofont-2x"></i>
                                        </a>                                 
                                  
                                 </div>
                                 <div id="Pricing-10" className="p-0 collapse" role="tabpanel" aria-labelledby="heading-10" data-parent="#Pricing-4" >
                                    <div className="card-body p-0">
                                       <div className="row m-0">
                                          <div className="col-sm-12 p-0">
                                             <div className="table-responsive">
                                                <table className="table table-borderless all-table tableTendered mb-0">
                                                   <tbody>
                                                      <tr>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                         <td>BAM Funds</td>
                                                         <td> / </td>
                                                         <td>Broker Funds</td>
                                                      </tr>
                                                      <tr>
                                                         <td>Base Charge :</td>
                                                         <td>$800.00</td>
                                                         <td>Cents/Mile: </td>
                                                         <td><Field type="text" name="milePerCents" id="milePerCents" component={renderFieldforTableTextBox} /></td>
                                                         <td>Target Base Charge:</td>
                                                         <td><Field type="text" name="targetBaseCharge" id="targetBaseCharge" component={renderFieldforTableTextBox} onChange={this.onchangeForPricing}/> </td>
                                                         <td>Carrier advance</td>
                                                         <td><Field type="text" name="carrierAdvance" id="carrierAdvance" component={renderFieldforTableTextBox} /></td>
                                                         <td> / </td>
                                                         <td>0%</td>
                                                      </tr>
                                                      <tr>
                                                         <td>Fuel Charge: </td>
                                                         <td>$129.69</td>
                                                         <td>Price/Mile:  </td>
                                                         <td>$2.72</td>
                                                         <td>Target Fuel Charge:</td>
                                                         <td><Field type="text" name="targetFuelCharge" id="targetFuelCharge" component={renderFieldforTableTextBox} onChange={this.onchangeForPricing}/> </td>
                                                         <td>Carrier amount</td>
                                                         <td>$762.57 </td>
                                                         <td> / </td>
                                                         <td>$0.00</td>
                                                      </tr>
                                                      <tr>
                                                         <td>Accessorial: </td>
                                                         <td>$0.00</td>
                                                         <td>Shipper Term:  </td>
                                                         <td>30</td>
                                                         <td>Target Accessorial:</td>
                                                         <td><Field type="text" name="targetAccessorial" id="targetAccessorial" component={renderFieldforTableTextBox} onChange={this.onchangeForPricing}/> </td>
                                                         <td>Fuel advance</td>
                                                         <td></td>
                                                         <td></td>
                                                         <td><Field type="text" name="fuelAdvance" id="fuelAdvance" component={renderFieldforTableTextBox} /> %  </td>
                                                      </tr>
                                                      <tr>
                                                         <td>Total: </td>
                                                         <td>$929.96</td>
                                                         <td>Carrier Term (Broker):</td>
                                                         <td><Field type="text" name="carrierTermBroker" id="carrierTermBroker" component={renderFieldforTableTextBox} /> </td>
                                                         <td>Target Tendered Price:</td>
                                                         <td><Field type="text" name="targetTenderedPrice" id="targetTenderedPrice" component={renderFieldforTableTextBox} /> </td>
                                                         <td>Fuel advance Amount</td>
                                                         <td></td>
                                                         <td></td>
                                                         <td>$0.00</td>
                                                      </tr>
                                                      <tr>
                                                         <td></td>
                                                         <td></td>
                                                         <td>Carrier Term (BAM):</td>
                                                         <td><Field type="text" name="carrierTermBAM" id="carrierTermBAM" component={renderFieldforTableTextBox} /> </td>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                      </tr>
                                                   </tbody>
                                                </table>
                                                <div className="card"> 
                                                <fieldset>
                                                   <div className="card-header" role="tab" id="heading-11">
                                                   {/* <div className="some-class">*/}
                                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                      <input type="radio" className="radio" name="shareManually" value="shareManually" id="shareManually" checked/>&nbsp;&nbsp;&nbsp;
                                                      <label htmlFor="shareManually">Choose Carrier</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                      <input type="radio" className="radio" name="shareAll" value="shareAll" id="shareAll" disabled/>&nbsp;&nbsp;&nbsp;
                                                      <label htmlFor="shareAll">Broadcast Load to Multiple Carriers</label>
                                                   {/* </div> */}
                                                   </div>
                                                  </fieldset>
                                                  <div className="card-body p-0" style={{background:'white'}}>
                                                     <div className="row">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <div className="col-md-4">
                                                          <Field type="text" className="form-control" name="carrierCompanyId" component={renderSelect} data={this.props.companyList} keyColumn="company_Id" keyValue="company_Name" controlClassName="level2" selectlabel="Carrier Name"/>
                                                        </div>
                                                        <div className="col-md-4"></div>
                                                        <div className="col-md-4"></div>
                                                       
                                                      </div>
                                                   </div>
                                                   {/* <div className="card-header" role="tab" id="heading-11">
                                                      <ul className="share-carrier">
                                                         <li>
                                                            <div className="form-check form-check-block">
                                                            <input className="form-check-input" type="radio" name="shareOption" id="shareManually" value="shareManually" onChange={this.onshareManually}/>
                                                            <label className="form-check-label" for="shareManually">Choose Carrier</label>
                                                            </div>
                                                         </li>
                                                         <li>
                                                            <div className="form-check form-check-block">
                                                            <input className="form-check-input" type="radio" name="shareOption" id="shareAll" value="shareAll" onChange={this.onshareManually}/>
                                                            <label className="form-check-label" for="shareAll">Broadcast Load to Multiple Carriers</label>
                                                            </div>
                                                         </li>
                                                      </ul>
                                                   </div> */}
                                                    {/* <div className="card-body p-0 choosecarriertabnone" id="choosecarriertabid">
                                                   <Field type="text" className="form-control" name="carrierCompanyId" component={renderSelect} data={this.props.data.companyList} keyColumn="company_Id" keyValue="company_Name" controlClassName="level2" selectlabel="Carrier Name"/>
                                                   </div> */}
                                                 </div>  
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                          
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-primary">Tender</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</form>
)
}
}

const PageOneForm = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   enableReinitialize: true
   })(TenderPopup)
 
 
 const mapStateToProps = (state,ownProps) => { 
   return{
       form: 'Tenderpop',
       data:state.loadReducer.data,
       initialValues: state.loadReducer.data.LoadInfo,   
       changeloadstatusupdateflag:state.loadReducer.changeloadstatusupdateflag,   
       validate,      
       enableReinitialize: true 
   }    
 }
 
 const PageOneFormContainer = connect(mapStateToProps)(PageOneForm)
   
 export default PageOneFormContainer
