import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalTitle from 'react-bootstrap/ModalTitle';
import $ from 'jquery';
import 'jquery-validation';
import { ViewTruckPOPUPComponent } from 'UI-Component';
class ViewTruckPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assetNumber: ''
        }
    }
    componentDidUpdate = (prevProps) => {
        if (this.props.userData !== prevProps.userData) {
            if (this.props.userData) {
                this.setState({
                    ...this.state,
                    assetNumber: this.props.userData.assetNumber,
                    assetName: this.props.userData.assetName,
                    make: this.props.userData.make,
                    licensePlateNo: this.props.userData.licensePlateNo,
                    assetType: this.props.userData.assetType,
                    licenseState: this.props.userData.licenseState,
                    identificationNo: this.props.userData.identificationNo,
                    modelYear: this.props.userData.modelYear,
                    assetSerialNo: this.props.userData.assetSerialNo,
                    color: this.props.userData.color,
                    manufacturer: this.props.userData.manufacturer,
                    axles: this.props.userData.axles,
                    size: this.props.userData.size,
                    eldProviderDisplayName: this.props.userData.eldProviderDisplayName
                });
            }
        }

    }
    closeThis = (flag) => {
        $('#view_truck').trigger('reset');
        this.props.closeNewUser(flag);
    }
    render = () => {
        return (
            <Modal size='lg' aria-labelledby='contained-modal-title-vcenter' centered show={this.props.newUserPopup} id='newuser'>
                <ModalHeader>
                    <ModalTitle>{this.props.title}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <ViewTruckPOPUPComponent state={this.state} />
                </ModalBody>
                <ModalFooter>
                    <button type='button' className='btn btn-primary' onClick={() => { this.closeThis(0) }}>Close</button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default ViewTruckPopup;