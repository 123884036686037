import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { renderSelect } from '../../../helpers/FormControls';
import { validate } from './loadReportsValidation';
import { DateTimePicker } from 'react-widgets'
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { loadStatusAction,loadReportAction,setgoFlag } from '../../../actions/reportAction';
import * as FileSaver from 'file-saver';
import { toastr } from 'react-redux-toastr';
import * as XLSX from 'xlsx';

const renderDateTimePicker = ({ input: { onChange, value }, label, meta: { touched, error }, showTime }) => (
    <div className={` ${touched && error ? 'error' : ''}`}>
        <DateTimePicker
            dropDown={true}
            onChange={onChange}
            className="requiredfield"
            format="MM-DD-YYYY HH:mm"
            time={true}
            value={!value ? null : new Date(value)}
           // max={new Date()}
        />
        {touched && error && <span className="error">{error}</span>}
    </div>
);


function submit(values) {
    const frmdata = {};
    var from_date = (values.fromDate) ? (moment(values.fromDate).format('YYYY-MM-DD HH:mm:ss')) : ("");
    var to_date = (values.toDate) ? (moment(values.toDate).format('YYYY-MM-DD HH:mm:ss')) : ("");
    frmdata.fromDate= from_date
    frmdata.toDate= to_date
    frmdata.loadStatus=(values.loadStatus) ? (values.loadStatus) : ("");
    //console.log(frmdata)
    //this.props.dispatch(loadReportAction(frmdata));    
    frmdata.exportFlg = this.state.export;
    frmdata.goFlg = this.state.go;
    this.props.dispatch(loadReportAction(frmdata));    
 }


class loadReports extends Component {
   
    state = {
		export : false,
		go :  false,
    };
    componentDidMount() {		
        document.title = 'Reports';
		this.props.dispatch(loadStatusAction());
	}

    handleExportClick = (e) =>{
		if(this.props.invalid === false){
			this.setState({
				export : true,
				go : false
			});
		}
	}
	handleGoClick = (e) => {		
		if(this.props.invalid === false){
			this.setState({
					export : false,
					go : true
			});
		}
    }
    
    render() {
        var indicationhtml = "No Data Found"
        if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}        
        const { handleSubmit } = this.props;
		if(this.props.exportFlg === true)
		{	
            if(this.props.data.loadInfoList !== undefined)
            {
                if(this.props.data.loadInfoList.length > 0)
                {
                    this.props.dispatch(setgoFlag(false));		
                    let csvData = [];
                    csvData = this.props.data.loadInfoList;	
                    let fileName = 'LoadReport';
                    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    const fileExtension = '.xlsx';
                    const ws = XLSX.utils.json_to_sheet(csvData);
                    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const data = new Blob([excelBuffer], {type: fileType});
                    FileSaver.saveAs(data, fileName + fileExtension);
                }
                else{
                    toastr.info('Info', 'No Data Available!');
                }
            }
        }
        var LoadReports=[];
		//if(this.props.goFlg == true)
		//{	
           // console.log("Get List")
            if(this.props.loading){
                indicationhtml = 
                <div className="spinner-border" role="status">
                 <div className="row react-bs-table-no-data"></div>
                 </div>
            }else
            {
            if(this.props.data.loadInfoList !== undefined)
            {
                if(this.props.data.loadInfoList.length > 0)
                {
                    LoadReports = this.props.data.loadInfoList.map(
                        row =>{                          
                            var stringLoadDate="";
                            if(row.stringLoadDate!=="")
                            {
                                stringLoadDate=moment(row.loadDate).format('MM-DD-YYYY');
                            }
                            else
                            {
                                stringLoadDate= "";
                            }
                            return({
                                id:row.loadId,
                                loadNumber:row.loadNumber,
                                assetNumber:row.vehicleId,
                                shipperReferenceNumber:row.shipperReferenceNumber,
                                stringLoadDate:stringLoadDate,
                                shipperName:row.shipperName,
                                carrierName:row.carrierName,
                                brokerName:row.brokerName,
                                createdTime:row.createdTime,
                                updatedTime:row.updatedTime,
                                orginAddress:row.originAddress,
                                destinationAddress:row.destinationAddress,
                                brokerLoadstatus:row.brokerLoadstatus,
                                carrierLoadstatus:row.carrierLoadstatus,
                                shipperLoadstatus:row.shipperLoadstatus

                            });
                        }
                    );
                }
                else{
                    LoadReports = [];
                    indicationhtml="No Data Found";
                }
            }
           } 
       // }
        const { SearchBar } = Search;        
        
		const columns=[	
		{
			text:'Load #',
			dataField:'loadNumber',
			sort:true
        },
        {
			text:'ShipperRef',
			dataField:'shipperReferenceNumber',
			sort:true
		},
		{
			text:'Date',
			dataField:'stringLoadDate',
			sort:true
        },
        {
			text:'Api Load Date',
			dataField:'createdTime',
			sort:true
        },
        {
			text:'Vehicle No',
			dataField:'assetNumber',
			sort:true
        },
        {
			text:'Carrier',
			dataField:'carrierName',
			sort:true
		},
		{
			text:'Broker',
			dataField:'brokerName',
			sort:true
		},
		{
			text:'Shipper',
			dataField:'shipperName',
			sort:true
		},
		{
			text:'Origin',
			dataField:'orginAddress',
			sort:true
		},
		{
			text:'Destination',
			dataField:'destinationAddress',
			sort:true
        },
        {
			text:'Broker Status',
			dataField:'brokerLoadstatus',
			sort:true
        },
        {
			text:'Carrier Status',
			dataField:'carrierLoadstatus',
			sort:true
        },
        {
			text:'Shipper Status',
			dataField:'shipperLoadstatus',
			sort:true
        },
        {
			text:'Modified Date',
			dataField:'updatedTime',
			sort:true
        },
	   ]
       
        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Showing { from } to { to } of { size } Results
            </span>
          );
          
          const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
          };
        return (
             <article className="table-data truck-data shadow bg-white rounded" style={{display:'block'}}>
                <h3>Load Summary</h3> <br></br>
                <form onSubmit={handleSubmit(submit.bind(this))}>
                    <div className="row">
                    <div className="form-group col col-lg-4">
                    <label>From Date</label>
                    <Field
                        name="fromDate"
                        defaultValue={null}
                        component={renderDateTimePicker}
                        />
                    </div>
                    <div className="form-group col col-lg-3">
                    <label>To Date</label>
                    <Field
                        name="toDate"
                        defaultValue={null}
                        component={renderDateTimePicker}
                        />
                    </div>
                    <div className="form-group col col-lg-3">
                    <Field type="text" name="loadStatus" controlClass="requiredfield" component={renderSelect} label="Load Status" data={this.props.loadStatusList}/> 
                    </div>
                    <div className="form-group col col-lg-2">
                        <button type="submit"  value={this.state.go} onClick={this.handleGoClick}  className="btn btn-secondary searchbtn">Get</button>&nbsp;
                        <button type="submit"  value={this.state.export} onClick={this.handleExportClick} className="btn btn-secondary searchbtn" >Export</button>
                    </div>
                    </div>
                </form>
                <ToolkitProvider
                        keyField="id"
                        data={ LoadReports }
                        columns={ columns }
                        search
                        >
                        {
                        props => (
                            <div>
                             <SearchBar { ...props.searchProps } /> 
                            <BootstrapTable
                                { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={indicationhtml} hover 
                                bordered={ false }
                            />
                            </div>
                        )
                        }
                    </ToolkitProvider>
            </article>
        )
    }
}

const mapStateToProps = state => {
    return {
        data : state.reportReducer.data,
        loading : state.reportReducer.loading,
        loadReportData : state.reportReducer.data,
        loadStatusList : state.reportReducer.loadStatusList,
		exportFlg: state.reportReducer.exportFlg,
		goFlg: state.reportReducer.goFlg
    }
  }
  
  export default connect(mapStateToProps)(reduxForm({
      form:'loadReports',validate
  })(loadReports))