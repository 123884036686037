import React from 'react';
import { Link } from 'react-router-dom';
import TrailerNew from './TrailerNew';

const TrailersList= ({ trailerlist }) => {
	const trailerListHtml = trailerlist.map( tList =>{
		return(
			<tr key={ tList.id }>
				<td><i className="circledown icofont-circled-down"></i></td>
	      		<td>{ tList.Trailername }</td>
	      		<td>{ tList.year }</td>
	      		<td>{ tList.make }</td>
	      		<td>{ tList.model }</td>
	      		<td>{ tList.plate }</td>
	      		<td>{ tList.Serial }</td>
	      		<td>
	      		    <div className="dropdown">
			         <label data-toggle="dropdown">&hellip;</label>
			           <div className="dropdown-menu">		
						 <a className="dropdown-item" data-toggle="modal" data-target=".bd-trailersedit-modal-lg" href="#"><i className="icofont-ui-edit"></i> Edit</a>
						 <Link className="dropdown-item" to="viewtrailer"><i className="icofont-eye-alt"></i> View</Link>
			           </div>
			         </div>
	      		</td>
	    	</tr>
    	)
	});

	return(
		<article className="table-data truck-data shadow bg-white rounded">
			<div className="row no-gutters">
				<div className="col-md-12">
					<ul className="nav nav-tabs" id="myTrucks" role="tablist">
					  	<li className="nav-item">
					    	<a className="nav-link active" id="mytrailers-tab" data-toggle="tab" href="#mytrailers" role="tab" aria-controls="mytrailers" aria-selected="true"><i className="icofont-truck-loaded"></i> My Trailers</a>
					  	</li>				  
					  	
					</ul>
					<div className="tab-content" id="myTabContent">
					  	<div className="tab-pane fade show active" id="mytrailers" role="tabpanel" aria-labelledby="mytrailers-tab">
					  		<table className="table table-sm">
							  	<thead>
							    	<tr>
										<th scope="col"></th>
								      	<th scope="col">Trailer #</th>
								      	<th scope="col">Year</th>
								      	<th scope="col">Make</th>
								      	<th scope="col">Model</th>
								      	<th scope="col">Plate #</th>
								      	<th scope="col">Serial</th>
								      	<th scope="col">More</th>
							    	</tr>
							  	</thead>
							  	<tbody>
							    	{ trailerListHtml }
							  	</tbody>
							 </table>
					  	</div>
					  	<div className="tab-pane fade" id="newtrailers" role="tabpanel" aria-labelledby="newtrailers-tab">
					  		<TrailerNew />
					  	</div>
					  	<div className="tab-pane fade" id="bulkupload" role="tabpanel" aria-labelledby="bulkupload-tab">
					  		
					  	</div>
					</div>
				</div>
			</div>
		</article>
	)
}
	
export default TrailersList;