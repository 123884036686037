import { takeLatest } from 'redux-saga/effects';
import * as types from '../actions';
import { loginSaga, logoutSaga } from './authSaga';
import { dashboardSaga } from './dashboardSaga';
import { truckSaga } from './truckSaga';
import { loadSaga } from './loadSaga';

export default function* watchUserAuthentication(){
    //yield takeLatest(types.REGISTER_USER, registerSaga);
    yield takeLatest(types.LOGIN_USER, loginSaga);
    yield takeLatest(types.LOGOUT_USER, logoutSaga);
    yield takeLatest(types.GET_DASHBOARD_DATA, dashboardSaga);
    yield takeLatest(types.GET_TRUCK_DATA, truckSaga);
    // yield takeLatest(types.GET_LOAD_DATA, loadSaga);
}