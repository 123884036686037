import React, { Component } from 'react';
import Icofont from 'react-icofont';
import ReactTooltip from 'react-tooltip';
import loadVehicleicon from '../../images/loadedtruck.png';
import {formatLocation} from '../../helpers/appUtil';
import $ from 'jquery';

class DashboardTrucksNotOnBoarded extends Component{

	constructor(props){
		super(props);
		this.timerSrc=null;
		this.srcData='';
	}

	handleClick = (arg1,arg2) =>{ 
		console.log(arg1,arg2)
		var checked = document.getElementById("cb"+arg1.id).checked;
		console.log(arg1,checked);
		var arr = [];
		if(arg2.target.className  === 'icofont-circled-right'){
			arg2.target.className = "icofont-circled-down";
			arr.push(arg1);
			if(checked) this.props.truckClicked(arr);		
		}else{
			arg2.target.className = "icofont-circled-right";
			this.props.truckClicked(arr);	
		}		
	}	
	handleChecked = (arg) => {
		var remainingEle = this.props.selectedData.filter(function(truck){
			var checked = document.getElementById("cb"+truck.id).checked;
			return checked !== false; 
		});		
		this.props.checkList(remainingEle);		
	}

	truckClick = (e, truck) => {
		this.props._truckClick(truck);		
	}

	searchTrucks = (e) => {		
		this.srcData=$(e.target).val().trim();
		if(this.timerSrc){
			clearTimeout(this.timerSrc);
		}
		this.timerSrc = setTimeout(
			function() {				
				clearTimeout(this.timerSrc);
				this.timerSrc=null;
				this.setState({});
			}
			.bind(this),
			500
		);
		//console.log(this.props.stopped);
	}

	filterData = (data) => {
		if(!(data.length)) return [];

		if(this.srcData.length===0) return data;

		let userType = localStorage.getItem('userType').toLowerCase();

		let expr = RegExp(this.srcData, 'i');
		let row = data.filter((item,index) =>{			
			return expr.test((userType==="shipper" ? item.shipperRefNumber : item.truckNo));
		});
		return row;
	}

	render(){
		//console.log(this.filterData(this.props.stopped));
		let userType = localStorage.getItem('userType').toLowerCase();
		console.log(userType);
		let label = userType === "shipper"?"Load":"Driver";

		var stopped = this.filterData(this.props.stopped);
		var idle    = this.filterData(this.props.idle);
		var active  = this.filterData(this.props.active);	
	    const stoppedData = stopped.length ? (
		    stopped.map((stoppedList,index) => {
				var titleval='';
				let location = formatLocation(stoppedList.location);
				console.log("shipper ref======="+stoppedList.shipperRefNumber) ;
				if(userType ==='broker') {
					if(stoppedList.shipperRefNumber!==null) //&&userType!=='carrier'
					{
						titleval="Customer Load # : "+stoppedList.shipperRefNumber;
					}
					// else if(stoppedList.loadNumber!==null && userType!=='carrier')
					// {
					// 	//titleval="Customer Load # : N/A";
					// 	titleval="Customer Load # : "+stoppedList.loadNumber;						
					// }
				}
				var truckOrPoNumber = '' ;				
				if(userType !== 'shipper') {
					truckOrPoNumber = "Driver Name : "+  stoppedList.truckNo ;					
				} else {
					truckOrPoNumber = "Load No : "+  stoppedList.shipperRefNumber ;
				}
				return (
					<article key={index}>
		          	<div className="card" >
				    	<div className="card-header" id={"stoppedHedaing" + stoppedList.id}>
			        		<div className="form-check">
							 	<input className="form-check-input" type="checkbox" onChange={this.handleChecked.bind(stoppedList)} value={stoppedList.id}  defaultChecked="true" id={"cb" + stoppedList.id} style={{display: 'none'}} />
								    <label  data-placement="top" onClick={(e) => {this.truckClick(e,stoppedList)}}  className="form-check-label" htmlFor={"cb1" + stoppedList.id}>
									  <span>
									  <Icofont onClick={this.handleClick.bind(this, stoppedList)} icon="circled-right" data-toggle="collapse" data-target={"#stoppedCollapse" + stoppedList.id} aria-expanded="true" aria-controls={"stoppedCollapse" + stoppedList.id}/>
									  </span> <span data-tip={titleval} title={titleval}>
									   {truckOrPoNumber} {(stoppedList.loadVehicle===true) ? (<span><img alt="" src= {loadVehicleicon} /></span>) : (<span></span>)}								  
								  		</span>
								       <ReactTooltip place='top'/> </label>

							  	{/* <label className="form-check-label"><span><Icofont onClick={this.handleClick.bind(this, stoppedList)} icon="circled-right" data-toggle="collapse" data-target={"#stoppedCollapse" + stoppedList.id} aria-expanded="true" aria-controls={"stoppedCollapse" + stoppedList.id}/></span> Driver Name : { stoppedList.truckNo }</label> */}
							  	<span className="float-right" style={{color:'red'}} ><Icofont icon="ui-press"/></span>
							</div>
				    	</div>
				    	<div id={"stoppedCollapse" + stoppedList.id} className="collapse" aria-labelledby={"stoppedHedaing" + stoppedList.id} data-parent="#stopped_stats">
				      		<div className="card-body">
				        		<ul>
				        			<li>
				        				<span>Date:</span><span>{stoppedList.convertedDate}</span>
				        			</li>
				        			<li>
				        				<span>Location:</span><span>{location}</span>
				        			</li>
				        			<li>
				        				<span>Reason</span><span>-</span>
				        			</li>
				        		</ul>
				      		</div>
				    	</div>
				  	</div>
					  </article>
		        )
		    })
		    ) : (
		      	<div className="stay-vertical">
		  			<div>
		  				<p>No Results Found</p>
		  			</div>
		  		</div>
		    );

		const idleData = idle.length ? (
		    idle.map((idleList,index) => {
				var titlevalidle='';
				let location = formatLocation(idleList.location);
				if(userType ==='broker') {
					if(idleList.shipperRefNumber!==null)
					{
						titlevalidle="Customer Load # : "+idleList.shipperRefNumber;
					}
					// else if(idleList.loadNumber!==null && userType!=='carrier')
					// {
					// 	titlevalidle="Customer Load # : "+idleList.loadNumber;					
					// }
				}
				var truckOrPoNumber = '' ;				
				if(userType !== 'shipper') {
					truckOrPoNumber = "Driver Name : "+  idleList.truckNo ;					
				} else {
					truckOrPoNumber = "Load No : "+  idleList.shipperRefNumber ;
				}
				return (
		          	<div className="card" key={index}>
				    	<div className="card-header" id={"idleHeading" + idleList.id}>
			        		<div className="form-check">
							 	{/* <input className="form-check-input" type="checkbox"  onChange={this.handleChecked.bind(idleList)} value={idleList.id}  defaultChecked="true" id={"cb" + idleList.id} data-toggle="collapse" data-target={"#idleCollapse" + idleList.id} aria-expanded="true" aria-controls={"idleCollapse" + idleList.id} />
							  	<label className="form-check-label"><span><Icofont onClick={this.handleClick.bind(this, idleList)} icon="circled-down"/></span> Driver Name : { idleList.truckNo }</label>
							  	<span className="float-right" style={{color:'#fdc800'}}>Idle <Icofont icon="ui-press"/></span> */}
								<input className="form-check-input" type="checkbox" onChange={this.handleChecked.bind(idleList)} value={idleList.id}  defaultChecked="true" id={"cb" + idleList.id} style={{display: 'none'}} />
								<label  data-placement="top" onClick={(e) => {this.truckClick(e,idleList)}} className="form-check-label" htmlFor={"cb1" + idleList.id}>
									<span>
									<Icofont onClick={this.handleClick.bind(this, idleList)} icon="circled-right" data-toggle="collapse" data-target={"#idleCollapse" + idleList.id} aria-expanded="true" aria-controls={"idleCollapse" + idleList.id}/>
									</span> <span data-tip={titlevalidle} title={titlevalidle}>
									{ truckOrPoNumber} {(idleList.loadVehicle===true) ? (<span><img alt="" src= {loadVehicleicon} /></span>) : (<span></span>)}								  
									</span>
									<ReactTooltip place='top'/> </label>

								<span className="float-right" style={{color:'#fdc800'}}><Icofont icon="ui-press"/></span>
							</div>
				    	</div>
				    	<div id={"idleCollapse" + idleList.id} className="collapse" aria-labelledby={"idleHeading" + idleList.id} data-parent="#idle_stats">
				      		<div className="card-body">
				        		<ul>
				        			<li>
				        				<span>Date:</span><span>{idleList.convertedDate}</span>
				        			</li>
				        			<li>
				        				<span>Location:</span><span>{location}</span>
				        			</li>
				        			<li>
				        				<span>Reason</span><span>-</span>
				        			</li>
				        		</ul>
				      		</div>
				    	</div>
				  	</div>
		        )
		    })
		    ) : (
		      	<div className="stay-vertical">
		  			<div>
		  				<p>No Results Found</p>
		  			</div>
		  		</div>
		);

		const activeData = active.length ? (
		    active.map((activeList,index) => {
				var titlevalactive='';
				let location = formatLocation(activeList.location);
				if(userType==='broker') {
					if(activeList.shipperRefNumber!==null)
					{
						titlevalactive="Customer Load # : "+activeList.shipperRefNumber;
					}
					// else if(activeList.loadNumber!==null && userType!=='carrier')
					// {
					// 	titlevalactive="Customer Load # : "+activeList.loadNumber;					
					// }
				}
				var truckOrPoNumber = '' ;				
				if(userType !== 'shipper') {
					truckOrPoNumber = "Driver Name : "+  activeList.truckNo ;					
				} else {
					truckOrPoNumber = "Load No : "+  activeList.shipperRefNumber ;
				}
				return (
					<div className="card" key={index}>
						<div className="card-header" id={"activeHedaing" + activeList.id}>
							<div className="form-check">
								{/* <input className="form-check-input" type="checkbox" onChange={this.handleChecked.bind(activeList)} value={activeList.id}   defaultChecked="true" id={"cb" + activeList.id} />
								<label className="form-check-label"><span><Icofont onClick={this.handleClick.bind(this, activeList)} icon="circled-right" data-toggle="collapse" data-target={"#activeCollapse" + activeList.id} aria-expanded="true" aria-controls={"activeCollapse" + activeList.id}/></span> Driver Name : { activeList.truckNo }</label>
								<span className="float-right" style={{color:'green'}}> Active <Icofont icon="ui-press"/></span> */}
								<input className="form-check-input" type="checkbox" onChange={this.handleChecked.bind(activeList)} value={activeList.id}  defaultChecked="true" id={"cb" + activeList.id} style={{display: 'none'}} />
								<label  data-placement="top" onClick={(e) => {this.truckClick(e,activeList)}} className="form-check-label" htmlFor={"cb1" + activeList.id} data-toggle="collapse" data-target={"#activeCollapse" + activeList.id} aria-expanded="true" aria-controls={"activeCollapse" + activeList.id}>
									<span>
									<Icofont onClick={this.handleClick.bind(this, activeList)} icon="circled-right" data-toggle="collapse" data-target={"#activeCollapse" + activeList.id} aria-expanded="true" aria-controls={"activeCollapse" + activeList.id}/>
									</span> <span data-tip={titlevalactive} title={titlevalactive}>
									{ truckOrPoNumber } {(activeList.loadVehicle===true) ? (<span><img alt="" src= {loadVehicleicon} /></span>) : (<span></span>)}								  
									</span>
									<ReactTooltip place='top'/> </label>

								<span className="float-right" style={{color:'green'}}> <Icofont icon="ui-press"/></span>
							</div>
						</div>
						<div id={"activeCollapse" + activeList.id} className="collapse" aria-labelledby={"activeHedaing" + activeList.id} data-parent="#stopped_stats">
							<div className="card-body">
								<ul>
									<li>
										<span>Date:</span><span>{activeList.convertedDate}</span>
									</li>
									<li>
										<span>Location:</span><span>{location}</span>
									</li>
									<li>
										<span>Reason</span><span>-</span>
									</li>
								</ul>
							</div>
						</div>
					</div>		          
		        )
		    })
		    ) : (
		      	<div className="stay-vertical">
		  			<div>
		  				<p>No Results Found</p>
		  			</div>
		  		</div>
		    );
			return (
			    <div className="truck-status">
				<h4 className="page-title">{label} Status</h4>
				{/* <ul className="nav nav-tabs flex-column flex-sm-row" id="myTab" role="tablist">
				  	<li className="nav-item flex-sm-fill text-sm-center">
				    	<a className="nav-link active" onClick={()=>{this.props.tabClicked(1);}} id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Active [{this.props.active.length}]</a>
				  	</li>
				  	<li className="nav-item flex-sm-fill text-sm-center">
				    	<a className="nav-link" onClick={()=>{this.props.tabClicked(2);}} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Idle [{this.props.idle.length}] </a>
				  	</li>
				  	<li className="nav-item flex-sm-fill text-sm-center">
				    	<a className="nav-link" onClick={()=>{this.props.tabClicked(3);}} id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Stopped [{this.props.stopped.length}] </a>
				  	</li>
				</ul> */}
				<div className="tab-search">
					<form onSubmit={e => { e.preventDefault(); }}>
						<div className="form-group form-icon" id="searchicondashboard">
							<input type="text" onKeyUp={(e) => {this.searchTrucks(e)}} className="form-control control-custom" id="truckSearch" placeholder={"Search "+label} />  
							<span><Icofont icon="search"/></span>  						
							</div>
					</form>
				</div>
				<div className="tab-content" id="myTabContent">
				  	<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
				  		<div className="accordion" id="active_stats">
						  	{activeData}
						</div>
				  	</div>
				  	<div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
				  		<div className="accordion" id="idle_stats">
						  	{idleData}
						</div>
				  	</div>
				  	<div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
			  			<div className="accordion" id="stopped_stats">
						  	{stoppedData}
						</div>
				  	</div>
				</div>
			</div>
		)
	}
}

export default DashboardTrucksNotOnBoarded