import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { geofenceRadiusListAction, geofenceMailTimeListAction, geofenceCompanyNameListAction,geoFenceRadiusUpdate } from '../../../actions/companyActions';
import ReactTooltip from 'react-tooltip';
import { toastr } from 'react-redux-toastr';
import $ from 'jquery'
class BrokersCarrierList extends Component
{
	constructor(props)
	{
		super(props);
		this.state={
			isReady:false,
			shipperList:[],
			GeofenceRad:[
				{id:"5",radiusVal:"5 Miles"},
				{id:"10",radiusVal:"10 Miles"},
				{id:"15",radiusVal:"15 Miles"},
				{id:"20",radiusVal:"20 Miles"}
			],
			GeofenceRadTime:[
				{id:"30",radiusValTime:"30 min before pickup time"},
				{id:"60",radiusValTime:"60 min before pickup time"},
				{id:"90",radiusValTime:"90 min before pickup time"},
				{id:"120",radiusValTime:"120 min before pickup time"},
				//{id:"00:30",radiusValTime:"30 min after pickup time"},
				//{id:"01:00",radiusValTime:"60 min after pickup time"},
				//{id:"01:20",radiusValTime:"120 min after pickup time"}
			],
			// GeofenceRadTime:[
			// 	{id:"00:10",radiusValTime:"00:10"},
			// 	{id:"00:20",radiusValTime:"00:20"},
			// 	{id:"00:30",radiusValTime:"00:30"},
			// 	{id:"00:40",radiusValTime:"00:40"},
			// 	{id:"00:50",radiusValTime:"00:50"},
			// 	{id:"01:00",radiusValTime:"01:00"}
			// ],
		}	
	}
	componentDidMount=()=>
	{		
		const params1={
            companytypeid:2,
         }    
        this.props.dispatch(geofenceRadiusListAction()); 
        this.props.dispatch(geofenceMailTimeListAction());
        this.props.dispatch(geofenceCompanyNameListAction(params1)); 
	}

	updatejeofenceRadius = (row,index) => {
		
		var radius = document.querySelector('#radiusValue'+[index]); 
		var timeValue = $('#Loadtimevalue'+[index]).val();  //console.log(timeValue)
		var notifyMail = $('#tOEmailIds'+[index]).val();
		console.log('form values :',notifyMail);
		const params={
			companyId:localStorage.getItem('companyId'),
			invitedCompanyId:row.Company_Id,
			arrivalGeofence:radius.value,
			geofenceTime:timeValue,
			tOEmailIds:notifyMail
         } 
        this.props.dispatch(geoFenceRadiusUpdate(params));
	}
	
	componentDidUpdate()
	{
		console.log(this.props);

		if(this.props.data.status==="ACCEPTED")
        {
		   toastr.success('Success', 'Geofence details updated successfully!');
		   const params1={
             companytypeid:2,
           }               
           this.props.dispatch(geofenceCompanyNameListAction(params1)); 
        }
	}

	userlist = [];
	render()
	{
		document.title = 'Geofence Configuration'; 
		var userlist = [];
		var indicationhtml = "No Data Found"
		if(this.props.loading){
			indicationhtml = 
			<div className="spinner-border" role="status">
			 <div className="row react-bs-table-no-data"></div>
			 </div>
		}
		else if(this.props.companyNameListValue)
		{
			userlist = [];
			console.log('company list :',this.props.companyNameListValue.companyList)
			if(this.props.companyNameListValue.companyList) {
				userlist = this.props.companyNameListValue.companyList.map(
					(row,index) =>{
						return({
							id:index,
							companyId:row.Company_Id,
							companyEmail:<span>
							<i data-tip data-for={"driveremail"+row.Company_Id} id="lanehistoryclass" className="icofont-email"></i>
								<ReactTooltip id={"driveremail"+row.Company_Id} >
									<p>Email : {row.Company_Email}</p>
								</ReactTooltip>
							</span>,
							companyName:row.Company_Name,
							phoneNumber:<span>
							<i data-tip data-for={"driverphnnumber"+row.Company_Id} id="lanehistoryclass" className="icofont-phone-circle"></i>
							<ReactTooltip id={"driverphnnumber"+row.Company_Id} >
								<p>Phone : {row.Phone_Number}</p>
							</ReactTooltip>
							</span>,
							geoFenceRadius:
	
							<select defaultValue={row.Geofence_Arrival_Radius} id={"radiusValue"+index} className="form-control json-col" name="radiusval">
							<option value="">Select Radius</option>
								{
									this.props.geofenceRadius?(this.props.geofenceRadius.data.map(function(row, index) {  
									return(
									<option key={index} value={row.Geofence_Radius}>{row.Geofence_Radius_Text}</option>
									)             
									})):(<option></option>)
								}                                       
								</select>,
							geofencetime:
	
							<select defaultValue={row.Geofence_Pickup_Notify_Time} id={"Loadtimevalue"+index} className="form-control json-col" name="radiusval">
							<option value="">Select Time</option>
								{
									this.props.geofenceMailTimeList?(this.props.geofenceMailTimeList.data.map(function(row, index) {  
									return(
									<option key={index} value={row.Geofence_Mail_Time}>{row.Geofence_Mail_Time_Text}</option>
									)             
									})):(<option></option>)
								}                                       
							</select> ,
							NotifyEmail : <input type="text" className="form-control json-col" defaultValue={row.TO_Email_Ids} id={"tOEmailIds"+index} name="tOEmailIds" />,
							Action : <button className="btn btn-primary" id={"truckval"+index} onClick={ (e) => this.updatejeofenceRadius(row,index) }>Update</button>					
						});
					}
				);
			}
		}
		else
		{
			userlist = [];		
			indicationhtml = "No Data Found"		
		}	
		const { SearchBar } = Search;
		const columns=[	
		    {
				text:'Company Name',
				dataField:'companyName',
				sort:true,
            },
            {
				text:'Phone Number',
				dataField:'phoneNumber',
				sort:true,
            },
            {
				text:'Email',
				dataField:'companyEmail',
				sort:true,
            },
            {
				text:'Geofence Radius',
				dataField:'geoFenceRadius',
                sort:true,
                headerStyle:{width:'15%'}
            },
            {
				text:'Email Schedule',
				dataField:'geofencetime',
                sort:true,
                headerStyle:{width:'15%'}
			},
			{
				text:'Notify Email',
				dataField:'NotifyEmail',
				sort:true,
				headerStyle:{width:'15%'}
            },
            {
				text:'Action',
				dataField:'Action',
                sort:true,
            }
		   
		]
		const customTotal = (from, to, size) => (
			<span className="react-bootstrap-table-pagination-total">
			  Showing { from } to { to } of { size } Results
			</span>
		  );
		  
		  const options = {
			paginationSize: 4,
			pageStartIndex: 1,
			firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: customTotal
		  };
		return(
			<article className="table-data truck-data shadow bg-white rounded">
				<div className="row no-gutters">
					<div className="col-md-12">
						<ul className="nav nav-tabs" id="myTrucks" role="tablist">
						  	<li className="nav-item">
						    	<a className="nav-link active" id="carriers-tab" data-toggle="tab" href="#carriers" role="tab" aria-controls="carriers" aria-selected="true">GEOFENCE CONFIG</a>
						  	</li>
						</ul>
						<div className="tab-content" id="myTabContent">
						  	<div className="tab-pane fade show active" id="carriers" role="tabpanel" aria-labelledby="carriers-tab">
						  	<ToolkitProvider
									keyField="id"
									data={ userlist }
									columns={ columns }
									search
								   >
									{
									props => (
										<div>
										<SearchBar { ...props.searchProps } />
										<BootstrapTable bootstrap4 
											{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={indicationhtml}  hover bordered={ false } />
										</div>
									)
									}
								</ToolkitProvider>
						  	</div>
						</div>
					</div>
				</div>
			</article>
		)
}
}
const mapStateToProps = (state) => {
	return{
		loading : state.companyReducer.loading,
        data : state.companyReducer.data,
        geofenceRadius : state.companyReducer.geofenceRadiusList,
        geofenceMailTimeList : state.companyReducer.geofenceMailTimeList,
		companyNameListValue : state.companyReducer.geofenceCompanyNamelist,
	}

}
export default connect(mapStateToProps)(BrokersCarrierList)