import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { loadAction, loadActionfrquickdispatch, quickDispatchAction } from '../../../actions/loadActions';
import { STATUS } from '../../../helpers/status';
import { getMapboxKey } from '../../../helpers/appUtil';
import moment from 'moment';
import cancel from '../../../cancel.png';
import $ from 'jquery'
require('dotenv').config();
class QuickDispatch extends Component {
    constructor(props) {
        super(props);
        this.indicationhtml = '';
    }
    state = { loadRequested: [], truckList: [], driverList: [], showMap: false, disablepro: true, data: [] };
    componentDidMount = () => {
        this.indicationfrLoadlist(true);
        mapboxgl.accessToken = getMapboxKey();
        const params = {
            ...STATUS.LOAD.REQUESTED,
        }
        this.props.dispatch(loadActionfrquickdispatch(params));
        // this.props.dispatch(getDriverListByCompany());
        // this.props.dispatch(truckNoAndLocation())
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.quickdispatchloads != this.props.quickdispatchloads) {
            console.log('this.props.quickdispatchloads', this.props.quickdispatchloads)
        }
        if (prevProps.quickDispatch != this.props.quickDispatch) {
            if (this.props.quickDispatch.status === 'ACCEPTED') {
                toastr.success('Success', this.props.quickDispatch.message);
                if (this.props.currentStatus.loadStatusId == 1) {
                    const params = {
                        ...STATUS.LOAD.REQUESTED
                    }
                    //this.props.dispatch(loadAction(params));
                    this.props.dispatch(loadActionfrquickdispatch(params));
                    this.indicationfrLoadlist(true);
                    this.setState({
                        ...this.state,
                        loadRequested: []
                    })
                    localStorage.setItem('cancelLoadIdscarrier', 0);
                }
            }
            else if (this.props.data.cancelledloadlist?.status === 'BAD_REQUEST') {
                toastr.error('Error', this.props.data.cancelledloadlist.validationErrors[0]);
                const params = {
                    ...(STATUS.LOAD.REQUESTED)
                }
                this.props.dispatch(loadAction(params));
            }
        }
        this.indicationfrLoadlist(true);
        $('.clearValueDisplay').click();
    }

    Requested_Load_Data() {
        let requestedLoad = [];
        if (this.props.quickdispatchloads) {
            this.props.quickdispatchloads.map((row, index) => {
                requestedLoad.push({
                    id: row.loadId,
                    loadNumber: row.loadNumber,
                    loadDate: moment(row.loadDate).format('MM-DD-YYYY'),
                    shipperName: row.shipperInfo?.name,
                    carrierName: row.carrierInfo?.name,
                    brokerName: row.companyInfo?.name,
                    assetNumber: row.vehicleInfo?.assetNumber,
                    distance: row.distance,
                    weight: row.weight,
                    orderNumber: row.orderNumber,
                    bolNumber: row.bolNumber,
                    instruction: row.instruction,
                    numberOfDropoff: row.numberOfDropoff,
                    numberOfPickup: row.numberOfPickup,
                    rate: row.rate,
                    status: row.status,
                    loadPoints: row.loadPoints,
                    trackingInfo: row.trackingInfo,
                    vehicleInfo: row.vehicleInfo,
                    tenderInfo: row.tenderInfo,
                    carrierInfo: row.carrierInfo,
                    selBtn: <div className='action-div'>
                        <select name='vehicleId' data-loadid={row.id} defaultValue={row.assetId} id={'assetvaluequickdispatch' + index} className='form-control2' >
                            <option value=''>Select Truck</option>
                            {
                                (row.assetId > 0) ? (<option value={row.assetId}>{row.assetName}</option>) : ('')
                            }

                            {
                                this.props.truckNoListAndLocation.length > 0 ? (this.props.truckNoListAndLocation.map(function (assets, index) {
                                    return (
                                        <option key={index} value={assets.Asset_Id}>{assets.Asset_Number}</option>
                                    )
                                })) : (<option></option>)
                            }
                        </select>&ensp;
                        <button className='btn btn-primary assignbtn action-div' name='carrierassignbtn' id={'truckval' + index} onClick={(e) => this.assignLoad(row, index)} >Assign</button>&ensp;
                        <img alt='' src={cancel} className='cancel-btn action-div' data-toggle='modal' id='cancelConfirmload' data-target='#cancelConfirmloadModal' onClick={(e) => this.selectLoad(row.loadId, row.loadNumber)} /></div>,
                });
            });
        }
        return requestedLoad;
    }
    cancelloadpopup(loadid, loadNo) {
        this.setState({ toBeCancelledLoadID: loadid, toBeCancelledLoadNo: loadNo })
    }
    assignLoad = (row, index) => {
        var selTruck = '';
        var selDriver = null;
        var a = document.querySelector('#assetvaluequickdispatch' + [index]);
        if (a.value == 0) {
            toastr.info('Info', 'Please choose a truck!');
            return;
        }
        else {
            selTruck = a.value;
        }
        var isDriverShow = localStorage.getItem('IsLoadDriverShow');

        if (isDriverShow == 1) {
            var selDriver = document.querySelector('#driveridvalcarrier' + [index]);
            if (selDriver.value == 0) {
                toastr.info('Info', 'Please choose a Driver!');
                return;
            }
            else {
                selDriver = selDriver.value;
            }
        }
        this.props.dispatch(quickDispatchAction({
            loadId: row.loadId,
            assetId: selTruck,
            driverId: selDriver,
        }));
    }
    selectLoad = (loadid, loadNo) => {
        this.props.cancelloadpopupData(loadid, loadNo);
    }
    indicationfrLoadlist = (flag) => {
        if (flag) {
            this.indicationhtml =
                <div className='spinner-border' role='status'>
                    <div className='row react-bs-table-no-data'></div>
                </div>
        }
        else {
            this.indicationhtml = 'No Data Found';
        }
    }
    render() {
        if (this.props.data.status == 'UNAUTHORIZED') {
            localStorage.clear();
            window.location = '/login'
            return <p></p>
        }
        var indicationhtml = '';
        if (this.props.loading) {
            indicationhtml =
                <div className='spinner-border' role='status'>
                    <div className='row react-bs-table-no-data'></div>
                </div>
        }
        const { SearchBar } = Search;
        const columns = [
            {
                dataField: 'loadSelInput',
                text: ''
            },
            {
                text: 'Load #',
                dataField: 'loadNumber',
                sort: true
            },
            {
                text: 'Date',
                dataField: 'loadDate',
                sort: true
            },
            {
                text: 'Customer',
                dataField: 'brokerName',
                sort: true
            },
            {
                text: 'Shipper',
                dataField: 'shipperName',
                sort: true
            },
            {
                text: 'Carrier Name',
                dataField: 'carrierName',
                sort: true,
            },
            {
                dataField: 'selBtn',
                text: 'Action',
                headerStyle: { width: '23%' }
            }
        ];
        const customTotal = (from, to, size) => (
            <span className='react-bootstrap-table-pagination-total'>
                Showing {from} to {to} of {size} Results
            </span>
        );
        const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            sizePerPage: 10,
            firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
        };
        var isDriverShow = localStorage.getItem('IsLoadDriverShow');
        //Driver Column remove for freightco carrier only
        if (isDriverShow == 0) {
            columns.splice(9, 1)
        }
        return (
            <ToolkitProvider
                keyField='id'
                data={this.Requested_Load_Data()}
                columns={columns}
                search
            >
                {
                    props => (
                        <div>
                            <div className='flex-left-auto'>
                                <ul className=''>
                                    <li className='searchicon'>
                                        <SearchBar {...props.searchProps} />
                                    </li>
                                </ul>
                            </div>
                            <BootstrapTable ref={n => this.node = n}
                                {...props.baseProps} pagination={paginationFactory(options)}
                                noDataIndication={this.props.indicationhtml} hover expandRow={this.props.expandRow}
                                bordered={false}
                            />
                        </div>
                    )
                }
            </ToolkitProvider>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.loadReducer.data,
        DriverList: state.loadReducer.DriverList,
        truckNoListAndLocation: state.loadReducer.truckNoListAndLocation,
        quickdispatchflagAction: state.loadReducer.quickdispatchflag,
        quickDispatch: state.loadReducer.QuickDispatch,
        loading: state.loadReducer.loading,
        canceledloadflag: state.loadReducer.canceledloadflag,
        quickdispatchloads: state.loadReducer.data.quickloadlists

    }
}
export default connect(mapStateToProps)(QuickDispatch)