import React, { Component, Fragment } from 'react';
import AddBroker from './AddBroker';
import BrokersList from './BrokersList';
import { toastr } from 'react-redux-toastr';
import { userlistAction } from '../../../actions/userActions';
import { connect } from 'react-redux';

class Brokers extends Component{
	
	componentDidMount() {
		document.title = 'Brokers';
	}

	componentDidUpdate()
    {
         if(this.props.data.status==="CREATED")
         {              
			 toastr.success('Success', 'Broker has been Successfully Created!');
			 var params={
				usertype:'broker',
			}
			this.props.dispatch(userlistAction(params));
            document.getElementById("brokerAdd").click();
         }
         else if(this.props.data.status==="ALREADY_REPORTED")
         {
             toastr.error('Error', 'Broker Already Exists!');            
         }
     }

	render(){		
		return(
			<Fragment>
				<BrokersList/>
				<AddBroker speciality={this.props.specialitytypelist} state={this.props.statelist}/>
			</Fragment>
		)
	}
}

const mapStateToProps = state => {
  return {
    data: state.userReducer.data,
    loading: state.userReducer.loading,
    error: state.userReducer.error,
  }
}


export default connect(mapStateToProps)(Brokers)