import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { STATUS } from '../../../helpers/status';
import TenderPopup from '../../widgets/TenderPopup';
import EditLoadPopup from './EditLoadPopup';
import LoadList from './LoadList';

import LoadListNotOnboarded from './LoadListNotOnboarded';
import {isOnboarded} from '../../../services/companyService';

class Loads extends Component{

	constructor(props){
		super(props);
		this.dropstate=false;
		this.requested = [];
		this.notyetdispatched = [];
		this.dispatchedenroute = [];
		this.delivered = [];
		this.bamloads = [];
		this.currentStatus = STATUS.LOAD.REQUESTED;
		this.contactLists=[];

		this.state ={
			isReady:false,
			isOnboarded:0
		}
	}

	componentDidMount() {
		document.title = 'Loads'
		isOnboarded().then((response) =>{
			console.log(response);
			if(response.Status){
				this.setState({
					...this.state,
					isReady:true,
					isOnboarded:response.Status
				});
			}
			else if(response.status){
				if(response.status=="UNAUTHORIZED"){
					localStorage.clear();
            		window.location = '/login';
				}
			} else{
				this.setState({
					...this.state,
					isReady:true
				});
			}
		});
	}

	render(){
		if(this.props.data.status == 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}
		if(this.props.data.loadList !== undefined)
		{			
			if(this.currentStatus === STATUS.LOAD.REQUESTED)
			{
				this.myload = this.props.data.loadList;
			}
			else if(this.currentStatus === STATUS.LOAD.NOTYETDISPATCHED)
			{
				this.notyetdispatched = this.props.data.loadList;
			}
			else if(this.currentStatus === STATUS.LOAD.DISPATCHEDENROUTE)
			{
				this.dispatchedenroute = this.props.data.loadList;
			}
			else if(this.currentStatus === STATUS.LOAD.DELIVERED)
			{
				this.delivered = this.props.data.loadList;
			}
			else if(this.currentStatus === STATUS.LOAD.	BAMLOADS)
			{
				this.bamloads = this.props.data.loadList;
			}
		}
		if(this.state.isReady){
			if(this.state.isOnboarded==1){
				return(													
					<LoadList loading={this.props.loading}/>
				)
			}
			else{
				return(<LoadListNotOnboarded />);
			}
		}
		else{
			return(
				<div className="text-center">
  					<div className="spinner-border" role="status">
    					<span className="sr-only">Loading...</span>
  					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		data: state.loadReducer.data,
	    loading: state.loadReducer.loading,
		error: state.loadReducer.error,
	}
}

export default connect(mapStateToProps)(Loads)