export const concat = (inputArray, sep) => {

    if(!Array.isArray(inputArray)) return "";

    var result = "";
    var join = "";
    sep = sep == null ? ", " : ( sep === "" ? ", " : sep);
    for (let str of inputArray)
    {
        result = result + join + (str == null ? "" : str.trim());
        if(result !== "")  join =sep
    }
    return result;
}


export const appBaseUrl = () => {
    //let baseUrl = process.env.REACT_APP_URL;
    // var baseUrl = window.location.origin;
    // "http://stackoverflow.com"

    var host = window.location.host;
    // stackoverflow.com
    host = host.replace(/\/$/, "");
    host = host.replace(/\:/g, "");
    host = host.replace(/[0-9]/g, "");
    host = host.replace("localhost", "");
    host = host.replace("truckergig.com", "");
    host = host.replace("truckercloud.com", "");
    host = host.replace("cobdev", "");
    host = host.replace(/\./g, "");
    if (host.length === 0) {
        host = "Carrier Onboard"
    }
    return host + " - ";
}

export const getBaseUrl = () => {
    let baseUrl = process.env.REACT_APP_URL;
    // var baseUrl = window.location.origin;
    // "http://stackoverflow.com"

    var host = window.location.host;
    // stackoverflow.com
    baseUrl = baseUrl.replace(/\/$/, "");
    return host ;
}
export const getUrlParams = (arg) => {
    let urlparam = arg.substring(1);
    urlparam = JSON.parse('{"' + decodeURI(urlparam).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
    return urlparam;
}

export const formatLocation = (location) => {
    try{
        let locationArray = location.split('|');
        locationArray = locationArray.reverse();
        return locationArray.filter(x => x.trim().length > 0).join(', ');
    } catch(err) {
        return location;
    }
}

export const checkUnauthorized = (status, msg) => {
    if(status === "UNAUTHORIZED")
    {
        localStorage.clear();
        localStorage.setItem("logmsg",msg);
		window.location = '/login'
    }
}

export const getMapboxKey = () =>{
    let mapboxKey = localStorage.getItem("mapboxKey");
    return (mapboxKey ? mapboxKey : "");
}

export const getMaxDate = (date) => {
    if(date){
        return date;
    } else {
        return new Date(2099, 11, 31);
    }
}

export const getMinDate = (date) => {
    if(date){
        return date;
    } else {
        return new Date(1900, 0, 1);
    }
}

export const getUrlSegments = (url) => {
    let urlSegs=url.split('/');
    if(Array.isArray(urlSegs)){
        let len1 = urlSegs.length;
        let lastSeg = urlSegs[len1-1];
        lastSeg = lastSeg.split('?');
        urlSegs[len1-1]=lastSeg[0];
        return urlSegs;
    } else{
        return [];
    }    
}

export const getUrlLastSegments = (url) => {
    let urlSegs=getUrlSegments(url);
    let len1 = urlSegs.length;
    if(len1 === 0){
        return "";
    } else {
        return urlSegs[len1-1];
    }
}

export const getDomainName = () =>{
    let domain = window.location.toString().split('/');
    return (domain && domain[2] ? domain[2] : '');
} 

export default {concat};