import { combineReducers } from 'redux';
import authReducer from './authReducer';
import dashboardReducer from './dashboardReducer';
import truckReducer from './truckReducer';
import loadReducer from './loadReducer';
import commonReducer from './commonReducer';
import userReducer from './userReducer';
import contactReducer from './contactReducer';
import carrierReducer from './carrierReducer';
import documentReducer from './documentReducer';
import assetReducer from './assetReducer';
import companyReducer from './companyReducer';
import reportReducer from './reportReducer';
import taskReducer from './taskReducer';
import {reducer as toastrReducer} from 'react-redux-toastr'

var formReducer = require('redux-form').reducer;

const rootReducer = combineReducers({
	authReducer,
	dashboardReducer,
	truckReducer,
	assetReducer,
	loadReducer,
	commonReducer,
	userReducer,
	contactReducer,
	carrierReducer,
	documentReducer,
	companyReducer,
	reportReducer,
	taskReducer,
	form: formReducer,
	toastr: toastrReducer
});

export default rootReducer
