const components = {
	dashboard: {
		component: 'DashboardNew',
		url: '/dashboard',
		title: 'Dashboard',
		icon: 'menu',
		module: 1
	},
	onboarding: {
		component: 'OnBoarding',
		url: '/onboarding',
		title: 'Onboarding',
		icon: 'menu',
		module: 2
	},
	Driveronboarding: {
		component: 'DriverOnBoarding',
		url: '/driveronboarding',
		title: 'Onboarding',
		icon: 'menu',
		module: 2
	},
	Reports: {
		component: 'Reports',
		url: '/reports',
		title: 'Reports',
		icon: 'menu',
		module: 3
	},
	carrierloads: {
		component: 'CarrierLoads',
		url: '/loads',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	// TruckPage: {
	// 	component: 'AdminuserAssetsPage',
	// 	url: '/trucks',
	// 	title: 'trucks',
	// 	icon: 'menu',
	// 	module: 1
	// },
	TrailersPage: {
		component: 'CarrierTrailers',
		url: '/trailers',
		title: 'Trailers',
		icon: 'menu',
		module: 1
	},
	DriversPage:{
		component: 'Drivers',
		url: '/drivers',
		title: 'CarrierDrivers',
		icon: 'menu',
		module: 1
	},
	ContactsPage: {
		component: 'CarrierContacts',
		url: '/contacts',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	TripRegReports: {
		component: 'TripRegReports',
		url: '/tripReg',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	TruckSummaryReport:{
		component: 'TruckSummaryReport',
		url: '/truckSummary',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	LoadSummaryReport:{
		component: 'LoadSummaryReport',
		url: '/loadSummary',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	DBFileSizeReport:{
		component: 'DBFileSizeReport',
		url: '/filesize',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	DBTabledataRecordsReport:{
		component: 'DBTabledataRecordsReport',
		url: '/dbrecords',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	HosReport:{
		component: 'HosReport',
		url: '/hosreport',
		title: 'Contacts',
		icon: 'menu',
		module: 1
    },
	// optInOptOut: {
	// 	component: 'optInOptOut',
    //     url: '/opt_in/out',
    //     title: 'OptInOptOut',
    //     icon: 'menu',
    //     module: 1
	// }, 
	Permissions:{
		component: 'Permissions',
		url: '/ermissions',
		title: 'Permissions',
		icon: 'menu',
		module: 1
    },
}

const DemoUserRoutes = {
	adminuser: {
		routes: [
			components.dashboard,
			components.onboarding,
			components.Driveronboarding,
			components.Reports,
			components.carrierloads,
			//components.TruckPage,
			components.TrailersPage,
			components.DriversPage,
			components.ContactsPage,
			//components.optInOptOut,
			components.TripRegReports,
			components.TruckSummaryReport,
			components.LoadSummaryReport,
			components.DBFileSizeReport,
			components.DBTabledataRecordsReport,
			components.HosReport,
			components.Permissions
        ]
    }
};

export { DemoUserRoutes };