import React, { Component, Fragment } from 'react';
import { toastr } from 'react-redux-toastr';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalTitle from 'react-bootstrap/ModalTitle';
import {formData} from '../../helpers/formData';
import $ from 'jquery';
import 'jquery-validation';
import {addNewUser} from '../../services/userService';
import {getInviteTemplatetypeService} from '../../services/companyService';
import {NewUserComponent} from 'UI-Component';

class NewUser extends Component{
    constructor(props) {
        super(props);
            this.state={
                cityData:[],
                zipData:[],
                firstName:'',
                lastName:'',                
                State:'',
                City:'',
                Zip:'',
                roleId:'',
                emailId:'',
                phoneNumber:'',                
                currentAddress1:'',
                userTypeId:localStorage.getItem('UserTypeId'),
                companyId:localStorage.getItem('companyId'),
                userId:null,
                templateId:'',
                templates:[]
        }
    }
    componentDidMount = () =>{
        const params={
			payload:{
                companyId:localStorage.getItem('companyId'),
                typename:'newuser'
			}
		 }
		getInviteTemplatetypeService(params).then(response =>{
			if(response && response.data){
				this.setState({
					...this.state,
					templates:response.data
				});
			}
		});
    }
    componentDidUpdate = (prevProps) =>{
        if(this.props.userData !== prevProps.userData)
        {
            if(this.props.userData){
                this.setState({
                    ...this.state,
                    firstName:this.props.userData.firstName,
                    lastName:this.props.userData.lastName,
                    State:this.props.userData.State,
                    City:this.props.userData.City,
                    Zip:this.props.userData.Zip,
                    roleId:this.props.userData.roleId,
                    emailId:this.props.userData.emailId,
                    phoneNumber:this.props.userData.phoneNumber,
                    currentAddress1:this.props.userData.currentAddress1,
                    userTypeId:2,
                    companyId:this.props.userData.companyId,
                    userId:this.props.userData.userId,
                    templateId:''
                });
            } else {
                this.setState({
                    ...this.state,
                    firstName:'',
                    lastName:'',
                    State:'',
                    City:'',
                    Zip:'',
                    roleId:'',
                    emailId:'',
                    phoneNumber:'',
                    currentAddress1:'',
                    userTypeId:localStorage.getItem('UserTypeId'),
                    companyId:localStorage.getItem('companyId'),
                    userId:null,
                    templateId:''
                });
            }
            setTimeout(() => {
                this.set_roleDesc();
              }, 1000);
        }
    }
    getTemplates = () =>{		
        return this.state.templates.map((data, index) =>{
            return <option key={index} value={data.carrierEmailTemplateId}>{data.emailTemplateName}</option>;
        });
    }
    set_templateId = (e) => {
        this.setState({
            ...this.state,
            templateId:e.target.value
        });
    }
    set_firstName = (e) =>{
        this.setState({
            ...this.state,
            firstName:e.target.value
        });
    }
    set_lastName = (e) =>{
        this.setState({
            ...this.state,
            lastName:e.target.value
        });
    }
    set_State = (e) =>{        
        this.setState({
            ...this.state,
            State:e.target.value
        });
    }
    set_City = (e) =>{
        this.setState({
            ...this.state,
            City:e.target.value
        });
    }
    set_Zip = (e) =>{
        this.setState({
            ...this.state,
            Zip:e.target.value
        });
    }
    set_emailId = (e) =>{
        this.setState({
            ...this.state,
            emailId:e.target.value
        });
    }
    set_phoneNumber = (e) =>{
        this.setState({
            ...this.state,
            phoneNumber:e.target.value
        });
    }
    set_currentAddress1 = (e) =>{
        this.setState({
            ...this.state,
            currentAddress1:e.target.value
        });
    }
    set_roleDesc = () => {
        let ctrl = $('#new_user select[name=roleId]')[0];        
        if(ctrl){
            let roleDesc = ctrl.options[ctrl.selectedIndex].dataset.roledesc;
            $('#roleDesc').html(roleDesc);
        }
    }
    set_roleId = (e) =>{
        this.set_roleDesc();
        this.setState({
            ...this.state,
            roleId:e.target.value
        });
    }
    set_City = (e) =>{
        this.setState({
            ...this.state,
            City:e.target.value
        });
    }
    set_Zip = (e) =>{
        this.setState({
            ...this.state,
            Zip:e.target.value
        });
    }
    renderState = () =>{
        let statelist=[];
        if(this.props.statelist && this.props.statelist.data && this.props.statelist.data.length>0){
            statelist=this.props.statelist.data.map((data,index)=>{                
                return <option key={index} data-id={data.id} value={data.name}>{data.name}</option>
            });
        }
        return <select className='form-control json-col' name='State' onChange={(e) =>{this.set_State(e);}} value={this.state.State}>
            <option value=''>Select state</option>
            {statelist}
        </select>
    }
    renderRoles = () =>{
        let roles=[];
        if(this.props.userRoles){
            roles=this.props.userRoles.map((data,index)=>{
                return <option key={index} data-roledesc={data.roleDesc} value={data.roleId}>{data.roleName}</option>
            });
        }
        return <select className='form-control requiredfield json-col' name='roleId' onChange={(e) =>{this.set_roleId(e)}} value={this.state.roleId} >
            <option data-roledesc='' value=''>Select role</option>
            {roles}
        </select>
    }
    closeThis = (flag) =>{
        $('#new_user').trigger('reset');
        this.props.closeNewUser(flag);
    }
    saveNewUser = () =>{
        if(this.props.userPopupMode==='add'){
            $('#new_user').validate({
                rules:{
                    firstName:{
                    required:true
                    },
                    emailId:{
                        required:true,
                        email:true
                    },
                    templateId:{
                        required:true
                    }
                },
                messages:{
                    firstName:{
                        required:'First name can not be left blank.!'
                    },
                    emailId:{
                        required:'Email can not be left blank.!',
                        email:'Email not valid'
                    },
                    templateId:{
                        required:'Select email template'
                    }
                },errorPlacement: function(error, element) {
                    if (element.attr('name') === 'fromDate' || element.attr('name') === 'toDate') {
                        error.insertAfter($(element).parents('div.date-picker').find($('.error_place')));
                    }
                    else {
                        error.insertAfter(element);
                    }
                }
            });
        } else {
            $('#new_user').validate({
                rules:{
                    firstName:{
                    required:true
                    },
                    emailId:{
                        required:true,
                        email:true
                    },
                },
                messages:{
                    firstName:{
                        required:'First name can not be left blank.!'
                    },
                    emailId:{
                        required:'Email can not be left blank.!',
                        email:'Email not valid'
                    },
                },errorPlacement: function(error, element) {
                    if (element.attr('name') === 'fromDate' || element.attr('name') === 'toDate') {
                        error.insertAfter($(element).parents('div.date-picker').find($('.error_place')));
                    }
                    else {
                        error.insertAfter(element);
                    }
                }
            });
        }

        if($('#new_user').valid())
        {
            let form = document.getElementById('new_user');
            let loadData = formData(form);
            let roleId=0;
            if(this.props.userRoles && this.props.userRoles.length>0){
                roleId=this.props.userRoles[0].roleId;
            }
            loadData.roleId=roleId;
            if(loadData.userId === ''){
                loadData.userId=null;
            }
            let param={
                payload:loadData
            }
            addNewUser(param).then((response) =>{
                if(response.status){
                    if(response.status === 'CREATED'){
                        toastr.success('Success', response.message);
                        this.closeThis(1);
                    } else {
                        toastr.error('Oops!', response.message);
                    }
                }
            });
        }
    }    
    getEmailTempaleField = () =>{
        if(this.props.userPopupMode === 'add'){
            return(                
                <div className='col form-group'>
                    <label>Email Template</label>
                    <select className='form-control requiredfield json-col' name='templateId' onChange={(e) =>{this.set_templateId(e)}} value={this.state.templateId}>
                        <option value=''>Select email template</option>
                        {this.getTemplates()}
                    </select>
                </div>                            
            );
        } else{
            return <Fragment/>;
        }
    }
    render = () =>{
        return(
            <Modal size='lg' aria-labelledby='contained-modal-title-vcenter' centered show={this.props.newUserPopup} id='newuser'>
                <ModalHeader>
                <ModalTitle>{this.props.title}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                <NewUserComponent state={this.state}
                 renderRoles={this.renderRoles()} 
                 renderState={this.renderState()} 
                 getEmailTempaleField={this.getEmailTempaleField()} 
                 set_firstName={(e) =>{this.set_firstName(e)}}
                 set_lastName={(e) =>{this.set_lastName(e)}}
                 set_currentAddress1={(e) =>{this.set_currentAddress1(e)}}
                 set_emailId={(e) =>{this.set_emailId(e)}}
                 set_phoneNumber={(e) =>{this.set_phoneNumber(e)}}
                 set_City={(e) =>{this.set_City(e)}}
                 set_Zip={(e) =>{this.set_Zip(e)}}
                 />
                    </ModalBody>
                <ModalFooter>
                    <button type='button' className='btn btn-primary'  onClick={() => {this.saveNewUser()}}>Save</button>
                    <button type='button' className='btn btn-primary' onClick={() => {this.closeThis(0)}}>Close</button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default NewUser;