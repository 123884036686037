import * as types from '../actions';

const initialState = {
   statelist: [],
   country: [],
   assets: [],
   loadpoint:[],
   loadstatus:[],
   eldproviderlist:[],
   trucktypelist:[],
   fueltypelist:[],
   drivertypelist:[],
   specialitytypelist:[],
   carriernamelist:[],
   accessorialslist:[],
   citylist:[],
   ziplist:[],
   subscriptionplanlist:[],
   documenttypelist:[],
   loadlist:[],
   //geofenceradiuslist: [],
   //geofencemailtimelist: [],
   error: '',
   loading:'',   
   mapboxData:{},
   timeZones:{}
};

export default function(state = initialState, action) { 
   switch(action.type) {      
       case types.COMMON_LIST:
           return {
               ...state,
               loading:true,
               error:''
           };
       case types.COMMON_LIST_SUCCESS:           
           return {
               ...state,
               loading:false,
               error:'',
               statelist: action.statelist,
               country: action.country,
               assets: action.assets,
               loadstatus: action.loadstatus,
               loadpoint: action.loadpoint,
               eldproviderlist:action.eldproviderlist,
               trucktypelist:action.trucktypelist,
               fueltypelist:action.fueltypelist,
               drivertypelist:action.drivertypelist,
               specialitytypelist:action.specialitytypelist,
               carriernamelist:action.carriernamelist,
               accessorialslist:action.accessorialslist,
               citylist:action.citylist,
               ziplist:action.ziplist,
               subscriptionplanlist:action.subscriptionplanlist,
               documenttypelist:action.documenttypelist,
               loadlist:action.loadlist,
               mapboxData:action.mapboxDetails,
               timeZones:action.timeZones
           };
       case types.COMMON_LIST_ERROR:
           return {
               ...state,
               loading:false,
               error: action.error
           };          
       default:
           return state;
   }
};