import * as types from '../actions';

const initialState = {
    data: [],
    loading: false,
    error: ''
};

export default function(state = initialState, action) {  
    switch(action.type) {
        case types.TASK_LIST:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.TASK_LIST_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.TASK_LIST_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.TASK_ADD:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.TASK_ADD_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.TASK_ADD_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        case types.TASK_CHANGE_STATUS:
            return {
                ...state,
                loading: true,
                error:''
            };
        case types.TASK_CHANGE_STATUS_SUCCESS:
            return { 
                ...state,
                data: action.data,
                loading: false,
            };
        case types.TASK_CHANGE_STATUS_ERROR:
            return { 
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};