export const validate = values => 
{
    const errors = {}
    if (!values.firstName) {
        errors.firstName = 'First name can not be left blank.!'
    } 

    if (!values.phoneNum) {
        errors.phoneNum = 'Phone No. can not be left blank.!'
    }
    else if (!/^\d{10}$/i.test(values.phoneNum)) { 
        errors.phoneNum = 'Make sure that you entered valid Phone number.!'
    }

    if (!values.emailId) {
        errors.emailId = 'Email can not be left blank.!'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailId)) {
        errors.emailId = 'Make sure that you entered valid email address.!'
    }

  return errors
}