import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  reduxForm } from 'redux-form';
import { validate } from './loadReportsValidation';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { DBFileSizeAction } from '../../../actions/reportAction';

class LoadSummaryReport extends Component {
   
    componentDidMount() {		
        document.title = 'Load-Reports';
        this.props.dispatch(DBFileSizeAction());
    }
    
    render() {
        console.log(this.props.data)
        var indicationhtml = "No Data Found"
        if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}        
        // const { handleSubmit } = this.props;	
        var LoadReports=[];
		
            if(this.props.loading){
               indicationhtml = 
                <div className="spinner-border" role="status">
                    <div className="row react-bs-table-no-data"></div>
                    </div>
            }else
            {
            if(this.props.data.DbFileSize !== undefined)
            {
                if(this.props.data.DbFileSize.length > 0)
                {
                    LoadReports = this.props.data.DbFileSize.map(
                        (row,index) =>{   
                            return({
                                id:index,
                                name:row.Table,
                                size:row.Size,
                            });
                        }
                    );
                }
                else{
                    LoadReports = [];
                    indicationhtml="No Data Found";
                }
            }
           } 
        const { SearchBar } = Search;    
		const columns=[	
            {
                text:'Table Name',
                dataField:'name',
                sort:true
            },
            {
                text:'Size',
                dataField:'size',
                sort:true
            },
           ]
       
        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Showing { from } to { to } of { size } Results
            </span>
          );
          
          const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
          };
        return (
             <article className="table-data truck-data shadow bg-white rounded" style={{display:'block'}}>
                <h3>Data Base Table File Size Summary</h3> <br></br>          
                <ToolkitProvider
                        keyField="id"
                        data={ LoadReports }
                        columns={ columns }
                        search
                        >
                        {
                        props => (
                            <div>
                             <SearchBar { ...props.searchProps } /> 
                            <BootstrapTable
                                { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={indicationhtml} hover 
                                bordered={ false }
                            />
                            </div>
                        )
                        }
                    </ToolkitProvider>
            </article>
        )
    }
}

const mapStateToProps = state => {
    return {
        data : state.reportReducer.data,
        loading : state.reportReducer.loading,
        loadReportData : state.reportReducer.data,
    }
  }
  
  export default connect(mapStateToProps)(reduxForm({
      form:'loadReports',validate
  })(LoadSummaryReport))