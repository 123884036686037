import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getcitybystateService, getzipbycityService} from '../../../services/loadService';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { shipperAdd } from '../../../actions/userActions';
import { formData } from '../../../helpers/formData';
import $ from 'jquery';
import 'jquery-validation';

class ShipperAdd extends Component{
   constructor(props){
      super(props);
      this.state={
          stateData:[],
          shipperCity:[],
          shipperZip:[],
          pickupdrops:[{
              fromstateData:[],
              fromcityData:[],
              fromzipData:[],
              tostateData:[],
              tocityData:[],
              tozipData:[],
              rowData:{
                  fromcity: "",
                  fromstate: "",
                  fromzip: "",
                  tocity: "",
                  tostate: "",
                  tozip: "",
                  fromstreet: "",
                  tostreet:"",
                  miles:"",
                  baseprice:"",
                  remarks:"",
              }
          }]
      }
  }

   componentDidUpdate = (prevProps, prevState) =>{  
      if(prevProps.statelist !== this.props.statelist){            
         let stateData = this.props.statelist.data.map((row) => row);
         let pickupdrops=this.state.pickupdrops;
         pickupdrops[0].fromstate=[...stateData];
         pickupdrops[0].tostate=[...stateData];
         this.setState({
            ...this.state,
            stateData:[...stateData],
            pickupdrops:pickupdrops
         });
      }
   }

   getStates = (stateData) => {      
     return stateData.map( (row,index) => {                
          return(
              <option key={index} value={row.id}>{row.name}</option>
          );
      } );   
   }

   
   ShipperCity = (e) => {
      let stateId = $(e.target).val();
      let param = {
          payload:stateId
      }
      getcitybystateService(param).then((response) => {
          if(response){                
              this.setState({
                  ...this.state,
                  shipperCity:[...response]
              });
          }
      });
  }

  getCity = (cityData) => {
   let row = cityData.map( (row,index) => {                
         return(
            <option key={index} value={row.cityId}>{row.cityName}</option>
         );
      } );
      return row;
   }

   getCityLane = (cityData) => {
      let row = cityData.map( (row,index) => {                
            return(
               <option key={index} data-value={row.cityId} value={row.cityName}>{row.cityName}</option>
            );
         } );
         return row;
      }

     getZip = (zipData) => {
         let row = zipData.map( (row,index) => {                
            return(
               <option key={index} value={row.zipId}>{row.zipValue}</option>
            );
         } );
         return row;
     }

    getZipLane = (zipData) => {
         let row = zipData.map( (row,index) => {                
            return(
               <option key={index} data-value={row.zipId} value={row.zipValue}>{row.zipValue}</option>
            );
         } );
         return row;
      }

   loadShipperZip = (e) => {
      let cityId = $(e.target).val();
      let param = {
         payload:cityId
      }
      getzipbycityService(param).then((response) => {            
         if(response){                
            this.setState({
                  ...this.state,
                  shipperZip:response
            });
         }
      });
   }

   submitForm = (e) => {     
      // var state = $( '#currentState option:selected').text();  alert(state) 
      // var city = $( '#currentCity option:selected').text();  alert(city) 
      // var zipcode = $( '#currentZip option:selected').text();  alert(zipcode) 
      $("#ShipperAddfrm").validate({
          rules:{
              companyName:{
                 required:true
              },
              firstName:{
                  required:true
              },
              phone:{
                  required:true
              },
              email:{
                  required:true,
                  email: true
              },
              specaility:{
                  required:true
              },
              State:{
                  required:true
              }
          },
          messages:{
              companyName:{
                   required:'Company Name can not be left blank.!'
              },
              firstName:{
                  required:'First Name can not be left blank.!'
              },
              phone:{
                  required:'Phone can not be left blank.!'
              },
              email:{
                  required:'Email can not be left blank.!'
              },
              specaility:{
                  required:'Speciality can not be left blank.!'
              },
              State:{
                  required:'State can not be left blank.!'
              }
          }
      });

      $('#ShipperAddfrm .sourceCityId').each(function() {
         $(this).rules('add', {
             required: true,
             messages: {
                 required:  "Required"
             }
         });
     })

     $('#ShipperAddfrm .sourceStateId').each(function() {
      $(this).rules('add', {
          required: true,
          messages: {
              required:  "Required"
          }
      });
    })

    $('#ShipperAddfrm .destinationStateId').each(function() {
      $(this).rules('add', {
          required: true,
          messages: {
              required:  "Required"
               }
            });
      })

      $('#ShipperAddfrm .destinationCityId').each(function() {
         $(this).rules('add', {
            required: true,
            messages: {
               required:  "Required"
            }
         });
      })

      let form = document.getElementById("ShipperAddfrm");
      let shipperData = formData(form);
     
      if( $("#ShipperAddfrm").valid())
      {
         const level1 = {};
         level1["laneList"]=[];

         var state = $( '#currentState option:selected').text();  
         var city = $( '#currentCity option:selected').text(); 
         var zipcode = $( '#currentZip option:selected').text();  

         level1.firstName= (shipperData.firstName) ? (shipperData.firstName) : ("");
         level1.LastName=(shipperData.lastName) ? (shipperData.lastName) : ("");
         level1.companyName=(shipperData.companyName) ? (shipperData.companyName) : ("");
         level1.phoneNumber=(shipperData.phone) ? (shipperData.phone) : ("");
         level1.companyEmail=(shipperData.email) ? (shipperData.email) : ("");
         level1.specialityId=(shipperData.specaility) ? (shipperData.specaility) : ("");
         level1.currentAddress1=(shipperData.address) ? (shipperData.address) : ("");
         level1.websiteAddress=(shipperData.website) ? (shipperData.website) : ("");
         level1.state=(state!=="Select State") ? (state) : ("");
         level1.city=(city!=="Select City") ? (city) : ("");
         level1.zipcode=(zipcode!=="Select Zip") ? (zipcode) : ("");       
         var data = shipperData.laneList;
         if(Array.isArray(shipperData.laneList))
         {
            data = shipperData.laneList
         }
         else
         {
            data = [shipperData.laneList];
         }
         var result = data.map(singleData=>{  
            return{
                  "sourceAddress":singleData.sourceAddress,
                  "sourceCity":singleData.sourceCityId,
                  "sourceState":singleData.sourceStateId,
                  "sourceZip":singleData.sourceZipId,
                  "destinationAddress":singleData.destinationAddress,
                  "destinationCity":singleData.destinationCityId,
                  "destinationState":singleData.destinationStateId,
                  "destinationZip":singleData.destinationZipId,
                  "miles":singleData.miles,
                  "basePrice":singleData.baseprice,
                  "remarks":singleData.remarks,
            }
         }); 
         level1["laneList"]=result;
         this.props.dispatch(shipperAdd(level1));
      }
  }

   addRow = () => {
      let stateData = this.props.statelist.data.map((row) => row);
      let pickupdrops = this.state.pickupdrops;
      pickupdrops.push({
         fromstateData:[...stateData],
         fromcityData:[],
         fromzipData:[],
         tostateData:[...stateData],
         tocityData:[],
         tozipData:[],
         rowData:{
            fromcity: "",
            fromstate: "",
            fromzip: "",
            tocity: "",
            tostate: "",
            tozip: "",
            fromstreet: "",
            tostreet:"",
            miles:"",
            baseprice:"",
            remarks:"",
         }
      });
      this.setState({
         ...this.state,
         pickupdrops:pickupdrops
      });
   }

   deleteRow = (e, index) => {        
      let pickupdrops = this.state.pickupdrops
      pickupdrops.splice(index,1);        
      this.setState({
         ...this.state,
         pickupdrops:pickupdrops
      });
   }

   sourceAddressChange = (e, index) => {
      let pickupdrops = this.state.pickupdrops;
      pickupdrops[index].rowData.fromstreet=$(e.target).val();
      this.setState({
          ...this.state,
          pickupdrops:pickupdrops
      });
  }

  destinationAddressChange = (e, index) => {
   let pickupdrops = this.state.pickupdrops;
      pickupdrops[index].rowData.tostreet=$(e.target).val();
      this.setState({
         ...this.state,
         pickupdrops:pickupdrops
      });
   }

   milesChange = (e, index) => {
      let pickupdrops = this.state.pickupdrops;
         pickupdrops[index].rowData.miles=$(e.target).val();
         this.setState({
            ...this.state,
            pickupdrops:pickupdrops
         });
      }

   basepriceChange = (e, index) => {
      let pickupdrops = this.state.pickupdrops;
         pickupdrops[index].rowData.baseprice=$(e.target).val();
         this.setState({
            ...this.state,
            pickupdrops:pickupdrops
         });
      }

   remarksChange = (e, index) => {
      let pickupdrops = this.state.pickupdrops;
         pickupdrops[index].rowData.remarks=$(e.target).val();
         this.setState({
            ...this.state,
            pickupdrops:pickupdrops
         });
      }

   fromCityChange = (e,index) => {
      let pickupdrops = this.state.pickupdrops;
      pickupdrops[index].rowData.fromcity=$(e.target).val();
      this.setState({
         ...this.state,
         pickupdrops:pickupdrops
      });
   }

   tocityChange = (e,index) => {
      let pickupdrops = this.state.pickupdrops;
      pickupdrops[index].rowData.tocity=$(e.target).val();
      this.setState({
         ...this.state,
         pickupdrops:pickupdrops
      });
   }

   fromzipChange = (e,index) => {
      let pickupdrops = this.state.pickupdrops;
      pickupdrops[index].rowData.fromzip=$(e.target).val();
      this.setState({
         ...this.state,
         pickupdrops:pickupdrops
      });
   }

   tozipChange = (e,index) => {
      let pickupdrops = this.state.pickupdrops;
      pickupdrops[index].rowData.tozip=$(e.target).val();
      this.setState({
         ...this.state,
         pickupdrops:pickupdrops
      });
   }

   
   loadSourceCity = (e,index) =>{
      var concat = '#sourceStateId'+[index];
      let stateIdvalue = $( concat+' option:selected').attr("data-value");  //alert(stateIdvalue)
      let stateId = $(e.target).val();
      let param = {
          payload:stateIdvalue
      }
      getcitybystateService(param).then((response) => {
          if(response){
              let pickupdrops = this.state.pickupdrops;
              pickupdrops[index].fromcityData=response;
              pickupdrops[index].rowData.fromstate=stateId;
              this.setState({
                  ...this.state,
                  pickupdrops:pickupdrops
              });
          }
      });
  }

  loadDestinationCity = (e,index) =>{
   var concat = '#destinationStateId'+[index];
   let stateIdvalue = $( concat+' option:selected').attr("data-value"); 
   let stateId = $(e.target).val();
   let param = {
       payload:stateIdvalue
   }
   getcitybystateService(param).then((response) => {
       if(response){
           let pickupdrops = this.state.pickupdrops;
           pickupdrops[index].tocityData=response;
           pickupdrops[index].rowData.tostate=stateId;
           this.setState({
               ...this.state,
               pickupdrops:pickupdrops
           });
       }
   });
}

loadSourceZip = (e,index) =>{
      var concat = '#sourceCityId'+[index];
      let cityIdvalue = $( concat+' option:selected').attr("data-value");  
      let cityId = $(e.target).val();
      let param = {
         payload:cityIdvalue
      }
      getzipbycityService(param).then((response) => {
         if(response){
            let pickupdrops = this.state.pickupdrops;
            pickupdrops[index].fromzipData=response;
            pickupdrops[index].rowData.fromzip=cityId;
            this.setState({
                  ...this.state,
                  pickupdrops:pickupdrops
            });
         }
      });
   }


   loadDestinationZip = (e,index) =>{
      var concat = '#destinationCityId'+[index];
      let cityIdvalue = $( concat+' option:selected').attr("data-value");  //alert(stateIdvalue)
      let cityId = $(e.target).val();
      let param = {
         payload:cityIdvalue
      }
      getzipbycityService(param).then((response) => {
         if(response){
            let pickupdrops = this.state.pickupdrops;
            pickupdrops[index].tozipData=response;
            pickupdrops[index].rowData.tozip=cityId;
            this.setState({
                  ...this.state,
                  pickupdrops:pickupdrops
            });
         }
      });
   }

   getRows = () => {
      let row = this.state.pickupdrops.map((row,index) => {
         return(
            <tr key={index} className="json-row" jsoncol="laneList">
                  <td>
                     <input value={row.rowData.fromstreet} name={"sourceAddress["+index+"]"} type="text" className="form-control json-col sourceAddress" placeholder="Source Address" onChange={(e) => {this.sourceAddressChange(e,index)}} />
                  </td>  
                  <td>
                     <select value={row.rowData.fromstate} className="form-control json-col requiredfield sourceStateId" id={"sourceStateId"+index} name={"sourceStateId["+index+"]"} onChange={(e) => {this.loadSourceCity(e,index)}}>
                        <option value=''>Select State</option>
                        {
                              this.props.statelist.data?(this.props.statelist.data.map(function(row, index) {  
                                          return(
                                             <option key={index} data-value={row.id} value={row.name}>{row.name}</option>
                                       )             
                              })):(<option></option>)
                        }
                     </select>
                  </td>
                  <td>
                     <select  className="form-control json-col requiredfield sourceCityId" id={"sourceCityId"+index} name={"sourceCityId["+index+"]"} onChange={(e) => {this.loadSourceZip(e,index)}}>
                        <option value=''>Select</option>
                        {this.getCityLane(row.fromcityData)}
                     </select>
                  </td>
                 <td>
                     <select value={row.rowData.fromzip} id={"sourceZipId"+index} className="form-control json-col requiredfield sourceZipId" name={"sourceZipId["+index+"]"} onChange={(e) => {this.fromzipChange(e,index)}}>
                        <option value=''>Select</option>
                        {this.getZipLane(row.fromzipData)}
                     </select>
                  </td>  
                  <td>
                     <input value={row.rowData.tostreet} name={"destinationAddress["+index+"]"} type="text" className="form-control json-col destinationAddress" placeholder="Destination Address" onChange={(e) => {this.destinationAddressChange(e,index)}} />                     
                  </td>  
                  <td>
                     <select value={row.rowData.tostate} id={"destinationStateId"+index} className="form-control json-col requiredfield destinationStateId" name={"destinationStateId["+index+"]"} onChange={(e) => {this.loadDestinationCity(e,index)}}>
                     <option value=''>Select State</option>
                        {
                              this.props.statelist.data?(this.props.statelist.data.map(function(row, index) {  
                                          return(
                                             <option key={index} data-value={row.id} value={row.name}>{row.name}</option>
                                       )             
                              })):(<option></option>)
                        }
                     </select>
                  </td>
                  <td>
                     <select id={"destinationCityId"+index} className="form-control json-col requiredfield destinationCityId" name={"destinationCityId["+index+"]"} onChange={(e) => {this.loadDestinationZip(e,index)}}>
                        <option value=''>Select</option>
                        {this.getCityLane(row.tocityData)}
                     </select>
                  </td>
                 <td>
                     <select value={row.rowData.tozip} id={"destinationZipId"+index} className="form-control json-col requiredfield destinationZipId" name={"destinationZipId["+index+"]"} onChange={(e) => {this.tozipChange(e,index)}}>
                        <option value=''>Select</option>
                        {this.getZipLane(row.tozipData)}     
                     </select>
                  </td>
                  <td>
                     <input value={row.rowData.miles} name={"miles["+index+"]"} type="text" className="form-control json-col miles" placeholder="Miles" onChange={(e) => {this.milesChange(e,index)}} />
                  </td>  
                  <td>
                     <input value={row.rowData.baseprice} name={"baseprice["+index+"]"} type="text" className="form-control json-col baseprice" placeholder="Base Price" onChange={(e) => {this.basepriceChange(e,index)}} />
                  </td> 
                  <td>
                     <input value={row.rowData.remarks} name={"remarks["+index+"]"} type="text" className="form-control json-col remarks" placeholder="Remarks" onChange={(e) => {this.remarksChange(e,index)}} />
                  </td> 
                  <td>
                     {this.getAction(index)}             
                  </td> 
            </tr>
         );
      });
      return(row);
   }

   getAction = (index) => {
      if(index === (this.state.pickupdrops.length-1)){
         return(<a href="/#" onClick={(e) => {this.addRow()}}>
            <i class="icofont-plus-circle add_plus pl-3" />
         </a>);
      } else {
         return(<a href="/#" onClick={(e) => {this.deleteRow(e, index)}}>
            <i class="icofont-ui-delete add_plus pl-3" />
         </a>);
      }
   }

   handleClick = () =>{
      $("label.error").hide();
      $(".error").removeClass("error");
      document.getElementById("ShipperAddfrm").reset();
      
      var popupVal = this.state.pickupdrops; 
      var popupVal1 = this.state.pickupdrops; 
      popupVal1[0].rowData.fromcity=""
      popupVal1[0].rowData.fromstate=""
      popupVal1[0].rowData.fromzip=null
      popupVal1[0].rowData.tocity=""
      popupVal1[0].rowData.tostate=null
      popupVal1[0].rowData.tozip=""
      popupVal1[0].rowData.fromstreet=""
      popupVal1[0].rowData.tostreet=""
      popupVal1[0].rowData.miles=""
      popupVal1[0].rowData.baseprice=""
      popupVal1[0].rowData.remarks=""
      popupVal.splice(1,this.state.pickupdrops.length);   
      this.setState({
          ...this.state,
          pickupdrops:popupVal1
      });
      this.setState({
          ...this.state,
          pickupdrops:popupVal
      });
  }


   render(){
      // const { handleSubmit, pristine } = this.props
      return(
        <div className="modal fade addshipperloadmodal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered shipperAddmodel">
           <div className="modal-content">
              <div className="modal-header">
                 <h5 className="modal-title doc-color" id="exampleModalLabel">Add Shipper</h5>
                 <button type="button" class="close" onClick={this.handleClick} id="addmodalpopup"  data-dismiss="modal" aria-label="Close">
                 <span aria-hidden="true">&times;</span>
                 </button>
              </div>
              <div className="modal-body table-data">
                 <div className="tab-content" id="addloadshippers">
                    <div className="tab-pane fade show active" id="addloadshipper" role="tabpanel" aria-labelledby="addloadshipper-tab">
                       <form id="ShipperAddfrm" >
                          <h5 className="highlight doc-color">Shipper Information</h5>
                             <div className='row'>
                                 <div className="col form-group">
                                     <label>Company Name</label>
                                     <input name="companyName" type="text"  className="form-control json-col requiredfield" placeholder="Company Name" />
                                 </div>
                                 <div className="col form-group">
                                    <label>First Name</label>
                                    <input name="firstName" type="text" className="form-control json-col requiredfield" placeholder="First Name" />
                                 </div>
                                 <div className="col form-group">
                                     <label>Last Name</label>
                                    <input name="lastName" type="text" className="form-control json-col" placeholder="Last Name" />
                                 </div>
                              </div>

                              <div className='row'>
                                 <div className="col form-group">
                                     <label>Phone</label>
                                     <input name="phone" type="text" className="form-control json-col requiredfield" placeholder="Phone" />
                                 </div>
                                 <div className="col form-group">
                                    <label>Email</label>
                                    <input name="email" type="text" className="form-control json-col requiredfield" placeholder="Email" />
                                 </div>
                                 <div className="col form-group">
                                   <label>Speciality</label>
                                   <select className="form-control json-col requiredfield" name="specaility">                                 
                                    <option value="">Select</option>
                                    {
                                       this.props.specialitytypelist.data?(this.props.specialitytypelist.data.map(function(row, index) {  
                                          return(
                                              <option key={index} data-lanedetailsindex={index} value={row.id}>{row.name}</option>
                                      )             
                                       })):(<option></option>)
                                    }
                                    </select>
                                 </div>
                              </div>

                              <div className='row'>
                                 <div className="col form-group">
                                     <label>Address</label>
                                     <input name="address" type="text" className="form-control json-col" placeholder="Address" />
                                 </div>
                                 <div className="col form-group">
                                    <label>Website</label>
                                    <input name="website" type="text" className="form-control json-col" placeholder="Website" />
                                 </div>
                                 <div className="col form-group">
                                   <label>State</label>
                                   <select className="form-control json-col requiredfield" id="currentState" name="State" onChange={(e) =>
                                    {this.ShipperCity(e)}}>                                 
                                    <option value="">Select State</option>
                                    {
                                       this.props.statelist.data?(this.props.statelist.data.map(function(row, index) {  
                                          return(
                                              <option key={index} data-lanedetailsindex={index} value={row.id}>{row.name}</option>
                                      )             
                                       })):(<option></option>)
                                    }
                                    </select>
                                 </div>
                              </div>

                              <div className='row'>                         
                                 <div className="col col-md-4 form-group">
                                   <label>City</label>
                                   <select className="form-control json-col" id="currentCity" name="Cityid" onChange={(e) => {this.loadShipperZip(e)}}>                                 
                                    <option value="">Select City</option>
                                    {this.getCity(this.state.shipperCity)}
                                    </select>
                                 </div>
                                 <div className="col col-md-4 form-group">
                                   <label>Zip</label>
                                   <select className="form-control json-col" id="currentZip" name="Zipid" >                                 
                                    <option value="">Select Zip</option>
                                    {this.getZip(this.state.shipperZip)}
                                    </select>
                                 </div>
                              </div>
                       
                            
                          <h5 className="highlight doc-color">Lane Table</h5>
                          <table className="table table-borderless  all-table load-table rate-table">
                            <thead>
                                <tr> 
                                  <th colSpan="4"  width="40%" className="shipperLaneTable">From</th> 
                                  <th colSpan="4" width="40%" className="shipperLaneTable">To</th> 
                                  <th rowSpan="2" width="10%" className="shipperLaneTable">Miles</th>
                                  <th className="shipperLaneTable">Rate</th> 
                                  <th rowSpan="2" width="20%" className="shipperLaneTable">Remarks</th>
                                  <th rowSpan="2" className="shipperLaneTable"></th>
                                </tr> 
                                <tr> 
                                  <th width="10%" >Street Name</th>
                                  <th width="10%" className="shipperLaneTable">State</th> 
                                  <th width="10%" className="shipperLaneTable">City</th>  
                                  <th width="10%" className="shipperLaneTable">Zip</th> 
                                  <th>Street Name</th>                                  
                                  <th className="shipperLaneTable">State</th>
                                  <th width="10%" className="shipperLaneTable">City</th> 
                                  <th width="10%" className="shipperLaneTable">Zip</th> 
                                  <th className="shipperLaneTable" width="10%">Base Price</th> 
                                </tr> 
                                </thead>
                             <tbody >
                              {this.getRows()}  
                             </tbody>
                          </table>
                       </form>
                    </div>
                 </div>
              </div>
              <div className="modal-footer">
                 <button type="button" className="btn btn-primary" onClick={(e) => {this.submitForm(e)}}>Save changes</button>
                 <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleClick}>Close</button>
              </div>
           </div>
        </div>
     </div>
         )
   }
}

const mapStateToProps = state => {
   return {
     CityVal:state.loadReducer.CityVal,
     ZipVal:state.loadReducer.ZipVal,
     data: state.loadReducer.data,
     loading: state.loadReducer.loading,
     error: state.loadReducer.error,
   }
}
 
export default connect(mapStateToProps)(ShipperAdd);

