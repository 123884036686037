import React, { Component } from 'react';
import { connect } from 'react-redux';
import DashboardTrucks from './DashboardTrucks';
//import DashboardSearch from './DashboardSearch';
import DashboardBreadcrump from './DashboardBreadcrump';
import DashMap from './DashboardMapGl';
import { eldAssetList, dashboardAction } from '../../actions/dashboardActions';
import { Row, Col } from 'react-bootstrap';
import Eldprovider from './Eldprovider';
require('dotenv').config();

class Dashboard extends Component{
	constructor(props) {
		super(props);
		this.state={
			selectedData : [],
			checkedData : [],
			viewFlag : false,
			routeData : [],
			updated : 0,
			eldProvider:'',
			TrucksNum :[],
			mapZoom : 3.5,
			mapCenter : [-96,37],
			tabActive : 0,
			activeTab : false,
			truckState : 'active',
			loaderPro : true,
		}
	}
	componentDidMount() {
		//this.props.dispatch(eldAssetList(1));
		this.props.dispatch(dashboardAction());
		//this.props.dispatch(truckList());
	}	
	componentDidUpdate (){
		if(this.state.eldProvider === "")
		{
			
			if(typeof this.props.eldproviders.data !== "undefined")
			{
				if(this.props.eldproviders.data.length>1){
					this.setState({
						...this.state,
						eldProvider: this.props.eldproviders.data[1].eldProviderDispName
					});
					this.props.dispatch(eldAssetList(this.props.eldproviders.data[1].id));
				} else if(this.props.eldproviders.data.length>0){
					this.setState({
						...this.state,
						eldProvider: this.props.eldproviders.data[0].eldProviderDispName
					});
					this.props.dispatch(eldAssetList(this.props.eldproviders.data[0].id));
				}		
			}
		}
		if(this.state.updated === 0 && this.props.dashboard.dashboard){
			if(this.props.dashboard.dashboard.active.length > 0){
			this.handleDashboardState(this.state.checkedData,this.props.dashboard.dashboard.active,1,'active');
			}
			else if (this.props.dashboard.dashboard.idle.length > 0){
				this.handleDashboardState(this.state.checkedData,this.props.dashboard.dashboard.idle,2,'ideal');
			}
			else {
				this.handleDashboardState(this.state.checkedData,this.props.dashboard.dashboard.stopped,3,'stopped');
			}
		}
	}
	handleDashboardState = (checkedState, activeState, activeTab,truckState) => {
		if(checkedState  !== activeState) {
			this.setState({
				...this.state,
				selectedData: activeState,
				checkedData : activeState,
				viewFlag : false,
				routeData : [],
				TrucksNum : activeState,
				tabActive : activeTab,
				truckState : truckState,
				loaderPro : false
			});
		}
	}
	handleTabClicked = (val,stopped) => {	
		if(this.props.dashboard.dashboard){
			if(val === 1){			// For Active Trucks 			
				this.setState({
					updated : 1,
					selectedData: this.props.dashboard.dashboard.active,
					checkedData : this.props.dashboard.dashboard.active,
					viewFlag : false,
					routeData : [],
					TrucksNum : this.props.dashboard.dashboard.active,
					mapZoom : 3.5,
					mapCenter : [-96,37],
					tabActive:1,
					truckState : 'active',
					loaderPro : false
				});
			}else if(val === 2){		// For Idle Trucks			
				this.setState({
					updated : 1,
					selectedData: this.props.dashboard.dashboard.idle,
					checkedData : this.props.dashboard.dashboard.idle,
					viewFlag : false,
					routeData : [],
					TrucksNum : this.props.dashboard.dashboard.idle,
					mapZoom : 3.5,
					mapCenter : [-96,37],
					tabActive:2,
					truckState : 'ideal',
					loaderPro : false
				});
			}else if(val === 3){		// For Stopped Trucks			
				this.setState({
					updated : 1,
					selectedData : this.props.dashboard.dashboard.stopped,
					checkedData : this.props.dashboard.dashboard.stopped,
					viewFlag : false,
					routeData : [],
					TrucksNum : this.props.dashboard.dashboard.stopped,
					mapZoom : 3.5,
					mapCenter : [-96,37],
					tabActive:3,
					truckState : 'stopped',
					loaderPro : false
				});
			}
		}		
	   }
	handletruckClicked = (selectedTruck) => {			
		console.log(selectedTruck,selectedTruck.length);	
		if(selectedTruck.length === 1){					// change to route Map			
			this.setState({
				...this.state,
				updated : 1,
				viewFlag: true,
				routeData : selectedTruck
			});									
		}else{											// restore original map
			this.setState({
				...this.state,
				updated : 1,
				viewFlag: false,
				routeData : []
			});			
		}			
	}
	handlecheckList = (filteredEle) =>{
		console.log(filteredEle);
		switch(filteredEle.length) {
		case 0:				
			this.setState({
				...this.state,
				updated : 1,
				viewFlag: false,
				checkedData : filteredEle,
				routeData: [],
				loaderPro : false
			});
			break; 
		case 1:				
			this.setState({
				...this.state,
				updated : 1,
				viewFlag : true,
				routeData : filteredEle,
				checkedData : filteredEle,	
				loaderPro : false				
			});
			break;
		default:					
			this.setState({
				...this.state,
				updated : 1,
				viewFlag : false,
				checkedData : filteredEle,
				routeData : [],
				loaderPro : false
			});
			break;
		}	
	   }	
	   handleEldprovider = (menu) =>{
		// return;
		this.setState({
			...this.state,
			eldProvider:menu.eldProviderDispName,
			mapZoom : 3.5,
			mapCenter : [-96,37],
			checkedData:[],
			tabActive:1,
			updated : 0,
		});
		this.props.dispatch(eldAssetList(menu.id));
	   }
	   
	   _truckClick = (truckInfo) => {
			//console.log('Ideal Trucks Info : --',this.state.selectedData);
			this.setState({
				...this.state,
				checkedData:[truckInfo],
				mapZoom:13,
				mapCenter:[truckInfo.longitude, truckInfo.latitude]
			});
		}
	render(){
		const TOKEN=process.env.REACT_APP_MAPBOX_TOKEN;
		var dashboard ={};
		var stopped   ={};
		var idle      ={};
		var active    ={};
		if(this.props.dashboard.dashboard) { 
			console.log(this.props.dashboard);
			dashboard = this.props.dashboard.dashboard;
			stopped   = dashboard.stopped;
			idle      = dashboard.idle;
			active    = dashboard.active;
		}
		let loader=( this.props.loading === false && this.state.loaderPro === false )? false : true;
		return(			
			<article className="dashboard-data shadow bg-white rounded">
				<Row className="no-gutters">
					<Col md={3} className="pos-rel">
						<DashboardTrucks 
						stopped={ stopped } 
						idle={ idle } 
						active={ active }
						tabClicked={(val)=> {this.handleTabClicked(val)}}
						truckClicked={(selectedTruck)=> {this.handletruckClicked(selectedTruck)}}
						selectedData={ this.state.selectedData }
						checkList={(filteredEle)=> {this.handlecheckList(filteredEle)}}
						eldProvider={this.state.eldProvider}
						_truckClick = {(truckInfo) => { this._truckClick(truckInfo); }}
						aTab = {this.state.tabActive}
						spinner={loader}
						/>
						<DashboardBreadcrump trucks={this.state.TrucksNum} />
					</Col>
					<Col md={9} id="map_parent">
						<Eldprovider handleEldprovider={(id)=> {this.handleEldprovider(id)}}/>
					 <DashMap mapZoom={this.state.mapZoom} mapCenter={this.state.mapCenter} spinner={loader} mapboxApiAccessToken={TOKEN} data={this.state.checkedData} viewflg={this.state.viewFlag} routeData={this.state.routeData} truckstate={this.state.truckState}
					 handleEldprovider={(id)=> {this.handleEldprovider(id)}} />
					</Col>
				</Row>
			</article>
		)
	}
}
const mapStateToProps = (state) => {
	console.log(state.dashboardReducer);
	return{
		dashboard : state.dashboardReducer.eldAssets,
		data: state.dashboardReducer.data,
		trucks: state.truckReducer.trucks,
		eldproviders : state.commonReducer.eldproviderlist,
		commonLoader: state.commonReducer.loading,
		loading: state.dashboardReducer.loading,
	}    
}
export default connect(mapStateToProps)(Dashboard)


