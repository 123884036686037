import React, { Component } from 'react';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { invitedCustomerList, myCustomerList, myCustomerOptInOutList } from '../../actions/companyActions';
import moment from 'moment';
import { getPdfDownloadLink, getOnboardPdfDownloadLink, } from '../../services/companyService';
import { TableDataComponent, TabsBar } from 'UI-Component';
import ToggleSwitch from '../../helpers/ToggleSwitch';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MaterialUITabs from '../uicomponents/DataTableGridMUIX';
import { Box } from '@mui/material';

require('dotenv').config();
const { SearchBar } = Search;

class CustomersList extends Component {
	getModal = (customer_list) => {
		this.setState({ showModal: true, modaldata: customer_list });
	};
	constructor(props) {
		super(props);
		this.getIndex = this.getIndex.bind(this)
		this.app_url = process.env.REACT_APP_URL;
		this.state = {
			driver_data_check_status: false,
			location_data_check_status: false,
			vehicle_data_check_status: false,
			hos_data_check_status: false,
			dvir_data_check_status: false,
			tabIndex: 0
		}
	}
	getIndex(index, selectedId) {
		this.setState({ activeId: selectedId })
	}
	componentDidMount = () => {
		let userType = localStorage.getItem('userType');
		this.setState({ userType: userType })
		if ('isActiveTab' in sessionStorage) {
			this.setState({ activeId: sessionStorage.getItem('isActiveTab') })
		} else {
			this.setState({ activeId: 'myCustomers' })
			sessionStorage.setItem('isActiveTab', 'myCustomers');
		}
		switch (sessionStorage.getItem('isActiveTab')) {
			case 'myCustomers':
				this.getMyCustomers(0);
				break;
			case 'invited':
				this.invitedAPICall(1);
				break;
			default:
				break;
		}
	}
	getInvitedCustomer = (newTabIndex) => {
		this.setState({ tabIndex: newTabIndex })
		this.props.dispatch(invitedCustomerList())
		sessionStorage.setItem('isActiveTab', 'invited');
	}

	getMyCustomers = (newTabIndex) => {
		this.setState({ tabIndex: newTabIndex })
		this.props.dispatch(myCustomerList())
		sessionStorage.setItem('isActiveTab', 'myCustomers');
	}
	componentWillUnmount() {
		sessionStorage.removeItem('isActiveTab');
	}
	loadTabs = () => {
		return (
			<MaterialUITabs tabsData={
				[{ component: 'MY Customers', tabID: 'myCustomers-tab', refData: 'myCustomers', methodName: (e, data, index) => { this.getMyCustomers(e, data, index) } },
				{ component: 'Invitations', tabID: 'invited-tab', refData: 'invited', methodName: (e, data, index) => { this.invitedAPICall(e, data, index) } }
				]}
				getIndex={this.getIndex}
				activeTab={this.state.activeId}
				state={this.state}
			/>
		);
	}
	downloadPdf = (e, Customer_Invitation_Id) => {
		e.preventDefault();
		let param = {
			payload: {
				customerInvitationId: Customer_Invitation_Id
			}
		}
		getPdfDownloadLink(param).then((response) => {
			if (response && response.status && response.status === 1) {
				window.open(response.message);
			} else {
				toastr.error('Error', 'Unable to find the document');
			}
		});
	}
	downloadOnboardedPdf = (e, Company_Misc_Id) => {
		e.preventDefault();
		let param = {
			payload: {
				companyMiscId: Company_Misc_Id
			}
		}
		getOnboardPdfDownloadLink(param).then((response) => {
			if (response && response.status && response.status === 1) {
				window.open(response.message);
			} else {
				toastr.error('Error', 'Unable to find the document');
			}
		});
	}
	downloadLOAPdf = (e, LOA) => {
		e.preventDefault();
		switch (LOA) {
			case 'empty': {
				return
			};
			case null: {
				return
			};
			case undefined: {
				return
			};
			case '': {
				return
			}
			default: {
				window.open(LOA);
			}
		}
	}
	prepareDataToBeselected() {
		console.log('in prepare')
		let preparedData = []
		const onboardLength = this.props.onBoardedCustomerList.length;
		let counter = 0;
		for (let i = 0; i < onboardLength; i++) {
			if (this.props.onBoardedCustomerList[i].statusInfo.enrollmentAuthorizedByCarrier === false) {
				for (let index = 0; index < this.props.onBoardedCustomerList[i].eldInfo.length; index++) {
					counter++;
					preparedData.push(counter)
				}
			} else {
				counter++;
			}
		}
		return preparedData;
	}
	componentWillReceiveProps(prevProps) {
		if (prevProps && prevProps.optStatus !== this.props.optStatus) {
			if (prevProps.optStatus === 'undefined' || prevProps.optStatus === undefined) {
				return;
			}
			else {
				if (prevProps?.optStatus.status === 'OK') {
					this.getMyCustomers()
					toastr.success('Success', `${this.state.customerName} data sharing has been ${this.state.authorize ? 'enabled' : 'disabled'}`);
				} else {
					toastr.error('Error', prevProps.optStatus.message);
				}
			}
		}
	}
	changeOptInOutStatus(params) {
		this.props.dispatch(myCustomerOptInOutList({ authorized: params.authorize, customerID: params.customerID }))
	}
	ToggleButton(authorize, customerID, customerName) {
		this.setState({ authorize: authorize, customerID: customerID, customerName: customerName, methodName: (e) => { this.changeOptInOutStatus(e) } })
	}
	showOnboardCustomer() {
		let myCustomers = []
		const onboardLength = this.props.onBoardedCustomerList.length;
		for (let i = 0; i < onboardLength; i++) {
			this.props.onBoardedCustomerList[i].eldInfo.map((row, index) => {
				let stringLoadDate = '';
				if (this.props.onBoardedCustomerList[i].statusInfo?.onboarded !== '' && this.props.onBoardedCustomerList[i].statusInfo?.onboarded != null) {
					stringLoadDate = moment(this.props.onBoardedCustomerList[i].statusInfo?.onboarded).format('MM-DD-YYYY');
				}
				myCustomers.push({
					Customer_Name: this.props.onBoardedCustomerList[i].customerInfo.companyName,
					company_Id: this.props.onBoardedCustomerList[i].customerInfo.companyId,
					Opt_Status: this.props.onBoardedCustomerList[i].statusInfo.enrollmentAuthorizedByCarrier ? 'Enabled' : 'Disabled',
					Data_Sharing: <span key={index}>
						{this.props.onBoardedCustomerList[i].eldInfo[index].eldDataSharing?.authenticationData ? <i id='iconPermissionTrue' className='icofont-lock' data-tip data-for='authenticationData'></i> : <i id='iconPermissionFalse' className='icofont-lock' data-tip data-for='authenticationData'></i>}
						{this.props.onBoardedCustomerList[i].eldInfo[index].eldDataSharing?.vehicleData ? <i id='iconPermissionTrue' className='icofont-truck-alt' data-tip data-for='vehicleData'></i> : <i id='iconPermissionFalse' className='icofont-truck-alt' data-tip data-for='vehicleData'></i>}
						{this.props.onBoardedCustomerList[i].eldInfo[index].eldDataSharing?.locationData ? <i id='iconPermissionTrue' className='icofont-location-pin' data-tip data-for='locationData'></i> : <i id='iconPermissionFalse' className='icofont-location-pin' data-tip data-for='locationData'></i>}
						{this.props.onBoardedCustomerList[i].eldInfo[index].eldDataSharing?.driverData ? <i id='iconPermissionTrue' className='icofont-user-alt-3' data-tip data-for='driverData'></i> : <i id='iconPermissionFalse' className='icofont-user-alt-3' data-tip data-for='driverData'></i>}
						{this.props.onBoardedCustomerList[i].eldInfo[index].eldDataSharing?.hoursOfServiceData ? <i id='iconPermissionTrue' className='icofont-ui-clock' data-tip data-for='hoursOfServiceData'></i> : <i id='iconPermissionFalse' className='icofont-ui-clock' data-tip data-for='hoursOfServiceData'></i>}
						{this.props.onBoardedCustomerList[i].eldInfo[index].eldDataSharing?.engineData ? <i id='iconPermissionTrue' className='icofont-spanner' data-tip data-for='engineData'></i> : <i id='iconPermissionFalse' className='icofont-spanner' data-tip data-for='engineData'></i>}
						{this.props.onBoardedCustomerList[i].eldInfo[index].eldDataSharing?.dvirData ? <i id='iconPermissionTrue' className='icofont-file-file' data-tip data-for='dvirData'></i> : <i id='iconPermissionFalse' className='icofont-file-file' data-tip data-for='dvirData'></i>}
						{
							<span>&nbsp;{
								<label class="switch" data-tip data-for={this.props.onBoardedCustomerList[i].statusInfo?.invitationId + 'data-sharing-hover'}>
									<input type="checkbox" checked={this.props.onBoardedCustomerList[i].statusInfo?.enrollmentAuthorizedByCarrier} data-toggle='modal' data-target='#toggleSwitchModal' data-backdrop='true' onClick={() => { this.ToggleButton(!this.props.onBoardedCustomerList[i].statusInfo?.enrollmentAuthorizedByCarrier, this.props.onBoardedCustomerList[i].customerInfo?.companyId, this.props.onBoardedCustomerList[i].customerInfo?.companyName); }} />
									<span class="slider round">
										<ReactTooltip id={this.props.onBoardedCustomerList[i].statusInfo?.invitationId + 'data-sharing-hover'}>
											{this.props.onBoardedCustomerList[i].statusInfo?.enrollmentAuthorizedByCarrier ? 'Disable' : 'Enable'}  Data Sharing
										</ReactTooltip>
									</span>
								</label>
							}
							</span>
						}
						{<button onClick={(e) => { this.downloadLOAPdf(e, this.props.onBoardedCustomerList[i].eldInfo[index].letterOfAuthorizationUrl); }} className='btn cobpdf' title='Download PDF' disabled={!this.props.onBoardedCustomerList[i].statusInfo.enrollmentAuthorizedByCarrier} >
							<i className='icofont-file-pdf' data-tip data-for='loa'></i>
						</button>}
						{this.props.onBoardedCustomerList[i].eldInfo[index].eldLetterOfAuthorizationUrl ? <button onClick={(e) => { this.downloadLOAPdf(e, this.props.onBoardedCustomerList[i].eldInfo[index].eldLetterOfAuthorizationUrl); }} className='btn cobpdf' title='Download PDF' disabled={!this.props.onBoardedCustomerList[i].statusInfo.enrollmentAuthorizedByCarrier}>
							<i className='icofont-file-pdf' data-tip data-for='eld-loa'></i>
						</button> : null}
						<ReactTooltip className='tooltip' id='authenticationData'>
							Authentication Data
						</ReactTooltip>
						<ReactTooltip id='vehicleData'>
							Vehicle Data
						</ReactTooltip>
						<ReactTooltip id='locationData'>
							Location Data
						</ReactTooltip>
						<ReactTooltip id='driverData'>
							Driver Data
						</ReactTooltip>
						<ReactTooltip id='hoursOfServiceData'>
							Hours Of Service Data
						</ReactTooltip>
						<ReactTooltip id='engineData'>
							Engine Data
						</ReactTooltip>
						<ReactTooltip id='dvirData'>
							DVIR Data
						</ReactTooltip>
						<ReactTooltip id='loa'>
							LOA
						</ReactTooltip>
						<ReactTooltip id='eld-loa'>
							ELD-LOA
						</ReactTooltip>
					</span>,
					ELD_Provider_Name: this.props.onBoardedCustomerList[i]?.eldInfo[index].eldVendorDisplayName,
					eMail: this.props.onBoardedCustomerList[i].customerInfo?.customerEmail,
					Date_Onboarded: stringLoadDate,
					name: this.props.onBoardedCustomerList[i].customerInfo.hasOwnProperty('firstName') && this.props.onBoardedCustomerList[i].customerInfo.hasOwnProperty('lastName') ? (this.props.onBoardedCustomerList[i].customerInfo?.firstName === null ? '' : this.props.onBoardedCustomerList[i].customerInfo?.firstName) + ' ' + (this.props.onBoardedCustomerList[i].customerInfo?.lastName === null ? '' : this.props.onBoardedCustomerList[i].customerInfo?.lastName) : null,
					Company_Type: this.props.onBoardedCustomerList[i].customerInfo.companyType
				})
			})
		}
		return myCustomers
	}
	invitedAPICall() {
		this.getInvitedCustomer(1)
		sessionStorage.setItem('isActiveTab', 'invited');
	}
	rowClasses = (row, rowIndex) => {
		console.log('row, rowIndex', row, rowIndex)
		let classes = null;
		let disabledRows = []
		if (row.Opt_Status === 'Disabled') {
			disabledRows.push(rowIndex + 1)
		}
		if (disabledRows.includes(rowIndex + 1)) {
			classes = "custom-row-class";
		}
		return classes;
	};
	render() {
		let myCustomers = [];
		let invited = [];
		let customer_nodata = '';
		if (this.props.loading) {
			customer_nodata =
				<div className='spinner-border' role='status'>
					<div className='row react-bs-table-no-data'></div>
				</div>
		}
		else {
			customer_nodata = 'No Data Found';
			if (this.props.invitedCustomerList.length > 0) {
				invited = this.props.invitedCustomerList.map((row) => {
					let stringLoadDate = '';
					if (row.Expired_On !== '' && row.Expired_On != null) {
						stringLoadDate = moment(row.Expired_On).format('MM-DD-YYYY');
					}
					return ({
						Customer_Email: row.customerInfo.customerEmail,
						Invitation_ID: row.statusInfo.invitationId,
						Customer_Name: row.customerInfo.companyName,
						Name: row.customerInfo.hasOwnProperty('firstName') && row.customerInfo.hasOwnProperty('lastName') ? (row.customerInfo.firstName === null ? '' : row.customerInfo.firstName) + ' ' + (row.customerInfo.lastName === null ? '' : row.customerInfo.lastName) : null,
						Status: <span>
							{row.statusInfo.status === 'EXPIRED' ?
								<ReactTooltip id='verificationDate'>
									Verification Failed Date
								</ReactTooltip>
								:
								<ReactTooltip id='expiredDate'>
									Expired On  {row.statusInfo.expired}
								</ReactTooltip>
							}
							{row.statusInfo.status === 'INVITED' ? <button id='statusInvited' className='btn btn-primary btn-sm' >{row.statusInfo.status}</button>
								: row.statusInfo.status === 'EXPIRED' ? <button data-tip data-for='expiredDate' id='statusNotInvited' className='btn btn-primary btn-sm' >{row.statusInfo.status}</button>
									: row.statusInfo.status === 'VERIFICATION_FAILED' ? <button data-tip data-for='verificationDate' id='statusNotInvited' className='btn btn-primary btn-sm' >VERIFICATION FAILED</button> : null}</span>,
						expiry_date: stringLoadDate,
						Invited: row.statusInfo.hasOwnProperty('invited') ? moment(row.statusInfo.invited).format('MM-DD-YYYY') : null,
						Expired: row.statusInfo.hasOwnProperty('expired') ? moment(row.statusInfo.expired).format('MM-DD-YYYY') : null,
						ELD_Provider_Name: row.statusInfo.status === 'INVITED' || row.statusInfo.status === 'EXPIRED' ? 'Unknown' : row.eldInfo.length > 0 ? row.eldInfo[0].eldVendorDisplayName : 'Unknown'
					});
				});
			}
		}
		const myCustomerColumns = [
			{
				text: 'TC Customer ID',
				dataField: 'company_Id',
				sort: true
			},
			{
				text: 'Customer',
				dataField: 'Customer_Name',
				sort: true
			},
			{
				text: 'Customer Contact Name',
				dataField: 'name',
				sort: true
			},
			{
				text: 'Customer Contact Email',
				dataField: 'eMail',
				sort: true
			},
			{
				text: 'ELD Provider',
				dataField: 'ELD_Provider_Name',
				sort: true
			},
			{
				text: 'Type',
				dataField: 'Company_Type',
				sort: true
			},
			{
				text: 'Date Onboarded',
				dataField: 'Date_Onboarded',
				sort: true
			},
			{
				text: 'Data Sharing',
				dataField: 'Data_Sharing',
				sort: true
			}
		];
		const invitedColumns = [
			{
				text: 'Invitation ID',
				dataField: 'Invitation_ID',
				sort: true
			},
			{
				text: 'Customer',
				dataField: 'Customer_Name',
				sort: true
			},
			{
				text: 'Customer Contact Name',
				dataField: 'Name',
				sort: true
			},
			{
				text: 'Customer Contact Email',
				dataField: 'Customer_Email',
				sort: true
			},
			{
				text: 'Invited On',
				dataField: 'Invited',
				sort: true
			},
			{
				text: ' Expires On',
				dataField: 'Expired',
				sort: true
			},
			{
				text: 'ELD Provider',
				dataField: 'ELD_Provider_Name',
				sort: true
			},
			{
				text: 'Status',
				dataField: 'Status',
				sort: true
			}
		];
		const customTotal = (from, to, size) => (
			<span className='react-bootstrap-table-pagination-total'>
				Showing {from} to {to} of {size} Results
			</span>
		);
		const options = {
			paginationSize: 4,
			pageStartIndex: 1,
			firstPageText: '<<<',
			prePageText: '<<',
			nextPageText: '>>',
			lastPageText: '>>>',
			nextPageTitle: 'First page',
			prePageTitle: 'Pre page',
			firstPageTitle: 'Next page',
			lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: customTotal,
		};
		return (
			<article className='table-data truck-data shadow bg-white rounded'>
				<div className='row no-gutters'>
					<div className='col-md-12'>
						{this.loadTabs()}
						<div className='tab-content' id='myTabContent'>
							{this.state.tabIndex === 0 && (
								<Box>
									<ToolkitProvider
										keyField='id'
										data={this.showOnboardCustomer()}
										columns={myCustomerColumns}
										search
									>
										{
											props => (
												<div>
													<SearchBar {...props.searchProps} />
													<BootstrapTable bootstrap4
														{...props.baseProps} pagination={paginationFactory(options)}
														noDataIndication={customer_nodata} hover bordered={false}
														expandRow={this.expandRow ? this.expandRow : {}}
														rowClasses={this.prepareDataToBeselected() && (this.state.userType === 'broker' || this.state.userType === 'carrier' || this.state.userType === 'techpartner') ? this.rowClasses : ''} />
												</div>
											)
										}
									</ToolkitProvider>
								</Box>
							)}
							{this.state.tabIndex === 1 && (
								<Box>
									<TableDataComponent tableData={invited} columnData={invitedColumns} noDataIndication={customer_nodata} />
								</Box>
							)}
						</div>
					</div>
					<ToggleSwitch data={this.state} />
				</div>
			</article>
		)
	}
}
const mapStateToProps = (state) => {
	let invitedCustomer = [];
	let onBoardedCustomer = [];
	if (state.companyReducer.invitedCustomers) {
		invitedCustomer = state.companyReducer.invitedCustomers
	}

	if (state.companyReducer.myCustomers) {
		onBoardedCustomer = state.companyReducer.myCustomers
	}
	return {
		loading: state.companyReducer.loading,
		data: state.companyReducer.data,
		searchlists: state.companyReducer.data,
		optStatus: state.companyReducer.optStatus,
		invitedCustomerList: invitedCustomer,
		onBoardedCustomerList: onBoardedCustomer
	}
}
export default connect(mapStateToProps)(CustomersList)