import * as types from './index';

export const dashboardAction = (payload) => {
	return {
		  type: types.GET_DASHBOARD_DATA,
		  payload: payload
	}
};
export const factordashboardAction = (payload) => {
	return {
		  type: types.GET_DASHBOARD_DATA_FACTOR,
		  payload: payload
	}
};

export const factordashboarMultipleElddAction = (payload) => {
	return {
		  type: types.GET_DASHBOARD_DATA_FACTOR_MUTIPLE_ELD,
		  payload: payload
	}
};

export const brokerdashboardAction = (payload) => {
	return {
		  type: types.GET_DASHBOARD_DATA_BROKER,
		  payload: payload
	}
};

export const brokerdashboardMultipleEldAction = (payload) => {
	return {
		  type: types.GET_DASHBOARD_DATA_BROKER_MULTIPLE_ELD,
		  payload: payload
	}
};

export const trackingList = (payload) => {
	return {
		type: types.GET_TRACKING_LIST,
		payload: payload
	}
};
export const eldAssetList = (id) => {	
	return {
		type: types.GET_ELD_ASSEST_LIST,
		payload : id
	}
};

export const setgoFlag = (flag) => {
    return {
        type: types.SET_GOFLAG,
        flag : flag
    }
};
