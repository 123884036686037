import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Modal from 'react-bootstrap/Modal';
import { shipperLoadAction } from '../../../actions/loadActions';
import { getDeliveredLoadVehicleTracking } from '../../../services/loadService';
import mapboxgl from 'mapbox-gl';
import { STATUS } from '../../../helpers/status';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { convertDistance, getDistance } from 'geolib';
import zipcodes from 'zipcodes';
import moment from 'moment';
import {formatLocation, getMapboxKey} from '../../../helpers/appUtil';
import  Breadcrumb  from './BreadCrump';
class Dispatched extends Component{
    
    constructor(props){
        super(props);
        this.state={
            mapobject:null,
            showModal: false,
            distance:'',
            pickupDate:'',
            deliveryDate:'',
            LoadNumber:'',
            trackingList:[]
        }
        this.SUBSCRIPTION_PLAN_ID=localStorage.getItem('SUBSCRIPTION_PLAN_ID');
    }

    componentDidMount() {
        document.title="Delivered";
        const params={
            ...STATUS.LOAD.DELIVERED,
        }
        this.props.dispatch(shipperLoadAction(params));
        mapboxgl.accessToken = getMapboxKey();
        //mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    }

    async handleVehicleData (req){
        const response = await getDeliveredLoadVehicleTracking(req);
        console.log('loadInfo',response.loadInfo) 
            if(response.loadVehicleTrackingList === null) {
                toastr.info('Info', 'No Data Available!');
            }
            else if(response.loadVehicleTrackingList.length === 0) {
                toastr.info('Info', 'No Data Available!');
            }
            else if(response.loadVehicleTrackingList.length > 0) {
                let deliveredDateTime = response.loadInfo.deliveredDateTime;
                let dispatchDateTime = response.loadInfo.dispatchDateTime;

                if(deliveredDateTime){
                    deliveredDateTime = deliveredDateTime.split('+')
                    if(deliveredDateTime.length>1){
                        deliveredDateTime = deliveredDateTime[0]
                    } else {
                        deliveredDateTime = deliveredDateTime.split('-')
                    }
                }

                if(dispatchDateTime){
                    dispatchDateTime = dispatchDateTime.split('+')
                    if(dispatchDateTime.length>1){
                        dispatchDateTime = dispatchDateTime[0]
                    } else {
                        dispatchDateTime = dispatchDateTime.split('-')
                    }
                }

                this.setState({
                    ...this.state,
                    showModal:true,
                    trackingList:response.loadVehicleTrackingList,
                    deliveryDate:moment(deliveredDateTime).format('MM-DD-YYYY hh:mm a'),
                    pickupDate:moment(dispatchDateTime).format('MM-DD-YYYY hh:mm a'),
                    LoadNumber:response.loadNumber
                });
                //this.showMap(response.loadVehicleTrackingList, response.loadInfo, response.sourcePoint, response.destinationPoint)
            }
            else {
                toastr.info('Info', 'No Data Available!');
            }
        
		//set state of show modal true
		//Intialize map
		//this.props.dispatch(vehicleList({loadId:this.state.loadId}));
		
    }
    
    showMap (response, loadInfo, sourcePoint, destinationPoint) {
		this.initHistoryMap(loadInfo);
		//force initializing map
		
        // let isMapOn = this.initHistoryMap();
        // while(!isMapOn){
        //     isMapOn = this.initHistoryMap();
        // }
        let mapobject = this.state.mapobject;
        let geojson = {
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'geometry': {
                    'type': 'LineString',
                    'coordinates': []
                    }
                }
            ]
        };
        //force initializing map
        let plottimer = null;
        let i = 0;
        let pathdata = [];
        let currentzoom = 12;
        let coords = response.map( data => {
            return {
                latitude:parseFloat(data.latitude),
                longitude:parseFloat(data.longitude),
                vehicleno:data.license_Plate_No,
                speed:data.speed,
                utctime:data.convertedDate,
                address:data.location
            }
        });
        
		//var trackingData = [...response];
		//var trackingData = [...this.props.trackingData];
		this.calculateDistance(response,coords);
		//pathdata = this.removeDuplicateData(coords);
        pathdata = coords;
        
        mapobject.setCenter([coords[0].longitude,coords[0].latitude]);
        mapobject.setZoom(currentzoom);
        let datalength = pathdata.length;
        this.plotInitialMarkers(mapobject,pathdata);
        mapobject.on('load', function() {
            mapobject.addSource('movementroute', {
                'type': 'geojson',
                'data': geojson
            });
            mapobject.addLayer({
                'id': 'historypath',
                'type': 'line',
                'source': 'movementroute',
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': 'red',
                    'line-opacity': 0.75,
                    'line-width': 3
                }
            });
            plottimer = setInterval(function(){
                if(pathdata[i].latitude && pathdata[i].longitude){
                    let currentposition = [pathdata[i].longitude,pathdata[i].latitude];
                    geojson.features[0].geometry.coordinates.push(currentposition);
                    mapobject.getSource('movementroute').setData(geojson);
                    i++;
                    if(!mapobject.getBounds().contains(currentposition)){
                        currentzoom = currentzoom-1;
                        mapobject.setZoom(currentzoom); 
                    }
                    if(i>=datalength){
                        clearInterval(plottimer);
                        this.plotMovementMarkers(mapobject,pathdata)
                    }
                }
            }.bind(this,mapobject), 100);
        }.bind(this));

        this.getPoint(sourcePoint);

        // let point1 = this.getPoint(sourcePoint);

        // let point2 = this.getPoint(destinationPoint);

        // if(point1.lat && point1.lont){
        //     new MapboxCircle({lat: point1.lat, lng: point1.lont}, 32186.9, {
        //         editable: false,
        //         minRadius: 1609.34,
        //         fillColor: '#dd1111'
        //     }).addTo(mapobject);

        // }

        
        // if(point2.lat && point2.lont){
        //     new MapboxCircle({lat: point2.lat, lng: point2.lont}, 32186.9, {
        //         editable: false,
        //         minRadius: 1609.34,
        //         fillColor: '#11dd11'
        //     }).addTo(mapobject);
        // }
    }

    addMarkerToMap(markerelement,mapobject,locationdata){
		let speed = parseFloat(locationdata.speed);
        if(isNaN(speed))
        {
            speed ='';
        }
        else{
            speed = speed.toFixed(2) + " mph";
        }
		let location = formatLocation(locationdata.address);
        let popup = new mapboxgl.Popup({ 
            offset: 25, 
            closeButton: false,
            closeOnClick: false
        }).setHTML('<span class="map-popup-label">Truck No. : </span><span class="map-popup-label">'+locationdata.vehicleno+'</span></br><span class="map-popup-label1"> Date : </span><span class="map-popup-label">'+locationdata.utctime+'</span></br><span class="map-popup-label1">Speed : </span><span class="map-popup-label">'+speed+'</span></br><span class="map-popup-label2">Location : </span><span class="map-popup-label">'+location+'</span></br>');
        // make a marker for each feature and add to the map
        let newmarker = new mapboxgl.Marker(markerelement)
            .setLngLat([locationdata.longitude,locationdata.latitude])
            .setPopup(popup)
            .addTo(mapobject);
        let markerDiv = newmarker.getElement();
        markerDiv.addEventListener('mouseenter', () => newmarker.togglePopup());
        markerDiv.addEventListener('mouseleave', () => newmarker.togglePopup());
	}
    
    plotMovementMarkers(mapobject,trackingdata){
        let pointsImage = "https://truckercloud.s3.us-east-2.amazonaws.com/images/blue.png";
        trackingdata.forEach(function(data,i){
            let pointsElement = this.createMarkerElement(i,pointsImage,"8px","8px");
            this.addMarkerToMap(pointsElement,mapobject,data);
        }.bind(this));
	}

    plotInitialMarkers(mapobject,trackingdata){
        let firstMarkerImage = "https://maps.google.com/mapfiles/ms/icons/red.png";
        let finalMarkerImage = "https://maps.google.com/mapfiles/ms/icons/green.png";
        let initialMarkerEl = this.createMarkerElement(0,firstMarkerImage,"32px","32px");
        let finalMarkerEl = this.createMarkerElement(trackingdata.length-1,finalMarkerImage,"32px","32px");
        this.addMarkerToMap(initialMarkerEl,mapobject,trackingdata[0]);
        this.addMarkerToMap(finalMarkerEl,mapobject,trackingdata[trackingdata.length-1]);
	}

    calculateDistance(trackingData, coords){
		var distance = getDistance(
			{ latitude: trackingData[0].latitude, longitude: trackingData[0].longitude },
			{ latitude: trackingData[coords.length-1].latitude, longitude: trackingData[coords.length-1].longitude } 
		);

    	distance = convertDistance(distance, 'km');
		this.setState({
			...this.state,
			distance : distance.toFixed(2)
		});
	}

    initHistoryMap(loadInfo){
        try{
            let pickupDate='';
            let deliveryDate='';
            let LoadNumber='';

            if(loadInfo)
            {
                pickupDate = moment(loadInfo.dispatchDateTime).format('MM-DD-YYYY hh:mm a');
                deliveryDate = moment(loadInfo.deliveredDateTime).format('MM-DD-YYYY hh:mm a');
                LoadNumber = loadInfo.loadNumber;
                
            }

            //return;
			this.setState({
				...this.state,
                showModal:true,
                pickupDate:pickupDate,
                deliveryDate:deliveryDate,
                LoadNumber:LoadNumber
			});
            let mapelement = document.querySelector("#map");
			if(mapelement===null){
                return false;
            }
            let body = document.body,
			html = document.documentElement;
			let height = Math.min( body.scrollHeight, body.offsetHeight, 
			html.clientHeight, html.scrollHeight, html.offsetHeight );
			height -= 150;
            mapelement.style.height = height+"px";
            let mapobject=new mapboxgl.Map({
                container: 'map', // container id
                style: 'mapbox://styles/mapbox/streets-v11', //stylesheet location
                center: [-96,37], // starting position
                zoom: 3.5 // st arting zoom
            });
            mapobject.on('load', function() {
                
            });
            this.setState({
                mapobject : mapobject 
            });
            return true;
        }
        catch(e){
            return false;
        }
    }
    
    createMarkerElement(id,imageurl,height,width){
        let el = document.createElement('div');
        el.className = 'marker';
        el.id = 'map-markers-'+id;
        el.style.backgroundImage =
        'url('+imageurl+')';
        el.style.width = width;
        el.style.height = height;
        return el;
    }
    
    close = (e) => {	
		this.setState({
            ...this.state,
			showModal: false			
		});			
    }
    
    getPoint = (zipCode) => {
        if(zipCode){
            let point1 = zipcodes.lookup(zipCode);
            if(point1){
                let lont = parseFloat(point1.longitude);
                let lat = parseFloat(point1.latitude);
                if(isNaN(lont) || isNaN(lat))
                {
                    return {};
                }
                return {lont:lont, lat:lat};
            }
            else{
                return {};
            }
        }
        else{
            return {};
        }
    }

    showBreadcrump = () => {
		
			return <Breadcrumb trackingList={this.state.trackingList}/>
		

		//return userType == "shipper"?(<Fragment></Fragment>):<DashboardBreadcrump trucks={this.state.TrucksNum} timeZones={this.props.timeZones} />
	}

    render(){

        var delivered = [];
        var indicationhtml="No Data Found";
        if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}
        if(this.props.loading){
			indicationhtml = 
			<div className="spinner-border" role="status">
			 <div className="row react-bs-table-no-data"></div>
			 </div>
		}else
        {
        if(this.props.data.LoadList !== undefined)
        {
            this.delivered  = this.props.data.LoadList;
            if(this.delivered.length > 0)
            {
                delivered = []
                delivered = this.delivered.map(
                    row =>{
                        var drivername='';
                        if(row.driverName!==null)
                        {
                            drivername = row.driverName.toUpperCase()
                        }else
                        {
                            drivername = '-'
                        }
                        var stringLoadDate="";
                        if(row.stringLoadDate!=="")
                        {
                            stringLoadDate=moment(row.stringLoadDate).format('MM-DD-YYYY');
                        }
                        else
                        {
                            stringLoadDate= "";
                        }

                        
                        // var pickUpdate = "";
                        // var pickUpTime = "";
                        // if(row.loadPointsList.length>0)
                        // {
                        //     if(row.loadPointsList.length==1)
                        //     {
                        //         if(row.loadPointsList[0].loadPointTypeId==2)
                        //         {
                        //             if(row.loadPointsList[0].earliestTime!="")
                        //             {
                        //                 var pickUpdate = moment(row.loadPointsList[0].earliestTime).format('MM-DD-YYYY HH:mm');
                        //                 var pickUpTime = moment(row.loadPointsList[0].earliestTime).format('HH:mm');
                        //             }
                        //         }
                        //     }
                        //     else if(row.loadPointsList.length==2)
                        //     {
                        //         if(row.loadPointsList[0].loadPointTypeId==2)
                        //         {
                        //             if(row.loadPointsList[0].earliestTime!="")
                        //             {
                        //                 var pickUpdate = moment(row.loadPointsList[0].earliestTime).format('MM-DD-YYYY HH:mm');
                        //                 var pickUpTime = moment(row.loadPointsList[0].earliestTime).format('HH:mm');
                        //             }
                        //         }
                        //         else if(row.loadPointsList[1].loadPointTypeId==2)
                        //         {
                        //             if(row.loadPointsList[1].earliestTime!="")
                        //             {
                        //                 var pickUpdate = moment(row.loadPointsList[1].earliestTime).format('MM-DD-YYYY HH:mm');
                        //                 var pickUpTime = moment(row.loadPointsList[1].earliestTime).format('HH:mm');
                        //             }
                        //         }
                        //     }
                        // }

                        // var deliverydate = "";
                        // var deliveryTime = "";
                        // if(row.loadPointsList.length>0)
                        // {
                        //     if(row.loadPointsList.length==1)
                        //     {
                        //         if(row.loadPointsList[0].loadPointTypeId==3)
                        //         {
                        //             if(row.loadPointsList[0].latestTime!="")
                        //             {
                        //                 var deliverydate = moment(row.loadPointsList[0].latestTime).format('MM-DD-YYYY HH:mm');
                        //                 var deliveryTime = moment(row.loadPointsList[0].latestTime).format('HH:mm');
                        //             }
                        //         }
                        //     }
                        //     else if(row.loadPointsList.length==2)
                        //     {
                        //         if(row.loadPointsList[0].loadPointTypeId==3)
                        //         {
                        //             if(row.loadPointsList[0].latestTime!="")
                        //             {
                        //                 var deliverydate = moment(row.loadPointsList[0].latestTime).format('MM-DD-YYYY HH:mm');
                        //                 var deliveryTime = moment(row.loadPointsList[0].latestTime).format('HH:mm');
                        //             }
                        //         }
                        //         else if(row.loadPointsList[1].loadPointTypeId==3)
                        //         {
                        //             if(row.loadPointsList[1].latestTime!="")
                        //             {
                        //                 var deliverydate = moment(row.loadPointsList[1].latestTime).format('MM-DD-YYYY HH:mm');
                        //                 var deliveryTime = moment(row.loadPointsList[1].latestTime).format('HH:mm');
                        //             }
                        //         }
                        //     }
                        // }
                        
                        var pickUpdate = "";
                        if(row.loadPointsList.length>0)
                        {                        
                            if(row.loadPointsList[0].loadPointTypeId===2)
                            {
                                if(row.loadPointsList[0].earliestTime!=="")
                                {
                                     pickUpdate = moment(row.loadPointsList[0].earliestTime).format('MM-DD-YYYY HH:mm');
                                }
                            }
                        }

                        var deliverydate = "";
                        if(row.loadPointsList.length>0)
                        {
                            if(row.loadPointsList[row.loadPointsList.length-1].loadPointTypeId===3)
                            {
                                if(row.loadPointsList[row.loadPointsList.length-1].latestTime!=="")
                                {
                                     deliverydate = moment(row.loadPointsList[row.loadPointsList.length-1].latestTime).format('MM-DD-YYYY HH:mm');
                                }
                            }
                        }

                        var pickUpLocation = "";
                        if(row.orginCityName!==null && row.orginStateCode!==null)
                        {
                             pickUpLocation = row.orginCityName+', '+row.orginStateCode
                        }
                        else if(row.orginCityName!==null)
                        {
                             pickUpLocation = row.orginCityName
                        }else if(row.orginStateCode!==null)
                        {
                             pickUpLocation =  row.orginStateCode
                        }


                        var DeliveryLocation = "";
                        if(row.destinationCityName!==null && row.destinationStateCode!==null)
                        {
                             DeliveryLocation = row.destinationCityName+', '+row.destinationStateCode
                        }
                        else if(row.destinationCityName!==null)
                        {
                             DeliveryLocation = row.destinationCityName
                        }else if(row.destinationStateCode!==null)
                        {
                             DeliveryLocation =  row.destinationStateCode
                        }

                        if(row.brokerName==="FreightCo Brokerage")
                        {
                            var brokerName = "Freightco"
                        }else{
                             brokerName = row.brokerName
                        }


                        return({
                            id:row.loadId,
                            loadNumber:row.customerLoadNumber,
                            loadNumberLink:row.customerLoadNumber,
                            stringLoadDate:stringLoadDate,
                            pickUpdate:pickUpdate,
                            shipperName:row.shipperName,
                            customerName:row.customerName,
                            carrierName:row.carrierName,
                            brokerName:"Freightco",
                            consigneeName:row.consigneeName,
                            consigneeAddress:((row.consigneeAddress1===null?'':row.consigneeAddress1) + " " + (row.consigneeAddress2===null?'':row.consigneeAddress2)),
                            weight:row.weight,
                            originCityName:row.orginCityName,
                            destinationCityName:row.destinationCityName,
                            commodity:row.commodity,
                            equipmentType:row.truckTypeName,
                            action:<a href="/#" className="btn_carrier_move btn btn-sm btn-primary">Status</a>,
                            loadPointsList:row.loadPointsList,
                            basecharge:row.baseCharges,
                            fulecharge:row.fuelCharges,
                            accessiorialcharge:row.accessorialCharges,
                            totalrevenue:row.totalRevenue,
                            loadedmiles:row.loadedMiles,
                            emptymiles:row.emptyMiles,
                            totalmiles:row.totalMiles,
                            driverpay:row.driverPay,
                            tenderprice:row.shipperLoadPrice,
                            originState:row.orginStateName,
                            destinationState:row.destinationStateName,
                            shipperref:row.shipperReferenceNumber,
                            assetName:row.assetName,
                            driverName:drivername,
                            distance:row.distance,
                            location:row.assetLocation,
                            pickUpLocation:pickUpLocation,
                            DeliveryLocation:DeliveryLocation,
                            deliverydate:deliverydate,
                            status:row.truckStatus,
                            icon:<a href="/#" onClick={(e) => {this.handleVehicleData(row.loadId)}}><i  data-toggle="modal" data-target=".lanehistorymodal" id="mapiconClass"  className="icofont-map-pins"></i></a>,
                            // <a className="icon_truck_location btn pc-mailer-tooltip" ><img src={"/images/mapicon.png"}/></a>
                        });
                    }
                );
            }
            else{
                delivered = [];
                indicationhtml="No Data Found";
            }
        }
       }  
        const { SearchBar } = Search;
        const columns=[
            {
				text:'Customer Load #',
				dataField:'loadNumberLink',
				sort:true,
			},	
			{
				text:'Carrier',
				sort:true,
				dataField:'brokerName'
			},  		
			{
				text:'Pickup Date / Time',
				sort:true,
				dataField:'pickUpdate'
			},
			// {
			// 	text:'Time',
			// 	sort:true,
			// 	dataField:'pickUpTime'
			// },
			{
				text:'Pickup Location',
				dataField:'pickUpLocation',
				sort:true
			},	
			{
				text:'Delivery Date / Time',
				sort:true,
				dataField:'deliverydate'
			},
			// {
			// 	text:'Time',
			// 	sort:true,
			// 	dataField:'deliveryTime'
			// },		
			{
				text:'Delivery Location',
				dataField:'DeliveryLocation',
				sort:true
			},
			// {
			// 	text:'Current Location',
			// 	dataField:'location',
			// 	sort:true
			// }, 
			{
				text:'History',
                dataField:'icon'
            }
            // ,
			// {
			// 	text:'Status',
			// 	dataField:'status',
			// 	sort:true
			// }
         ];
	
      const customTotal = (from, to, size) => (
       <span className="react-bootstrap-table-pagination-total">
         Showing { from } to { to } of { size } Results
       </span>
     );
     
     const options = {
       paginationSize: 4,
       pageStartIndex: 1,      
       firstPageText: 'First',
       prePageText: 'Back',
       nextPageText: 'Next',
       lastPageText: 'Last',
       nextPageTitle: 'First page',
       prePageTitle: 'Pre page',
       firstPageTitle: 'Next page',
       lastPageTitle: 'Last page',
       showTotal: true,
       paginationTotalRenderer: customTotal,
     };
        return(
                <div className="table-data border">
                   {/* <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">DELIVERED LOADS</a>
                        </li>                                 
                    </ul> */}
                    <div className="tab-content" id="myTabContent">
                  	    <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">                                      
                       
							<div className="row">                 
								<div className="col col-md-12">
									<article className="table-data truck-data shadow bg-white rounded">
									 <ToolkitProvider
										keyField="id"
										data={ delivered }
										columns={ columns }
										search
									 >
										{
										props => (
											<div>
                                            <div className="SearchDispatched"><SearchBar { ...props.searchProps } /></div>
											<BootstrapTable
												{ ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={indicationhtml}  hover bordered={ false }
											/>
											</div>
										)
										}
							    	</ToolkitProvider>
                                    <Modal show={this.state.showModal} onHide={this.close} dialogClassName="modal-90w modal-map">
                                        <Modal.Header closeButton>
                                            <Modal.Title className="w-100">Truck Location
                                                <span className="h6 float-right pt-1 pl-2">Delivery Date : {this.state.deliveryDate}</span>
                                                <span className="h6 float-right pt-1 pl-2">Pickup Date : {this.state.pickupDate}</span>
                                                <span className="h6 float-right pt-1">Customer Load # : {this.state.LoadNumber}</span>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body >
                                            {this.showBreadcrump()}
                                            {/* <div id="map" className="map-container"></div> */}
                                        </Modal.Body>								
                                    </Modal>
									</article>
								</div>
							</div>
                    </div>                   
                </div>
            </div> 
        )
    }
}  

const mapStateToProps = state => {
	return {
		data: state.loadReducer.data,
	    loading: state.loadReducer.loading,
		error: state.loadReducer.error,
	}
}
export default connect(mapStateToProps)(Dispatched);