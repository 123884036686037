import React, { Component } from 'react';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../helpers/FormControls';
import { assetVerifyService } from '../../../services/assetService';
import { toastr } from 'react-redux-toastr';

class ViewTruck extends Component{  
    state={
		verifyResponse : "",
		truckno : "",
		assetName:"",
		Asset_Type:"",
		device_id:"",
		make:"",
		model:"",                                                
		model_Year:"",                                                
		license_Plate_No:"",
		licenseState:"",                                              
		identification_No:"",                                              
		color:"",                                            
		axles:"",                                             
		weight:"",                                           
		tankCapacity1:"",                                            
		tankCapacity2:"",  
		description:"", 
		manufacturer:"",
		size:"",
		eldProviderDisplayName:""
} 
	
	verifyThisProfile =()=>
    {
	  var assetId = this.props.location.aboutProps.id	 
	  let param = {
		  payload:assetId
	  }
	  assetVerifyService(param).then((response) => {            
		  if(response){                
			  this.setState({
				  ...this.state,
				  verifyResponse:response
			  });
		  }
	  });
	    // var userType = localStorage.getItem('userType');
		// var url = "/"+userType+"/trucks";
		// this.props.history.push(url);
	}

	componentDidMount = () =>
	{
		if(this.props.location.aboutProps)
		{
			this.setState({
				...this.state,
				...this.props.location.aboutProps
			})
		}
		
	}
	
	componentDidUpdate = () =>{  
        if(this.state.verifyResponse.message==="Asset Verified Successfully"){  
			
			toastr.success('Success', 'Asset Verified Successfully!');
			var userType = localStorage.getItem('userType');
			var url = "/"+userType+"/trucks";
			this.props.history.push(url);
        }
    }

    render(){
		this.props.initialize(this.state);
        return(
			<form className="tg-forms"> 
                <div className="table-data border">
                    <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">View Truck</a>
                        </li>  
                    </ul>
                    <div className="tab-content" id="myTabContent">
                  	    <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">
							<div className="row">                 
								<div className="col col-md-12">
									<h5>TRUCK INFO</h5>
									<article className="table-data truck-data shadow bg-white rounded">
										<div className="row">									
											<div className="col col-md-3">
												<div className="form-group">
												<Field type="text" name="truckno" component={renderField} label="Truck/Asset No"/>                                
												</div>
												<div className="form-group">
												<Field type="text" className="form-control" name="assetName" component={renderField} label="Asset Name" />
												</div>
												<div className="form-group">
												<Field type="text" name="make" component={renderField} label="Make"/> 
												</div>
											</div>
											<div className="col col-md-3">
											<div className="form-group">
												<Field type="text" name="license_Plate_No" component={renderField} label="License Plate No"/>                               
												</div>
												<div>
												<Field type="text" name="Asset_Type" component={renderField} label="Asset Type"/>
												</div>
												<div className="form-group">
												<Field type="text" name="model" component={renderField} label="Model"/>
												</div>
											</div>
											<div className="col col-md-3">
											<div className="form-group">
												<Field type="text" name="identification_No" component={renderField} label="VIN"/>
												</div>
												<div>
												<Field type="text" name="licenseState" component={renderField}  label="License State" />
												</div>
												{/* <div>
												<Field type="text" name="asset_status" component={renderField} label="Asset Status"/>                               
												</div> */}
												<div className="form-group">
												<Field type="text" name="model_Year" component={renderField} label="Year"/>
												</div>
											</div>  
											<div className="col col-md-3">
												<div  className="form-group">
												<Field type="text" name="device_id" component={renderField}  label="Device ID" />
												</div>
											    <div className="form-group">
												<Field type="text" name="eldProviderDisplayName" component={renderField}  label="ELD Provider"/>
												</div>
												<div className="form-group">
												<Field type="text" name="color" component={renderField} label="Color"/>                                 
												</div>
											</div>                             
										</div>


										<div className="row">									
											<div className="col col-md-3">
													<div className="form-group">
													<Field type="text" className="form-control" name="manufacturer" component={renderField} label="Manufacturer" />                          
												</div>
											
															 
											</div>
											<div className="col col-md-3">
													<div className="form-group">
                                                                     <Field type="text" className="form-control" name="axles" component={renderField} label="Axles" />                         
                                                                 </div>
											
											</div>  
											<div className="col col-md-3">
														<div className="form-group">
                                                                     <Field type="text" className="form-control" name="size" component={renderField} label="Size" />                                                        
                                                                 </div>
											</div>  											                         
										</div>											
									</article>
								</div>
							</div>
                    </div>                   
                </div>
            </div> 
			</form>
        )
    }
}  

const ShowTheLocationWithRouter = withRouter(ViewTruck);
export default reduxForm({
    form: 'viewTrucks', 
     enableReinitialize: false,
})(ShowTheLocationWithRouter);