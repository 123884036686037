import React, { Component, Fragment } from 'react';
import CarrierList from './CarrierList';
import CarrierNew from './CarrierNew';
import EditCarrier from './EditCarrier';

class CarriersShipper extends Component{
	state={
		carrierslist : [
            {Carriername:'arun',Phone:'897865443322',email:'arun@gmail.com',city:'Chennai',zipcode:'6454545',state:'Tamilnadu',speciality:'good',id:1},
     		{Carriername:'pradeesh',Phone:'897865443322',email:'pradeesh@gmail.com',city:'Chennai',zipcode:'6454545',state:'Tamilnadu',speciality:'good',id:2},
     		{Carriername:'buvan',Phone:'897865443322',email:'Buvan@gmail.com',city:'Chennai',zipcode:'6454545',state:'Tamilnadu',speciality:'good',id:3},
        ]
	}
	componentDidMount() {
		document.title = 'Brokers';
	}

	render(){
		return(
			<Fragment>
				<CarrierList carrierslist={ this.state.carrierslist } />
				<CarrierNew statelist={this.props.statelist} country={this.props.country} specialitytypelist={this.props.specialitytypelist} eldproviderlist={this.props.eldproviderlist}/>
                <EditCarrier/>
			</Fragment>
		)
	}
}

export default CarriersShipper