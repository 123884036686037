import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { Field, reduxForm,initialize } from 'redux-form';
import { getcitybystate, getzipbycity } from '../../../actions/loadActions';
import { adduserAction } from '../../../actions/userActions';
import { renderField, renderSelect } from '../../../helpers/FormControls';
import { validate } from './validation';
import normalizePhone from '../../../helpers/normalizePhone';

function submit(values) {	
  const formObject={'userTypeId':'2','firstName':'','middleName':'','lastName':'','emailId':'','currentAddress1':'','currentAddress2':'','currentAddress3':'',
                     'current_City':'','currentStateId':'','CurrentZip':'','currentCountryId':'','permenantAddress1':'','permenantAddress2':'','permenantAddress2':'',
                     'permenantAddress3':'','permenantCity':'','permanentStateId':'','permanentZip':'','permanentCountryId':'','companyName':'','phoneCountryCode':''
                     ,'phoneNumber':'','altPhoneCountryCode':'','altPhoneNumber':'','faxCountryCode':'','faxNumber':'' };
  var formData = _.extend({}, formObject, values);
  this.props.dispatch(adduserAction(formData));
}

class AddBroker extends Component{
   stateOnchange=(event)=>
    {
        this.props.dispatch(getcitybystate(event.target.value));
    }

    cityOnchange=(event)=>
    {
        this.props.dispatch(getzipbycity(event.target.value));
    }

    handleClick = () =>{
      this.props.dispatch(initialize('addbrokerValidation'));
  }
  
render(){
    const { handleSubmit, pristine, reset, submitting } = this.props
    
  
return(
        <div className="modal fade addbroker" tabIndex="-1" data-backdrop="static" data-keyboard="false" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                 <div className="modal-content">     
                   <div className="modal-header">
                    <h5 className="modal-title doc-color" id="exampleModalLabel">ADD BROKER</h5>
                    <button type="button" onClick={this.handleClick} className="close" id="brokerAdd" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                   </div>
                    <div className="modal-body">
                              <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                                 <li className="nav-item">
                                    <a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">ADD BROKER</a>
                                 </li>                                                                 
                              </ul>
                                 <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">                                      
                                        <form className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}>                                            
                                             <div className="row">                 
                                                <div className="col col-md-12">
                                                <article className="table-data truck-data shadow bg-white rounded">
                                                         <div className="row">
                                                            <div className="col col-md-4">
                                                               <div className="form-group">
                                                                    <Field type="text" name="companyName"  controlClass="requiredfield"  component={renderField} label="Company"/>
                                                               </div>
                                                               <div className="form-group">
                                                               <Field type="text" className="form-control"  controlClass="requiredfield" normalize={normalizePhone} component={renderField} name="phoneNumber" id="primary_phone" label="Primary Phone" />                                                                    
                                                               </div>
                                                               <div className="form-group">
                                                                    <Field type="text" controlClass="requiredfield" name="currentStateId" onChange={this.stateOnchange} component={renderSelect} className="form-control" label="State" data={this.props.state.data}/>                        
                                                               </div>
                                                            </div>
                                                            <div className="col col-md-4">
                                                               <div className="form-group">
                                                                    <Field type="text"  controlClass="requiredfield"  name="emailId" component={renderField} label="Email"/>                                  
                                                               </div>
                                                               <div className="form-group">
                                                                    <Field type="text" name="currentAddress1" component={renderField} label="Address"/>
                                                               </div>
                                                               <div className="form-group">
                                                                    <Field type="text" name="current_City" data={this.props.CityVal}  onChange={this.cityOnchange} keyColumn='cityId' keyValue='cityName' component={renderSelect} label="City"/>    
                                                               </div>
                                                            </div>
                                                            <div className="col col-md-4">
                                                               <div className="form-group">
                                                                    <Field type="text" name="speciality" component={renderSelect} className="form-control" label="Speciality" data={this.props.speciality.data}/>                           
                                                               </div>
                                                               <div className="form-group">
                                                                    <Field type="text" name="website" component={renderField} className="form-control" label="Website"/>                                
                                                               </div>
                                                               <div className="form-group">
                                                                    <Field type="text" name="CurrentZip" data={this.props.ZipVal} keyColumn='zipId' keyValue='zipValue' component={renderSelect} className="form-control" label="Zip Code"/>  
                                                               </div>
                                                            </div>                           
                                                         </div>                                                           
                                                         </article>
                                                               <div className="row">
                                                               <div className="col col-md-12">
                                                                  <div className="form-group form-group-btns">
                                                                  <button type="submit" className="btn btn-secondary btn-sm">Save</button>&nbsp;
                                                                  <button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-primary btn-sm">Reset</button>

                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </form>
                                                </div>
                                             </div>
                                          </div>                              
                                       </div>
                                    </div>
                                 </div>
         )
      }
   }

const mapStateToProps = state => {
  return {
    data: state.userReducer.data,
    loading: state.userReducer.loading,
    error: state.userReducer.error,
    CityVal:state.loadReducer.CityVal,
    ZipVal:state.loadReducer.ZipVal
  }
}


export default connect(mapStateToProps)(reduxForm({form: 'addbrokerValidation',validate })(AddBroker))