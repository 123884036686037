// component's object.
const components = {
	shippers: {
		component: 'Shippers',
		url: '/shippers',
		title: 'Shippers',
		icon: 'menu',
		module: 1
	},
	carriers: {
		component: 'Carriers',
		url: '/carriers',
		title: 'Carriers',
		icon: 'menu',
		module: 1
	},
	brokers: {
		component: 'Brokers',
		url: '/brokers',
		title: 'Brokers',
		icon: 'menu',
		module: 1
	},
	drivers: {
		component: 'Drivers',
		url: '/drivers',
		title: 'Drivers',
		icon: 'menu',
		module: 1
	},
	dashboard: {
		component: 'Dashboard',
		url: '/dashboard',
		title: 'Dashboard',
		icon: 'menu',
		module: 1
	},
	loads: {
		component: 'Loads',
		url: '/loads',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	assets: {
		component: 'Assets',
		url: '/assets',
		title: 'Assets',
		icon: 'menu',
		module: 1
	},
	contacts: {
		component: 'Contacts',
		url: '/contacts',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	documents: {
		component: 'Documents',
		url: '/documents',
		title: 'Documents',
		icon: 'menu',
		module: 1
	},
	hos: {
		component: 'Hos',
		url: '/hours-of-services',
		title: 'Hos',
		icon: 'menu',
		module: 1
	},
	providers: {
		component: 'Providers',
		url: '/providers',
		title: 'Providers',
		icon: 'menu',
		module: 1
	},
	shipperloads: {
		component: 'ShipperLoads',
		url: '/loads',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	shipperaddload: {
        component: 'ShipperAddLoad',
        url: '/addLoad',
        title: 'addLoad',
        icon: 'menu',
        module: 1
    },
	shipperbrokers: {
		component: 'ShipperBrokers',
		url: '/brokers',
		title: 'brokers',
		icon: 'menu',
		module: 1
	},
	shipperdocuments: {
		component: 'ShipperDocuments',
		url: '/documents',
		title: 'documents',
		icon: 'menu',
		module: 1
		},
	shipperadddocument: {
		component: 'ShipperAddDocument',
		url: '/adddocument',
		title: 'Adddocumentpage',
		icon: 'menu',
		module: 1
	},
	shippercarriers: {
		component: 'ShipperCarriers',
		url: '/carriers',
		title: 'Carriers',
		icon: 'menu',
		module: 1
	},
	shippertrucks: {
		component: 'ShipperTrucks',
		url: '/trucks',
		title: 'trucks',
		icon: 'menu',
		module: 1
	},
	shippercontacts: {
		component: 'ShipperContacts',
		url: '/contacts',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	carrierloads: {
		component: 'CarrierLoads',
		url: '/loads',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	carrierdrivers:{
		component: 'CarrierDrivers',
		url: '/carrier-drivers',
		title: 'CarrierDrivers',
		icon: 'menu',
		module: 1
	},
	quickdispatch:{
		component: 'QuickDispatch',
		url: '/loads/quickdispatch',
		title: 'CarrierLoadsQuickDispatch',
		icon: 'menu',
		module: 1
	},
	carriertrucks: {
		component: 'CarrierTrucks',
		url: '/trucks',
		title: 'Trucks',
		icon: 'menu',
		module: 1
	},
	carriertrailers: {
		component: 'CarrierTrailers',
		url: '/trailers',
		title: 'Trailers',
		icon: 'menu',
		module: 1
	},
	carriercontacts: {
		component: 'CarrierContacts',
		url: '/contacts',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	carrierdocuments: {
		component: 'CarrierDocuments',
		url: '/documents',
		title: 'Documents',
		icon: 'menu',
		module: 1
	},
	carrieradddocuments: {
		component: 'CarrierAddDocuments',
		url: '/documents/add/:type',
		title: 'Add Documents',
		icon: 'menu',
		module: 1
	},
	carrierviewdrivers: {
        component: 'CarrierViewDrivers',
        url: '/viewdriver',
        title: 'View Driver',
        icon: 'menu',
        module: 1
    },  
    carrierviewtrucks: {
        component: 'CarrierViewTrucks',
        url: '/viewtruck',
        title: 'View Truck',
        icon: 'menu',
        module: 1
    },    
    carrierviewtrailers: {
        component: 'CarrierViewTrailers',
        url: '/viewtrailer',
        title: 'View Trailer',
        icon: 'menu',
        module: 1
    },   
	brokertrucks: {
		component: 'BrokerTrucks',
		url: '/trucks',
		title: 'trucks',
		icon: 'menu',
		module: 1
	},
	brokercarriers: {
		component: 'BrokerCarriers',
		url: '/carriers',
		title: 'Carriers',
		icon: 'menu',
		module: 1
	},
	brokerloads: {
		component: 'BrokerLoads',
		url: '/loads',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	brokerdocuments: {
		component: 'BrokerDocuments',
		url: '/documents',
		title: 'documents',
		icon: 'menu',
		module: 1
	},
	brokercontacts: {
		component: 'BrokerContacts',
		url: '/contacts',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	brokeradddocument: {
		component: 'AddDocument',
		url: '/adddocument',
		title: 'Adddocumentpage',
		icon: 'menu',
		module: 1
	},
	brokerloadreport: {
		component: 'BrokerLoadReport',
		url: '/report',
		title: 'Report',
		icon: 'menu',
		module: 1
	},
	brokertrailers: {
        component: 'BrokerTrailers',
        url: '/trailers',
        title: 'BrokerTrailers',
        icon: 'menu',
        module: 1
	},
	brokerviewtrailers: {
        component: 'BrokerViewTrailers',
        url: '/viewtrailer',
        title: 'View Trailer',
        icon: 'menu',
        module: 1
    },
	pagenotfound: {
		component: 'CarrierLoads',
		url: '/pagenotfound',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
};

// modules for grouping.
const modules = {
	0: {
		title: 'Dashboard',
		icon: 'home',
		isExpendable: true
	}
};

// component's access to roles.
const rolesConfig = {
	admin: {
		routes: [...Object.values(components)]
	},
	shipper: {
		routes: [
			components.dashboard,
			components.shipperloads,
			components.shipperaddload,
			components.assets,
			components.shipperbrokers,
			//components.carriers,
			//components.contacts,
			//components.documents,
			components.drivers,
			components.shippercarriers,
			components.shippercontacts,
			components.shipperdocuments,
			components.shipperadddocument,
			components.shippertrucks,
			components.pagenotfound,
		]
	},
	broker: {
		routes: [
			components.dashboard,
			//components.loads,
			components.assets,
			//components.contacts,
			//components.documents,
			//components.carriers,
			components.drivers,
			components.brokertrucks,
			components.brokercarriers,
			components.brokerloads,
			components.brokerdocuments,
			components.brokeradddocument,
			components.brokercontacts,
			components.brokerloadreport,
			components.brokertrailers,
			components.brokerviewtrailers,
			components.pagenotfound,
		]
	},
	carrier: {
		routes: [
			components.dashboard,
			components.carrierloads,
			components.carrierdrivers,
			components.assets,
			components.drivers,
			components.quickdispatch,
			components.hos,
			components.carriertrucks,
			components.carriertrailers,
			components.carriercontacts,
			components.carrierdocuments,
			components.carrieradddocuments,
			components.carrierviewdrivers, 
    		components.carrierviewtrucks,  
    		components.carrierviewtrailers, 
			components.pagenotfound
		]
	},
	driver: {
		routes: [
			components.drivers,
			components.documents,
			components.contacts,
			components.pagenotfound
		]
	},
	common: {
		routes: [
			{
				component: 'Home',
				url: '/',
				title: 'Home',
				icon: 'menu',
				module: 1
			},
			{
				component: 'Profile',
				url: '/profile',
				title: 'Profile',
				icon: 'menu',
				module: 1
			}
		]
	}
};

export { modules, rolesConfig };
