import React, { Component, Fragment } from 'react';
// import MainMenu from '../widgets/MainMenu';
import RoleMenu from './RoleMenu';
import AppUser from './AppUser';
import logo from '../../truckergig-logo.png';
//import logo from '../../TruckerCloud_white.png'
import { withRouter } from "react-router";
import { history } from '../../helpers/history';
import Header from '../Header/Header';
import { HeaderBar } from "UI-Component";
class TopBar extends Component{

	state={
		loggedUser:{
			companyId:localStorage.getItem('companyId'),
			userName:localStorage.getItem('userName'),
			userType:localStorage.getItem('userType'),
			FirstName:localStorage.getItem('FirstName')
		},
		userMenu:localStorage.getItem('userMenu'),
	}

	dashboardpage = (e) => {
		e.preventDefault();
		var userType = localStorage.getItem('userType');
		var url = "/"+userType+"/dashboard";
		history.push(url);
	}

	handleCallback = (childData) =>{
        this.setState({data: childData})
		console.log('topbar data from header',childData,this.state.data)
		this.props.parentCallbackFromTopBar(childData);

    }
	render(){
		const {pathname} = this.props.location;
		let urlArray=pathname.split("/").map(String);
		let menuList=[];
		if(pathname==="/broker/drivers")
		{
			menuList="";  
		}
		if(pathname==="/shipper/drivers")
		{
			menuList="";  
		}
		else if(urlArray[2]!=='carrier-drivers') 
		{
			menuList=this.state.userMenu;
		}
		else
		{
			menuList="";  
		}		 
		return(
			<Fragment>
				<header>
					<nav className="navbar navbar-expand-lg navbar-light bg-light">
					<HeaderBar passingData={logo} onClick = {(e) =>{this.dashboardpage(e)}}/>
					  	<div className="collapse navbar-collapse" id="navbarSupportedContent">
					  		<RoleMenu />
						    <AppUser loggedUser={this.state.loggedUser}/>
					  	</div>
					</nav>		
				</header>
				<Header userMenu={menuList} userType={this.state.loggedUser.userType} parentCallback = {this.handleCallback}/>
				{/* <MainMenu userMenu={menuList} userType={this.state.loggedUser.userType}/> */}
			</Fragment>
		)
	}

}

export default withRouter(TopBar)