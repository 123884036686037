export const validate = values => {
    const errors = {}
    //for add
    if (!values.assetNumber) {
        errors.assetNumber = 'Asset # can not be left blank.!'
    }    
    if (!values.licensePlateNo) {
        errors.licensePlateNo = 'Plate # can not be left blank.!'
    }    
    if (!values.deviceId) {
        errors.deviceId = 'Device Serial can not be left blank.!'
    }
    //for edit
    if (!values.asset_Number) {
        errors.asset_Number = 'Trailer # can not be left blank.!'
    }    
    if (!values.license_Plate_No) {
        errors.license_Plate_No = 'Plate # can not be left blank.!'
    }    
    if (!values.device_id) {
        errors.device_id = 'Device Serial can not be left blank.!'
    }

    
    return errors
}
