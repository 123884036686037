import React, { Component } from 'react';
import TopBar from '../widgets/TopBar';

export default class PageNotFound extends Component {
    render() {
        return (
            <article className="table-data truck-data shadow bg-white rounded">
                <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <h4 className="text-center">Oops! Page Not Found.</h4>
                        <div className="text-center">
                            It looks like you are looking for a page that is not recognized by Truckercloud.
                            Maybe try one of the links in above menu or press back to navigate previous page.
                        </div>
                    </div>
                    <div className="col-md-2">
                    </div>
                </div>
            </article>
        );
    }
}