import React, { Component, Fragment } from 'react';
import ContactsList from './ContactsList';


class Contacts extends Component{
	state={
		contactList : [
            {isChecked:false,name:'Bulldozer',company:'FedEx',phone:'9451239087',email:'fedex@gmail.com',notes:'van',action:'',id:1},
            {isChecked:false,name:'Beast',company:'FedEx Freight',phone:'9412131234',email:'beast@gmail.com',notes:'van',action:'',id:2} 
        ],
        allChecked:true
	}
	componentDidMount() {
		document.title = 'Contacts';
	}
	render(){
		return(
			<Fragment>
				<ContactsList contactList={ this.state.contactList } />
			</Fragment>
		)
	}
}

export default Contacts