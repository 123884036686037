import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { shipperandlanedetails } from '../../../actions/loadActions';
import { STATUS } from '../../../helpers/status';
import LoadList from './LoadList';

class Loads extends Component{
	constructor(props){
		super(props);
		this.dropstate=false;
		this.myload = [];
		this.assigned = [];
		this.tendered = [];
		this.active = [];
		this.completed = [];
		this.available = [];
		this.currentStatus = STATUS.LOAD.MYLOADS;
		this.contactLists=[];
	}
		
	componentDidMount() {
		document.title = 'Loads';
		this.props.dispatch(shipperandlanedetails());
	}
	render(){
		if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}
		return(
			<Fragment>	
				<LoadList 					
					loading={this.props.loading}
					state={this.props.statelist} loadpoint={this.props.loadpoint} trucktypelist={this.props.trucktypelist} shippernameAndLane={this.props.ShipperNameAndLaneDetails}
				/>			
			</Fragment>		
		)
	}
}
const mapStateToProps = state => {
	return {		
		data: state.loadReducer.data,
		ShipperNameAndLaneDetails:state.loadReducer.Shipperandlanedetails.companyTypeList,
	    loading: state.loadReducer.loading,
		error: state.loadReducer.error
	}
}

export default connect(mapStateToProps)(Loads)