import React, { Component, Fragment } from 'react';
import DocumentsSearch from './DocumentsSearch';
import DocumentsList from './DocumentsList';
import {docList} from '../../../actions/documentActions';
import {connect} from 'react-redux';
class Documents extends Component{
	componentDidMount() {
		document.title = 'Documents';
	}

	render(){
		return(
			<Fragment>
				<DocumentsSearch/>
				<DocumentsList loadlist={this.props.loadlist} documenttypelist={this.props.documenttypelist}/>
			</Fragment>
		)
	}
}
const mapStateToProps = state => ({
    data: state.documentReducer.data,
    loading: state.documentReducer.loading,
    error: state.documentReducer.error
});
export default connect(mapStateToProps)(Documents)