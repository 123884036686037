import React, { Component } from 'react';
import { connect } from 'react-redux';
import DriverList from '../common/DriverList';
import './dashboardnew.css';

class DriverOnBoarding extends Component{
	
    render = () => {
        return(
            <article className="shadow bg-white rounded" id="onboardpage">
                <div className="row">
                    <div className="col">
                        <h3>Driver Onboarding</h3>
                        <DriverList companyTypeId={0} 
                        statelist={this.props.statelist} 
                        country={this.props.country} 
                        specialitytypelist={this.props.specialitytypelist} 
                        eldproviderlist={this.props.eldproviderlist} 
                        driver={this.props.drivertypelist}/>
                    </div>
                </div>
            </article>
        );
    }
}

const mapStateToProps = (state) => {
    return{}
}
export default connect(mapStateToProps)(DriverOnBoarding);