import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { renderSelect } from '../../../helpers/FormControls';
import { validate } from './loadReportsValidation';
import { DateTimePicker } from 'react-widgets'
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { tripRegAction } from '../../../actions/reportAction';

const renderDateTimePicker = ({ input: { onChange, value }, label, meta: { touched, error }, showTime }) => (
    <div className={` ${touched && error ? 'error' : ''}`}>
        <DateTimePicker
            dropDown={true}
            onChange={onChange}
            className="requiredfield"
            format="MM-DD-YYYY HH:mm"
            id="dateValue"
            time={true}
            value={!value ? null : new Date(value)}
            max={new Date()}
        />
        {touched && error && <span className="error">{error}</span>}
    </div>
);


function submit(values) {
    const frmdata = {};
    var date = (values.fromDate) ? (moment(values.fromDate).format('YYYY-MM-DD HH:mm:ss')) : ("");
    frmdata.date= date
    frmdata.status=(values.loadStatus) ? (values.loadStatus) : ("");
    this.props.dispatch(tripRegAction(frmdata));    
 }


class tripRegistration extends Component {
   
    state = {
        loadStatus:{
            data:[
                {id:"Success",name:"Success"},
                {id:"Failed",name:"Failed"}
            ]
        }
    };
    componentDidMount() {		
        document.title = 'Trip-Reports';
    }
    
    render() {
       // console.log(this.props)
        var indicationhtml = "No Data Found"
        if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
			window.location = '/login'
		}        
        const { handleSubmit } = this.props;	
        var LoadReports=[];
		
            if(this.props.loading){
                indicationhtml = 
                <div className="spinner-border" role="status">
                    <div className="row react-bs-table-no-data"></div>
                    </div>
            }else
            {
            if(this.props.data.tripRegistrationList !== undefined)
            {
                if(this.props.data.tripRegistrationList.length > 0)
                {
                    LoadReports = this.props.data.tripRegistrationList.map(
                        (row,index) =>{   
                            return({
                                id:index,
                                loadNumber:row.loadNumber,
                                stringLoadDate:row.loadDate,
                                registrationDate:row.registrationDate,
                                responseCode:row.responseCode,
                                reason:row.reason,
                            });
                        }
                    );
                }
                else{
                    LoadReports = [];
                    indicationhtml="No Data Found";
                }
            }
           } 
        const { SearchBar } = Search;        
       
		const columns=[	
        // {
        //     text:'Load Id',
        //     dataField:'id',
        //     sort:true
        // },
		{
			text:'Load #',
			dataField:'loadNumber',
			sort:true
		},
		{
			text:'Load Date',
			dataField:'stringLoadDate',
			sort:true
        },
        {
			text:'Reg Date',
			dataField:'registrationDate',
			sort:true
		},
		{
			text:'Response Code',
			dataField:'responseCode',
			sort:true
		},
		{
			text:'Reason',
			dataField:'reason',
			sort:true
		}
	   ]
       
        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
              Showing { from } to { to } of { size } Results
            </span>
          );
          
          const options = {
            sizePerPage:10,
            paginationSize: 4,
            pageStartIndex: 1,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
          };
        return (
             <article className="table-data truck-data shadow bg-white rounded" style={{display:'block'}}>
                <h3>Trip Registration Summary</h3> <br></br>
                <form onSubmit={handleSubmit(submit.bind(this))}>
                    <div className="row">
                    <div className="form-group col col-lg-4">
                    <label>Date</label>
                    <Field
                        name="fromDate"
                        defaultValue={null}
                        component={renderDateTimePicker}
                        />
                    </div>
                    <div className="form-group col col-lg-3">
                    <Field type="text" name="loadStatus" controlClass="requiredfield" component={renderSelect} label="Status" data={this.state.loadStatus.data}/> 
                    </div>
                    <div className="form-group col col-lg-2">
                        <button type="submit" value="searchbtn" onClick={this.handleGoClick} className="btn btn-secondary searchbtn">Get</button>&nbsp;                        
                    </div>
                    </div>
                </form>
                <ToolkitProvider
                        keyField="id"
                        data={ LoadReports }
                        columns={ columns }
                        search
                        >
                        {
                        props => (
                            <div>
                             <SearchBar { ...props.searchProps } /> 
                            <BootstrapTable
                                { ...props.baseProps } pagination={ paginationFactory(options) } noDataIndication={indicationhtml} hover 
                                bordered={ false }
                            />
                            </div>
                        )
                        }
                    </ToolkitProvider>
            </article>
        )
    }
}

const mapStateToProps = state => {
    return {
        data : state.reportReducer.data,
        loading : state.reportReducer.loading,
        loadReportData : state.reportReducer.data,
    }
  }
  
  export default connect(mapStateToProps)(reduxForm({
      form:'tripReg',validate
  })(tripRegistration))