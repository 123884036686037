import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { assetupdate } from '../../../actions/assetAction';
import { renderField, renderSelect,renderSelectStringVal } from '../../../helpers/FormControls';
import { validate } from './validation';

function submit(values,params) {
	const frmdata = {};
	frmdata.assetId= (values.assetId) ? (values.assetId) : ("");
	frmdata.assetELDId=(values.assetELDId) ? (values.assetELDId) : ("");
    frmdata.assetNumber=(values.assetNumber) ? (values.assetNumber) : ("");
    frmdata.assetName=(values.assetName) ? (values.assetName) : ("");
    frmdata.assetType='truck';
    frmdata.eldProviderId=(values.eldProviderId) ? (values.eldProviderId) : ("");
    frmdata.licensePlateNo=(values.licensePlateNo) ? (values.licensePlateNo) : ("");
    frmdata.identificationNo=(values.identificationNo) ? (values.identificationNo) : ("");
    frmdata.licenseState=(values.licenseState) ? (values.licenseState) : ("");
    frmdata.manufacturer=(values.manufacturer) ? (values.manufacturer) : ("");
    frmdata.make=(values.make) ? (values.make) : ("");
    frmdata.model=(values.model) ? (values.model) : ("");
    frmdata.modelYear=(values.modelYear) ? (values.modelYear) : ("");
    frmdata.color=(values.color) ? (values.color) : ("");
    frmdata.assetSerialNo=(values.deviceId) ? (values.deviceId) : ("");
    frmdata.fuelType=(values.fuelType) ? (values.fuelType) : ("");  
    frmdata.tankCapacity1=(values.tankCapacity1) ? (values.tankCapacity1) : ("");
    frmdata.tankCapacity2=(values.tankCapacity2) ? (values.tankCapacity2) : ("");
    frmdata.size=(values.size) ? (values.size) : ("");
    frmdata.axles=(values.axles) ? (values.axles) : ("");
    frmdata.weight=(values.weight) ? (values.weight) : ("");
    frmdata.description=(values.description) ? (values.description) : ("");
    frmdata.isHeavyduty=(values.isHeavyduty) ? (values.isHeavyduty) : ("");
    frmdata.dataStatus=(values.dataStatus) ? (values.dataStatus) : ("");
    frmdata.isBulkUpload=(values.isBulkUpload) ? (values.isBulkUpload) : ("");
    frmdata.optInOut=(values.optInOut) ? (values.optInOut) : ("");
	this.props.dispatch(assetupdate(frmdata));
}
class EditTruckPopup extends Component{
	handleClick = () =>{    
		this.props.initialize(this.props.initialValues);
		}
render(){
    const { handleSubmit, pristine, reset, submitting } = this.props
	let minOffset = 0, maxOffset = 20;
	let thisYear = (new Date()).getFullYear();
	let allYears = [];
	for(let x = 0; x <= maxOffset; x++) {
		allYears.push(thisYear - x)
	}
	const yearList = allYears.map(x=>{       
		return{
			"id":x,
			"name":x,
		}
	}); 
return(
	<div className="modal fade" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
   <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
         <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">EDIT TRUCK</h5>
            <button type="button" onClick={this.handleClick} className="close" id="editfrm" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div className="modal-body table-data">
            <ul className="nav nav-tabs" id="myTrucks" role="tablist">
               <li className="nav-item">
                  <a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">EDIT TRUCK</a>
               </li>
            </ul>
            <div className="tab-content" id="myTabContent">
               <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">
                  <form className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}>
                     <div className="row">
                        <div className="col col-md-12">
                           <h5>TRUCK INFO</h5>
                           <article className="table-data truck-data shadow bg-white rounded">
                              <div className="row">
                                 <Field type="hidden" name="companyId" component={renderField} />
                                 <Field type="hidden" name="vehicleMileage" component={renderField} />
                                 <Field type="hidden" name="fuelType"component={renderField} />
                                 <Field type="hidden" name="weight" component={renderField} />
                                 <Field type="hidden" name="mileageUnit" component={renderField} />
                                 <div className="col col-md-4">
                                    <div className="form-group">
                                       <Field type="text" name="assetNumber" component={renderField} label="Truck #"/>
                                    </div>
                                    <div className="form-group">
                                       <Field type="text" className="form-control" name="modelYear" id="year" component={renderSelect} data={yearList} label="Year" />
                                    </div>
                                    <div className="form-group">
                                       <Field type="text" className="form-control" name="assetName" component={renderField} label="Asset Name" />
                                    </div>
                                 </div>
                                 <div className="col col-md-4">
                                    <div className="form-group">
                                       <Field type="text" name="make" component={renderField} label="Make"/>
                                    </div>
                                    <div className="form-group">
                                       <Field type="text" name="identificationNo" controlClass="requiredfield" component={renderField} label="VIN"/>
                                    </div>
                                    <div className="form-group">
                                       <Field type="text" name="color" component={renderField} label="Color"/>
                                    </div>
                                 </div>
                                 <div className="col col-md-4">
                                    <div className="form-group">
                                       <Field type="text" name="model" component={renderField} label="Model"/>
                                    </div>
                                    <div className="form-group">
                                       <Field type="text" name="licensePlateNo" controlClass="requiredfield" component={renderField} label="Plate #"/>
                                    </div>
                                    <div className="form-group">
                                       <Field type="text" className="form-control" name="licenseState" component={renderSelect} data={this.props.state.data} label="License State" />
                                    </div>
                                 </div>
                              </div>
                              <div className="row">
                                 <div className="col col-md-4">
                                    <div className="form-group">
                                       <Field type="text" className="form-control" name="manufacturer" component={renderField} label="Manufacturer" />
                                    </div>
                                    <div className="form-group">
                                       <Field type="text" className="form-control" name="tankCapacity1" component={renderField} label="Tank Capacity1" />
                                    </div>
                                    <div className="form-group">
                                       <Field type="text" className="form-control" name="tankCapacity2"  component={renderField} label="Tank Capacity2" />
                                    </div>
                                 </div>
                                 <div className="col col-md-4">
                                    <div className="form-group">
                                       <Field type="text" className="form-control" name="size" component={renderField} label="Size" />
                                    </div>
                                    <div className="form-group">
                                       <Field type="text" className="form-control" name="axles" component={renderField} label="Axles" />
                                    </div>
                                    <div className="form-group">
                                       <Field type="text" className="form-control" name="weight" component={renderField} label="Weight" />
                                    </div>
                                 </div>
                                 <div className="col col-md-4">
                                    <div className="form-group">
                                       <Field type="text" className="form-control" name="description" component={renderField} label="Description" />
                                    </div>
                                    <div className="form-group">
                                       <Field type="text" className="form-control" name="fuelType" component={renderSelectStringVal} data={this.props.fueltypelist.FuelTypeList} label="Fuel Type" />
                                    </div>
                                 </div>
                              </div>
                           </article>
                           <h5>ELD DETAILS</h5>
                           <article className="table-data truck-data shadow bg-white rounded">
                              <div className="row">
                                 <div className="col col-md-4">
                                    <div className="form-group">
                                       <Field type="text" name="eldProviderId" controlClass="requiredfield" component={renderSelect} className="form-control" label="ELD Provider" data={this.props.eldprovider.data} />
                                    </div>
                                 </div>
                                 <div className="col col-md-1">
                                    <div className="form-group ico_circle">
                                       <i className="icofont-plus-circle add_plus"></i>
                                    </div>
                                 </div>
                                 <div className="col col-md-4">
                                    <div className="form-group">
                                       <Field type="text" name="assetELDId" component={renderField} label="Device Serial #"/>
                                    </div>
                                 </div>
                              </div>
                           </article>
                           <div className="row">
                              <div className="col col-md-12">
                                 <div className="form-group form-group-btns">
                                    <button type="submit" className="btn btn-primary btn-sm">Save</button>&nbsp;&nbsp;
                                    <button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-primary btn-sm">Reset</button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
)
}
}
const PageOneForm = reduxForm({
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
  })(EditTruckPopup)


  const mapStateToProps = (state,ownProps) => {	
	return{
		  form: 'edittruckValidation',
		  message: state.assetReducer.data,
		  initialValues: ownProps.modalvalues,
		  validate,
		  enableReinitialize: true 
	}    
}
  
const PageOneFormContainer = connect(mapStateToProps)(PageOneForm)
  
 export default PageOneFormContainer