import React, { Component, Fragment } from 'react';
import DocumentsSearch from './DocumentsSearch';
import DocumentsList from './DocumentsList';

class Documents extends Component{
	state={
		documentList : [
            {load:'Bulldozer',document_title:'BOL',category:'CDL',owner:'fedrick',uploaded_details:'2019-08-23',notes:'',action:'',id:1} 
        ]
	}
	componentDidMount() {
		document.title = 'Documents';
	}

	render(){
		return(
			<Fragment>
				<DocumentsSearch/>
				<DocumentsList documentList={ this.state.documentList }/>
			</Fragment>
		)
	}
}

export default Documents