
import React from 'react';
import ReactTooltip from 'react-tooltip';
class InviteNewCarrierWithExistingDataComponent extends React.Component {
    render() {
        console.warn('has_no_carrier',this.props.state.has_no_carrier)
        return (
            <article className='table-data truck-data shadow bg-white rounded' style={{ display: 'block' }}>
                <form id='new_carrier' >
                    <div className='row'>
                        <div className='col form-group' data-tip data-for={'value' + this.props.state.carrierName}  >
                            <label >Carrier Name</label>
                            <input disabled={!this.props.state.carrierName.has_carrier === true ? true : false} type='text' className='form-control requiredfield json-col' placeholder='Carrier Name' name='carrierName' onChange={(e) => { this.props.set_carrierName(e) }} value={this.props.state.carrierName} />
                            <ReactTooltip id={'value' + this.props.state.carrierName}>
                                {this.props.state.carrierName}
                            </ReactTooltip>
                        </div>
                        <div className='col form-group'>
                            <label>MC#</label>
                            <input disabled={!this.props.state.carrierName.has_mcnumber === true ? true : false} type='text' className='form-control  json-col' placeholder='MC#' name='mcNumber' onChange={(e) => { this.props.set_mcNumber(e) }} value={this.props.state.mcNumber} />
                        </div>
                        <div className='col form-group'>
                            <label>DOT#</label>
                            <input disabled={!this.props.state.carrierName.has_dotnumber === true ? true : false} type='text' className={this.props.state.uuID !== '' ? 'form-control  json-col' : 'form-control requiredfield json-col'} placeholder='DOT#' name='dotNumber' onChange={(e) => { this.props.set_dotnumber(e) }} value={this.props.state.dotNumber} />
                        </div>
                        <div className='col form-group'>
                            <label>UUID#</label>
                            <input disabled={!this.props.state.carrierName.has_uuID === true ? true : false} type='text' className={this.props.state.uuID !== '' ? 'form-control json-col requiredfield' : 'form-control json-col'} placeholder='UUID#' name='uuidID' onChange={(e) => { this.props.set_uuID(e) }} value={this.props.state.uuID} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col form-group' data-tip data-for={'email' + this.props.state.email}>
                            <label>Carrier Email</label>
                            <input disabled={!this.props.state.email.has_email === true ? true : false} type='email' className='form-control requiredfield json-col' placeholder='Carrier Email' name='email' onChange={(e) => { this.props.set_email(e) }} value={this.props.state.email} />
                            <span className='shadow-input'>{this.props.state.emailError}</span>
                            <ReactTooltip id={'email' + this.props.state.email}>{this.props.state.email}</ReactTooltip>
                        </div>
                        <div className='col form-group' data-tip data-for={'firstname' + this.props.state.firstName}>
                            <label>First Name</label>
                            <input disabled={!this.props.state.email.has_firstname === true ? true : false} type='text' className='form-control requiredfield json-col' placeholder='First Name' name='firstName' onChange={(e) => { this.props.set_firstName(e) }} value={this.props.state.firstName} />
                            {this.props.state.firstName !== null ? <ReactTooltip id={'firstname' + this.props.state.firstName}>{this.props.state.firstName}</ReactTooltip> : ''}
                        </div>
                        <div className='col form-group' data-tip data-for={'lastname' + this.props.state.lastName}>
                            <label>Last Name</label>
                            <input disabled={!this.props.state.email.has_lastname === true ? true : false} type='text' className='form-control requiredfield json-col' placeholder='Last Name' name='lastName' onChange={(e) => { this.props.set_lastName(e) }} value={this.props.state.lastName} />
                            {this.props.state.lastName !== null ? <ReactTooltip id={'lastname' + this.props.state.lastName}>{this.props.state.lastName}</ReactTooltip> : ''}
                        </div>
                    </div>
                </form>
            </article>
        )
    }
}
export default InviteNewCarrierWithExistingDataComponent;
