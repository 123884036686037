import { authHeader } from '../helpers/authHeader';
import {checkUnauthorized} from '../helpers/appUtil';
require('dotenv').config();
export const loginUserService = (request) => {
	//const LOGIN_API_ENDPOINT = 'https://devtgapi.truckergig.com/api/v1/userresource/login';
	const LOGIN_API_ENDPOINT = process.env.REACT_APP_BASE_URL+'userresource/login';
	const parameters = {
	  	method: 'POST',
	  	headers: {
			'Content-Type': 'application/json'
	  	},
	  	body: JSON.stringify(request.user)
	};

	return fetch(LOGIN_API_ENDPOINT, parameters)
  	.then(response => {
		return response.json();
  	})
  	.then(json => {
		return json;
  	});
};

export const logoutUserService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
    const TRUCK_API_ENDPOINT = BASE_URL+'userwebresource/logout';  
    return fetch(TRUCK_API_ENDPOINT,{
        method: "POST",
        headers: authHeader(),
        body:JSON.stringify(request),
    })
    .then((response) => response.json())
    .then((responseData) => {
        console.log('auth response',responseData)
        //checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        return {};
    });
}

export const frgtpwdService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
   const TRUCK_API_ENDPOINT = BASE_URL+'userresource/savetoken?useremail='+request.payload.useremail;  
    return fetch(TRUCK_API_ENDPOINT,{
        method: "POST",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {    
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        return {};
    });
}


export const savenewpwdService = (request) => {
    const BASE_URL= process.env.REACT_APP_BASE_URL;
   const TRUCK_API_ENDPOINT = BASE_URL+'userresource/saveresetpassword?token='+request.payload.token+'&newpassword='+request.payload.newpassword;  
    return fetch(TRUCK_API_ENDPOINT,{
        method: "POST",
        headers: authHeader()
    })
    .then((response) => response.json())
    .then((responseData) => {    
        checkUnauthorized(responseData.status, responseData.message);           
        return responseData;
    })
    .catch(error => {
        return {};
    });
}