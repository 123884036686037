export const validate = values => {
    const errors = {}
    if (!values.shippers) {
        errors.shippers = 'Shippers can not be left blank.!'
    }
    if (!values.lane) {
        errors.lane = 'Select Lane can not be left blank.!'
    }
    if (!values.loadno) {
        errors.loadno = 'Load Number can not be left blank.!'
    } 
    if (!values.bol) {
        errors.bol = 'Bol # can not be left blank.!'
    }
    if (!values.street) {
        errors.street = 'Street can not be left blank.!'
    }
    if (!values.city) {
        errors.city = 'City can not be left blank.!'
    }
    if (!values.state) {
        errors.state = 'State can not be left blank.!'
    }
    if (!values.zip) {
        errors.zip = 'Zip can not be left blank.!'
    }
    if (!values.attachment) {
        errors.attachment = 'Choose file can not be left blank.!'
    }
    if (!values.asset_id) {
        errors.asset_id = 'Truck can not be left blank.!'
    }
    if (!values.Driver_id) {
        errors.Driver_id = 'Driver can not be left blank.!'
    }
    if (!values.Load_Rate) {
        errors.Load_Rate = 'LoadRate can not be left blank.!'
    }  
    return errors
}