import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { getDriverListByCompany, loadAction, quickDispatchAction,loadActionfrquickdispatch, quickdispatchFlagAction, truckNoAndLocation,canceledloadflag} from '../../../actions/loadActions';
import { STATUS } from '../../../helpers/status';
import {getMapboxKey} from '../../../helpers/appUtil';
import moment from 'moment';
require('dotenv').config();
class QuickDispatch extends Component{
    
    state = { loadRequested : [], truckList:[], driverList:[], showMap:false,disablepro:true,data:[] };

    componentDidMount = () => {
        mapboxgl.accessToken = getMapboxKey();
        //mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
        const params={
			...STATUS.LOAD.REQUESTED,
		}
		this.props.dispatch(loadActionfrquickdispatch(params));
        this.props.dispatch(getDriverListByCompany());
        this.props.dispatch(truckNoAndLocation())
    }

    selectLoad = (e, index) => {
        var loadRequested = this.state.loadRequested;
        loadRequested[index].loadSel = e.target.checked;
        this.setState({
            ...this.state,
            loadRequested : loadRequested
        });     
        this.props.tabClicked(e,this.state.loadRequested);
    }

    // selectTruck = (e, index) => {
    //     var truckList = this.state.truckList;
    //     truckList[index].selTruck = e.target.checked;
    //     this.setState({
    //         ...this.state,
    //         truckList : truckList
    //     });selectTruck
    // }

    openMap = (row) => {
        var Latitude = parseFloat(row.Latitude);
        var Longitude = parseFloat(row.Longitude);


        if(isNaN(Latitude) || isNaN(Longitude))
        {
            toastr.info('Info', 'Location not valid!');
            return;
        }
        else if(Latitude>90||Longitude<-90)
        {
            toastr.error('Error', 'Invalid Latitude Longitude');
            return;
        }
        this.setState({
            ...this.state,
            showMap:true
        });

        setTimeout(() => this.loadMap(row), 1000);
    }

    loadMap = (row) => {

        var el = document.querySelector("#map-canvas");
        if(el === null)
        {
            setTimeout(() => this.loadMap(row), 1000);
            return;
        }
        
        this.showRouteMap(row.Asset_Id.toString(),row.Asset_Number.toString(),row.Location.toString(),row.Latitude.toString(),row.Longitude.toString(),row.Speed.toString(), row.UTC_Date_Time.toString());
    }

    closeMap = () => {                
        this.setState({
            ...this.state,
            showMap:false
        });
    }

    driverListInput = (driverId, index) => {
        return(
            <select name="driverId" id={"driveridvalcarrier"+index} className="form-control" defaultValue={driverId} >
                <option value={index}>Select Driver</option>
                {
                    this.state.driverList.map(
                        (driver, index) => 
                         <option key={index} value={driver.User_Id}>{driver.User_Name.toUpperCase()}</option>
                        
                    )
                }                
            </select>
        );
    }

    componentDidUpdate = () => {
        if(this.props.quickdispatchflagAction===true)
        {
            if(this.props.quickDispatch.status === "ACCEPTED")
            {
                toastr.success('Success', 'Quick dispatch saved Successfully!'); 
                this.props.dispatch(quickdispatchFlagAction());
                // const params={
                //     ...STATUS.LOAD.REQUESTED
                // }               
                // this.props.dispatch(loadAction(params));                
                const params={
                    ...STATUS.LOAD.REQUESTED,
                }
                this.props.dispatch(loadActionfrquickdispatch(params));
                this.props.dispatch(truckNoAndLocation())
                //document.querySelector('input[name="truckid"]:checked').checked = false
                //const idval = "truckval"+index;
                //document.querySelector('input[name="carrierassignbtn"]').disabled = false;
                
                document.getElementsByClassName('assignbtn').disabled = true;
                this.setState({
                    ...this.state,
                    loadRequested:[]
                })
            }
            else if(typeof this.props.quickDispatch.status != "undefined")
            {
                toastr.error('error', 'Unable to save Quick dispatch!');
                this.props.dispatch(quickdispatchFlagAction());
                // const params={
                //     ...STATUS.LOAD.REQUESTED
                // }               
                // this.props.dispatch(loadAction(params));                
                const params={
                    ...STATUS.LOAD.REQUESTED,
                }
                this.props.dispatch(loadActionfrquickdispatch(params));
                this.props.dispatch(truckNoAndLocation())
                this.setState({
                    ...this.state,
                    loadRequested:[],
                })
            }
        }

        if(this.props.canceledloadflag===true)
		{
			if(this.props.data.message==="Loads Cancelled Sucessfully")
			{
				toastr.success('Success', 'Loads Cancelled Sucessfully!');
                this.props.dispatch(canceledloadflag());
                if(this.props.currentStatus.loadStatusId===1)
                {
                    const params={
                        ...STATUS.LOAD.REQUESTED
                    }
                    //this.props.dispatch(loadAction(params));
                    this.props.dispatch(loadActionfrquickdispatch(params));
                    this.setState({
                        ...this.state,
                        loadRequested:[]
                    })
                }else{
                    const params={
                        ...STATUS.LOAD.DELIVERY_CONFIRMED
                    }
                    this.props.dispatch(loadAction(params));
                    //this.props.dispatch(loadActionfrquickdispatch(params));
                    this.setState({
                        ...this.state,
                        loadRequested:[]
                    })
                }
		   }
	   }
    }
    componentWillUpdate  = (nextProps) => {
        if((nextProps.quickdispatchloads.quickloadlists))
        {
             if(this.state.loadRequested.length === 0)
            {
                var loadRequested = nextProps.quickdispatchloads.quickloadlists.LoadList.map(
                    row =>{
                        return({                            
                            loadSel:false,
                            id:row.loadId,
                            loadNumber:row.loadNumber,
                            stringLoadDate:row.stringLoadDate,
                            customerName:row.customerName,
                            consigneeName:row.consigneeName,
                            consigneeAddress:((row.consigneeAddress1===null?'':row.consigneeAddress1) + " " + (row.consigneeAddress2===null?'':row.consigneeAddress2)),
                            weight:row.weight,
                            originCity:row.originCity,
                            destinationCity:row.destinationCity,
                            commodity:row.commodity,
                            equipmentType:row.truckTypeName,							
                            loadPointsList:row.loadPointsList,
                            basecharge:row.baseCharges,
                            fulecharge:row.fuelCharges,
                            accessiorialcharge:row.accessorialCharges,
                            totalrevenue:row.totalRevenue,
                            loadedmiles:row.loadedMiles,
                            emptymiles:row.emptyMiles,
                            totalmiles:row.totalMiles,
                            driverpay:row.driverPay,
                            tenderprice:row.shipperLoadPrice,
                            originaddress:row.originAddress1,
                            destaddress:row.destinationAddress1,
                            distance:row.distance,
                            shipperName:row.shipperName,
                            brokerName:row.brokerName,
                            shipperref:row.shipperReferenceNumber,
                        });
                    }					
                );

                if(loadRequested.length > 0)
                {
                    this.setState({
                        ...this.state,
                        loadRequested : loadRequested
                    });
                }
            }
        }

        // console.log(nextProps)
        // //if(typeof nextProps.truckNoListAndLocation === "object")
        // if(Array.isArray(nextProps.truckNoListAndLocation))
        // {
            
        //     if(this.state.truckList.length == 0)
        //     {
        //         var truckList = nextProps.truckNoListAndLocation.map(                    
        //             (row, index) =>{           
                                 
                        // return({
                        //     Asset_Id:row.Asset_Id,
                        //     //License_Plate_No:row.License_Plate_No,
                        //     Asset_Number:row.Asset_Number,
                        //     Truck_Type_Name:row.Truck_Type_Name,
                        //     Make:row.Make,
                        //     Model_Year:row.Model_Year,
                        //     Model:row.Model,
                        //     Latitude:row.Latitude,
                        //     Longitude:row.Longitude,
                        //     selTruck:false,
                        //     selIndex:'',
                        //     selDriver:'',
                        //     selDriverMobNo:"",
                        //     Location:row.Location,
                        //     Speed:row.Speed,
                        //     UTC_Date_Time:row.UTC_Date_Time,
                        //     Location:row.Location,
        //                 });
        //             });

        //             if(truckList.length > 0)
        //             {
        //                 this.setState({
        //                     ...this.state,
        //                     truckList : truckList
        //                 });
        //             }
        //     }
        // }
        
        //if(typeof nextProps.DriverList === "object")
        if(Array.isArray(nextProps.DriverList))
        {
            if(this.state.driverList.length === 0)
            {
                var driverList = nextProps.DriverList.map(                    
                    (row, index) =>{
                       
                        var name = row.First_Name + "," + row.Last_Name
                        return({
                            User_Id:row.User_Id,
                            User_Name:name, 
                            Phone_Number:row.Phone_Number
                        });
                    });

                if(driverList.length > 0)
                {
                    this.setState({
                        ...this.state,
                        driverList : driverList
                    });
                }
            }
        }
        
    }
   

    showRouteMap(id,truckNo,location,Latitude,longitude,Speed,utctime){
		var el = document.querySelector("#map-canvas");

		if(el == null)
		{
			setTimeout(
				function() {
					this.showRouteMap(id,truckNo,location,Latitude,longitude,Speed,utctime);
				}
				.bind(this),
				1000
			);

			return;
		}

		if(el.clientHeight < 1)
		{
            var body = document.body,
            html = document.documentElement;
            var height = Math.min( body.scrollHeight, body.offsetHeight, 
            html.clientHeight, html.scrollHeight, html.offsetHeight );
            height -= 150;
            el.style.height = height+"px";
        }
        this.setState({
            ...this.state,
            data:[
                { 
                    "id":id,
                    "truckNo":truckNo,
                    "latitude":Latitude,
                    "longitude":longitude,
                    "speed":Speed,
                    "address":location,
                    "location":location,
                    "date":utctime
                }
            ]
        });
       
       // if(this.props.data.length>0){
            var features = this.state.data.map(data => {
                return(
                {
                    "type":"Feature",
                    "properties":{
                        "id":data.id,                        
                        "latitude":data.latitude,
                        "longitude":data.longitude,
                        "speed":data.speed,                        
                        "location":data.location,
                        "date":data.date,
                        "truckNo":data.truckNo,
                    },
                    "geometry":{
                        "type":"Point",
                        "coordinates":[
                            data.longitude,                            
                            data.latitude
                            
                        ]
                    }
                 }
                )
            }) 
		const map = new mapboxgl.Map({
			container : 'map-canvas', // container id
			style : 'mapbox://styles/mapbox/streets-v11', //stylesheet location
            center: [-96,37], // starting position
            zoom: 3.5 // st arting zoom
        });
        
        var geojsonData = {
            "type":"FeatureCollection",
            "features": features
        }
		
        map.on('load', function() {
            // Add a new source from our GeoJSON data and set the
            // 'cluster' option to true.
            map.addSource("earthquakes", {
                type: "geojson",
                // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
                // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
                data: geojsonData,
                cluster: true,
                clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterRadius: 40 // Radius of each cluster when clustering points (defaults to 50)
               
            });    
            map.loadImage('https://maps.google.com/mapfiles/ms/icons/red.png', function(error, image) {
                if (error) throw error;
                map.addImage('cat', image);
            });       
            map.addLayer({
                "id": "unclustered-points",
                "type": "symbol",
               "source": "earthquakes",
                "layout": {
                        "icon-image": "cat",
                        "icon-size": 1
                },
                "properties":{
                    iconAllowOverlap :false
                }
            });
            // Display the earthquake data in three layers, each filtered to a range of
            // count values. Each range gets a different fill color.
            var layers = [
                [20, '#f28cb1'],
                [10, '#f1f075'],
                [0, '#51bbd6']
            ];    
            layers.forEach(function (layer, i) {
                map.addLayer({
                    "id": "cluster-" + i,
                    "type": "circle",
                    "source": "earthquakes",
                    "paint": {
                        "circle-color": layer[1],
                        "circle-radius": 18
                    },
                    "filter": i === 0 ?
                        [">=", "point_count", layer[0]] :
                        ["all",
                            [">=", "point_count", layer[0]],
                            ["<", "point_count", layers[i - 1][0]]]
                });
            });   
    // Add a layer for the clusters' count labels
            map.addLayer({
                "id": "cluster-count",
                "type": "symbol",
                "source": "earthquakes",
                "layout": {
                    "text-field": "{point_count}",
                    "text-font": [
                        "DIN Offc Pro Medium",
                        "Arial Unicode MS Bold"
                    ],
                    "text-size": 12
                },
                'paint': {
                    'text-color': 'black'
                }
            });
        });
		
		var popup = new mapboxgl.Popup({
			closeButton: false,
			closeOnClick: false
			});
			 
			map.on('mouseenter', 'unclustered-points', function(e) {
				// Change the cursor style as a UI indicator.
				map.getCanvas().style.cursor = 'pointer';
				var coordinates = e.features[0].geometry.coordinates.slice();
                var description = e.features[0].properties;  
				// Ensure that if the map is zoomed out such that multiple
				// copies of the feature are visible, the popup appears
				// over the copy being pointed to.
				while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
					coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
				}         
				// Populate the popup and set it's coordinates
                // based on the feature found.
                var popupDate="";
                if(description.date!=null)
                {
                    popupDate=moment(description.date).format('MM-DD-YYYY T: h:mm a');
                }
                else
                {
                    popupDate= "";
                }
				popup
				.setLngLat(coordinates) 
				.setHTML('<span style="color:red;">Truck No: </span><span style="color:blue;">'+description.truckNo+'</span></br><span style="color:red;">Date: </span><span style="color:blue;">'+popupDate+'</span></br><span style="color:red;">Speed: </span><span style="color:blue;">'+description.speed+'</span></br><span style="color:red;">Address: </span><span style="color:blue;">'+description.location+'</span></br><span style="color:red;">Latitute: </span><span style="color:blue;">'+description.latitude+'</span></br><span style="color:red;">Longitude: </span><span style="color:blue;">'+description.longitude+'</span></br>')
				.addTo(map);
			});         
			map.on('mouseleave', 'unclustered-points', function() {
				map.getCanvas().style.cursor = '';
				popup.remove();
			});

       
			map.on('mouseleave', 'unclustered-points1', function() {
				map.getCanvas().style.cursor = '';
				popup.remove();
			});

	}
    assignLoad = (row,index) => {
        var selRow = parseInt(row.selIndex);
        var radioval = document.querySelector('#truckid'+[index])

        if(radioval.checked===false)
        {
            toastr.info('Info', 'Please choose a Truck!');
            return;
        }

        var isDriverShow = localStorage.getItem('IsLoadDriverShow'); 
        var selDriver='';
        if(isDriverShow===1)
        {
             selDriver = document.querySelector('#driveridvalcarrier'+[index]);
            if(selDriver.value===0)
            {
                toastr.info('Info', 'Please choose a Driver!');
                return;
            }
            else
            {
                selDriver = selDriver.value;
            }
        }

        
        
        //var selDriver = row.selDriver.trim();
       
        //var selTruck = parseInt(this.state.truckList[selRow].Asset_Id);
        // if(isDriverShow==1)
        // {
        //     if(selDriver === "")
        //     {
        //         toastr.info('Info', 'Select Driver!');
        //         return;
        //     }
        // }

        var selLoads = this.state.loadRequested.filter( (row1) => {
            return row1.loadSel;
        });

        if(selLoads.length === 0)
        {
            toastr.info('Info', 'Select Load!');
            return;
        }

        selLoads = selLoads.map( (load) => load.id);
        
        this.props.dispatch(quickDispatchAction({
            loads:selLoads,
            driver:selDriver,
            assetId:row.Asset_Id
        }));
        
    }

    radiobutton=(e,index)=>
    {
        var cells = document.getElementsByClassName("assignbtn"); 
        for (var i = 0; i < cells.length; i++) { 
            cells[i].disabled = true;
           }
        if(e.target.value==='on')
        {
            const idval = "truckval"+index;
            document.getElementById(idval).disabled = false;
        }
    }

    
    indicationfrtrucklist=()=> {
        if(this.props.loading)
        {
            var indicationhtml = 
			<div className="spinner-border" role="status">
			 <div className="row react-bs-table-no-data"></div>
			 </div>
             return indicationhtml;
        }
        else
        {
          return "No Data Found"
        }
    }

    indicationfrLoadlist=()=> {
        if(this.props.loading)
        {
            var indicationhtml = 
			<div className="spinner-border" role="status">
			 <div className="row react-bs-table-no-data"></div>
			 </div>
             return indicationhtml;
        }
        else
        {
          return "No Data Found"
        }
    }

	render(){     
        if(this.props.data.status === 'UNAUTHORIZED') {
			localStorage.clear();
            window.location = '/login'
            return <p></p>
        }
        var rowData = [];
        var indicationhtml = "";
        if(this.props.loading)
        {
          indicationhtml = 
			<div className="spinner-border" role="status">
			 <div className="row react-bs-table-no-data"></div>
			 </div>
        }else
        {
            if(this.state.loadRequested.length>0)
            {
                 rowData = this.state.loadRequested.map(          
                    (row, index) =>{
                        //console.log(this.state.loadRequested)
                        var stringLoadDate="";
						if(row.stringLoadDate!=="")
						{
							stringLoadDate=moment(row.stringLoadDate).format('MM-DD-YYYY');
						}
						else
						{
							stringLoadDate= "";
						}
                        return({
                            loadSelInput:<input type="checkbox" onChange={(e) => this.selectLoad(e,index)} defaultChecked={row.loadSel} />,
                            id:row.id,
                            loadNumber:row.loadNumber,
                            stringLoadDate:stringLoadDate,
                            customerName:row.customerName,
                            consigneeName:row.consigneeName,
                            consigneeAddress:row.consigneeAddress,
                            weight:row.weight,
                            originCity:row.originCity,
                            destinationCity:row.destinationCity,
                            commodity:row.commodity,
                            equipmentType:row.equipmentType,							
                            loadPointsList:row.loadPointsList,
                            basecharge:row.basecharge,
                            fulecharge:row.fulecharge,
                            accessiorialcharge:row.accessiorialcharge,
                            totalrevenue:row.totalrevenue,
                            loadedmiles:row.loadedmiles,
                            emptymiles:row.emptymiles,
                            totalmiles:row.totalmiles,
                            driverpay:row.driverpay,
                            tenderprice:row.tenderprice,
                            originaddress:row.originaddress,
                            destaddress:row.destaddress,
                            distance:row.distance,
                            shipperName:row.shipperName,
                            brokerName:row.brokerName,
                            shipperref:row.shipperref
                            //assetName:row.assetName,
                            //driverName:row.driverName,
                        });
                    }					
                );
            }
        }
           
          
        const expandRow2 = {	
            renderer: row => (	
                <article className="table-data truck-data shadow bg-white rounded">  
                   <div className="row no-gutters">
                    <div className="col-md-12">
                        
                            <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="pickup-tab"  data-toggle="tab" href={ `#pickup_${row.id}` } role="tab" aria-controls="pickup" aria-selected="true" >
                                     pickup Details
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="delivery-tab" data-toggle="tab" href={ `#delivery_${row.id}` } role="tab" aria-controls="delivery" aria-selected="false" >
                                     Delivery Details
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="brokertender-tab" data-toggle="tab"  href={ `#brokertender_${row.id}` } role="tab" aria-controls="brokertender" aria-selected="false" >
                                      Broker Tender
                                    </a>
                                </li>							
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id={ `pickup_${row.id}` } role="tabpanel" aria-labelledby="pickup-tab">								
                                          <table className="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Address</th>
                                                            <th scope="col">Contact</th>
                                                            <th scope="col">Earliest Time</th>
                                                            <th scope="col">Latest Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        row.loadPointsList.length?(row.loadPointsList.map(function(doc, index) {  
                                                            if(doc.loadPointTypeId===2)                  
                                                                {
                                                                    var contactDetails = "";
                                                                    if(doc.contactCityName!==null && doc.contactStateName!==null && doc.contactZipValue!==null)
                                                                    {
                                                                         contactDetails = doc.contactCityName+', '+doc.contactStateName+', '+doc.contactZipValue
                                                                    }
                                                                    else if(doc.contactCityName!==null && doc.contactStateName!==null)
                                                                    {
                                                                         contactDetails = doc.contactCityName+', '+doc.contactStateName
                                                                    }
                                                                    else if(doc.contactCityName!==null && doc.contactZipValue!==null)
                                                                    {
                                                                         contactDetails = doc.contactCityName+', '+doc.contactZipValue
                                                                    }
                                                                    else if(doc.contactCityName!==null)
                                                                    {
                                                                         contactDetails = doc.contactCityName
                                                                    }
                                                                    else if(doc.contactStateName!==null)
                                                                    {
                                                                         contactDetails =  doc.contactStateName
                                                                    }
                                                                    else if(doc.contactZipValue!==null)
                                                                    {
                                                                         contactDetails =  doc.contactZipValue
                                                                    }				
                                                                    // var Contact =(doc.contactAddress1 == null ? "" : doc.contactAddress1);			
                                                                    // Contact = (Contact == "" ? "" : (Contact + ", ")) + (doc.contactAddress2 == null ? "" : doc.contactAddress2);			
                                                                    // Contact = (Contact == "" ? "" : (Contact + ", ")) + (doc.contactZip == null ? "" : doc.contactZip);								
                                                                    return(
                                                                        <tr key={index}>
                                                                            <td>{ contactDetails}</td>
                                                                            <td>{ doc.contactNumber }</td>
                                                                            <td>{ doc.earliestTime }</td>
                                                                            <td>{ doc.latestTime }</td>
                                                                    </tr>
                                                                ) 
                                                             }                 
                                                        })):(<tr><td className="text-center" colspan="4">No Record Found</td></tr>)
                                                    }
                                            </tbody>	
                                        </table>						
                                    </div>
                                    <div className="tab-pane fade" id={ `delivery_${row.id}` } role="tabpanel" aria-labelledby="delivery-tab">								
                                    <table className="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Address</th>
                                                            <th scope="col">Contact</th>
                                                            <th scope="col">Earliest Time</th>
                                                            <th scope="col">Latest Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        row.loadPointsList.length?(row.loadPointsList.map(function(doc, index) {  
                                                            if(doc.loadPointTypeId===3)                  
                                                                {
                                                                    var contactDetails = "";
                                                                    if(doc.contactCityName!==null && doc.contactStateName!==null && doc.contactZipValue!==null)
                                                                    {
                                                                         contactDetails = doc.contactCityName+', '+doc.contactStateName+', '+doc.contactZipValue
                                                                    }
                                                                    else if(doc.contactCityName!==null && doc.contactStateName!==null)
                                                                    {
                                                                         contactDetails = doc.contactCityName+', '+doc.contactStateName
                                                                    }
                                                                    else if(doc.contactCityName!==null && doc.contactZipValue!==null)
                                                                    {
                                                                         contactDetails = doc.contactCityName+', '+doc.contactZipValue
                                                                    }
                                                                    else if(doc.contactCityName!==null)
                                                                    {
                                                                         contactDetails = doc.contactCityName
                                                                    }
                                                                    else if(doc.contactStateName!==null)
                                                                    {
                                                                         contactDetails =  doc.contactStateName
                                                                    }
                                                                    else if(doc.contactZipValue!==null)
                                                                    {
                                                                         contactDetails =  doc.contactZipValue
                                                                    }
                                                                    // var Contact =(doc.contactAddress1 == null ? "" : doc.contactAddress1);			
                                                                    // Contact = (Contact == "" ? "" : (Contact + ", ")) + (doc.contactAddress2 == null ? "" : doc.contactAddress2);			
                                                                    // Contact = (Contact == "" ? "" : (Contact + ", ")) + (doc.contactZip == null ? "" : doc.contactZip);								
                                                                    return(
                                                                        <tr key={index}>
                                                                            <td>{ contactDetails }</td>
                                                                            <td>{ doc.contactNumber }</td>
                                                                            <td>{ doc.earliestTime }</td>
                                                                            <td>{ doc.latestTime }</td>
                                                                    </tr>
                                                                ) 
                                                             }                 
                                                         })):(<tr><td className="text-center" colspan="4">No Record Found</td></tr>)
                                                    }
                                            </tbody>	
                                        </table>					
                                    </div>
                                    <div className="tab-pane fade" id={ `brokertender_${row.id}` } role="tabpanel" aria-labelledby="brokertender-tab">							 	
                                    <table className="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Base Charge</th>
                                                            <th scope="col">Fuel Charge</th>
                                                            <th scope="col">Accessorial Charge</th>
                                                            <th scope="col">Tendered Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>	
                                                        <tr>
                                                            <td>{(row.basecharge===null)?<i>&nbsp;</i>:(row.basecharge)}</td>
                                                            <td>{(row.fulecharge===null)?<i>&nbsp;</i>:(row.fulecharge)}</td>
                                                            <td>{(row.accessiorialcharge===null)?<i>&nbsp;</i>:(row.accessiorialcharge)}</td>
                                                            <td>{(row.tenderprice===null)?<i>&nbsp;</i>:(row.tenderprice)}</td>
                                                        </tr>
                                                </tbody>	
                                        </table>					
                                    </div>										
                            </div>
                    </div>
                </div>
            </article>
            ),
             showExpandColumn: true,
             expandByColumnOnly: true,
             expandHeaderColumnRenderer: ({ isAnyExpands  }) => {
                if (isAnyExpands ) {
                  return (
                    <i className="icofont-minus-circle"></i>
                  );
                }
                return (
                    <i  className="icofont-plus-circle"></i>
                );
              },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                  return (
                    <i className="icofont-minus-circle"></i>
                  );
                }
                return (
                    <i  className="icofont-plus-circle"></i>
                );
              }
       };
        
        const { SearchBar } = Search;
		const columns=[
            {
                dataField:'loadSelInput',
                text:''
            },	
			{
				text:'Load #',
				dataField:'loadNumber',
				sort:true
			},
			{
				text:'Date',
				dataField:'stringLoadDate',
				sort:true
			},
			{
				text:'Shipper',
				dataField:'shipperName',
				sort:true
            },
            {
				text:'Shipper Ref',
				dataField:'shipperref',
				sort:true
			},           
            {
				text:'Broker',
				dataField:'brokerName'
			},
			{
				text:'Weight',
				dataField:'weight',
				sort:true
			},			
            {
				text:'Equipment',
				dataField:'equipmentType',
				sort:true
            },
            {
				text:'Distance',
				dataField:'distance',
				sort:true
            },
            {
				text:'Origin',
				dataField:'originaddress',
				sort:true
            },
			{
				text:'Destination',
				dataField:'destaddress',
				sort:true
			},
		
         ];
         
         const customTotal = (from, to, size) => (
			<span className="react-bootstrap-table-pagination-total">
			  Showing { from } to { to } of { size } Results
			</span>
		  );
		  
		  const options = {
			paginationSize: 4,
			pageStartIndex: 1,
			sizePerPage: 5,
			firstPageText: '<<<',
			prePageText: '<<',
			nextPageText: '>>',
			lastPageText: '>>>',
			nextPageTitle: 'First page',
			prePageTitle: 'Pre page',
			firstPageTitle: 'Next page',
			lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: customTotal,
          };
          
          const truckColumns=[
            {
                dataField:'truckSelInput',
                text:' ',
                //headerStyle:{width:'3%'}
            },	
			{
				text:'Truck #',
				dataField:'Asset_Number',
				sort:true
            },
			{
				text:'Year',
				dataField:'Model_Year',
				sort:true
			},
            {
				text:'Make',
				dataField:'Make',
				sort:true
            },
            {
				text:'Model',
				dataField:'Model',
				sort:true
            },
            {
				text:'Truck Type',
				dataField:'Truck_Type_Name',
				sort:true
            },
            {
				text:'Location',
				dataField:'location',
                sort:true,
            },
            {
				text:'Driver Name',
				dataField:'selDriverInput',
                sort:true,
                headerStyle:{width:'15%'}
            },
            {
				text:'Mobile No.',
				dataField:'selDriverMobNo',
				sort:true
            },
            {
                dataField:'truckMap',
                text:'Map',
                headerStyle:{width:'5%'}
            },
            {
                dataField:'selBtn',
                text:' ',
                headerStyle:{width:'6%'}
            }
        ];

        var isDriverShow = localStorage.getItem('IsLoadDriverShow'); 
        //Driver Column remove for freightco carrier only
        if(isDriverShow===0)
        {
            truckColumns.splice(7,1)
            truckColumns.splice(7,1)
        }

        const truckCustomTotal= (from, to, size) => (
			<span className="react-bootstrap-table-pagination-total">
			  Showing { from } to { to } of { size } Results
			</span>
		  );

        const truckOptions = {
			paginationSize: 4,
			pageStartIndex: 1,			
			firstPageText: '<<<',
			prePageText: '<<',
			nextPageText: '>>',
			lastPageText: '>>>',
			nextPageTitle: 'First page',
			prePageTitle: 'Pre page',
			firstPageTitle: 'Next page',
			lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: truckCustomTotal,
          };
          var truckRowData=[];
           indicationhtml = '';
          if(this.props.loading)
          {
            indicationhtml = 
			<div className="spinner-border" role="status">
			 <div className="row react-bs-table-no-data"></div>
			 </div>
          }
          else{
            if(this.props.truckNoListAndLocation.length > 0)
            {
               truckRowData = this.props.truckNoListAndLocation.map(
                    (row,index) =>{
                      return({
                              Asset_Id:row.Asset_Id,
                              //License_Plate_No:row.License_Plate_No,
                              Asset_Number:row.Asset_Number,
                              Truck_Type_Name:row.Truck_Type_Name,
                              Make:row.Make,
                              Model_Year:row.Model_Year,
                              Model:row.Model,
                              Latitude:row.Latitude,
                              Longitude:row.Longitude,
                              selTruck:false,
                              selIndex:'',
                              selDriver:'',
                              selDriverMobNo:"",
                              location:row.Location,
                              Speed:row.Speed,
                              UTC_Date_Time:row.UTC_Date_Time,
                              Location:row.Location,  
                              selDriverInput:this.driverListInput(row.selIndex,index),    //onClick={ (e) => this.radiobutton(e,index) }
                              truckSelInput:<input type="radio" name="truckid" onClick={ (e) => this.radiobutton(e,index) } id={"truckid"+index}  />,
                              truckMap:<a href='/#' onClick={ (e) => this.openMap(row)} className="icon_truck_location btn pc-mailer-tooltip" ><img src={"/images/mapicon.png"} alt=''/></a>,
                              selBtn:<button className="btn btn-primary assignbtn" name="carrierassignbtn" id={"truckval"+index} onClick={ (e) => this.assignLoad(row,index) } >Assign</button>
                        });
                    }					
                );
            }
            else
            {
              truckRowData=[];
            }
          }


           
            // if(this.state.truckList.length>0)
            // {
            //     var truckRowData = this.state.truckList.map(
            //         (row, index) =>{
            //             return({
                            // // onChange={(e) => this.selectTruck(e,index)} defaultChecked={row.selTruck}
                            // truckSelInput:<input type="radio" name="truckid" id={"truckid"+index} onClick={ (e) => this.radiobutton(e,index) } />,
                            // truckMap:<a onClick={ (e) => this.openMap(row)} className="icon_truck_location btn pc-mailer-tooltip" ><img src={"/images/mapicon.png"}/></a>,
                            // selBtn:<button className="btn btn-primary assignbtn" id={"truckval"+index} onClick={ (e) => this.assignLoad(row) } >Assign</button>,                     
            //                 id:row.Asset_Id,
            //                 //License_Plate_No:row.License_Plate_No,
            //                 Asset_Number:row.Asset_Number,
            //                 Truck_Type_Name:row.Truck_Type_Name,
            //                 Make:row.Make,
            //                 Model_Year:row.Model_Year,
            //                 Model:row.Model,
            //                 Latitude:row.Latitude,
            //                 Longitude:row.Longitude,
            //                 location:row.Location,
            //                 selDriverInput:this.driverListInput(row.selIndex,index),                    
            //                 selDriverMobNo:row.selDriverMobNo
            //             });
            //         });
            //  }else{
            //     var indicationhtml = "No Data Found";
            //     var truckRowData = [];
            //  }

        return(
            <article className="table-data truck-data shadow bg-white rounded">			
				<div className="row no-gutters">
					<div className="col-md-12">
						{/* <ul className="nav nav-tabs" id="availableLoads" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="load-tab" data-toggle="tab" href="#availLoads" role="tab" aria-controls="availLoads" aria-selected="true"> AVAILABLE LOADS</a>
                            </li>					  								  
						</ul> */}
						
                                <ToolkitProvider
                                        keyField="id"
                                        data={ rowData }
                                        columns={ columns }
                                        search
                                    >
                                        {
                                        props => (
                                            <div>
                                            {/* <SearchBar { ...props.searchProps } /> */}
                                            <ul className="nav nav-tabs" id="availableTrucks" role="tablist">                                              
                                            <li className="nav-item">
                                                <a className="nav-link active" id="mytruck-tab" data-toggle="tab" href="#mytruck" role="tab" aria-controls="mytruck" aria-selected="true"> AVAILABLE LOADS</a>
                                            </li>
                                                <div className="flex-left-auto">
                                                    <ul className="ul-horizontal tab-menus">
                                                        <li className="searchicon">
                                                        <SearchBar { ...props.searchProps } />
                                                        </li>
                                                    </ul>
                                                </div>		  								  
                                            </ul>
                                            <BootstrapTable
                                                { ...props.baseProps } pagination={ paginationFactory(options) } 
                                                noDataIndication={this.indicationfrLoadlist}  hover expandRow={ expandRow2 }
                                                bordered={false}
                                            />
                                            </div>
                                        )
                                        }
                                    </ToolkitProvider>
						
					</div>
				</div>
                <p>&nbsp;</p>
                <div className="row no-gutters">
					<div className="col-md-12">
						
						{/* <div className="tab-content" id="myTabContent">
							  <div className="tab-pane fade show active" id="mytruck" role="tabpanel" aria-labelledby="mytruck-tab"> */}
                                <ToolkitProvider
                                        keyField="id"
                                        data={ truckRowData }
                                        columns={ truckColumns }
                                        search
                                    >
                                        {
                                        props => (
                                            <div>
                                            <ul className="nav nav-tabs" id="availableTrucks" role="tablist">                                              
                                            <li className="nav-item">
                                                <a className="nav-link active" id="mytruck-tab" data-toggle="tab" href="#mytruck" role="tab" aria-controls="mytruck" aria-selected="true"><i className="icofont-truck-loaded"></i> AVAILABLE Trucks</a>
                                            </li>
                                                <div className="flex-left-auto">
                                                    <ul className="ul-horizontal tab-menus">
                                                        <li className="searchicon">
                                                        <SearchBar { ...props.searchProps } />
                                                        </li>
                                                    </ul>
                                                </div>		  								  
                                            </ul>
                                           
                                            <BootstrapTable
                                                { ...props.baseProps } pagination={ paginationFactory(truckOptions) } 
                                                noDataIndication={ this.indicationfrtrucklist }  hover 
                                                bordered={false}
                                            />
                                            </div>
                                        )
                                        }
                                    </ToolkitProvider>
							  {/* </div>							  
						</div> */}
					</div>
				</div>

                <Modal show={this.state.showMap} onHide={this.closeMap} dialogClassName="modal-90w modal-map">
				    <Modal.Header closeButton>
					    <Modal.Title>Truck Location</Modal.Title>
				    </Modal.Header>
				    <Modal.Body >
                        <div id="map-canvas" className="map-container"></div>
                    </Modal.Body>								
			    </Modal>	
			</article>
        );
	}
}

const mapStateToProps = state => {
	return {
        data: state.loadReducer.data,
        DriverList:state.loadReducer.DriverList,
        truckNoListAndLocation:state.loadReducer.truckNoListAndLocation,
        quickdispatchflagAction:state.loadReducer.quickdispatchflag,
        quickDispatch:state.loadReducer.QuickDispatch,
        loading: state.loadReducer.loading,
        canceledloadflag:state.loadReducer.canceledloadflag,
        quickdispatchloads:state.loadReducer.data
	}
}
export default connect(mapStateToProps)(QuickDispatch)