import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import moment from 'moment'
import momentLocalizer from "react-widgets-moment";
import { trackingList, setgoFlag } from '../../actions/dashboardActions';
import { connect } from 'react-redux';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {toastr} from 'react-redux-toastr';
import 'react-widgets/dist/css/react-widgets.css'
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as ReactBootstrap from 'react-bootstrap';
import { getDistance, convertDistance } from 'geolib';
import {formatLocation, getMapboxKey} from '../../helpers/appUtil';
require('dotenv').config();
momentLocalizer(moment)

class dashboardrenderSelect extends Component {
  
	render() {
	   const { input, data, label, selectlabel, controlClass, meta: { touched, error } } = this.props
	   return (
		  <div className={`form-group ${(touched && error) ? 'error' : ''}`}>          
			 <select {...input} placeholder={label} className={`form-control ${(controlClass) ? controlClass : ''}`}>
				<option value="">{ `${(label) ? label : selectlabel}`}</option>
				{
				   Array.isArray(data) ? data.map(option =>
				   <option 
					  value={option.truckNo} 
					  key={option.id}>{option.truckNo}
				   </option>) : ''
				}
			 </select>
			 {touched && error && <span className="error">{error}</span>}
		  </div>
	   )
	}
 }
 const renderDateTimePicker = ({ input: { onChange, value }, showTime }) =>
	<DateTimePicker dropUp={true}
	onChange={onChange}
	format="MM-DD-YYYY"
	time={false}
	value={!value ? null : new Date(value)}
	/>
 const renderTimePicker = ({ input: { onChange, value }, showTime }) =>
	<DateTimePicker dropUp={true}
	onChange={onChange}
	date={false} default={null}
	/> 
  const validate = values => {    
	const errors = {}
	if (!values.truckno) {
        errors.truckno = 'Truck/Asset No can not be left blank.!'
    }
    if (!values.from_date) {
        errors.from_date = 'From Date can not be left blank.!'
    }
    if (!values.to_date) {
        errors.to_date = 'To Date can not be left blank.!'
    }
    if (!values.from_time) {
        errors.from_time = 'From time can not be left blank.!'
    }
    if (!values.to_time) {
        errors.to_time = 'To time can not be left blank.!'
    }
	return errors
  }
  function submit(values) {
    this.setState({ truckNo: " ( " + values.truckno + " )"});
	  const frmdata = {};
	  var from_date = (values.from_date) ? (moment(values.from_date).format('YYYY-MM-DD')) : ("");
	  var from_time = (values.from_time) ? (moment(values.from_time).format('HH:mm:ss')) : ("00:00:00");
	  frmdata.fromDate = (values.from_date) ? (from_date+' '+from_time) : ("");
	  var to_date = (values.to_date) ? (moment(values.to_date).format('YYYY-MM-DD')) : ("");
	  var to_time = (values.to_time) ? (moment(values.to_time).format('HH:mm:ss')) : ("00:00:00");
	  frmdata.toDate = (values.to_date) ? (to_date+' '+to_time) : ("");
	  frmdata.truckNo = (values.truckno) ? (values.truckno) : ("");
	  frmdata.exportFlg = this.state.export;
	  frmdata.goFlg = this.state.go;
	  if(frmdata.fromDate && frmdata.toDate){		
		this.props.dispatch(trackingList(frmdata));
	  }   
  }
class DashboardSearch extends Component{
	state = {
		startDate1 : null,
		startDate2 : null,
		export : false,
		go :  false,
		showModal: false,
		truckNo:'',
        distance:'',
        mapobject:null,
        activemarkers:[],
        errors:''
	};
	handleChange1 = date => {
		this.setState({
		  startDate1: date
		});
	};
	handleChange2 = date => {
		this.setState({
		  startDate2: date
		});
	}; 
	componentDidMount()
    {
        mapboxgl.accessToken = getMapboxKey();	  
        //mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
	}  
	handleExportClick = (e) =>{ 
		if(this.props.invalid === false){
			this.setState({
				export : true,
				go : false
			});
		}
	}
	handleGoClick = (e) => {	
		if(this.props.invalid === false){
			this.setState({
					export : false,
					go : true
			});
		}
	}
	close = (e) => {	
		this.setState({
			showModal: false			
		});			
	}
    createMarkerElement(id,imageurl,height,width){
        let el = document.createElement('div');
        el.className = 'marker';
        el.id = 'map-markers-'+id;
        el.style.backgroundImage =
        'url('+imageurl+')';
        el.style.width = width;
        el.style.height = height;
        return el;
    }
    initHistoryMap(){
        try{
            console.log('trying to init map');
            let mapelement = document.querySelector("#map-canvas");
            if(mapelement===null){
                return false;
            }
            let body = document.body,
			html = document.documentElement;
			let height = Math.min( body.scrollHeight, body.offsetHeight, 
			html.clientHeight, html.scrollHeight, html.offsetHeight );
			height -= 150;
            mapelement.style.height = height+"px";
            let mapobject=new mapboxgl.Map({
                container: 'map-canvas', // container id
                style: 'mapbox://styles/mapbox/streets-v11', //stylesheet location
                center: [-96,37], // starting position
                zoom: 3.5 // st arting zoom
            });
            this.setState({
                mapobject : mapobject 
            });
            return true;
        }
        catch(e){
            console.log(e.message);
            return false;
        }
    }
    plotInitialMarkers(mapobject,trackingdata){
        let firstMarkerImage = "https://maps.google.com/mapfiles/ms/icons/red.png";
        let finalMarkerImage = "https://maps.google.com/mapfiles/ms/icons/green.png";
        let initialMarkerEl = this.createMarkerElement(trackingdata[0],firstMarkerImage,"32px","32px");
        let finalMarkerEl = this.createMarkerElement(trackingdata[trackingdata.length-1],finalMarkerImage,"32px","32px");
        this.addMarkerToMap(initialMarkerEl,mapobject,trackingdata[0]);
        this.addMarkerToMap(finalMarkerEl,mapobject,trackingdata[trackingdata.length-1]);
    }
    plotMovementMarkers(mapobject,trackingdata){
        let pointsImage = "https://truckercloud.s3.us-east-2.amazonaws.com/images/blue.png";
        trackingdata.forEach(function(data,i){
            let pointsElement = this.createMarkerElement(i,pointsImage,"8px","8px");
            this.addMarkerToMap(pointsElement,mapobject,data);
        }.bind(this));
    }
    addMarkerToMap(markerelement,mapobject,locationdata){
        // var popupdate="";
        // if(locationdata[5]!=null)
        // {
        //     popupdate=moment(locationdata[5]).format('MM-DD-YYYY T: hh:mm a');
        // }
        // else
        // {
        //     popupdate= "";
        // }
        let speed = parseFloat(locationdata.speed);
        if(isNaN(speed))
        {
            speed ='';
        }
        else{
            speed = speed.toFixed(2) + " mph";
        }
        console.log(speed);
        let location = formatLocation(locationdata.address);
        let popup = new mapboxgl.Popup({ 
            offset: 25, 
            closeButton: false,
            closeOnClick: false
        }).setHTML('<span class="map-popup-label">Truck/Asset No. : </span><span class="map-popup-label">'+locationdata.vehicleno+'</span></br><span class="map-popup-label1"> Date : </span><span class="map-popup-label">'+locationdata.utctime+'</span></br><span class="map-popup-label1">Speed : </span><span class="map-popup-label">'+speed+'</span></br><span class="map-popup-label2">Location : </span><span class="map-popup-label">'+location+'</span></br>');
        // make a marker for each feature and add to the map
        let newmarker = new mapboxgl.Marker(markerelement)
            .setLngLat([locationdata.longitude,locationdata.latitude])
            .setPopup(popup)
            .addTo(mapobject);
        let markerDiv = newmarker.getElement();
        markerDiv.addEventListener('mouseenter', () => newmarker.togglePopup());
        markerDiv.addEventListener('mouseleave', () => newmarker.togglePopup());
    }
    checkIfDataDuplicate(previouslat,previouslon,currentlat,currentlon){
        let response = false;
        if(previouslat===currentlat && previouslon===currentlon){
            console.log("skip on equal");
            response = true;
        }
        previouslat = previouslat.toFixed(5);
        previouslon = previouslon.toFixed(5);
        currentlat = currentlat.toFixed(5);
        currentlon = currentlon.toFixed(5);
        if(previouslat===currentlat && previouslon===currentlon){
            console.log("skip on 5 nos equal");
            response = true;
        }
        return response;
    }
    removeDuplicateData(coords){
        let cleandata = [];
        let previouslat = 0.0;
        let previouslon = 0.0;
        coords.forEach(function(data,i){
            if(!this.checkIfDataDuplicate(previouslat,previouslon,parseFloat(data[0]),parseFloat(data[1]))){
                cleandata.push(data);
            }
            previouslat = parseFloat(data[0]);
            previouslon = parseFloat(data[1]);
        }.bind(this));
        return cleandata;
    }
    calculateDistance(trackingData, coords){
      var distance = getDistance(
			{ latitude: trackingData[0].latitude, longitude: trackingData[0].longitude },
			{ latitude: trackingData[coords.length-1].latitude, longitude: trackingData[coords.length-1].longitude } 
		);

    	distance = convertDistance(distance, 'mi');
		this.setState({
			...this.state,
			distance : distance.toFixed(2)
		});
    }
    showMap () {
        //force initializing map
        let isMapOn = this.initHistoryMap();
        while(!isMapOn){
            isMapOn = this.initHistoryMap();
        }
        let mapobject = this.state.mapobject;
        let geojson = {
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'geometry': {
                    'type': 'LineString',
                    'coordinates': []
                    }
                }
            ]
        };
        //force initializing map
        let plottimer = null;
        let i = 0;
        let pathdata = [];
        let currentzoom = 15;
        let coords = this.props.trackingData.map( data => {
            return {
                latitude:parseFloat(data.latitude),
                longitude:parseFloat(data.longitude),
                vehicleno:data.license_Plate_No,
                speed:data.speed,
                utctime:data.convertedDate,
                address:data.location
            }
        });
       
        var trackingData = [...this.props.trackingData];
        this.calculateDistance(trackingData,coords);

        mapobject.setCenter([coords[0].longitude,coords[0].latitude]);
        mapobject.setZoom(currentzoom);
        //pathdata = this.removeDuplicateData(coords);
        pathdata = coords;
        let datalength = pathdata.length;
        this.plotInitialMarkers(mapobject,pathdata);
        mapobject.on('load', function() {
            mapobject.addSource('movementroute', {
                'type': 'geojson',
                'data': geojson
            });
            mapobject.addLayer({
                'id': 'historypath',
                'type': 'line',
                'source': 'movementroute',
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': 'red',
                    'line-opacity': 0.75,
                    'line-width': 3
                }
            });
            plottimer = setInterval(function(){
                if(pathdata[i].latitude && pathdata[i].longitude){
                    let currentposition = [pathdata[i].longitude,pathdata[i].latitude];
                    geojson.features[0].geometry.coordinates.push(currentposition);
                    mapobject.getSource('movementroute').setData(geojson);
                    i++;
                    if(!mapobject.getBounds().contains(currentposition)){
                        currentzoom = currentzoom-1;
                        mapobject.setZoom(currentzoom); 
                    }
                    if(i>=datalength){
                        clearInterval(plottimer);
                        this.plotMovementMarkers(mapobject,pathdata)
                    }
                }
            }.bind(this,mapobject), 100);
        }.bind(this));
    }
	render(){		
		const { handleSubmit } = this.props;
		if(this.props.exportFlg === true)
		{	
			this.props.dispatch(setgoFlag(false));		
			if(this.props.trackingData.length>0){
				let csvData = [];
				csvData = this.props.trackingData;	
				let fileName = 'TrackHistory';
				const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				const fileExtension = '.xlsx';
				const ws = XLSX.utils.json_to_sheet(csvData);
				const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
				const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
				const data = new Blob([excelBuffer], {type: fileType});
				FileSaver.saveAs(data, fileName + fileExtension);
			}else{
				toastr.info('Info', 'No Data Available!');
			}
		}
		if(this.props.goFlg === true)
		{	
		this.props.dispatch(setgoFlag(false));
            if(this.props.trackingData.length>0){
                this.state.showModal = true;
                if(this.state.showModal === true){
                    setTimeout(
						function() {
                            this.showMap();
						}
						.bind(this),
						500
					);
                }
            }
            else{
                this.state.showModal = false;
                toastr.info('Info', 'No Data Available!');
            }
        }
		var Modal = ReactBootstrap.Modal;		
		return(
			<form className="row" onSubmit={handleSubmit(submit.bind(this))}>
			<div className="trcuk-breadcrump">
				<h4 className="page-title">Breadcrumb Trail</h4>
				<div className="row trim-margin">
					<div className="col-md-3">
						<label>From</label>
					</div>
					<div className="col-md-5">
						<div className="form-group form-icon input-date">	
							<Field
							name="from_date"
							defaultValue={null}
							component={renderDateTimePicker}
							/>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-group form-icon">	
							<Field
							name="from_time"
							defaultValue={null}
							component={renderTimePicker} value={this.state.time1}
							/>		
						</div>
					</div>
				</div>
				<div className="row trim-margin">
					<div className="col-md-3">
						<label>To</label>
					</div>
					<div className="col-md-5">
						<div className="form-group form-icon input-date">						
							<Field
							name="to_date"
							defaultValue={null}
							time={false}
							component={renderDateTimePicker}
							/>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-group form-icon">
							<Field
							name="to_time"
							defaultValue={null}
							component={renderTimePicker}
							/>
						</div>
					</div>
				</div>
				<div className="row trim-margin">
					<div className="col-md-3">
						<label>Truck/Asset No</label>
					</div>
					<div className="col-md-7">
						<div className="form-group">					
						<Field type="text" className="form-control" name="truckno" id="truckno" component={dashboardrenderSelect} data={this.props.trucks} label="Truck/Asset No" />    
						</div>
					</div>
					<div className="col-md-5">
						<div className="form-group">
						    <button type="submit" disabled={this.state.loading} value={this.state.export} onClick={this.handleExportClick} data-name="export" className="btn btn-primary btn-sm">
                            Export
                            </button>
							<button type="submit" disabled={this.state.loading} data-name="go" value={this.state.go} id="resizeButton" onClick={this.handleGoClick} data-toggle="modal" data-target=".gopopup" className="btn btn-primary btn-sm float-right">
                            Go
                            </button>
							{/* <Button variant="primary" onClick={this.open}>
								Launch demo modal
							</Button> */}
						</div>
					</div>
				</div>
			</div>
			<Modal show={this.state.showModal} onHide={this.close} dialogClassName="modal-90w"
        >
				<Modal.Header closeButton>
				<Modal.Title className="w-100" >Truck Details <span className="h6">{this.state.truckNo}</span> <span className="h6 float-right">Distance {this.state.distance} Miles</span></Modal.Title>
				</Modal.Header>
				<Modal.Body><div id="map-canvas"></div></Modal.Body>
			</Modal>
			</form>
		)
	}
}
const mapStateToProps = (state) => {
	console.log(state.dashboardReducer);
	return{
		loading : state.dashboardReducer.loading,
		trackingData : state.dashboardReducer.export,
		exportFlg: state.dashboardReducer.exportFlg,
		goFlg: state.dashboardReducer.goFlg
	}    
}
DashboardSearch = connect(
    mapStateToProps
)(DashboardSearch);

export default reduxForm({
    form: 'dashboardSearchValidation', // a unique name for this form
	validate 
})(DashboardSearch);
