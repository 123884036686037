import React,{Component} from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import {validate} from './changepwdvalidation';
import { changepasswordaction } from '../../actions/userActions';
import { renderField } from '../../helpers/FormControls';
import {toastr} from 'react-redux-toastr';

function submit(values,params) {   
    const frmdata = {};
     //window.alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`)
     frmdata.oldpassword= (values.currentPassword) ? (values.currentPassword) : ("");
     frmdata.newpassword= (values.password) ? (values.password) : ("");
     this.props.dispatch(changepasswordaction(frmdata)); 
  }

class changepassword extends Component
{
     componentDidUpdate()
	{
		if(this.props.data.message==="Password Updated")
		{
            //toastr.success('Success', 'Password updated Successfully!');      
            document.getElementById('modalclose').click();      
            localStorage.clear();
			window.location = '/login'
        }
        else if(this.props.data.message==="Invalid Password")
		{
            toastr.error('Error', 'Please Enter a valid password!');    
        }
    }
    
    handleClick = () =>{
        this.props.dispatch(initialize('changepwdvalidation'));
    }

    render(){
        const { handleSubmit } = this.props
        return(            
        <div  data-backdrop="static" data-keyboard="false" className="modal fade changepassword"  role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg proofofdelivery">
                <div className="modal-content">   
                <form name="ManageLoadsForm" className="mb-0 tg-forms" id="changepwdvalidation" method="post" onSubmit={handleSubmit(submit.bind(this))}  autoComplete="off" encType="multipart/form-data">
                      
                <div className="modal-header">
                   <h5 className="modal-title doc-color" id="exampleModalLabel">CHANGE PASSWORD</h5>
                    <button type="button" className="close" data-dismiss="modal" id="modalclose" onClick={this.handleClick} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                   </div>
               <div className="modal-body" id="favourite-loads-container">
               <div className="row">
	          <div className="col-md-12 col-sm-12 col-12">	
			<div className="row">
				<div className="col-md-12 col-sm-12 col-xs-12 ">
					<font color="red">  </font>
				</div>
			</div>
			<div className="row form-group">
				<div className="col-md-12 col-sm-12 col-xs-12">
					<div className="row">
						<div className="col-md-4 col-sm-4 col-xs-12 lbl">
							<label>Current Password</label>
						</div>
						<div className="col-md-8 col-sm-8 col-xs-12">
                            <Field type="password" name="currentPassword"  maxlength="25" component={renderField} labelnotavailabe="labelnotavailabe"/>							
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 col-sm-4 col-xs-12 lbl">
							<label>Password</label>
						</div>
						<div className="col-md-8 col-sm-8 col-xs-12">
                            <Field type="password" name="password" maxlength="25" component={renderField} labelnotavailabe="labelnotavailabe"/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 col-sm-4 col-xs-12 lbl">
							<label>Retype Password</label>
						</div>
						<div className="col-md-8 col-sm-8 col-xs-12">
                            <Field type="password" name="retypePassword" maxlength="25" component={renderField} labelnotavailabe="labelnotavailabe"/>
						</div>
					</div>
				</div>
			</div>
                    <div className="row">
                        <div className="col-md-12">					
                            <button type="submit" className="btn btn-primary btn-sm float-right">Change</button>
                        </div>
                    </div>
            </div>
           </div>
               </div>          
                </form>               
                </div>
            </div>
        </div>
        )
    }
}

const PageOneForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    })(changepassword)

  const mapStateToProps = (state,ownProps) => { 
    return{
        data: state.userReducer.data,
        form: 'changepwdvalidation',
        validate,      
        enableReinitialize: true 
    }    
  }
  
  const PageOneFormContainer = connect(mapStateToProps)(PageOneForm)
    
  export default PageOneFormContainer