import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderSelect } from '../../../helpers/FormControls';
import { validate } from './validation';

function submit(values) {
	
}

class DocumentsSearch extends Component{
	state = {
		cate : [
			{id: "1", name: "All Category"},
			{id:"2", name: "Address Proof"},
			{id:"3", name: "Cdl"}
		]
	}
	getCategoryOptions(selected_id){
		if(!this.state.cate){
			return <option>No data found!</option>
		}

		return this.state.cate.map(cat => {
			return <option key={cat.id} value={cat.id}>{cat.name}</option>;
		})
	}
	render(){
		const { handleSubmit } = this.props
		return(
			<article className="table-data truck-data shadow bg-white rounded">
				<form className="row" onSubmit={handleSubmit(submit.bind(this))}>
					<div className="col-md-11">
					<h6 className="doc-color">QUICK SEARCH</h6>
						<div className="row">
							<div className="form-group col col-md-4">
								<Field type="text" name="title" component={renderField} label="Title"/>
						  	</div>
						  	<div className="form-group col col-md-4">
								<Field type="text" name="category" component={renderSelect} className="form-control" label="Category" data={this.state.cate}/>
						  	</div>
						  	<div className="form-group col col-md-4">
								<Field type="date" name="date" component={renderField} label="Uploaded Date"/>
						  	</div>
						</div>
					</div>
					<div className="col-md-1">
						<div className="form-group">
							  <button type="submit" className="btn btn-secondary btn-sm" data-dismiss="modal">Find</button>&nbsp;
					  		<a href="/#" className="btn btn-primary btn-sm">Reset</a>
					  	</div>
					</div>				  	
				</form>
			</article>
		)
	}
}

export default reduxForm({
    form: 'syncValidation',  
    validate                
  })(DocumentsSearch)