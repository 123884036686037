import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderSelect } from '../../../helpers/FormControls';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

class ViewTrailer extends Component{
    render(){
		this.props.initialize(this.props.location.aboutProps);
		const { SearchBar } = Search;
		const document= [
			 {
			   text: 'Truck',
			   dataField: 'Truck',
			 },
			 {
			   text: 'Document Title',
			   dataField: 'Document'
			 },
			 {
			   text: 'Category',
			   dataField: 'Category'
			 },
			 {
			   text: 'Owner',
			   dataField: 'Owner'
			 },
			 {
			   text: 'Uploaded Details',
			   dataField: 'UploadedDetails'
			 },
			 {
			   text: 'Notes',
			   dataField: 'Notes'
			 }
		   ]
			   const customTotal = (from, to, size) => (
				 <span className="react-bootstrap-table-pagination-total">
				   Showing { from } to { to } of { size } Results
				 </span>
			   );
			   
			   const options = {
				 paginationSize: 4,
				 pageStartIndex: 1,
				 firstPageText: 'First',
				 prePageText: 'Back',
				 nextPageText: 'Next',
				 lastPageText: 'Last',
				 nextPageTitle: 'First page',
				 prePageTitle: 'Pre page',
				 firstPageTitle: 'Next page',
				 lastPageTitle: 'Last page',
				 showTotal: true,
				 paginationTotalRenderer: customTotal
			   };
		const products = [];
        this.props.initialize(this.props.location.aboutProps);
        return(
			<form className="tg-forms"> 
			<div className="table-data border">
				<ul className="nav nav-tabs" id="myTrucks" role="tablist">
					<li className="nav-item">
						<a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">View Truck</a>
					</li>                                 
				</ul>
				<div className="tab-content" id="myTabContent">
					  <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">                                      
				   
						<div className="row">                 
							<div className="col col-md-12">
								<h5>TRUCK INFO</h5>
								<article className="table-data truck-data shadow bg-white rounded">
									<div className="row">									
										<div className="col col-md-3">
											<div className="form-group">
											<Field type="text" name="truckno" component={renderField} label="Truck #"/>                                
											</div>
											<div className="form-group">
											<Field type="text" name="model_Year" component={renderField} label="Year"/>
											</div>
											<div className="form-group">
											<Field type="text" className="form-control" name="assetName" component={renderField} label="Asset Name" />
											</div>
										</div>
										<div className="col col-md-3">
											<div className="form-group">
											<Field type="text" name="make" component={renderField} label="Make"/> 
											</div>
											<div className="form-group">
											<Field type="text" name="identification_No" component={renderField} label="VIN"/>
											</div>
											<div className="form-group">
											<Field type="text" name="color" component={renderField} label="Color"/>                                 
											</div>
										</div>
										<div className="col col-md-3">
											<div className="form-group">
											<Field type="text" name="model" component={renderField} label="Model"/>
											</div>
											<div className="form-group">
											<Field type="text" name="license_Plate_No" component={renderField} label="Plate #"/>                               
											</div>
											<div className="form-group">
											<Field type="text" name="notes" component={renderField} label="Notes"/>                                
											</div>
										</div>  
										<div className="col col-md-3">
											<div className="form-group">
											<Field type="text" name="eld_Provider_Id" component={renderSelect} className="form-control" label="ELD Provider" data={this.props.eldproviderlist.data}/>
											</div>
											<div className="form-group">
											<Field type="text" name="device_id" component={renderField} label="Device Serial #"/>                               
											</div>
											<div className="form-group">
												<Field type="text" className="form-control" name="manufacturer" component={renderField} label="Manufacturer" />                          
											</div>
										</div>                             
									</div>


									<div className="row">									
										<div className="col col-md-3">
												<div className="form-group"> 
														<Field type="text" className="form-control" name="tankCapacity1" component={renderField} label="Tank Capacity1" /> 
													</div> 
													<div className="form-group">
														<Field type="text" className="form-control" name="tankCapacity2"  component={renderField} label="Tank Capacity2" />                                                        
													</div>
												
										</div>
										<div className="col col-md-3">
										<div className="form-group">
																 <Field type="text" className="form-control" name="axles" component={renderField} label="Axles" />                         
															 </div>
															 <div className="form-group">
																  <Field type="text" className="form-control" name="weight" component={renderField} label="Weight" />                          
														  </div>
														 
										</div>
										<div className="col col-md-3">
										<div className="form-group">
																 <Field type="text" className="form-control" name="size" component={renderField} label="Size" />                                                        
															 </div>
															 <div className="form-group">
																 <Field type="text" className="form-control" name="description" component={renderField} label="Description" />                                                       
															 </div>
										</div>  											                         
									</div>											
								</article>
								{/* <h5>ELD DETAILS</h5>
								<article className="table-data truck-data shadow bg-white rounded">
									<div className="row">
										<div className="col col-md-4">
											<div className="form-group">
											<Field type="text" name="eld_Provider_Id" component={renderSelect} className="form-control" label="ELD Provider" data={this.props.eldproviderlist.data}/>
											</div>
										</div>
										<div className="col col-md-1">
											<div className="form-group ico_circle">
												<i className="icofont-plus-circle add_plus"></i>
											</div>
										</div>
										<div className="col col-md-4">
											<div className="form-group">
											<Field type="text" name="device_id" component={renderField} label="Device Serial #"/>                               
											</div>
										</div>
									</div>
								</article> */}
								<h5>DOCUMENT</h5>
								<article className="table-data truck-data shadow bg-white rounded">
								  <ToolkitProvider
										keyField="id"
										data={ products }
										columns={ document }
										search
									 >
										{
										props => (
											<div>
											<SearchBar { ...props.searchProps } />
											<BootstrapTable
												{ ...props.baseProps } noDataIndication="No Data Found"  pagination={ paginationFactory(options) } hover  bordered={false}/>
											</div>
										)
										}
									</ToolkitProvider>
								</article>
								{/* <div className="row">
									<div className="col col-md-12">
										<div className="form-group form-group-btns">
											<button type="submit" className="btn btn-primary btn-sm">Save</button>
											<a href="#" className="btn btn-primary btn-sm">Reset</a>
										</div>
									</div>
								</div> */}
							</div>
						</div>
				</div>                   
			</div>
		</div>   
	</form>
        )
    }
}
const ShowTheLocationWithRouter = withRouter(ViewTrailer);
export default reduxForm({
    form: 'ViewTrailer', 
     enableReinitialize: false,
})(ShowTheLocationWithRouter);
//export default ViewTrailer