import * as types from './index';

export const documentAction = (payload) => {
    return {
        type: types.GET_DOCUMENT_DATA,
        payload: payload
    }
};

export const EditDocument = (payload) => {
	return {
		type: types.EDIT_DOCUMENT_DATA,
		payload: payload
	}
};


export const docList=(payload)=>{
	console.log(payload,"brokerload");
	return{
		type:types.LIST_DOCUMENT_DATA,
		payload:payload
	}
}

export const addDocument = (payload) => {
	return {
		type: types.POST_DOCUMENT_DATA,
		payload: payload
	}
};

export const editDocument=(payload)=>{
	return{
		type:types.EDIT_DOCUMENTS_DATA,
		payload:payload
	}
}