import React, { Component } from 'react';
import logo from '../../pc-mailer.png';
class TenderPopup extends Component{
render(){
return(
<form className="tg-forms">
   <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg">
         <div className="modal-content">
            <div className="modal-header">
               <h5 className="modal-title" id="exampleModalLabel">BAM</h5>
               <button type="button" className="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div className="popup-tender">
               <table>
                  <tbody>
                     <tr>
                        <td>fdfd</td>
                        <td>fdfd</td>
                     </tr>
                     <tr>
                        <td>fdfd</td>
                        <td>fdfd</td>
                     </tr>
                  </tbody>
               </table>
               <div id="accordionOne">
                  <div className="card">
                     <div className="card-header" id="popupHeadOne">
                        <h5 className="mb-0">
                           <button className="btn btn-link" data-toggle="collapse" data-target="#collapseHeadOne" aria-expanded="true" aria-controls="collapseHeadOne">
                           Load Information
                           </button>
                        </h5>
                     </div>
                     <div id="collapseHeadOne" className="collapse show" aria-labelledby="popupHeadOne" data-parent="#accordionOne">
                        <div className="card-body">
                           <div className="strip-pad">
                              <div className="form-row">
                                 <div className="col-3">
                                    <div className="row">
                                       <div className="col-md-4"><label htmlFor="recipient-name" className="form-label">Recipient:</label></div>
                                       <div className="col-md-8"><input type="text" className="form-control" placeholder="Zip"/></div>
                                    </div>
                                 </div>
                                 <div className="col-3">
                                    <div className="row">
                                       <div className="col-md-4"><label htmlFor="recipient-name" className="form-label">Recipient:</label></div>
                                       <div className="col-md-8"><input type="text" className="form-control" placeholder="Zip"/></div>
                                    </div>
                                 </div>
                                 <div className="col-3">
                                    <div className="row">
                                       <div className="col-md-4"><label htmlFor="recipient-name" className="form-label">Recipient:</label></div>
                                       <div className="col-md-8"><input type="text" className="form-control" placeholder="Zip"/></div>
                                    </div>
                                 </div>
                                 <div className="col-3">
                                    <div className="row">
                                       <div className="col-md-4"><label htmlFor="recipient-name" className="form-label">Recipient:</label></div>
                                       <div className="col-md-8"><input type="text" className="form-control" placeholder="Zip"/></div>
                                    </div>
                                 </div>
                              </div>
                              <div className="form-row">
                                 <div className="col-3">
                                    <div className="row">
                                       <div className="col-md-4"><label htmlFor="recipient-name" className="form-label">Recipient:</label></div>
                                       <div className="col-md-8"><input type="text" className="form-control" placeholder="Zip"/></div>
                                    </div>
                                 </div>
                                 <div className="col-3">
                                    <div className="row">
                                       <div className="col-md-4"><label htmlFor="recipient-name" className="form-label">Recipient:</label></div>
                                       <div className="col-md-8"><input type="text" className="form-control" placeholder="Zip"/></div>
                                    </div>
                                 </div>
                                 <div className="col-3">
                                    <div className="row">
                                       <div className="col-md-4"><label htmlFor="recipient-name" className="form-label">Recipient:</label></div>
                                       <div className="col-md-8"><input type="text" className="form-control" placeholder="Zip"/></div>
                                    </div>
                                 </div>
                                 <div className="col-3">
                                    <div className="row">
                                       <div className="col-md-4"><label htmlFor="recipient-name" className="form-label">Recipient:</label></div>
                                       <div className="col-md-8"><input type="text" className="form-control" placeholder="Zip"/></div>
                                    </div>
                                 </div>
                              </div>
                              <div className="form-row">
                                 <div className="col-3">
                                    <div className="row">
                                       <div className="col-md-4"><label htmlFor="recipient-name" className="form-label">Recipient:</label></div>
                                       <div className="col-md-8"><input type="text" className="form-control" placeholder="Zip"/></div>
                                    </div>
                                 </div>
                                 <div className="col-3">
                                    <div className="row">
                                       <div className="col-md-4"><label htmlFor="recipient-name" className="form-label">Recipient:</label></div>
                                       <div className="col-md-8"><input type="text" className="form-control" placeholder="Zip"/></div>
                                    </div>
                                 </div>
                                 <div className="col-3">
                                    <div className="row">
                                       <div className="col-md-4"><label htmlFor="recipient-name" className="form-label">Recipient:</label></div>
                                       <div className="col-md-8"><input type="text" className="form-control" placeholder="Zip"/></div>
                                    </div>
                                 </div>
                                 <div className="col-3">
                                    <div className="row">
                                       <div className="col-md-4"><label htmlFor="recipient-name" className="form-label">Recipient:</label></div>
                                       <div className="col-md-8"><input type="text" className="form-control" placeholder="Zip"/></div>
                                    </div>
                                 </div>
                              </div>
                              <div className="form-row">
                                 <div className="col-3">
                                    <div className="row">
                                       <div className="col-md-4"><label htmlFor="recipient-name" className="form-label">Recipient:</label></div>
                                       <div className="col-md-8"><input type="text" className="form-control" placeholder="Zip"/></div>
                                    </div>
                                 </div>
                                 <div className="col-3">
                                    <div className="row">
                                       <div className="col-md-4"><label htmlFor="recipient-name" className="form-label">Recipient:</label></div>
                                       <div className="col-md-8"><input type="text" className="form-control" placeholder="Zip"/></div>
                                    </div>
                                 </div>
                                 <div className="col-3">
                                    <div className="row">
                                       <div className="col-md-4"><label htmlFor="recipient-name" className="form-label">Recipient:</label></div>
                                       <div className="col-md-8"><input type="text" className="form-control" placeholder="Zip"/></div>
                                    </div>
                                 </div>
                                 <div className="col-3">
                                    <div className="row">
                                       <div className="col-md-4"><label htmlFor="recipient-name" className="form-label">Recipient:</label></div>
                                       <div className="col-md-8"><input type="text" className="form-control" placeholder="Zip"/></div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="strip-pad">
                              <h4>sdjksdsdbsdbsd</h4>
                              <div id="accordionChildOne">
                                 <div className="card">
                                    <div className="card-header" id="accChildHeadOne">
                                       <h5 className="mb-0">
                                          <button className="btn btn-link" data-toggle="collapse" data-target="#accchildCollapseOne" aria-expanded="true" aria-controls="accchildCollapseOne">
                                          Pick Up Details
                                          </button>
                                       </h5>
                                    </div>
                                    <div id="accchildCollapseOne" className="collapse show" aria-labelledby="accChildHeadOne" data-parent="#accordionChildOne">
                                       <div className="card-body">
                                          <table>
                                             <thead>
                                                <tr>
                                                   <th>Pick Date</th>
                                                   <th>Pick Time</th>
                                                   <th>Pickup 1</th>
                                                   <th>Pickup 2</th>
                                                   <th>Pickup 3</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr>
                                                   <td>10/12/2018</td>
                                                   <td>02:40:50</td>
                                                   <td>Test Location, 1000 Test Location Avenue, Suite #1000, Atlanta, GA, 30302.</td>
                                                   <td>Test Location, 1000 Test Location Avenue, Suite #1000, Atlanta, GA, 30302.</td>
                                                   <td>Test Location, 1000 Test Location Avenue, Suite #1000, Atlanta, GA, 30302.</td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="card">
                                    <div className="card-header" id="accChildHeadTwo">
                                       <h5 className="mb-0">
                                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#accChildCollapseTwo" aria-expanded="false" aria-controls="accChildCollapseTwo">
                                          Delivery Details
                                          </button>
                                       </h5>
                                    </div>
                                    <div id="accChildCollapseTwo" className="collapse" aria-labelledby="accChildHeadTwo" data-parent="#accordion2">
                                       <div className="card-body">
                                          <table>
                                             <thead>
                                                <tr>
                                                   <th>Pick Date</th>
                                                   <th>Pick Time</th>
                                                   <th>Pickup 1</th>
                                                   <th>Pickup 2</th>
                                                   <th>Pickup 3</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr>
                                                   <td>10/12/2018</td>
                                                   <td>02:40:50</td>
                                                   <td>Test Location, 1000 Test Location Avenue, Suite #1000, Atlanta, GA, 30302.</td>
                                                   <td>Test Location, 1000 Test Location Avenue, Suite #1000, Atlanta, GA, 30302.</td>
                                                   <td>Test Location, 1000 Test Location Avenue, Suite #1000, Atlanta, GA, 30302.</td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-header" id="popupHeadTwo">
                        <h5 className="mb-0">
                           <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseHeadTwo" aria-expanded="false" aria-controls="collapseHeadTwo">
                           Rate Information
                           </button>
                        </h5>
                     </div>
                     <div id="collapseHeadTwo" className="collapse" aria-labelledby="popupHeadTwo" data-parent="#accordion">
                        <div className="card-body">
                           <table>
                              <tbody>
                                 <tr>
                                    <td>Rate</td>
                                    <td>Rate</td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <table>
                                          <tbody>
                                             <tr>
                                                <td>Base Charge</td>
                                                <td></td>
                                                <td>Accessorials</td>
                                                <td></td>
                                             </tr>
                                             <tr>
                                                <td>Fuel Charge</td>
                                                <td></td>
                                                <td>Cash Advance</td>
                                                <td></td>
                                             </tr>
                                             <tr>
                                                <td>Other</td>
                                                <td></td>
                                                <td>Discount Amt</td>
                                                <td></td>
                                             </tr>
                                             <tr>
                                                <td>Gross</td>
                                                <td></td>
                                                <td>Total Revenue</td>
                                                <td></td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </td>
                                    <td>
                                       <table>
                                          <tbody>
                                             <tr>
                                                <td>Loaded Miles</td>
                                                <td></td>
                                                <td>Loaded Rate</td>
                                                <td></td>
                                             </tr>
                                             <tr>
                                                <td>Empty Miles</td>
                                                <td></td>
                                                <td>Empty Rate</td>
                                                <td></td>
                                             </tr>
                                             <tr>
                                                <td>Total Miles</td>
                                                <td></td>
                                                <td>Driver Pay</td>
                                                <td></td>
                                             </tr>
                                             <tr>
                                                <td>No. of stops</td>
                                                <td></td>
                                                <td>Pay/stop</td>
                                                <td></td>
                                             </tr>
                                             </tbody>
                                          </table>
                                       </td>
                                    </tr>
                                 </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="modal fade bd-trucks-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg">
         <div className="modal-content">
            <div className="modal-header">
               <h5 className="modal-title" id="exampleModalLabel">BAM</h5>
               <button type="button" className="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div className="popup-tender">
               <div className="row">
                  <div className="form-group col col-md-2">
                     <label htmlFor="exampleInputEmail1">From</label>
                     <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="From"/>				    	
                  </div>
                  <div className="form-group col col-md-2">
                     <label htmlFor="exampleInputPassword1">To</label>
                     <input type="text" className="form-control" id="exampleInputPassword1" placeholder="To"/>
                  </div>
                  <div className="form-group col col-md-2">
                     <label htmlFor="exampleInputEmail1">Miles</label>
                     <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Miles"/>				    	
                  </div>
                  <div className="form-group col col-md-0 mt-4 justify-content-center">
                     <img src={logo} alt=""/>
                  </div>
                  <div className="form-group col col-md-3">
                     <label htmlFor="exampleInputPassword1">Date</label>
                     <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Year"/>
                  </div>
                  <div className="form-group col col-md-2">
                     <label htmlFor="exampleInputPassword1">Load Number</label>
                     <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Plate"/>
                  </div>
               </div>
               <div id="accordionOne">
                  <table className="table table-borderless all-table tableTendered mb-0">
                     <thead>
                        <tr>
                           <th scope="col">P&L Summary</th>
                           <th scope="col">Dollars	</th>
                           <th scope="col">Percentage</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>Shipper Load Price</td>
                           <td>$929.96</td>
                           <td></td>
                        </tr>
                        <tr>
                           <td>Carrier Tender Price</td>
                           <td>$762.57</td>
                           <td>82.0%</td>
                        </tr>
                        <tr>
                           <td>Broker Gross Margin</td>
                           <td>$167.39</td>
                           <td>18.0%</td>
                        </tr>
                        <tr>
                           <td>Estimated BAM wire Fee</td>
                           <td>($10.68)</td>
                           <td>1.2%</td>
                        </tr>
                        <tr>
                           <td>Estimated Broker Net</td>
                           <td>$156.71</td>
                           <td>16.9%</td>
                        </tr>
                        <tr>
                           <td>Fuel Advance Commission</td>
                           <td>$0.00</td>
                           <td></td>
                        </tr>
                     </tbody>
                     <tfoot>
                        <tr>
                           <td>Total Broker Net</td>
                           <td>$156.71</td>
                           <td>16.9%</td>
                        </tr>
                     </tfoot>
                  </table>
                  <div className="row">
                     <div className="col-sm-12">
                        <div className="accordion accordion-bordered carrier-accordion" id="accordion-1" role="tablist">
                           <div className="accordion accordion-solid-header" id="Pricing-4" role="tablist">
                              <div className="card">
                                 <div className="card-header carrier-header" data-toggle="collapse" data-target="#Pricing-10" role="tab" id="heading-10">
                                    <h5 className="mb-0">                                      
                                       Pricing
                                       <i className="icofont-minus float-right"></i>
                                    </h5>
                                 </div>
                                 <div id="Pricing-10" className="p-0 collapse" role="tabpanel" aria-labelledby="heading-10" data-parent="#Pricing-4" >
                                    <div className="card-body p-0">
                                       <div className="row m-0">
                                          <div className="col-sm-12 p-0">
                                             <div className="table-responsive">
                                                <table className="table table-borderless all-table tableTendered mb-0">
                                                   <tbody>
                                                      <tr>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                         <td>BAM Funds</td>
                                                         <td> / </td>
                                                         <td>Broker Funds</td>
                                                      </tr>
                                                      <tr>
                                                         <td>Base Charge :</td>
                                                         <td>$800.00</td>
                                                         <td>Cents/Mile: </td>
                                                         <td>$0.38</td>
                                                         <td>Target Base Charge:</td>
                                                         <td>$ <input type="text" name="" defaultValue="632.61" placeholder="" className="mb-0"/> </td>
                                                         <td>Carrier advance</td>
                                                         <td><input type="text" name="" defaultValue="100" placeholder="" className="mb-0"/> % </td>
                                                         <td> / </td>
                                                         <td>0%</td>
                                                      </tr>
                                                      <tr>
                                                         <td>Fuel Charge: </td>
                                                         <td>$129.69</td>
                                                         <td>Price/Mile:  </td>
                                                         <td>$2.72</td>
                                                         <td>Target Fuel Charge:</td>
                                                         <td>$ <input type="text" name="" defaultValue="129.96" placeholder="" className="mb-0"/> </td>
                                                         <td>Carrier amount</td>
                                                         <td>$762.57 </td>
                                                         <td> / </td>
                                                         <td>$0.00</td>
                                                      </tr>
                                                      <tr>
                                                         <td>Accessorial: </td>
                                                         <td>$0.00</td>
                                                         <td>Shipper Term:  </td>
                                                         <td>30</td>
                                                         <td>Target Accessorial:</td>
                                                         <td>$ <input type="text" name="" defaultValue="0.00" placeholder="" className="mb-0"/> </td>
                                                         <td>Fuel advance</td>
                                                         <td></td>
                                                         <td></td>
                                                         <td><input type="text" name="" defaultValue="0" placeholder="" className="mb-0"/> %  </td>
                                                      </tr>
                                                      <tr>
                                                         <td>Total: </td>
                                                         <td>$929.96</td>
                                                         <td>Carrier Team (Broker):</td>
                                                         <td><input type="text" name="" defaultValue="45" placeholder="" className="mb-0"/> </td>
                                                         <td>Target Tendered Price:</td>
                                                         <td>$ <input type="text" name="" defaultValue="762.57" placeholder="" className="mb-0"/> </td>
                                                         <td>Fuel advance Amount</td>
                                                         <td></td>
                                                         <td></td>
                                                         <td>$0.00</td>
                                                      </tr>
                                                      <tr>
                                                         <td></td>
                                                         <td></td>
                                                         <td>Carrier Team (BAM):</td>
                                                         <td><input type="text" name="" defaultValue="2" placeholder="" className="mb-0"/> </td>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                         <td></td>
                                                      </tr>
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="card">
                                 <div className="card-header carrier-header" data-toggle="collapse" data-target="#collapse-11" role="tab" id="heading-11">
                                    <h5 className="mb-0">                                      
                                       Sharing
                                       <i className="icofont-minus float-right"></i>
                                    </h5>
                                 </div>                             
                                 <div id="collapse-11" className="p-0 collapse" role="tabpanel" aria-labelledby="heading-11" data-parent="#Pricing-4" >
                                    <div className="card-body p-0">
                                       <div className="row m-0">
                                          <div className="col-md-12 p-0">
                                             <div className="card">
                                                <div className="card-body pt-2 pb-2 pl-0 pr-0">
                                                   <div className="basic-block">
                                                      <div className="row m-0 pt-2 pb-2">
                                                         <div className="col-md-2">
                                                            <div className="form-check form-check-block">
                                                               <input className="form-check-input" type="radio" name="shareOption" id="shareManually" value="shareManually"/>
                                                               <label className="form-check-label" htmlFor="shareManually">Share Manually</label>
                                                            </div>
                                                         </div>
                                                         <div className="col-md-10 carriershareManually">
                                                            <div className="row"> 
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className="row m-0 pt-2 pb-2">
                                                         <div className="col-md-2">
                                                            <div className="form-check form-check-block">
                                                               <input className="form-check-input" type="radio" name="shareOption" id="shareAll" value="shareAll"/>
                                                               <label className="form-check-label" htmlFor="shareAll">Share to all</label>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="modal-footer">
		              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
		              <button type="button" className="btn btn-primary">Tender</button>
		            </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</form>
)
}
}
export default TenderPopup