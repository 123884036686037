import React from 'react';
//import Adddocumentpopup from '../documents/Adddocumentpopup';
import {Link,NavLink} from 'react-router-dom';

const DocumentList= ({ documentList }) => {
	const documentListHtml = documentList.map( tList =>{
		return(
			<tr key={ tList.id }>
			    <td><input type="checkbox"/></td>
	      		<td>{ tList.load }</td>
	      		<td>{ tList.document_title }</td>
	      		<td>{ tList.category }</td>
	      		<td>{ tList.owner }</td>
	      		<td>{ tList.uploaded_details}</td>
	      		<td>{ tList.notes }</td>
	      		<td>
	      		</td>
	    	</tr>
    	)
	});

	return(
		<article className="table-data truck-data shadow bg-white rounded">
			<div className="row no-gutters">
				<div className="col-md-12">
					<h4>Documents</h4>
					<ul className="nav nav-tabs" id="myDocuments" role="tablist">
					  	<li className="nav-item">
					    	<a className="nav-link active" id="mydocument-tab" data-toggle="tab" href="#mydocument" role="tab" aria-controls="mydocument" aria-selected="true"><i className="icofont-truck-loaded"></i>Documents</a>
					  	</li>	
					  	  	<div className="flex-left-auto">
					  		<ul>
					  			<li>					  				
					  				<Link to="/shipper/adddocument" className="btn btn-primary btn-sm">Add Document</Link>
					  			</li>
					  		</ul>
					  	</div>				  	
					</ul>
					<div className="tab-content" id="myTabContent">
					  	<div className="tab-pane fade show active" id="mydocument" role="tabpanel" aria-labelledby="mydocument-tab">
					  		<table className="table table-sm">
							  	<thead>
							    	<tr>
								      	<th scope="col"><input type="checkbox"/></th>
								     	<th scope="col">Load</th>
								     	<th scope="col">Document Title</th>	    
								     	<th scope="col">Category</th>
								     	<th scope="col">Owner</th>
								     	<th scope="col">Uploaded Details</th>
								     	<th scope="col">Notes</th>
								     	<th scope="col">Action</th>
							    	</tr>
							  	</thead>
							  	<tbody>
							    	{ documentListHtml }
							  	</tbody>
							 </table>
					  	</div>
					</div>
				</div>
			</div>
		</article>
	)
}
	
export default DocumentList;