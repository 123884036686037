
import React, { Component } from 'react';
import { Field,reduxForm } from 'redux-form';
import { renderField, renderSelect } from '../../../helpers/FormControls';
import { editDocument } from '../../../actions/documentActions';
import { connect } from 'react-redux';
import { formData } from '../../../helpers/formData';
function submit(dispatch){
  var data = formData(document.querySelector("#frmdocument"));

    if(Array.isArray(data.documentdata))
    {
        data = data.documentdata;
    }
   else
    {
        data = [data.documentdata];
    }

 var list=data.map(dList=>{
        return{
            documentId:dList.documentId,
            loadId:dList.loadId, 
            documentTitle:dList.documentTitle,
            documentTypeId:dList.documentTypeId,
            expiration:dList.expiration,
            issuedBy:dList.issuedBy,
            issuedDate:dList.issuedDate,
            others:dList.others
        }
    });
     var formfileval = document.querySelector('#frmdocument');
     var newval = formfileval.querySelectorAll('.fileclass');
    
  const documentobjects=
  {
    'document':list,
     'refs':newval
  }
  this.props.dispatch(editDocument(documentobjects));
}

class EditDocumentPopup extends Component {

   componentDidMount()
    {        
       // this.props.dispatch(commonlist());
    }
    render(){
       const {handleSubmit} = this.props;
        const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({ 
    input: { value: omitValue, onChange, onBlur, ...inputProps },data, label, controlClass,
    meta:{omitMeta,touched, error},  ...props
  }) => {
    return (
        <div className={` ${(touched && error) ? 'error' : ''}`}>
            <label>{label}</label>
            <input 
                className={`form-control ${(controlClass) ? controlClass : ''}`}
                onChange={adaptFileEventToValue(onChange)}
                onBlur={adaptFileEventToValue(onBlur)}
                type="file"
                {...props.input}
                {...props}
                placeholder={label} 
            />        
            {touched && error && <span className="error">{error}</span>}
        </div>
    );
};

  return (
<div className="modal fade bd-documentedit-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content"> 
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">EDIT DOCUMENT-LOAD</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                        </div>
                        <div className="modal-body">
                            <form className="tg-forms" id="frmdocument" onSubmit={handleSubmit(submit.bind(this))}>
                                        <article className="table-data truck-data shadow bg-white rounded">
                                       <div className="json-row" jsoncol="documentdata"> 
                                        <div className="row">  
                                        <Field type="hidden" controlClass="json-col" className="form-control" name="documentId" component={renderField}/>
                                        <div className="form-group col col-md-6">
                                        <Field type="text" controlClass="json-col" className="form-control" id="loadId" name="loadId" label="Order No" keyColumn="loadId" keyValue="name" component={renderSelect} data={this.props.loadlist.data}  />
                                        </div>
                                          <div className="form-group col col-md-6">
                                          <Field type="text" controlClass="json-col" className="form-control" name="documentTypeId" label="Document Type" component={renderSelect} data={this.props.documenttypelist.data}/>
                                        </div>
                                        </div>
                                        <div className="row">
                                        <div className="form-group col col-md-6">
                                        <Field  type="text" className="form-control" controlClass="json-col" name="documentTitle" component={renderField} label="Document Name"/>
                                        </div>
                                          <div className="form-group col col-md-6">
                                          <Field type="date" controlClass="json-col" className="form-control" name="expiration" component={renderField} label="Expiration" />
                                          </div>
                                        </div>
                                        <div className="row">
                                           <div className="form-group col col-md-6">
                                            <Field type="text" controlClass="json-col" className="form-control" name="issuedBy" component={renderField} label="Issued By" />
                                           </div>
                                            <div className="form-group col col-md-6">
                                            <Field type="date" controlClass="json-col" className="form-control" name="issuedDate" component={renderField} label="Issued Date" />
                                            </div>
                                        </div>
                                         <div className="row">
                                          <div className="form-group col col-md-6">
                                           <Field type="file" className="form-control" name="select_file" controlClass="json-col fileclass" id="select_file" component={FileInput} label="Select File"/>
                                          
                                          </div>
                                            <div className="form-group col col-md-6">
                                            <Field type="text" className="form-control" controlClass="json-col" name="others" component={renderField} label="Others" />
                                            </div>
                                         </div>
                                         </div>
                            </article> 
                            <div className="row">
                                <div className="col col-md-12">
                                    <div className="form-group form-group-btns">         
                                         <button type="submit" className="btn btn-primary btn-sm">Update</button>
                                    </div>
                                </div>
                            </div>
                            </form>
                      </div>
      </div>
  </div>
</div>
  )
}
}
const mapStateToProps = (state,ownProps) => {
return {
      data:state.documentReducer.data,
      loading: state.documentReducer.loading,
      error: state.documentReducer.error,
      initialValues: ownProps.modalvalues,
      enableReinitialize: true 
    }
}
export default connect(mapStateToProps)(reduxForm({
    form: 'EditDocumentPopup',   
  })(EditDocumentPopup))



