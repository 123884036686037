const components = {
	shippers: {
		component: 'Shippers',
		url: '/shippers',
		title: 'Shippers',
		icon: 'menu',
		module: 1
	},
	carriers: {
		component: 'Carriers',
		url: '/carriers',
		title: 'Carriers',
		icon: 'menu',
		module: 1
	},
	brokers: {
		component: 'Brokers',
		url: '/brokers',
		title: 'Brokers',
		icon: 'menu',
		module: 1
	},
	drivers: {
		component: 'Drivers',
		url: '/drivers',
		title: 'Drivers',
		icon: 'menu',
		module: 1
	},
	dashboard: {
		component: 'Dashboard',
		url: '/dashboard',
		title: 'Dashboard',
		icon: 'menu',
		module: 1
	},
	loads: {
		component: 'Loads',
		url: '/loads',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	assets: {
		component: 'Assets',
		url: '/assets',
		title: 'Assets',
		icon: 'menu',
		module: 1
	},
	contacts: {
		component: 'Contacts',
		url: '/contacts',
		title: 'Contacts',
		icon: 'menu',
		module: 1
	},
	documents: {
		component: 'Documents',
		url: '/documents',
		title: 'Documents',
		icon: 'menu',
		module: 1
	},
	providers: {
		component: 'Providers',
		url: '/providers',
		title: 'Providers',
		icon: 'menu',
		module: 1
	},
	pagenotfound: {
		component: 'PageNotFound',
		url: '/pagenotfound',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	loadreportforcustomer: {
		component: 'Loadreportforcustomer',
		url: '/loadreportforcustomer',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	truckreportforcustomer: {
		component: 'TruckReportForcustomer',
		url: '/truckreporforcustomer',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
	hosreportforcustomer: {
		component: 'HosReportForcustomer',
		url: '/hosreportforcustomer',
		title: 'Loads',
		icon: 'menu',
		module: 1
	},
};

const CommonRoutes = [
	components.dashboard,
	components.drivers,
	components.pagenotfound,
	components.loadreportforcustomer,
	components.truckreportforcustomer,
	components.hosreportforcustomer,
];
export { CommonRoutes }