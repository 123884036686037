import * as types from './index';

export const loadStatusAction = (payload) => {
	return {
		  type: types.GET_LOAD_STATUS,
		  payload: payload
	}
};

export const loadReportAction = (payload) => {
	return {
		  type: types.GET_LOAD_REPORTS,
		  payload: payload
	}
};
export const setgoFlag = (flag) => {
    return {
        type: types.SET_GOFLAG,
        flag : flag
    }
};

export const tripRegAction = (payload) => {
	return {
		  type: types.TRIP_REG_REPORT_STATUS,
		  payload: payload
	}
};

export const TruckReportAction = (payload) => {
	return {
		  type: types.TRUCK_SUMMARY_REPORT,
		  payload: payload
	}
};


export const LoadReportAction = (payload) => {
	return {
		  type: types.LOAD_SUMMARY_REPORT,
		  payload: payload
	}
};

export const DBFileSizeAction = (payload) => {
	return {
		  type: types.FILE_SIZE_DB,
		  payload: payload
	}
};

export const DBTableRecordsSizeAction = (payload) => {
	return {
		  type: types.FILE_RECORDS_SIZE_DB,
		  payload: payload
	}
};

export const HosReportAction = (payload) => {
	return {
		  type: types.HOS_REPORT,
		  payload: payload
	}
};




