export const validate = values => {
    const errors = {}
    if (!values.trailer) {
        errors.trailer = 'Trailer can not be left blank.!'
    }
    if (!values.make) {
        errors.make = 'Make can not be left blank.!'
    }    
    if (!values.model) {
        errors.model = 'Model can not be left blank.!'
    } 
    if (!values.year) {
        errors.year = 'Year can not be left blank.!'
    } 
    if (!values.plate) {
        errors.plate = 'Plate can not be left blank.!'
    } 
    if (!values.notes) {
        errors.notes = 'Notes can not be left blank.!'
    }
    if (!values.eldprovider) {
        errors.eldprovider = 'ELD Provider can not be left blank.!'
    }
    if (!values.serial) {
        errors.serial = 'Device Serial can not be left blank.!'
    }
    if (!values.attachment) {
        errors.attachment = 'Choose file can not be left blank.!'
    }
    return errors
}
