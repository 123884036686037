import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalTitle from 'react-bootstrap/ModalTitle';
import $ from 'jquery';
import { ViewDriverPOPUPComponent } from 'UI-Component';
import 'jquery-validation';

class ViewDriverPopup extends Component{
    constructor(props) {
        super(props);
            this.state={
                assetNumber:'' 
        }
    }
    componentDidUpdate = (prevProps) =>{
        if(this.props.userData !== prevProps.userData)
        {
            if(this.props.userData){
                this.setState({
                    ...this.state,
                    firstName:this.props.userData.First_Name,
                    lastName:this.props.userData.Last_Name,
                    dob:this.props.userData.dob,
                    drivingLicenseExpDate:this.props.userData.License_Expiry,
                    userTypeName:this.props.userData.userTypeName,
                    physicalExpDate:this.props.userData.physicalExpDate,
                    drivingLicenseStateCode:this.props.userData.License_State,
                    drivingLicenseNo:this.props.userData.License_No,
                    hireDate:this.props.userData.hireDate,
                    emailId:this.props.userData.Email_Id,
                    driverPay:this.props.userData.driverPay,
                    emptyRate:this.props.userData.emptyRate,
                    phoneNumber:this.props.userData.Phone_No,
                    state:this.props.userData.State,
                    city:this.props.userData.City,
                    occupation:this.props.userData.occupation,
                    address:this.props.userData.Address1,
                    zip:this.props.userData.zip,
                    eldProvider:this.props.userData.EldProviderDisplayName
                });
            }
        }
    }
    closeThis = (flag) =>{
        $('#view_driver').trigger('reset');
        this.props.closeNewUser(flag);
    }
    render = () =>{
        return(
            <Modal size='lg' aria-labelledby='contained-modal-title-vcenter' centered show={this.props.newDriverPopup} id='newuser'>
                <ModalHeader>
                <ModalTitle>{this.props.title}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                <ViewDriverPOPUPComponent state={this.state} />
                </ModalBody>
                <ModalFooter>
                    <button type='button' className='btn btn-primary' onClick={() => {this.closeThis(0)}}>Close</button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default ViewDriverPopup;