import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { assetupdate } from '../../../actions/assetAction';
import { renderField, renderSelect } from '../../../helpers/FormControls';
import { validate } from './Validation';

function submit(values,params) {
  const frmdata = {};
  frmdata.assetId= (values.asset_id) ? (values.asset_id) : ("");
  frmdata.assetType= 1;
  frmdata.companyId= (values.companyId) ? (values.companyId) : ("");

  frmdata.assetNumber= (values.asset_Number) ? (values.asset_Number) : ("");
  frmdata.truckTypeId= (values.truck_Type_Id) ? (values.truck_Type_Id) : ("");
  frmdata.fuelTypeId= (values.fuel_Type_Id) ? (values.fuel_Type_Id) : (""); 
  frmdata.eldProviderId= (values.eld_Provider_Id) ? (values.eld_Provider_Id) : ("");  
  frmdata.licensePlateNo=(values.license_Plate_No) ? (values.license_Plate_No) : ("");
  frmdata.identificationNo= (values.identification_No) ? (values.identification_No) : ("");
  frmdata.deviceId=(values.device_id) ? (values.device_id) : ("");
  frmdata.fuelType=(values.fuelType) ? (values.fuelType) : ("");
  frmdata.make=(values.make) ? (values.make) : ("");
  frmdata.model=(values.model) ? (values.model) : (""); 
  frmdata.modelYear=(values.model_Year) ? (values.model_Year) : ("");
  frmdata.color= (values.color) ? (values.color) : ("");
  frmdata.weight= (values.weight) ? (values.weight) : ("");
  frmdata.vehicleMileage=(values.vehicleMileage) ? (values.vehicleMileage) : ("");
  frmdata.mileageUnit=(values.mileageUnit) ? (values.mileageUnit) : ("");   
  this.props.dispatch(assetupdate(frmdata));
  // const frmParam={
  //     assettype: 'truck',
  //     usertype: 'Broker'      
  //   } 
  //   //window.alert(`You submitted:\n\n${JSON.stringify(frmParam, null, 2)}`)
  
  //   //this.props.dispatch(truckAction(frmParam)); 
}
   
class EditTruckPopup extends Component{
  componentDidMount()
    {        
        //this.props.dispatch(commonlist());
    }

  state={
    // truck_type:
    // [
    // {optName:"Select Truck Type",id:""},
    // {optName:"Semi Truck",id:1}
    // ],
    // eld_providers:
    // [
    // {optName:"Select ELD Providers",id:""},
    // {optName:"Azuga",id:1},
    // {optName:"Big Road",id:2},
    // {optName:"Blue Ink",id:3}
    // ]
  }  

  state=
  {
    allyears:[]
  }      

render(){
    const { handleSubmit, pristine, reset, submitting,itemsCountPerPage,activePage } = this.props
    
    // for year
    // let minOffset = 0, 
    let maxOffset = 20;
    let thisYear = (new Date()).getFullYear();
    let allYears = [];
    for(let x = 0; x <= maxOffset; x++) {
        allYears.push(thisYear - x)
    } 
    //const yearList = allYears.map((x) => {return(<option value={x} key={x}>{x}</option>)});
    const yearList = allYears.map(x=>{       
        return{
            "id":x,
            "name":x,
        }
    }); 
    //<Field type="text" name="model_Year" component={renderField} label="Year"/>  // old one
    // for year

/*    let minOffset = 0, maxOffset = 10;
    let thisYear = (new Date()).getFullYear();
    let allYears = [];
    for(let x = 0; x <= maxOffset; x++) {
        allYears.push(thisYear - x)
    }
    //const yearList = allYears.map((x) => {return(<option value={x} key={x}>{x}</option>)});
    const yearList = allYears.map(x=>{       
        return{
            "id":x,
            "name":x,
        }
    }); 
    <Field type="text" className="form-control" name="model_Year" id="year" component={renderSelect} data={yearList} label="Year" />                         

    */
   
return(
        <div className="modal fade bd-trucksedit-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                 <div className="modal-content">     
                   <div className="modal-header">
                   <h5 className="modal-title doc-color" id="exampleModalLabel">EDIT TRUCK</h5>
                    <button type="button" className="close" id="editfrm" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                   </div>
                    <div className="modal-body">
                              <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                                 <li className="nav-item">
                                    <a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">EDIT TRUCK</a>
                                 </li>  
                              </ul>
                                 <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">                                      
                                         <form className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}> 
                                           <input type="text" name="page" defaultValue={activePage} hidden/>
                                           <input type="text" name="itemsCountPerPage" defaultValue={itemsCountPerPage} hidden/>
                                             <div className="row">                 
                                                <div className="col col-md-12">
                                                <h5 className="doc-color">TRUCK INFO</h5>
                                                <article className="table-data truck-data shadow bg-white rounded">
                                                         <div className="row">
                                                            <div className="col col-md-4">
                                                               <div className="form-group">
                                                                   <Field type="text" className="form-control"  controlClass="requiredfield" name="asset_Number" id="truck" component={renderField} label="Truck #"/>                          
                                                               </div>
                                                               <div className="form-group">
                                                                  <Field type="text" className="form-control" name="model_Year" id="year" component={renderSelect} data={yearList} label="Year" />

                                                               </div> 
                                                               <div className="form-group">
                                                                    <Field type="text" className="form-control" name="truck_Type_Id" id="truck_type" component={renderSelect} label="Truck Type" data={this.props.trucktypelist.data}/>                          
                                                               </div>
                                                            </div>
                                                            <div className="col col-md-4">
                                                            <div className="form-group">
                                                                   <Field type="text" className="form-control" name="make" id="make" component={renderField} label="Make"/>                                                        
                                                               </div>
                                                               <div className="form-group">
                                                                   <Field type="text" className="form-control"  controlClass="requiredfield" name="identification_No" id="VIN" component={renderField} label="VIN"/>                         
                                                               </div>
                                                               <div className="form-group">
                                                                    <Field type="text" className="form-control" name="color" id="color" component={renderField} label="Color"/>                          
                                                            </div>
                                                            </div>
                                                            <div className="col col-md-4">     
                                                               <div className="form-group">
                                                                   <Field type="text" className="form-control" name="model" id="model" component={renderField} label="Model"/>                                                       
                                                               </div>
                                                               <div className="form-group">
                                                                   <Field type="number" className="form-control" name="license_Plate_No" id="plate" component={renderField} label="Plate #"/>                                                    
                                                               </div>
                                                               <div className="form-group">
                                                                  <Field type="text" name="fuel_Type_Id" component={renderSelect} className="form-control" label="Fuel Type" data={this.props.fueltypelist.FuelTypeList} />
                                                               </div>
                                                            </div>                           
                                                         </div>
                                                         </article>
                                                         <h5>ELD DETAILS</h5>
                                                         <article className="table-data truck-data shadow bg-white rounded">
                                                          <div className="row">
                                                            <div className="col col-md-5">
                                                               <div className="form-group">
                                                                   <Field type="text" className="form-control" name="eld_Provider_Id" id="provider" component={renderSelect} data={this.props.eldproviderlist.data} label="ELD Provider" />                         
                                                               </div>
                                                            </div>                                                           
                                                            <div className="col col-md-6">
                                                               <div className="form-group">
                                                                   <Field type="text" className="form-control"  controlClass="requiredfield" name="device_id" id="serialno" component={renderField} label="Device Serial #" />                                                        
                                                               </div>
                                                            </div>
                                                          </div>
                                                           </article>
                                                               <div className="row">
                                                               <div className="col col-md-12">
                                                                  <div className="form-group form-group-btns">
                                                                     <button type="submit" className="btn btn-primary btn-sm">Save</button>&nbsp;&nbsp;
                                                                     <button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-primary btn-sm">Reset</button>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                      </div>
                                                   </div>
                                          </form>
                                    </div>                      
                                   </div>
                                 </div>
                           </div>
                    </div>
               </div>
)
}
}


const PageOneForm = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  })(EditTruckPopup)


const mapStateToProps = (state,ownProps) => { 
  return{
      form: 'edittruck',
      initialValues: ownProps.modalvalues,
      validate,      
      enableReinitialize: true 
  }    
}

const PageOneFormContainer = connect(mapStateToProps)(PageOneForm)
  
export default PageOneFormContainer