export const validate = values => {
    const errors = {}
    if (!values.emailId) {
        errors.emailId = 'Email id can not be left blank.!'
    } 
    if (!values.carriername) {
        errors.carriername = 'carriername can not be left blank.!'
    } 
    if (!values.currentStateId) {
        errors.currentStateId = 'carriername can not be left blank.!'
    }
    if (!values.current_City) {
        errors.current_City = 'carriername can not be left blank.!'
    }
    if (!values.phoneNumber) {
        errors.phoneNumber = 'carriername can not be left blank.!'
    }
    // if (!values.firstname) {
    //     errors.firstname = 'First name can not be left blank.!'
    // } else if (values.firstname.length > 15) {
    //     errors.firstname = 'Must be 15 characters or less.!'
    // } else if (values.firstname.length < 3) {
    //     errors.firstname = 'Must be 3 characters or greater.!'
    // }
    // // if (!values.lastname) {
    // //     errors.lastname = 'Last name can not be left blank.!'
    // // } else if (values.lastname.length > 15) {
    // //     errors.lastname = 'Must be 15 characters or less.!'
    // // } else if (values.lastname.length < 3) {
    // //     errors.lastname = 'Must be 3 characters or greater.!'
    // // }
    // //if (!values.speciality) {
    //   //  errors.speciality = 'Speciality can not be left blank.!'
    // //}
    // //if (!values.carriername) {
    //     //errors.carriername = 'Carrier Name can not be left blank.!'
    // //}
    // if (!values.currentAddress1) {
    //     errors.currentAddress1 = 'Current Address can not be left blank.!'
    // }
    // //if (!values.permanentaddress) {
    //   //errors.permanentaddress = 'Permanent Address can not be left blank.!'
    // //}
    // if (!values.emailId) {
    //     errors.emailId = 'Email can not be left blank.!'
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailId)) {
    //     errors.emailId = 'Make sure that you entered valid email address.!'
    // }
    // if (!values.phoneNumber) {
    //     errors.phoneNumber = 'Primary Phone can not be left blank.!'
    // }
    // if (!values.faxNumber) {
    //     errors.faxNumber = 'Fax can not be left blank.!'
    // }
    // //if (!values.website) {
    //     //errors.website = 'Website can not be left blank.!'
    // //}
    // //else if(!isUrlValid(values.website) ) {
    //     //errors.website="Insert a valid URL";
    // //}
    // //if (!values.eldprovider) {
    //     //errors.eldprovider = 'ELD Provider can not be left blank.!'
    // //}
    // if (!values.current_City) {
    //     errors.current_City = 'City can not be left blank.!'
    // }    
    // if (!values.state) {
    //     errors.state = 'State can not be left blank.!'
    // } 
    // if (!values.CurrentZip) {
    //     errors.CurrentZip = 'Zip Code can not be left blank.!'
    // }
    //if (!values.country) {
        //errors.country = 'Country can not be left blank.!'
    //}
    //if (!values.nooftrucks) {
        //errors.nooftrucks = 'No of trucks can not be left blank.!'
    //}
    return errors
}

// const isUrlValid = (userInput) => {
//     var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
//     if(res == null)
//         return (false );
//     else
//         return (true);
// };