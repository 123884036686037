import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { assetupdate } from '../../../actions/assetAction';
import { renderField, renderSelect } from '../../../helpers/FormControls';
import { validate } from './validation';

function submit(values,params) {
	const frmdata = {};
	frmdata.assetId= (values.asset_id) ? (values.asset_id) : ("");
	frmdata.assetType= 1;
	frmdata.companyId= localStorage.getItem("companyId") ;
	frmdata.assetNumber= (values.asset_Number) ? (values.asset_Number) : ("");
	frmdata.truckTypeId= (values.truck_Type_Id) ? (values.truck_Type_Id) : ("");
	frmdata.fuelTypeId= (values.fuel_Type_Id) ? (values.fuel_Type_Id) : ("");	
	frmdata.eldProviderId= (values.eld_Provider_Id) ? (values.eld_Provider_Id) : ("");
	frmdata.licensePlateNo=(values.license_Plate_No) ? (values.license_Plate_No) : ("");
	frmdata.identificationNo= (values.identification_No) ? (values.identification_No) : ("");
	frmdata.deviceId=(values.device_id) ? (values.device_id) : ("");
	frmdata.fuelType=(values.fuelType) ? (values.fuelType) : ("");
    frmdata.make=(values.make) ? (values.make) : ("");
	frmdata.model=(values.model) ? (values.model) : ("");	
	frmdata.modelYear=(values.model_Year) ? (values.model_Year) : ("");
	frmdata.color= (values.color) ? (values.color) : ("");
	frmdata.weight= (values.weight) ? (values.weight) : ("");
	frmdata.vehicleMileage=(values.vehicle_Mileage) ? (values.vehicle_Mileage) : ("");
	frmdata.mileageUnit=(values.mileageUnit) ? (values.mileageUnit) : ("");	
	this.props.dispatch(assetupdate(frmdata));
}
class EditTruckPopup extends Component{
	// state={
	// 	drivers: [ 			
	// 		{ id:"1", name:"Shaji" }, 
	// 		{ id:"2", name:"Lal" }
	// 	]
	// }	
render(){
 const { handleSubmit, pristine, reset, submitting } = this.props
let minOffset = 0, maxOffset = 20;
let thisYear = (new Date()).getFullYear();
let allYears = [];
for(let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x)
}
//const yearList = allYears.map((x) => {return(<option value={x} key={x}>{x}</option>)});
const yearList = allYears.map(x=>{       
    return{
        "id":x,
        "name":x,
    }
}); 
//<Field type="text" name="model_Year" component={renderField} label="Year"/>  // old one
// for year
return(
   <div className="modal fade bd-trucksedit-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg">
         <div className="modal-content">     
            <div className="modal-header">
               <h5 className="modal-title" id="exampleModalLabel">EDIT TRUCK</h5>
                  <button type="button" className="close" id="editfrmshpr" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
            </div>
            <div className="modal-body table-data">
               <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                  <li className="nav-item">
                     <a className="nav-link active" id="truckinfo-tab" data-toggle="tab" href="#truckinfo" role="tab" aria-controls="truckinfo" aria-selected="true">EDIT TRUCK</a>
                  </li>                                                     
               </ul>
               <div className="tab-content" id="myTabContent">
                  	<div className="tab-pane fade show active" id="truckinfo" role="tabpanel" aria-labelledby="truckinfo-tab">                                      
                    	<form className="tg-forms" onSubmit={handleSubmit(submit.bind(this))}>	
							<div className="row">                 
								<div className="col col-md-12">
									<h5>TRUCK INFO</h5>
									<article className="table-data truck-data shadow bg-white rounded">
										<div className="row">
										<Field type="hidden" name="companyId" component={renderField} />
										<Field type="hidden" name="vehicleMileage" component={renderField} />
										<Field type="hidden" name="fuelType"component={renderField} />
										<Field type="hidden" name="weight" component={renderField} />
										<Field type="hidden" name="mileageUnit" component={renderField} />
											<div className="col col-md-4">
												<div className="form-group">
												<Field type="text" name="asset_Number" component={renderField} label="Truck #"/>                                
												</div>
												<div className="form-group">
												
												<Field type="text" className="form-control" name="model_Year" id="year" component={renderSelect} data={yearList} label="Year" />                         
												</div>
												<div className="form-group">
												<Field type="text" name="truck_Type_Id" component={renderSelect} className="form-control" label="Truck Type" data={this.props.trucktype.data} />
												</div>
											</div>
											<div className="col col-md-4">
												<div className="form-group">
												<Field type="text" name="make" component={renderField} label="Make"/> 
												</div>
												<div className="form-group">
												<Field type="text" name="identification_No" component={renderField} label="VIN"/>
												</div>
												<div className="form-group">
												<Field type="text" name="color" component={renderField} label="Color"/>                                 
												</div>
											</div>
											<div className="col col-md-4">
												<div className="form-group">
												<Field type="text" name="model" component={renderField} label="Model"/>
												</div>
												<div className="form-group">
												<Field type="text" name="license_Plate_No" component={renderField} label="Plate #"/>                               
												</div>
												<div className="form-group">
												<Field type="text" name="fuel_Type_Id" component={renderSelect} className="form-control" label="Fuel Type" data={this.props.fueltypelist.FuelTypeList} />
												</div>
											</div>                           
										</div>
									</article>
									<h5>ELD DETAILS</h5>
									<article className="table-data truck-data shadow bg-white rounded">
										<div className="row">
											<div className="col col-md-4">
												<div className="form-group">
												<Field type="text" name="eld_Provider_Id" component={renderSelect} className="form-control" label="ELD Provider" data={this.props.eldprovider.data} />
												</div>
											</div>
											<div className="col col-md-1">
												<div className="form-group ico_circle">
													<i className="icofont-plus-circle add_plus"></i>
												</div>
											</div>
											<div className="col col-md-4">
												<div className="form-group">
												<Field type="text" name="device_id" component={renderField} label="Device Serial #"/>                               
												</div>
											</div>
										</div>
									</article>
									<div className="row">
										<div className="col col-md-12">
											<div className="form-group form-group-btns">
												<button type="submit" className="btn btn-primary btn-sm">Save</button>&nbsp;&nbsp;
												<button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-primary btn-sm">Reset</button>
											</div>
										</div>
									</div>
								</div>
							</div>
                    	</form>
                    </div>          
                    </div>
                </div>                              
            </div>
        </div>
    </div>
)
}
}
const PageOneForm = reduxForm({
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
  })(EditTruckPopup)


  const mapStateToProps = (state,ownProps) => {	
	return{
		  form: 'edittruckValidation',
		  message: state.assetReducer.data,
		  initialValues: ownProps.modalvalues,
		  validate,
		  enableReinitialize: true 
	}    
}
  
const PageOneFormContainer = connect(mapStateToProps)(PageOneForm)
  
 export default PageOneFormContainer