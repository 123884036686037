export const validate = values => {
    const errors = {}  
    if(values.modelYear)
        if(isNaN(values.modelYear))        {
            errors.modelYear = 'Must be a number.!'
        }
        else if (values.modelYear.length > 4) {
            errors.modelYear = 'Must be 4 digits.!'
        } else if (values.modelYear.length < 4) {
            errors.modelYear = 'Must be 4 digits.!'
       }    
   
        if (!values.identificationNo) {
            errors.identificationNo = 'VIN can not be left blank.!'
        } 
        if (!values.licensePlateNo) {
            errors.licensePlateNo = 'Plate # can not be left blank.!'
        }
        if(values.color)
        {
            if (values.color.length > 10) {
                errors.color = 'Must be 10 characters or less.!'
            }
        }
        // if (!values.deviceId) {
        //     errors.deviceId = 'Device Serial # can not be left blank.!'
        // } 
        //  if (!values.assetNumber) {
        //     errors.assetNumber = 'Trailer # can not be left blank.!'
        // } 

        if (!values.eldProviderId) {
            errors.eldProviderId = 'EldProvider can not be left blank.!'
        } 

        
   
    return errors   
}
export const bulkUploadValidation = values => {
    const errors = {}
    if (!values.attachment) {
        errors.attachment = 'Attachment can not be left blank.!'
    }
    return errors
}