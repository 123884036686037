import React, { Component, Fragment } from 'react';
import { userlistAction } from '../../../actions/userActions';
import DriverList from '../drivers/DriverList';
import {toastr} from 'react-redux-toastr'
import { connect } from 'react-redux';

class Drivers extends Component{
	componentDidUpdate()
	{
		if(this.props.data.status==="ALREADY_REPORTED")
        {
		   toastr.info('Info', 'Driver Already Exists!');
		    const params={	
				companytype: 'Carrier',
				usertype: 'Driver'			
		    }
		    this.props.dispatch(userlistAction(params));		
        }
        else if(this.props.data.status==="CREATED")
        {
			toastr.success('Success', 'Driver Created Successfully!');
			const params={	
				companytype: 'Carrier',
				usertype: 'Driver'			
			}
			this.props.dispatch(userlistAction(params));
			document.getElementById("adddriverfrm").click(); 
		}
		else if(this.props.data.status==="OK")
        {
			toastr.success('Success', 'Driver Updated Successfully!');
			const params={	
				companytype: 'Carrier',
				usertype: 'Driver'			
			}	
			this.props.dispatch(userlistAction(params));  
			document.getElementById("editdriverfrm").click();   
		}
		else if(this.props.data.status==="ACCEPTED")
        {
			toastr.success('Success', 'Driver Deleted Successfully!');
			const params={	
				companytype: 'Carrier',
				usertype: 'Driver'			
			}	
			this.props.dispatch(userlistAction(params));  
			document.getElementById("editdriverfrm").click();   
        }
	}

	componentDidMount() {
		document.title = 'Drivers';
	}

	render(){
		return(
			<Fragment>
				<DriverList/>
			</Fragment>
		)
	}
}
const mapStateToProps = state => {
	return {
		data: state.userReducer.data,
		error: state.userReducer.error,
	}
}
export default connect(mapStateToProps)(Drivers)