import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../helpers/FormControls';

class ViewDriver extends Component{
    state={
        data:[
        {name:'Alaska',id:'1'}
        ]
    }
    render(){
        this.props.initialize(this.props.location.aboutProps);
        return(
            <form className="tg-forms"> 
            <div className="table-data border">
                <ul className="nav nav-tabs" id="mydrivers" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="driverinfo-tab" data-toggle="tab" href="#driverinfo" role="tab" aria-controls="driverinfo" aria-selected="true">View driver</a>
                    </li>                                 
                </ul>
                <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="driverinfo" role="tabpanel" aria-labelledby="driverinfo-tab">                                      
                   
                      <div className="row">                 
                                      <div className="col col-md-12">
                                      <h5 className="doc-color">DRIVER INFO</h5>
                                      <article className="table-data driver-data shadow bg-white rounded">
                                               <div className="row">
                                                  <div className="col col-md-4">
                                                     <div className="form-group">
                                                     <Field type="text" name="firstname" controlClass="requiredfield" component={renderField} label="First Name"/>										    
                                                     </div>
                                                     <div className="form-group">
                                                     <Field type="text" name="drivingLicenseExpDate" component={renderField} label="License Exp. Date"/>		
                                                     </div> 
                                                     <div className="form-group">
                                                       <Field type="date" name="drivingLicenseStateCode" component={renderField} label="License State" />
                                                     </div>
                                                  </div>
                                                  <div className="col col-md-4">
                                                  <div className="form-group">
                                                  <Field type="text" name="lastname" controlClass="requiredfield" component={renderField} label="Last Name"/>	
                                                     </div>
                                                     <div className="form-group">
                                                     <Field type="text" name="physicalExpDate" component={renderField} label="Physical Exp. Date"/>   
                                                     </div>
                                                     <div className="form-group">
                                                     <Field type="text" name="drivingLicenseNo" controlClass="requiredfield" component={renderField} label="License Number" />                          
                                                  </div>
                                                  </div>
                                                  <div className="col col-md-4">     
                                                     <div className="form-group">
                                                     <Field type="text" name="dob" component={renderField} label="D.O.B"/>
                                                     </div>
                                                     <div className="form-group">
                                                     <Field type="text" name="userTypeName" controlClass="requiredfield" component={renderField} label="Driver Type"  />  
                                                     
                                                     </div>
                                                     <div className="form-group">
                                                     <Field type="text" name="hireDate" component={renderField}  label="hire Date"/>
                                                     </div>
                                                  </div>                           
                                               </div>

                                               <div className="row">
                                                  <div className="col col-md-4">
                                                     <div className="form-group">
                                                     <Field type="text" name="email" controlClass="requiredfield" component={renderField} label="Email"/>										    
                                                     </div>
                                                     <div className="form-group">
                                                     <Field type="text" name="mobileno" component={renderField} label="Mobile Number"/>		
                                                     </div> 
                                                     <div className="form-group">
                                                     <Field type="text" name="occupation" component={renderField} label="Occupation"/>	
                                                       
                                                     </div>
                                                  </div>
                                                  <div className="col col-md-4">
                                                  <div className="form-group">
                                                  <Field type="text" name="driverPay" component={renderField} label="LoadedRate/Driver-pay"/>	
                                                     </div>
                                                     <div className="form-group">
                                                     <Field type="text" name="state" controlClass="requiredfield" component={renderField} label="State"/>
                                                     </div>
                                                     <div className="form-group">
                                                     <Field type="text" name="address" component={renderField} label="Address" />                          
                                                  </div>
                                                  </div>
                                                  <div className="col col-md-4">     
                                                     <div className="form-group">
                                                     <Field type="text" name="emptyRate" component={renderField} label="Empty Rate"/>                                                         
                                                     </div>
                                                     <div className="form-group">
                                                     <Field type="text" name="permanentCityName" onChange={this.cityOnchange} component={renderField} keyColumn='cityId' keyValue='cityName' label="City" />                                                           
                                                     </div>
                                                     <div className="form-group">
                                                     <Field type="text" name="permanentZipValue" keyColumn='zipId' keyValue='zipValue'  component={renderField} label="Zip"/>
                                                     </div>
                                                  </div>                           
                                               </div>
                                               </article>                                    
                                               {/* <div className="row">
                                                    <div className="col col-md-12">
                                                    <div className="form-group form-group-btns">
                                                        <button type="submit" className="btn btn-primary btn-sm">Save</button>&nbsp;
                                                        <button type="button" className="btn btn-primary btn-sm">Reset</button>
                                                    </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                         </div>
              
                </div>                   
            </div>
        </div>   
    </form>
        )
    }
}



const ShowTheLocationWithRouter = withRouter(ViewDriver);
export default reduxForm({
    form: 'viewTrucks', 
     enableReinitialize: false,
})(ShowTheLocationWithRouter);