import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import App from './App';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import './index.css';
import './components/common/IndvidualCss/paymentsList.css'
import './indexCompany.css';
import './fontAwesomeFree5.0.1.css';
import './icofont.css';
import * as serviceWorker from './serviceWorker';
import ReduxToastr from 'react-redux-toastr'
import font from './fonts/font.css';
import { createRoot } from "react-dom/client";
const store = configureStore();
// React 18 render method for loading our react app component into root element based on Root-ID to get all react-18 latest features.
const rootElement = document.getElementById("root");
const root =createRoot(rootElement);
root.render(
    <Provider store={store}>
        <App />
        <div>
            <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
            />
        </div>
    </Provider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
