import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { borkerQuickDispatchAction, companyListByType, loadAction, loadActionfrquickdispatch, quickdispatchbrokerFlagAction, canceledloadflag } from '../../../actions/loadActions';
import { STATUS } from '../../../helpers/status';
import { preferredCompanyListByType } from '../../../actions/companyActions';



class QuickDispatch extends Component {

    constructor(props) {
        super(props);
        this.requested = [];
    }

    state = { loadRequested: [], carriers: [] };

    componentDidMount = () => {
        const params = {
            ...STATUS.LOAD.REQUESTED,
        }
        this.props.dispatch(loadActionfrquickdispatch(params));
        const params1 = {
            companytypeid: 7
        }
        this.props.dispatch(preferredCompanyListByType(params1));
    }

    selectLoad = (e, index) => {
        var loadRequested = this.state.loadRequested;
        loadRequested[index].loadSel = e.target.checked;
        this.setState({
            ...this.state,
            loadRequested: loadRequested
        });
        this.props.tabClicked(e, this.state.loadRequested);
    }

    selectCarrier = (e, index) => {

        var carriers = this.state.carriers;
        carriers[index].selCarrier = e.target.checked;
        this.setState({
            ...this.state,
            carriers: carriers
        });

    }

    componentDidUpdate = () => {
        if (this.props.canceledloadflag === true) {
            if (this.props.data.message === "Loads Cancelled Sucessfully") {
                toastr.success('Success', 'Loads Cancelled Sucessfully!');
                this.props.dispatch(canceledloadflag());
                if (this.props.currentStatus.loadStatusId === 1) {
                    const params = {
                        ...STATUS.LOAD.REQUESTED
                    }
                    this.props.dispatch(loadActionfrquickdispatch(params));
                    this.setState({
                        ...this.state,
                        loadRequested: []
                    })
                } else {
                    const params = {
                        ...STATUS.LOAD.DELIVERY_CONFIRMED
                    }
                    this.props.dispatch(loadAction(params));
                    this.setState({
                        ...this.state,
                        loadRequested: []
                    })
                }
            }
        }
        if (this.props.quickdispatchbrokerflag === true) {
            if (this.props.quickDispatch.status === "ACCEPTED") {
                toastr.success('Success', 'Quick dispatch saved Successfully!');
                this.props.dispatch(quickdispatchbrokerFlagAction());
                const params = {
                    ...STATUS.LOAD.REQUESTED,
                }
                this.props.dispatch(loadActionfrquickdispatch(params));
                this.props.dispatch(companyListByType({
                    companytypeid: localStorage.getItem('companyId')
                }));
                this.setState({
                    ...this.state,
                    loadRequested: [],
                    carriers: []
                })
            }
            else if (typeof this.props.quickDispatch.status != "undefined") {
                toastr.error('error', 'Unable to save Quick dispatch!');
                const params = {
                    ...STATUS.LOAD.REQUESTED,
                }
                this.props.dispatch(loadActionfrquickdispatch(params));
            }
        }
    }

    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.quickdispatchloads.quickloadlists) {
            if (nextProps.quickdispatchloads.quickloadlists.LoadList === 0) {
                var loadRequested = nextProps.quickdispatchloads.quickloadlists.LoadList.map(
                    row => {
                        return ({
                            loadSel: false,
                            id: row.loadId,
                            loadNumber: row.loadNumber,
                            stringLoadDate: row.stringLoadDate,
                            shipperName: row.shipperName,
                            customerName: row.customerName,
                            CarrierName: row.carrierName,
                            consigneeName: row.consigneeName,
                            consigneeAddress: ((row.consigneeAddress1 === null ? '' : row.consigneeAddress1) + " " + (row.consigneeAddress2 === null ? '' : row.consigneeAddress2)),
                            weight: row.weight,
                            originCity: row.originCity,
                            destinationCity: row.destinationCity,
                            commodity: row.commodity,
                            equipmentType: row.truckTypeName,
                            loadPointsList: row.loadPointsList,
                            basecharge: row.baseCharges,
                            fulecharge: row.fuelCharges,
                            accessiorialcharge: row.accessorialCharges,
                            totalrevenue: row.totalRevenue,
                            loadedmiles: row.loadedMiles,
                            emptymiles: row.emptyMiles,
                            totalmiles: row.totalMiles,
                            driverpay: row.driverPay,
                            tenderprice: row.shipperLoadPrice
                        });
                    }
                );
                if (loadRequested.length > 0) {
                    this.setState({
                        ...this.state,
                        loadRequested: loadRequested
                    });
                }
            }
        }


        if (Array.isArray(nextProps.companyList.companyList)) {
            if (this.state.carriers.length === 0) {
                var carriers = nextProps.companyList.companyList.map(
                    (row, index) => {
                        return ({
                            id: row.Company_Id,
                            company_Name: row.Company_Name,
                            selCarrier: false,
                            selIndex: '',
                            current_City: row.City_Name,
                            company_Email: row.Company_Email,
                            state_Name: row.State_Name,
                            current_Zip: row.Current_Zip,
                            phone_Number: row.Phone_Number,
                            isPreferredCompany: row.isPreferredCompany,
                        });
                    });

                if (carriers.length > 0) {
                    this.setState({
                        ...this.state,
                        carriers: carriers
                    });
                }
            }
        }
    }


    assignLoad = (row) => {

        var selLoads = this.state.loadRequested.filter((row1) => {

            return row1.loadSel;

        });

        if (selLoads.length === 0) {
            toastr.error('Error', 'Select Load!');
            return;
        }

        selLoads = selLoads.map((load) => load.id);
        this.props.dispatch(borkerQuickDispatchAction({
            loads: selLoads,
            carrier: row.id
        }));



    }

    radiobutton = (e, index) => {
        var cells = document.getElementsByClassName("assignbtn");
        for (var i = 0; i < cells.length; i++) {
            cells[i].disabled = true;
        }
        if (e.target.value === 'on') {
            const idval = "truckval" + index;
            document.getElementById(idval).disabled = false;
        }
    }

    render() {

        if (this.props.data.status === 'UNAUTHORIZED') {
            localStorage.clear();
            window.location = '/login'
            return <p></p>
        }
        if (this.props.loading) {
            var indicationhtml =
                <div className="spinner-border" role="status">
                    <div className="row react-bs-table-no-data"></div>
                </div>
        }
        else {
            if (this.state.loadRequested.length > 0) {
                var rowData = this.state.loadRequested.map(
                    (row, index) => {
                        return ({
                            loadSelInput: <input type="checkbox" onChange={(e) => this.selectLoad(e, index)} defaultChecked={row.loadSel} />,
                            id: row.id,
                            loadNumber: row.loadNumber,
                            stringLoadDate: row.stringLoadDate,
                            shipperName: row.shipperName,
                            customerName: row.customerName,
                            carrierName: row.CarrierName,
                            consigneeName: row.consigneeName,
                            consigneeAddress: row.consigneeAddress,
                            weight: row.weight,
                            originCity: row.originCity,
                            destinationCity: row.destinationCity,
                            commodity: row.commodity,
                            equipmentType: row.equipmentType,
                            loadPointsList: row.loadPointsList,
                            basecharge: row.basecharge,
                            fulecharge: row.fulecharge,
                            accessiorialcharge: row.accessiorialcharge,
                            totalrevenue: row.totalrevenue,
                            loadedmiles: row.loadedmiles,
                            emptymiles: row.emptymiles,
                            totalmiles: row.totalmiles,
                            driverpay: row.driverpay,
                            tenderprice: row.tenderprice
                        });
                    }
                );
            }
            else {
                rowData = [];
                indicationhtml = "No Data Found";
            }
        }
        const expandRow2 = {
            renderer: row => (
                <article className="table-data truck-data shadow bg-white rounded">
                    <div className="row no-gutters">
                        <div className="col-md-12">

                            <ul className="nav nav-tabs" id="myTrucks" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="pickup-tab" data-toggle="tab" href={`#pickup_${row.id}`} role="tab" aria-controls="pickup" aria-selected="true" >
                                        pickup Details
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="delivery-tab" data-toggle="tab" href={`#delivery_${row.id}`} role="tab" aria-controls="delivery" aria-selected="false" >
                                        Delivery Details
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="brokertender-tab" data-toggle="tab" href={`#brokertender_${row.id}`} role="tab" aria-controls="brokertender" aria-selected="false" >
                                        Broker Tender
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id={`pickup_${row.id}`} role="tabpanel" aria-labelledby="pickup-tab">
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">Address</th>
                                                <th scope="col">Contact</th>
                                                <th scope="col">Earliest Time</th>
                                                <th scope="col">Latest Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                row.loadPointsList.length ? (row.loadPointsList.map(function (doc, index) {
                                                    if (doc.loadPointTypeId === 2) {
                                                        var Contact = (doc.contactAddress1 == null ? "" : doc.contactAddress1);
                                                        Contact = (Contact === "" ? "" : (Contact + ", ")) + (doc.contactAddress2 == null ? "" : doc.contactAddress2);
                                                        Contact = (Contact === "" ? "" : (Contact + ", ")) + (doc.contactZip == null ? "" : doc.contactZip);
                                                        return (
                                                            <tr key={index}>
                                                                <td>{Contact}</td>
                                                                <td>{doc.contactNumber}</td>
                                                                <td>{doc.earliestTime}</td>
                                                                <td>{doc.latestTime}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })) : (<tr><td className="text-center" colspan="4">No Record Found</td></tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="tab-pane fade" id={`delivery_${row.id}`} role="tabpanel" aria-labelledby="delivery-tab">
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">Address</th>
                                                <th scope="col">Contact</th>
                                                <th scope="col">Earliest Time</th>
                                                <th scope="col">Latest Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                row.loadPointsList.length ? (row.loadPointsList.map(function (doc, index) {
                                                    if (doc.loadPointTypeId === 3) {
                                                        var Contact = (doc.contactAddress1 == null ? "" : doc.contactAddress1);
                                                        Contact = (Contact === "" ? "" : (Contact + ", ")) + (doc.contactAddress2 == null ? "" : doc.contactAddress2);
                                                        Contact = (Contact === "" ? "" : (Contact + ", ")) + (doc.contactZip == null ? "" : doc.contactZip);
                                                        return (
                                                            <tr key={index}>
                                                                <td>{Contact}</td>
                                                                <td>{doc.contactNumber}</td>
                                                                <td>{doc.earliestTime}</td>
                                                                <td>{doc.latestTime}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })) : (<tr><td className="text-center" colspan="4">No Record Found</td></tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="tab-pane fade" id={`brokertender_${row.id}`} role="tabpanel" aria-labelledby="brokertender-tab">
                                    <table className="table table-sm">
                                        <tbody>
                                            <tr>
                                                <td>Shipper Load Price{(row.basecharge === null) ? <i>&nbsp;</i> : (row.basecharge)}</td><td><input className="form-control" type="text" name="shipperloadprice" style={{ width: '98px' }} /></td>
                                                <td>Carrier Tender Price{(row.accessiorialcharge === null) ? <i>&nbsp;</i> : (row.accessiorialcharge)}</td><td><input className="form-control" type="text" name="carriertenderprice" style={{ width: '98px' }} /></td>
                                                <td>Broker Gross Margin{(row.tenderprice === null) ? <i>&nbsp;</i> : (row.tenderprice)}</td><td><input className="form-control" type="text" name="brokergrossmargin" style={{ width: '98px' }} /></td>
                                                <td>Base Charge{(row.basecharge === null) ? <i>&nbsp;</i> : (row.basecharge)}</td><td><input className="form-control" type="text" name="basecharge" style={{ width: '98px' }} /></td>
                                                <td>Fuel Charge{(row.basecharge === null) ? <i>&nbsp;</i> : (row.basecharge)}</td><td><input className="form-control" type="text" name="fuelcharge" style={{ width: '98px' }} /></td>
                                                <td><button type="submit" className="btn btn-primary btn-sm">Confirm</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            ),
            showExpandColumn: true,
            expandByColumnOnly: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return (
                        <i className="icofont-minus-circle"></i>
                    );
                }
                return (
                    <i className="icofont-plus-circle"></i>
                );
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <i className="icofont-minus-circle"></i>
                    );
                }
                return (
                    <i className="icofont-plus-circle"></i>
                );
            }
        };
        const columns = [
            {
                dataField: 'loadSelInput',
                text: ''
            },
            {
                text: 'Load #',
                dataField: 'loadNumber',
                sort: true
            },
            {
                text: 'Date',
                dataField: 'stringLoadDate',
                sort: true
            },
            {
                text: 'Shipper',
                dataField: 'shipperName',
                sort: true
            },
            {
                text: 'Carrier',
                dataField: 'carrierName',
                sort: true
            },
            {
                text: 'Consignee',
                dataField: 'consigneeName',
                sort: true
            },
            {
                text: 'Consignee Address',
                dataField: 'consigneeAddress',
                sort: true
            },
            {
                text: 'Weight',
                dataField: 'weight',
                sort: true
            },
            {
                text: 'Origin',
                dataField: 'originCity',
                sort: true
            },
            {
                text: 'Destination',
                dataField: 'destinationCity',
                sort: true
            },
            {
                text: 'Commodity',
                dataField: 'commodity',
                sort: true
            },
            {
                text: 'Equipment Type',
                dataField: 'equipmentType',
                sort: true
            }
        ];

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                Showing {from} to {to} of {size} Results
            </span>
        );

        const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            sizePerPage: 5,
            firstPageText: '<<<',
            prePageText: '<<',
            nextPageText: '>>',
            lastPageText: '>>>',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
        };
        var carrierRowData = [];
        if (this.props.loading) {
             indicationhtml =
                <div className="spinner-border" role="status">
                    <div className="row react-bs-table-no-data"></div>
                </div>
        }
        else {
            if (this.state.carriers.length > 0) {
                 carrierRowData = this.state.carriers.map(
                    (row, index) => {
                        if (row.isPreferredCompany === 1)
                            return ({
                                carrierSelInput: <input type="radio" name="radionbtn" id={"truckid" + index} onClick={(e) => this.radiobutton(e, index)} />,
                                selBtn: <button className="btn btn-primary assignbtn" id={"truckval" + index} onClick={(e) => this.assignLoad(row)} >Assign</button>,
                                id: row.id,
                                company_Name: row.company_Name,
                                current_City: row.current_City,
                                company_Email: row.company_Email,
                                state_Name: row.state_Name,
                                current_Zip: row.current_Zip,
                                phone_Number: row.phone_Number,
                                isPreferredCompany: row.isPreferredCompany,
                            });
                    });
            } else {
                carrierRowData = [];
                 indicationhtml = "No Data Found";
            }
        }

        return (
            <article className="table-data truck-data shadow bg-white rounded">
                <div className="row no-gutters">
                    <div className="col-md-12">
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="availLoads" role="tabpanel" aria-labelledby="availLoads">
                                <ToolkitProvider
                                    keyField="id"
                                    data={rowData}
                                    columns={columns}
                                    search
                                >
                                    {
                                        props => (
                                            <div>
                                                <BootstrapTable
                                                    {...props.baseProps} pagination={paginationFactory(options)} noDataIndication={indicationhtml} hover expandRow={expandRow2}
                                                    bordered={false}
                                                />
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <p>&nbsp;</p>
            </article>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.loadReducer.data,
        DriverList: state.loadReducer.DriverList,
        companyList: state.companyReducer.preferredCompanyList,
        quickdispatchbrokerflag: state.loadReducer.quickdispatchbrokerflag,
        quickDispatch: state.loadReducer.QuickDispatch,
        canceledloadflag: state.loadReducer.canceledloadflag,
        quickdispatchloads: state.loadReducer.data
    }
}
export default connect(mapStateToProps)(QuickDispatch);