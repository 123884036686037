import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import * as types from '../actions';
import {PreferredCompanyListByTypeService,
        PreferredCompanyListService, 
        ChangePreferenceService,
        carrierSearchService,
        carrierAddService,
        companyNameListService,
        geofenceRadiusStatusService,
        carrierBulkUploadService,
         geofenceCompanyNameListService, 
         geofenceCarrierCompanyNameListService, 
         geofenceRadiusListService, 
         geofenceMailTimeService, 
         carrierListForFactorService,
         getInvitedCarrierService,
         getCarrierInvitationService,
         tcWidgetCarrierInvitationService,
         VerifyingCredentialsAndSignLOAService,
         tcWidgetVerifyingCredentialsAndSignLOAService,
         completeSignUPCarriersService,
         getInvitedCustomerService,
         getMyCustomerService,
         getMyCarrierService,
         tcWidgetCompleteSignUPCarriersService,
         completeTCWidgetSignUPCarriersService,
         getSweepstakesCarrierInvitationService,
         getMyCustomerOptInOutService,
         getActiveDeliveriesService,
         getPastDeliveriesService,
         getActiveFuelAdvanceService,
         getPastFuelAdvanceService,
         updateFactorService} from '../services/companyService';
import { changePreference } from '../actions/companyActions';
import {
    carrierSearchServiceNew,
    inviteNewCarrierService
} from '../services/companyService';

function* PreferredCompanyListByType(payload) { 
    try {   
        const response = yield call( PreferredCompanyListByTypeService, payload );
        yield put({type: types.PREFERRED_COMPANY_LIST_BY_TYPE_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.PREFERRED_COMPANY_LIST_BY_TYPE_ERROR, error })
    }
}

function* PreferredCompanyList(payload) { 
    try {   
        const response = yield call( PreferredCompanyListService, payload );
        yield put({type: types.PREFERRED_COMPANY_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.PREFERRED_COMPANY_LIST_ERROR, error })
    }
}

function* ChangePreference(payload){
    try{
        const response = yield call( ChangePreferenceService, payload );
        yield put({type: types.CHANGE_PREFERRED_RESPONSE_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.CHANGE_PREFERRED_RESPONSE_FAILED, error })
    }
}

function* CarrierSearch(payload){
    try{
        const response = yield call( carrierSearchService, payload );
        yield put({type: types.CARRIER_SEARCH_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.CARRIER_SEARCH_ERROR, error })
    }
}

function* Carrieradd(payload){
    try{
        const response = yield call( carrierAddService, payload );
        yield put({type: types.CARRIER_ADD_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.CARRIER_ADD_ERROR, error })
    }
}

function* companyNameList(payload){
    try{
        const response = yield call( companyNameListService, payload );
        yield put({type: types.COMPANY_NAME_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.COMPANY_NAME_LIST_ERROR, error })
    }
}

function* geofenceCompanyNameList(payload){
    try{
        const response = yield call( geofenceCompanyNameListService, payload );
        yield put({type: types.GEOFENCE_COMPANY_NAME_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GEOFENCE_COMPANY_NAME_LIST_ERROR, error })
    }
}

function* geofenceCarrierCompanyNameList(payload){
    try{
        const response = yield call( geofenceCarrierCompanyNameListService, payload );
        yield put({type: types.GEOFENCE_CARRIER_COMPANY_NAME_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GEOFENCE_CARRIER_COMPANY_NAME_LIST_ERROR, error })
    }
}

function* geoFenceradiusStatus(payload){
    try{
        const response = yield call( geofenceRadiusStatusService, payload );
        yield put({type: types.GEOFENCE_RADIUS_STATUS_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GEOFENCE_RADIUS_STATUS_ERROR, error })
    }
}
// radius
function* geoFenceradiusList(payload){
    try{
        const response = yield call( geofenceRadiusListService, payload );
        yield put({type: types.GEOFENCE_RADIUS_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GEOFENCE_RADIUS_LIST_ERROR, error })
    }
}
function* geoFencemailtimeList(payload){
    try{
        const response = yield call( geofenceMailTimeService, payload );
        yield put({type: types.GEOFENCE_MAIL_TIME_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GEOFENCE_MAIL_TIME_LIST_ERROR, error })
    }
}


function* carrierBulkUpload(payload){
    try{
        const response = yield call( carrierBulkUploadService, payload );
        yield put({type: types.CARRIER_BULK_UPLOAD_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.CARRIER_BULK_UPLOAD_ERROR, error })
    }
}

function* carrierListForFactor(payload){
    try{
        const response = yield call( carrierListForFactorService, payload );
        yield put({type: types.GET_CARRIER_LIST_FACTOR_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GET_CARRIER_LIST_FACTOR_ERROR, error })
    }
}
//new carrier search
function* CarrierSearchNew(payload) {
    try {
        const response = yield call(carrierSearchServiceNew, payload);
        yield put({ type: types.CARRIER_SEARCH_NEW_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.CARRIER_SEARCH_NEW_ERROR, error })
    }
}
//invite new carrier
function* invitedNewCarrier(payload){
    try{
        const response = yield call( inviteNewCarrierService, payload );
        yield put({type: types.INVITED_NEW_CARRIER_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.INVITED_NEW_CARRIER_ERROR, error })
    }
} 
//invited carriers tab
function* invitedCompanyList() {
    try {
        const response = yield call(getInvitedCarrierService);
        yield put({ type: types.GET_INVITED_COMPANY_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GET_INVITED_COMPANY_LIST_ERROR, error })
    }
}
//my carriers tab
function* myCarrierCompanyList() {
    try {
        const response = yield call(getMyCarrierService);
        yield put({ type: types.GET_MY_CARRIER_COMPANY_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GET_MY_CARRIER_COMPANY_LIST_ERROR, error })
    }
}

//INVITED CARRIER THROUGH INVITATION LINK
function* carrierInvitation(payload) {
    try {
        const response = yield call(getCarrierInvitationService,payload);
        yield put({ type: types.GET_CARRIER_INVITATION_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GET_CARRIER_INVITATION_ERROR, error })
    }
}
//INVITED CARRIER THROUGH TC WIDGET 
function* tcWidgetCarrierInvitation(payload) {
    try {
        const response = yield call(tcWidgetCarrierInvitationService,payload);
        yield put({ type: types.GET_TC_WIDGET_CARRIER_INVITATION_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GET_TC_WIDGET_CARRIER_INVITATION_ERROR, error })
    }
}
//INVITED CARRIER THROUGH SWEEPSTAKES UI 
function* sweepstakeaCarrierInvitation(payload) {
    try {
        const response = yield call(getSweepstakesCarrierInvitationService,payload);
        yield put({ type: types.GET_SWEEPSTAKES_CARRIER_INVITATION_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GET_SWEEPSTAKES_CARRIER_INVITATION_ERROR, error })
    }
}
function* completeSignUPCarriers(payload) {
    try {
        const response = yield call(completeSignUPCarriersService, payload);
        yield put({ type: types.COMPLETE_SIGNUP_CARRIERS_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.COMPLETE_SIGNUP_CARRIERS_ERROR, error })
    }
}
//TC Widget Carrier Sign UP
function* completeTCWidgetSignUPCarriers(payload) {
    try {
        const response = yield call(completeTCWidgetSignUPCarriersService, payload);
        yield put({ type: types.COMPLETE_TC_WIDGET_SIGNUP_CARRIERS_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.COMPLETE_TC_WIDGET_SIGNUP_CARRIERS_ERROR, error })
    }
}
//instant cob ELD verification and sign LOA
function* verifyingCredentialsAndSignLOA(payload) {
    try {
        const response = yield call(VerifyingCredentialsAndSignLOAService, payload);
        yield put({ type: types.VERIFY_CREDENTIALS_AND_SIGN_LOA_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.VERIFY_CREDENTIALS_AND_SIGN_LOA_ERROR, error })
    }
}
//TC Widget ELD verification and sign LOA
function* tcWidgetVerifyingCredentialsAndSignLOA(payload) {
    try {
        const response = yield call(tcWidgetVerifyingCredentialsAndSignLOAService, payload);
        yield put({ type: types.TC_WIDGET_VERIFY_CREDENTIALS_AND_SIGN_LOA_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.TC_WIDGET_VERIFY_CREDENTIALS_AND_SIGN_LOA_ERROR, error })
    }
}
//Invited Customers
function* invitedCustomerList() {
    try {
        const response = yield call(getInvitedCustomerService);
        yield put({ type: types.GET_INVITED_CUSTOMER_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GET_INVITED_CUSTOMER_LIST_ERROR, error })
    }
}
//My customers
function* myCustomerList() {
    try {
        const response = yield call(getMyCustomerService);
        yield put({ type: types.GET_MY_CUSTOMER_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GET_MY_CUSTOMER_LIST_ERROR, error })
    }
}
//My customers OPT IN/OUT
function* myCustomerOptInOutList(payload) {
    try {
        const response = yield call(getMyCustomerOptInOutService, payload);
        yield put({ type: types.CHANGE_MY_CUSTOMER_OPT_IN_OUT_STATUS_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.CHANGE_MY_CUSTOMER_OPT_IN_OUT_STATUS_LIST_ERROR, error })
    }
}

//Factoring API List
// CONFIRMED DELIVERIES
function* confirmedActiveDeliveriesList(payload) {
    try {
        const response = yield call(getActiveDeliveriesService, payload);
        yield put({ type: types.GET_ACTIVE_DELEVERIES_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GET_ACTIVE_DELEVERIES_LIST_ERROR, error })
    }
}
function* confirmedPastDeliveriesList(payload) {
    try {
        const response = yield call(getPastDeliveriesService, payload);
        yield put({ type: types.GET_PAST_DELEVERIES_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GET_PAST_DELEVERIES_LIST_ERROR, error })
    }
}
//Fuel Advances
function* fuelAdvanceActiveList(payload) {
    try {
        const response = yield call(getActiveFuelAdvanceService, payload);
        yield put({ type: types.GET_ACTIVE_FUEL_ADVANCE_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GET_ACTIVE_FUEL_ADVANCE_LIST_ERROR, error })
    }
}
function* fuelAdvancePastList(payload) {
    try {
        const response = yield call(getPastFuelAdvanceService, payload);
        yield put({ type: types.GET_PAST_FUEL_ADVANCE_LIST_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.GET_PAST_FUEL_ADVANCE_LIST_ERROR, error })
    }
}
//Update Factor
function* UpdateFactor(payload) {
    try {
        const response = yield call(updateFactorService, payload);
        yield put({ type: types.UPDATE_FACTOR_BASED_ON_INVOICE_SUCCESS, data: response });
    } catch (error) {
        yield put({ type: types.UPDATE_FACTOR_BASED_ON_INVOICE_ERROR, error })
    }
}
export function* companySaga() {
    yield takeEvery(types.PREFERRED_COMPANY_LIST_BY_TYPE, PreferredCompanyListByType);
    yield takeEvery(types.PREFERRED_COMPANY_LIST, PreferredCompanyList);
    yield takeEvery(types.CHANGE_PREFERRED_REQUEST, ChangePreference);
    yield takeEvery(types.CARRIER_SEARCH, CarrierSearch);
    yield takeEvery(types.CARRIER_ADD, Carrieradd);
    yield takeEvery(types.COMPANY_NAME_LIST, companyNameList);
    yield takeEvery(types.GEOFENCE_COMPANY_NAME_LIST, geofenceCompanyNameList);
    yield takeEvery(types.GEOFENCE_CARRIER_COMPANY_NAME_LIST, geofenceCarrierCompanyNameList);
    yield takeEvery(types.GEOFENCE_RADIUS_LIST, geoFenceradiusList);
    yield takeEvery(types.GEOFENCE_MAIL_TIME_LIST, geoFencemailtimeList);
    yield takeEvery(types.GEOFENCE_RADIUS_STATUS, geoFenceradiusStatus);
    yield takeEvery(types.CARRIER_BULK_UPLOAD, carrierBulkUpload);
    yield takeEvery(types.GET_CARRIER_LIST_FACTOR, carrierListForFactor);
    yield takeEvery(types.CARRIER_SEARCH_NEW,CarrierSearchNew );
    yield takeEvery(types.GET_INVITED_COMPANY_LIST , invitedCompanyList);
    yield takeEvery(types.GET_MY_CARRIER_COMPANY_LIST , myCarrierCompanyList);
    yield takeEvery(types.GET_CARRIER_INVITATION , carrierInvitation);
    yield takeEvery(types.GET_TC_WIDGET_CARRIER_INVITATION , tcWidgetCarrierInvitation);
    yield takeEvery(types.GET_SWEEPSTAKES_CARRIER_INVITATION , sweepstakeaCarrierInvitation);
    yield takeEvery(types.VERIFY_CREDENTIALS_AND_SIGN_LOA,verifyingCredentialsAndSignLOA );
    yield takeEvery(types.TC_WIDGET_VERIFY_CREDENTIALS_AND_SIGN_LOA,tcWidgetVerifyingCredentialsAndSignLOA );
    yield takeEvery(types.COMPLETE_SIGNUP_CARRIERS,completeSignUPCarriers );
    yield takeEvery(types.COMPLETE_TC_WIDGET_SIGNUP_CARRIERS,completeTCWidgetSignUPCarriers );
    yield takeEvery(types.GET_INVITED_CUSTOMER_LIST , invitedCustomerList);
    yield takeEvery(types.GET_MY_CUSTOMER_LIST , myCustomerList);
    yield takeEvery(types.CHANGE_MY_CUSTOMER_OPT_IN_OUT_STATUS_LIST , myCustomerOptInOutList);
    yield takeEvery(types.GET_ACTIVE_DELEVERIES_LIST, confirmedActiveDeliveriesList);
    yield takeEvery(types.GET_PAST_DELEVERIES_LIST, confirmedPastDeliveriesList);
    yield takeEvery(types.GET_ACTIVE_FUEL_ADVANCE_LIST, fuelAdvanceActiveList);
    yield takeEvery(types.GET_PAST_FUEL_ADVANCE_LIST, fuelAdvancePastList);
    yield takeEvery(types.UPDATE_FACTOR_BASED_ON_INVOICE, UpdateFactor);
}